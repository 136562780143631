import React from "react";
import PropTypes from "prop-types";
import {
  STATUS_LOADING,
  STATUS_SUCCESS,
  STATUS_ERROR,
} from "../../common/utils/generic";
import Validator from "../../common/utils/validator";
import "./style.scss";
import { toast } from "react-toastify";
import "react-datepicker/dist/react-datepicker.css";

export default class UpdateProfile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      document_type: "NATIONAL_ID",
      type_text: "Documento de Identidad *",
      document_number: "",
      first_name: "",
      last_name: "",
      birthdate: new Date(),
      gender: "",
      error: "",
      mobile_number: "",
      country_code_number: "",
      code: "",
      loadCode: false,
      showResponse: false,
      readOnly: false,

      accepted_terms_cond: true,
      accepted_politics_privacity: true,

      delete_account: false,
      delete_points: false,
      sendEmail: false,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.updateComponent();
    let {
      sendDeleteStatus,
      cleanDeleteStatus,
      changeSecondaryMenu,
      changeStateCart,
      stateCart,
    } = this.props;
    if (sendDeleteStatus === STATUS_SUCCESS) {
      toast.success("Se ha enviado el correo de confirmación");
      this.setState({ sendEmail: true });
      cleanDeleteStatus();
    }
    if (sendDeleteStatus === STATUS_ERROR) {
      toast.error("Ha ocurrido un error al enviar el correo de confirmación");
      cleanDeleteStatus();
    }
    if (stateCart !== "CART_INVISIBLE") {
      changeSecondaryMenu();
      changeStateCart();
    }
  }

  componentDidMount() {
    let { changeSecondaryMenu, changeStateCart } = this.props;
    changeSecondaryMenu();
    changeStateCart();
    this.updateComponent();
  }

  updateComponent = () => {
    let { user } = this.props;
    if (user) {
      if (this.state.document_number !== user.document_number) {
        let birthdate = new Date(user.birthdate);
        birthdate = birthdate.setDate(birthdate.getDate() + 1);
        birthdate = new Date(birthdate);
        this.setState({
          document_type: user.document_type,
          document_number: user.document_number,
          first_name: user.first_name,
          last_name: user.last_name,
          birthdate:
            user.birthdate === "" || user.birthdate === null ? "" : birthdate,
          gender: user.gender === "" || user.gender === null ? "" : user.gender,
          country_code_number:
            user.country_code_number === "" || user.country_code_number === null
              ? ""
              : user.country_code_number,
          //mobile_number: (user.mobile_number === '' || user.mobile_number === null) ? "" : user.mobile_number.replace(`+${user && user.area && user.area.country.code}`,"")
          mobile_number:
            user.mobile_number === "" || user.mobile_number === null
              ? ""
              : user.mobile_number.substring(3, user.mobile_number.length),
        });
        this.validator = new Validator([
          {
            id: "document_number",
            ref: this.dniInput,
            rules: ["required", "dni"],
          },
          {
            id: "first_name",
            ref: this.firstNameInput,
            rules: ["required"],
          },
          {
            id: "last_name",
            ref: this.lastNameInput,
            rules: ["required"],
          },
          {
            id: "mobile_number",
            ref: this.mobileInput,
            rules: ["numeric"],
          },
        ]);
      }
    }

    if (
      !this.state.loadCode &&
      user &&
      user.area &&
      this.state.code !== `+${user && user.area && user.area.country.code}`
    ) {
      this.setState({
        code: `+${user && user.area && user.area.country.code}`,
        loadCode: true,
      });
    }
  };

  handleOption = (e, gender) => {
    this.setState({ gender });
  };

  selectDocumentType = (e, name, type_id) => {
    e.preventDefault();
    this.setState({ type_text: name, type: type_id });
  };

  setFormValues = (propertiesToUpdate) => {
    this.setState(propertiesToUpdate);
    this.validator.validate(propertiesToUpdate);
  };

  onSubmitForm = async (event) => {
    event.preventDefault();
    let { sendDeleteEmail } = this.props;

    if (!this.state.delete_account || !this.state.delete_points) {
      toast.error("Debe aceptar las condiciones");
      return;
    }
    sendDeleteEmail();
  };

  onCloseSession = async (event) => {
    event.preventDefault();
    let { closeSession } = this.props;
    closeSession();
  };

  renderError(errors, fieldName) {
    return (
      errors &&
      errors[fieldName] && (
        <span className="field-error-message">{errors[fieldName][0]}</span>
      )
    );
  }

  onChangeDate = (date) => {
    this.setState({ birthdate: date });
  };

  getYear = (date) => {
    return date.getFullYear();
  };
  getMonth = (date) => {
    return date.getMonth();
  };

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({ [name]: value });
  };

  render() {
    let { updateStatus } = this.props;
    return (
      <div className=" container ">
        {this.state.sendEmail ? (
          <div className={"container-delete-account"}>
            <h3>Tu baja necesita ser confirmada mediante mail</h3>
            <p>
              Se te ha enviado un mail con un enlace para poder confirmar la
              baja de tu cuenta
            </p>

            <div className="submits col-sm-12 col-md-4 flex-column">
              <button
                type="submit"
                onClick={(e) => this.onCloseSession(e)}
                style={{
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  padding: "10px 0",
                }}
                className="btn btn-update btn-primary "
              >
                Cerrar sesión
              </button>
            </div>
          </div>
        ) : (
          <div className={"container-delete-account"}>
            <h3>Estás seguro que quieres darte de baja?</h3>
            <p>
              Recuerda que si te das de baja perderás todo tus puntos, monedas y
              beneficios acumulados
            </p>

            <div className={"checkbox-content"}>
              <input
                type="checkbox"
                id="delete_account"
                value={this.state.delete_account}
                onClick={(e) => {
                  this.setState({ delete_account: !this.state.delete_account });
                }}
                className={"mr-2"}
              />
              <label>
                Entiendo que mi cuenta dejará de estar disponible en la APP y
                apps relacionadas
              </label>
            </div>

            <div className={"checkbox-content"}>
              <input
                type="checkbox"
                id="delete_points"
                value={this.state.delete_points}
                onClick={(e) => {
                  this.setState({ delete_points: !this.state.delete_points });
                }}
                className={"mr-2"}
              />
              <label>
                Entiendo que todos mis puntos, monedas y beneficios serán
                eliminados.
              </label>
            </div>

            <div className="submits col-sm-12 col-md-4 flex-column">
              <button
                type="submit"
                onClick={(e) => this.onSubmitForm(e)}
                disabled={
                  updateStatus === STATUS_LOADING || this.state.readOnly
                }
                style={{
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  padding: "10px 0",
                }}
                className="btn btn-delete-account"
              >
                Dar de baja a mi cuenta
              </button>
            </div>
          </div>
        )}
      </div>
    );
  }
}

UpdateProfile.defaultProps = {
  error: null,
  updateStatus: "",
};

UpdateProfile.propTypes = {
  dispatch: PropTypes.func,
  error: PropTypes.object,
  updateStatus: PropTypes.string,
};
