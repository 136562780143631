import React, {Fragment} from 'react'
import PropTypes from 'prop-types';
import {STATUS_LOADING} from '../../common/utils/generic';
import Validator from '../../common/utils/validator';
import {BTN_UPDATE, BTN_UPDATE_LOADING} from "./constants";
import './style.scss';
import arrow_down from "../../common/images/downarrow.png";
import Extras from "../../common/utils/extras";
import countriesPhone from "../../common/components/JSON/countriesPhone.json"
import Select from 'react-select'
import downarrow from "../../common/images/downarrow.png";

export default class CompleteData extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      document_type: "NATIONAL_ID",
      type_text: "Documento de Identidad",
      document_number: "",
      first_name: "",
      last_name: "",
      birthdate: "",
      gender: "",
      error: '',
      mobile_number: "",
      country_code_number: "",
      code: "+51",
      showResponse: false
    };
  }


  componentDidMount() {

    let {user, getProfile} = this.props
    if (user === null) {
      getProfile()
    } else {
      this.updateComponent()
    }
  }

  updateComponent() {
    let {user} = this.props
    if(user){

      this.validator = new Validator([
        {
          id: "document_number",
          ref: this.dniInput,
          rules: ["dni"]
        },
        {
          id: "birthdate",
          ref: this.birthdateInput,
          rules: ["date"]
        },
        {
          id: "first_name",
          ref: this.firstNameInput,
          rules: ["required"]
        },
        {
          id: "last_name",
          ref: this.firstNameInput,
          rules: ["required"]
        }, {
          id: "mobile_number",
          ref: this.mobileNumberInput,
          rules: ["numeric"]
        }
      ]);
    }

    if (user && user.area && this.state.code !== `+${user && user.area && user.area.country.code}`) {
      this.setState({code: `+${user && user.area && user.area.country.code}`})
    }

  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {history, user} = this.props;
    if (user) {
      if (Extras.isDataComplete(user) || localStorage.getItem("skipUpdate") === 'skip') {
       history.replace('/')
      } else {
        this.updateComponent()
      }
    }
  }

  handleOption = (e, gender) => {
    this.setState({gender});
  };

  selectDocumentType = (e, name, type_id) => {
    e.preventDefault();
    this.setState({type_text: name, type: type_id});
  };

  setFormValues = (propertiesToUpdate) => {
    this.setState(propertiesToUpdate);
    if(this.validator){
      this.validator.validate(propertiesToUpdate);
    }
  }


  onSubmitForm = async (event) => {
    event.preventDefault();

    this.setState({showResponse: false})

    let {updateUser, user} = this.props;

    /* payload */
    let payload = {...this.state}
    delete payload.showResponse
    delete payload.type_text
    delete payload.error
    payload.first_name = payload.first_name.split(" ")[0]
    payload.last_name = payload.last_name.split(" ")[0]

    // If 'user' have first_name, then payload wont have this property
    if (user.first_name !== null && user.first_name !== "") {
      delete payload.first_name
    }
    if (user.last_name !== null && user.last_name !== "") {
      delete payload.last_name
    }
    if ((user.birthdate !== null && user.birthdate !== "")) {
      delete payload.birthdate
    }
    if ((user.document_number !== null && user.document_number !== "")) {
      delete payload.document_number
      delete payload.document_type
    }
    if (user.gender !== null && user.gender !== "") {
      delete payload.gender
    }
    if (payload.birthdate === '') {
      delete payload.birthdate
    }
    if (payload.gender === '') {
      delete payload.gender
    }
    if (payload.mobile_number !== ''){
      payload.mobile_number = `${this.state.code}${payload.mobile_number}`
      payload.country_code_number = `${this.state.code}`
    }else{
      delete payload.mobile_number
      delete payload.country_code_number
    }

    if (this.validator.validate(payload)) {
      updateUser(payload);
    }

  }

  renderError(errors, fieldName) {
    return errors && errors[fieldName] &&
      <span className='field-error-message'>
        {errors[fieldName][0]}
      </span>;
  }

  render() {
    let {updateStatus, profileStatus, user, errors, app: {company: {logo_rectangle, logo}}, skipUpdate} = this.props;
    let logo_app = logo_rectangle ? logo_rectangle : logo
    let listCountriesPhone = countriesPhone.countries
    let options = []
    let defaultValue = null
    listCountriesPhone.sort(function (o1,o2) {
      if (o1.name_es > o2.name_es) { //comparación lexicogŕafica
        return 1;
      } else if (o1.name_es < o2.name_es) {
        return -1;
      }
      return 0;
    });
    listCountriesPhone.map(c => {
      options.push({value: `${c.dial_code}`, label: `${c.dial_code} ${c.name_es}`})
      if (c.dial_code === `+${user && user.area && user.area.country.code}`) {
        defaultValue = {value: `${c.dial_code}`, label: `${c.dial_code} ${c.name_es}`}
      }
      return true
    })

    let mainColor = localStorage.getItem("colors_main_color") ? localStorage.getItem("colors_main_color") : "#1b4bc6"


    return (

      <div className={"rendered-content"}>

        <div className="elevation-container col-xl-4 col-lg-5 col-md-7 col-sm-6">
          <div className="complete-data">
            {
              user ?
                <Fragment>
                  <div className="box-form">
                    <form onSubmit={this.onSubmitForm}
                          className={(profileStatus !== null || profileStatus !== "") ? "form" : "hidden"}>
                      <img src={logo_app ? logo_app : logo} className="logo" alt="logo"/>
                      <h2>Completar Información Personal</h2> <br/>
                      <p className={(user.document_number && user.document_number !== "") ? "hidden" : ""}>
                        Hay algunos datos que debes completar. Recuerda que los negocios necesitan tu documento para
                        poder validar tus promociones en los establecimientos
                      </p>
                      <div
                        className={user.document_number && user.document_number !== "" ? "hidden" : "form-group dropdown"}>
                        <div
                          className="form-control"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false">
                          <span style={{
                            display: "block",
                            marginTop: "5px",
                          }}>{this.state.type_text}</span>
                          <img src={arrow_down} alt="" className={"arrow-down"}/>
                        </div>
                        <div
                          className="dropdown-menu"
                          style={{
                            width: "100%",
                            overflowY: "auto",
                            maxHeight: 200
                          }}
                        >
                          <a
                            href={"/"}
                            className="dropdown-item dropdown-type select-type"
                            onClick={e =>
                              this.selectDocumentType(
                                e,
                                "Documento de Identidad *",
                                "NATIONAL_ID"
                              )
                            }
                          >
                            Documento de Identidad
                          </a>
                          <a
                            href={"/"}
                            className="dropdown-item dropdown-type select-type"
                            onClick={e =>
                              this.selectDocumentType(
                                e,
                                "Pasaporte",
                                "PASSPORT"
                              )
                            }
                          >
                            Pasaporte
                          </a>
                          <a
                            href={"/"}
                            className="dropdown-item dropdown-type select-type"
                            onClick={e =>
                              this.selectDocumentType(
                                e,
                                "Carnet de Extranjería",
                                "FOREIGNER_ID"
                              )
                            }
                          >
                            Carnet de Extranjería
                          </a>
                          <a
                            href={"/"}
                            className="dropdown-item dropdown-type select-type"
                            onClick={e =>
                              this.selectDocumentType(e, "Ruc", "TAX_ID")
                            }
                          >
                            Ruc
                          </a>
                          <a
                            href={"/"}
                            className="dropdown-item dropdown-type select-type"
                            onClick={e =>
                              this.selectDocumentType(e, "Otro", "OTHER")
                            }
                          >
                            Otro
                          </a>
                        </div>
                      </div>
                      <div className={
                        user.document_number !== null &&
                        user.document_number !== "" ? "hidden" : "form-group"
                      }>
                        <div className="input-group" ref={input => {
                          if (input) {
                            this.dniInput = input.querySelector("input");
                          }
                        }}>
                          <input
                            value={this.state.document_number}
                            className="form-control"
                            onChange={e => this.setFormValues({document_number: e.target.value})}
                            type="text"
                            placeholder="Documento de identidad *"
                            disabled={updateStatus === STATUS_LOADING}
                          />

                        </div>
                        {this.renderError(errors, 'document_number')}
                      </div>

                      <div className={user.first_name && user.first_name !== "" ? "hidden" : "form-group dropdown"}>
                        <div
                          className="input-group"
                          ref={(input) => {
                            if (input) {
                              this.firstNameInput = input.querySelector('input');
                            }
                          }}
                        >
                          <input
                            className={"form-control"}
                            value={this.state.first_name}
                            onChange={e => this.setFormValues({first_name: e.target.value})}
                            type="text"
                            placeholder="Nombres *"
                            disabled={updateStatus === STATUS_LOADING}
                          />

                        </div>
                        {this.renderError(errors, 'first_name')}
                      </div>
                      <div
                        className={user.last_name !== null && user.last_name !== "" ? "hidden" : "form-group dropdown"}>
                        <div>
                          <div
                            className="input-group"
                            ref={(input) => {
                              if (input) {
                                this.lastNameInput = input.querySelector('input');
                              }
                            }}
                          >
                            <input
                              className={"form-control"}
                              value={this.state.last_name}
                              onChange={e => this.setFormValues({last_name: e.target.value})}
                              type="text"
                              placeholder="Apellidos *"
                              disabled={updateStatus === STATUS_LOADING}
                            />
                          </div>
                          {this.renderError(errors, 'last_name')}
                        </div>
                      </div>

                      <p
                        className={(user.birthdate && user.birthdate !== "" && user.gender && user.gender !== "") ? "hidden" : ""}>
                        Para ofertas más personalizadas llenar la siguiente información
                      </p>
                      {
                        user.birthdate === null &&
                        <label htmlFor="document_type">Fecha de nacimiento</label>
                      }
                      <div
                        className={user.birthdate !== null && user.birthdate !== "" ? "hidden" : "form-group dropdown"}>
                        <div>
                          <div
                            className="input-group"
                            ref={(input) => {
                              if (input) {
                                this.birthdateInput = input.querySelector('input');
                              }
                            }}
                          >
                            <input
                              className={"form-control"}
                              value={this.state.birthdate}
                              onChange={e => this.setFormValues({birthdate: e.target.value})}
                              type="date"
                              placeholder="Fecha de nacimiento DD/MM/YYYY *"
                            />
                          </div>
                          {this.renderError(errors, 'birthdate')}
                        </div>
                      </div>

                      {(user.mobile_number === null || user.mobile_number === "") &&
                        <label htmlFor="document_type">Código del país</label>
                      }
                      <div
                        className={user.mobile_number !== null && user.mobile_number !== "" ? "hidden" : "form-group dropdown"}>
                        <Select options={options}
                                onChange={e => {
                                  this.setState({
                                    code: e.value,
                                    country_code_number: e.value
                                  });
                                }}
                                defaultValue={defaultValue}
                        />
                        <img src={downarrow} alt="downarrow" className={"down-phone-number-update"} style={{width: 20, height: 20}}/>

                      </div>
                      {(user.mobile_number === null || user.mobile_number === "") &&
                        <label htmlFor="document_type">Número del celular</label>
                      }
                      <div
                        className={user.mobile_number !== null && user.mobile_number !== "" ? "hidden" : "form-group dropdown"}>
                        <div>
                          <div
                            className="input-group"
                            ref={(input) => {
                              if (input) {
                                this.mobileNumberInput = input.querySelector('input');
                              }
                            }}
                          >
                            <input
                              className={"form-control"}
                              value={this.state.mobile_number}
                              onChange={e => this.setFormValues({mobile_number: e.target.value})}
                              type="text"
                              placeholder="Numero Teléfono"
                            />
                          </div>
                          {this.renderError(errors, 'mobile_number')}
                        </div>
                      </div>
                      <div className={user.gender !== null && user.gender !== "" ? "hidden" : "form-group dropdown"}>
                        <label>Género:</label>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="gender"
                            value="f"
                            onChange={e => this.handleOption(e, "f")}
                          />
                          <label className="form-check-label" htmlFor="f">
                            Femenino
                          </label>
                        </div>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="gender"
                            value="m"
                            onChange={e => this.handleOption(e, "m")}
                          />
                          <label className="form-check-label" htmlFor="m">
                            Masculino
                          </label>
                        </div>

                        {this.renderError(errors, 'gender')}
                      </div>

                      {
                        (errors && errors.non_field_errors) &&
                        <div
                          style={{marginTop: 15}}
                        >
                          {this.renderError(errors, 'non_field_errors')}
                        </div>
                      }

                      <div className="submits" style={{marginTop: 30, flexDirection: "column"}}>
                        <button
                          type="button"
                          disabled={!(user.first_name && user.first_name !== "" && user.last_name && user.last_name !== "")}
                          className="btn btn-secondary"
                          onClick={() => skipUpdate()}>
                          Saltar este paso
                        </button>

                        <button
                          type="submit"
                          disabled={updateStatus === STATUS_LOADING}
                          className="btn btn-primary"
                          style={{backgroundColor: `${mainColor}`, border: `2px solid ${mainColor}`}}
                        >
                          {updateStatus === STATUS_LOADING ? BTN_UPDATE_LOADING : BTN_UPDATE}
                        </button>
                      </div>
                    </form>
                  </div>
                </Fragment> :
                <Fragment>
                  <p>Cargando...</p>
                </Fragment>
            }

          </div>
        </div>
      </div>

    );
  }
}

CompleteData.defaultProps = {
  error: null,
  updateStatus: '',
};

CompleteData.propTypes = {
  dispatch: PropTypes.func,
  error: PropTypes.object,
  updateStatus: PropTypes.string
};

