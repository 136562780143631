import React, {Component} from 'react';
import './style.scss';
import $ from "jquery";


export default class PaginationTable extends Component {

  previous(){
    const {getPage, previousPage, pageCurrent, setPageCurrent} = this.props
    setPageCurrent(pageCurrent-1)
    getPage(previousPage)

    $("html, body").animate({ scrollTop: 0 }, "slow")
  }

  next(){
    const {getPage, nextPage, pageCurrent, setPageCurrent} = this.props
    setPageCurrent(pageCurrent+1)
    getPage(nextPage)

    $("html, body").animate({ scrollTop: 0 }, "slow")
  }


  renderPagination() {
    const {count, getNumberPage, pageCurrent, setPageCurrent} = this.props
    let nPage = Math.ceil(count/25)
    let pages = [];

    for (let i = 1; i < nPage+1; i++) {
      if(nPage+1>10){
        if(pageCurrent===i){
          if(pageCurrent===1){
            pages.push(1)
            pages.push(2)
            pages.push(3)
            pages.push("...")
            pages.push(nPage-2)
            pages.push(nPage-1)
            pages.push(nPage)
          }
          if(pageCurrent===2){
            pages.push(1)
            pages.push(2)
            pages.push(3)
            pages.push(4)
            pages.push("...")
            pages.push(nPage-2)
            pages.push(nPage-1)
            pages.push(nPage)
          }
          if(pageCurrent===3){
            pages.push(1)
            pages.push(2)
            pages.push(3)
            pages.push(4)
            pages.push(5)
            pages.push("...")
            pages.push(nPage-2)
            pages.push(nPage-1)
            pages.push(nPage)
          }
          if(pageCurrent===4){
            pages.push(1)
            pages.push(2)
            pages.push(3)
            pages.push(4)
            pages.push(5)
            pages.push(6)
            pages.push("...")
            pages.push(nPage-2)
            pages.push(nPage-1)
            pages.push(nPage)
          }
          if(pageCurrent===5){
            pages.push(1)
            pages.push(2)
            pages.push(3)
            pages.push(4)
            pages.push(5)
            pages.push(6)
            pages.push(7)
            pages.push("...")
            pages.push(nPage-2)
            pages.push(nPage-1)
            pages.push(nPage)
          }
          if(pageCurrent>5 && pageCurrent<nPage-2){
            pages.push(1)
            pages.push(2)
            pages.push("...")
            pages.push(pageCurrent-2)
            pages.push(pageCurrent-1)
            pages.push(pageCurrent)
            pages.push(pageCurrent+1)
            pages.push(pageCurrent+2)
            pages.push("...")
            pages.push(nPage-2)
            pages.push(nPage-1)
            pages.push(nPage)
          }
          if(pageCurrent===nPage-2){
            pages.push(1)
            pages.push(2)
            pages.push(3)
            pages.push("...")
            pages.push(pageCurrent-1)
            pages.push(pageCurrent)
            pages.push(pageCurrent+1)
            pages.push(pageCurrent+2)
          }
          if(pageCurrent===nPage-1){
            pages.push(1)
            pages.push(2)
            pages.push(3)
            pages.push("...")
            pages.push(pageCurrent-1)
            pages.push(pageCurrent)
            pages.push(pageCurrent+1)
          }
          if(pageCurrent===nPage){
            pages.push(1)
            pages.push(2)
            pages.push(3)
            pages.push("...")
            pages.push(pageCurrent-2)
            pages.push(pageCurrent-1)
            pages.push(pageCurrent)
          }
        }
      }else{
        pages.push(i)
      }
    }

    for(let i = pages.length -1; i >=0; i--){
      if(pages.indexOf(pages[i]) !== i){
        if(pages[i]!=="..."){
          pages.splice(i,1);
        }
      }
    }

    return pages.map((page, index) => (
      <li key={`page${index}`}>
        <a href={"/#"}
           className={page===pageCurrent ? "MuiButtonBase-root MuiPaginationItem-root MuiPaginationItem-sizeMedium MuiPaginationItem-outlined MuiPaginationItem-circular Mui-selected MuiPaginationItem-page css-wnp1v-MuiButtonBase-root-MuiPaginationItem-root active"
             : "MuiButtonBase-root MuiPaginationItem-root MuiPaginationItem-sizeMedium MuiPaginationItem-outlined MuiPaginationItem-circular MuiPaginationItem-page css-wnp1v-MuiButtonBase-root-MuiPaginationItem-root"}
           onClick={(e)=>{
             //window.scrollTo({ top: $("#all-categorie-item").offsetTop, behavior: 'smooth' });
             e.preventDefault()
             if(page!==pageCurrent && page!=="..."){
               setPageCurrent(page)
               getNumberPage(page)
             }

             $("html, body").animate({ scrollTop: 0 }, "slow")
              }}>
          {page}
        </a>
      </li>
    ));

  }

  render() {
    const {previousPage, nextPage} = this.props
    return (
      <div className={"MuiPagination-root MuiPagination-outlined css-1oj2twp-MuiPagination-root mt-1 mb-1"}>
        <ul className="MuiPagination-ul css-wjh20t-MuiPagination-ul pl-0">
          <li>
            <button className={previousPage===null ? "MuiButtonBase-root Mui-disabled MuiPaginationItem-root MuiPaginationItem-sizeMedium MuiPaginationItem-outlined MuiPaginationItem-circular Mui-disabled MuiPaginationItem-page css-doyps1-MuiButtonBase-root-MuiPaginationItem-root is-disabled": "MuiButtonBase-root MuiPaginationItem-root MuiPaginationItem-sizeMedium MuiPaginationItem-outlined MuiPaginationItem-circular MuiPaginationItem-page css-doyps1-MuiButtonBase-root-MuiPaginationItem-root"}
                    disabled={previousPage===null} onClick={()=>this.previous()}>
              <svg
                className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiPaginationItem-icon css-g2z002-MuiSvgIcon-root-MuiPaginationItem-icon"
                focusable="false" viewBox="0 0 24 24" aria-hidden="true" data-testid="NavigateBeforeIcon">
                <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>
              </svg>
            </button>
          </li>
          {this.renderPagination()}
          <li>
            <button className={nextPage===null ? "MuiButtonBase-root Mui-disabled MuiPaginationItem-root MuiPaginationItem-sizeMedium MuiPaginationItem-outlined MuiPaginationItem-circular MuiPaginationItem-page css-doyps1-MuiButtonBase-root-MuiPaginationItem-root is-disabled": "MuiButtonBase-root MuiPaginationItem-root MuiPaginationItem-sizeMedium MuiPaginationItem-outlined MuiPaginationItem-circular MuiPaginationItem-page css-doyps1-MuiButtonBase-root-MuiPaginationItem-root"} disabled={nextPage===null}
                    onClick={()=>this.next()}>
              <svg
                className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiPaginationItem-icon css-g2z002-MuiSvgIcon-root-MuiPaginationItem-icon"
                focusable="false" viewBox="0 0 24 24" aria-hidden="true" data-testid="NavigateNextIcon">
                <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
              </svg>
              <span className="MuiTouchRipple-root css-8je8zh-MuiTouchRipple-root"></span></button>
          </li>
        </ul>
      </div>
    )
  }
}