import HttpClient from "../common/utils/httpClient";


export const GET_COUPON= 'GET_COUPON';
export const VALIDATE_COUPON= 'VALIDATE_COUPON';
export const SET_COUPON_CURRENT= 'SET_COUPON_CURRENT';



/**Urls**/
export const COUPON_URL = 'v5/programs/{programUid}/promotions/{promotionUid}/coupon/get-code/';
export const VALIDATE_COUPON_URL = 'v5/companies/{companyUid}/validate-code/{code}/';


export function getCoupon(programUid, promotionUid) {

  let url = COUPON_URL.replace("{programUid}",programUid).replace("{promotionUid}",promotionUid)
  return (dispatch) => HttpClient.post({}, dispatch, GET_COUPON, url);

}


export function validateCoupon(companyUid, code) {

  let url = VALIDATE_COUPON_URL.replace("{companyUid}",companyUid).replace("{code}",code)
  return (dispatch) => HttpClient.get(dispatch, VALIDATE_COUPON, url);

}


export function setCoupon( companyUid, code) {
  return {
    type: SET_COUPON_CURRENT,
    companyUid,
    code
  }
}
