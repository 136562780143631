import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import {Provider} from "react-redux";
import {Route} from 'react-router';
import {BrowserRouter as Router, Switch} from "react-router-dom";
import {applyMiddleware, compose, createStore} from "redux";
import promise from "redux-promise-middleware";
import thunkMiddleware from "redux-thunk";
import logger from "redux-logger";
import 'bootstrap/dist/js/bootstrap';
import 'react-toastify/dist/ReactToastify.css';
import './common/styles/theme.scss';
import reducer from "./reducers";
import {initialState} from "./setup";
import * as Sentry from '@sentry/react';
import App from "./App";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

let version = "1.0.0"

let middlewares = []
if(process.env.NODE_ENV==="development" || process.env.REACT_APP_URL_REST==="https://api.bemaven.xyz/"){
  middlewares = [promise, thunkMiddleware, logger];
}else{
  middlewares = [promise, thunkMiddleware];
}

const store = createStore(
  reducer,
  initialState(),
  composeEnhancers(applyMiddleware(...middlewares)),
);
if(process.env.NODE_ENV==="production")
  Sentry.init({dsn: process.env.REACT_APP_SENTRY_CONFIG_DNS, environment: process.env.NODE_ENV });

if(localStorage.getItem("version")){
  if(version!==localStorage.getItem("version")){
    localStorage.clear()
    localStorage.setItem("version", version)
  }
}else{
  localStorage.clear()
  localStorage.setItem("version", version)
}

ReactDOM.render(
  <Provider store={store}   >
    <Router>
      <Switch>
        {
          localStorage.getItem("version") && version===localStorage.getItem("version") &&
          <Route path="/" component={App}/>
        }
      </Switch>
    </Router>
  </Provider>
  , document.getElementById('root'));

serviceWorker.unregister();
