import {OPEN_SIDEBAR_MENU, STATE_SECONDARY_MENU, OPEN_CART_MENU} from "../actions/menu";


const initialState = {
  activeSidebarMenu: false,
  activeCartMenu: false,
  stateSecondaryMenu: '',
};

export default function app(state = initialState, action) {
  const {type} = action;
  switch (type) {
    case `${OPEN_SIDEBAR_MENU}`:
      return {
        ...state,
        activeSidebarMenu: action.open
      };
    case `${OPEN_CART_MENU}`:
      return {
        ...state,
        activeCartMenu: action.open
      };
    case `${STATE_SECONDARY_MENU}`:
      return {
        ...state,
        stateSecondaryMenu: action.state
      };
    default:
      return state
  }
}