import React from 'react';
import {Switch} from "react-router-dom";
import {Route} from 'react-router';
import ProductContainer from "../products";
import ProductsPlaceListMore from "../products/productsPlace/ProductsPlaceListMore";
import {
  getMoreProductsByPlace,
  getProductCurrent,
  getProductsByPlace,
  getProductsRedeemablesByPlace
} from "../../actions/productsByPlace";
import {getSectionsToProducts} from "../../actions/sectionsToProducts";
import {changeStateSecondaryMenu, SECONDARY_MENU_BUSINESS} from "../../actions/menu";
import {CART_INVISIBLE, CART_PRODUCTS, changeStateCart, setTypeCart} from "../../actions/cart";
import {dismissToRaffle, getRaffles, registerInRaffle} from "../../actions/raffles";
import {getProgram, joinToProgram} from "../../actions/programs";
import {getSections} from "../../actions/sections";
import {getNextPromotions, getPromotions} from "../../actions/promotions";
import {getAds} from "../../actions/ads";
const $ = require('jquery');

export default class ProductRoot extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      companyUid: null,
      adsCompany: []
    }
  }

  componentDidMount() {
    this.props.cleanAdsCompany()
    this.updateComponent()

  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if ( $(".modal-backdrop").length > 1 ) {
      $('.modal-backdrop:first').remove();
    }
    localStorage.removeItem("skipBuyService")
    this.updateComponent()
  }

  updateComponent() {
    let {company, getAdsCompany, app, cleanAdsCompany, adsCompany, multisite, stateCart, changeStateCart} = this.props;
    if(multisite){
      if(app.company.uid!==company.uid){
        if (company.uid && company.uid !== this.state.companyUid) {
          this.setState({companyUid: company.uid})
          getAdsCompany(company.uid)
        }

        if(adsCompany && adsCompany.length!==0 && JSON.stringify(adsCompany)!==JSON.stringify(this.state.adsCompany)){
          this.setState({adsCompany: adsCompany})
          cleanAdsCompany()
        }
      }
    }else{
      if (company.uid && company.uid !== this.state.companyUid) {
        this.setState({companyUid: company.uid})
        getAdsCompany(company.uid)
      }

      if(adsCompany && adsCompany.length!==0 && JSON.stringify(adsCompany)!==JSON.stringify(this.state.adsCompany)){
        this.setState({adsCompany: adsCompany})
        cleanAdsCompany()
      }
    }

    if(stateCart!=="CART_INVISIBLE"){
      changeStateCart()
    }
  }

  render() {
    const {
      match,
      dispatch,
      company,
      authStatus,
      selectedPlace,
      selectPlace,
      selectArea,
      places,
      productsPlace,
      sectionsProducts,
      listPlacesStatus,
      openPopUp,
      activePopUp,
      componentPopUp,
      addProductCart,
      entitiesCart,
      addProductsBack,
      updateProductCart,
      typeCart,
      raffles,
      programs,
      app,
      cleanCart,
      statusAdsCompany,
      cleanAdsCompany,
      multisite,

      //programRoot
      programs: {
        results: programsResults,
        entities: programEntities
      },
      sections,
      promotions,
      //selectedAreaUid,
      ads,
      productsPlace: {
        entities: productsByPlaceEntities,
        results: productsByPlaceResults,
        listProductsByPlaceStatus,
        listNextStatus,
        nextPage,
        count,
        productsByPlaceCurrent,
        productsByPlaceCurrentStatus,
      },
      sectionsProducts: {
        entities: sectionsEntities,
        results: sectionsResults,
        listStatus: sectionsStatus,
      },
    } = this.props;
    let {adsCompany} = this.state
    let cUid = app && app.allowed_programs && app.allowed_programs.length!==0 && app.allowed_programs[0].uid
    return (
      <Switch>
        <Route exact path={`${match.url}`} render={(props) =>
          <ProductContainer
            {...props}
            authStatus={authStatus}
            company={company}
            productsPlace={productsPlace}
            getProductsByPlace={(uidPlace, params = {
              is_redeemable:false
            })=> dispatch(getProductsByPlace(uidPlace,{...params}))}
            getSectionsToProducts={(uidPlace) => dispatch(getSectionsToProducts(uidPlace))}
            getMoreProductsByPlace={(url) => dispatch(getMoreProductsByPlace(url))}
            selectedPlace={selectedPlace}
            sectionsProducts={sectionsProducts}
            listPlacesStatus={listPlacesStatus}
            getProductCurrent={(uidPlace, productUid) => dispatch(getProductCurrent(uidPlace, productUid))}
            openPopUp={openPopUp}
            selectPlace={selectPlace}
            selectArea={selectArea}
            places={places}
            activePopUp={activePopUp}
            componentPopUp={componentPopUp}
            //changeSecondaryMenu={() => dispatch(changeStateSecondaryMenu(SECONDARY_MENU_BUSINESS))}
            addProductCart={addProductCart}
            entitiesCart={entitiesCart}
            addProductsBack ={addProductsBack }
            updateProductCart={updateProductCart}
            //changeStateCart={(state) => dispatch(changeStateCart(state))}
            changeTypeCart={() => dispatch(setTypeCart(CART_PRODUCTS))}
            typeCart={typeCart}

            getRaffles={(uid) => dispatch(getRaffles(uid))}
            dismissToRaffle={(programUid, raffleUid) => dispatch(dismissToRaffle(programUid, raffleUid))}
            raffles={raffles}
            programs={programs}
            app={app}
            cleanCart={cleanCart}

            adsCompany={adsCompany}
            statusAdsCompany={statusAdsCompany}
            cleanAdsCompany={cleanAdsCompany}
            multisite={multisite}

            //programContainer
            getProgram={(uid) => dispatch(getProgram(uid))}
            addProgram={(uid) => dispatch(joinToProgram({program: uid}))}
            getSections={(uid) => dispatch(getSections(uid))}
            getPromotions={(uid, params = {}) => dispatch(getPromotions(uid, {area: localStorage.getItem("selectedAreaUid"),...params}))}
            getMoreProgramPromotions={(url) => dispatch(getNextPromotions(url))}
            programsCurrent={programsResults.map(pUid => programEntities[pUid])}
            changeSecondaryMenu={() => dispatch(changeStateSecondaryMenu(SECONDARY_MENU_BUSINESS))}
            changeStateCart={() => dispatch(changeStateCart(CART_INVISIBLE))}
            selectedAreaUid={localStorage.getItem("selectedAreaUid")}
            sections={sections}
            promotions={promotions}

            getAds={(uid, params = {}) => dispatch(getAds(uid,{...params}))}
            ads={ads}
            registerInRaffle={(programUid, raffleUid) => dispatch(registerInRaffle(programUid, raffleUid))}
            programUid={cUid}

            getProductsRedeemablesByPlace={(uidPlace, params = {
              is_redeemable:true
            })=> dispatch(getProductsRedeemablesByPlace(uidPlace,{...params}))}
          />
        }
        />
        <Route exact path={`${match.url}/more`} render={(props) =>
          <ProductsPlaceListMore
            {...props}
            app={app}
            multisite={multisite}
            sections={sectionsResults && sectionsEntities && sectionsResults.map(
              (cUid) => sectionsEntities[cUid]
            )}
            productsPlace={productsByPlaceResults.map(
              (cUid) => productsByPlaceEntities[cUid]
            )}
            count={count}
            company={company}
            nextPageProducts={nextPage}
            listProductsByPlaceStatus={listProductsByPlaceStatus}
            listNextProductsByPlaceStatus={listNextStatus}
            placeUid={selectedPlace ? selectedPlace.uid : ""}
            selectedPlace={selectedPlace}
            productsByPlaceCurrent={productsByPlaceCurrent}
            productsByPlaceCurrentStatus={productsByPlaceCurrentStatus}
            openPopUp={openPopUp}
            activePopUp={activePopUp}
            componentPopUp={componentPopUp}
            getProductCurrent={(uidPlace, productUid) => dispatch(getProductCurrent(uidPlace, productUid))}
            addProductCart={addProductCart}
            addProductsBack={addProductsBack}
            entitiesCart={entitiesCart}
            authStatus={authStatus}
            updateProductCart={updateProductCart}
            currency={company.currency}
            typeCart={typeCart}
            cleanCart={cleanCart}

            getRaffles={(uid) => dispatch(getRaffles(uid))}
            dismissToRaffle={(programUid, raffleUid) => dispatch(dismissToRaffle(programUid, raffleUid))}
            raffles={raffles}
            programs={programs}
            programsCurrent={programsResults.map(pUid => programEntities[pUid])}
            getProductsByPlace={(uidPlace, params = {
              is_redeemable:false
            })=> dispatch(getProductsByPlace(uidPlace,{...params}))}
            getSectionsToProducts={(uidPlace) => dispatch(getSectionsToProducts(uidPlace))}
            getMoreProductsByPlace={(url) => dispatch(getMoreProductsByPlace(url))}
            listPlacesStatus={listPlacesStatus}
            sectionsStatus={sectionsStatus}
            getProgram={(uid) => dispatch(getProgram(uid))}
            addProgram={(uid) => dispatch(joinToProgram({program: uid}))}
            getSections={(uid) => dispatch(getSections(uid))}
            getPromotions={(uid, params = {}) => dispatch(getPromotions(uid, {area: localStorage.getItem("selectedAreaUid"),...params}))}
            getMoreProgramPromotions={(url) => dispatch(getNextPromotions(url))}
          />
        }/>

      </Switch>
    )
  }
}
