import React, {Component, Fragment} from "react";

export default class PollItem extends Component  {

  render() {
    let {poll, loading,
      openPopUp, clearStatusSendPoll, changeLoadList, read=false
    } = this.props
    return (
      <Fragment>
        {
          loading ?
            <div className={"section poll-load"} >
              <div className="container">
                <div className={"mx-auto col-lg-10 col-md-12 survey-list"}>
                  <div className={"survey-item load"}>
                    <div className={"img"}> </div>
                    <p>
                      <span className={"first-span"}> </span>
                      <span> </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            :
            <button className={"survey-item"}
                    style={read ? {cursor: "inherit"} : {}}
                    onClick={()=>{
              if(!read){
                openPopUp(poll.uid)
                clearStatusSendPoll()
                changeLoadList()
              }
              console.log(poll.uid)
            }}>
              {
                poll.image &&
                <img src={poll.image} alt="background"/>
              }
              <p className={!poll.image ? "data-complete" : ""}>
                <span>{poll.title}</span><br/>
                {poll.message}
              </p>
            </button>
        }
      </Fragment>
    );
  }



}
