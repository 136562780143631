import React, {Fragment} from 'react'
import {STATUS_LOADING, STATUS_SUCCESS} from "../../common/utils/generic";
import moment from "moment";
import {Link} from "react-router-dom";
const $ = require('jquery');

export default class DetailEvent extends React.Component {

  constructor() {
    super();
    this.state = {
      companyUid : "",
      pageCurrent: 1,
      openEventDetail: false,
      eventUid: "",
      bandGet: true,
    }
  }

  componentDidMount() {
    const {
      match: {
        params: { uid },
      },
      changeStateCart,changeSecondaryMenu,
      retrieveEvent
    } = this.props
    retrieveEvent(uid);

    changeStateCart()
    changeSecondaryMenu()
    this.updateComponent()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let { changeSecondaryMenu, changeStateCart, stateCart } = this.props;
    this.updateComponent()
    if(stateCart!=="CART_INVISIBLE"){
      changeSecondaryMenu()
      changeStateCart()
    }
    if ( $(".modal-backdrop").length > 1 ) {
      $('.modal-backdrop:first').remove();
    }
  }

  updateComponent() {
    const {company, getEvents } = this.props
    if(company){
      if(company.uid !==  this.state.companyUid){
        this.setState({companyUid: company.uid })
          getEvents()
      }
    }
  }


  renderStatus = (status) => {
    switch (status) {
      case "received": return "Orden recibida";
      case "delivered": return "Orden entregada";
      case "canceled_by_place": return "Orden Cancelada";
      case "canceled_by_user": return "Orden Cancelada por el usuario";
      case "accepted": return "Orden aceptada";
      case "Aceptada": return "Orden aceptada";
      case "shipped": return "Orden enviada";
      default: return '--'
    }
  }
  formatTime(time) {
    let hours = time ? (time.split(":")[0]) : "";
    let minutes = time ? (time.split(":")[1]) : "";

    let strTime = hours + ':' + minutes;
    return strTime;
  }
  urlText = (text) => {
    //let urlRegex = /(https?:\/\/[^\s]+)/g;
    let newText = ``

    if(text){
      /*
      newText = text.replace(urlRegex, function (url) {
        return `<a target='_blank' href=${url}>${url}</a>`
      })*/
      newText = text
    }
    return newText
  }
  goBack  = (e) => {
    const { history } = this.props;
    e.preventDefault();
    if (history) history.goBack();
  };
  render() {
    let {
      events:{
        formInitial: event,
        retrieveStatus,
      },
    } = this.props
   /* let event = {
      "uid": "o9RK8q",
      "name": "Prueba 1",
      "company_retail": {
        "uid": "Wv6DeW",
        "name": "Fastfox"
      },
      "start_date": "2023-11-10",
      "end_date": "2024-12-10",
      "image": "https://bederr-staging.s3.amazonaws.com/media/events/images/6643fd5f-75b.png",
      "start_time": "09:00:00",
      "end_time": "11:00:00",
      "link": "https://www.bederr.com/",
      "is_active": true,
      "created_at": "2023-11-10T11:06:34.583303-05:00"
    }
    let retrieveStatus=STATUS_SUCCESS */
    console.log(event, "event")
    return (
      <Fragment>
        <section className=" text-center container detail p-0 mb-4">
          {
            retrieveStatus === STATUS_LOADING ?
              <div className={"loading"}>
                <div className={"text-left fw-500 font-xss d-flex align-items-center"}>
                  <button className={" text-grey-500 fw-500 font-xss go-back me-2"}>
                    Eventos</button>
                  > <p className={"m-0 p-0 ms-2 text-loading"}/></div>
                <div className={"d-flex justify-content-between event-contain"}>
                  <div className={"col-lg-4 col-md-6 pe-md-4"}>
                    <div className={"image"}/>
                    <div className={"d-flex date-box mt-4 p-2 rounded-xxl"}>
                      <div className="bg-greylight me-3 p-3 border-light-md rounded-xxl theme-dark-bg">
                        {/*<h5 className="fw-700 font-lg ls-3 text-grey-900 mb-0 text-center"/>*/}
                        <span className="ls-3 d-block font-xsss text-grey-500 fw-500 text-uppercase date"/>
                      </div>
                      <div className={"schedule"}>
                        <p
                          className={"font-xsss fw-500 text-grey-500 text-left d-flex"}>init_hour: <span
                          className={"ms-2"}/></p>
                        <p
                          className={"font-xsss fw-500 text-grey-500 text-left d-flex"}>final_hour: <span
                          className={"ms-4"}/></p>
                      </div>
                    </div>
                    <button type="button" className="mt-4 col-12 btn btn-secondary">
                      inscription</button>
                  </div>
                  <div className={"col-lg-8 col-md-6 ps-md-4 text-left info mt-4 mt-md-0"}>
                    {/*<h3 className={"fw-bold mb-2"}/>*/}
                    <p className={"font-xsss fw-500 text-grey-600 description-event"} style={{width: "100%"}}/>
                    <p className={"font-xsss fw-500 text-grey-600 description-event"} style={{width: "90%"}}/>
                    <p className={"font-xsss fw-500 text-grey-600 description-event"} style={{width: "100%"}}/>
                    <p className={"font-xsss fw-500 text-grey-600 description-event"} style={{width: "90%"}}/>
                    <p className={"font-xsss fw-500 text-grey-600 description-event"} style={{width: "100%"}}/>
                    <p className={"font-xsss fw-500 text-grey-600 description-event"} style={{width: "90%"}}/>
                    <p className={"font-xsss fw-500 text-grey-600 description-event"} style={{width: "100%"}}/>


                    <Fragment>
                      {/*<h3 className={"fw-bold mb-2 mt-4"}/>*/}
                      <p className={"font-xss fw-500 text-grey-600"}/>
                      <div className={"d-flex align-items-center mt-3"}>
                        <p className={"m-0 p-0 ms-2 address-loading"}/>
                        map
                      </div>
                    </Fragment>

                  </div>
                </div>
              </div>
              :
              retrieveStatus === STATUS_SUCCESS &&
              <Fragment>
                <div className={"container detail "}>
                  <section className="card text-center container detail border-0 shadow-xss bg-white p-0">

                    <p className={"text-left fw-500 font-xss "} style={{margin: "0px"}}>
                      <button className={"text-grey-500 fw-500 font-xss go-back"} onClick={(e) => this.goBack(e)}>
                        Eventos </button>
                       <span className={"ml-1 mr-1"}>></span> {event.name}</p>

                  </section>
                </div>
                <div className={"container detail "}>
                  <div className={"d-flex justify-content-between event-contain "}>
                    <div className={"col-lg-4 col-md-6 pe-md-4 card shadow-detail fit-content"}>
                      <div className="m-0 not-shadow bg-white border-0 py-2">
                        <img src={event.image ? event.image : ``} alt="img"
                            />
                      </div>

                      <div className="card-body d-flex p-0 mt-2">
                        <div className="date bg-greylight mr-3 p-2 rounded-xxl theme-dark-bg date-event">
                          <h4 className="font-weight-bold font-lg ls-3 mb-0 text-center">
                          <span
                            className="ls-3 d-block font-xsss text-grey-500 text-uppercase">{moment(event.start_date).format('MMMM')}</span>{moment(event.start_date).format('DD')}</h4></div>
                                  <h2 className="font-weight-bold lh-3 font-xss text-left">{event.name}
                                    <span className={event.address ? "d-flex font-xssss font-weight-normal mt-2 lh-3 text-grey-500" : "d-none"}>
                          {event.address}</span>
                                    <span className="d-flex font-xssss font-weight-normal mt-2 lh-3 text-grey-900">
                          {this.formatTime(event.start_time)}
                        </span>
                        </h2>
                      </div>
                      <div className="card-body p-0 view">
                        {
                          event.link && event.link.length!==0 &&
                          <a href={event.link} target={"_blank"} rel="noopener noreferrer"
                             className="view-more font-weight-bold float-right mt-4 text-uppercase d-inline-block mr-1">Reservar</a>
                        }
                      </div>
                      {
                        event.number_of_registered > 10 &&
                        <div className={"d-flex mt-3 align-items-center"}>
                          <i className={"feather-icon me-3"}>
                            user-check
                          </i>
                          <p
                            className={"fw-bold mb-0"}>{event.number_of_registered} register_user</p>
                        </div>
                      }
                      {
                        event.internal_registration ?
                          <Link to={`/`} type="button"
                                className="mt-4 col-12 btn btn-secondary">
                            inscription</Link> :
                          event.registration_url ?
                            <a href={event.registration_url} target={"__blank"} type="button"
                               className="mb-3 mt-3 fw-700 text-white rounded-xl button-primary font-xsssss text-uppercase ls-3 lh-30 mt-0 text-center d-inline-block p-2 w-100">
                              inscription</a> : <p></p>
                      }
                    </div>
                    <div className={"col-lg-8 col-md-6 ps-4 text-left info card not-center bg-white shadow-xss border-0 py-2 pl-4"}>
                      <h3 className={"font-weight-bold mb-2"}>{event.name}</h3>
                      <p className={"font-xsss fw-500 text-grey-600 description-event"}
                         dangerouslySetInnerHTML={{__html: this.urlText(event.description)}}
                      />
                      {event.price &&
                        <Fragment>
                          <span className={"fw-700 font-sm font-weight-bold d-block mt-2"}>Precio</span>
                          <p className={"font-xss fw-500 text-grey-600"}>{event.price && parseInt(event.price)===0 ? "Gratis" : event.price}</p>
                        </Fragment>
                      }
                      {
                        event.place &&
                        <Fragment>
                      <span
                        className={"fw-700 font-sm font-weight-bold d-block mt-2"}>Lugar del evento</span>
                          <p className={"font-xss fw-500 text-grey-600"}>{event.place}</p>
                          <div className={event.address ? "d-flex align-items-center" : "d-none"}>
                            <p className={"font-xsss fw-500 text-grey-600"}>{event.place}</p>
                            {event.latitude && event.longitude &&
                              <Link
                                to={{pathname: `https://www.google.com/maps/place/${event.latitude},${event.longitude}`}}
                                target="_blank"> map </Link>
                            }
                          </div>
                        </Fragment>
                      }

                      {
                        event.video_url &&
                        <Fragment>
                          <span className={"fw-bold d-block mt-2"}>video</span>
                          <a href={event.video_url} target={"__blank"}>{event.video_url}</a>
                        </Fragment>
                      }
                    </div>
                  </div>
                </div>


                <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel"
                     aria-hidden="true">
                  <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                      <div className="modal-body p-0">

                        <img src={event.image ? event.image : ``}
                             className="img-responsive" alt={"img"}/>
                      </div>
                    </div>
                  </div>
                </div>
              </Fragment>
          }
        </section>

      </Fragment>
    );
  }

}

