 import {
  CLOSE_SESSION,
  GET_USER,
  RECOVERY_PASSWORD,
  RESET_PASSWORD, SEND_DELETE_USER,
  SKIP_UPDATE,
  USER_PATCH,
  VALIDATE_EMAIL,
  CLEAN_DELETE_SEND_USER, VALIDATE_LINK_EXPIRATION,
  GET_PROMOTIONS_DELETE, DELETE_USER, CLEAN_DELETE_STATUS_USER, GET_QR_MEMBER
} from "../actions/user";
import {ERROR_DEFAULT, STATUS_ERROR, STATUS_LOADING, STATUS_SUCCESS} from "../common/utils/generic";
import {normalize, schema} from "normalizr";


const promotionDeleteSchema = new schema.Entity('promotions', {}, {idAttribute: 'name'});
const promotionListDeleteSchema = new schema.Array(promotionDeleteSchema);

const initialState = {
  resetStatus: '',
  errorReset: null,
  errorRecovery: null,
  recoveryStatus: '',
  error: null,
  profileStatus: '',
  user: null,
  updateStatus: '',
  errorUpdate: null,
  validateStatus: '',
  errorValidate: null,
  validateDeleteStatus: '',
  errorValidateDelete: null,

  sendDeleteStatus: null,
  errorSendDelete: null,

  statusDeleteUser: null,

  entities: [],
  results: [],
  nextPage: null,
  previousPage: null,
  listStatus: null,
  count: 0,
};

export default function reducer(state = initialState, action) {
  const {type, payload = {data: null}} = action;
  const {data} = payload;
  switch (type) {

    case `${RECOVERY_PASSWORD}_PENDING`:
      return {
        ...state,
        recoveryStatus: STATUS_LOADING,
        errorRecovery: null,
      };
    case `${RECOVERY_PASSWORD}_REJECTED`:
      return {
        ...state,
        recoveryStatus: STATUS_ERROR,
        errorRecovery: ERROR_DEFAULT,
      };
    case `${RECOVERY_PASSWORD}_FULFILLED`: {

      return {
        ...state,
        recoveryStatus: payload.status === 200 ? STATUS_SUCCESS : STATUS_ERROR,
        errorRecovery: payload.status !== 200 ? (payload.status === 400 ? data : ERROR_DEFAULT) : null
      };
    }

    case `${GET_PROMOTIONS_DELETE}_PENDING`:
      return {
        ...state,
        listStatus: STATUS_LOADING,
        count: 0,
        entities: null,
        nextPage: null,
        results: []
      };
    case `${GET_PROMOTIONS_DELETE}_REJECTED`:
      return {
        ...state,
        listStatus: STATUS_ERROR,
      };
    case `${GET_PROMOTIONS_DELETE}_FULFILLED`:
      let dataAux = payload.status === 200 ? normalize(payload.data.results, promotionListDeleteSchema) : {};
      return {
        ...state,
        entities: dataAux.entities ? dataAux.entities.promotions : [],
        results: dataAux.result ? dataAux.result : [],
        nextPage: payload.data ? payload.data.next : null,
        previousPage: payload.data ? payload.data.previous : null,
        listStatus: payload.status === 200 ? STATUS_SUCCESS : STATUS_ERROR,
        count: payload.data ? payload.data.count : 0,
      };

    case `${GET_USER}_PENDING`:
      return {
        ...state,
        profileStatus: STATUS_LOADING,
        errorRecovery: null,
      };
    case `${GET_USER}_REJECTED`:
      return {
        ...state,
        profileStatus: STATUS_ERROR,
        errorRecovery: ERROR_DEFAULT,
      };
    case `${GET_USER}_FULFILLED`: {
      return {
        ...state,
          profileStatus: (payload.status === 200 || payload.status === 201) ? STATUS_SUCCESS : STATUS_ERROR,
          user: data ? data : null
      };
    }

    case `${GET_QR_MEMBER}_PENDING`:
      return {
        ...state,
        qrStatus: STATUS_LOADING,
        errorQR: null,
      };
    case `${GET_QR_MEMBER}_REJECTED`:
      return {
        ...state,
        qrStatus: STATUS_ERROR,
        errorQR: ERROR_DEFAULT,
      };
    case `${GET_QR_MEMBER}_FULFILLED`: {
      return {
        ...state,
        qrStatus: (payload.status === 200 || payload.status === 201) ? STATUS_SUCCESS : STATUS_ERROR,
          qrMember: data ? data : null
      };
    }

    case `${SEND_DELETE_USER}_PENDING`:
      return {
        ...state,
        sendDeleteStatus: STATUS_LOADING,
        errorSendDelete: null,
      };
    case `${SEND_DELETE_USER}_REJECTED`:
      return {
        ...state,
        sendDeleteStatus: STATUS_ERROR,
        errorSendDelete: ERROR_DEFAULT,
      };
    case `${SEND_DELETE_USER}_FULFILLED`: {
      return {
        ...state,
        sendDeleteStatus: (payload.status === 200 || payload.status === 201) ? STATUS_SUCCESS : STATUS_ERROR,
        errorSendDelete: null,
      };
    }


    case `${RESET_PASSWORD}_PENDING`:
      return {
        ...state,
        resetStatus: STATUS_LOADING,
      };
    case `${RESET_PASSWORD}_REJECTED`:
      return {
        ...state,
        resetStatus: STATUS_ERROR,
      };
    case `${RESET_PASSWORD}_FULFILLED`: {
      return {
        ...state,
        resetStatus: (payload.status === 200 || payload.status === 201) ? STATUS_SUCCESS : STATUS_ERROR,
        error: payload.status !== 200 ? (payload.status === 400 ? data : ERROR_DEFAULT) : null,
      };
    }

    case `${USER_PATCH}_PENDING`:
      return {
        ...state,
        updateStatus: STATUS_LOADING,
      };
    case `${USER_PATCH}_REJECTED`:
      return {
        ...state,
        updateStatus: STATUS_ERROR,
      };
    case `${USER_PATCH}_FULFILLED`: {
      localStorage.setItem('skipUpdate', 'skip');
      return {
        ...state,
        updateStatus: (payload.status === 200 || payload.status === 201) ? STATUS_SUCCESS : STATUS_ERROR,
        errorUpdate: payload.status !== 200 ? (payload.status === 400 ? data : ERROR_DEFAULT) : null,
      };
    }


    case `${VALIDATE_EMAIL}_PENDING`:
      return {
        ...state,
        validateStatus: STATUS_LOADING,
      };
    case `${VALIDATE_EMAIL}_REJECTED`:
      return {
        ...state,
        validateStatus: STATUS_ERROR,
      };
    case `${VALIDATE_EMAIL}_FULFILLED`: {
      return {
        ...state,
        validateStatus: (payload.status === 200 || payload.status === 201) ? STATUS_SUCCESS : STATUS_ERROR,
        errorValidate: payload.status !== 200 ? (payload.status === 400 ? data : ERROR_DEFAULT) : null
      };
    }

    case `${VALIDATE_LINK_EXPIRATION}_PENDING`:
      return {
        ...state,
        validateDeleteStatus: STATUS_LOADING,
      };
    case `${VALIDATE_LINK_EXPIRATION}_REJECTED`:
      return {
        ...state,
        validateDeleteStatus: STATUS_ERROR,
      };
    case `${VALIDATE_LINK_EXPIRATION}_FULFILLED`: {
      return {
        ...state,
        validateDeleteStatus: (payload.status === 200 || payload.status === 201) ? STATUS_SUCCESS : STATUS_ERROR,
        errorValidateDelete: payload.status !== 200 ? (payload.status === 400 ? data : ERROR_DEFAULT) : null
      };
    }

    case `${DELETE_USER}_PENDING`:
      return {
        ...state,
        statusDeleteUser: STATUS_LOADING,
      };
    case `${DELETE_USER}_REJECTED`:
      return {
        ...state,
        statusDeleteUser: STATUS_ERROR,
      };
    case `${DELETE_USER}_FULFILLED`: {
      if((payload.status === 200 || payload.status === 201 || payload.status === 204)){
        localStorage.removeItem('token');
        localStorage.removeItem('merge');
        localStorage.removeItem('skipUpdate');
        Object.keys(localStorage).map( item => {
          if(item.includes('cart') && !item.includes('cartSubscription'))
            localStorage.removeItem(item);
          return true;
        })
      }
      return {
        ...state,
        statusDeleteUser: (payload.status === 200 || payload.status === 201 || payload.status === 204) ? STATUS_SUCCESS : STATUS_ERROR,
      };
    }

    case `${CLEAN_DELETE_STATUS_USER}`:
      return {
        ...state,
        statusDeleteUser: null,
      };

    case `${CLEAN_DELETE_SEND_USER}`:
      return {
        ...state,
        sendDeleteStatus: null,
        errorSendDelete: null,
      };
    case `${SKIP_UPDATE}`:
      localStorage.setItem('skipUpdate', 'skip');
      return {
        ...state,
      };
    case `${CLOSE_SESSION}`: {
      localStorage.removeItem('token');
      localStorage.removeItem('merge');
      localStorage.removeItem('skipUpdate');
      Object.keys(localStorage).map( item => {
        if(item.includes('cart') && !item.includes('cartSubscription'))
          localStorage.removeItem(item);
        return true;
      })
      window.location.replace("/");
      return {
        user: null,
        profileStatus: '',
        error: null,
      };
    }
    default:
      return state;
  }
}
