import React, {Fragment} from 'react'
import EventTable from "./EventTable/EventTable";
import Banner from "../../bederr/Banner";
const $ = require('jquery');

export default class ListEvents extends React.Component {

  constructor() {
    super();
    this.state = {
      companyUid : "",
      pageCurrent: 1,
      openEventDetail: false,
      eventUid: "",
      bandGet: true,
    }
  }

  componentDidMount() {
    const {
      changeStateCart,changeSecondaryMenu
    } = this.props
    
    changeStateCart()
    changeSecondaryMenu()
    this.updateComponent()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let { changeSecondaryMenu, changeStateCart, stateCart } = this.props;
    this.updateComponent()
    if(stateCart!=="CART_INVISIBLE"){
      changeSecondaryMenu()
      changeStateCart()
    }
    if ( $(".modal-backdrop").length > 1 ) {
      $('.modal-backdrop:first').remove();
    }
  }

  updateComponent() {
    const {company, getEvents } = this.props
    if(company){
      if(company.uid !==  this.state.companyUid){
        this.setState({companyUid: company.uid })
          getEvents()
      }
    }
  }


  renderStatus = (status) => {
    switch (status) {
      case "received": return "Orden recibida";
      case "delivered": return "Orden entregada";
      case "canceled_by_place": return "Orden Cancelada";
      case "canceled_by_user": return "Orden Cancelada por el usuario";
      case "accepted": return "Orden aceptada";
      case "Aceptada": return "Orden aceptada";
      case "shipped": return "Orden enviada";
      default: return '--'
    }
  }
  render() {
    let {
      user,
      events:{
        entities: eventsEntities,
        results: eventsResults,
        listStatus,
        listNextStatus,
        nextPage,
        previousPage,
        count,
      },
      getEvents,
      getNumberPageEvents,
      app,
      company,
      programs: {
        entity: program,
        addStatus,
        retrieveStatus: retrieveStatusProgram,
      },
      selectedPlace,
      history
    } = this.props
    return (
      <Fragment>
        <Banner
          history={history}
          app={app}
          company={company}
          addStatus={addStatus}
          retrieveStatus={retrieveStatusProgram}
          program={program}
          selectedPlace={selectedPlace}
        />

        <EventTable
          user={user}
          events={eventsResults.map(cUid => eventsEntities[cUid])}
          count = {count}
          nextPage={nextPage}
          previousPage={previousPage}
          listStatus={listStatus}
          listNextStatus={listNextStatus}
          getEvents={(params) => {getEvents(params)}}
          getNumberPage={(numberPage) => getNumberPageEvents(numberPage)}
          pageCurrent={this.state.pageCurrent}
          setPageCurrent={pageCurrent=>this.setState({pageCurrent:pageCurrent})}
        />

      </Fragment>
    );
  }

}

