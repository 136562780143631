import React, { Fragment } from "react";
import PropTypes from "prop-types";
import "./style.scss";
import {
  CARD_METHOD,
  DELIVERY_METHOD,
  PICKUP_METHOD,
  PLACE_METHOD,
} from "../constants";
import CulqiPayment from "./CulqiPayment";
import { toast } from "react-toastify";
import { STATUS_LOADING, STATUS_SUCCESS } from "../../../common/utils/generic";
import { CART_SUBSCRIPTION } from "../../../actions/cart";
import moment from "moment";
import CoinSvg from "../../../common/images/Coin.svg";
import creditCardIcon from "../../../common/images/creditCard.svg";
import receiptIcon from "../../../common/images/receipt.svg";

export default class SummaryPaymentCart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      code: "",
      companyUid: "",
      paymentMethodSelected: null,
      deliveryMethod: "",
      loadPaymentMethod: false,
    };
    this.payError = this.payError.bind(this);
    this.pay = this.pay.bind(this);
    this.selectPaymentMethod = this.selectPaymentMethod.bind(this);
  }

  componentDidMount() {
    let { coupon, validateCode, companyUid, cleanCode, ItemExchange } =
      this.props;
    if (ItemExchange === "true") {
      this.setState({
        paymentMethodSelected: {
          description: "Pago con coins del usuario",
          name: "Coins",
          type: "coins",
          uid: "0WpJ9Y",
        },
      });
    }

    if (coupon !== null && coupon.couponCurrent !== null) {
      if (companyUid === coupon.companyCouponUid) {
        this.setState({
          code: coupon.couponCurrent,
          companyUid: coupon.companyCouponUid,
        });
        validateCode(companyUid, coupon.couponCurrent);
      } else {
        cleanCode();
      }
    }
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    let {
      orderErrors,
      cleanPaymentCart,
      cleanPaymentCartSubscription,
      selectedPlace,
      ItemExchange,
    } = this.props;
    if (orderErrors && orderErrors.delivery_service) {
      toast.error(orderErrors.delivery_service[0]);
      cleanPaymentCart();
      cleanPaymentCartSubscription();
    }
    if (orderErrors !== null && orderErrors && orderErrors.coins) {
      toast.error("No tienes suficientes monedas");
      cleanPaymentCart();
    }
    if (!this.state.loadPaymentMethod && selectedPlace !== null) {
      if (ItemExchange==="true") {
        this.selectPaymentMethod({
          description: "Pago con coins del usuario",
          name: "Coins",
          type: "coins",
          uid: "0WpJ9Y",
        });
      } else {
        this.selectPaymentMethod(selectedPlace.payment_methods.filter(p=>p.type!=="coins")[0]);
      }
      this.setState({ loadPaymentMethod: true });
    }
  }

  selectPaymentMethod = (paymentMethod) => {
    this.setState({ paymentMethodSelected: paymentMethod });
  };
  payError = () => {
    toast.error("Tu tarjeta no ha podido ser procesada correctamente");
  };
  pay = (token) => {
    let { paymentMethodSelected } = this.state;
    if (paymentMethodSelected)
      this.order({
        token: token.id,
        payment_method: paymentMethodSelected.uid,
      });
  };
  order = (extra) => {
    let {
      deliveryMethod,
      infoDelivery,
      selectedLocality,
      infoForm,
      infoPickUp,
      infoFrecuency,
      infoSubscriptionDetail,
      infoIntervalsRangeSubscription,
      codeErrors,
      items,
      user,
      codeStatus,
      sendOrder,
      typeCart,
      commentary,
      programsCurrent,
      selectedPlace,
      areDigitalProducts,
      app,
    } = this.props;

    let payload = { ...extra };
    //add info user
    if (infoForm) {
      let payloadInfoForm = Object.assign({}, infoForm);
      let phone = payloadInfoForm.phone_number;
      if (phone.length > 0) {
        if (phone[0] !== "+") {
          phone = `+51${phone}`;
          payloadInfoForm.phone_number = phone;
        } else {
          payloadInfoForm.phone_number = phone;
        }
      }
      delete payloadInfoForm.userUid;
      delete payloadInfoForm.isValid;
      payload.email = payloadInfoForm.email;
      delete payloadInfoForm.email;
      let aux = "";
      if (app && app.countries) aux = app.countries[0].name;
      if (aux === "México") {
        payloadInfoForm.country_code = "MX";
      } else {
        payloadInfoForm.country_code = "PE";
      }
      payload.antifraud_details = { ...payloadInfoForm };

      payload.contact_phone = phone;
    } else {
      return false;
    }

    //delivery method
    if (!areDigitalProducts) {
      if (deliveryMethod) {
        payload.delivery_method = deliveryMethod.uid;
        if (deliveryMethod.type === PICKUP_METHOD) {
          payload.selected_interval = { ...infoPickUp.selectedInterval };
        } else if (deliveryMethod.type === DELIVERY_METHOD) {
          if (this.props.delivery_service) {
            // console.log(this.props.delivery_service)
            // payload.delivery_service = this.props.delivery_service.uid
            payload.location = JSON.parse(localStorage.getItem("location"));
            payload.shipping_address = localStorage.getItem("address");
            payload.reference_address = localStorage.getItem("reference");
          } else {
            //payload.delivery_locality = selectedLocality ? selectedLocality.uid : "";
            payload.uid_area = selectedLocality ? selectedLocality.uid : "";
            payload.shipping_address = infoDelivery.address;
            payload.reference_address = infoDelivery.reference;
          }
        }
      } else {
        return false;
      }
    }

    if (typeCart === CART_SUBSCRIPTION) {
      if (infoFrecuency && selectedPlace) {
        let frecuency = [];
        infoFrecuency.subscriptionsDaysSelected.map((d, index) => {
          if (d) {
            if (selectedPlace.subscriptions_days_enabled.includes(index + 1)) {
              frecuency.push(index + 1);
            }
          }
          return true;
        });
        payload.frequency = frecuency;
      }
      if (infoIntervalsRangeSubscription) {
        payload.delivery_scheduled_interval =
          infoIntervalsRangeSubscription.selectedInterval.uid;
      }
      if (items) {
        let payloadSubscriptionItems = [];

        items.map((item) => {
          payloadSubscriptionItems.push({
            product: item.uid,
            quantity: item.quantity,
          });
          return true;
        });

        /*
        items.map(item=>{
          let product = item.uid
          let quantity = item.quantity
          let option_groups = []
          if(item.option_groups.length!==0) {
            item.option_groups.map(group=>{
              let uid = group.uid
              let options = []
              group.options.map(option=>{
                options.push({uid: option.uid})
                return true
              })
              option_groups.push({
                uid: uid,
                options: options
              })
              return true
            })
          }
          payloadSubscriptionItems.push({
            product: product,
            quantity: quantity,
            option_groups: option_groups
          })
          return true
        })

         */
        payload.subscription_products = payloadSubscriptionItems;
      }
      if (infoSubscriptionDetail) {
        payload.name = infoSubscriptionDetail.name;
      }
      payload.starts_date = moment().add("days", 1).format("YYYY-MM-DD");
      payload.ends_date = moment().add("days", 8).format("YYYY-MM-DD");
      payload.installments = 1;
      delete payload.selected_interval;
    }

    //products user anonymous
    if (!user) {
      let order_products = [];
      items.map((item) => {
        let product = item.uid;
        let quantity = item.quantity;
        let option_groups = [];
        if (item.option_groups.length !== 0) {
          item.option_groups.map((group) => {
            let uid = group.uid;
            let options = [];
            group.options.map((option) => {
              options.push({ uid: option.uid });
              return true;
            });
            option_groups.push({
              uid: uid,
              options: options,
            });
            return true;
          });
        }
        order_products.push({
          product: product,
          quantity: quantity,
          option_groups: option_groups,
        });
        return true;
      });
      payload.order_products = order_products;
    }

    //code
    if (!codeErrors && codeStatus === STATUS_SUCCESS) {
      payload.coupon_code = this.state.code;
    }

    if (commentary !== "") {
      payload.comments = commentary;
    }

    // TODO first program
    if (programsCurrent.length !== 0) {
      payload.program = programsCurrent[0].uid;
    } else {
      if (app && programsCurrent.length === 0) {
        payload.program = app.allowed_programs[0].uid;
      }
    }

    payload.delivery = localStorage.getItem("delivery")
    console.log(selectedPlace,selectedLocality,selectedPlace.price,   localStorage.getItem("delivery"), "-----")

    if(selectedPlace.delivery.toLowerCase()==="specific"){
      if (selectedLocality) {
        payload.price = selectedLocality.price;
      }
    }else{
      if(selectedPlace.delivery.toLowerCase()==="general"){
        payload.price = selectedPlace.price;
      }
    }
    sendOrder(payload);
    console.log(payload, "sendOrder")
  };

  setFormValues = (propertiesToUpdate) => {
    let { cleanCode } = this.props;
    if (propertiesToUpdate.code) {
      cleanCode();
    }
    this.setState(propertiesToUpdate);
  };

  selectDeliveryMethod = (deliveryMethod) => {
    this.setState({ deliveryMethod });
  };

  render() {
    let {
      items,
      orderStatus,
      orderSubscriptionStatus,
      publicKey,
      logo,
      validateCode,
      codeStatus,
      codeSuccessBody,
      codeErrors,
      activeButton,
      btnTitle,
      selectedPlace,
      infoDelivery,
      selectedLocality,
      deliveryMethod,
      currency,
      companyUid,
      infoFrecuency,
      ItemExchange,
      statusCartBack
    } = this.props;
    let { paymentMethodSelected } = this.state;

    let haveDelivery = (selectedPlace && selectedPlace.delivery && selectedPlace.delivery.length!==0 && selectedPlace.delivery.toLowerCase()!=="none")
   console.log(haveDelivery, "aaaa")
    let total = 0.0;
    let subtotal = 0.0;
    let taxes = 0.0;
    let discount = 0.0;

    items.forEach((item) => {
      let extras = 0.0;
      if (
        item.option_groups.length !== 0 &&
        item.option_groups[0].options !== undefined
      ) {
        item.option_groups.map((option_group, index) => {
          option_group.options.map((o) => {
            extras += parseFloat(o.price);
            return true;
          });
          return true;
        });
      }

      let tax = 0.0;
      item.tax_affectations &&
        item.tax_affectations.forEach((t) => (tax += t.percentage));
      taxes += item.quantity * ((tax * item.unit_price) / 100);
      subtotal +=
        item.quantity * item.unit_price -
        item.quantity * ((tax * item.unit_price) / 100) +
        extras * item.quantity;
      total += item.quantity * item.unit_price + extras * item.quantity;
    });

    if (codeSuccessBody && codeSuccessBody.rate_discount) {
      discount = (total * codeSuccessBody.rate_discount) / 100;
      total = total - discount;
    }

    let delivery = 0.0;
    console.log(selectedPlace, "selectedPlaceselectedPlace", deliveryMethod)
    if (deliveryMethod && deliveryMethod.type === DELIVERY_METHOD) {
      if(selectedPlace.delivery.length!==0 && selectedPlace.delivery.toLowerCase()!=="none"){
        if(selectedPlace.delivery.toLowerCase()==="specific"){
          if (selectedLocality) {
            delivery = selectedLocality.price;
            total += delivery;
          }
        }else{
          if(selectedPlace.delivery.toLowerCase()==="general"){
            total += selectedPlace.price;
          }
        }
      }else {
        total += 0
      }

    } else {
      if (deliveryMethod && deliveryMethod.type === PICKUP_METHOD) {
        total -= delivery;
      }
    }
    if (infoFrecuency && infoFrecuency.subscriptionsDaysSelected) {
      let frecuency = 0;
      infoFrecuency.subscriptionsDaysSelected.map((s) => {
        if (s === true) {
          frecuency += 1;
        }
        return true;
      });
      total = total * frecuency;
    }

    if (statusCartBack===STATUS_SUCCESS && items.length === 0) {
      return (
        <div
          className={"card detail  prepayment  col-10 col-lg-10 mx-auto mt-4"}
          style={{ borderBottom: 0 }}
        >
          <div className={"title-bar-section d-flex align-items-center"}>

            <img
              src={creditCardIcon}
              alt={"cart"}
              style={{
                height: 30,
                width: 30,
                marginBottom: 4,
                marginRight: 6,
                marginLeft: 0,
              }}
            />
            <h6 className={""}>Resumen</h6>
          </div>
          <div className={" p-0"}>
            <div className={"col pl-0"}>
              <p style={{fontWeight: "500", fontSize: "1rem"}}>
                Tienes tu carrito de compras vacío
              </p>
            </div>
          </div>
        </div>
      );
    }
    
    return (
            <div
              className={
                "card detail  prepayment prepayment-exchange col-10 col-lg-10 mx-auto mt-4"
              }
              style={{borderBottom: 0}}
            >
              <div className={"title-bar-section"}>
                <img
                  src={creditCardIcon}
                  alt={"cart"}
                  style={{
                    height: 30,
                    width: 30,
                    marginBottom: 4,
                    marginRight: 6,
                    marginLeft: 0,
                  }}
                />
                <h6 className={""}>Método de pago</h6>
              </div>
              {
                ItemExchange === "true" && !activeButton ?
                  <p>No cuenta con los coins suficientes para canjear este producto</p> :
                  <Fragment>

                    <div className={"col-12 p-0"}>
                      {selectedPlace && (
                        <div
                          className="btn-group payment"
                          role="group"
                          aria-label="options"
                        >
                          {ItemExchange === "true" ? (
                            <Fragment>
                              <button
                                key={`dm-coins`}
                                type="button"
                                className={"btn btn-primary btn-dm"}
                              >
                                Coins
                              </button>
                            </Fragment>
                          ) : (
                            <Fragment>
                              {selectedPlace.payment_methods.map((dm) => (
                                <button
                                  onClick={(e) => this.selectPaymentMethod(dm)}
                                  key={`dm${dm.uid}`}
                                  disabled={
                                    paymentMethodSelected &&
                                    paymentMethodSelected.uid === dm.uid
                                  }
                                  type="button"
                                  className={
                                    paymentMethodSelected ?
                                      dm.type === "coins" ? "d-none" :
                                        paymentMethodSelected.uid === dm.uid
                                          ? "btn btn-primary btn-dm"
                                          : "btn btn-secondary btn-dm" : ""
                                  }
                                >
                                  {dm.type === "in_place" ? (haveDelivery) ? "Pago a la entrega" : "Pago en tienda" : dm.name}
                                </button>
                              ))}
                            </Fragment>
                          )}
                        </div>
                      )}
                    </div>

                    {(
                      <Fragment>
                        <div className={"title-bar-section mt-2"}>
                          <img
                            src={receiptIcon}
                            alt={"cart"}
                            style={{
                              height: 30,
                              width: 30,
                              marginBottom: 4,
                              marginRight: 6,
                              marginLeft: 0,
                            }}
                          />
                          <h6 className={""}>Resumen</h6>
                        </div>
                      </Fragment>
                    )}

                    <div className={"pl-0"}>
                      {(
                        <Fragment>
                          <div
                            className={"col-12 mx-auto pl-0 d-flex justify-content-between"}
                          >
                            <div className={"col pl-0"}>Subtotal</div>
                            <div className={"mr-auto price font-weight-bold"}>
                              {ItemExchange === "true" ? "c" : currency ? currency.symbol : "S/."}
                              {(Math.round(subtotal * 100) / 100).toFixed(2)}
                            </div>
                          </div>
                          <div
                            className={"col-12 mx-auto pl-0 d-flex justify-content-between"}
                          >
                            <div className={"col pl-0"}>Taxes</div>
                            <div className={"mr-auto price  font-weight-bold"}>
                              {/* {currency ? currency.symbol : "S/."} */}
                              {ItemExchange === "true" ? "c" : currency ? currency.symbol : "S/."}
                              {(Math.round(taxes * 100) / 100).toFixed(2)}
                            </div>
                          </div>
                          {((selectedPlace && selectedPlace.delivery && selectedPlace.delivery.length !== 0 && selectedPlace.delivery.toLowerCase() !== "none") || infoDelivery.selectedLocality) &&
                            deliveryMethod.type === DELIVERY_METHOD && (
                              <div
                                className={
                                  "col-12 mx-auto pl-0 d-flex justify-content-between"
                                }
                              >
                                <div className={"col pl-0"}>Delivery</div>
                                <div className={"mr-auto price  font-weight-bold"}>
                                  {/* {currency ? currency.symbol : "S/."} */}
                                  {ItemExchange === "true" ? "c" : currency ? currency.symbol : "S/."}
                                  {selectedPlace.delivery && selectedPlace.delivery.toLowerCase() === "general" ?
                                    Math.round(selectedPlace.price) :
                                    selectedPlace.delivery && selectedPlace.delivery.toLowerCase() === "specific" &&
                                    selectedLocality ?
                                      (Math.round(selectedLocality.price * 100) / 100).toFixed(2) : 0
                                  }
                                </div>
                              </div>
                            )}
                          <div
                            className={
                              "col-12 mx-auto pl-0 d-flex justify-content-between "
                            }
                          >
                            <div className={"col pl-0"}>Descuento</div>
                            <div className={"mr-auto price  font-weight-bold"}>
                              - {ItemExchange === "true" ? "c" : currency ? currency.symbol : "S/."}
                              {(Math.round(discount * 100) / 100).toFixed(2)}
                            </div>
                          </div>
                          <hr/>
                          <div
                            className={
                              "total col-12 mx-auto pl-0 d-flex justify-content-between  pb-3"
                            }
                          >
                            <div className={"col pl-0"}>Total</div>
                            <div className={"mr-auto price  font-weight-bold"}>
                              {ItemExchange === "true" ? "c" : currency ? currency.symbol : "S/."}
                              {(Math.round(total * 100) / 100).toFixed(2)}
                            </div>
                          </div>
                          {
                            false && (
                              <div
                                className={
                                  "form-group form col-12 mx-auto pl-0 d-flex justify-content-between"
                                }
                              >
                                <div className={"col pl-0"}>
                                  <div className="input-group">
                                    <input
                                      className={"form-control"}
                                      name={"discount"}
                                      value={this.state.code}
                                      maxLength={6}
                                      disabled={codeStatus === STATUS_LOADING}
                                      onChange={(e) =>
                                        this.setFormValues({code: e.target.value})
                                      }
                                      placeholder="Codigo de descuento"
                                    />
                                  </div>
                                  {codeErrors && (
                                    <small>
                                      {codeErrors["non_field_errors"]} {codeErrors.detail}
                                    </small>
                                  )}
                                </div>
                                <div className={"mr-auto price  font-weight-bold"}>
                                  <button
                                    className={"btn"}
                                    disabled={codeStatus === STATUS_LOADING}
                                    onClick={() => {
                                      if (this.state.code !== "" && companyUid !== "")
                                        validateCode(companyUid, this.state.code);
                                    }}
                                  >
                                    Validar
                                  </button>
                                </div>
                              </div>
                            )
                          }
                        </Fragment>
                      )}
                      <div className={"row mb-4"}>
                        <div className={"col"}>
                          <div className={"actions"}>
                            {paymentMethodSelected &&
                            paymentMethodSelected.type === CARD_METHOD ? (
                              publicKey !== "" ? (
                                <CulqiPayment
                                  paymentStatus={
                                    orderStatus ? orderStatus : orderSubscriptionStatus
                                  }
                                  publicKey={publicKey}
                                  enabled={activeButton}
                                  price={(Math.round(total * 100) / 100).toFixed(2)}
                                  logo={logo}
                                  title={"Pagar orden"}
                                  btnTitle={btnTitle}
                                  pay={this.pay}
                                  payError={this.payError}
                                />
                              ) : (
                                <p>
                                  El comercio no cuenta con pagos mediante tarjeta de
                                  crédito / tarjeta de débito
                                </p>
                              )
                            ) : paymentMethodSelected &&
                            paymentMethodSelected.type === PLACE_METHOD ? (
                              <button
                                className={"btn btn-primary"}
                                disabled={
                                  !activeButton ||
                                  orderStatus === STATUS_LOADING ||
                                  orderSubscriptionStatus === STATUS_LOADING
                                }
                                onClick={(e) =>
                                  this.order({payment_method: paymentMethodSelected.uid})
                                }
                              >
                                {btnTitle}
                              </button>
                            ) : paymentMethodSelected &&
                            paymentMethodSelected.type === "coins" ? (
                              <button
                                className={
                                  ItemExchange === "true"
                                    ? !activeButton
                                      ? "btn btn-redemptions-pay disabled-coin"
                                      : "btn btn-redemptions-pay"
                                    : "btn btn-primary"
                                }
                                disabled={
                                  !activeButton ||
                                  orderStatus === STATUS_LOADING ||
                                  orderSubscriptionStatus === STATUS_LOADING
                                }
                                onClick={(e) =>
                                  this.order({payment_method: paymentMethodSelected.uid})
                                }
                              >
                                {ItemExchange === "true" ? (
                                  <div className="box-redemptions-pay">
                                    <h3>Canjear</h3>
                                    <p className="coin font-weight-bold">
                                      <img src={CoinSvg} alt="coin"/>{" "}
                                      {items &&
                                        items.length > 0 &&
                                        Math.round(items[0].unit_price)}{" "}
                                    </p>
                                  </div>
                                ) : (
                                  btnTitle
                                )}
                              </button>
                            ) : (
                              <Fragment>
                                <p>**Debe elegir un método de pago</p>
                                <button className={"btn btn-primary"} disabled={true}>
                                  {btnTitle}
                                </button>
                              </Fragment>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Fragment>
              }
            </div>


    );
  }
}

SummaryPaymentCart.propTypes = {
  items: PropTypes.array,
  user: PropTypes.object,
  sendOrder: PropTypes.func,
  activeButton: PropTypes.bool,
};
