import HttpClient from "../common/utils/httpClient";


export const GET_PROMOTION_PLACES= 'GET_PROMOTION_PLACES';



/**Urls**/
export const LIST_PLACES_PROMOTIONS_URL = 'v5/me/promotions/{promotionUid}/places/';



export function getPromotionPlaces(promotionUid) {

  let url = LIST_PLACES_PROMOTIONS_URL.replace("{promotionUid}",promotionUid)
  return (dispatch) => HttpClient.get(dispatch, GET_PROMOTION_PLACES, url);

}


