import React, { Fragment } from "react";
import PropTypes from "prop-types";
import CoinSvg from "../../../../../common/images/Coin.svg";
import ImageDefault from "../../../../../common/images/placeholder.jpg";
import "./style.scss";
import ModalProductCarta from "./ModalProductCarta";

export default class Product extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modalCarta: false,
    };
  }

  openModal = (e, product) => {
    e.preventDefault();

    const { notOpenModal = false } = this.props;
    if (!notOpenModal) {
      const { openPopUp, setProductUid } = this.props;
      if (product.stock !== 0) {
        openPopUp(true, "productPopUp");
        console.log(product, "product--");
        setProductUid(product.uid);
      }
    }
  };

  clickModalCarta = () => {
    this.setState({
      modalCarta: !this.state.modalCarta,
    });
  };

  render() {
    let {
      product,
      is_detail,
      loading,
      currency,
      newStyle,
      notOpenModal = false,
      carta = false,
      count=4
    } = this.props;
    //console.log(product,"product");
    let style_class = is_detail
      ? product && product.stock === 0
        ? "product-card no-hover col-sm-12 no-event"
        : "product-card no-hover col-sm-12"
      : product && product.stock === 0
      ? "product-card no-event"
      : notOpenModal
      ? "product-card cursor-inherit"
      : count>3 ? "product-card" : "product-card mr-4 pr-4";
    if (!loading) {
      let body = newStyle ? (
        <div
          onClick={(e) => {
            if (!notOpenModal) {
              this.openModal(e, product);
            }
          }}
        >
          <div className="item-image">
            <img
              src={product.image ? product.image : ImageDefault}
              alt="exchange-img"
            />
          </div>
          <div className="item-info">
            <h4>
              {" "}
              <span>
                <img src={CoinSvg} alt="coin" />{" "}
              </span>{" "}
              {product.unit_price}{" "}
            </h4>
            <h5> {product.title} </h5>
          </div>
        </div>
      ) : (
        <figure
          className={
            product.image
              ? "product-figure row "
              : "product-figure row not-figure"
          }
          onClick={(e) => this.openModal(e, product)}
        >
          <img
            className={"product-image"}
            src={product.image ? product.image : ImageDefault}
            alt="product"
          />

          <div
            className={
              "d-flex justify-content-between flex-column info col mb-3"
            }
          >
            <p className="font-weight-bold">
              {currency ? currency.symbol : "S/."} {product.unit_price}
            </p>

            <div className=" d-flex bd-highlight">
              <p className="title ">{product.title}</p>
              {product.stock === 0 && <p className="no-stock">Sin stock</p>}
            </div>
          </div>
        </figure>
      );
      return (
        <Fragment>
          {carta ? (
            <div
              className={newStyle ? "item-exchange" : style_class}
              onClick={carta && this.clickModalCarta}
            >
              {body}
            </div>
          ) : (
            <div
              className={newStyle ? "item-exchange" : style_class}
            >
              {body}
            </div>
          )}
          {/* <div
            className={newStyle ? "item-exchange" : style_class}
            onClick={carta && this.clickModalCarta}
          >
            {body}
          </div> */}
          {this.state.modalCarta && carta && (
            <div className="modal-carta">
              <ModalProductCarta
                product={product}
                close={carta && this.clickModalCarta}
                currency={currency}
              />
            </div>
          )}
        </Fragment>
      );
    } else {
      return (
        <div className={`${style_class} load`}>
          <figure className={"product-figure"}>
            <div className="image-loading"></div>
            <div className="info">
              <p className="title"> </p>
              <p className="price-loading" />
              <div className="row detail">
                <div className="col">
                  <p className="title loading" />
                </div>
              </div>
            </div>
          </figure>
        </div>
      );
    }
  }
}
Product.defaultProps = {
  is_detail: false,
  loading: false,
  promotion: null,
};

Product.propTypes = {
  promotion: PropTypes.object,
  is_detail: PropTypes.bool,
  loading: PropTypes.bool,
};
