import React from "react";

import svgs from "./icons";
import "./icon.css";

const Icon = ({ svg, classes, onClick }) => {
  const svgRender = svgs[svg] || svgs.default;
  return (
    <svg
      viewBox={svgRender.viewBox}
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      {svgRender.svg}
    </svg>
  );
};

export default Icon;