import HttpClient from "../common/utils/httpClient";


export const GET_AREAS = 'GET_AREAS';
export const SELECT_AREA = 'SELECT_AREA';



/**Urls**/
export const LIST_AREAS_URL = 'v7/areas/';




export function getAreas(params) {
  let filters = "";
  if (params.length > 0) {
    filters = `?`;
    params.forEach(function (param) {
      if(filters.length>1)
        filters += `&`;

      for (let key of Object.keys(param)) {
        filters += `${key}=${param[key]}`
      }

    })
  }
  let url = LIST_AREAS_URL.replace("{country}",params)
  url = `${url}${filters}`;
  return (dispatch) => HttpClient.get(dispatch, GET_AREAS, url);
}

export function selectArea(area) {
  return {
    type: SELECT_AREA,
    area: area
  };
}

