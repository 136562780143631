import React, {Fragment} from "react";
import PropTypes from 'prop-types';
import InfiniteScroll from "react-infinite-scroller";
import {STATUS_LOADING, STATUS_SUCCESS} from "../../../common/utils/generic";
import "./style.scss"
import ItemRedemptions from "./ItemRedemptions"
import NoSearchResult from "../../../common/images/NoSearchResult.svg";

export default class ListRedemptions extends React.Component {

  loadMore = () => {
    const {nextPageRedemptions, listNextProgramRedemptionsStatus, listProgramRedemptionsStatus, getMoreProgramRedemptions} = this.props;
    if (listNextProgramRedemptionsStatus !== STATUS_LOADING && listProgramRedemptionsStatus !== STATUS_LOADING  && nextPageRedemptions != null) {
      getMoreProgramRedemptions(nextPageRedemptions);
    }
  };

  renderProgramRedemptions() {
    const {programRedemptions, listProgramRedemptionsStatus, count, programUid, currency} = this.props;
    if (count === 0 && listProgramRedemptionsStatus === STATUS_LOADING) {
      return <Fragment>
        <ItemRedemptions loading={true}/>
        <ItemRedemptions loading={true}/>
        <ItemRedemptions loading={true} />
      </Fragment>;
    }
    if (count === 0 && listProgramRedemptionsStatus === STATUS_SUCCESS) {
      return <div className="content-no-items">
          <img src={NoSearchResult} alt="noItems"/>
          <p className={"mt-3"}>No se encuentran redenciones asociadas a este GIT CARD</p>
      </div>
    }
    return programRedemptions.map((programRedemption, index) => (
            <ItemRedemptions
              key={`redemption${index}`}
              redemption={programRedemption}
              programUid={programUid}
              currency={currency}
              loading={false}/>
    ));

  }

  render() {
    let {nextPageRedemptions} = this.props;
    let more = nextPageRedemptions != null;
    let loaderMore = (

      <Fragment key={"load"}>
        <ItemRedemptions loading={true}/>
        <ItemRedemptions loading={true}/>
        <ItemRedemptions loading={true} />
      </Fragment>

    );
    return (
      <div className={"container"}>

        <InfiniteScroll
          className="row"
          pageStart={0}
          loadMore={this.loadMore.bind(this)}
          hasMore={more}
          loader={loaderMore}
        >
          {this.renderProgramRedemptions()}
        </InfiniteScroll>
      </div>
    );
  }
}

ListRedemptions.defaultProps = {
  programRedemptions: [],
  listProgramRedemptionsStatus: '',
  count: 0,
  nextPageRedemptions: null
};

ListRedemptions.propTypes = {
  programRedemptions: PropTypes.array,
  nextPageRedemptions: PropTypes.string,
  getMoreProgramRedemptions: PropTypes.func,
  listNextProgramRedemptionsStatus: PropTypes.string,
  programUid: PropTypes.string,
  count: PropTypes.number,
  listProgramRedemptionsStatus: PropTypes.string
};


