import HttpClient from "../common/utils/httpClient";


export const GET_PROGRAMS = 'GET_PROGRAMS';
export const GET_PROGRAM = 'GET_PROGRAM';
export const ADD_PROGRAM = 'ADD_PROGRAM';




/**Urls**/
export const LIST_PROGRAMS_ME_URL = 'v5/me/programs/';
export const LIST_PROGRAMS_URL = 'v5/programs/';

export function getPrograms(params) {

  let filters = "";
  for (let key of Object.keys(params)) {
    if(params[key]!=null && params[key]!==""){
      if (filters.length > 1)
        filters += `&`;

      filters += `${key}=${params[key]}`
    }
  }
  let url = `${LIST_PROGRAMS_URL}`
  if(filters.length>0)
    url = `${LIST_PROGRAMS_URL}?${filters}`;
  return (dispatch) => HttpClient.get(dispatch, GET_PROGRAMS, url);
}


export function getProgram(uidProgram) {
  return (dispatch) => HttpClient.get(dispatch, GET_PROGRAM, `${LIST_PROGRAMS_ME_URL}${uidProgram}/`);
}

export function joinToProgram(data) {
  return (dispatch) => HttpClient.post(data, dispatch, ADD_PROGRAM, `${LIST_PROGRAMS_ME_URL}`);
}
