import React, {Fragment} from 'react'
import OrderTable from "./OrderTable/OrderTable";
import ModalOrder from "../ModalOrder";
const $ = require('jquery');

export default class ListOrders extends React.Component {

  constructor() {
    super();
    this.state = {
      companyUid : "",
      pageCurrent: 1,
      openOrderDetail: false,
      orderUid: "",
      bandGet: true,
    }
  }

  componentDidMount() {
    const {
      changeStateCart,changeSecondaryMenu
    } = this.props
    
    changeStateCart()
    changeSecondaryMenu()
    this.updateComponent()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let { changeSecondaryMenu, changeStateCart, stateCart } = this.props;
    this.updateComponent()
    if(stateCart!=="CART_INVISIBLE"){
      changeSecondaryMenu()
      changeStateCart()
    }
    if ( $(".modal-backdrop").length > 1 ) {
      $('.modal-backdrop:first').remove();
    }
  }

  updateComponent() {
    const {company, getOrders,listCoins } = this.props
    if(company){
      if(company.uid !==  this.state.companyUid){
        this.setState({companyUid: company.uid })
          getOrders({companyUid: company.uid})
      }
    }
      if(listCoins !==  this.state.bandGet){
        this.setState({bandGet: !this.state.bandGet })
          getOrders()
      }
  }

  openPopUp(orderUid){
    this.setState({openOrderDetail: true, orderUid: orderUid})
  }

  renderStatus = (status) => {
    switch (status) {
      case "received": return "Orden recibida";
      case "delivered": return "Orden entregada";
      case "canceled_by_place": return "Orden Cancelada";
      case "canceled_by_user": return "Orden Cancelada por el usuario";
      case "accepted": return "Orden aceptada";
      case "Aceptada": return "Orden aceptada";
      case "shipped": return "Orden enviada";
      default: return '--'
    }
  }
  render() {
    let {
      orders:{
        entities: ordersEntities,
        results: ordersResults,
        listStatus,
        listNextStatus,
        nextPage,
        previousPage,
        count,
        retrieveStatus,
        formInitial
      },
      getOrders,
      getNumberPageOrders,
      retrieveOrder,
      listCoins
    } = this.props
    return (
      <Fragment>
        <OrderTable
          orders={ordersResults.map(cUid => ordersEntities[cUid])}
          count = {count}
          nextPage={nextPage}
          previousPage={previousPage}
          listOrdersStatus={listStatus}
          listNextOrdersStatus={listNextStatus}
          getOrders={() => {getOrders()}}
          getNumberPage={(numberPage) => getNumberPageOrders(numberPage)}
          pageCurrent={this.state.pageCurrent}
          setPageCurrent={pageCurrent=>this.setState({pageCurrent:pageCurrent})}
          openPopUp={(uid)=>this.openPopUp(uid)}
          listCoins={listCoins}
        />

        <ModalOrder
          showModal={active => this.setState({openOrderDetail: active})}
          retrieveStatus={retrieveStatus}
          order={formInitial}
          orderUid={this.state.orderUid}
          getOrder={(orderUid)=>(retrieveOrder(orderUid))}
          renderStatus={(status) => this.renderStatus(status)}
          formInitial={formInitial}
          listCoins={listCoins}
          />
      </Fragment>
    );
  }

}

