import * as React from "react"

const TerminosIcon = (props) => (
  <svg
    width={16}
    height={17}
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M.38 9.5C-.475 8.53.208 7 1.5 7h2.435l1.427-4.954C5.67 1.056 6.462.242 7.48.05a2.72 2.72 0 0 1 3.115 1.86L12.065 7h2.434c1.294 0 1.977 1.53 1.12 2.5-2.282 2.577-3.852 4.38-6.551 7.064a1.527 1.527 0 0 1-2.136 0C4.232 13.88 2.662 12.077.38 9.5Z"
      fill="#1FB6FF"
    />
  </svg>
)
export default TerminosIcon