import React, {Fragment} from 'react'
import PropTypes from 'prop-types';
import ProgramPromotionDetail from "./components/programPromotionDetail";
import ModalValidationByUser from "./components/validationPopup/ModalValidationByUser";
import ModalExchange from "./components/exchangePopup/ModalExchange";
// import RelatedPromotions from "./components/relatedPromotions";


export default class ProgramPromotionDetailContainer extends React.Component {

  constructor() {
    super();
    this.state = {
      openPromotionDetail: false,
      promotionUid: '',
      typePopUp: 'qr',
      qrCode: "",
      load: false
    }
    this.openPopUp = this.openPopUp.bind(this);
  }

  componentDidMount() {
    const {changeSecondaryMenu, changeStateCart} = this.props
    changeSecondaryMenu()
    changeStateCart()
    this.updateComponent()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.updateComponent()
  }

  updateComponent() {
    const {
      match: {params: {uid, promotionUid}},
      getPromotionCurrent,
      getPromotionPlaces,
      getRelatedProgramPromotion
    } = this.props
    if (this.state.promotionUid !== promotionUid) {
      this.setState({promotionUid: promotionUid})
      if (uid && uid !== '' && promotionUid && promotionUid !== '') {
        getPromotionCurrent(uid, promotionUid);
        getPromotionPlaces(promotionUid);
        getRelatedProgramPromotion(uid, promotionUid);
      }
    }
  }

  openPopUp(promotionUid, type) {
    this.setState({openPromotionDetail: true, promotionUid: promotionUid, typePopUp: type})
  }

  render() {
    let {
      match: {params: {uid, promotionUid}},
      app,
      promotions: {
        promotionCurrent,
        promotionStatus,
        qrPromotionProgramDetail,
      },
      placesPromotion: {
        listPlacePromotionStatus,
        listPlacePromotion,
      },
      // relatedPromotion: {
      //   listRelatedPromotion,
      //   listRelatedPromotionStatus
      // },
      coupon: {
        couponDetail,
        errorDetailCoupon,
        statusDetailCoupon
      },
      redemptions: {
        //isLoadingRedeem,
        //errorRedeem,
        statusRedeem
      },
      getCoupon,
      multisite,
      company,
      getQRPromotion,
      redeemByUser,
      clearStatusRedeem,
      getPromotionCurrent,
    } = this.props;

    return (
      <Fragment>
        <ProgramPromotionDetail
          programUid={uid}
          promotionUid={promotionUid}
          promotionCurrent={promotionCurrent}
          promotionStatus={promotionStatus}
        
          listPlacePromotionStatus={listPlacePromotionStatus}
          listPlacePromotion={listPlacePromotion}

          couponDetail={couponDetail}
          errorDetailCoupon={errorDetailCoupon}
          statusDetailCoupon={statusDetailCoupon}
          multisite = {multisite}
          app={app}
          company = {company}
          getCoupon={getCoupon}
          openPopUp={this.openPopUp}
        />
        {/* <RelatedPromotions
          programUid={uid}
          multisite = {multisite}
          company = {company}
          listRelatedPromotion={listRelatedPromotion}
          listRelatedPromotionStatus={listRelatedPromotionStatus}/> */}


        <ModalExchange
          typePopUp={this.state.typePopUp}
          app={app}
          showModal={active => this.setState({openPromotionDetail: active})}
          qrCode={qrPromotionProgramDetail && qrPromotionProgramDetail.qr}
          setQrCode={(qrCode)=>this.setState({qrCode: qrCode})}
          getQRPromotion={()=>{this.state.qrCode.length===0 && getQRPromotion(uid, promotionUid)}}
          promotion={promotionCurrent}
          promotionUid={this.state.promotionUid}
          setPromotionUid={promotionUid => (this.setState({promotionUid: promotionUid}))}
          openPromotionDetail={this.state.openPromotionDetail}
        />
        <ModalValidationByUser
          statusRedeem={statusRedeem}
          redeemByUser={()=>redeemByUser(promotionCurrent && promotionCurrent.uid)}
          getPromotionCurrent={()=>getPromotionCurrent(uid, promotionUid)}
          clearStatusRedeem={()=>clearStatusRedeem()}
          typePopUp={this.state.typePopUp}
          app={app}
          showModal={active => this.setState({openPromotionDetail: active})}
          qrCode={qrPromotionProgramDetail && qrPromotionProgramDetail.qr}
          setQrCode={(qrCode)=>this.setState({qrCode: qrCode})}
          getQRPromotion={()=>{this.state.qrCode.length===0 && getQRPromotion(uid, promotionUid)}}
          promotion={promotionCurrent}
          promotionUid={promotionCurrent && promotionCurrent.uid}
          setPromotionUid={promotionUid => (this.setState({promotionUid: promotionUid}))}
          openPromotionDetail={this.state.openPromotionDetail}
        />
      </Fragment>
    );
  }
}

ProgramPromotionDetailContainer.defaultProps = {
  promotions: null,
  placesPromotion: null,
  relatedPromotion: null,
  coupon:null
};

ProgramPromotionDetailContainer.propTypes = {
  promotions: PropTypes.object,
  placesPromotion: PropTypes.object,
  relatedPromotion: PropTypes.object,
  coupon: PropTypes.object,
  getPromotionCurrent: PropTypes.func,
  getPromotionPlaces: PropTypes.func,
  getRelatedProgramPromotion: PropTypes.func,
};


