
export const STATUS_SUCCESS = 'success';
export const STATUS_ERROR = 'error';
export const STATUS_INCLUDE = 'include';
export const STATUS_LOADING = 'loading';

export const LOGIN_TYPE_EMAIL = 'EMAIL';
export const LOGIN_TYPE_DOCUMENT = 'DOCUMENT';
export const LOGIN_TYPE_ONLY_DOCUMENT = 'ONLY_DOCUMENT';
export const LOGIN_TYPE_FACEBOOK = 'FACEBOOK';
export const LOGIN_TYPE_IOS = 'IOS';
export const ERROR_DEFAULT ={non_field_errors: ['Algo malo ha pasado, por favor intentarlo más adelante.']};
export const ERROR_NO_CODE ={detail: 'Este código no existe'};

export default class Help {
  static getParams(params) {
    let filters = "";
    for (let key of Object.keys(params)) {
      if(params[key]!=null && params[key]!==""){
        if (filters.length > 1) {
          filters += `&`;
        }
        filters += `${key}=${params[key]}`
      }
    }
    return filters.length>0?`?${filters}`:''
  }

}