import React from 'react'
import PropTypes from 'prop-types';
import {STATUS_LOADING, STATUS_SUCCESS} from '../../common/utils/generic';
import Validator from '../../common/utils/validator';
import './style.scss';
import {toast} from "react-toastify";
import {BTN_CHANGE, BTN_CHANGE_LOADING} from "./constants";


export default class ChangePassword extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      password: "",
      password_confirmation: "",
      showResponse: false,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let { updateStatus, changeSecondaryMenu, changeStateCart, stateCart } = this.props;
    if( updateStatus === STATUS_SUCCESS && this.state.showResponse){
      toast.success("Contraseña actualizada correctamente.");
      this.setState({ showResponse: false })
    }

    if(stateCart!=="CART_INVISIBLE"){
      changeSecondaryMenu()
      changeStateCart()
    }
  }

  componentDidMount() {
    let {changeSecondaryMenu, changeStateCart} = this.props
    changeSecondaryMenu()
    changeStateCart()
    this.validator = new Validator([
      {
        id: "password",
        ref: this.passwordInput,
        rules: ["required", "password"]
      },
      {
        id: "password_confirmation",
        ref: this.passwordConfirmationInput,
        rules: ["required", "password"]
      }
    ]);

  }

  setFormValues = (propertiesToUpdate) => {
    this.setState(propertiesToUpdate);
    this.validator.validate(propertiesToUpdate);
  }

  onSubmitForm = async (event) => {
    event.preventDefault();

    const {updateUser} = this.props;
    const {password, password_confirmation} = this.state;
    this.setState({ showResponse: true })

    if(password_confirmation !== password){
      toast.error("Las contraseñas deben ser iguales");
      this.setState({ showResponse: false })
      return;
    }

    let payload = {...this.state}
    if(this.validator.validate(payload)){
      delete payload.password_confirmation
      delete payload.showResponse

      updateUser(payload);
    }


  }

  renderError(errors, fieldName) {
    return errors && errors[fieldName] &&
      <span className='field-error-message'>
        {errors[fieldName][0]}
      </span>;
  }

  render() {
    let {updateStatus, errors} = this.props;
    let mainColor = localStorage.getItem("colors_main_color") ? localStorage.getItem("colors_main_color") : "#1b4bc6"

    return (
      <div className="">
        <form className={"row form form-change d-flex flex-column"}>
          <div className={" mx-auto col-8 col-lg-5 p-0"}>
            <h5>Cambiar contraseña</h5>
            <div className={"form-group p-0"}>
              <div
                className="input-group"
                ref={(input) => {
                  if (input) {
                    this.passwordInput = input.querySelector('input');
                  }
                }}
              >
                <input
                  className={"form-control"}
                  value={this.state.password}
                  onChange={e => this.setFormValues({password: e.target.value})}
                  type={"password"}
                  placeholder="Nueva contraseña *"
                  disabled={updateStatus === STATUS_LOADING}
                />
              </div>
              {this.renderError(errors, 'password')}
            </div>
            <div className={"form-group p-0"}>
              <div
                className="input-group"
                ref={(input) => {
                  if (input) {
                    this.passwordConfirmationInput = input.querySelector('input');
                  }
                }}
              >
                <input
                  className={"form-control"}
                  value={this.state.password_confirmation}
                  onChange={e => this.setFormValues({password_confirmation: e.target.value})}
                  type={"password"}
                  placeholder="Confirmar contraseña *"
                  disabled={updateStatus === STATUS_LOADING}
                />
              </div>
              {this.renderError(errors, 'password_confirmation')}
            </div>
          </div>
          {this.renderError(errors, 'non_field_errors')}
          <div className="mx-auto col-8 col-lg-5 p-0">
            <button
              onClick={e=>this.onSubmitForm(e)}
              disabled={updateStatus === STATUS_LOADING || this.state.readOnly}
              className="btn btn-change btn-primary "
              style={{textOverflow:"ellipsis", overflow: "hidden", backgroundColor: `${mainColor}`, border: `2px solid ${mainColor}`}}>>
              {updateStatus === STATUS_LOADING ? BTN_CHANGE_LOADING : BTN_CHANGE}
            </button>
          </div>
        </form>
      </div>
    );
  }
}
ChangePassword.defaultProps = {
  errors: null,
  updateStatus: '',
};
ChangePassword.propTypes = {
  dispatch: PropTypes.func,
  errors: PropTypes.object,
  updateStatus: PropTypes.string
};

