import HttpClient from "../common/utils/httpClient";

export const GET_EVENTS = 'GET_EVENTS';
export const EVENT_DETAIL = 'EVENT_DETAIL';

/**Urls**/
export const LIST_EVENTS_URL = 'v5/companies/{company_uid}/events/';
export const UPDATE_RETRIEVE_EVENT_URL = 'v5/events/{event_uid}/';


export function getEvents(company_uid, params={}) {
  let url = LIST_EVENTS_URL.replace("{company_uid}",company_uid)
  console.log(params, "params")
  let filters = "";
  for (let key of Object.keys(params)) {
    if(params[key]!=null && params[key]!==""){
      if (filters.length > 1) {
        filters += `&`;
      }
      filters += `${key}=${params[key]}`
    }
  }
  if(filters.length>0){
    url = `${url}?${filters}`;
  }else{
    url = `${url}?ordering=start_date,start_time`;
  }
  return (dispatch) => HttpClient.get(dispatch, GET_EVENTS, url);
}

export function getNumberPageEvents(numberPage) {
  return (dispatch) => HttpClient.get(dispatch, GET_EVENTS, `${LIST_EVENTS_URL}?page=${numberPage}`);
}

export function retrieveEvent(company_uid, eventUid) {
  return (dispatch) => HttpClient.get(
    dispatch,
    EVENT_DETAIL,
    UPDATE_RETRIEVE_EVENT_URL.replace("{company_uid}",company_uid).replace("{event_uid}", eventUid)
  );
}