/* eslint-disable no-restricted-globals */
import axios from 'axios';


let API_URL = 'https://api.bederr.com/';
if (process.env.REACT_APP_URL_REST !== undefined)
  API_URL = process.env.REACT_APP_URL_REST;

const getHeaders = () => {
  let payload = {}
  let token = window.localStorage.getItem(`token`);
  if(token && token!=="null" && token!=="undefined"){
    payload['Authorization'] = `Token ${token}`
  }
  let apiKey = window.localStorage.getItem(`apiKey`);
  if(apiKey && apiKey!=="null" && apiKey!=="undefined"){
    payload['API-KEY'] = apiKey
  }
  return payload
}





export default class HttpClient {
  static get(dispatch, type, url, useApiBase = true) {
    url = useApiBase ? `${API_URL}${url}` : url;
    return dispatch({
      type,
      payload: axios.get(url, {headers: getHeaders()}).then((response) => response)
        .catch((error) => error.response)
    });
  }


  static post(data, dispatch, type, url) {
    return dispatch({
      type,
      payload: axios.post(`${API_URL}${url}`, data, {headers: getHeaders()}).then((response) => response)
        .catch((error) => error.response),
    });
  }

  static patch(data, dispatch, type, url) {
    return dispatch({
      type,
      payload: axios.patch(`${API_URL}${url}`, data, {headers: getHeaders()}).then((response) => response)
        .catch((error) => error.response),
    });
  }

  static delete(dispatch, type, url, itemUid) {
    return dispatch({
      type,
      payload: axios.delete(`${API_URL}${url}`, {headers: getHeaders()}).then((response) => {
        response.uid = itemUid
        return response
      }).catch((error) => {
        error.response.uid = itemUid
        return error.response
      }),
    });
  }


}
