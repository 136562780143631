import HttpClient from "../common/utils/httpClient";


export const ADD_PRODUCT = 'ADD_PRODUCT';
export const ADD_PRODUCT_SUBSCRIPTION = 'ADD_PRODUCT_SUBSCRIPTION';
export const ADD_PRODUCT_BACK = 'ADD_PRODUCT_BACK';
export const REMOVE_PRODUCT = 'REMOVE_PRODUCT';
export const UPDATE_QUANTITY_PRODUCT = 'UPDATE_QUANTITY_PRODUCT';
export const UPDATE_QUANTITY_PRODUCT_SUBSCRIPTION = 'UPDATE_QUANTITY_PRODUCT_SUBSCRIPTION';
export const GET_CART = 'GET_CART';
export const GET_CART_SUBSCRIPTION = 'GET_CART_SUBSCRIPTION';
export const GET_CART_BACK = 'GET_CART_BACK';
export const ORDER = 'ORDER';
export const SUBSCRIPTION = 'SUBSCRIPTION';
export const VALIDATE_CODE = 'VALIDATE_CODE';
export const CLEAN_ORDER = 'CLEAN_ORDER';
export const CLEAN_ORDER_SUBSCRIPTION = 'CLEAN_ORDER_SUBSCRIPTION';
export const CLEAN_CODE = 'CLEAN_CODE';
export const STATE_CART = 'STATE_CART';
export const CART_INVISIBLE = 'CART_INVISIBLE';
export const CART_VISIBLE = 'CART_VISIBLE';
export const CLEAN_CART = 'CLEAN_CART';

export const TYPE_CART = 'TYPE_CART';
export const CART_PRODUCTS = 'CART_PRODUCTS';
export const CART_SUBSCRIPTION = 'CART_SUBSCRIPTION';


/**Urls**/
export const SHOPPING_CART = 'v5/me/shopping-cart/';
export const ORDER_URL = 'v5/places/{place_uid}/orders/';
export const CODE_URL = 'v5/companies/{place_uid}/validate-code/{code}/';
export const SUBSCRIPTION_URL = 'v5/places/{place_uid}/subscriptions/';



export function changeStateCart(state) {
  return {
    type: STATE_CART,
    state
  }
}

export function setTypeCart(state) {
  return {
    type: TYPE_CART,
    state
  }
}

export function getProductCart(place) {
  return {
    type: GET_CART,
    place,
  }
}

export function getProductsBack(place_uid) {
  let url =`${SHOPPING_CART}?place_uid=${place_uid}`;
  return (dispatch) => HttpClient.get(dispatch, GET_CART_BACK, url);
}

export function getProductCartSubscription(place) {
  return {
    type: GET_CART_SUBSCRIPTION,
    place,
  }
}

export function addProductsBack(data) {
  return (dispatch) => HttpClient.post(data, dispatch, ADD_PRODUCT_BACK, SHOPPING_CART);
  //return { type: ADD_PRODUCT_BACK, }
}

export function addProductCart(product, place) {
  return {
    type: ADD_PRODUCT,
    product,
    place
  }
}

export function addProductCartSubscription(product, place) {
  return {
    type: ADD_PRODUCT_SUBSCRIPTION,
    product,
    place
  }
}

export function updateProductCart(quantity,productUid, place) {
  return {
    type: UPDATE_QUANTITY_PRODUCT,
    quantity,
    productUid,
    place
  }
}

export function updateProductCartSubscription(quantity,productUid, place) {
  return {
    type: UPDATE_QUANTITY_PRODUCT_SUBSCRIPTION,
    quantity,
    productUid,
    place
  }
}

export function removeProductCart(productUid, place) {
  return {
    type: REMOVE_PRODUCT,
    productUid,
    place
  }

}

export function orderCart(placeUid, data) {
  let url =`${ORDER_URL.replace('{place_uid}',placeUid)}`;
  return (dispatch) => HttpClient.post(data, dispatch, ORDER, url);
}

export function orderSubscription(placeUid, data) {
  let url =`${SUBSCRIPTION_URL.replace('{place_uid}',placeUid)}`;
  return (dispatch) => HttpClient.post(data, dispatch, SUBSCRIPTION, url);
}

export function cleanPaymentCart() {
  return {
    type: CLEAN_ORDER
  }
}


export function cleanPaymentCartSubscription() {
  return {
    type: CLEAN_ORDER_SUBSCRIPTION
  }
}
export function validateCode(companyUid, code) {
  let url =`${CODE_URL.replace('{code}',code).replace('{place_uid}', companyUid)}`;
  return (dispatch) => HttpClient.get( dispatch, VALIDATE_CODE, url);
}

export function cleanCode() {
  return {
    type: CLEAN_CODE
  }
}

export function cleanCart() {
  return {
    type: CLEAN_CART
  }
}