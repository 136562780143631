import React, {Fragment} from 'react'
import './style.scss';
import PropTypes from "prop-types";
import {STATUS_LOADING, STATUS_SUCCESS} from "../../../../common/utils/generic";
import {Link} from "react-router-dom";
import moment from "moment";
const $ = require('jquery');

export default class EventTable extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedEvent: null,
      date: null,
      name: '',
      search: "",
      typing: false,
      typingTimeout: 0,
    };
    this.searchTerm = this.searchTerm.bind(this);
  }

  cleanState(){
    this.setState({
      date: moment().format("YYYY-MM-DD"),
      name: '',
    })
      //this.props.getOrders(placeCurrent.uid, moment().format("YYYY-MM-DD"), "", localStorage.getItem('paymentTypeSearchOrders'));
  }


  searchTerm = (event) => {
    const self = this;

    if (self.state.typingTimeout) {
      clearTimeout(self.state.typingTimeout);
    }
    let that = this;
      self.setState({
        search: event.target.value,
        typing: false,
        typingTimeout: setTimeout(function () {
          that.searchEvents();
        }, 800),
      });

  };

  searchEvents = () => {
    let { getEvents } = this.props;
    if (!this.state.typing) {
      let params = {
        date: this.state.date,
        name: this.state.search,
      };
      getEvents(params);
      this.setState({ typing: true });
    }
  };

  checkedValidation = () => {
    let isChecked = false

    if ($("#chechbox-orders").is(':checked')) {
      isChecked = true
    }
    if (isChecked)
      this.setState({date: null})


    let params = {
      date: null,
      name: this.state.search,
    };
    this.props.getEvents(params);
  }

  previous() {
    const {getNumberPage, pageCurrent, setPageCurrent} = this.props
    setPageCurrent(pageCurrent - 1)
    getNumberPage(pageCurrent - 1)
  }

  next() {
    const {getNumberPage, pageCurrent, setPageCurrent} = this.props
    setPageCurrent(pageCurrent + 1)
    getNumberPage(pageCurrent + 1)
  }

  componentDidMount() {
    let {placeCurrent} = this.props
    if (placeCurrent) {
      this.props.getEvents();
    }
  }

  renderStatus = (status) => {
    switch (status) {
      case "received":
        return "Orden recibida";
      case "delivered":
        return "Orden entregada";
      case "canceled_by_place":
        return "Orden Cancelada";
      case "canceled_by_user":
        return "Orden Cancelada por el usuario";
      case "accepted":
        return "Orden aceptada";
      case "Aceptada":
        return "Orden aceptada";
      case "shipped":
        return "Orden enviada";
      default:
        return '--'
    }
  }

  formatTime(time) {
    let hours = time ? (time.split(":")[0]) : "";
    let minutes = time ? (time.split(":")[1]) : "";
    let h =  hours<12 ? "am" : "pm"

    let strTime = hours + ':' + minutes + " " + h;
    return strTime;
  }

  renderOrders(results) {
    let {user} = this.props
    console.log(results, "results")
    return results.map((event, index) => (
      <Link to={`/events/${event.uid}/`} className="event col-lg-3 col-md-6 pr-2 pl-2 mt-2 mb-3" key={`${index}`}>
        <div
          className="card mb-3">
          <div className={user ? "event-image " : "event-image event-none"}
                style={{backgroundImage: `url(${event.image ? event.image : "https://via.placeholder.com/400x400.png"})`, backgroundSize: "cover", backgroundPosition: "center"}}
          >
          </div>
          <div className="card-body d-flex p-0">
            <div className="date bg-greylight mr-3 p-2 rounded-xxl theme-dark-bg date-list-event">
              <h4 className="font-weight-bold font-lg ls-3 mb-0 text-center">
                <span
              className="ls-3 d-block font-xsss text-grey-500 text-uppercase">{moment(event.start_date).format('MMMM')}</span>{moment(event.start_date).format('DD')}</h4></div>
            <h2 className="font-weight-bold lh-3 font-xss">{event.name}
              <span className={event.address ? "d-flex font-xssss font-weight-normal mt-2 lh-3 text-grey-500" : "d-none"}>
                {event.address}</span>
              <span className="d-flex font-xssss font-weight-normal mt-2 lh-3 text-grey-900">
                {this.formatTime(event.start_time)}
              </span>
            </h2>
          </div>
        </div>
      </Link>
    ));
  }


  renderPagination() {
    const {count, getNumberPage, pageCurrent, setPageCurrent} = this.props
    let nPage = Math.ceil(count / 25)
    let pages = [];

    for (let i = 1; i < nPage + 1; i++) {
      if (nPage + 1 > 10) {
        if (pageCurrent === i) {
          if (pageCurrent === 1) {
            pages.push(1)
            pages.push(2)
            pages.push(3)
            pages.push("...")
            pages.push(nPage - 2)
            pages.push(nPage - 1)
            pages.push(nPage)
          }
          if (pageCurrent === 2) {
            pages.push(1)
            pages.push(2)
            pages.push(3)
            pages.push(4)
            pages.push("...")
            pages.push(nPage - 2)
            pages.push(nPage - 1)
            pages.push(nPage)
          }
          if (pageCurrent === 3) {
            pages.push(1)
            pages.push(2)
            pages.push(3)
            pages.push(4)
            pages.push(5)
            pages.push("...")
            pages.push(nPage - 2)
            pages.push(nPage - 1)
            pages.push(nPage)
          }
          if (pageCurrent === 4) {
            pages.push(1)
            pages.push(2)
            pages.push(3)
            pages.push(4)
            pages.push(5)
            pages.push(6)
            pages.push("...")
            pages.push(nPage - 2)
            pages.push(nPage - 1)
            pages.push(nPage)
          }
          if (pageCurrent === 5) {
            pages.push(1)
            pages.push(2)
            pages.push(3)
            pages.push(4)
            pages.push(5)
            pages.push(6)
            pages.push(7)
            pages.push("...")
            pages.push(nPage - 2)
            pages.push(nPage - 1)
            pages.push(nPage)
          }
          if (pageCurrent > 5 && pageCurrent < nPage - 2) {
            pages.push(1)
            pages.push(2)
            pages.push("...")
            pages.push(pageCurrent - 2)
            pages.push(pageCurrent - 1)
            pages.push(pageCurrent)
            pages.push(pageCurrent + 1)
            pages.push(pageCurrent + 2)
            pages.push("...")
            pages.push(nPage - 2)
            pages.push(nPage - 1)
            pages.push(nPage)
          }
          if (pageCurrent === nPage - 2) {
            pages.push(1)
            pages.push(2)
            pages.push(3)
            pages.push("...")
            pages.push(pageCurrent - 1)
            pages.push(pageCurrent)
            pages.push(pageCurrent + 1)
            pages.push(pageCurrent + 2)
          }
          if (pageCurrent === nPage - 1) {
            pages.push(1)
            pages.push(2)
            pages.push(3)
            pages.push("...")
            pages.push(pageCurrent - 1)
            pages.push(pageCurrent)
            pages.push(pageCurrent + 1)
          }
          if (pageCurrent === nPage) {
            pages.push(1)
            pages.push(2)
            pages.push(3)
            pages.push("...")
            pages.push(pageCurrent - 2)
            pages.push(pageCurrent - 1)
            pages.push(pageCurrent)
          }
        }
      } else {
        pages.push(i)
      }
    }

    for (let i = pages.length - 1; i >= 0; i--) {
      if (pages.indexOf(pages[i]) !== i) {
        if (pages[i] !== "...") {
          pages.splice(i, 1);
        }
      }
    }

    return pages.map((page, index) => (
      <li key={`page${index}`}><a href={"/#"} key={`page${index}`}
                                  className={page === pageCurrent ? "active" : ""}
                                  onClick={(e) => {
                                    e.preventDefault()
                                    if (page !== pageCurrent && page !== "...") {
                                      setPageCurrent(page)
                                      getNumberPage(page)
                                    }
                                  }}
      >{page}</a></li>
    ));

  }

  render() {
    let {
      events, count,
      previousPage, nextPage, pageCurrent, listStatus} = this.props

    return (
      <Fragment>


        <div className={"container program-promotions program-promotions__discount"}>

          <div className="container event mt-1 ml-0 p-0">
            <div className={"mx-auto col-lg-12 col-md-12 p-0 m-0"}>
              <div
                className="col-lg-12 "
              >
                <div className="row align-content-center">
                  <div className="col-12 col-lg-3 p-0 pr-lg-3" style={{marginBottom: 5}}>
                    <div className="form-check m-0">
                      <div className="start_date-form d-flex flex-column">
                        <input
                          className={"form-control input-order mb-1"}
                          value={(this.state.date) ? this.state.date : ""}
                          onChange={e => {
                            console.log(e.target.value.split("-"))
                            this.setState({date: e.target.value})

                            let params = {
                              date: e.target.value,
                              search: this.state.search,
                            };
                            this.props.getEvents(params);
                          }}
                          type="date"
                          placeholder="Fecha DD/MM/YYYY"
                        />
                        <div className="checkbox d-flex flex-row align-items-center">
                          <input type="checkbox" id={"chechbox-orders"} checked={this.state.date === null}
                                 style={{marginLeft: 5}} onChange={e => this.checkedValidation()}/>
                          <label htmlFor="chechbox-orders" className="form-check-label" style={{marginLeft: 25, fontSize:16}}>Todas
                            las fechas</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-4 p-0 pr-lg-3">
                    <input
                      type="text"
                      className="form-control input-search input-order"
                      placeholder="Buscar evento"
                      value={this.state.search}
                      onKeyPress={(event) => {
                        if (event.key === "Enter") {
                          this.searchTerm(event);
                        }
                      }}
                      onChange={(e) => {
                        this.setState({ typing: true });
                        this.searchTerm(e);
                      }}
                    />
                  </div>
                </div>
              </div>


              <br/>
              <br/>
            </div>
          </div>


          <div className="container event p-0">

            <div className={"mx-auto col-lg-12 col-md-12"}>
              <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">
                {
                  listStatus === STATUS_SUCCESS ?
                    count!==0 ? this.renderOrders(events) :
                      <div className={"ml-4"}>No hay eventos disponibles</div>
                    :
                    listStatus === STATUS_LOADING ?
                    <Fragment>
                      <div className="event col-lg-3 col-md-6 pr-2 pl-2 mt-2">
                        <div
                          className="card mb-3">
                          <Link to={`/detail/`} className="event-image"
                                style={{backgroundImage: `url("https://via.placeholder.com/400x400.png"})`, backgroundSize: "cover", backgroundPosition: "center"}}
                          >
                          </Link>
                          <div className="card-body d-flex p-0">
                            <div className="date bg-greylight mr-3 p-2 rounded-xxl theme-dark-bg">
                              <h4 className="font-weight-bold font-lg ls-3 mb-0 text-center">
                <span
                  className="ls-3 d-block font-xsss text-grey-500 text-uppercase">
                </span></h4></div>
                            <h2 className="font-weight-bold lh-3 font-xss">
                              <span className="d-flex font-xssss font-weight-normal mt-2 lh-3 text-grey-500"></span>
                              <span className="d-flex font-xssss font-weight-normal mt-2 lh-3 text-grey-900"></span>
                            </h2>
                          </div>
                          <div className="card-body p-0 view">
                            <button
                              className="view-more font-weight-bold float-right mt-4 text-uppercase d-inline-block mr-1">Ver más</button>
                          </div>
                        </div>
                      </div>
                      <div className="event col-lg-3 col-md-6 pr-2 pl-2 mt-2">
                        <div
                          className="card mb-3">
                          <Link to={`/detail/`} className="event-image"
                                style={{backgroundImage: `url("https://via.placeholder.com/400x400.png"})`, backgroundSize: "cover", backgroundPosition: "center"}}
                          >
                          </Link>
                          <div className="card-body d-flex p-0">
                            <div className="date bg-greylight mr-3 p-2 rounded-xxl theme-dark-bg">
                              <h4 className="font-weight-bold font-lg ls-3 mb-0 text-center">
                <span
                  className="ls-3 d-block font-xsss text-grey-500 text-uppercase">
                </span></h4></div>
                            <h2 className="font-weight-bold lh-3 font-xss">
                              <span className="d-flex font-xssss font-weight-normal mt-2 lh-3 text-grey-500"></span>
                              <span className="d-flex font-xssss font-weight-normal mt-2 lh-3 text-grey-900"></span>
                            </h2>
                          </div>
                          <div className="card-body p-0 view">
                            <button
                              className="view-more font-weight-bold float-right mt-4 text-uppercase d-inline-block mr-1">Ver más</button>
                          </div>
                        </div>
                      </div>
                      <div className="event col-lg-3 col-md-6 pr-2 pl-2 mt-2">
                        <div
                          className="card mb-3">
                          <Link to={`/detail/`} className="event-image"
                                style={{backgroundImage: `url("https://via.placeholder.com/400x400.png"})`, backgroundSize: "cover", backgroundPosition: "center"}}
                          >
                          </Link>
                          <div className="card-body d-flex p-0">
                            <div className="date bg-greylight mr-3 p-2 rounded-xxl theme-dark-bg">
                              <h4 className="font-weight-bold font-lg ls-3 mb-0 text-center">
                <span
                  className="ls-3 d-block font-xsss text-grey-500 text-uppercase">
                </span></h4></div>
                            <h2 className="font-weight-bold lh-3 font-xss">
                              <span className="d-flex font-xssss font-weight-normal mt-2 lh-3 text-grey-500"></span>
                              <span className="d-flex font-xssss font-weight-normal mt-2 lh-3 text-grey-900"></span>
                            </h2>
                          </div>
                          <div className="card-body p-0 view">
                            <button
                              className="view-more font-weight-bold float-right mt-4 text-uppercase d-inline-block mr-1">Ver más</button>
                          </div>
                        </div>
                      </div>
                      <div className="event col-lg-3 col-md-6 pr-2 pl-2 mt-2">
                        <div
                          className="card mb-3">
                          <Link to={`/detail/`} className="event-image"
                                style={{backgroundImage: `url("https://via.placeholder.com/400x400.png"})`, backgroundSize: "cover", backgroundPosition: "center"}}
                          >
                          </Link>
                          <div className="card-body d-flex p-0">
                            <div className="date bg-greylight mr-3 p-2 rounded-xxl theme-dark-bg">
                              <h4 className="font-weight-bold font-lg ls-3 mb-0 text-center">
                <span
                  className="ls-3 d-block font-xsss text-grey-500 text-uppercase">
                </span></h4></div>
                            <h2 className="font-weight-bold lh-3 font-xss">
                              <span className="d-flex font-xssss font-weight-normal mt-2 lh-3 text-grey-500"></span>
                              <span className="d-flex font-xssss font-weight-normal mt-2 lh-3 text-grey-900"></span>
                            </h2>
                          </div>
                          <div className="card-body p-0 view">
                            <button
                              className="view-more font-weight-bold float-right mt-4 text-uppercase d-inline-block mr-1">Ver más</button>
                          </div>
                        </div>
                      </div>
                    </Fragment> : <div className={"ml-4"}>No hay eventos disponibles</div>
                }
              </div>

            </div>
          </div>

          <div className={"d-flex justify-content-center mt-1 mb-1"}>
            <ul className="pagination">
              <li>
                <button className={(previousPage === null || pageCurrent === 1) ? "is-disabled" : ""}
                        disabled={previousPage === null} onClick={() => this.previous()}> {"<"} </button>
              </li>
              {this.renderPagination()}
              <li>
                <button className={nextPage === null ? "is-disabled" : ""} disabled={nextPage === null}
                        onClick={() => this.next()}> {">"} </button>
              </li>
            </ul>
          </div>
        </div>


      </Fragment>
    );
  }
}

EventTable.defaultProps = {
  subscriptions: [],
  listSubscriptionsStatus: '',
  count: 0,
  nextPageSubscriptions: null
};

EventTable.propTypes = {
  subscriptions: PropTypes.array,
  nextPageSubscriptions: PropTypes.string,
  getMoreSubscriptions: PropTypes.func,
  listNextSubscriptionsStatus: PropTypes.string,
  count: PropTypes.number,
  listSubscriptionsStatus: PropTypes.string
};