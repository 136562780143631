import * as React from "react"

const ArrowSvg = (props) => (
  <svg
    width={16}
    height={9}
    fill="none"
    {...props}
  >
    <path
      d="M13.977.422a1.037 1.037 0 0 1 1.48 0 1.068 1.068 0 0 1 0 1.498L9.004 8.453a1.037 1.037 0 0 1-1.48 0L1.07 1.92a1.062 1.062 0 0 1-.306-.749c0-.27.102-.542.306-.749a1.037 1.037 0 0 1 1.48 0l5.714 5.923L13.977.422Z"
      fill="#000000"
    />
  </svg>
)

export default ArrowSvg
