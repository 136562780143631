import React from "react";
import PropTypes from "prop-types";
//import Validator from "../../../common/utils/validator";
import svgPlace from "../../../common/images/svg-place.svg";
import {toast} from "react-toastify";

export default class DeliveryMethodNew extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedLocality: null,
      address: '',
      reference: '',
      copy_address: false,
      firstLoad: false,
    }
    this.selectLocality = this.selectLocality.bind(this)
  }

  componentDidMount() {
    let {info} = this.props

    this.setState({...info})
    /*
    this.validator = new Validator([
      {
        id: "address",
        ref: this.addressInput,
        rules: ["required"]
      }])*/
  }



  selectLocality = (selectedLocality) => {
    this.setState({selectedLocality})
    let {
      setInfoDelivery,
    } = this.props
    let payload = {...this.state, selectedLocality}


    setInfoDelivery({
      ...payload,
      isValid: selectedLocality !== null
    })
  }

  renderError(errors, fieldName) {
    return errors && errors[fieldName] &&
      <span className='field-error-message'>
        {errors[fieldName][0]}
      </span>;
  }

  setFormValues = (propertiesToUpdate) => {
    let {
      selectedLocality,
    } = this.state;
    this.setState(propertiesToUpdate);
    //this.validator.validate(propertiesToUpdate);
    let {
      setInfoDelivery
    } = this.props
    let payload = {...this.state, ...propertiesToUpdate}


    setInfoDelivery({
      ...payload,
      isValid: (selectedLocality===null && this.isSpecific()) ? false : selectedLocality !== null
    })
  }


  order = () => {
    if (this.state.selectedLocality == null) {
      toast.info("Escoger un distrito")
      return;
    }
    if (this.validator.validate(this.state)) {

    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let {
      selectLocality,
      selectedLocality,
      setInfoDelivery,
      delivery
    } = this.props;
    // console.log(address);




    if(localStorage.getItem("address") && this.state.address!==localStorage.getItem("address")){
      this.setState({firstLoad: false, address: localStorage.getItem("address"), address_map: localStorage.getItem("address")})
    }

    if(this.state.reference!==localStorage.getItem("reference")){
      this.setState({firstLoad: false, reference: localStorage.getItem("reference")})
    }
    //console.log(localStorage.getItem("locality"), selectedLocality, "lll")
    if(localStorage.getItem("locality") && selectedLocality===null){
      selectLocality(JSON.parse(localStorage.getItem("locality")))
      this.setState({firstLoad: false})
    }

    if(localStorage.getItem("address") && !this.state.firstLoad){
      let payload = {
        address: localStorage.getItem("address"),
        reference: localStorage.getItem("reference"),
        selectedLocality: JSON.parse(localStorage.getItem("locality"))
      }
      if(this.isSpecific()){
        //console.log(JSON.parse(localStorage.getItem("locality")), "selectedLocalityAA")
        setInfoDelivery({
          ...payload,
          isValid: JSON.parse(localStorage.getItem("locality")) !== null
        })
      }else{
        if(delivery.toLowerCase()==="general"){
          setInfoDelivery({
            ...payload,
            isValid: true
          })
        }
      }
      this.setState({firstLoad: true})
    }

  }

  isSpecific = () => {
    let {delivery} = this.props
    if (delivery.toLowerCase()==="specific") {
      localStorage.setItem("delivery", "SPECIFIC")
      return true;
    }
    if (delivery.toLowerCase()==="none") {
      localStorage.setItem("delivery", "NONE")
      return true;
    }
    localStorage.setItem("delivery", "GENERAL")
    return false;
  }

  render() {
    let {
      setOpenDeliveryPopUp,
      selectedLocality,
    } = this.props;

    // console.log("props delivery", this.props);


    //console.log(selectedLocality, "selectedLocality111");

    let hidden = (this.isSpecific()) && selectedLocality===null

    return (
      <div className={"col-12 pl-0 d-flex flex-column justify-content-center p-0 m-0"}>

        <p className={localStorage.getItem("address") ? "text-center  mt-2" : "d-none"}>Su pedido se entregará a:</p>
        <div className={"d-flex align-items-center"}>
          <img src={svgPlace} alt="svgPlace" width={20}/>
          <div className={"ml-2 d-flex justify-content-center flex-column col-12"}>
            <p className={(!localStorage.getItem("address") || hidden) ? "mt-3 mb-3 text-center font-weight-bold m-0" : "d-none"}>Es necesario añadir una dirección para usar esta opción</p>

            <p className={(localStorage.getItem("address") && !hidden) ? "text-center font-weight-bold m-0" : "d-none"}>{localStorage.getItem("address")}</p>
            <p className={(localStorage.getItem("address") && !hidden)  ? "text-center font-weight-bold" : "d-none"}>Referencia: {localStorage.getItem("reference")}</p>
          </div>
        </div>
        <p className={this.props.selectedLocality ? "text-center font-weight-bold" : "d-none"}>Área: {this.props.selectedLocality ? this.props.selectedLocality.name : ""}</p>
        <button
          className={"btn btn-delivery mt-1 text-capitalize"}
          onClick={e=>{
          e.preventDefault()
          setOpenDeliveryPopUp(true)
        }}>{localStorage.getItem("address") ? "Modificar dirección" : "Añadir dirección aquí"}</button>
      </div>
    )
  }
}

DeliveryMethodNew.defaultProps = {
  localities: []
};

DeliveryMethodNew.propTypes = {
  localities: PropTypes.array,
  info: PropTypes.object,
  setInfoDelivery: PropTypes.func
};
