import React from 'react'

export default class SaveCoupon extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      code:""
    };
  }

  componentDidMount() {

    let {match, setCoupon, app, multisite} = this.props;
    const code = match.params.code;
    const companyUid = match.params.companyUid;
    if (code !== undefined && code !== "" && companyUid !== undefined && companyUid !== "" && this.state.code !== code) {
      setCoupon(companyUid, code)
      this.setState({code:code, companyUid:companyUid})
    }


    let existCompanyinMultisite = false;

    if(multisite){
        app.allowed_companies.map((company) =>{
          if(company.uid === companyUid){
            existCompanyinMultisite = true;
            this.props.history.push(`/c/${companyUid}/products`);
          }
          return true;
        })
    }

    if(!existCompanyinMultisite)
      this.props.history.push(`/products`);


  }

  render() {
    return (
    <p>Cargando...</p>
    );
  }
}
