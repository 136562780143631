import React from 'react';
import {Switch} from "react-router-dom";
import {Route} from 'react-router';
import {
  changeStateSecondaryMenu, SECONDARY_MENU_BACK,
} from "../../actions/menu";
import {changeStateCart, CART_INVISIBLE} from "../../actions/cart";
import ListOrders from "./ListOrders/ListOrders";
import {getNumberPageOrders, getOrders, retrieveOrder} from "../../actions/orders";

import './style.scss';

export default class SubscriptionRoot extends React.Component {


  render() {
    const {
      match,
      app,
      dispatch,
      company,
      selectedPlace,
      authStatus,
      orders,
      orders:{
        results: ordersResults,
        listStatus: listOrdersStatus,
        entities: ordersEntities,
        nextPage,
        previousPage,
        count,
        formInitial
      },
      stateCart
    } = this.props;

    return (
      <Switch>
        <Route exact path={"/orders/coins"} render={(props) =>
          <ListOrders
            {...props}
            authStatus={authStatus}
            app={app}
            company={company}
            selectedPlace={selectedPlace}
            stateCart={stateCart}
            changeSecondaryMenu={() => dispatch(changeStateSecondaryMenu(SECONDARY_MENU_BACK))}
            changeStateCart={() => dispatch(changeStateCart(CART_INVISIBLE))}
            getOrders={() => dispatch(getOrders("0WpJ9Y"))}
            orders={orders}
            getNumberPageOrders={ (numberPage) => dispatch(getNumberPageOrders(numberPage))}
            retrieveOrder={ (uid) => dispatch(retrieveOrder(uid))}
            nextPage={nextPage}
            previousPage={previousPage}
            listOrdersStatus={listOrdersStatus}
            count={count}
            ordersResults={ordersResults}
            ordersEntities={ordersEntities}
            formInitial={formInitial}
            listCoins={true}
          />
        }
        />
        <Route exact path={`${match.url}`} render={(props) =>
          <ListOrders
            {...props}
            authStatus={authStatus}
            app={app}
            company={company}
            selectedPlace={selectedPlace}
            stateCart={stateCart}
            changeSecondaryMenu={() => dispatch(changeStateSecondaryMenu(SECONDARY_MENU_BACK))}
            changeStateCart={() => dispatch(changeStateCart(CART_INVISIBLE))}
            getOrders={() => dispatch(getOrders("a90x9M"))}
            orders={orders}
            getNumberPageOrders={ (numberPage) => dispatch(getNumberPageOrders(numberPage))}
            retrieveOrder={ (uid) => dispatch(retrieveOrder(uid))}
            nextPage={nextPage}
            previousPage={previousPage}
            listOrdersStatus={listOrdersStatus}
            count={count}
            ordersResults={ordersResults}
            ordersEntities={ordersEntities}
            formInitial={formInitial}
            listCoins={false}
          />

          
        }
        />
      </Switch>
    )
  }
}

