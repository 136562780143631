import React, {Fragment} from 'react'
import PropTypes from 'prop-types';
import {GoogleMap, LoadScriptNext, Marker} from '@react-google-maps/api'
import moment from "moment";
import './style.scss';


export default class MapPlacePromotion extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      listPlaces: [],
      currentlyPlace: null
    }
  }

  componentDidMount() {
    const {
      places
    } = this.props;
    if (places.length>0) {
      this.setState(
        {
          listPlaces: places,
          currentlyPlace: places[0]
        }
      )
    }
  }



  setCurrentlyPlace = (e, place) => {
    this.setState({
      currentlyPlace: place
    })
  }


  renderMap = () => {

    let {listPlaces,
      currentlyPlace} = this.state
    //let API_key = "AIzaSyCDVeXSSU_rDyyTPSc0mrJJoaSQRR-w4sI";
    let API_key = "AIzaSyAWTCEZ32pjF851IgouM7sbFKbaOWjWvPM";

    const containerStyle = {
      width: '100%',
      height: '300px'
    };

    const center = {
      lat: currentlyPlace.location.latitude,
      lng: currentlyPlace.location.longitude
    };




    return <LoadScriptNext
      googleMapsApiKey={API_key}

    >
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        options={{
          streetViewControl: false,
          scaleControl: false,
          fullscreenControl: true,
          gestureHandling: "greedy",
          minZoom: 11,
          maxZoom: 18,
          mapTypeControl: false,
          zoomControl: true,
          clickableIcons: true
        }}
        zoom={13}
      >
        {listPlaces.map(place => (
          <Marker
            key={place.uid}
            position={{
              lat: parseFloat(place.location.latitude),
              lng: parseFloat(place.location.longitude)
            }}
            onClick={(e) => this.setCurrentlyPlace(e, place)}
          />
        ))}
      </GoogleMap>
    </LoadScriptNext>
  }

  render() {

    const {currentlyPlace} = this.state;
    const {places} = this.props;

    return (
      <div>
        {
          currentlyPlace &&
          this.renderMap()
        }
        {
          places.map((currentlyPlace, index)=>(
            <div className="place-detail p-3 " key={index}>

              <h5 className={"mt-0 mb-3"}>
                {currentlyPlace.name &&
                <span>{currentlyPlace.name }</span>} -
                {currentlyPlace.locality && <span>{" "}{currentlyPlace.locality.name}</span>} </h5>
              <div className="address-schedule d-flex">
                <div className="address col-12 col-lg-8 pl-0">
                  <h6>Dirección</h6>
                  <div>
                    <p>{currentlyPlace.address}</p>
                  </div>

                  <div>
                    {((currentlyPlace.telephone1 &&
                      currentlyPlace.telephone1 !== "") ||
                      (currentlyPlace.telephone2 &&
                        currentlyPlace.telephone2 !== "")) &&
                    <Fragment>
                      <h6>Teléfono(s)</h6>
                      <p>
                        {currentlyPlace.telephone1 &&
                        currentlyPlace.telephone1 !== "" && (
                          <span>{currentlyPlace.telephone1}</span>
                        )}
                        {currentlyPlace.telephone2 &&
                        currentlyPlace.telephone2 !== "" && (
                          <span>, {currentlyPlace.telephone2}</span>
                        )}
                      </p>
                    </Fragment>
                    }
                  </div>
                </div>
                <div className="schedule col-12 col-lg-4 pl-0">
                  {currentlyPlace.timetable &&
                  <Fragment>
                    <h6>Horarios</h6>
                    {currentlyPlace.timetable.monday_hours_from && (
                      <p style={{fontWeight: 300}}>
                        <span>L a V:</span>{" "}
                        {moment(
                          currentlyPlace.timetable.monday_hours_from,
                          "hh:mm:ss"
                        ).format("hh:mm")}am{" "}
                        a{" "}
                        {moment(
                          currentlyPlace.timetable.monday_hours_to,
                          "hh:mm:ss"
                        ).format("hh:mm")}pm
                      </p>
                    )}
                    {currentlyPlace.timetable.saturday_hours_from && (
                      <p style={{fontWeight: 300}}>
                        <span>S:</span>{" "}
                        {moment(
                          currentlyPlace.timetable.saturday_hours_from,
                          "hh:mm:ss"
                        ).format("hh:mm")}am{" "}
                        a{" "}
                        {moment(
                          currentlyPlace.timetable.saturday_hours_to,
                          "hh:mm:ss"
                        ).format("hh:mm")}pm
                      </p>
                    )}
                    {currentlyPlace.timetable.sunday_hours_from && (
                      <p >
                        <span>D:</span>{" "}
                        {moment(
                          currentlyPlace.timetable.sunday_hours_from,
                          "hh:mm:ss"
                        ).format("hh:mm")}am{" "}
                        a{" "}
                        {moment(
                          currentlyPlace.timetable.sunday_hours_to,
                          "hh:mm:ss"
                        ).format("hh:mm")}pm
                      </p>
                    )}
                    {currentlyPlace.timetable.extra &&
                    currentlyPlace.timetable.extra !== "" && (
                      <p className="list-group-item">
                        <i>{currentlyPlace.timetable.extra}</i>
                      </p>
                    )}
                  </Fragment>
                  }
                </div>
              </div>
            </div>
          ))
        }

      </div>
    )
  }


}


MapPlacePromotion.defaultProps = {
  places: []
};

MapPlacePromotion.propTypes = {
  places: PropTypes.array
};

