import React, {Fragment} from 'react'
import './style.scss';
import ProductCart from "./productCart";
import NoItemsCart from '../../common/images/NoItemsCart.svg';
import {STATUS_SUCCESS} from "../../common/utils/generic";
import {CART_INVISIBLE, CART_PRODUCTS, CART_SUBSCRIPTION, CART_VISIBLE} from "../../actions/cart";
import {Link} from "react-router-dom";
import ProductPopUp from "../products/productsPlace/components/productPopUp";

export default class ProductsCart extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      productUid: "",
      statusCartBack: null
    }

  }

  componentDidMount() {
    this.updateComponent()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.updateComponent()
  }

  updateComponent() {
    let {  selectedPlace,statusCartBack, typeCart } = this.props;

    if( selectedPlace !== null && selectedPlace !== undefined){
      localStorage.setItem("typeCart",typeCart)
      if(this.state.statusCartBack!==statusCartBack){
        this.setState({
          statusCartBack: statusCartBack,
          loading: true
        })
        this.updateCartBack()
      }
    }
  }

  updateCartBack = () => {
      const {authStatus,entitiesCart,statusCartBack, addProductsBack, selectedPlace, typeCart} = this.props;
      if(typeCart===CART_PRODUCTS){
        if (authStatus === STATUS_SUCCESS && statusCartBack===STATUS_SUCCESS) {
          if(entitiesCart && entitiesCart!==undefined && entitiesCart.products!==undefined){
            let listProducts = []
            Object.keys(entitiesCart.products).map((p,index) => {

              if(entitiesCart.products[p].option_groups.length!==0){
                entitiesCart.products[p].option_groups.map(group => {

                  delete group.name
                  delete group.description
                  delete group.minimum_to_select
                  delete group.maximum_to_select
                  delete group.is_active
                  if(group.options.length!==0){
                    group.options.map(o => {
                      delete o.description
                      delete o.is_active
                      return true
                    })
                  }
                  return true

                })


                listProducts.push({
                  uid: entitiesCart.products[p].uid,
                  quantity:entitiesCart.products[p].quantity,
                  option_groups: entitiesCart.products[p].option_groups
                })
              }else{
                listProducts.push({
                  uid: entitiesCart.products[p].uid,
                  quantity:entitiesCart.products[p].quantity,
                  option_groups: []
                })
              }
              return true;
            })
            addProductsBack({place: selectedPlace.uid, products: listProducts})
          }
        }
      }
  }

  renderPopUp = () => {
    let {multisite, history, company, authStatus, productsByPlaceCurrent, productsByPlaceCurrentStatus, openPopUp, activePopUp, componentPopUp, getProductCurrent,
      addProductCart, selectedPlace, entitiesCart, entitiesCartSubscription, addProductsBack,updateProductCart, currency, typeCart} = this.props;

    if (this.state.productUid !== "") {
      let listOptionsCartProduct = []
      if(entitiesCart && entitiesCart.products !== undefined){
        Object.keys(entitiesCart.products).map(p => {
          if(this.state.productUid===entitiesCart.products[p].id){
            if(entitiesCart.products[p].length!==0){
              if(entitiesCart.products[p].option_groups){
                listOptionsCartProduct = entitiesCart.products[p].option_groups
              }
            }
          }
          return true;
        })
      }

      return (
        <ProductPopUp
          productsByPlaceCurrent={productsByPlaceCurrent}
          productsByPlaceCurrentStatus={productsByPlaceCurrentStatus}
          openPopUp={openPopUp}
          activePopUp={activePopUp}
          getProductCurrent={getProductCurrent}
          placeUid={selectedPlace && selectedPlace.uid}
          uid={this.state.productUid}
          setProductUid={this.setProductUid}
          componentPopUp={componentPopUp}
          addProductCart={addProductCart}
          addProductsBack ={addProductsBack }
          selectedPlace={selectedPlace}
          entitiesCart={entitiesCart}
          entitiesCartSubscription={entitiesCartSubscription}
          authStatus={authStatus}
          updateProductCart={updateProductCart}
          currency={currency}
          typeCart={typeCart}
          listOptionsCartProduct={listOptionsCartProduct}
          history={history}
          company={company}
          multisite={multisite}
        />
      );
    }
  }

  setProductUid = (uid) => {
    this.setState({
      productUid: uid
    })
  };

  renderProductsCart() {
    const { openPopUp,cleanCart, selectedPlace, listCartStatus,updateProductCart, entitiesCart, entitiesCartSubscription, addProductsBack, authStatus, company, typeCart} = this.props;

    let listProducts = []
    if(entitiesCart && entitiesCart.products !== undefined){
      Object.keys(entitiesCart.products).map(p => {
        listProducts.push(entitiesCart.products[p])
        return true;
      })
    }


    if(this.props.match && listProducts!== [] && selectedPlace!== null && selectedPlace!== undefined && listCartStatus==="success"){
      return listProducts.map((productCart, index) => (

        <ProductCart
          {...this.props}
          match={this.props.match}
          key={`promo${index}`}
          placeUid={selectedPlace.uid}
          product={productCart}
          loading={false}
          setProductUid={this.setProductUid}
          updateProductCart={updateProductCart}
          selectedPlace={selectedPlace}
          addProductsBack={addProductsBack}
          entitiesCart={entitiesCart}
          entitiesCartSubscription={entitiesCartSubscription}
          authStatus={authStatus}
          currency={company.currency}
          typeCart={typeCart}
          openPopUp={openPopUp}
          cleanCart={cleanCart}
        />
      ));
    }
  }

  renderProductsCartSubscription() {
    const { openPopUp,cleanCart, selectedPlace, typeCart, listCartSubscriptionStatus, updateProductCartSubscription, entitiesCart, entitiesCartSubscription, addProductsBack, authStatus, company} = this.props;
    let listProducts = []

    if(entitiesCartSubscription && entitiesCartSubscription.products !== undefined){
      Object.keys(entitiesCartSubscription.products ).map(p => {
        listProducts.push(entitiesCartSubscription.products[p])
        return true;
      })
    }

    if (listProducts!== [] && selectedPlace!== null && selectedPlace!== undefined && listCartSubscriptionStatus==="success") {
      return listProducts.map((productCart, index) => (
        <ProductCart
          {...this.props}
          match={this.props.match}
          key={`promo${index}`}
          placeUid={selectedPlace.uid}
          product={productCart}
          loading={false}
          setProductUid={this.setProductUid}
          updateProductCart={updateProductCartSubscription}
          selectedPlace={selectedPlace}
          addProductsBack={addProductsBack}
          entitiesCart={entitiesCart}
          entitiesCartSubscription={entitiesCartSubscription}
          authStatus={authStatus}
          currency={company.currency}
          typeCart={typeCart}
          openPopUp={openPopUp}
          cleanCart={cleanCart}
        />
      ));
    }
  }

  render() {
    const {history, entitiesCart, entitiesCartSubscription, stateCart, company, multisite, typeCart, authStatus, componentPopUp} = this.props;

    if (stateCart === CART_VISIBLE) {
      return (
        <div>
          <div
            className={entitiesCart ? "cart d-none d-sm-block col-lg-3 overflowY" : "cart d-none d-sm-block col-lg-3"}
          >
            {
              (typeCart === CART_PRODUCTS) ?
                <Fragment>
                  <h4>Mi pedido</h4>
                  {
                    (entitiesCart && entitiesCart.products !== undefined && Object.keys(entitiesCart.products).length !== 0) ?
                      <div
                      >
                        <div className={"list-products-cart"}>
                          {this.renderProductsCart()}
                        </div>
                        <Link
                          className="btn btn-primary btn-cart mb-3"
                          to={multisite ? `/c/${company.uid}/cart` : `/cart`}>
                          Hacer pedido</Link>
                      </div> :
                      <div className={"no-items"}>
                        <p className={"mt-3"}>Tu carrito esta vacío</p>
                        <div className={"d-flex justify-content-center align-items-center"}>
                          <img src={NoItemsCart} alt="noItems"/>
                        </div>
                      </div>

                  }
                </Fragment>
                :
                <Fragment>
                  <h4>Mi suscripción</h4>
                  {
                    (entitiesCartSubscription && entitiesCartSubscription.products && Object.keys(entitiesCartSubscription.products).length !== 0) ?
                      <div
                      >
                        <div className={"list-products-cart"}>
                          {this.renderProductsCartSubscription()}
                        </div>
                        <Link
                          className="btn btn-primary btn-cart mb-3"
                          onClick={() => (authStatus!==STATUS_SUCCESS) && localStorage.setItem("redirectLoginToCartSubscription", company.uid)}
                          to={(authStatus===STATUS_SUCCESS) ? (multisite ? `/c/${company.uid}/cart` : `/cart`) : "/login"}>
                        Realizar suscripción</Link>
                      </div> :
                      <div className={"no-items"}>
                        <p className={"mt-3"}>Tu carrito esta vacío</p>
                        <div className={"d-flex justify-content-center align-items-center"}>
                          <img src={NoItemsCart} alt="noItems"/>
                        </div>
                      </div>
                  }
                </Fragment>
            }
          </div>
          {
            (typeCart === CART_SUBSCRIPTION) &&
            (history.location.pathname!=="/subscription/me") &&
            <Link
              className={
                (entitiesCartSubscription && entitiesCartSubscription.products && Object.keys(entitiesCartSubscription.products).length !== 0) ?
                  "btn btn-primary btn-cart mb-3 btn-cart-fixed" : "hidden"}
              onClick={() => (authStatus!==STATUS_SUCCESS) && localStorage.setItem("redirectLoginToCartSubscription", company.uid)}
              to={(authStatus===STATUS_SUCCESS) ? (multisite ? `/c/${company.uid}/cart` : `/cart`) : "/login"}>
              Realizar suscripción</Link>
          }
          {
            (componentPopUp=== "productPopUp") &&
            this.renderPopUp()
          }
        </div>
      );
    }

    if (stateCart === CART_INVISIBLE)
      return (<Fragment/>);
    return (<Fragment/>);
  }
}
