/*
 * HomeConstants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'yourproject/YourComponent' so we avoid
 * reducersapp accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'yourproject/YourContainer/YOUR_ACTION_CONSTANT';
 */

export const FROM_PROMOTION = 'FROM_PROMOTION';
export const FROM_PRODUCTS = 'FROM_PRODUCTS';

export const QULQI = 'CULQI';


export const BTN_PAYMENT = 'Pagar';
export const BTN_PAYMENT_LOADING = 'Pagando...';
