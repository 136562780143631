import React from "react";
import { Link } from "react-router-dom";
// import placeholder from "../../../../common/images/placeholder.jpg";
// import ReactImageAppear from 'react-image-appear';
import PropTypes from "prop-types";

const Promotion = (props) => {
  let { promotion, color, is_detail, programUid, loading, multisite, company,app, backdrop=false } =
    props;
   //console.log(props,"promotions");

  let colorDiscound = color.length<1? "36AA8C" : color.replace("#", "")
  let style_class = is_detail
    ? "program-promotion-card no-hover col-xs-12 col-sm-12"
    : "program-promotion-card col-xs-12 col-md-4 col-sm-6 col-xl-4";
  if(backdrop){
    let stamps = [];
    let promotionBackDrop = {
      "uid": "",
      "promotion_uid": "",
      "title": "",
      "image": "",
      "is_used": 0,
      "is_available": true,
      "company_retail": {
        "uid": "",
        "name": "",
        "web_page": "",
        "facebook_page": "",
        "logo": "",
        "cover": "",
        "logo_rectangle": "",
        "description": ""
      },
      "pin": {
        "uid": "",
        "name": "",
        "image": ""
      },
      "required_stamps": 0,
      "available_stamps": 0,
      "type": "FIXED",
      "original_price": "0",
      "allow_payment": true,
      "content": "",
      "fixed_discount": "0",
      "rate_discount": 0,
      "color": "",
      "subtitle": "",
      "all_levels": true,
      "all_segments": true,
      "promotion_unexpired": true
    }
    let required_stamps = promotionBackDrop.required_stamps
      ? promotionBackDrop.required_stamps
      : 0;
    if (required_stamps > 0) {
      for (let i = 0; i < required_stamps; i++) {
        stamps.push(promotionBackDrop.available_stamps > i);
      }
    }
    let body = (
      <figure>
        {promotionBackDrop.type === "GIFT_CARD" ? (
          <div>
            <div
              className="company-logo"
              style={{
                backgroundImage: promotionBackDrop.company_retail
                  ? `ùrl(${promotionBackDrop.company_retail.logo})`
                  : `none`,
              }}
            />
          </div>
        ) : (
          <>
            {promotionBackDrop.pin ? (
              <>
                {promotionBackDrop.type !== "STAMPS" && (
                  <div className="DiscountBox load" >
                    <div className="DiscountType" style={{ background: `#${colorDiscound}`}}>

                      {promotionBackDrop.type === "FIXED" && (
                        <>
                          {promotionBackDrop.fixed_discount && (
                            <div className={"d-flex"}>
                              <h3>S/.</h3> <span></span>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                    <div className="DiscountDateBox">
                      <h1>
                        {promotionBackDrop.title.substring(0, 18)}
                        {promotionBackDrop.title.length > 18 && "..."}
                      </h1>
                      <p>
                        {promotionBackDrop.company_retail.description.substring(0, 100)}
                        {promotionBackDrop.company_retail.description.length > 100 && "..."}
                      </p>
                      <div className="DiscountDate" style={{ background: `#${colorDiscound}`}}>
                      </div>
                    </div>
                  </div>
                )}
              </>
            ) :  <>
              {promotionBackDrop.type !== "STAMPS" && (
                <div className="DiscountBox">
                  <div className="DiscountType" style={{ background: `#${colorDiscound}`}} >
                    {promotionBackDrop.type === "RATE" && (
                      <>
                        {promotionBackDrop.rate_discount===100 ? (
                          <>
                            <h1>GRA</h1>
                            <h1>TIS</h1>
                          </>
                        ) : (
                          <>
                            <h1>{promotionBackDrop.rate_discount}%</h1>
                            <h2>de dscto.</h2>
                          </>
                        )}
                      </>
                    )}
                    {promotionBackDrop.type === "FIXED" && (
                      <>
                        {promotionBackDrop.fixed_discount && (
                          <>
                           <h3>S/.</h3>
                            <h3>{promotionBackDrop.fixed_discount.replace(".", ",")}</h3>
                          </>
                        )}
                      </>
                    )}
                  </div>
                  <div className="DiscountDateBox">
                    <h1>
                      {promotionBackDrop.title.substring(0, 18)}
                      {promotionBackDrop.title.length > 18 && "..."}
                    </h1>
                    <p>
                      {promotionBackDrop.content && promotionBackDrop.content.substring(0, 100)}
                      {promotionBackDrop.content.length > 100 && "..."}
                    </p>
                    <div className="DiscountDate" style={{ background: `#${colorDiscound}`}}>
                    </div>
                  </div>
                </div>
              )}
            </>}
          </>
        )}
      </figure>
    );
    return (
      <div>
        {!promotionBackDrop.is_available ? (
          <>
            <div style={{opacity: "0.5"}}>{body}</div>
          </>
        ) : (
          <div>
            {body}
          </div>
        )}
      </div>
    );
  }
  if (!loading) {
    // let pinImage = promotion.pin && promotion.pin.image;
    // let pin = (promotion.pin && promotion.pin.name) ? promotion.pin.name.split(" ")[0] : "";

    let stamps = [];
    let required_stamps = promotion.required_stamps
      ? promotion.required_stamps
      : 0;
    if (required_stamps > 0) {
      for (let i = 0; i < required_stamps; i++) {
        stamps.push(promotion.available_stamps > i);
      }
    }

    let body = (
      <figure>
        {/* <ReactImageAppear
          className="cover"
          loader={placeholder}
          loaderClass="cover"
          placeholderClass={"placeholder"}
          src={promotion.image}
          animationDuration="1s"
        />
        { props.promotion.type !== "GIFT_CARD" && <div className={"shadow-image"} /> } */}
        {promotion.type === "GIFT_CARD" ? (
          <div>
            <div
              className="company-logo"
              style={{
                backgroundImage: promotion.company_retail
                  ? `ùrl(${promotion.company_retail.logo})`
                  : `none`,
              }}
            />
          </div>
        ) : (
          // <div className="info ml-2">
          //   <div className="col">
          //     {required_stamps <= 0 ? (pinImage ?
          //         <img alt={"pin"} className="pin-image" src={promotion.pin.image}/> : <p className="pin">{pin}</p>
          //       ) :
          //       (
          //         <div className="row stamps p-0">
          //           {stamps.map(function (item, i) {
          //             return <div className="stamp" style={item ? {background: color} : {}}
          //                         key={`stamp${promotion.uid}${i}`}/>
          //           })
          //           }
          //         </div>
          //       )
          //     }
          //   </div>
          //   <div className="row detail">
          //     <div className="col">
          //       <p className="title">{promotion.title} </p>
          //       <p className="subtitle">{promotion.company_retail.name}</p>
          //     </div>
          //   </div>
          // </div>
          <>
          {promotion.pin ? (
            <>
            {promotion.type !== "STAMPS" && (
              <div className="DiscountBox" >
                <div className="DiscountType" style={{ background: `#${colorDiscound}`}}>
                  {promotion.type === "RATE" && (
                    <>
                      {promotion.pin.name.includes("100%") ? (
                        <>
                          <h1>GRA</h1>
                          <h1>TIS</h1>
                        </>
                      ) : (
                        <>
                          {promotion.pin.name && <h1>{promotion.pin.name}</h1>}
                          <h2>de&nbsp;dscto.</h2>
                        </>
                      )}
                    </>
                  )}
                  {promotion.type === "FIXED" && (
                    <>
                      {promotion.fixed_discount && (
                        <>
                          {app.company.currency.symbol && <h3>{app.company.currency.symbol}</h3>}
                          <h3>{promotion.fixed_discount.replace(".", ",")}</h3>
                        </>
                      )}
                    </>
                )}
                  {/* {promotion.type !== "RATE" && (
                    <>
                      {promotion.pin.name.includes("Promo") ? (
                        <>
                          <h1>PRO</h1>
                          <h1>MO</h1>
                        </>
                      ) : (
                        <h1>{promotion.pin.name}</h1>
                      )}
                    </>
                  )} */}
                </div>
                <div className="DiscountDateBox">
                  <h1>
                    {promotion.title.substring(0, 18)}
                    {promotion.title.length > 18 && "..."}
                  </h1>
                  <p>
                    {promotion.company_retail.description.substring(0, 100)}
                    {promotion.company_retail.description.length > 100 && "..."}
                  </p>
                  <div className="DiscountDate" style={{ background: `#${colorDiscound}`}}>
                  </div>
                </div>
              </div>
            )}
            </>
          ) :  <>
          {promotion.type !== "STAMPS" && (
            <div className="DiscountBox">
              <div className="DiscountType" style={{ background: `#${colorDiscound}`}} >
                {promotion.type === "GIFT" && (
                  <>
                    <h1>GRA</h1>
                    <h1>TIS</h1>
                  </>
                )}{promotion.type === "SPECIAL_PRICE" && (
                  <>
                    <h1>ESPE</h1>
                    <h1>CIAL</h1>
                  </>
                )}
                {promotion.type === "RATE" && (
                  <>
                    {promotion.rate_discount===100 ? (
                      <>
                        <h1>GRA</h1>
                        <h1>TIS</h1>
                      </>
                    ) : (
                      <>
                        <h1>{promotion.rate_discount}%</h1>
                        <h2>de dscto.</h2>
                      </>
                    )}
                  </>
                )}
                {promotion.type === "FIXED" && (
                    <>
                      {promotion.fixed_discount && (
                        <>
                          {app.company.currency.symbol && <h3>{app.company.currency.symbol}</h3>}
                          <h3>{promotion.fixed_discount.replace(".", ",")}</h3>
                        </>
                      )}
                    </>
                )}
              </div>
              <div className="DiscountDateBox">
                <h1>
                  {promotion.title.substring(0, 18)}
                  {promotion.title.length > 18 && "..."}
                </h1>
                <p>
                  {promotion.content && promotion.content.substring(0, 100)}
                  {promotion.content && promotion.content.length > 100 && "..."}
                </p>
                <div className="DiscountDate" style={{ background: `#${colorDiscound}`}}>
                </div>
              </div>
            </div>
          )}
          </>}
            
          </>
        )}

      </figure>
    );
    let url = "";
    if (multisite && company) url = `/c/${company.uid}`;
    return (
      <div>
        {!props.promotion.is_available ? (
          <>
          <div style={{opacity: "0.5"}}>{body}</div>
          {promotion.is_available === false && (
          <div className="used-promotion-container">
            {props.promotion.is_used > 0 ? (
              <p>La promoción ya fue usada</p>
            ) : (
              <p>La promoción ya no puede ser usada</p>
            )}
          </div>
        )}
          </>
        ) : (
          <Link
            to={`${url}/program/${programUid}/promotions/${props.promotion.uid}`}
          >
            {body}
          </Link>
        )}
      </div>
    );
  } else {
    return (
      <div className={`${style_class} load`}>
        <div>
          <figure>
            <div className="info">
              <div className={"pin loading"}>
                <div />
              </div>
              <div className="row detail">
                <div className="col">
                  <p className="title loading" />
                  <p className="subtitle loading" />
                </div>
              </div>
            </div>
          </figure>
        </div>
      </div>
    );
  }
};

Promotion.defaultProps = {
  is_detail: false,
  loading: false,
  promotion: null,
  multisite: false,
  company: null,
  color: "#36AA8C",
};

Promotion.propTypes = {
  promotion: PropTypes.object,
  is_detail: PropTypes.bool,
  loading: PropTypes.bool,
  multisite: PropTypes.bool,
  company: PropTypes.object,
  color: PropTypes.string,
};
export default Promotion;
