import React from 'react'
import $ from "jquery";
import "./style.scss"
import {STATUS_ERROR, STATUS_SUCCESS} from "../../../../../common/utils/generic";
import {toast} from "react-toastify";

export default class ModalValidationByUser extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      promotionUid: "",
      qrCode: "",
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let {promotionUid,
      showModal,
      openPromotionDetail,
      getQRPromotion,
      qrCode,
      setQrCode,
      promotion,
      typePopUp,
      statusRedeem,
      clearStatusRedeem,
      getPromotionCurrent,
    } = this.props
    if (promotionUid !== this.state.promotionUid) {
      this.setState({promotionUid: promotionUid})
      getQRPromotion()
    }
    if(promotion && promotion.allow_qr && qrCode !== this.state.qrCode){
      this.setState({qrCode: qrCode})
      setQrCode(qrCode)
    }

    if (openPromotionDetail && typePopUp==="by_user") {
      this.setState({step: 1})
      $('#validationModal').modal('show');
      showModal(false);

    }
    $('body').css("padding", "0");

    console.log("statusRedeem", statusRedeem)
    if(statusRedeem===STATUS_SUCCESS) {
      this.setState({step: this.state.step + 1})
      getPromotionCurrent()
      clearStatusRedeem()
    }else {
      if(statusRedeem===STATUS_ERROR) {
        toast.error("La promoción no puede ser validada por el usuario")
        clearStatusRedeem()
      }
    }
  }

  handleCloseClick = (e) => {
    e.preventDefault()
    let {setPromotionUid} = this.props
    setPromotionUid("")
    $(this.modal).modal('hide');
  }


  render() {
    let {app:{subdomain, company: {logo_rectangle, logo}}, promotion, redeemByUser} = this.props
    let {step} = this.state
    let mainColor =  "#20a6ff"

    let logo_app = logo ? logo : logo_rectangle
    return (
      <div
        className={"modal fade"}
        ref={(modal) => this.modal = modal}
        id="validationModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-order-detail  modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-body ">
              <img src={logo_app} className="logo mx-auto"
                   alt="logo" style={subdomain!=="banbif" ? {width: 120} : {}}/>
              <h3 className={step===3 && "success-title"}>{step===1 ?
                "RECORDAR QUE ESTA PROMOCIÓN SOLO DEBE SER ACTIVADA SI ESTAS EN EL LOCAL" :
                step===2 ? "ENSEÑAR AL ESTABLECIMIENTO" : "FELICIDADES"
              } </h3>
              {
                promotion &&
                <div className={"content-detail mt-1 mb-4"}>
                  {
                    step===2 &&
                    <div>
                      <h1><strong className={"mb-1"}>{`${promotion.title}`}</strong><br/>
                        {`${promotion.company_retail ? promotion.company_retail.name : ""}`}</h1>
                      <p className={"mt-3"}>Máximo monto de consumo S/. {`${promotion.maximum_discount_amount ? promotion.maximum_discount_amount : ""}`}</p>
                      <p className={"mb-2"}>Solo se usa una por usuario</p>
                    </div>
                  }
                  <div className={step===1 ? "mt-4 mb-4" : "mt-4 mb-1"}>{step===3 ? "Acabas de usar una Promoción de" : "Descuento dado por programa de Beneficios de"} <strong className={"text-uppercase"}>{`${promotion.company_retail ? promotion.company_retail.name : ""}`}</strong></div>
                  {
                    step===3 &&
                    <div className={"mt-4"}>
                      Esperamos que hayas disfrutado el descuento
                    </div>
                  }
                  {
                    this.state.qrCode &&
                    <img src={this.state.qrCode} alt="qr" className={"qr-code"}/>
                  }
                </div>
              }
              <div className={"d-flex justify-content-between flex-column flex-lg-row flex-wrap mb-3"}>
                <button className={step===1 ? "btn-back col-12 col-lg-5" : "d-none"} onClick={e=>this.handleCloseClick(e)}
                        style={{backgroundColor: `${mainColor}`, border: `2px solid ${mainColor}`}}>
                  Cancelar
                </button>
                <button className={step===1 ? "btn-next col-12 col-lg-5" : "btn-next col-12"} onClick={e=> {
                  if(step===3) {
                    this.handleCloseClick(e)
                  }
                  if(step===2) {
                    e.preventDefault()
                    redeemByUser()
                  }
                  if (step === 1) {
                    e.preventDefault()
                    this.setState({step: step + 1})
                  }
                }} style={{backgroundColor: `${mainColor}`, border: `2px solid ${mainColor}`}}>
                  {step===1 ? "Estoy en el local" : step===2 ? "Promoción aceptada" : "Descubrir otras promociones"}
                </button>
              </div>
            </div>

            <button onClick={this.handleCloseClick} type="button" className="close close-modal">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
      </div>
    )
  }

}

