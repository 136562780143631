import React from "react";
import "./style.scss"
import Validator from "../../../common/utils/validator";

export default class SubscriptionDetailForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      name: ""
    }
  }

  componentDidMount() {
    this.updateComponent()
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    this.updateComponent()
  }

  renderError(errors, fieldName) {
    return errors && errors[fieldName] &&
      <span className='field-error-message'>
        {errors[fieldName][0]}
      </span>;
  }

  validate = () => {
    return this.validator.validate({...this.state})
  }


  updateComponent() {
    if(!this.validator){
      this.validator = new Validator([
        {
          id: "name",
          ref: this.nameInput,
          rules: ["required"]
        }
      ])
    }
  }

  setFormValues = (propertiesToUpdate) => {
    let {setInfoSubscriptionDetail} = this.props
    let payload = {...this.state, ...propertiesToUpdate}
    this.setState(propertiesToUpdate);
    if(this.validator)
      this.validator.validate(propertiesToUpdate);
    setInfoSubscriptionDetail({...payload,isValid:this.validator.validateVisibleError(payload,false)})
  }

  render() {
    let {
      errors
    } = this.props;

    return (
      <div className={"payment_user_info card"}>
        <div className={"title-bar-section"}>
          <h6 className={"col-10 col-lg-12 mx-auto"}>Nombre para su suscripcion</h6>
        </div>
        <div className={"col-10 col-lg-12 mx-auto p-0"}>
          <div className="form-group col-12 pl-0 mt-3">
            <div
              className="input-group"
              ref={(input) => {
                if (input) {
                  this.nameInput = input.querySelector('input');
                }
              }}
            >
              <input
                className={"form-control"}
                name={"name"}
                value={this.state.name}
                onChange={e => this.setFormValues({name: e.target.value})}
                type="text"
                placeholder="Nombre de la suscripción *"
              />
            </div>
            {this.renderError(errors, 'name')}
          </div>
        </div>
      </div>
    )
  }
}


