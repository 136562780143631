import {STATUS_ERROR, STATUS_LOADING, STATUS_SUCCESS} from "../common/utils/generic";
import {GET_SCHEDULE} from "../actions/places";


const initialState = {
  entity: null,
  status: null,
};

export default function reducer(state = initialState, action) {
  const {type, payload} = action;
  let data;
  switch (type) {

    case `${GET_SCHEDULE}_PENDING`:
      return {
        ...state,
        status: STATUS_LOADING,
        entity: null
      };
    case `${GET_SCHEDULE}_FULFILLED`:
      data = payload.status === 200 ? payload.data: null;
      return {
        ...state,
        entity: data,
        status: payload.status === 200 ? STATUS_SUCCESS : STATUS_ERROR,
      };

    default:
      return state;
  }
}
