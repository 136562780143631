import React, {Fragment} from 'react'
import './style.scss';
import ReactImageAppear from "react-image-appear";
import placeholder from "../../../../../common/images/placeholder.jpg";
import {STATUS_LOADING, STATUS_SUCCESS} from "../../../../../common/utils/generic";
import {toast} from "react-toastify";
import {CART_PRODUCTS} from "../../../../../actions/cart";
import CoinSvg from "../../../../../common/images/Coin.svg";
const $ = require('jquery');

export default class ProductPopUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      placeUid: "",
      productUid: "",
      productQuantity: 1,
      optionGroupUid: "",
      options: [],
      direct_purchase: false,
      inCart: false,
      load: true
    }
  }

  componentDidMount() {
    this.updateComponent();
  }

  componentDidUpdate() {
    this.updateComponent();
  }

  updateComponent() {
    const {productsByPlaceCurrent, activePopUp, openPopUp, getProductCurrent, placeUid, uid, componentPopUp} = this.props
    console.log(productsByPlaceCurrent, "productsByPlaceCurrent")
    if (placeUid !== "" && uid !== "") {
      if ((this.state.placeUid !== placeUid) || (this.state.productUid !== uid)) {
        this.setState({
          placeUid: placeUid,
          productUid: uid,
          productQuantity:1,
          options: [],
          optionGroupUid: "",
          inCart: false
        })
        getProductCurrent(placeUid, uid.split('_')[0]);
        openPopUp(false, componentPopUp);
      }
    }
    if (activePopUp) {
      $('#productModal').modal('show');
      this.getProductCart(uid);
      openPopUp(false, componentPopUp);

    }
    $('body').css("padding", "0");
  }

  closeModal = (e) => {
    e.preventDefault()
    let {setProductUid} = this.props
    this.setState(  {
      placeUid: "",
      productUid: "",
      productQuantity: 1,
      options: [],
      optionGroupUid: "",
      inCart: false
    });
    setProductUid("")
    $(this.modal).modal('hide');
  }

  getNecessaryProperties = (productsByPlaceCurrent) => {
    let list = ["brand","description","image_reverse","short_description","sku"];
    let payloadAux = Object.assign({} , productsByPlaceCurrent)
    list.map( (key) => {
      if(key in payloadAux){
        delete payloadAux[key]
      }
      return true;
    })
    payloadAux["name"] = payloadAux.title
    delete payloadAux["title"]
    return payloadAux;
  }

  increase = (e) => {
    e.preventDefault()
    this.setState({
      productQuantity: (this.state.productQuantity+1)
    });
  };

  decrease = (e) => {
    e.preventDefault()
    if(this.state.productQuantity>0){
      this.setState({
        productQuantity: (this.state.productQuantity-1)
      });
    }
  };

  addProductsCart = (e) => {
    e.preventDefault()

    $('#items').addClass('load');
    setTimeout(() => {
      $('#items').removeClass('load');
    }, 4000);

    let { updateProductCart, multisite, company:{uid:uidCompany}, uid, setProductUid, authStatus, addProductCart,addProductCartSubscription, addProductsBack, selectedPlace, productsByPlaceCurrent,entitiesCart, typeCart,redirect=true } = this.props

    if(typeCart===CART_PRODUCTS){
      if(selectedPlace!==null && selectedPlace!==undefined){
        let payload = this.getNecessaryProperties(productsByPlaceCurrent)
        payload["quantity"] = this.state.productQuantity;
        payload["options"] = this.state.options;
        payload["optionGroupUid"] = this.state.optionGroupUid;
        payload["id"] = uid;

        console.log(payload, "NECES")
        this.setState({
          inCart:true
        })
        if(payload.option_groups.length!==0){

          let aux = []
          payload.option_groups.map((group, index)=>{
            aux = []

            group.options.map(o=>{
              if(this.state.options.includes(o.uid)){
                aux.push(o)
              }
              return true
            })

            group.options  = aux
            return true;
          })

        }

        if(authStatus==="success" ){
          let listProducts = []
          if(entitiesCart!==null && entitiesCart!==undefined && entitiesCart.products!==undefined){

            Object.keys(entitiesCart.products).map((p,index) => {

              if(entitiesCart.products[p].option_groups.length!==0){
                entitiesCart.products[p].option_groups.map((group, index)=>{
                  delete group.name
                  delete group.description
                  delete group.minimum_to_select
                  delete group.maximum_to_select
                  delete group.is_active

                  group.options.map(o => {
                    delete o.price
                    delete o.description
                    delete o.is_active
                    return true
                  })
                  return true;

                })

                listProducts.push({
                  id: entitiesCart.products[p].id,
                  uid: entitiesCart.products[p].uid,
                  direct_purchase: entitiesCart.products[p].direct_purchase,
                  quantity:entitiesCart.products[p].quantity,
                  option_groups: entitiesCart.products[p].option_groups
                })
              }else{
                listProducts.push({
                  id: entitiesCart.products[p].id,
                  uid: entitiesCart.products[p].uid,
                  direct_purchase: entitiesCart.products[p].direct_purchase,
                  quantity:entitiesCart.products[p].quantity,
                  option_groups: []
                })
              }
              return true;
            })

            if(listProducts.length===0){

              payload.option_groups.map((group, index)=>{
                delete group.name
                delete group.description
                delete group.minimum_to_select
                delete group.maximum_to_select
                delete group.is_active

                group.options.map(o => {
                  delete o.price
                  delete o.description
                  delete o.is_active
                  return true
                })

                return true
              })
              listProducts.push({
                id: payload.id,
                direct_purchase: payload.direct_purchase,
                uid: payload.uid, quantity:payload.quantity,
                option_groups: payload.option_groups.length!==0 ? payload.option_groups : []
              })
            }else{
              let found = -1;
              listProducts.map((p, index) => {
                if(p.id === payload.id){
                  found= index
                }
                return true;
              })
              if(found !== -1 ){
                listProducts[found].quantity = payload.quantity

                payload.option_groups.map((group, index)=>{
                  delete group.name
                  delete group.description
                  delete group.minimum_to_select
                  delete group.maximum_to_select
                  delete group.is_active

                  group.options.map(o => {
                    delete o.price
                    delete o.description
                    delete o.is_active
                    return true
                  })
                  return true

                })
                listProducts[found] = ({
                  id: payload.id,
                  direct_purchase: payload.direct_purchase,
                  uid: payload.uid, quantity:payload.quantity,
                  option_groups: payload.option_groups.length!==0 ? payload.option_groups : []
                })

              }else{

                payload.option_groups.map((group, index)=>{
                  delete group.name
                  delete group.description
                  delete group.minimum_to_select
                  delete group.maximum_to_select
                  delete group.is_active

                  group.options.map(o => {
                    delete o.price
                    delete o.description
                    delete o.is_active
                    return true
                  })

                  return true
                })
                listProducts.push({
                  id: payload.id,
                  direct_purchase: payload.direct_purchase,
                  uid: payload.uid, quantity:payload.quantity,
                  option_groups: payload.option_groups.length!==0 ? payload.option_groups : []
                })

              }
            }
          }
          console.log(payload, "PAYLOADDDD", listProducts, "LISTPRODUCTS")
          if(payload.direct_purchase){
            let aux = []
            let existDirectPurchase = false
            listProducts.map(p=>{
              if(p.direct_purchase){
                existDirectPurchase = true
              }
              return true;
            })
            if(!existDirectPurchase){
              listProducts.map(p=>{
                if(p.uid===payload.uid && p.direct_purchase){
                  aux.push(p)
                }
                return true;
              })
            }else{
              listProducts.map(p=>{
                if(p.uid===payload.uid && p.direct_purchase){
                  aux.push(p)
                }
                return true;
              })
            }
            console.log(aux[0], payload.uid, "/*-*--*-*-*-*-")
            addProductsBack({place: selectedPlace.uid, products: [aux[0]]})
          }else{
            let aux = []
            let deleteAux = []
            listProducts.map(p=>{
              if(!p.direct_purchase){
                aux.push(p)
              }else{
                deleteAux.push(p)
              }
              return true;
            })
            addProductsBack({place: selectedPlace.uid, products: aux})
            deleteAux.map(p=>{
              updateProductCart(0, p.id, selectedPlace)
              return true;
            })
          }
        }
        setProductUid("")

        this.closeModal(e)

        if(payload.direct_purchase && selectedPlace){
          localStorage.removeItem(`cart${selectedPlace.uid}`);
          localStorage.setItem("skipBuyService", "true")
          this.props.cleanCart()
          addProductCart(payload, selectedPlace)
        }else{
          addProductCart(payload, selectedPlace)
        }



        //addProductCart(payload, selectedPlace)
        if(productsByPlaceCurrent.direct_purchase && redirect){
          if(multisite){
            this.props.history.push(`/c/${uidCompany}/cart`)
          }else{
            this.props.history.push("/cart")
          }
        }

        if(productsByPlaceCurrent && productsByPlaceCurrent.is_redeemable){
          localStorage.setItem("ItemExchange", "true")
        }else{
          localStorage.setItem("ItemExchange", "false")
        }
      }
    }else{

      if(selectedPlace!==null && selectedPlace!==undefined){
        let payload = this.getNecessaryProperties(productsByPlaceCurrent)
        payload["quantity"] = this.state.productQuantity;
        payload["options"] = this.state.options;
        payload["optionGroupUid"] = this.state.optionGroupUid;
        payload["id"] = uid;

        this.setState({
          inCart:true
        })
        if(payload.option_groups.length!==0){

          let aux = []
          payload.option_groups.map((group, index)=>{
            aux = []

            group.options.map(o=>{
              if(this.state.options.includes(o.uid)){
                aux.push(o)
              }
              return true
            })

            group.options  = aux
            return true;
          })

        }
        /*
          const placeCartSchema = new schema.Entity('place', {}, {idAttribute: 'uid'});


          let dataPlace = normalize(selectedPlace, placeCartSchema)

          if(payload.direct_purchase && selectedPlace){
            localStorage.removeItem(`cart${selectedPlace.uid}`);
            localStorage.setItem(`cart${selectedPlace.uid}`, JSON.stringify(
              {
                place: dataPlace.entities ? dataPlace.entities.place : [],
                products: []
              }
              )
            );
            console.log(selectedPlace.uid)
          }else{
            addProductCartSubscription(payload, selectedPlace)
          }
  */
        setProductUid("")
        this.closeModal(e)

        if(payload.direct_purchase && selectedPlace){
          localStorage.removeItem(`cart${selectedPlace.uid}`);
          localStorage.setItem("skipBuyService", "true")
          this.props.cleanCart()
          addProductCartSubscription(payload, selectedPlace)
        }else{
          addProductCartSubscription(payload, selectedPlace)
        }

        //addProductCartSubscription(payload, selectedPlace)
        if(productsByPlaceCurrent.direct_purchase){
          if(multisite){
            this.props.history.push(`/c/${uidCompany}/cart`)
          }else{
            this.props.history.push("/cart")
          }
        }
      }
    }

  }

  getProductCart = (productUid) => {
    let {entitiesCart, entitiesCartSubscription, typeCart} = this.props;

    if(typeCart===CART_PRODUCTS){
      if(entitiesCart && entitiesCart.products!==undefined){
        if(entitiesCart.products[productUid]!== undefined){

          if(entitiesCart.products[productUid].option_groups.length!==0){
            let optionsUids = []

            entitiesCart.products[productUid].option_groups.map((group, index)=>{
              group.options.map(o => {
                delete o.description
                delete o.is_active
                optionsUids.push(o.uid)
                return true
              })
              return true
            })

            this.setState({
              productQuantity: entitiesCart.products[productUid].quantity,
              options: optionsUids,
              productUid: entitiesCart.products[productUid].id,

              inCart:true
            })

          }else{

            this.setState({
              productQuantity: entitiesCart.products[productUid].quantity,
              options: [],
              optionGroupUid: "",
              productUid: entitiesCart.products[productUid].id,
              inCart:true
            })
          }
        }else{
          this.setState({
            productQuantity: 1,
            options: [],
            optionGroupUid: "",
            productUid:"",
            inCart:false
          })
        }
      }
    } else {

      if(entitiesCartSubscription && entitiesCartSubscription.products!==undefined){
        if(entitiesCartSubscription.products[productUid]!== undefined){

          if(entitiesCartSubscription.products[productUid].option_groups.length!==0){
            let optionsUids = []

            entitiesCartSubscription.products[productUid].option_groups.map((group, index)=>{
              group.options.map(o => {
                delete o.description
                delete o.is_active
                optionsUids.push(o.uid)
                return true
              })
              return true
            })

            this.setState({
              productQuantity: entitiesCartSubscription.products[productUid].quantity,
              options: optionsUids,
              productUid: entitiesCartSubscription.products[productUid].id,

              inCart:true
            })

          }else{

            this.setState({
              productQuantity: entitiesCartSubscription.products[productUid].quantity,
              options: [],
              optionGroupUid: "",
              productUid: entitiesCartSubscription.products[productUid].id,
              inCart:true
            })
          }
        }else{
          this.setState({
            productQuantity: 1,
            options: [],
            optionGroupUid: "",
            productUid:"",
            inCart:false
          })
        }
      }

    }
    this.setState({load: false})
  }

  updateCartBack = () => {
    let { selectedPlace, addProductsBack, entitiesCart, typeCart } = this.props
    let listProducts = []

    if (typeCart===CART_PRODUCTS){
      if (entitiesCart!==null && entitiesCart.products !== undefined) {
        Object.keys(entitiesCart.products).map((p,index) => {
          listProducts.push({
            id: entitiesCart.products[p].id,
            direct_purchase: entitiesCart.products[p].direct_purchase,
            uid: entitiesCart.products[p].uid, quantity:entitiesCart.products[p].quantity })
          return true;
        })
        addProductsBack({place: selectedPlace.uid, products: listProducts})
      }
    }

  }
  removeProductCart = (e) => {
    e.preventDefault()

    $('#items').addClass('load');
    setTimeout(() => {
      $('#items').removeClass('load');
    }, 4000);

    let {updateProductCart, updateProductCartSubscription, selectedPlace, typeCart, setProductUid } = this.props
    if (selectedPlace!==undefined) {
      if(typeCart===CART_PRODUCTS){
        updateProductCart(0, this.state.productUid, selectedPlace)
        this.updateCartBack()
      }else{
        updateProductCartSubscription(0, this.state.productUid, selectedPlace)
        this.updateCartBack()
      }
    }
    this.setState({
      inCart:false
    })

    setProductUid("")
    this.closeModal(e)
    toast.success("Se ha eliminado el producto de su carrito")
  }

  showRemoveButton = () => {
    if(this.state.inCart && this.state.productQuantity===0) {
      return true;
    }
    return false;
  }

  selectOptionsQuantity(productsByPlaceCurrent){
    let nOptions = [];
    if(productsByPlaceCurrent.option_groups.length!==0){

      productsByPlaceCurrent.option_groups.map(option_group=>{
        let i = 0
        if(option_group.length!==0){
          option_group.options.map(o=>{
            if(this.state.options.includes(o.uid)){
              i += 1
            }
            return true
          })
        }
        if(i>=option_group.minimum_to_select && i<=option_group.maximum_to_select){
          nOptions.push(true)
        }else{
          nOptions.push(false)
        }
        return true
      })
    }
    if(nOptions.includes(false)){
      return true
    }
    return false
  }

  getPrice(productsByPlaceCurrent){
    let aux=0
    if(productsByPlaceCurrent.option_groups){

      productsByPlaceCurrent.option_groups.map(group => {
        group.options.map(o=>{
          if(this.state.options.includes(o.uid)){
            aux += o.price
          }
          return true
        })
        return true
      })
    }

    return parseFloat((productsByPlaceCurrent.unit_price+aux)*this.state.productQuantity).toFixed(2)
  }

  render() {
    let {
      productsByPlaceCurrent,
      productsByPlaceCurrentStatus,
      currency,
      typeCart,
    } = this.props
    let nTopping = 0
    if(productsByPlaceCurrentStatus === STATUS_SUCCESS && productsByPlaceCurrent.option_groups && productsByPlaceCurrent.option_groups.length!==0){
      productsByPlaceCurrent.option_groups.map((option_group, index)=>{
        nTopping += option_group.options.length
        return true
      })
    }
    //console.log(this.props, "modal");

    return (
      <div>
        <div>
          <div
            className={"modal fade"}
            id="productModal"
            ref={(modal) => this.modal = modal}
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
            data-backdrop="static" data-keyboard="false"
          >
            <div
              className={productsByPlaceCurrentStatus === STATUS_LOADING ?
                "modal-dialog modal-dialog-centered modal-lg load modal-product" : "modal-dialog modal-dialog-centered modal-product  modal-lg"}
              role="document">
              <div
                className={productsByPlaceCurrentStatus === STATUS_LOADING ? "modal-content load" : "modal-content "}>
                <div
                  className={productsByPlaceCurrentStatus === STATUS_LOADING ? "modal-header load" : "modal-header "}>
                  {productsByPlaceCurrentStatus === STATUS_LOADING &&
                    <div className={"cover load"}>
                    </div>
                  }
                  { (productsByPlaceCurrentStatus === STATUS_SUCCESS ) &&
                    <ReactImageAppear
                      className="cover"
                      loader={placeholder}
                      loaderClass="cover"
                      placeholderClass={"placeholder"}
                      src={productsByPlaceCurrent.image ? productsByPlaceCurrent.image : ""}
                      animationDuration="1s"
                    />
                  }

                  <button type="button" className="close btn-close-ad btn-close-product" data-dismiss="modal" aria-label="Close"
                          style={{zIndex: 99, paddingRight: "25px"}} >
                    <span aria-hidden="true" style={{fontSize:"30px"}}>&times;</span>
                  </button>
                </div>

                <div className={nTopping===0 ? "modal-body not-topping-sm" : nTopping>4 ? "modal-body" : "modal-body  not-topping"}>
                  <div className={"detail detail-redemptions"}>
                    <div className="header-detail d-flex">
                      {productsByPlaceCurrentStatus === STATUS_SUCCESS &&
                        <Fragment>
                          <span>{productsByPlaceCurrent.title}</span>
                        </Fragment>
                      }
                    </div>
                    <div className="body-detail">
                      {productsByPlaceCurrentStatus === STATUS_SUCCESS ?
                        <Fragment>
                          {/* <p> Descripción</p> */}
                          <p>{productsByPlaceCurrent.short_description}</p>
                          {
                            productsByPlaceCurrent && productsByPlaceCurrent.is_redeemable ?
                              <p className="coin font-weight-bold"><img src={CoinSvg} alt="coin" /> {productsByPlaceCurrent && productsByPlaceCurrent.unit_price}</p>
                              :
                              <p className="price font-weight-bold">{currency ? currency.symbol : "S/."} {productsByPlaceCurrent && productsByPlaceCurrent.unit_price}</p>
                          }

                        </Fragment> : <p/>}
                    </div>
                    {
                      productsByPlaceCurrentStatus === STATUS_SUCCESS && productsByPlaceCurrent.option_groups && productsByPlaceCurrent.option_groups.length!==0 &&
                      productsByPlaceCurrent.option_groups.map((option_group, index)=>(

                        <div className={"topping"} key={`option_group${index}`}>
                          <div className="header-detail d-flex mt-4">
                            <span className={"col-12 pb-1"}>{option_group.name}</span>
                          </div>
                          <div className={"d-flex col-12 topping-min-max"}>
                            <p className={"topping-terms"} style={{marginRight: "0.15rem"}}>Escoge mínimo {option_group.minimum_to_select},</p>
                            <p className={"topping-terms"}>{" "}máximo {option_group.maximum_to_select} {option_group.maximum_to_select>1 ? "opciones": "opción"}</p>
                          </div>
                          <div className="body-detail">
                            {
                              option_group.options.map((p, index)=>(
                                <div className={index===option_group.options.length-1 ? "d-flex justify-content-between topping-block mt-0 align-items-center border-bottom-0" : "d-flex justify-content-between topping-block mt-0 align-items-center"} key={`option${index}`} >
                                  <div className={"topping-right col-10 pl-0 d-flex pr-0"}>
                                    <label htmlFor={`checkbox${option_group.uid}${p.uid}`} className="form-check-label mt-2" >
                                      <p>{p.name}</p>
                                      <span className={"topping-price"}>+{currency ? currency.symbol : "S/."}  {p.price}</span>
                                    </label>
                                  </div>
                                  <div className={"topping-left col-2 p-0 text-right"}>
                                    <div className={""}>
                                      <input type="checkbox" id={`checkbox${option_group.uid}${p.uid}`}
                                             checked={this.state.options.includes(p.uid)}
                                             onChange={e=>{
                                               if(this.state.options.includes(p.uid)){
                                                 let aux = this.state.options
                                                 let i = aux.indexOf( p.uid );
                                                 if ( i !== -1 ) {
                                                   aux.splice( i, 1 );
                                                 }
                                                 this.setState({options: aux, optionGroupUid: option_group.uid})
                                               }else{
                                                 this.setState({options:[...this.state.options, p.uid], optionGroupUid: option_group.uid})
                                               }
                                             }}/>
                                    </div>

                                  </div>
                                </div>
                              ))
                            }
                          </div>
                        </div>
                      ))

                    }
                  </div>
                    <div className="controls d-flex justify-content-sm-between">
                      {
                        productsByPlaceCurrentStatus === STATUS_SUCCESS && !productsByPlaceCurrent.direct_purchase &&productsByPlaceCurrent && !productsByPlaceCurrent.is_redeemable &&
                        <div className="quantity d-flex ">
                          <button type="button" disabled={productsByPlaceCurrentStatus === STATUS_LOADING}
                                  className="btn btn-default bg-white"
                                  onClick={e => this.decrease(e)}>-
                          </button>
                          <span>{productsByPlaceCurrentStatus === STATUS_SUCCESS && this.state.productQuantity}</span>
                          <button type="button" disabled={productsByPlaceCurrentStatus === STATUS_LOADING}
                                  className="btn btn-default"
                                  onClick={e => this.increase(e)}>+
                          </button>
                        </div>
                      }
                      <div className={productsByPlaceCurrentStatus === STATUS_SUCCESS && productsByPlaceCurrent.direct_purchase ? "add-cart col-12 col-sm-12 col-lg-12  mx-auto" : "add-cart "}>
                        {
                          this.showRemoveButton() &&
                          <button type="button" disabled={productsByPlaceCurrentStatus === STATUS_LOADING}
                                  className="btn btn-primary btn-delete" onClick={e => this.removeProductCart(e)}>Eliminar del carrito
                          </button>
                        }
                        { !this.showRemoveButton()  &&

                          <button type="button" disabled={
                            (productsByPlaceCurrentStatus === STATUS_LOADING) || (this.state.productQuantity===0) ||
                            (productsByPlaceCurrentStatus === STATUS_SUCCESS && this.selectOptionsQuantity(productsByPlaceCurrent))
                          }
                                  className={"btn btn-primary btn-redemptions"} onClick={e => this.addProductsCart(e)}>
                            {
                              productsByPlaceCurrentStatus === STATUS_SUCCESS && productsByPlaceCurrent.direct_purchase ?
                                productsByPlaceCurrent && productsByPlaceCurrent.is_redeemable ? (
                                  <div className='box-redemptions'>
                                    <h3>Canjear</h3>
                                    <p className="coin font-weight-bold"><img src={CoinSvg} alt="coin" /> {productsByPlaceCurrent && productsByPlaceCurrent.unit_price}</p>
                                  </div>
                                ) : "Adquirir"
                                :
                                (typeCart===CART_PRODUCTS) ? (!this.state.inCart) ?
                                    `Agregar ${currency ? currency.symbol : "S/."} ${productsByPlaceCurrent && this.getPrice(productsByPlaceCurrent)}` : "Editar producto" :
                                  (!this.state.inCart) ? "Agregar al carrito" : "Editar suscripción"
                            }
                          </button>
                        }
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
