import React, {Fragment} from 'react'
import PropTypes from 'prop-types';
import TermsConditionsGeneral from "../../../common/components/TermsConditionsGeneral";
import logo from "../../../common/images/logo.png";
import './style.scss';
import {STATUS_LOADING, STATUS_SUCCESS} from "../../../common/utils/generic";
import Footer from "../../footer/Footer";
import {CART_INVISIBLE} from "../../../actions/cart";
const $ = require('jquery');

export default class FAQS extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      appUid: "",
      faqs: ""
    }
  }

  componentDidMount() {
    const {changeSecondaryMenu, changeStateCart} = this.props
    if(changeSecondaryMenu && changeStateCart){
      changeSecondaryMenu()
      changeStateCart()
    }
    this.updateComponent()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.updateComponent()
  }

  updateComponent(){
    let {app, getApp, appFields, changeSecondaryMenu, changeStateCart, stateCart} = this.props;
    if(app.uid !== this.state.appUid){
      this.setState({appUid: app.uid})
      getApp(app.uid)
    }
    console.log(appFields,"appFieldsfaqs")
    if(appFields && appFields.site && appFields.site.faqs){
      if(appFields.site.faqs !== this.state.faqs){
        this.setState({faqs: appFields.site.faqs})
        $("#terms-box").html(`<div class="mx-auto col-lg-10 col-md-12 pt-4 pb-2">${appFields.site.faqs}</div>`)
      }
    }

    if(stateCart!=="CART_INVISIBLE" && changeSecondaryMenu && changeStateCart){
      changeSecondaryMenu()
      changeStateCart()
    }
  }

  render() {
    let {app, authStatus, appFields, statusAppFields} = this.props;
    let logo_app = app.company.logo_rectangle ? app.company.logo_rectangle: app.logo
    console.log(statusAppFields, "statusAppFields")
    return (
      <div className={"section"}>

        <div className={"title-bar-section"}>
          <div className="container">
            <h6 className={"mx-auto col-lg-10 col-md-12"}>FAQs</h6>
          </div>
        </div>
        {
            (authStatus === STATUS_SUCCESS || app.allow_anonymous_navigation === true) ?
              <div className="container">
                <div className="row terms-and-conditions"  id={"terms-box"}>
                  {
                    statusAppFields===STATUS_SUCCESS ?
                    !(appFields && appFields.site && appFields.site.privacy_policies) &&
                    <TermsConditionsGeneral
                      app={app.name} /> :
                      !appFields && statusAppFields===STATUS_LOADING ?
                        <div className={"mx-auto col-lg-10 col-md-12"}>
                          <p className={"load"}></p>
                          <p className={"load"}></p>
                          <p className={"load"}></p>
                        </div>
                        : <p></p>
                  }
                </div>
              </div> :

              <Fragment>
                <div className={"rendered-content"}>
                  <div className="elevation-terms mx-auto col-12 col-md-10 col-lg-8" id={"terms-box"}>
                    {
                      !(appFields && appFields.site && appFields.site.privacy_policies) &&
                      <Fragment>
                        <img src={logo_app?logo_app:logo} className="logo" alt="logo"/>
                        <div className={"container p-4"}>
                          <div className={"row"}>
                          </div>
                        </div>
                      </Fragment>
                    }
                  </div>
                </div>
                <Footer
                  stateCart={CART_INVISIBLE}></Footer>
              </Fragment>
        }
      </div>
    );
  }
}

FAQS.defaultProps = {
  app: null,
  authStatus: ""
};

FAQS.propTypes = {
  app: PropTypes.object,
  authStatus: PropTypes.string
};

