import HttpClient from "../common/utils/httpClient";
import Help from "../common/utils/generic";


export const GET_PLACES = 'GET_PLACES';
export const SELECT_PLACE = 'SELECT_PLACE';
export const GET_SCHEDULE = 'GET_SCHEDULE';


/**Urls**/
export const LIST_PLACES_URL = 'v7/places/';
export const LIST_PLACES_SCHEDULE_URL = 'v7/places/{uidPlace}/pickup-schedule/';

export function getPlaces(params) {
  let filters = Help.getParams(params)
  let url = LIST_PLACES_URL.replace("{company_uid}", params)
  return (dispatch) => HttpClient.get(dispatch, GET_PLACES, `${url}${filters}`);
}

export function getSchedule(placeUid, params) {
  let filters = Help.getParams(params)
  let url = LIST_PLACES_SCHEDULE_URL.replace("{uidPlace}", placeUid)
  return (dispatch) => HttpClient.get(dispatch, GET_SCHEDULE, `${url}${filters}`);
}

export function selectPlace(place) {
  return {
    type: SELECT_PLACE,
    place: place
  };
}

