import React, { Fragment } from "react";
import PropTypes from "prop-types";
import ProgramPromotionsList from "./programPromotions/ProgramPromotionsList";
// import ProgramPromotionsFilters from "./ProgramPromotionsFilters/ProgramPromotionsFilters";
import { STATUS_INCLUDE, STATUS_SUCCESS } from "../../common/utils/generic";
import ProgramAdsPopUp from "./programAdsPopUp";
import ProgramRafflesPopUp from "./programRafflesPopUp";
import CompanyAdsPopUp from "../products/companyAdsPopUp";
import ProductsPlaceList from "../products/productsPlace/ProductsPlaceList";
import { CART_INVISIBLE, CART_VISIBLE } from "../../actions/cart";
import { Link } from "react-router-dom";
import CoinSvg from "../../common/images/Coin.svg";
import ProductColumn from "../products/productsPlace/components/productCard/Product";
import Banner from "../bederr/Banner";

//Product
const $ = require("jquery");

export default class ProgramContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      uidProgram: "",
      selectedAreaUid: "",
      program: null,
      placeUid: "",
      //
      isEcommerceEnabled: null,
      programUid: "",
    };
  }

  componentDidMount() {
    const { changeStateCart, changeSecondaryMenu, changeTypeCart } = this.props;
    changeStateCart();
    changeSecondaryMenu();
    changeTypeCart();
    this.updateComponent();
  }

  existProgram = (uid) => {
    const { app } = this.props;
    if (app && app.allowed_programs) {
      for (let i = 0; i < app.allowed_programs.length; i++) {
        if (app.allowed_programs[i].uid === uid) return true;
      }
    }
    return false;
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if ($(".modal-backdrop").length > 1) {
      $(".modal-backdrop:first").remove();
    }
    this.updateComponent();
  }

  updateComponent() {
    const {
      match: {
        params: { uid },
      },
      getSections,
      getProgram,
      selectedAreaUid,
      getPromotions,
      getAds,
      getRaffles,
      programs: {
        entity: program,
        retrieveStatus: retrieveStatusProgram,
        addStatus,
      },
      getProductsByPlace,
      selectedPlace,
      //Product
      programs,
      company,
      changeStateCart,
    } = this.props;

    if (uid && uid !== this.state.uidProgram) {
      if (this.existProgram(uid)) {
        this.setState({ uidProgram: uid });
        getProgram(uid);
        getAds(uid, { type: "POP_UP" });
        getRaffles(uid);
      }
    }
    if (retrieveStatusProgram === STATUS_SUCCESS && program != null) {
      if (
        this.state.program == null ||
        (this.state.program && program.uid !== this.state.program.uid) ||
        this.state.selectedAreaUid !== selectedAreaUid
      ) {
        this.setState({ selectedAreaUid });
        this.setState({ program: program });
        getPromotions(uid, { area: selectedAreaUid });
        getSections(uid);
      }
    }
    if (
      addStatus === STATUS_SUCCESS &&
      retrieveStatusProgram === STATUS_INCLUDE &&
      program != null
    ) {
      getProgram(uid);
    }
    if (selectedPlace !== null && selectedPlace) {
      if (this.state.placeUid !== selectedPlace.uid) {
        this.setState({ placeUid: selectedPlace.uid });
        if (selectedPlace.uid !== null) {
          getProductsByPlace(selectedPlace.uid);
          // getSectionsToProducts(selectedPlace.uid);
        }
      }
    }

    //Product
    if (programs && programs.results.length !== 0) {
      if (this.state.programUid !== programs.results[0]) {
        this.setState({ programUid: programs.results[0] });
        getRaffles(programs.results[0]);
      }
    }
    if (company.ecommerce_enabled !== this.state.isEcommerceEnabled) {
      this.setState({ isEcommerceEnabled: company.ecommerce_enabled });
      if (company.ecommerce_enabled) {
        changeStateCart(CART_VISIBLE);
      } else {
        changeStateCart(CART_INVISIBLE);
      }
    }
  }

  renderStatus = (rule) => {
    switch (rule) {
      case "FOR_COIN_WHEN_BUYING":
        return "Por cada moneda que gaste en una compra";
      case "FOR_SOLVING_SURVEY":
        return "Por resolver una encuesta";
      case "FOR_SEEING_NOTIFICATION":
        return "Por ver una notificación";
      case "FOR_DIRECT_CONSUMPTION":
        return "Por un consumo directo";
      default:
        return "--";
    }
  };

  renderMission = (rules, type = "coin") => {
    return (
      <div key={`rule-${rules.uid}`} className="rule-gamification ml-4">
        <h5>{this.renderStatus(rules.rule)}</h5>
        {/* <h5>{rules.rule_name}</h5> */}
        <div className="coin-container-menu d-flex mission-coin-pint">
          <h4>
            <span>{type === "coin" && <img src={CoinSvg} alt="coin" />}</span>
            {rules.reward}
            {type === "point" && " pts"}
          </h4>
        </div>
      </div>
    );
  };

  render() {
    let {
      match: {
        params: { uid },
      },
      app,
      company,
      multisite,
      programs: {
        entity: program,
        addStatus,
        retrieveStatus: retrieveStatusProgram,
      },
      promotions: {
        entities: programPromotionsEntities,
        results: programPromotionsResults,
        listStatus,
        listNextStatus,
        nextPage,
        count,
      },
      // sections:{
      //   entities: sectionsEntities,
      //   results: sectionsResults,
      //   listStatus: sectionsStatus,
      // },
      // getPromotions,
      addProgram,
      getMoreProgramPromotions,
      ads: {
        entities: adsEntities,
        results: adsResults,
        listStatus: adsStatus,
      },
      raffles: {
        entities: rafflesEntities,
        results: rafflesResults,
        listStatus: rafflesStatus,
        register: rafflesRegister,
        registerStatus: rafflesRegisterStatus,
        dismiss: rafflesDismiss,
        dismissStatus: rafflesDismissStatus,
      },
      registerInRaffle,
      dismissToRaffle,
      history,
      adsCompany,
      productsPlace,

      //Product
      sectionsProducts: {
        entities: sectionsEntities,
        results: sectionsResults,
      },
      authStatus,
      selectedPlace,
      productsPlace: {
        entities: productsByPlaceEntities,
        results: productsByPlaceResults,
        listProductsByPlaceStatus,
        listNextStatus: ProductListNextStatus,
        nextPage: ProductNextPage,
        count: ProductCount,
        productsByPlaceCurrent,
        productsByPlaceCurrentStatus,
      },
      getMoreProductsByPlace,
      getProductCurrent,
      openPopUp,
      activePopUp,
      componentPopUp,
      addProductCart,
      addProductsBack,
      entitiesCart,
      updateProductCart,
      typeCart,
      cleanCart,
    } = this.props;
    let url = "";
    if (multisite && company) url = `/c/${company.uid}`;
    return (
      <Fragment>
        <Banner
          history={history}
          app={app}
          company={company}
          addStatus={addStatus}
          retrieveStatus={retrieveStatusProgram}
          program={program}
          selectedPlace={selectedPlace}
        />


        {false && adsCompany && adsCompany.length !== 0 && (
          <CompanyAdsPopUp adsCompany={adsCompany} />
        )}

        {adsStatus === STATUS_SUCCESS && adsResults.length !== 0 && (
          <ProgramAdsPopUp
            app={app}
            ads={adsResults.map((cUid) => adsEntities[cUid])}
            adsStatus={adsStatus}
          />
        )}

        {rafflesStatus === STATUS_SUCCESS &&
          rafflesResults.length !== 0 &&
          false && (
            <ProgramRafflesPopUp
              app={app}
              raffles={rafflesResults.map((cUid) => rafflesEntities[cUid])}
              rafflesStatus={rafflesStatus}
              history={history}
              registerInRaffle={(raffleUid) =>
                registerInRaffle(this.state.uidProgram, raffleUid)
              }
              dismissToRaffle={(raffleUid) =>
                dismissToRaffle(this.state.uidProgram, raffleUid)
              }
              rafflesRegister={rafflesRegister}
              rafflesRegisterStatus={rafflesRegisterStatus}
              rafflesDismiss={rafflesDismiss}
              rafflesDismissStatus={rafflesDismissStatus}
            />
          )}

        {/* <Fragment>
              <ProgramPromotionsFilters
                sections={sectionsResults.map(cUid => sectionsEntities[cUid])}
                listSectionsStatus={sectionsStatus}
                retrieveStatusProgram={retrieveStatusProgram}
                getPromotions={(params)=> { if(uid) getPromotions(uid,params)}}
              />

            </Fragment> */}
        <ProgramPromotionsList
          selectedPlace={selectedPlace}
          programPromotions={programPromotionsResults.map(
            (cUid) => programPromotionsEntities[cUid]
          )}
          count={count}
          multisite={multisite}
          company={company}
          nextPagePromotions={nextPage}
          listProgramPromotionsStatus={listStatus}
          listNextProgramPromotionsStatus={listNextStatus}
          getMor  eProgramPromotions={getMoreProgramPromotions}
          programUid={uid}
          countDiscount={6}
          moreDiscount={true}
          app={app}
          addProgramUser={() => addProgram(uid)}
          addStatus={addStatus}
          retrieveStatus={retrieveStatusProgram}
          program={program}
          productsPlace={productsPlace}
        />

        <div>
          {company &&
          selectedPlace &&
          company.gamification_system &&
          productsPlace.entities !== null && (
            <div
              className={
                "container program-promotions program-promotions__discount"
              }
            >
              {
                retrieveStatusProgram === STATUS_SUCCESS && authStatus !== null ?
                  <div className={productsPlace.results.length !== 0 ? "exchange-container" : "d-none"}>
                    <div className="DiscountBoxList">
                      <h3 className={"category-title mt-4"}>CANJES</h3>
                      <h4 className={"category-title mt-4"}>
                        {productsPlace.results.length > 8 && (
                          <Link to={`${url}/program/${uid}/more-exchange`}>
                            Ver más
                          </Link>
                        )}
                      </h4>
                    </div>
                    <span className={productsPlace.results.length !== 0 ? "category-description" : "d-none"}>Gana más monedas con tus consumos para canjear productos</span>
                    <ProductsPlaceList
                      multisite={multisite}
                      sections={sectionsResults.map(
                        (cUid) => sectionsEntities[cUid]
                      )}
                      productsPlace={productsByPlaceResults.map(
                        (cUid) => productsByPlaceEntities[cUid]
                      )}
                      count={ProductCount}
                      company={company}
                      nextPageProducts={ProductNextPage}
                      listProductsByPlaceStatus={listProductsByPlaceStatus}
                      listNextProductsByPlaceStatus={ProductListNextStatus}
                      getMoreProductsByPlace={getMoreProductsByPlace}
                      placeUid={selectedPlace.uid}
                      selectedPlace={selectedPlace}
                      productsByPlaceCurrent={productsByPlaceCurrent}
                      productsByPlaceCurrentStatus={productsByPlaceCurrentStatus}
                      openPopUp={openPopUp}
                      activePopUp={activePopUp}
                      componentPopUp={componentPopUp}
                      getProductCurrent={getProductCurrent}
                      addProductCart={addProductCart}
                      addProductsBack={addProductsBack}
                      entitiesCart={entitiesCart}
                      authStatus={authStatus}
                      updateProductCart={updateProductCart}
                      currency={company.currency}
                      typeCart={typeCart}
                      history={history}
                      cleanCart={cleanCart}
                      newStyle={true}
                      countProduct={8}
                    />
                  </div> :
                  <div className="exchange-container first">
                    <div className="DiscountBoxList">
                      <h3 className={"category-title mt-4"} style={{opacity: "0.3"}}>CANJES</h3>
                    </div>
                    <div style={{position: "relative", height: 150}}>
                      <div className="DiscountBoxList flex-row exchanges" style={{opacity: "0.3", paddingBottom: 50}}>
                        <ProductColumn loading={true}/>
                        <ProductColumn loading={true}/>
                        <ProductColumn loading={true}/>
                        <ProductColumn loading={true}/>
                      </div>
                      <div className={"promotions-backdrop"}>
                        </div>
                    </div>
                  </div>
              }
              <br />
              <div className={authStatus === null ? company.gamification_rules.length > 0 ? "exchange-container mt-lg-5 pt-lg-5" : "d-none" : "exchange-container"}>
                <div style={authStatus === null ? { position: "relative", height: 120, marginTop: 50 } : { position: "relative", minHeight: 120 }}>
                  <div
                    className="DiscountBoxList"
                    style={authStatus === null ? { opacity: "0.3" } : {}}
                  >
                    {company.gamification_rules.length > 0 && (
                      <h3 className={"category-title mt-0 ml-3"}>
                        MISIONES DE MONEDAS
                      </h3>
                    )}
                    {/* <h4 className={"category-title mt-4"}>
                      { productsPlace.results.length > 3 && <Link to={`${url}/program/${programUid}/more-exchange`}>Ver más</Link>}
                    </h4> */}
                  </div>
                  <div style={authStatus === null ? { opacity: "0.3" } : {}}>
                    {company.gamification_rules.map((rules) => {
                      if (rules.type_reward === "COINS") {
                        return this.renderMission(rules);
                      }
                      return true;
                    })}
                  </div>

                  {authStatus === null && (
                    <div className={"promotions-backdrop"}>

                    </div>
                  )}
                </div>
                <br />
                <br />
                <div style={{ position: "relative" }}>
                  <div
                    className="DiscountBoxList"
                    style={authStatus === null ? { opacity: "0.3" } : {}}
                  >
                    {company.gamification_rules.length > 0 && (
                      <h3 className={"category-title mt-4 ml-3"}>
                        MISIONES DE PUNTOS
                      </h3>
                    )}
                  </div>
                  <div style={authStatus === null ? { opacity: "0.3" } : {}}>
                    {company.gamification_rules.map((rules) => {
                      if (rules.type_reward === "POINTS") {
                        return this.renderMission(rules, "point");
                      }
                      return true;
                    })}
                  </div>

                  {authStatus === null && (
                    <div className={"promotions-backdrop"}>

                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>

      </Fragment>
    );
  }
}

ProgramContainer.defaultProps = {
  programs: null,
  app: null,
  promotions: null,
};

ProgramContainer.propTypes = {
  app: PropTypes.object,
  programs: PropTypes.object,
  promotions: PropTypes.object,
  getProgram: PropTypes.func,
  getSections: PropTypes.func,
  getPromotions: PropTypes.func,
  getMoreProgramPromotions: PropTypes.func,
};
