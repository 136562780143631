import React from 'react';
import {toast} from "react-toastify";

import PropTypes from 'prop-types';
import './style.scss';
import {STATUS_SUCCESS} from "../../../common/utils/generic";
const $ = require('jquery');

export default class ProgramRafflesPopUp extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      openModal: false,
      showDismiss: false
    };
  }

  componentDidMount() {
    if(!this.state.openModal){
      $('#rafflesModal').modal('show');
      this.setState({openModal: true})
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let {rafflesDismissStatus} = this.props
    if ( $(".modal-backdrop").length > 1 ) {
      $('.modal-backdrop:first').remove();
    }
    if(this.state.showDismiss && rafflesDismissStatus===STATUS_SUCCESS){
      console.log("aqui")
      toast.success("Se eliminó correctamente el sorteo")
      this.setState({showDismiss: false})
    }
  }

  closeModal = (e) => {
    e.preventDefault()
    $(this.modal).modal('hide');
    $('body').removeClass('modal-open');
    $('.modal-backdrop').remove();
    this.setState({openModal: false})
  }

  renderRaffles = () => {
    let {raffles, history, dismissToRaffle} = this.props

    return raffles.map((raffle, index) => (
      <div className={"raffle-item col-lg-6 mb-4"} key={`raffle${index}`}>
        <img
          src={raffle.image}
          alt={"img"}
          className={"d-block w-100"}
          style={{borderRadius: 8}}
        />
        <h3 className={"mt-1"} style={{fontWeight: 700}}>{raffle.name}</h3>
        <p className={"mb-0"}>Términos y condiciones</p>
        <p className={"mb-2"}>{raffle.terms.substr(0,100)}{raffle.terms.length>100 && " ..."}</p>
        <button
          style={{textOverflow:"ellipsis", overflow: "hidden", width:"100%"}}
          className="btn btn-raffle btn-primary btn-refuse-raffle mb-1" onClick={e=>{
          e.preventDefault()
          dismissToRaffle(raffle.uid)
          this.setState({showDismiss: true})
          this.closeModal(e)
        }}>
          No me interesa
        </button>
        <button
          style={{textOverflow:"ellipsis", overflow: "hidden", width:"100%"}}
          className="btn btn-raffle btn-primary mt-2 btn-accept-raffle" onClick={e=>{
          this.closeModal(e)
          e.preventDefault()
          history.push(`/raffles/${raffle.uid}/`);
        }}>
          Participar
        </button>
      </div>
    ));
  }

  render() {
    let {raffles, rafflesStatus} = this.props
    return (
      <div>
        {
          raffles.length !==0 &&
          <div>
            <div
              className={"modal fade"}
              id="rafflesModal"
              ref={(modal) => this.modal = modal}
              tabIndex="-1"
              role="dialog"
              aria-labelledby="exampleModalLabel2"
              aria-hidden="true"
            >
              <div
                className={"modal-dialog modal-dialog-centered modal-lg"}
                role="document">
                <div
                  className={"modal-content"}>
                  <div className="modal-header d-flex align-items-center" style={{height:80, borderBottom: 0}}>
                    <h3 className={"mb-0 raffle-name"} >TUS SORTEOS</h3>
                    <button type="button" className=" btn-primary btn-close btn-close-raffle" data-dismiss="modal" aria-label="Close"
                            style={{zIndex: 99, paddingRight: "25px"}}>
                      Cerrar
                    </button>
                  </div>
                  <div className="modal-body pt-0">
                    <div className={"d-flex flex-wrap"}>
                      { (rafflesStatus=== STATUS_SUCCESS ) &&
                      this.renderRaffles()
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    );
  }
}

ProgramRafflesPopUp.defaultProps = {
  program: null,
  app: null,
  retrieveStatus: ''
};

ProgramRafflesPopUp.propTypes = {
  program: PropTypes.object,
  app: PropTypes.object,
  getProgram: PropTypes.func,
  retrieveStatus: PropTypes.string
};


