import React, { Fragment } from "react";
import "./style.scss";
import { STATUS_LOADING, STATUS_SUCCESS } from "../../common/utils/generic";
import NavPlaces from "./components/NavPlaces";
import { Link } from "react-router-dom";
import { CART_PRODUCTS, CART_SUBSCRIPTION } from "../../actions/cart";
import programIcon from "../../common/images/program.svg";
import backIcon from "../../common/images/back.svg";
import cartIcon from "../../common/images/cart.svg";
import { SECONDARY_MENU_BACK } from "../../actions/menu";
import searchIcon from "../../common/images/search.svg";
import hamburgerIcon from "../../common/images/hamburger.png";
import ArrowSvg from "./assets/Svg";
import CoinSvg from "../../common/images/Coin.svg";
//import relatedIcon from "../../common/images/relatedIcon.svg";

export default class MainMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      companyUid: "",
      isVisibleCart: false,
      search: "",
      typing: false,
      typingTimeout: 0,
      openMenuNew: false,
      openCart: false,
      selectedArea: null,

      show_cart: true,
      show_store: true,
      show_program: true,

    };

    this.searchTerm = this.searchTerm.bind(this);
    // console.log(this.props.user);
  }

  componentDidMount() {
    let { getPrograms, loginStatus, app:{company} } = this.props;
    if (loginStatus === STATUS_SUCCESS) {
      getPrograms();
    }
    company.hidden_for_members.map(s=>{
      if(s.name==="cart"){
        this.setState({show_cart: false})
      }
      if(s.name==="store"){
        this.setState({show_store: false})
      }
      if(s.name==="program"){
        this.setState({show_program: false})
      }
      return true
    })
    this.updateComponent();
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    this.updateComponent();
  }

  updateComponent() {
    let { company, getPlaces, places, selectPlace, selectArea, selectedPlace, selectedArea} =
      this.props;
    if (company.uid !== this.state.companyUid) {
      this.setState({ companyUid: company.uid });
      getPlaces();
    }
    if (company.ecommerce_enabled) {
      if (
        localStorage.getItem("selectedPlaceUid") !== "null" &&
        localStorage.getItem("selectedPlaceUid")
      ) {
        if (selectedPlace === null) {
          places.map((p, index) => {
            if (p.uid === localStorage.getItem("selectedPlaceUid")) {
              selectPlace(p);
              //selectArea(p.locality.area);
              //console.log("-----3", p.locality.area);
              this.getCart();
            }
            return true;
          });
        }
      }
    }
    //console.log(places, "places");
    if (selectedPlace === null && places.length!==0) {
      selectPlace(places[0]);
      if(places[0].locality){
        selectArea(places[0].locality.area);
      }
    }
    if(places.length!==0 && (selectedArea!==this.state.selectedArea || selectedArea===null)){

      if(places[0].locality){
        // console.log(places,"places")
        selectArea(places[0].locality.area);
        this.setState({selectedArea: selectedArea})
      }
    }
  }

  searchTerm = (event) => {
    const self = this;

    if (self.state.typingTimeout) {
      clearTimeout(self.state.typingTimeout);
    }
    let that = this;
    const { history } = this.props;
    if (history.location.pathname.includes("/products")) {
      self.setState({
        search: event.target.value,
        typing: false,
        typingTimeout: setTimeout(function () {
          that.searchProductPlace();
        }, 800),
      });
    }
    if (history.location.pathname.includes("/program")) {
      self.setState({
        search: event.target.value,
        typing: false,
        typingTimeout: setTimeout(function () {
          that.searchPromotions();
        }, 800),
      });
    }
  };

  searchProductPlace = () => {
    let { getProductsByPlace, selectedPlace } = this.props;
    if (!this.state.typing) {
      let params = {
        product_category: localStorage.getItem("sectionSearchProduct"),
        search: this.state.search,
      };
      getProductsByPlace(selectedPlace.uid, params);
      this.setState({ typing: true });
    }
  };
  searchPromotions = () => {
    let { getPromotions, app } = this.props;
    if (!this.state.typing) {
      let params = { search: this.state.search };
      getPromotions(
        app && app.allowed_programs.length !== 0 && app.allowed_programs[0].uid,
        params
      );
      this.setState({ typing: true });
    }
  };

  getCart = () => {
    let {
      authStatus,
      getProductCart,
      getProductCartSubscription,
      getProductsBack,
      typeCart,
    } = this.props;
    // console.log(this.props);
    if (
      localStorage.getItem("selectedPlaceUid") !== "null" &&
      localStorage.getItem("selectedPlaceUid") !== null
    ) {
      if (typeCart === CART_PRODUCTS) {
        getProductCart(localStorage.getItem("selectedPlaceUid"));
        if (authStatus === STATUS_SUCCESS) {
          getProductsBack(localStorage.getItem("selectedPlaceUid"));
        }
      } else {
        getProductCartSubscription(localStorage.getItem("selectedPlaceUid"));
      }
    }
  };

  // openSidebar = (e) => {
  //   e.preventDefault();
  //   const { openSidebarMenu } = this.props;
  //   openSidebarMenu(true);
  // };
  openSidebar = (e) => {
    e.preventDefault();
    const { openSidebarMenu } = this.props;
    openSidebarMenu(true);
  };
  openMenuNew = (e) => {
    e.preventDefault();
    const { openSidebarMenu } = this.props;
    openSidebarMenu(true);
    this.setState({ openMenuNew: !this.state.openMenuNew });
  };
  openCart = (e) => {
    e.preventDefault();
    const { openCartMenu } = this.props;
    openCartMenu(true);
    this.setState({ openCart: !this.state.openCart });
  };

  countItems = () => {
    const { entitiesCart, entitiesCartSubscription, typeCart } = this.props;
    let count = 0;
    if (typeCart === CART_PRODUCTS) {
      Object.keys(entitiesCart.products).map((p, index) => {
        count = count + entitiesCart.products[p].quantity;
        return true;
      });
    }
    if (typeCart === CART_SUBSCRIPTION && entitiesCartSubscription.products) {
      Object.keys(entitiesCartSubscription.products).map((p, index) => {
        count = count + entitiesCartSubscription.products[p].quantity;
        return true;
      });
    }
    return count;
  };

  renderPrograms() {
    let { programs, multisite, company } = this.props;
    //console.log(this.props);

    if (programs) {
      programs = programs.filter((p) => {
        return company && p.company && company.uid === p.company.uid;
      });
      return programs.map((p, index) => (
        <li key={`program${index}`} className="nav-item">
          <Link
            className="nav-link"
            to={
              multisite
                ? `/c/${company.uid}/program/${p.uid}`
                : `/program/${p.uid}`
            }
          >
            <img
              src={programIcon}
              alt={p.name}
              style={{ height: 30, width: 30 }}
            />
          </Link>
        </li>
      ));
    }
  }

  renderProgramsMultisite() {
    let { programs, multisite, company } = this.props;
    if (programs) {
      programs = programs.filter((p) => {
        return company && p.company && company.uid === p.company.uid;
      });
      return programs.map((p, index) => (
        <li key={`program${index}`} className="nav-item">
          <Link
            className="nav-link"
            to={multisite && `/c/${company.uid}/program/${p.uid}`}
          >
            <img
              src={programIcon}
              alt={p.name}
              style={{ height: 30, width: 30 }}
            />
          </Link>
        </li>
      ));
    }
  }

  isMainCompanyMultisite = () => {
    let { company, app, multisite } = this.props;
    if (multisite) {
      let mainCompanyUid = app.company.uid;
      if (company.uid === mainCompanyUid) {
        return true;
      }
    }
    return false;
  };

  isChildCompanyMultisite = () => {
    let { company, app, multisite } = this.props;
    if (multisite) {
      let mainCompanyUid = app.company.uid;
      if (company.uid !== mainCompanyUid) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  };

  onBack = (e) => {
    const { history } = this.props;
    e.preventDefault();
    if (history) history.goBack();
  };
  // showSearch = () => {
  //   const { history } = this.props;
  //   if (history.location.pathname.includes("/products")) {
  //     return true;
  //   }
  //   return false;
  // };
  showSearch = () => {
    const { history } = this.props;
    if (
      history.location.pathname.includes("/products") ||
      history.location.pathname.includes("/products") ||
      (history.location.pathname.includes("/program") &&
        !history.location.pathname.includes("/promotions")) ||
      history.location.pathname.includes("/more")
    ) {
      return true;
    }
  };
  showCart = () => {
    const { history } = this.props;
    if (history.location.pathname.includes("/products")) {
      return true;
    }
    return false;
    // if ((history.location.pathname.includes("/program")
    //       && !history.location.pathname.includes("/promotions")) ||(history.location.pathname.includes("/more")) ) {
    //   return true
    // }
    // return false
  };

  showProgram = () => {
    const { history } = this.props;
    if (history.location.pathname.includes("/program")) {
      return true;
    }
    return false;
  };

  renderProgramsName() {
    let { programs, multisite, company } = this.props;
    if (programs) {
      programs = programs.filter((p) => {
        return company && p.company && company.uid === p.company.uid;
      });
      return programs.map((p, index) => (
        <Link
          key={`program${index}`}
          to={
            multisite
              ? `/c/${company.uid}/program/${p.uid}`
              : `/program/${p.uid}`
          }
        >
          {p.name}
        </Link>
      ));
    }
  }

  render() {
    let {
      authStatus,
      stateSecondaryMenu,
      listProgramStatus,
      multisite,
      app,
      company: { ecommerce_enabled, logo_rectangle, logo, event_enabled },
      company,
      loginStatus,
      places,
      selectedPlace,
      listPlacesStatus,
      selectPlace,
      user,
      // profileStatus,
      entitiesCart,
      entitiesCartSubscription,
      selectArea,
      typeCart,
      listCartStatus,
      listCartSubscriptionStatus,
      programCoin,
    } = this.props;

    //console.log(this.props, "main menu");
    // console.log(programCoin, ' menu coin');

    let { search,
      show_store,
      show_program,
    } = this.state;
    let logo_app = logo ? logo : logo_rectangle;
    let that = this;

    return (
      <nav
        className={
          multisite
            ? "navbar navBarPrograms multisite-menu navbar-light navbar-expand-md bg-light d-flex justify-content-between multisite-header"
            : "navbar navBarPrograms navbar-light navbar-expand-md bg-light d-flex justify-content-between"
        }
      >
        <div
          className={
            multisite
              ? "col-6 d-none d-md-flex align-items-center ml-md-5"
              : "col-6 d-none d-md-flex align-items-center"
          }
        >
          <div className={"d-flex align-items-center navbar-brand-div"}>
            {stateSecondaryMenu === SECONDARY_MENU_BACK && (
              <a
                className="nav-link back-icon mr-3"
                href={"/"}
                onClick={(e) => this.onBack(e)}
              >
                <img src={backIcon} alt="backIcon" />
              </a>
            )}
            <Link to={"/"} className="navbar-brand p-0 mr-0">
              <img src={multisite ? app.company.logo : logo_app} alt="" />
            </Link>
          </div>
          {/* {ecommerce_enabled && !multisite && this.showProgram() && (
            <div
              className={
                places.length > 1
                  ? "d-block  navbar-nav navResponsive ml-3"
                  : "d-block  navbar-nav navResponsive d-none"
              }
            >
              <NavPlaces
                selectedPlace={selectedPlace}
                selectPlace={selectPlace}
                selectArea={selectArea}
                places={places}
                listPlacesStatus={listPlacesStatus}
              />
            </div>
          )} */}
          {this.showCart() && (
            <>
              {ecommerce_enabled && (
                <div
                  className={
                    places.length > 1
                      ? "navbar-nav navResponsive ml-md-3"
                      : "navbar-nav navResponsive ml-md-3 d-none"
                  }
                >
                  <NavPlaces
                    selectedPlace={selectedPlace}
                    selectPlace={selectPlace}
                    selectArea={selectArea}
                    places={places}
                    listPlacesStatus={listPlacesStatus}
                  />
                </div>
              )}
            </>
          )}
          {this.showSearch() && (
            <div
              className={
                multisite
                  ? "col-12 col-sm-3 col-md-4 search-input ml-md-4"
                  : "col-12 col-sm-3 col-md-4 search-input"
              }
            >
              <input
                type="text"
                className="form-control form-search nav-menu-input"
                placeholder={``}
                aria-label="Buscar"
                value={search}
                onKeyPress={(event) => {
                  if (event.key === "Enter") {
                    this.searchTerm(event);
                  }
                }}
                onChange={(e) => {
                  this.setState({ typing: true });
                  this.searchTerm(e);
                }}
                style={{ backgroundImage: `url(${searchIcon})` }}
              />
            </div>
          )}
          {this.showProgram() && programCoin && company.gamification_system && (
            <div className="coin-container-menu">
              <h4>
                {" "}
                <span>
                  <img src={CoinSvg} alt="coin" />{" "}
                </span>{" "}
                {programCoin?.coins}{" "}
              </h4>
            </div>
          )}
        </div>
        <Link to={"/"} className="d-block d-md-none navbar-brand p-0 mr-0">
          <img src={multisite ? app.company.logo : logo_app} alt="" />
        </Link>
        {this.showSearch() && (
          <div
            className={
              "d-block d-md-none col-12 col-sm-6 col-md-4 search-input"
            }
          >
            <input
              type="text"
              className="form-control"
              placeholder={``}
              aria-label="Buscar"
              value={search}
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  this.searchTerm(event);
                }
              }}
              onChange={(e) => {
                this.setState({ typing: true });
                this.searchTerm(e);
              }}
            />
          </div>
        )}
        <ul className={multisite ? "navbar-nav mr-md-4" : "navbar-nav"}>
          {!this.isMainCompanyMultisite() && false && (
            <Fragment>
              {company &&
                company.benefit_programs_enabled &&
                loginStatus === STATUS_SUCCESS && (
                  <Fragment>
                    {listProgramStatus === STATUS_LOADING ? (
                      <li className="nav-item loading">
                        <span />
                      </li>
                    ) : (
                      <Fragment>{that.renderPrograms()}</Fragment>
                    )}
                  </Fragment>
                )}
              {selectedPlace &&
                selectedPlace.subscription_enabled &&
                false &&
                listPlacesStatus === STATUS_SUCCESS && (
                  <li className="nav-item">
                    <Link
                      className="nav-link"
                      to={
                        multisite
                          ? `/c/${company.uid}/subscription`
                          : `/subscription`
                      }
                    >
                      Suscripcion
                    </Link>
                  </li>
                )}
            </Fragment>
          )}

          {this.isMainCompanyMultisite() && (
            <li className="nav-item">
              <a
                href={`https://administrador.${
                  process.env.REACT_APP_URL_REST.includes("bemaven")
                    ? "bemaven.xyz"
                    : "bederr.com"
                }/register/?api_key=${app && app.api_key}`}
                target="_blank"
                rel="noopener noreferrer"
                className={"btn btn-create-store"}
              >
                Crear tienda
              </a>
            </li>
          )}
          {/* {app && app.allowed_programs.length !== 0 && (
            <li className="nav-item">
              <Link to={"/"} className={"btn btn-related"}>
                <img src={relatedIcon} alt="relatedIcon" height={32} width={32}/>
                <span class="material-symbols-outlined">
                  store
                </span>
              </Link>
            </li>
          )} */}

          {
            app && app.company && app.name.toLowerCase().includes("rock") && app.name.toLowerCase().includes("pez") &&
            <li className="nav-item icon-link-products">
              <Link to={"/events"} className={"btn btn-related"}>
                <span className="material-symbols-outlined">local_activity</span>
              </Link>
            </li>
          }
          {
            show_store && show_program &&
            <li className="nav-item icon-link-products">
              <Link to={"/products/more"} className={"btn btn-related"}>
                <span className="material-symbols-outlined">store</span>
              </Link>
            </li>
          }
          {app && app.allowed_programs.length !== 0 && show_store && show_program && (
              <li className="nav-item icon-link-products">
                <Link to={`/program/${app.allowed_programs[0]?.uid}`} className={"btn btn-related"}>
                  <span className="material-symbols-outlined">loyalty</span>
                </Link>
              </li>
          )}
          {
            company && event_enabled &&
            <li className="link-products">
              <Link to={"/events"} className={"btn btn-related"}>
                Eventos
              </Link>
            </li>
          }
          {
            show_store && show_program &&
            <li className="link-products">
              <Link to={"/products/more"} className={"btn btn-related"}>
                Tienda
              </Link>
            </li>
          }
          {app && app.allowed_programs.length !== 0 && show_store && show_program && (
              <li className="link-products">
                <Link
                  to={`/program/${app.allowed_programs[0]?.uid}`}
                  className={"btn btn-related"}
                >
                  Loyalty
                </Link>
              </li>
          )}
          {show_store && (
            <li className="nav-item">
              <div
                className="nav-link svg-cart btn btn-related"
                style={{ width: "54px" }}
                onClick={(e) => this.openCart(e)}
                //to={multisite ? `/c/${uidCompany}/cart` : `/cart`}
              >
                <img
                  className={"cartIcon"}
                  src={cartIcon}
                  alt={"cart"}
                  style={{ height: 30, width: 30 }}
                />
                {typeCart === CART_PRODUCTS ? (
                  listCartStatus === "success" &&
                  entitiesCart &&
                  entitiesCart.products ? (
                    <span id="items" className={"count-items"}>
                      {this.countItems()}
                    </span>
                  ) : (
                    <span id="items" className={"count-items zero"}>
                      0
                    </span>
                  )
                ) : listCartSubscriptionStatus === "success" &&
                  entitiesCartSubscription &&
                  entitiesCartSubscription.products ? (
                  <span id="items" className={"count-items"}>
                    {this.countItems()}
                  </span>
                ) : (
                  <span id="items" className={"count-items zero"}>
                    0
                  </span>
                )}
              </div>
            </li>
          )}

          {ecommerce_enabled && false && (
            <>
              {this.showCart() && (
                <li className="nav-item">
                  <div
                    className="nav-link svg-cart"
                    onClick={(e) => this.openCart(e)}
                    //to={multisite ? `/c/${uidCompany}/cart` : `/cart`}
                  >
                    <img
                      className={"cartIcon"}
                      src={cartIcon}
                      alt={"cart"}
                      style={{ height: 30, width: 40 }}
                    />
                    {typeCart === CART_PRODUCTS ? (
                      listCartStatus === "success" &&
                      entitiesCart &&
                      entitiesCart.products ? (
                        <span id="items" className={"count-items"}>
                          {this.countItems()}
                        </span>
                      ) : (
                        <span id="items" className={"count-items zero"}>
                          0
                        </span>
                      )
                    ) : listCartSubscriptionStatus === "success" &&
                      entitiesCartSubscription &&
                      entitiesCartSubscription.products ? (
                      <span id="items" className={"count-items"}>
                        {this.countItems()}
                      </span>
                    ) : (
                      <span id="items" className={"count-items zero"}>
                        0
                      </span>
                    )}
                  </div>
                </li>
              )}
            </>
          )}
          {company &&
            company.benefit_programs_enabled &&
            false &&
            loginStatus === STATUS_SUCCESS && (
              <li
                className={
                  this.props.history.location.pathname.includes("program")
                    ? "nav-option p-0 active"
                    : "nav-option p-0"
                }
              >
                <p className={"m-0 p-0"}>{this.renderProgramsName()}</p>
              </li>
            )}
          {loginStatus === STATUS_SUCCESS ? (
            <li className="nav-item sm-hidden">
              {/*
                  profileStatus === STATUS_SUCCESS ?
                    <Link className="nav-link name" to="/profile">
                      <img src={userIcon} alt={user.first_name} style={{height: 25, width: 30, marginTop: 3}}/>
                    </Link> :
                    <div className="nav-link name loading">
                      <span/><br/><div/>
                    </div>
                    */}
            </li>
          ) : (
            <li className="nav-item">
              <Link to={"/login"} className={"btn btn-primary"}>
                Ingresar
              </Link>
            </li>
          )}
          {authStatus === STATUS_SUCCESS && (
            <>
              <div
                className="userNavBox"
                onClick={(e) => this.openMenuNew(e)}
                style={{ position: "relative" }}
              >
                {user ? (
                  <h4>{user.first_name}&nbsp;&nbsp;</h4>
                ) : (
                  <h4>&nbsp;&nbsp;</h4>
                )}
                <h4>
                  <ArrowSvg />
                </h4>
                {/* {this.state.openMenuNew && (
                  <div className="listNavUser">
                    
                  </div>
                )} */}
              </div>
              <li className="nav-item nav-programs__main">
                <a
                  className="nav-link hamburger"
                  href="/#"
                  onClick={(e) => this.openSidebar(e)}
                >
                  <img src={hamburgerIcon} alt="hamburgerIcon" />
                </a>
              </li>
            </>
          )}
        </ul>
      </nav>
    );
  }
}
