import HttpClient from "../common/utils/httpClient";


export const GET_RELATED_PROMOTIONS= 'GET_RELATED_PROMOTIONS';



/**Urls**/
export const LIST_RELATED_PROMOTIONS_URL = 'v5/me/programs/{programUid}/promotions/{promotionUid}/related/';



export function getRelatedProgramPromotion(programUid, promotionUid)  {

  let url = LIST_RELATED_PROMOTIONS_URL.replace("{programUid}",programUid).replace("{promotionUid}",promotionUid)

  return (dispatch) => HttpClient.get(dispatch, GET_RELATED_PROMOTIONS, url);

}
