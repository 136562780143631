import React from "react";

export default class ProgramBanner extends React.Component {
  render() {
    let {
      color,
      percent,
      description,
      nivelTitle,
      pointsExtra,
      point,
      nextLevel,
      colorNextLevel,
      descriptionNextLevel,
      HandleClose,
    } = this.props;
    //console.log(this.props);
    return (
      <div className="level_info_modal">
        <div className="level_header_modal">
          <h2>Info Puntos</h2>
          <h3 onClick={HandleClose}>X</h3>
        </div>
        <div className="card-info">
          <div className={"advanced-percent"}>
            <div className={"bar mt-1"}>
              <div
                style={
                  color !== null && color.includes("#")
                    ? { background: `${color}`, width: `${percent}%` }
                    : { background: `#${color}`, width: `${percent}%` }
                }
              />
            </div>
            <div className="poinsProgram level-info_points">
              <p>
                <span>{point}pts </span> - {percent.toFixed(0)}%
              </p>
            </div>
          </div>
        </div>
        <div className="info_general">
          <h3>
            Actualmente cuentas con <span>{point}pts</span>
          </h3>
          {nextLevel !== null && (
            <h3>
              Te falta <span>{pointsExtra}pts</span> para subir al nivel{" "}
              <span>{nextLevel}</span>
            </h3>
          )}
        </div>
        <br />
        <div className="benefit_info">
          <div className="benefit_box">
            {nivelTitle !== null && (
              <h4
                style={
                  color !== null && color.includes("#")
                    ? { background: `${color}` }
                    : { background: `#${color}` }
                }
              >
                Beneficios {nivelTitle.toUpperCase()}
              </h4>
            )}
          </div>
          <div className="benefit_destription">
            {description !== null && description !== "" ? (
              <h4>{description}</h4>
            ) : (
              <h4>Acceso a mejores beneficios que el nivel anterior</h4>
            )}
          </div>
        </div>
        <br />
        {nextLevel !== null && (
          <div className="benefit_info">
            <div className="benefit_box">
              {nextLevel !== null && (
                <h4
                  style={
                    colorNextLevel !== null && colorNextLevel.includes("#")
                      ? { background: `${colorNextLevel}` }
                      : { background: `#${colorNextLevel}` }
                  }
                >
                  Beneficios {nextLevel.toUpperCase()}
                </h4>
              )}
            </div>
            <div className="benefit_destription">
              {descriptionNextLevel !== null && descriptionNextLevel !== "" ? (
                <h4>{descriptionNextLevel}</h4>
              ) : (
                <h4>Acceso a mejores beneficios que el nivel anterior</h4>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}
