import React from "react";

const TermsConditionsGeneral = ({ app }) => {
  const appName = app;
  return (
    <div className={"mx-auto col-lg-10 col-md-12"}>
          <p>Términos y condiciones / {appName} </p>
          <p>
            Queremos hacer de tu experiencia en nuestro sistema la mejor de todas.
            Por eso, te pedimos que tengas en cuenta nuestros Términos y
            Condiciones del Servicio (“Términos y Condiciones”), que rigen toda la
            actividad que se lleva a cabo en nuestro sitio. Recuerda que para
            cualquier consulta o pregunta puedes escribirnos a team@bederr.com .
          </p>
          <p>
            Este documento contiene los Términos y Condiciones del Servicio bajo
            las cuales se regula el acceso y uso que hagan del sitio web{" "}
            {appName} (el “Sitio”), operado por {appName}{" "}
            (la “Empresa”), aplicables a cualquier persona natural o jurídica que
            use sus servicios (el “Usuario”).
          </p>
          <p>1. Declaraciones</p>
          <p>
            <br/>
            1.1. La Empresa es una persona jurídica de derecho privado constituida
            según las leyes peruanas y domiciliada para efectos del presente
            Contrato en la ciudad de Lima, Perú.
            <br/>
            1.2. El Sitio consiste en una página web que permite al usuario ver,
            valorar y reservar beneficios corporativos y locales de la ciudad.
            <br/>
            1.3. El Usuario declara ser mayor de edad y estar en plena capacidad
            para contratar según las leyes peruanas. Solo se considerará como
            Usuario a cualquier persona natural mayor de dieciocho (18) años que
            visite, utilice, participe o se registre en el Sitio, sin necesidad de
            que esas actividades confluyan.
            <br/>
            1.4. El Usuario declara tener capacidad para discernir, que han
            accedido al Sitio por su propia voluntad y que no han sido
            coaccionados para ello, así como que el uso que llevan a cabo del
            Sitio lo hacen por su sola y única cuenta y riesgo
          </p>
          <p>2. Aceptación y vinculación al Contrato</p>
          <p>
            <br/>
            2.1. El Usuario se obliga a utilizar el Sitio únicamente para los
            fines permitidos por (a) el presente Contrato y (b) las leyes,
            normativas, prácticas generalmente aceptadas o directrices de
            aplicación en Perú.
            <br/>
            2.2. Se entiende que el Usuario suscribe y se compromete a cumplir el
            presente Contrato cuando:
            <br/> a. declara haber leído y estar de acuerdo con los Términos y
            Condiciones del Servicio en el formulario de registro; o,
            <br/> b. sin haberse registrado, usan de forma efectiva cualquier de
            los servicios que brinda la Empresa a través del Sitio. En este caso,
            el Usuario reconoce y acepta que, a partir de ese momento, la Empresa
            considerará la utilización del Sitio como una aceptación del presente
            Contrato.
            <br/>
            2.3. Este Contrato se entiende aceptado sin modificaciones por parte
            del Usuario. La Empresa se reserva el derecho de, en cualquier momento
            y a su exclusivo criterio, cambiar o modificar de otro modo este
            Contrato sin aviso previo bastando para ello que notifique por correo
            electrónico al Usuario. El continuo acceso o uso del Sitio por parte
            del Usuario indica su aceptación del Contrato actualizado o
            modificado.
            <br/>
            2.4. En ejercicio de su libertad de contratar, la Empresa se reserva
            el derecho de negar el acceso al Sitio y a los servicios que ofrece a
            cualquier persona, en cualquier momento y bajo cualquier motivo,
            incluyendo pero no limitándose al desconocimiento de lo estipulado en
            este Contrato.
            <br/>
            2.5. De conformidad con las reglas de responsabilidad civil, el
            Usuario reconoce y acepta que la Empresa podrá solicitar una
            indemnización por los reclamos y gastos en los que haya incurrido la
            Empresa a consecuencia del desconocimiento o infracción a lo
            estipulado en estos Términos y Condiciones de parte del Usuario, los
            que incluyen honorarios profesionales de abogados, tasas judiciales o
            administrativas y cualquier daño emergente o lucro cesante.
            <br/>
            2.6. Este Contrato solo aplica a la relación que existe entre los
            Usuarios y el Sitio al momento de acceder a los beneficios
            corporativos. La prestación individual de cada uno de los servicios o
            venta de productos en los que consiste el beneficio corporativo se
            ofrecen.
          </p>
          <p>3. Sobre el servicio</p>
          <p>
            <br/>
            3.1. El Usuario reconoce y acepta que la forma y la naturaleza de los
            Servicios que la Empresa presta podrá cambiar ocasionalmente sin
            necesidad de preaviso.
            <br/>
            3.2. El Usuario reconoce y acepta que la Empresa podrá interrumpir (de
            forma permanente o temporal) la prestación de parte o totalidad de los
            servicios brindados a través del Sitio (o de cualquiera de sus
            características), sin necesidad de preaviso.
            <br/>
            3.3. El Usuario podrá dejar de utilizar el Sitio o sus servicios en
            cualquier momento, bastando para ello que se den de baja a través del
            envío de un mensaje al correo electrónico team@bederr.com con la
            palabra BAJA en el Asunto o a través del mecanismo para ello
            incorporado en el Sitio.
            <br/>
            3.4. El Usuario reconoce y acuerda que los comentarios y valoraciones
            dejados en las secciones públicas en el Sitio no son confidenciales y
            su propiedad no es exclusiva.
            <br/>
            3.5. El Usuario reconoce que al enviar un contenido al Sitio por
            correo electrónico, a través de los formularios de publicación o de
            otra forma, incluyendo valoraciones, comentarios, reseñas, preguntas,
            sugerencias, ideas o similares contenidos en cualquier presentación
            (en conjunto, "Presentaciones") otorga a la Empresa y a sus sociedades
            vinculadas un derecho no exclusivo, libre de regalías, perpetuo,
            transferible, irrevocable y totalmente sub licenciable para: (a) usar,
            reproducir, modificar, adaptar, traducir, distribuir, publicar, crear
            obras derivadas de y exhibir públicamente y realizar esas
            Presentaciones en todo el mundo en cualquier medio, conocido
            actualmente o que se cree en adelante; y (b) usar el nombre que el
            Usuario indique en relación con esa Presentación. El Usuario reconoce
            que la Empresa puede elegir dar a conocer sus comentarios o reseñas, a
            su exclusivo criterio. Además, el Usuario otorga a la Empresa el
            derecho a llevar adelante una acción judicial contra cualquier persona
            o entidad que viole sus derechos o los de la Empresa sobre las
            Presentaciones por incumplimiento de este Contrato.
            <br/>
            3.6. El Sitio puede contener información sobre beneficios
            corporativos, foros de debate, tablones de anuncios, servicios de
            crítica u otros foros a través de los cuales el Usuario pueden
            publicar reseñas, u otro contenido, mensajes, materiales u otros
            artículos en el Sitio respecto de las ofertas comerciales o terceros
            en general (“Áreas Interactivas”). El Usuario es exclusivamente
            responsable del uso que haga de esas Áreas Interactivas y las utiliza
            bajo su propio riesgo. Al utilizar las Áreas Interactivas, el Usuario
            se compromete expresamente a no publicar, subir, transmitir,
            distribuir, almacenar, crear o publicar de otro modo a través del
            Sitio nada de lo siguiente:
            <br/> a. Ningún mensaje, dato, información, texto, música, sonido,
            foto, gráfico, código ni ningún otro material (“Contenido”) que sea
            ilegal, calumnioso, difamatorio, obsceno, pornográfico, indecente,
            lascivo, sugestivo, acosador, amenazante, que infrinja de derechos de
            privacidad o publicidad, incendiario, doloso, fraudulento u objetable
            de otro modo;
            <br/> b. Ningún Contenido que constituya, fomente o brinde
            instrucciones para un delito penal, viole los derechos de cualquier
            parte o que de otra forma cree responsabilidad o viole cualquier ley
            local, estatal, nacional o internacional;
            <br/> c. Ningún Contenido que infrinja una patente, marca, secreto
            comercial, derecho de autor u otro derecho intelectual o de propiedad
            de cualquier parte;
            <br/> d. Ningún Contenido a través del cual suplante a otra persona o
            entidad o de otra forma manifieste falsamente su vinculación con una
            persona o entidad;
            <br/> e. Información personal de terceros, incluyendo, a título
            ilustrativo, apellidos, domicilios, números telefónicos, direcciones
            de correo electrónico, números de seguridad social y números de
            tarjetas de crédito;
            <br/> f. Virus, archivos dañados u otros archivos peligrosos,
            perturbadores o destructivos;
            <br/> g. Ningún Contenido que contenga o transmita cualquier tipo de
            publicidad comercial no solicitada, material promocional, SPAM,
            cadenas de correo o cualquier contenido similar a este;
            <br/> h. Ningún contenido o enlaces a un contenido que, a sólo juicio
            de la Empresa: (a) viole los apartados anteriores del presente, (b)
            sea objetable, (c) que impida o inhiba a cualquier otra persona usar o
            disfrutar de las Áreas Interactivas o el Sitio, o (d) que exponga a la
            Empresa o a sus usuarios a cualquier daño o responsabilidad de
            cualquier tipo.
            <br/>
            3.7. La Empresa no asume responsabilidad ni obligación alguna por
            ningún Contenido que los Usuarios o algún tercero hayan publicado,
            almacenado o subido, ni por ninguna pérdida o daño del mismo, ni
            tampoco es responsable de ningún error, difamación, calumnia, omisión,
            falsedad, obscenidad, pornografía o blasfemia que usted encuentre.
            <br/>
            3.8. Como proveedor de servicios interactivos, la Empresa no es
            responsable de ninguna declaración, manifestación o contenido provisto
            por sus usuarios en cualquier foro público, hoja de inicio personal u
            otras Áreas Interactivas. Aunque la Empresa no tiene ninguna
            obligación de examinar, editar o monitorear ninguno de los Contenidos
            publicados o distribuidos a través de un Área Interactiva, se reserva
            el derecho, y tiene absoluto criterio, para eliminar, examinar o
            editar sin aviso cualquier contenido publicado o almacenado en el
            Sitio en cualquier momento y por cualquier motivo y los Usuarios serán
            exclusivamente responsables de crear copias de seguridad y de
            reemplazar cualquier Contenido que publique o almacene en el Sitio,
            exclusivamente a su cargo.
            <br/>
            3.9. El Sitio puede contener hipervínculos con sitios web operados por
            personas que no son la Empresa y con los cuales la Empresa no tiene
            ninguna relación. Dichos hipervínculos se proveen sólo para que el
            Usuario tenga una referencia. La Empresa no controla esos sitios web y
            no se hace responsable de sus contenidos o de sus prácticas de
            privacidad.
            <br/>
            3.10. Todo uso de las Áreas Interactivas u otras secciones del Sitio
            contrario a lo señalado en el presente Contrato puede resultar, entre
            otras cosas, en la extinción o suspensión de sus derechos a usar las
            Áreas Interactivas y/o el Sitio. A fin de colaborar con pedidos
            gubernamentales debidamente emitidos, citaciones u ordenes judiciales,
            proteger los clientes y sistemas de la Empresa o garantizar la
            integridad y funcionamiento de los sistemas y negocios, la Empresa
            podrá acceder a y revelar cualquier información que considere
            necesaria o apropiada, incluyendo, a título ilustrativo, información
            del perfil de usuario (es decir, nombre, dirección de correo
            electrónico, etc.), dirección de IP e información de tráfico,
            historial de uso y Contenido publicado. El derecho de la Empresa a
            revelar ese tipo de información y usarla de conformidad con las leyes
            sobre la materia, tendrá prioridad sobre cualquier otro término de la
            Política de Privacidad y Protección de Datos Personales.
          </p>
          <p>4. Propiedad Intelectual</p>
          <p>
            <br/>
            4.1. La Empresa concede permiso al Usuario para descargar
            temporalmente una copia de los materiales (de información o software)
            en el Sitio para uso personal y no comercial sólo transitoria. Esta es
            la concesión de una licencia, no una transferencia de título, y bajo
            esta licencia no podrá:
            <br/>
            Modificar o copiar el material
            <br/>
            Utilizar los materiales para cualquier propósito comercial, o para
            cualquier exhibición pública (comercial o no comercial)
            <br/>
            Eliminar cualquier derecho de autor u otros notaciones de propiedad de
            los materiales, o transferir el material a otra persona o "espejo" de
            los materiales en cualquier otro servidor.
            <br/>
            4.2. Esta licencia terminará automáticamente si usted viola cualquiera
            de estas restricciones y podrá ser denunciado por en cualquier
            momento. Después de cancelar su visión de estos materiales o al
            término de esta licencia, deberá destruir cualquier material
            descargado en su poder ya sea en formato electrónico o impreso.
            <br/>
            4.3. Salvo donde se indique lo contrario, todos los contenidos del
            Sitio constituyen propiedad intelectual de la Empresa. Todos los
            derechos se encuentran reservados. La Empresa no es responsable del
            contenido en sitios web operados por personas que no son ella misma o
            actúan en su representación según poderes verificables.
            <br/>
            4.4. {appName}, el logo y todo otro nombre de producto o
            servicio o slogan visualizado en el Sitio son marcas registradas de
            propiedad de la Empresa para todo el territorio peruano, y no pueden
            copiarse, imitarse ni usarse, total o parcialmente, sin el previo
            permiso por escrito de la Empresa o el titular de la marca
            correspondiente.
            <br/>
            4.5. Los contenidos publicados el Usuario en las Áreas Interactivas,
            incluyendo los diseños que se someten a votación, son de propiedad de
            quien se señale como autor y cualquier infracción a las normas de
            derechos de autor y propiedad industrial será de exclusiva
            responsabilidad del Usuario que haya publicado el contenido. Si un
            tercero reconoce parte de su propiedad intelectual publicada en el
            Sitio, podrá ponerse en contacto con la Empresa a team@bederr.com para
            solicitar el retiro de los contenidos señalados. Al participar en el
            Sitio, los Usuarios otorgan a la Empresa una licencia no exclusiva a
            nivel mundial para utilizar los contenidos que hayan generado con la
            finalidad de promover el sitio y brindar servicios a otros Usuarios.
            Esta licencia no exclusiva le permitirá a la Empresa copiar, recortar,
            reproducir, comunicar públicamente y distribuir los contenidos subidos
            por el Usuario.
            <br/>
            4.6. El aspecto del Sitio, incluyendo todos los encabezados de
            páginas, gráfica personalizada, íconos de botones y scripts, la marca
            de servicio, marca y/o envoltorio son de uso exclusivo de la Empresa y
            no pueden copiarse, imitarse o usarse, total o parcialmente, sin el
            previo permiso por escrito de la Empresa.
            <br/>
            4.7. Todas las otras marcas, marcas registradas, nombres de productos,
            empresas, establecimientos comerciales y razones sociales o logos
            mencionados en el Sitio son propiedad de sus respectivos propietarios.
            La referencia a cualquier producto, servicio, proceso u otra
            información por nombre comercial, marca, fabricante, proveedor o de
            otro modo no constituye ni implica su aprobación, auspicio o
            recomendación por la Empresa.
          </p>
          <p>5. Renuncia</p>
          <p>
            <br/>
            5.1. Los materiales en el Sitio se proporcionan "tal cual". La Empresa
            no ofrece ninguna garantía, expresa o implícita, y por la presente
            renuncia y niega todas las otras garantías, incluyendo, sin
            limitación, las garantías o condiciones implícitas de
            comercialización, idoneidad para un propósito particular o no
            violación de la propiedad intelectual o cualquier otra violación de
            los derechos.
            <br/>
            5.2. La información, software, productos y servicios publicados en el
            Sitio pueden incluir inexactitudes o errores. En la máxima medida
            permitida por la ley aplicable, la Empresa no garantiza la exactitud
            de esta información presentada en el Sitio y declina toda
            responsabilidad por errores u otras inexactitudes relativos a la
            información y descripción de diseños, materiales y otros productos
            visualizados en este sitio web.
          </p>
          <p>6. Limitaciones</p>
          <p>
            <br/>
            6.1. En ningún caso la Empresa o sus proveedores serán responsables
            por cualquier daño (incluyendo, sin limitación, daños por pérdida de
            datos o beneficios, o debido a la interrupción del negocio,) que
            surjan del uso o la imposibilidad de usar los materiales en Internet,
            el Sitio, incluso si la Empresa o un representante autorizado ha sido
            notificado en forma oral o por escrito de la posibilidad de tales
            daños. Debido a que algunas jurisdicciones no permiten limitaciones
            sobre garantías implícitas o limitaciones de responsabilidad por daños
            indirectos o incidentales, estas limitaciones pueden no aplicarse a
            usted.
          </p>
          <p>7. Las revisiones y erratas</p>
          <p>
            <br/>
            7.1. Los materiales que aparecen en el Sitio podrían incluir errores
            técnicos, tipográficos o fotográficos. La Empresa no garantiza que
            cualquiera de los materiales en su sitio web sea precisa, completa o
            actualizada. La Empresa puede hacer cambios a los materiales
            contenidos en su sitio web en cualquier momento sin previo aviso.
          </p>
          <p>8. Enlaces</p>
          <p>
            <br/>
            8.1. La Empresa no ha revisado todos los sitios vinculados a su sitio
            web en Internet y no es responsable de los contenidos de ningún sitio
            vinculado. La inclusión de estos vínculos no implica aprobación por
            parte la Empresa. El uso de cualquier sitio web vinculado como es bajo
            el propio riesgo del usuario.
          </p>
          <p>9. Modificaciones a los Términos de Uso</p>
          <p>
            <br/>
            9.1. La Empresa puede revisar estos términos de uso de su sitio web en
            cualquier momento sin previo aviso. Al usar este sitio web usted
            acepta estar obligado por la versión actual de estos Términos y
            condiciones de uso.
          </p>
          <p>10. Legislación aplicable</p>
          <p>
            10.1. Estos Términos y Condiciones se rigen y serán aplicados e
            interpretados de acuerdo con la legislación peruana. Cualquier disputa
            que surja en su aplicación se someterá al conocimiento de los
            tribunales con jurisdicción en Lima, Perú.
          </p>
          <p>11. Contacto</p>
          <p>
            11.1. Para cualquier pregunta respecto de estos Términos y
            Condiciones, por favor contáctenos a: team@bederr.com{" "}
          </p>
    </div>
  );
};

export default TermsConditionsGeneral;
