import React from "react";
import "./style.scss"

export default class FrecuencyDays extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      subscriptionsDaysEnabled: [],
      subscriptionsDaysSelected: []
    }
  }

  componentDidMount() {
    let {
      setInfoFrecuency,
      selectedPlace,
      info
    } = this.props;
      if (info && info.subscriptionsDaysSelected && info.subscriptionsDaysEnabled) {
        this.setState({info})
      } else {
        let info = {subscriptionsDaysEnabled: [...selectedPlace.subscriptions_days_enabled.map(s => ({
            uid: s,
            value: s,
            label: (s===1) ? "Lun" : ((s===2) ? "Mar" : ((s===3) ? "Miér" : ((s===4) ? "Juev" : ((s===5) ? "Vier" : ((s===6) ? "Sáb" : "Dom"))))),
            name: (s===1) ? "Lunes" : ((s===2) ? "Martes" : ((s===3) ? "Miércoles" : ((s===4) ? "Jueves" : ((s===5) ? "Viernes" : ((s===6) ? "Sábado" : "Domingo")))))
          }))]
          , subscriptionsDaysSelected: [...selectedPlace.subscriptions_days_enabled.map(s => false)]}
        setInfoFrecuency(info)
        this.setState(info)
      }

  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let {
      info,
      subscription_days_enabled
    } = this.props;

    let infoAux = {subscriptionsDaysEnabled: [...subscription_days_enabled.map(s => ({
        uid: s,
        value: s,
        label: (s===1) ? "Lun" : ((s===2) ? "Mar" : ((s===3) ? "Miér" : ((s===4) ? "Juev" : ((s===5) ? "Vier" : ((s===6) ? "Sáb" : "Dom"))))),
        name: (s===1) ? "Lunes" : ((s===2) ? "Martes" : ((s===3) ? "Miércoles" : ((s===4) ? "Jueves" : ((s===5) ? "Viernes" : ((s===6) ? "Sábado" : "Domingo")))))
      }))]
      , subscriptionsDaysSelected: [...subscription_days_enabled.map(s => false)]}

    if(!this.compare(this.state.subscriptionsDaysEnabled, infoAux.subscriptionsDaysEnabled)){
      this.setState({subscriptionsDaysEnabled:infoAux.subscriptionsDaysEnabled})
    }
    if(!this.compare(this.state.subscriptionsDaysSelected,info.subscriptionsDaysSelected)){
      this.setState({subscriptionsDaysSelected: info.subscriptionsDaysSelected})
    }
  }

  compare = (obj1, obj2) => {
    return JSON.stringify(obj1) === JSON.stringify(obj2)
  }

  changeDay(i){
    let aux = []
    this.state.subscriptionsDaysSelected.map((d,i)=>{
      aux[i] = this.state.subscriptionsDaysSelected[i]
      return true
    })
    aux[i]=!this.state.subscriptionsDaysSelected[i]
    this.setState({subscriptionsDaysSelected: aux})

    let { setInfoFrecuency } = this.props;
    let payload = {subscriptionsDaysSelected: aux}
    setInfoFrecuency({...payload, isValid: true})
  }

  render() {
    const {subscriptionsDaysEnabled} = this.state;
    const {info} = this.props
    return (
      <div className={`card detail`}>
        <div className={"title-bar-section"}>
          <h6 className={"col-10 col-lg-10 mx-auto"}>Frecuencia de días</h6>
        </div>
        <div className="col-10 col-lg-10 mx-auto p-0 d-flex flex-wrap ">
          {
            subscriptionsDaysEnabled.length > 0 ?
            subscriptionsDaysEnabled.map((d, i)=>{
              return(
                <div className="checkbox d-flex flex-column align-items-center mt-2 mb-2 schedule-days mr-2" key={i}>
                  <label htmlFor={d.label} className="form-check-label" style={{margin: "0px 5px 10px 0"}}>
                    {d.label}
                  </label>
                  <input type="checkbox" id={d.label} checked={this.state.subscriptionsDaysSelected[d.uid-1]===true}
                         onChange={e => this.changeDay(d.uid-1)}/>
                </div>
              )
            }) :
              <div className={"col-10 col-lg-10 mx-auto p-0 no-results text-left"}>
                <p style={{fontWeight:"500", fontSize:"1rem"}}>No se cuentan con dias disponibles</p>
              </div>
          }
        </div>
        {
          info && info.subscriptionsDaysSelected &&
          !info.subscriptionsDaysSelected.includes(true) &&
          <span className="col-10 col-lg-10 mx-auto p-0 field-error-message mb-2" style={{display:"block"}}>
              Debe seleccionar por lo menos un día para realizar su suscripción
          </span>
        }
      </div>
    )
  }
}

FrecuencyDays.propTypes = {
};

