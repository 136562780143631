import HttpClient from "../common/utils/httpClient";


export const GET_SECTIONS_PRODUCTS = 'GET_SECTIONS_PRODUCTS';
export const GET_SECTIONS_BY_COMPANY = 'GET_SECTIONS_BY_COMPANY';




/**Urls**/
export const LIST_SECTIONS_PRODUCTS_URL = 'v7/places/{place_uid}/product-categories/';
export const LIST_SECTIONS_BY_COMPANY_URL = 'v7/company/{company_uid}/product-categories/';




export function getSectionsToProducts(placeUid ) {

  let url = LIST_SECTIONS_PRODUCTS_URL.replace("{place_uid}",placeUid)
  return (dispatch) => HttpClient.get(dispatch, GET_SECTIONS_PRODUCTS, url);
}

export function getSectionsByCompany(companyUid) {

  let url = LIST_SECTIONS_BY_COMPANY_URL.replace("{company_uid}",companyUid)
  return (dispatch) => HttpClient.get(dispatch, GET_SECTIONS_BY_COMPANY, url);
}


