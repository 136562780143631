import React from 'react'
import PropTypes from 'prop-types';
import {STATUS_ERROR, STATUS_LOADING, STATUS_SUCCESS} from '../../common/utils/generic';
import './style.scss';
import "react-datepicker/dist/react-datepicker.css"

export default class QRProfile extends React.Component {


  constructor(props) {
    super(props);

    this.state = {
      document_type: "NATIONAL_ID",
      type_text: "Documento de Identidad *",
      document_number: "",
      first_name: "",
      last_name: "",
      birthdate: new Date(),
      gender: "",
      error: '',
      mobile_number: "",
      country_code_number: "",
      code: "",
      loadCode: false,
      showResponse: false,
      readOnly: false,

      accepted_terms_cond: true,
      accepted_politics_privacity: true,
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let {changeSecondaryMenu, changeStateCart, stateCart } = this.props;

    if(stateCart!=="CART_INVISIBLE"){
      changeSecondaryMenu()
      changeStateCart()
    }
  }

  componentDidMount() {
    let {changeSecondaryMenu, changeStateCart, getQR} = this.props
    changeSecondaryMenu()
    changeStateCart()
    getQR()
  }


  renderError(errors, fieldName) {
    return errors && errors[fieldName] &&
      <span className='field-error-message'>
        {errors[fieldName][0]}
      </span>;
  }

  render() {
    let {qrMember, qrStatus, errors} = this.props;
    let uidQR = ""
    if(qrMember & qrStatus!==STATUS_ERROR){
      uidQR = qrMember.qr.split("/")
      uidQR = qrMember.qr.split("/")[uidQR.length-1]
    }
    return (
      <div className=" container ">
        {
          (qrStatus===STATUS_SUCCESS) ?
            <div className={"row form container-update"}>
              <div className={"col-sm-12"}>
                <h5>Mi código QR</h5>
                <div className={"text-center"}>
                  <img className={"qr-image"} src={qrMember.qr} alt="qrMember"/>
                  <p>{uidQR.replace(".png", "")}</p>
                </div>
              </div>
            </div> :
            (qrStatus===STATUS_LOADING) ?
              <div className={"row form container-update"}>
                <div className={"col-sm-12"}>
                  <h5>Mi código QR</h5>
                  <div className={"qr-image load "}> </div>
                </div>
              </div>
              :
                (qrStatus===STATUS_ERROR) ?
                  <div className={"row form container-update"}>
                    <div className={"col-sm-12"}>
                      <h5>Mi código QR</h5>
                      <div className={"qr-image load"}> </div>
                      <p className={"text-center"}>Error QR</p>
                    </div>
                  </div>
                  :
                  <div className={"container-update load"}>
                    {this.renderError(errors, "qr")}
                  </div>

        }
      </div>

    );
  }
}

QRProfile.defaultProps = {
  error: null,
  updateStatus: '',
};

QRProfile.propTypes = {
  dispatch: PropTypes.func,
  error: PropTypes.object,
  updateStatus: PropTypes.string
};

