import React, {Fragment} from 'react'
import "./styles.scss"

export default class InfluencerRoot extends React.Component {

  componentDidMount() {
    const {setInfluencer, history, match: {params: {uid}}} = this.props
    setInfluencer(uid)
    console.log(uid)
    history.push("/products")
  }


  render() {
    return (
      <Fragment>

      </Fragment>
    );
  }
}

