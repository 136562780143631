import React from "react";
import PropTypes from "prop-types";
import "./style.scss";
import Validator from "../../../common/utils/validator";
import badgeIcon from "../../../common/images/badge.svg";

export default class PaymentForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userUid: "",
      first_name: "",
      phone_number: "",
      email: "",
      last_name: "",
      address: "",
      address_city: "",
      use_own_data: true,
      copy_address: false
    };
  }

  componentDidMount() {
    this.updateComponent();
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    this.updateComponent();
  }

  renderError(errors, fieldName) {
    return (
      errors &&
      errors[fieldName] && (
        <span className="field-error-message">{errors[fieldName][0]}</span>
      )
    );
  }

  validate = () => {
    return this.validator.validate({ ...this.state });
  };

  updateComponent() {
    const { user } = this.props;
    if (user && this.state.userUid !== user.uid) {
      this.setState({
        userUid: user.uid,
        first_name: user.first_name || "",
        phone_number: user.mobile_number || "",
        email: user.email || "",
        last_name: user.last_name || "",
        address: "",
        address_city: user.area ? user.area.name || "" : "",
      });
    }
    if (!this.validator) {
      this.validator = new Validator([
        {
          id: "first_name",
          ref: this.firstNameInput,
          rules: ["required"],
        },
        {
          id: "last_name",
          ref: this.lastNameInput,
          rules: ["required"],
        },
        {
          id: "phone_number",
          ref: this.phoneNumberInput,
          rules: ["required", "telephone"],
        },
        {
          id: "address_city",
          ref: this.addressCityInput,
          rules: ["required"],
        },
        {
          id: "address",
          ref: this.addressInput,
          rules: ["required"],
        },
        {
          id: "email",
          ref: this.emailInput,
          rules: ["required", "email"],
        },
      ]);
    }

    let {address,address_city} = this.state

    let sameAddressChecked = localStorage.getItem("sameAddressChecked") === 'true'

    let selectedLocality = JSON.parse(localStorage.getItem("selectedLocality"))
    if(sameAddressChecked){
      if(localStorage.getItem("delivery") === "SPECIFIC"){
        if(localStorage.getItem("selectedLocality") && (address!==localStorage.getItem("address") || address_city!==selectedLocality.name)){
          this.setFormValues({address: localStorage.getItem("address"), address_city: selectedLocality.name, copy_address: true})
        }
      }else{
        if(localStorage.getItem("delivery") === "GENERAL"){
          if((address!==localStorage.getItem("address"))){
            this.setFormValues({address: localStorage.getItem("address"), copy_address: true})
          }
        }
      }
    }else{
      console.log(sameAddressChecked, this.state.copy_address, "localStorage.getItem(\"sameAddressChecked\")")
      if(!sameAddressChecked && sameAddressChecked!==this.state.copy_address){
        this.setFormValues({address: "", address_city: "", copy_address: false})
      }
    }
  }

  setFormValues = (propertiesToUpdate) => {
    let { setInfoForm } = this.props;
    let payload = { ...this.state, ...propertiesToUpdate };
    this.setState(propertiesToUpdate);
    if (this.validator) this.validator.validate(propertiesToUpdate);
    setInfoForm({
      ...payload,
      isValid: this.validator.validateVisibleError(payload, false),
    });
  };

  render() {
    let { disabled, errors, setAddress,user } = this.props;

    return (
      <div className={"payment_user_info card prepayment  col-10 col-lg-10 mx-auto mt-4"}>
        <div className={"title-bar-section"}>
          <img
            src={badgeIcon}
            alt={"cart"}
            style={{ height: 30, width: 30, marginBottom: 4, marginRight: 8, marginLeft: 0 }}
          />
          <h6 className={""}>Datos de facturación</h6>
        </div>
        <div className={"p-0 personal-information"}>
          <div className= {user ? "form-group col-12 pl-0 mt-2" : "d-none"}>
            <div className="input-group prepayment">
              <input type="checkbox"
                     onChange={e => {
                       this.setState({use_own_data: true})
                     }
                     }
                     checked={this.state.use_own_data}
                     className="form-check-input " id="checkbox-terms"/>
              <label className="form-check-label" htmlFor="exampleCheck1" onClick={e => {
                this.setState({use_own_data: true})
              }}>
                <span>Mismos que la cuenta principal</span>
              </label>

            </div>
          </div>


          {/* <label htmlFor="document_type" className={"mt-3"}>
                Nombres
              </label> */}
              <div className={user && user.first_name !=="" && user.first_name !==null ? "d-none"  : "form-group col-12 col-lg-12 pl-0 mt-2"} >
                <div
                  className="input-group"
                  ref={(input) => {
                    if (input) {
                      this.firstNameInput = input.querySelector("input");
                    }
                  }}
                >
                  <input
                    name={"first_name"}
                    disabled={disabled}
                    className={"form-control"}
                    value={this.state.first_name}
                    onChange={(e) =>
                      this.setFormValues({ first_name: e.target.value })
                    }
                    type="text"
                    placeholder="Nombres *"
                  />
                </div>
                {this.renderError(errors, "first_name")}
              </div>

              {/* <label htmlFor="document_type">Apellidos</label> */}
              <div className= {user && user.last_name !=="" && user.last_name !==null ? "d-none"  : "form-group col-12 col-lg-12 pl-0 mt-2"}>
                <div
                  className="input-group"
                  ref={(input) => {
                    if (input) {
                      this.lastNameInput = input.querySelector("input");
                    }
                  }}
                >
                  <input
                    name={"last_name"}
                    disabled={disabled}
                    className={"form-control"}
                    value={this.state.last_name}
                    onChange={(e) =>
                      this.setFormValues({ last_name: e.target.value })
                    }
                    type="text"
                    placeholder="Apellidos *"
                  />
                </div>
                {this.renderError(errors, "last_name")}
              </div>

              {/* <label htmlFor="document_type">Correo electrónico</label> */}
              <div className={user && user.email !=="" && user.email !==null ? "d-none"  : "form-group col-12 col-lg-12 pl-0 mt-2"}>
                <div
                  className="input-group"
                  ref={(input) => {
                    if (input) {
                      this.emailInput = input.querySelector("input");
                    }
                  }}
                >
                  <input
                    className={"form-control"}
                    name={"email"}
                    disabled={disabled}
                    value={this.state.email}
                    onChange={(e) =>
                      this.setFormValues({ email: e.target.value })
                    }
                    type="text"
                    placeholder="Correo Electronico *"
                  />
                </div>
                {this.renderError(errors, "email")}
              </div>

        
              {/* <label className={user && user.mobile_number !=="" && "d-none"} htmlFor="document_type">Número de celular</label> */}
              <div className={user && user.mobile_number!=="" && user.mobile_number!==null ? "d-none" : "form-group col-12 col-lg-12 pl-0 mt-2"  } >
                <div
                  className="input-group"
                  ref={(input) => {
                    if (input) {
                      this.phoneNumberInput = input.querySelector("input");
                    }
                  }}
                >
                  <input
                    className={"form-control"}
                    name={"phone"}
                    disabled={disabled}
                    value={this.state.phone_number}
                    onChange={(e) =>
                      this.setFormValues({ phone_number: e.target.value })
                    }
                    type="text"
                    placeholder="Celular *"
                  />
                </div>
                {this.renderError(errors, "phone_number")}
              </div>
           

              {/* <label htmlFor="document_type">Ciudad</label> */}
              <div className={user && user.area && user.area.name!=="" && user.area.name!==null ? "d-none" : "form-group col-12 col-lg-12 pl-0 mt-2"}>
                <div
                  className="input-group"
                  ref={(input) => {
                    if (input) {
                      this.addressCityInput = input.querySelector("input");
                    }
                  }}
                >
                  <input
                    className={"form-control"}
                    name={"city"}
                    disabled={disabled}
                    value={this.state.address_city}
                    onChange={(e) =>
                      this.setFormValues({ address_city: e.target.value })
                    }
                    type="text"
                    placeholder="Ciudad *"
                  />
                </div>
                {this.renderError(errors, "address_city")}
              </div>

              {/* <label htmlFor="document_type">Dirección</label> */}
              <div className="form-group col-12 col-lg-12 pl-0 mt-2">
                <div
                  className="input-group"
                  ref={(input) => {
                    if (input) {
                      this.addressInput = input.querySelector("input");
                    }
                  }}
                >
                  <input
                    className={"form-control"}
                    name={"address"}
                    disabled={disabled}
                    value={this.state.address}
                    onChange={(e) => {
                      this.setFormValues({ address: e.target.value });
                      setAddress(e.target.value);
                    }}
                    type="text"
                    placeholder="Dirección *"
                  />
                </div>
                {this.state.address.length < 5 &&
                  this.state.address.length > 0 && (
                    <small className="field-error-message">
                      El campo requiere una dirección valida
                    </small>
                  )}
                {this.renderError(errors, "address")}
              </div>
        </div>
      </div>
    );
  }
}

PaymentForm.propTypes = {
  disabled: PropTypes.bool,
  errors: PropTypes.object,
  setInfoForm: PropTypes.func,
  user: PropTypes.object,
};
