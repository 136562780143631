import React from "react";
import ImageDefault from "../../../../../common/images/placeholder.jpg";

const ModalProductCarta = (props) => {
  const { product, close, currency } = props;
  //console.log(props);
  return (
    <div className="content-modal-carta">
      <div className="btn-close-carta" onClick={close}>
        <span>x</span>
      </div>
      <div className="item-image">
        <img
          src={product.image ? product.image : ImageDefault}
          alt="exchange-img"
        />
      </div>
      <div className="item-info">
        <h5> {product.title} </h5>
        {product.short_description!=="" && <h4>{product.short_description}</h4>}
        {product.short_description==="" && product.description !=="" && <h4>{product.description}</h4>}
        <h3 className="font-weight-bold">
          {currency ? currency.symbol : "S/."} {product.unit_price}
        </h3>
      </div>
    </div>
  );
};

export default ModalProductCarta;
