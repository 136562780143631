


export const OPEN_POPUP = 'OPEN_POPUP';



export function openPopUp(open, component) {
  return {
    type: OPEN_POPUP,
    open,
    component
  }

}
