import React, { Fragment } from "react";
import PropTypes from "prop-types";
// import Promotion from "../../../programPromotions/components/Promotion";
import {
  STATUS_LOADING,
  STATUS_SUCCESS,
} from "../../../../../common/utils/generic";
import "./style.scss";
import { Link } from "react-router-dom";
import MapPlacePromotion from "../mapPlacePromotion";
import DiscountCode from "./DiscountCode";
import TerminosIcon from "./Svg";
import PromotionItemDetail from "../../../programPromotions/components/PromotionItemDetail";

export default class ProgramPromotionDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisibleTerms: true,
      top: -1
    };
  }
 
  renderPlacesPromotions() {
    let { listPlacePromotion } = this.props;
    if (listPlacePromotion) {
      if (listPlacePromotion.length === 0) {
        return (
          <div>
            <p>Ups! , no pudimos encontrar locales</p>
          </div>
        );
      } else {
        // console.log(listPlacePromotion);
        return React.createElement(MapPlacePromotion, {
          listPlacePromotion: listPlacePromotion,
        });
      }
    }
  }
  goToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth',
    });
};
  componentDidUpdate(prevProps, prevState, snapshot) {
    let {promotionCurrent} = this.props
    if(promotionCurrent){
      if(window.scrollY!==0 && this.state.top!==0){
        this.goToTop()
        this.setState({top: 0})
      }
    }
  }

  show_place_description = (promotionCurrent) =>
    promotionCurrent.company_retail &&
    promotionCurrent.company_retail.description &&
    promotionCurrent.company_retail.description !== "" &&
    promotionCurrent.company_retail.description !== ".";

  see_leads_btn = (promotionCurrent) =>
    promotionCurrent.enable_lead &&
    promotionCurrent.lead_form &&
    !promotionCurrent.interested;

  btn_text_lead = (promotionCurrent) => {
    let btn_text_lead = "Estoy Interesado(a)";
    if (
      this.see_leads_btn(promotionCurrent) &&
      promotionCurrent.lead_form &&
      promotionCurrent.lead_form.button_text &&
      promotionCurrent.lead_form.button_text.length > 0
    ) {
      btn_text_lead = promotionCurrent.lead_form.button_text;
    }
    return btn_text_lead;
  };

  see_raffle = (promotionCurrent) => promotionCurrent.raffle != null;

  see_information_btn = (promotionCurrent) =>
    promotionCurrent.link && promotionCurrent.link.length > 0;

  get_terms = (promotionCurrent) => {
    let terms =
      promotionCurrent.promotion_terms &&
      promotionCurrent.promotion_terms !== "" &&
      promotionCurrent.promotion_terms !== "."
        ? promotionCurrent.promotion_terms
        : "";
    terms = terms === "" ? terms : terms + "\n\n";
    terms =
      terms +
      (promotionCurrent.terms &&
      promotionCurrent.terms !== "" &&
      promotionCurrent.terms !== "."
        ? promotionCurrent.terms
        : "");
    return terms;
  };

  render() {
    let {
      getCoupon,
      promotionCurrent,
      programUid,
      promotionUid,
      promotionStatus,
      statusDetailCoupon,
      errorDetailCoupon,
      couponDetail,
      company,
      multisite,
      app,
      openPopUp
      // color,
    } = this.props;

    //console.log(this.props, "promo detail");
    let currency = company.currency;
    let extraUrl = multisite ? `/c/${company.uid}` : "";

    return (
      <>
         <div className={"container program-promotion"}>
         {promotionStatus === STATUS_SUCCESS ? (
           <div className={"container-detail row"}>
             <div className={promotionCurrent.required_stamps<6 ? "promotionDetailContainer col-lg-4" : "promotionDetailContainer col-lg-5"}>

               <PromotionItemDetail is_detail={true}
                          image={this.state.image}
                          app={app}
                          programUid={programUid}
                          promotion={promotionCurrent}
                          openPopUp={openPopUp}
                          see_information_btn={()=>this.see_information_btn(promotionCurrent)}
               />

               {/* {this.see_leads_btn(promotionCurrent) && (
                 <Fragment>
                   <Link
                     to={`/promotions/${promotionUid}/interest`}
                     className="btn btn-secondary"
                   >
                     {this.btn_text_lead(promotionCurrent)}
                   </Link>
                 </Fragment>
               )}
               {this.see_raffle(promotionCurrent) && (
                 <Fragment>
                   <Link
                       to={`/promotions/${promotionCurrent.uid}/raffle/${promotionCurrent.raffle.uid}`}
                       className="btn btn-secondary"
                     >
                       {promotionCurrent.raffle.button_text != null && promotionCurrent.raffle.button_text}
                       {promotionCurrent.raffle.button_text == null && "Entrar al Sorteo"}
                     </Link>
                 </Fragment>
               )} */}


               <DiscountCode
                 multisite={multisite}
                 promotionCurrent={promotionCurrent}
                 statusDetailCoupon={statusDetailCoupon}
                 errorDetailCoupon={errorDetailCoupon}
                 couponDetail={couponDetail}
                 getCoupon={getCoupon}
                 programUid={programUid}
                 promotionUid={promotionUid}
                 company={company}
                 app={app}
               />
             </div>
             <div className={promotionCurrent.required_stamps<6 ? "col-lg-8 col-md-7" : "col-lg-7"}>
               <div className="detail_Title_Content">
                 <h4>{promotionCurrent.title}</h4>
                 {promotionCurrent.type === "GIFT_CARD" && (
                   <Fragment>
                     {promotionCurrent.purchase ? (
                       <div>
                         {promotionCurrent.purchase.total_balance <= 0.0 ? (
                           <div className="alert alert-info justify-content-md-center">
                             <span className="title">
                               No te queda saldo en esta promoción
                             </span>
                           </div>
                         ) : (
                           <div className="alert alert-info alert-success">
                             <span className="title">
                               Tienes un pago de
                               {currency ? currency.symbol : "S/."}
                               {promotionCurrent.purchase.total_balance} en este
                               producto
                             </span>
                             <Link
                               to={`${extraUrl}/program/${programUid}/promotions/${promotionUid}/redemptions/`}
                               className="pill-link"
                             >
                               Ir al historial
                             </Link>
                           </div>
                         )}
                       </div>
                     ) : (
                       <div>
                         <div className="alert alert-info justify-content-md-center">
                           <p className="title">
                             Aún no has adquirido esta promoción
                           </p>
                         </div>
                       </div>
                     )}
                   </Fragment>
                 )}
 
                 {this.show_place_description(promotionCurrent) && (
                   <div>
                     {/* <div className="promotions-store">
                       <h5>El establecimiento:</h5>
                       <p>{promotionCurrent.company_retail.description}</p>
                     </div>
                     <div className="line" /> */}
                   </div>
                 )}
                 {promotionCurrent.content !== null &&
                   promotionCurrent.content !== "" &&
                   promotionCurrent.content !== "." && (
                     <div>
                       <div className="benefit">
                         <p className={"benefit-detail"}>
                           {promotionCurrent.content}
                         </p>
                       </div>
                       {/* <div className="line" /> */}
                     </div>
                   )}
               </div>
               {this.get_terms(promotionCurrent) !== "" && (
                 <div className="detail_terms">
                   <div className="terms">
                     <h5>Términos y Condiciones <TerminosIcon className="terms_icon_detail" onClick={e=>this.setState({isVisibleTerms: !this.state.isVisibleTerms })} /> </h5>
                     {this.state.isVisibleTerms && (
                      <p className={"terms-and-conditions"}>
                       {this.get_terms(promotionCurrent)}
                     </p>
                     )} 
                   </div>
                   {/* <div className="line" /> */}
                 </div>
               )}
               {!promotionCurrent.is_digital_entity && (
                 <div className="detail_Place">
                   <h5>Lugares de canje</h5>
                   {this.renderPlacesPromotions()}
                 </div>
               )}
             </div>
           </div>
         ) : promotionStatus === STATUS_LOADING ? (
           <div className={"container-detail"}>
             <div className={"container-detail row"}>
             <div className="promotionDetailContainer col-lg-3 col-md-4">
                <div className="loading_detail_discount"></div>
             </div>
             <div className="col-lg-9 col-md-7">
             <div className="loading_detail_content">
              <h1>&nbsp;</h1>
              <h2>&nbsp;</h2>
              <h2>&nbsp;</h2>
             </div>
             <div className="loading_detail_content loading_terms">
              <h1>&nbsp;</h1>
              <h2>&nbsp;</h2>
              <h2>&nbsp;</h2>
             </div>
             </div>
             </div>
           </div>
         ) : (
           <div />
         )}
       </div> 

      {promotionStatus==="error" && (
        <div>
          {promotionCurrent}
        </div>
      ) }
      </>
    );
  }
}

ProgramPromotionDetail.defaultProps = {
  promotionCurrent: null,
  promotionUid: "",
  programUid: "",
  promotionStatus: "",
  color: "#36AA8C",
};

ProgramPromotionDetail.propTypes = {
  promotionCurrent: PropTypes.object,
  promotionUid: PropTypes.string,
  programUid: PropTypes.string,
  promotionStatus: PropTypes.string,
  getCoupon: PropTypes.func,
  color: PropTypes.string,
};
