
export default class Extras {
  static isDataComplete(user) {
    if (user) {
      if ((user.first_name && user.first_name !== "") &&
        (user.last_name && user.last_name !== "") &&
        (user.birthdate && user.birthdate !== "") &&
        (user.document_number  && user.document_number !== "") &&
        (user.gender && user.gender !== "")) {
        return true;
      }
    }
    return false;
  }
}