import React, {Fragment} from "react";
import PropTypes from "prop-types";


const Benefit = props => {
  let {promotion, loading} = props
  if (!loading) {

    let body = (
      <Fragment>
        <div className={"d-flex justify-content-between col-12 mt-2 mb-2 benefit flex-wrap"}>
          <p className={"mb-0"}>{promotion && promotion.name ? promotion.name : ""}</p>
          <p className={"mb-0"}>{promotion && promotion.coins && promotion.coins!==0 ? `${promotion.coins} Coins` : "" }</p>
          <p className={"mb-0"}>{promotion && promotion.total_points && promotion.total_points!==0 ? `${promotion.total_points} Pts.` : ""} </p>
        </div>
      </Fragment>
    );
    return (
      <div className={"col-12 p-0 mt-2"}>
          <Fragment>
            <div>{body}</div>
          </Fragment>
      </div>
    );
  } else {
    return (
      <div className={`load`}>
        <div>
          <figure>
            <div className="info">
              <div className={"pin loading"}>
                <div/>
              </div>
              <div className="row detail">
                {
                  false &&
                  <div className="col">
                    <p className="title loading"/>
                    <p className="subtitle loading"/>
                  </div>
                }
              </div>
            </div>
          </figure>

        </div>
      </div>
    )
  }

};

Benefit.defaultProps = {
  is_detail: false,
  loading: false,
  promotion: null,
  multisite: false,
  company: null,
  color: "#ccc"
};

Benefit.propTypes = {
  promotion: PropTypes.object,
  is_detail: PropTypes.bool,
  loading: PropTypes.bool,
  multisite: PropTypes.bool,
  company: PropTypes.object,
  color: PropTypes.string,
};
export default Benefit;