import {ERROR_DEFAULT, STATUS_ERROR, STATUS_LOADING, STATUS_SUCCESS} from "../common/utils/generic";

import {GET_COUPON, VALIDATE_COUPON, SET_COUPON_CURRENT} from "../actions/coupon";

const initialState = {
  couponDetail: null,
  errorDetailCoupon: null,
  statusDetailCoupon: null,
  couponCurrent:null,
  statusValidatedCoupon: null,
  errorValidatedCoupon: null,
  validatedCoupon: null
};

export default function reducer(state = initialState, action) {
  const {type, payload} = action;
  let data;
  switch (type) {

    case `${GET_COUPON}_PENDING`:
      return {
        ...state,
        statusDetailCoupon: STATUS_LOADING,
      };
    case `${GET_COUPON}_REJECTED`:
      return {
        ...state,
        statusDetailCoupon: STATUS_ERROR
      };
    case `${GET_COUPON}_FULFILLED`: {
      return {
        ...state,
        statusDetailCoupon: (payload.status === 200 || payload.status === 201) ? STATUS_SUCCESS : STATUS_ERROR,
        errorDetailCoupon: payload.status !== 200 ? ((payload.status === 400 ||payload.status === 406 )? payload.data : ERROR_DEFAULT) : null,
        couponDetail: payload.data ? payload.data : null,
      };
    }

    case `${SET_COUPON_CURRENT}`:
      return {
        ...state,
        couponCurrent: action.code,
        companyCouponUid: action.companyUid
      };

    case `${VALIDATE_COUPON}_PENDING`:
      return {
        ...state,
        statusValidatedCoupon: STATUS_LOADING,
      };
    case `${VALIDATE_COUPON}_REJECTED`:
      return {
        ...state,
        statusValidatedCoupon: STATUS_ERROR
      };
    case `${VALIDATE_COUPON}_FULFILLED`: {
      return {
        ...state,
        statusValidatedCoupon: (payload.status === 200 || payload.status === 201) ? STATUS_SUCCESS : STATUS_ERROR,
        errorValidatedCoupon: payload.status !== 200 ? (payload.status === 400 ? data : STATUS_ERROR) : null,
        validatedCoupon: payload.data ? payload.data : null,
      };
    }
    default:
      return state;
  }
}
