import React from 'react'
import PropTypes from 'prop-types';
import './style.scss';
import SimpleMap from "../../../common/components/GoogleMap/SimpleMap";
import {toast} from "react-toastify";
import Icon from "../../../common/icons/icon";

const $ = require('jquery');

export default class DeliveryPopUp extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      openModal: false,
      address_map: '',
      address: '',
      reference: '',
      location: null,
      firstLoad: false,
      sameAddressChecked: false,
      selectedLocality: {},
      firstSelect: false
    };
    this.setLatitudeLongitude = this.setLatitudeLongitude.bind(this)
  }

  componentDidMount() {

    if (!this.state.openModal) {
      $('#deliveryModal').modal('show');
      this.setState({openModal: true})
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
   let {selectedLocality} = this.props
    if(!this.state.firstSelect && JSON.stringify(this.state.selectedLocality)!==JSON.stringify(selectedLocality)){
      this.setState({selectedLocality: selectedLocality, firstSelect: true})
    }

    if ($(".modal-backdrop").length > 1) {
      $('.modal-backdrop:first').remove();
    }
    if(!this.state.firstLoad){
      this.setState({address: localStorage.getItem("address"), address_map: localStorage.getItem("address")})
      this.setState({reference: localStorage.getItem("reference")})
      this.setState({sameAddressChecked: localStorage.getItem("sameAddressChecked") === "true"})
      $("#reference").value = "fgg"

      this.setState({location: JSON.parse(localStorage.getItem("location"))})
      this.setState({firstLoad: true})
    }
    console.log(this.props.latitude , this.props.longitude)
    if(!localStorage.getItem("location")){
      if(this.props.latitude && this.props.longitude){
        if(this.state.location===null){
          this.setState({
            location: {
              latitude: this.props.latitude,
              longitude: this.props.longitude
            }
          })
        }
      }else{
        this.setState({
          location: {
            latitude: -12.1226727,
            longitude: -77.033459
          }
        })
      }
    }

  }

  handleCheckboxChange = (e) => {
    e.preventDefault()
    this.setState({ sameAddressChecked: !this.state.sameAddressChecked });
  };

  closeModal = (e) => {
    this.props.setOpenDeliveryPopUp(false)
    e.preventDefault()
    $(this.modal).modal('hide');
    $('body').removeClass('modal-open');
    $('.modal-backdrop').remove();
    this.setState({openModal: false})
  }

  setLatitudeLongitude(lat, long) {
    let that = this
    that.setState({location: {latitude: lat, longitude: long}})
  }

  setAddress = (address) => {
    this.setState({
      address: address,
      address_map: address
    })
  }

  searchPoint = event => {
    const self = this;
    self.setState({
      address: $(".pac-target-input").val(),
      address_map: $(".pac-target-input").val()
    });
  }

  isSpecific = () => {
    let {delivery, selectedPlace} = this.props
    if (delivery.toLowerCase()==="specific") {
      return true;
    }
    if (delivery.toLowerCase()==="none" && selectedPlace.delivery_zones.length!==0) {
      return true;
    }
    return false;
  }

  submitForm = event => {

    localStorage.setItem("sameAddressChecked", this.state.sameAddressChecked)

    if (localStorage.getItem("locality")){

      this.props.selectLocality(JSON.parse(localStorage.getItem("locality")))
    }

    if(this.isSpecific()){
      if (!localStorage.getItem("locality")){
        toast.error("Debe seleccionar una área de entrega")
        event.preventDefault()
      }
    }

    if(this.state.address_map!=="" && this.state.address_map!==null && this.state.address_map!=="null" && this.state.location!==null){
      localStorage.setItem("address",this.state.address_map)
      localStorage.setItem("location",JSON.stringify(this.state.location))
      localStorage.setItem("reference",$("#reference").val())
      this.closeModal(event)
    }else{
      if(this.state.address_map==="" || this.state.address_map===null || this.state.address_map==="null" ||this.state.location===null){
        toast.error("Dirección de entrega no válida")
      }
      event.preventDefault()
    }

  }
  selectDeliveryMethodInStore = event => {
      let {delivery_methods, selectDeliveryMethod} = this.props
    console.log(delivery_methods, "delivery_methods")
    delivery_methods.map(d=>{
      if(d.type.toLowerCase()==="pickup"){
        selectDeliveryMethod(d)
      }
      return true
    })
      this.closeModal(event)
  }



  render() {


    return (
      <div>
        <div>
          <div
            className={"modal fade"}
            id="deliveryModal"
            ref={(modal) => this.modal = modal}
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel2"
            aria-hidden="true"
          >
            <div
              className={"modal-dialog modal-dialog-popup modal-lg"}
              role="document">
              <div
                className={"modal-content"}>

                <button type="button" className="close btn-close-ad" data-dismiss="modal" aria-label="Close"
                        style={{zIndex: 99, paddingRight: "25px"}} onClick={this.closeModal}>
                  <span aria-hidden="true" style={{fontSize: "30px"}}>&times;</span>
                </button>
                <form className="modal-header modal-header-delivery">
                  <h3 className={"title-delivery"}>Añade una dirección de entrega</h3>
                  <div className={"body-single-map"} style={{width: "100%"}}>
                      <div className={"delivery-map"} style={{width: "100%"}}>

                        <div style={{width: "45%"}} className={!this.isSpecific() ? "d-none" : "nav-item dropdown nav-dates pb-1 pt-1"}>
                          <button className="nav-link nav-select"
                                  id="menu-dates"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false">
                            <span>Localidad</span><br/>

                            { this.state.selectedLocality ?
                              this.state.selectedLocality.name : "Seleccione una Localidad"
                            }


                            <Icon svg="down" classes="svg-icon down" title="Down"/>
                          </button>

                          <div className="dropdown-menu dropdown-select" aria-labelledby="menu-dates">
                            {
                              this.props.localities.map((locality, index) =>
                                <div key={`locality${index}`} className="dropdown-item"
                                     onClick={event => {
                                       localStorage.setItem("locality", JSON.stringify(locality))
                                       // this.props.selectLocality(locality)
                                       this.setState({
                                         selectedLocality: locality
                                       })
                                     }}
                                >
                                  <span>{locality.name}</span>
                                </div>
                              )
                            }
                          </div>
                        </div>

                        <div className={"d-flex col-10 m-0 p-0"}>
                          <input
                            type="checkbox"
                            id={"sameAddressChecked"}
                            checked={this.state.sameAddressChecked}
                            onChange={e=> {
                              this.setState({sameAddressChecked: !this.state.sameAddressChecked})
                              this.props.setCopyAddress(!this.state.sameAddressChecked)
                            }}
                          />
                          <label htmlFor="sameAddressChecked" className={"ml-2 col-12 col-lg-6 m-0 mt-2 mb-2 pl-1"}>Usar como dirección de facturación</label>
                        </div>
                        {
                          (localStorage.getItem("location") ||
                          this.state.location) &&
                          <SimpleMap
                            sameAddressChecked={this.state.sameAddressChecked}
                            handleCheckboxChange={e=>this.setState({sameAddressChecked: !this.state.sameAddressChecked})}
                            height='300px'
                            zoom={16}
                            center={{
                              lat: this.state.location ? this.state.location.latitude : 0,
                              lng: this.state.location ? this.state.location.longitude : 0
                            }}
                            address={this.state.address_map}
                            onDragEvent={this.setLatitudeLongitude}
                            setAddress={(address) => this.setAddress(address)}
                            searchPoint={(e) => this.searchPoint(e)}


                            disabled={this.props.disabled}
                            localities={this.props.localities}
                            selectedLocality={this.state.selectedLocality}
                            selectLocality={this.props.selectLocality}
                          />
                        }
                      </div>
                  </div>

                  <button
                    className="btn btn-login btn-primary mb-4 btn-delivery-form first text-uppercase"
                    onClick={e=> this.submitForm(e)}
                  >
                    Confirmar dirección
                  </button>
                  {
                    this.props.allows_pickup &&
                    <button
                      className="btn btn-login btn-secondary btn-delivery-form second text-uppercase"
                      onClick={e=> this.selectDeliveryMethodInStore(e)}
                    >
                      Prefiero recogerlo en tienda
                    </button>
                  }
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

DeliveryPopUp.defaultProps = {
  adsCompany: [],
};

DeliveryPopUp.propTypes = {
  adsCompany: PropTypes.array,
};


