import HttpClient from "../common/utils/httpClient";

export const GET_POLLS = 'GET_POLLS';
export const GET_POLLS_RESOLVED = 'GET_POLLS_RESOLVED';
export const POLL_DETAIL = 'POLL_DETAIL';
export const SEND_POLL = 'SEND_POLL';
export const CLEAR_STATUS_POLL = 'CLEAR_STATUS_POLL';
export const CLEAR_STATUS_SEND_POLL = 'CLEAR_STATUS_SEND_POLL';

/**Urls**/
export const LIST_POLLS_URL = 'v5/me/programs/{program_uid}/polls/';
export const UPDATE_RETRIEVE_POLL_URL = 'v5/me/programs/{program_uid}/polls/{poll_uid}/';


export function getPolls(programUid) {
  return (dispatch) => HttpClient.get(dispatch, GET_POLLS,
    LIST_POLLS_URL.replace("{program_uid}", programUid));
}
export function getPollsResolved(programUid) {
  return (dispatch) => HttpClient.get(dispatch, GET_POLLS_RESOLVED,
    `${LIST_POLLS_URL.replace("{program_uid}", programUid)}?is_resolved=true`
    );
}

export function getNumberPagePolls(programUid, numberPage) {
  return (dispatch) => HttpClient.get(dispatch, GET_POLLS,
    `${LIST_POLLS_URL.replace("{program_uid}", programUid)}?page=${numberPage}`);
}

export function retrievePoll(programUid, pollUid) {
  return (dispatch) => HttpClient.get(
    dispatch,
    POLL_DETAIL,
    UPDATE_RETRIEVE_POLL_URL.replace("{program_uid}", programUid).replace("{poll_uid}", pollUid)
  );
}
export function sendPoll(pollUid, data) {
  return (dispatch) => HttpClient.post(
    data,
    dispatch,
    SEND_POLL,
    `v5/me/polls/${pollUid}/responses/`
  );
}

export function clearStatusPoll() {
  return {
    type: CLEAR_STATUS_POLL
  };
}

export function clearStatusSendPoll() {
  return {
    type: CLEAR_STATUS_SEND_POLL
  };
}
