import React, { Fragment } from "react";
import PropTypes from "prop-types";
import {
  STATUS_ERROR,
  STATUS_LOADING,
  STATUS_SUCCESS,
} from "../../common/utils/generic";
import "./style.scss";
import { toast } from "react-toastify";
import PromotionsDeleteList from "./PromotionsDeleteList";
import down from "../../common/images/down.png";

export default class ValidateDeleteAccount extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      uidApp: "",
      uid: "",
      token: "",
      showResponse: "",
      loadPromotions: false,
      collapse: true,

      pageCurrent: 1,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let { match, validateStatus, getPromotionsDelete } = this.props;
    if (validateStatus === STATUS_ERROR && this.state.showResponse === true) {
      toast.error("El link ha expírado");
      this.setState({ showResponse: false });
    } else {
      if (
        validateStatus === STATUS_SUCCESS &&
        this.state.showResponse === true
      ) {
        if (!this.state.loadPromotions) {
          this.setState({ loadPromotions: true });
          getPromotionsDelete(match.params.uidUser, match.params.token);
        }
      }
    }
  }

  componentDidMount() {
    const { match, validateLinkExpiration } = this.props;
    const uidApp = match.params.uidApp;
    const uidUser = match.params.uidUser;
    const token = match.params.token;

    if (uidUser !== undefined && token !== undefined) {
      this.setState({ uidApp: uidApp });
      this.setState({ uid: uidUser });
      this.setState({ token: token });
      validateLinkExpiration(uidUser, token);

      this.setState({ showResponse: true });
    } else {
      this.props.history.push(`/`);
    }
  }

  renderError(errors, fieldName) {
    return (
      errors &&
      errors[fieldName] && (
        <span className="field-error-message">{errors[fieldName]}</span>
      )
    );
  }

  onSubmitForm = async (event) => {
    event.preventDefault();
    let { deleteUser, match } = this.props;
    deleteUser(match.params.uidUser, match.params.token);
  };

  render() {
    let {
      validateStatus,
      promotionsDeleteResults,
      promotionsDeleteEntities,
      count,
      nextPage,
      previousPage,
      getNumberPage,
      listStatus,
      getPagePromotions,
      statusDeleteUser,
      app: {
        company: { logo_rectangle, logo },
      },
    } = this.props;
    //console.log(this.props);
        let validate =
      promotionsDeleteResults && promotionsDeleteEntities && promotionsDeleteResults.length <=1
        ? promotionsDeleteResults.map((cUid) => promotionsDeleteEntities[cUid])
        : [];
        //console.log(validate);
        let validateShow = validate.length===1 && validate.coins!==null && validate.coins!==0 ? false : true;
        //console.log(validateShow);

      
    let logo_app = logo_rectangle ? logo_rectangle : logo;
    return (
      <Fragment>
        {statusDeleteUser === STATUS_SUCCESS ? (
          <div
            className={"d-flex  align-items-center"}
            style={{ height: "100vh" }}
          >
            <div className={"d-flex flex-column align-items-center mx-auto"}>
              <p>Su cuenta ha sido eliminada.</p>
              <p>Esperamos volver a verte pronto</p>
            </div>
          </div>
        ) : validateStatus === STATUS_SUCCESS ? (
          <div className={"container delete-account-page"}>
            <div className={"container-img"}>
              <img
                src={logo_app ? logo_app : logo}
                className="logo"
                alt="logo"
              />
            </div>
            <h3>Hola,</h3>
            <p>
              Estás a punto de eliminar tu cuenta de usuario del app. De
              hacerlo, se dejarán de visualizar todos los beneficios que hayas
              acumulado con ella. Si realmente deseas eliminar tu cuenta, dale
              click a "Dar de baja mi cuenta".
            </p>
            {promotionsDeleteResults && promotionsDeleteEntities && validateShow && (
              <Fragment>
                <strong
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({ collapse: !this.state.collapse });
                  }}
                >
                  Beneficios y programas que se eliminarán
                  <img
                    src={down}
                    alt="down"
                    className={
                      this.state.collapse ? "ml-2 no-invert" : "ml-2 invert"
                    }
                    height={15}
                    width={16}
                  />
                </strong>
                <PromotionsDeleteList
                  programPromotions={
                    promotionsDeleteResults && promotionsDeleteEntities
                      ? promotionsDeleteResults.map(
                          (cUid) => promotionsDeleteEntities[cUid]
                        )
                      : []
                  }
                  count={count}
                  nextPagePromotions={nextPage}
                  previousPagePromotions={previousPage}
                  getNumberPage={(pageUid) =>
                    getNumberPage(this.state.uid, pageUid, this.state.token)
                  }
                  collapse={this.state.collapse}
                  listStatus={listStatus}
                  getPagePromotions={getPagePromotions}
                  pageCurrent={this.state.pageCurrent}
                  setPageCurrent={(page) => {
                    this.setState({ pageCurrent: page });
                  }}
                  showPrograms={this.state.showPrograms}
                />
              </Fragment>
            )}
            <div className="submits col-sm-12 flex-column">
              <button
                type="submit"
                onClick={(e) => this.onSubmitForm(e)}
                style={{ textOverflow: "ellipsis", overflow: "hidden" }}
                className="btn btn-delete-account"
              >
                Dar de baja mi cuenta
              </button>
            </div>
          </div>
        ) : validateStatus === STATUS_LOADING ? (
          <div className={"not-data-page"}>Cargando ...</div>
        ) : (
          <div className={"not-data-page"}>El link ha expirado</div>
        )}
      </Fragment>
    );
  }
}

ValidateDeleteAccount.defaultProps = {
  errorValidate: null,
  validateStatus: "",
};

ValidateDeleteAccount.propTypes = {
  dispatch: PropTypes.func,
  errorValidate: PropTypes.object,
  validateStatus: PropTypes.string,
};
