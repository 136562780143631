import {normalize, schema} from 'normalizr';
import {GET_PLACES, SELECT_PLACE} from "../actions/places";


import {STATUS_ERROR, STATUS_LOADING, STATUS_SUCCESS} from "../common/utils/generic";

const placeSchema = new schema.Entity('places', {}, {idAttribute: 'uid'});
const placeListSchema = new schema.Array(placeSchema);


const initialState = {
  entities: null,
  results: [],
  nextPage: null,
  selectedPlace: null,
  selectedPlaceUid: null,
  listStatus: null,
  count: 0
};

export default function reducer(state = initialState, action) {
  const {type, payload} = action;
  let data;
  switch (type) {

    case `${GET_PLACES}_PENDING`:
      return {
        ...state,
        listStatus: STATUS_LOADING
      };

    case `${GET_PLACES}_FULFILLED`:
      data = payload.status === 200 ? normalize(payload.data.results, placeListSchema) : {};
      let selectedPlace = null
      if (state.selectedPlaceUid && data.entities && data.entities.places) {
        selectedPlace = data.entities.places[state.selectedPlaceUid] ? data.entities.places[state.selectedPlaceUid] : null

      }
      /*
      if (payload.data.results.length === 1 && data.entities) {
        selectedPlace = data.entities.places[payload.data.results[0].uid]
        localStorage.setItem('selectedPlaceUid', selectedPlace.uid);
      }*/

      return {
        ...state,
        entities: data.entities ? data.entities.places : [],
        results: data.result ? data.result : [],
        nextPage: payload.data ? payload.data.next : null,
        listStatus: payload.status === 200 ? STATUS_SUCCESS : STATUS_ERROR,
        count: payload.data ? payload.data.count : null,
        selectedPlace: selectedPlace,
        selectedPlaceUid: selectedPlace ? selectedPlace.uid : null
      };

    case SELECT_PLACE:
      let placeUid = action.place ? action.place.uid : null
      localStorage.setItem('selectedPlaceUid', placeUid);
      return {
        ...state,
        selectedPlace: action.place,
        selectedPlaceUid: placeUid
      };
    default:
      return state;
  }
}
