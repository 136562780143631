import React from 'react'
import PropTypes from 'prop-types';
import {STATUS_LOADING, STATUS_SUCCESS} from '../../common/utils/generic';
import Validator from '../../common/utils/validator';
import {BTN_UPDATE, BTN_UPDATE_LOADING} from "./constants";
import './style.scss';
import arrow_down from "../../common/images/downarrow.png";
import {toast} from "react-toastify";
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import moment from "moment";
import countriesPhone from "../../common/components/JSON/countriesPhone.json";
import Select from "react-select";
import downarrow from "../../common/images/downarrow.png"

export default class UpdateProfile extends React.Component {


  constructor(props) {
    super(props);

    this.state = {
      document_type: "NATIONAL_ID",
      type_text: "Documento de Identidad *",
      document_number: "",
      first_name: "",
      last_name: "",
      birthdate: new Date(),
      gender: "",
      error: '',
      mobile_number: "",
      country_code_number: "",
      code: "",
      loadCode: false,
      showResponse: false,
      readOnly: false,

      accepted_terms_cond: true,
      accepted_politics_privacity: true,
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.updateComponent();
    let { updateStatus,getProfile, changeSecondaryMenu, changeStateCart, stateCart } = this.props;
    if( updateStatus === STATUS_SUCCESS && this.state.showResponse){
      getProfile();
      toast.success("Datos actualizados correctamente.");

      this.setState({ showResponse: false })
    }
    if(stateCart!=="CART_INVISIBLE"){
      changeSecondaryMenu()
      changeStateCart()
    }
  }

  componentDidMount() {
    let {changeSecondaryMenu, changeStateCart} = this.props
    changeSecondaryMenu()
    changeStateCart()
    this.updateComponent();
  }

  updateComponent = () => {
    let {user} = this.props;
    if(user) {
      if(this.state.document_number !== user.document_number){
        let birthdate =  new Date(user.birthdate)
        birthdate = birthdate.setDate(birthdate.getDate() + 1)
        birthdate =  new Date(birthdate)
        console.log(user, "user")
        this.setState({
          document_type: user.document_type,
          document_number: user.document_number,
          first_name: user.first_name,
          last_name: user.last_name,
          birthdate: (user.birthdate === '' || user.birthdate === null) ? "" : birthdate,
          gender: (user.gender === '' || user.gender === null) ? "" : user.gender,
          country_code_number: (user.country_code_number === '' || user.country_code_number === null) ? "" : user.country_code_number,
          //mobile_number: (user.mobile_number === '' || user.mobile_number === null) ? "" : user.mobile_number.replace(`+${user && user.area && user.area.country.code}`,"")
          mobile_number: (user.mobile_number === '' || user.mobile_number === null) ? "" : user.mobile_number.replace(user.country_code_number, '')
        })
        this.validator = new Validator([
          {
            id: "document_number",
            ref: this.dniInput,
            rules: []
          },
          {
            id: "first_name",
            ref: this.firstNameInput,
            rules: ["required"]
          },
          {
            id: "last_name",
            ref: this.lastNameInput,
            rules: ["required"]
          },
          {
            id: "mobile_number",
            ref: this.mobileInput,
            rules: [""]
          },
        ]);
      }
    }

    if(!this.state.loadCode && user && user.area && this.state.code !== `+${user && user.area && user.area.country.code}`){
      this.setState({code: `+${user && user.area && user.area.country.code}`, loadCode: true})
    }
  }


  handleOption = (e, gender) => {
    this.setState({gender});
  };

  selectDocumentType = (e, name, type_id) => {
    e.preventDefault();
    this.setState({type_text: name, type: type_id});
  };

  setFormValues = (propertiesToUpdate) => {
    this.setState(propertiesToUpdate);
    this.validator.validate(propertiesToUpdate);
  }

  onSubmitForm = async (event) => {
    event.preventDefault();

    if (!this.state.accepted_terms_cond) {
      toast.error("Debe aceptar los terminos y condiciones");
      return;
    }

    if (!this.state.accepted_politics_privacity) {
      toast.error("Debe aceptar las políticas de tratamiendo de datos personales");
      return;
    }

    this.setState({ showResponse: true })
    let {updateUser} = this.props;
    let payload = {...this.state}
    delete payload.readOnly
    delete payload.showResponse
    delete payload.type_text
    delete payload.error
    payload.first_name = payload.first_name.split(" ")[0]
    payload.last_name = payload.last_name.split(" ")[0]
    if (payload.birthdate === '') {
      delete payload.birthdate
    }
    payload.birthdate = moment(payload.birthdate).format("YYYY-MM-DD")
    if (payload.birthdate === 'Fecha invalida') {
      delete payload.birthdate
    }
    if (payload.gender === '') {
      delete payload.gender
    }

    if(payload.mobile_number !== ''){
      payload.mobile_number = `${this.state.code}${payload.mobile_number}`
      if(this.state.country_code_number.length===0){
        toast.error("Debe seleccionar el código de un país")
        return
      }
      payload.country_code_number = `${this.state.code}`
    }
    if (this.validator.validate(payload)) {
      updateUser(payload);
    }

  }


  renderError(errors, fieldName) {
    return errors && errors[fieldName] &&
      <span className='field-error-message'>
        {errors[fieldName][0]==="This field may not be blank." ? "El campo no puede estar vacío." : errors[fieldName][0]}
      </span>;
  }

  onChangeDate = date => {
    this.setState({birthdate: date})
  }

  getYear = date => {
    return date.getFullYear()
  }
  getMonth = date => {
    return date.getMonth()
  }

  handleInputChange = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({[name]: value});
  };

  render() {
    let {updateStatus, errors, user} = this.props;
    const years = Array(this.getYear(new Date()) + 1 - 1960 + 1).fill().map((_, idx) => 1960 + idx)
    const months = [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Setiembre",
      "Octubre",
      "Noviembre",
      "Diciembre"
    ];
    let listCountriesPhone = countriesPhone.countries
    let options = []
    let defaultValue = null
    listCountriesPhone.sort(function (o1,o2) {
      if (o1.name_es > o2.name_es) { //comparación lexicogŕafica
        return 1;
      } else if (o1.name_es < o2.name_es) {
        return -1;
      }
      return 0;
    });
    console.log(user, "user")
    listCountriesPhone.map(c => {
      options.push({value: `${c.dial_code}`, label: `${c.dial_code} ${c.name_es}`})
      if(user && user.country_code_number && user.country_code_number.length!==0){
        if (c.dial_code === `${user.country_code_number}`) {
          defaultValue = {value: `${c.dial_code}`, label: `${c.dial_code} ${c.name_es}`}
        }
      }else{
        if(user && user.mobile_number && user.mobile_number.length!==0){
          if (c.dial_code === `+${user.mobile_number.substring(1,3)}`) {
            defaultValue = {value: `${c.dial_code}`, label: `${c.dial_code} ${c.name_es}`}
          }
        }
      }
      return true
    })
    let mainColor = localStorage.getItem("colors_main_color") ? localStorage.getItem("colors_main_color") : "#1b4bc6"

    return (
      <div className=" container ">
        {
          (user) ?
            <form className={"row form container-update"}>
              <div className={"col-sm-12"}>
                <h5>Actualizar Información Personal</h5>
              </div>
              <div className={"profile-form col-xs-12 col-sm-12 col-md-6"}>
                <div className={"form-group dropdown"}>
                  {this.state.readOnly ?
                    <div className="form-control"
                         data-toggle=""
                         aria-haspopup="true"
                         aria-expanded="false">
                        <span style={{
                          display: "block",
                          marginTop: "5px",
                        }}>{this.state.type_text}</span>
                      <img src={arrow_down} alt="" className={"arrow-down"}/>
                    </div> :
                    <div
                      className="form-control"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false">
                        <span style={{
                          display: "block",
                          marginTop: "5px",
                        }}>{this.state.type_text}</span>
                      <img src={arrow_down} alt="" className={"arrow-down"}/>
                    </div>
                  }
                  <div
                    className="dropdown-menu"
                    style={{
                      width: "100%",
                      overflowY: "auto",
                      maxHeight: 200
                    }}
                  >
                    <a
                      href={"/"}
                      className="dropdown-item dropdown-type select-type"
                      onClick={e =>
                        this.selectDocumentType(
                          e,
                          "Documento de Identidad *",
                          "NATIONAL_ID"
                        )
                      }
                    >
                      Documento de Identidad
                    </a>
                    <a
                      href={"/"}
                      className="dropdown-item dropdown-type select-type"
                      onClick={e =>
                        this.selectDocumentType(
                          e,
                          "Pasaporte",
                          "PASSPORT"
                        )
                      }
                    >
                      Pasaporte
                    </a>
                    <a
                      href={"/"}
                      className="dropdown-item dropdown-type select-type"
                      onClick={e =>
                        this.selectDocumentType(
                          e,
                          "Carnet de Extranjería",
                          "FOREIGNER_ID"
                        )
                      }
                    >
                      Carnet de Extranjería
                    </a>
                    <a
                      href={"/"}
                      className="dropdown-item dropdown-type select-type"
                      onClick={e =>
                        this.selectDocumentType(e, "Ruc", "TAX_ID")
                      }
                    >
                      Ruc
                    </a>
                    <a
                      href={"/"}
                      className="dropdown-item dropdown-type select-type"
                      onClick={e =>
                        this.selectDocumentType(e, "Otro", "OTHER")
                      }
                    >
                      Otro
                    </a>
                  </div>
                </div>
                <div className={"form-group "}>
                  <div className="input-group" ref={input => {
                    if (input) {
                      this.dniInput = input.querySelector("input");
                    }
                  }}>
                    <input
                      value={this.state.document_number}
                      className="form-control"
                      onChange={e => this.setFormValues({document_number: e.target.value})}
                      type="text"
                      placeholder="Documento de identidad *"
                      disabled={updateStatus === STATUS_LOADING}
                      readOnly={this.state.readOnly}
                    />

                  </div>
                  {this.renderError(errors, 'document_number')}
                </div>

                <div className={"form-group "}>
                  <div
                    className="input-group"
                    ref={(input) => {
                      if (input) {
                        this.firstNameInput = input.querySelector('input');
                      }
                    }}
                  >
                    <input
                      className={"form-control"}
                      value={this.state.first_name}
                      onChange={e => this.setFormValues({first_name: e.target.value})}
                      type="text"
                      placeholder="Nombres *"
                      disabled={updateStatus === STATUS_LOADING}
                      readOnly={this.state.readOnly}
                    />

                  </div>
                  {this.renderError(errors, 'first_name')}
                </div>
                <div className={"form-group "}>


                  <div
                    className="input-group"
                    ref={(input) => {
                      if (input) {
                        this.lastNameInput = input.querySelector('input');
                      }
                    }}
                  >
                    <input
                      className={"form-control"}
                      value={this.state.last_name}
                      onChange={e => this.setFormValues({last_name: e.target.value})}
                      type="text"
                      placeholder="Apellidos *"
                      disabled={updateStatus === STATUS_LOADING}
                      readOnly={this.state.readOnly}
                    />
                  </div>
                  {this.renderError(errors, 'last_name')}
                </div>

              </div>


              <div className={" col-xs-12 col-sm-12 col-md-6"}>
                <div className={"form-group"}>
                  <div
                    className="input-group">
                    <DatePicker
                      placeholderText="Seleccione su fecha de nacimiento"
                      className={"form-control"}
                      dateFormat="dd/MM/yyyy"
                      renderCustomHeader={({
                                             date,
                                             changeYear,
                                             changeMonth,
                                             decreaseMonth,
                                             increaseMonth,
                                             prevMonthButtonDisabled,
                                             nextMonthButtonDisabled
                                           }) => (
                        <div
                          style={{
                            margin: 10,
                            display: "flex",
                            justifyContent: "center"
                          }}
                        >
                          <button onClick={(e)=> {
                            e.preventDefault()
                            decreaseMonth()
                          }} disabled={prevMonthButtonDisabled}>
                            {"<"}
                          </button>
                          <select
                            value={this.getYear(date)}
                            onChange={({ target: { value } }) => changeYear(value)}
                          >
                            {years && years.map(option => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>

                          <select
                            value={months[this.getMonth(date)]}
                            onChange={({ target: { value } }) =>
                              changeMonth(months.indexOf(value))
                            }
                          >
                            {months.map(option => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>

                          <button onClick={(e)=> {
                            e.preventDefault()
                            increaseMonth()
                          }} disabled={nextMonthButtonDisabled}>
                            {">"}
                          </button>
                        </div>
                      )}
                      selected={this.state.birthdate}
                      onChange={date => this.onChangeDate(date)}
                    />
                  </div>
                </div>
                {
                  false &&
                  <div className={"form-group "}>
                    <Select options={options}
                            onChange={e => {
                              this.setState({
                                code: e.value
                              });
                            }}
                            defaultValue={defaultValue}
                    />
                  </div>
                }
                <div className={"form-group "} style={{position: "relative"}}>
                  <Select options={options}
                          onChange={e => {
                            this.setState({
                              code: e.value,
                              country_code_number: e.value
                            });
                          }}
                          defaultValue={defaultValue}
                          placeholder={"Seleccione el país"}
                  />
                  <img src={downarrow} alt="downarrow" className={"down-phone-number"} style={{width: 20, height: 20}}/>
                </div>
                <div className={"form-group "}>
                  <div className="input-group" ref={input => {
                    if (input) {
                      this.mobileInput = input.querySelector("input");
                    }
                  }}>
                    <input
                      value={this.state.mobile_number}
                      className="form-control"
                      onChange={e => this.setFormValues({mobile_number: e.target.value})}
                      type="text"
                      placeholder="Número de celular "
                      disabled={updateStatus === STATUS_LOADING}
                      readOnly={this.state.readOnly}
                    />

                  </div>
                  {this.renderError(errors, 'mobile_number')}
                </div>


                <div className={"form-group "}>
                  <label>Género:</label>
                  <div className="form-check">

                    <input
                      className="form-check-input"
                      type="radio"
                      name="gender"
                      value="f"
                      onChange={e => this.handleOption(e, "f")}
                      checked={this.state.gender === 'f'}
                      disabled={this.state.readOnly}
                    />
                    <label className="form-check-label" htmlFor="f">
                      Femenino
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="gender"
                      value="m"
                      onChange={e => this.handleOption(e, "m")}
                      checked={this.state.gender === 'm'}
                      disabled={this.state.readOnly}
                    />
                    <label className="form-check-label" htmlFor="m">
                      Masculino
                    </label>
                  </div>

                  {this.renderError(errors, 'gender')}
                </div>
              </div>

              {
                (errors && errors.non_field_errors) &&
                <div
                  style={{marginTop: 15}}
                >
                  {this.renderError(errors, 'non_field_errors')}
                </div>
              }
              <div className={"d-flex flex-column col-12"}>
                <div className="submits col-sm-12 col-md-4 flex-column">
                  <button
                    type="submit"
                    onClick={e=>this.onSubmitForm(e)}
                    disabled={updateStatus === STATUS_LOADING || this.state.readOnly}
                    className="btn btn-update btn-primary "
                    style={{textOverflow:"ellipsis", overflow: "hidden", backgroundColor: `${mainColor}`, border: `2px solid ${mainColor}`}}>
                    {updateStatus === STATUS_LOADING ? BTN_UPDATE_LOADING : BTN_UPDATE}
                  </button>
                </div>

                  <a
                    href={"/delete"}
                    className={"btn-delete"}
                    >
                    Dar de baja mi cuenta
                  </a>
              </div>

            </form>
            :
              <div className={"container-update load"}>
                <h6>Cargando datos personales...</h6>
              </div>
        }
      </div>

    );
  }
}

UpdateProfile.defaultProps = {
  error: null,
  updateStatus: '',
};

UpdateProfile.propTypes = {
  dispatch: PropTypes.func,
  error: PropTypes.object,
  updateStatus: PropTypes.string
};

