import React, { Component, Fragment } from "react";
import { STATUS_INCLUDE, STATUS_SUCCESS } from "../../../common/utils/generic";
import bg from "../../../common/images/background.jpg";
import CoinSvg from "../../../common/images/Coin.svg";
import ProductsPlaceList from "../../products/productsPlace/ProductsPlaceList";
import { CART_INVISIBLE, CART_VISIBLE } from "../../../actions/cart";

const $ = require("jquery");

export default class ProgramExchange extends Component {
  constructor(props) {
    super(props);
    this.state = {
      terms_conditions: false,
      stampsExist: false,
      uidProgram: "",
      selectedAreaUid: "",
      program: null,
      placeUid: "",
      //
      isEcommerceEnabled: null,
      programUid: "",
    };
    // console.log(this.props,"more new");
  }
  componentDidMount() {
    const { changeSecondaryMenu, changeStateCart , changeTypeCart } = this.props;
    changeStateCart();
    changeSecondaryMenu();
    changeTypeCart();
  }
  existProgram = (uid) => {
    const { app } = this.props;
    if (app && app.allowed_programs) {
      for (let i = 0; i < app.allowed_programs.length; i++) {
        if (app.allowed_programs[i].uid === uid) return true;
      }
    }
    return false;
  };
  componentDidUpdate(prevProps, prevState, snapshot) {
    if ($(".modal-backdrop").length > 1) {
      $(".modal-backdrop:first").remove();
    }
    this.updateComponent();
  }

  updateComponent() {
    const {
      match: {
        params: { uid },
      },
      getSections,
      getProgram,
      selectedAreaUid,
      getPromotions,
      getAds,
      getRaffles,
      programs: {
        entity: program,
        retrieveStatus: retrieveStatusProgram,
        addStatus,
      },
      getProductsByPlace,
      selectedPlace,
      //Product
      programs,
      company,
      changeStateCart,
    } = this.props;

    if (uid && uid !== this.state.uidProgram) {
      if (this.existProgram(uid)) {
        this.setState({ uidProgram: uid });
        getProgram(uid);
        getAds(uid, { type: "POP_UP" });
        getRaffles(uid);
      }
    }
    if (retrieveStatusProgram === STATUS_SUCCESS && program != null) {
      if (
        this.state.program == null ||
        (this.state.program && program.uid !== this.state.program.uid) ||
        this.state.selectedAreaUid !== selectedAreaUid
      ) {
        this.setState({ selectedAreaUid });
        this.setState({ program: program });
        getPromotions(uid, { area: selectedAreaUid });
        getSections(uid);
      }
    }
    if (
      addStatus === STATUS_SUCCESS &&
      retrieveStatusProgram === STATUS_INCLUDE &&
      program != null
    ) {
      getProgram(uid);
    }
    if (selectedPlace !== null && selectedPlace) {
      if (this.state.placeUid !== selectedPlace.uid) {
        this.setState({ placeUid: selectedPlace.uid });
        if (selectedPlace.uid !== null) {
          getProductsByPlace(selectedPlace.uid);
          // getSectionsToProducts(selectedPlace.uid);
        }
      }
    }

    //Product
    if (programs && programs.results.length !== 0) {
      if (this.state.programUid !== programs.results[0]) {
        this.setState({ programUid: programs.results[0] });
        getRaffles(programs.results[0]);
      }
    }
    if (company.ecommerce_enabled !== this.state.isEcommerceEnabled) {
      this.setState({ isEcommerceEnabled: company.ecommerce_enabled });
      if (company.ecommerce_enabled) {
        changeStateCart(CART_VISIBLE);
      } else {
        changeStateCart(CART_INVISIBLE);
      }
    }
    
  }
  render() {
    let {
      company: { banner, cover },
      company,
      multisite,
      programs: {
        entity: program,
      },
      history,
      productsPlace,

      //Product
      sectionsProducts: {
        entities: sectionsEntities,
        results: sectionsResults,
      },
      authStatus,
      selectedPlace,
      productsPlace: {
        entities: productsByPlaceEntities,
        results: productsByPlaceResults,
        listProductsByPlaceStatus,
        listNextStatus: ProductListNextStatus,
        nextPage: ProductNextPage,
        count: ProductCount,
        productsByPlaceCurrent,
        productsByPlaceCurrentStatus,
      },
      getMoreProductsByPlace,
      getProductCurrent,
      openPopUp,
      activePopUp,
      componentPopUp,
      addProductCart,
      addProductsBack,
      entitiesCart,
      updateProductCart,
      typeCart,
      cleanCart,
    } = this.props;
    let cover_app = banner ? banner : cover || bg;
    //console.log(this.props, "list");
    return (
      <Fragment>
        {program && (
          <div className={"program-banner"}>
            <div className="container program-promotions program-promotions__discount">
              {/* <div>
                {company  && company.banner && (
                    <img src={company.banner}  alt="banner" />
                ) }    
            </div> */}
              <div className={"container containerPrograms"}>
                <div
                  className={"banner bannerPrograms row banner-exchanges"}
                  style={{ backgroundImage: `url(${cover_app})` }}
                >
                  {program && (
                    <div className="banner-exchanges-box">
                      <h4>MONEDAS</h4>
                      <h5>
                        <span>
                          <img src={CoinSvg} alt="coin" />
                        </span>
                        <p>{program?.coins}</p> 
                      </h5>
                    </div>
                  )}
                </div>
              </div>
              {company &&
          selectedPlace &&
          company.gamification_system &&
          productsPlace.entities !== null && (
            <div className={
              "container program-promotions program-promotions__discount"
            }>
              <div className="exchange-container">
                  <ProductsPlaceList
                    multisite={multisite}
                    sections={sectionsResults.map(
                      (cUid) => sectionsEntities[cUid]
                    )}
                    productsPlace={productsByPlaceResults.map(
                      (cUid) => productsByPlaceEntities[cUid]
                    )}
                    count={ProductCount}
                    company={company}
                    nextPageProducts={ProductNextPage}
                    listProductsByPlaceStatus={listProductsByPlaceStatus}
                    listNextProductsByPlaceStatus={ProductListNextStatus}
                    getMoreProductsByPlace={getMoreProductsByPlace}
                    placeUid={selectedPlace.uid}
                    selectedPlace={selectedPlace}
                    productsByPlaceCurrent={productsByPlaceCurrent}
                    productsByPlaceCurrentStatus={productsByPlaceCurrentStatus}
                    openPopUp={openPopUp}
                    activePopUp={activePopUp}
                    componentPopUp={componentPopUp}
                    getProductCurrent={getProductCurrent}
                    addProductCart={addProductCart}
                    addProductsBack={addProductsBack}
                    entitiesCart={entitiesCart}
                    authStatus={authStatus}
                    updateProductCart={updateProductCart}
                    currency={company.currency}
                    typeCart={typeCart}
                    history={history}
                    cleanCart={cleanCart}
                    newStyle={true}
                    countProduct={999}
                  />
              </div>
              <br />
            </div>
          )}
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}
