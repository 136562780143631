import React from 'react'
import '../style.scss';
const $ = require('jquery');

export default class SubscriptionPopUp extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      subscriptionCurrentUid: ""
    }
  }

  componentDidMount() {
    let {subscriptionUid} = this.props
    if(this.state.subscriptionCurrentUid!==subscriptionUid){
      this.setState({subscriptionCurrentUid: subscriptionUid})
      this.openModal()
    }
  }

  openModal(){
    $('#confirmModal').modal('show');
  }

  closeModal = (e) => {
    let {closePopup} = this.props
    e.preventDefault()

    $('#confirmModal').modal('hide');
    closePopup()
  }

  confirmCancel = (e) => {
    let {canceledSubscription} = this.props
    canceledSubscription(this.state.subscriptionCurrentUid)
    this.closeModal(e)
  }

  render() {
    return (
      <div className="modal fade" id="confirmModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel"
           aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <h5 className={"text-center"}>¿Desea cancelar suscripción? </h5>
              <h5 className={"text-center"}>
                Recuerde que seguirá recibiendo sus suscripciones hasta la última semana del pago realizado.
                <span>Osea si pagó un lunes  1 y cancela un Martes 2, seguirá recibiendo los productos hasta el domingo 7</span>
              </h5>
              <div className="submits" style={{marginTop: 30, fontSize: "20px"}}>
                <button
                  type="button"
                  className="btn btn-close btn-secondary"
                  onClick={(e)=> this.closeModal(e)}>
                  CERRAR
                </button>
                <button
                  type="submit"
                  className="btn btn-confirm btn-primary"
                  onClick={(e)=> {
                    this.confirmCancel(e)
                  }}>
                  CONFIRMAR
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
