import React, { Fragment } from "react";
import PropTypes from "prop-types";
import "./style.scss";
import ProductCart from "../../productsCart/productCart";
import ProductPopUp from "../../products/productsPlace/components/productPopUp";
import cartIcon from "../../../common/images/cart.svg";

export default class ItemsPaymentCart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      productUid: "",
    };
  }

  setProductUid = (uid) => {
    this.setState({
      productUid: uid,
    });
  };

  renderPopUp = () => {
    let {
      authStatus,
      company,
      productsByPlaceCurrent,
      productsByPlaceCurrentStatus,
      openPopUp,
      activePopUp,
      componentPopUp,
      getProductCurrent,
      addProductCart,
      selectedPlace,
      entitiesCart,
      addProductsBack,
      updateProductCart,
      currency,
      typeCart,
      cleanCart,
    } = this.props;

    if (this.state.productUid !== "") {
      let listOptionsCartProduct = [];
      if (entitiesCart && entitiesCart.products !== undefined) {
        Object.keys(entitiesCart.products).map((p) => {
          if (this.state.productUid === entitiesCart.products[p].id) {
            if (entitiesCart.products[p].length !== 0) {
              if (entitiesCart.products[p].option_groups) {
                listOptionsCartProduct = entitiesCart.products[p].option_groups;
              }
            }
          }
          return true;
        });
      }

      return (
        <ProductPopUp
          company={company}
          productsByPlaceCurrent={productsByPlaceCurrent}
          productsByPlaceCurrentStatus={productsByPlaceCurrentStatus}
          openPopUp={openPopUp}
          activePopUp={activePopUp}
          getProductCurrent={getProductCurrent}
          placeUid={selectedPlace && selectedPlace.uid}
          setProductUid={this.setProductUid}
          uid={this.state.productUid}
          componentPopUp={componentPopUp}
          addProductCart={addProductCart}
          addProductsBack={addProductsBack}
          selectedPlace={selectedPlace}
          entitiesCart={entitiesCart}
          authStatus={authStatus}
          updateProductCart={updateProductCart}
          currency={currency}
          typeCart={typeCart}
          listOptionsCartProduct={listOptionsCartProduct}
          cleanCart={cleanCart}
          redirect={false}
        />
      );
    }
  };

  render() {
    let {
      items,
      updateProductCart,
      addProductsBack,
      authStatus,
      entitiesCart,
      entitiesCartSubscription,
      selectedPlace,
      currency,
      typeCart,
      componentPopUp,
      openPopUp,
      cartPopup = false,
      validate = true,
    } = this.props;
    //console.log(this.props, "items");
    return (
      <Fragment>
        {cartPopup ? (
          <div className={""}>
            <div>
              <h5 className="font-weight-bold ml-4 mt-5">
                Mi pedido
              </h5>
            </div>
            <div
              className={
                items.length !== 0
                  ? "col-10 mx-auto content-items-cart-popup"
                  : "col-10 mx-auto pl-0 content-items-cart-popup"
              }
            >
              {selectedPlace ? (
                items.length !== 0 ? (
                  <div className={""}>
                    {items.map((item, index) => (
                      <ProductCart
                        {...this.props}
                        key={`promo${index}`}
                        authStatus={authStatus}
                        placeUid={selectedPlace.uid}
                        product={item}
                        loading={false}
                        updateProductCart={updateProductCart}
                        addProductsBack={addProductsBack}
                        selectedPlace={selectedPlace}
                        entitiesCart={entitiesCart}
                        entitiesCartSubscription={entitiesCartSubscription}
                        currency={currency}
                        typeCart={typeCart}
                        openPopUp={openPopUp}
                        setProductUid={this.setProductUid}
                        cartPopup={true}
                        validate={validate}
                      />
                    ))}
                  </div>
                ) : (
                  <p className={"col-12 mt-2 pl-0"}>
                    Tienes tu carrito de compras vacío
                  </p>
                )
              ) : (
                <p className={"col-12 mt-2 pl-0"}>
                  Usted no ha seleccionado ningun local
                </p>
              )}
            </div>

            {componentPopUp === "productPopUp" && this.renderPopUp()}
          </div>
        ) : (
          <div className={"card detail prepayment  col-10 col-lg-10 mx-auto mt-4"}>
            <div className={"title-bar-section"}>
              <img
                src={cartIcon}
                alt={"cart"}
                style={{ height: 25, width: 40, marginBottom: 4 }}
              />
              <h6 className={""}>
                Confirmación de pedido
              </h6>
            </div>
            <div
              className={
                items.length !== 0
                  ? ""
                  : "pl-0"
              }
            >
              {selectedPlace ? (
                items.length !== 0 ? (
                  <div className={""}>
                    {items.map((item, index) => (
                      <ProductCart
                        {...this.props}
                        key={`promo${index}`}
                        authStatus={authStatus}
                        placeUid={selectedPlace.uid}
                        product={item}
                        loading={false}
                        updateProductCart={updateProductCart}
                        addProductsBack={addProductsBack}
                        selectedPlace={selectedPlace}
                        entitiesCart={entitiesCart}
                        entitiesCartSubscription={entitiesCartSubscription}
                        currency={currency}
                        typeCart={typeCart}
                        openPopUp={openPopUp}
                        setProductUid={this.setProductUid}
                      />
                    ))}
                  </div>
                ) : (
                  <p className={"col-12 mt-2 pl-0"}>
                    Tienes tu carrito de compras vacío
                  </p>
                )
              ) : (
                <p className={"col-12 mt-2 pl-0"}>
                  Usted no ha seleccionado ningun local
                </p>
              )}
            </div>

            {componentPopUp === "productPopUp" && this.renderPopUp()}
          </div>
        )}
      </Fragment>
    );
  }
}

ItemsPaymentCart.propTypes = {
  listCartStatus: PropTypes.string,
  entitiesCart: PropTypes.object,
};
