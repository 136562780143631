import HttpClient from '../common/utils/httpClient';

export const LOGIN = 'LOGIN';
export const LOGIN_GOOGLE = 'LOGIN_GOOGLE';
export const VALIDATE = 'VALIDATE';
export const CLEAN_VALIDATE_USER = 'CLEAN_VALIDATE_USER';
export const REGISTER = 'REGISTER';
export const USER_BAND = 'USER_BAND';
export const RESET = 'RESET';
export const RECOVERY_PASSWORD = 'RECOVERY_PASSWORD';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const GET_USER = 'GET_USER';
export const GET_QR_MEMBER = 'GET_QR_MEMBER';
export const SEND_DELETE_USER = 'SEND_DELETE_USER';
export const CLEAN_DELETE_SEND_USER = 'CLEAN_DELETE_SEND_USER';
export const USER_PATCH = 'USER_PATCH';
export const SKIP_UPDATE = 'SKIP_UPDATE';
export const VALIDATE_EMAIL = "VALIDATE_EMAIL";
export const DELETE_USER = "DELETE_USER";
export const CLEAN_DELETE_STATUS_USER = "CLEAN_DELETE_STATUS_USER";
export const VALIDATE_LINK_EXPIRATION = "VALIDATE_LINK_EXPIRATION";
export const GET_PROMOTIONS_DELETE = "GET_PROMOTIONS_DELETE";

export const CLOSE_SESSION = 'CLOSE_SESSION';

export const URL_LOGIN = 'v5/login/';
export const URL_LOGIN_GOOGLE = 'v5/login/mobile/google/';
export const URL_VALIDATE = 'v5/validate/';
export const URL_REGISTER = 'v5/register/';
export const URL_RECOVERY_PASSWORD = 'v5/me/reset_password/';
export const URL_USER_PROFILE = 'v5/me/';
export const URL_VALIDATE_EMAIL = 'v5/verification-email/';
export const URL_VALIDATE_DELETE = 'v5/me/delete/';
export const URL_VALIDATE_DELETE_REPSOL = 'v5/repsol/me/delete/';
export const URL_USER_DELETE = 'v5/delete-account/';
export const URL_GET_PROMOTIONS_DELETE = 'v5/me/delete/{uid}/{token}/';
export const URL_GET_PROMOTIONS_DELETE_REPSOL = 'v5/repsol/me/delete/{uidApp}/{uid}/{token}/';
export const URL_QR_MEMBER = 'v5/me/programs/{uid}/qr/';

export function validateUser(data) {
  return (dispatch) => HttpClient.post(data, dispatch, VALIDATE, URL_VALIDATE);
}

export function loginUser(data) {
  return (dispatch) => HttpClient.post(data, dispatch, LOGIN, URL_LOGIN);
}

export function loginUserGoogle(data) {
  return (dispatch) => HttpClient.post(data, dispatch, LOGIN_GOOGLE, URL_LOGIN_GOOGLE);
}

export function registerUser(data) {
  return (dispatch) => HttpClient.post(data, dispatch, REGISTER, URL_REGISTER);
}

export function recoveryPassword(data) {
  return (dispatch) => HttpClient.post(data, dispatch, RECOVERY_PASSWORD, URL_RECOVERY_PASSWORD);
}

export function resetPassword(data, uid, token) {
  return (dispatch) => HttpClient.post(data, dispatch, RESET_PASSWORD, `${URL_RECOVERY_PASSWORD}${uid}/${token}/`);
}

export function getProfile() {
  return (dispatch) => HttpClient.get( dispatch, GET_USER, URL_USER_PROFILE);
}
export function getQRMember(uid) {
  return (dispatch) => HttpClient.post( {}, dispatch, GET_QR_MEMBER, URL_QR_MEMBER.replace("{uid}", uid));
}

export function sendDeleteEmail() {
  return (dispatch) => HttpClient.post( {}, dispatch, SEND_DELETE_USER, URL_USER_DELETE);
}

export function updateUser(data) {
  return (dispatch) => HttpClient.patch(data, dispatch, USER_PATCH, URL_USER_PROFILE);
}

export function validateEmail( appUid, uid, token) {
  return (dispatch) => HttpClient.get( dispatch, VALIDATE_EMAIL, `${URL_VALIDATE_EMAIL}${appUid}/${uid}/${token}/`);
}
export function validateLinkExpiration( uid, token) {
  return (dispatch) => HttpClient.get( dispatch, VALIDATE_LINK_EXPIRATION, `${URL_VALIDATE_DELETE}${uid}/${token}/`);
}
export function validateLinkExpirationRepsol( uidApp, uid, token) {
  return (dispatch) => HttpClient.get( dispatch, VALIDATE_LINK_EXPIRATION, `${URL_VALIDATE_DELETE_REPSOL}${uidApp}/${uid}/${token}/`);
}

export function getPromotionsDelete( uid, token) {
  return (dispatch) => HttpClient.get( dispatch, GET_PROMOTIONS_DELETE,
    `${URL_GET_PROMOTIONS_DELETE.replace("{uid}", uid).replace("{token}", token)}list-members-programs/`
  );
}

export function getNextPromotionsDelete(nextPageUri) {
  return (dispatch) => HttpClient.get(dispatch, GET_PROMOTIONS_DELETE, nextPageUri, false);
}


export function getNumberPagePromotionsDelete(uid, pageUid, token) {
  let url = `${URL_GET_PROMOTIONS_DELETE.replace("{uid}", uid).replace("{token}", token)}list-members-programs/`;
  let type = GET_PROMOTIONS_DELETE;
  url = `${url}&page=${pageUid}`;
  return (dispatch) => HttpClient.get(dispatch, type, url, true);
}

export function getPagePromotionsDelete(pageUri) {
  return (dispatch) => HttpClient.get(dispatch, GET_PROMOTIONS_DELETE, pageUri, false);
}

export function deleteUser( uid, token) {
  return (dispatch) => HttpClient.delete(dispatch, DELETE_USER, URL_GET_PROMOTIONS_DELETE.replace("{uid}", uid).replace("{token}", token), uid);
}
export function deleteUserRepsol( uidApp, uid, token) {
  return (dispatch) => HttpClient.delete(dispatch, DELETE_USER, URL_GET_PROMOTIONS_DELETE_REPSOL.replace("{uidApp}", uidApp).replace("{uid}", uid).replace("{token}", token), uid);
}

export function cleanValidateUser() {
  return {
    type: CLEAN_VALIDATE_USER,
  };
}
export function cleanStatusDeleteUser() {
  return {
    type: CLEAN_DELETE_STATUS_USER,
  };
}
export function cleanDeleteStatus() {
  return {
    type: CLEAN_DELETE_SEND_USER,
  };
}
export function skipUpdate() {
  return {
    type: SKIP_UPDATE,
  };
}

export function setUserBand(user) {
  return {
    type: USER_BAND,
    user
  };
}

export function reset() {
  return {
    type: RESET
  };
}

export function closeSession() {
  return {
    type: CLOSE_SESSION,
  };
}