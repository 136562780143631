import React, {Fragment} from 'react'
import PropTypes from 'prop-types';
import './style.scss';
import {BTN_PAYMENT, BTN_PAYMENT_LOADING, FROM_PRODUCTS, FROM_PROMOTION, QULQI} from "./constants";
import {STATUS_ERROR, STATUS_LOADING, STATUS_SUCCESS} from "../../common/utils/generic";
import Validator from "../../common/utils/validator";
import Icon from "../../common/icons/icon";
import {Culqi, CulqiProvider} from "../../common/components/Culqi";
import {toast} from "react-toastify";


export default class PaymentContainer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      promotionUid: '',
      first_name: '',
      phone_number: '',
      email: '',
      last_name: '',
      address: '',
      address_city: '',
      show_message: true,
      userUid: ''
    }
  }

  componentDidMount() {
    const {
      cleanPayment, changeSecondaryMenu, changeStateCart
    } = this.props
    cleanPayment()
    changeSecondaryMenu()
    changeStateCart()
    this.updateComponent()

  }

  renderError(errors, fieldName) {
    return errors && errors[fieldName] &&
      <span className='field-error-message'>
        {errors[fieldName][0]}
      </span>;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.updateComponent()
  }


  updateComponent() {
    const {
      match: {params: {uid, promotionUid}},
      getPromotion,
      from,
      promotions: {
        promotionCurrent,
        paymentStatus,
        paymentErrors
      },
      user
    } = this.props
    if(user && this.state.userUid!==user.uid){
      this.setState({
        promotionUid: promotionUid,
        userUid: user.uid,
        first_name: user.first_name || '',
        phone_number: user.mobile_number || '',
        email: user.email || '',
        last_name: user.last_name || '',
        address: '',
        address_city: user.area? user.area.name|| '' : ''
      })
    }

    if (from === FROM_PROMOTION) {
      if (paymentStatus === STATUS_SUCCESS && !this.state.show_message) {
        toast.success("La compra fue realizada con éxito")
        this.setState({show_message: true})
      } else {

        if (promotionCurrent && promotionCurrent.uid === promotionUid) {
          if (this.state.promotionUid !== promotionUid) {
            this.setState({promotionUid: promotionUid})
          }
        } else {
          if (this.state.promotionUid !== promotionUid) {
            this.setState({promotionUid: promotionUid})
            if (uid && uid !== '' && promotionUid && promotionUid !== '') {
              getPromotion(uid, promotionUid);
            }
          }
        }
        if (promotionCurrent) {

          this.validator = new Validator([
            {
              id: "first_name",
              ref: this.firstNameInput,
              rules: ["required"]
            },
            {
              id: "last_name",
              ref: this.lastNameInput,
              rules: ["required"]
            },
            {
              id: "phone_number",
              ref: this.phoneNumberInput,
              rules: ["required", "telephone"]
            },
            {
              id: "address_city",
              ref: this.addressCityInput,
              rules: ["required"]
            },
            {
              id: "address",
              ref: this.addressInput,
              rules: ["required"]
            },
            {
              id: "email",
              ref: this.emailInput,
              rules: ["required", "email"]
            }
          ])
        }
        if (paymentStatus === STATUS_ERROR && !this.state.show_message) {
          if (paymentErrors && paymentErrors.user_message) {
            toast.error(paymentErrors.user_message)
          }
          if (paymentErrors && paymentErrors.non_field_errors) {
            toast.error(paymentErrors.non_field_errors[0])
          }
          this.setState({show_message: true})
        }
      }

    }
    if (from === FROM_PRODUCTS) {


    }
  }

  setFormValues = (propertiesToUpdate) => {
    this.setState(propertiesToUpdate);
    this.validator.validate(propertiesToUpdate);
  }

  errorPayment = () => {
    toast.error("Tu tarjeta no ha podido ser procesada correctamente")
  };

  validate = () => {
    return this.validator.validate({...this.state})
  }
  payPromotion = (token) => {
    const {
      match: {params: {uid, promotionUid}},
      paymentPromotion,
      cleanPayment,
      promotions: {
        paymentStatus
      }
    } = this.props

    if (token.client && token.client.ip_country_code) {
      let phone = this.state.phone_number
      if (phone.length === 9) {
        if (phone[0] !== '+') {
          phone = `+51${phone}`
        }
      }
      let data = {
        token: token.id,
        email: token.email,
        antifraud_details: {
          address: this.state.address,
          address_city: this.state.address_city,
          country_code: token.client.ip_country_code,
          first_name: this.state.first_name,
          last_name: this.state.last_name,
          phone_number: phone
        }
      };
      if (paymentStatus !== STATUS_LOADING) {
        cleanPayment()
        this.setState({show_message: false})
        paymentPromotion(data, uid, promotionUid);
      }
    } else {
      toast.error("Algo sucedió mal, paece que tu tarjeta no ha podido ser procesada correctamente")
    }

  };

  onBack=(e)=>{
    const {history} = this.props;
    e.preventDefault()
    if(history)
      history.goBack();
  }

  render() {
    let {
      company: {logo_rectangle, logo, currency, payment_gateway_settings},
      from,
      promotions: {
        promotionCurrent,
        promotionStatus,
        paymentStatus,
        paymentSuccessBody,
        paymentErrors
      }
    } = this.props;
    let publicKey = payment_gateway_settings ? ( payment_gateway_settings.payment_gateway === QULQI ? (payment_gateway_settings.public_key || ""):"" ):""
    let error_antifraud = paymentErrors ? paymentErrors.antifraud_details : null
    let logo_app = logo ? logo : logo_rectangle
    let that = this;


    return (
      <Fragment>
        {paymentStatus ===STATUS_SUCCESS ?
          <div className={"container order"}>
            <div className={"success_payment col-12"}>
              <p className={"main-message"}>Felicidades, su compra fue exitosa</p>
              {
                from === FROM_PROMOTION && <Fragment>
                  {(paymentSuccessBody && paymentSuccessBody.remaining_amount) &&
                  <p>Usted tiene un saldo de {currency ? currency.symbol : "S/."}  {paymentSuccessBody.remaining_amount} en esta promoción</p>
                  }
                  <div className={"mx-auto col-lg-4"}>
                    <button onClick={e=>{this.onBack(e)}} className={"btn btn-secondary"}>Regresar</button>
                  </div>
                </Fragment>
              }
            </div>
          </div>
          :
          <Fragment>
            {
              from === FROM_PROMOTION &&
              <Fragment>
                {
                  promotionStatus === STATUS_LOADING ?
                    <div className={"container order "}>
                      <div className={"row"}>
                        <div className={"data col-12 "}>
                          <h5>Pedido</h5>
                          <div className={"payment_user_info loading"}>
                            <p/>
                            <p/>
                            <p/>
                            <p/>
                            <p/>
                          </div>
                        </div>
                      </div>
                      <div className={"detail col-12 loading"}>
                        <p/>
                        <p/>
                        <p/>
                        <p/>
                      </div>
                    </div>
                    :
                    <div className={"container order"}>
                      {
                        promotionCurrent ?
                          <div className={"row"}>
                            <div className={"data col-12 col-lg-8"}>
                              <div className={"title"}>
                                <h5>Pedido</h5>
                              </div>
                              <div className={"payment_user_info card"}>
                                <h6>
                                  Datos de facturación
                                </h6>
                                <div className={"form row"}>
                                  <div className="form-group col-lg-6">
                                    <div
                                      className="input-group"
                                      ref={(input) => {
                                        if (input) {
                                          this.firstNameInput = input.querySelector('input');
                                        }
                                      }}
                                    >
                                      <input
                                        name={"first_name"}
                                        disabled={paymentStatus === STATUS_LOADING}
                                        className={"form-control"}
                                        value={this.state.first_name}
                                        onChange={e => this.setFormValues({first_name: e.target.value})}
                                        type="text"
                                        placeholder="Nombres *"
                                      />

                                    </div>
                                    {this.renderError(error_antifraud, 'first_name')}
                                  </div>
                                  <div className="form-group col-lg-6">
                                    <div
                                      className="input-group"
                                      ref={(input) => {
                                        if (input) {
                                          this.lastNameInput = input.querySelector('input');
                                        }
                                      }}
                                    >
                                      <input
                                        name={"last_name"}
                                        disabled={paymentStatus === STATUS_LOADING}
                                        className={"form-control"}
                                        value={this.state.last_name}
                                        onChange={e => this.setFormValues({last_name: e.target.value})}
                                        type="text"
                                        placeholder="Apellidos *"
                                      />

                                    </div>
                                    {this.renderError(error_antifraud, 'last_name')}
                                  </div>
                                  <div className="form-group col-lg-6">
                                    <div
                                      className="input-group"
                                      ref={(input) => {
                                        if (input) {
                                          this.emailInput = input.querySelector('input');
                                        }
                                      }}
                                    >
                                      <input
                                        className={"form-control"}
                                        name={"email"}
                                        disabled={paymentStatus === STATUS_LOADING}
                                        value={this.state.email}
                                        onChange={e => this.setFormValues({email: e.target.value})}
                                        type="text"
                                        placeholder="Correo Electronico *"
                                      />
                                    </div>
                                    {this.renderError(error_antifraud, 'email')}
                                  </div>
                                  <div className="form-group col-lg-6">
                                    <div
                                      className="input-group"
                                      ref={(input) => {
                                        if (input) {
                                          this.phoneNumberInput = input.querySelector('input');
                                        }
                                      }}
                                    >
                                      <input
                                        className={"form-control"}
                                        name={"phone"}
                                        disabled={paymentStatus === STATUS_LOADING}
                                        value={this.state.phone_number}
                                        onChange={e => this.setFormValues({phone_number: e.target.value})}
                                        type="text"
                                        placeholder="Teléfono *"
                                      />
                                    </div>
                                    {this.renderError(error_antifraud, 'phone_number')}
                                  </div>
                                  <div className="form-group col-lg-6">
                                    <div
                                      className="input-group"
                                      ref={(input) => {
                                        if (input) {
                                          this.addressCityInput = input.querySelector('input');
                                        }
                                      }}
                                    >
                                      <input
                                        className={"form-control"}
                                        name={"city"}
                                        disabled={paymentStatus === STATUS_LOADING}
                                        value={this.state.address_city}
                                        onChange={e => this.setFormValues({address_city: e.target.value})}
                                        type="text"
                                        placeholder="Ciudad *"
                                      />
                                    </div>
                                    {this.renderError(error_antifraud, 'address_city')}
                                  </div>
                                  <div className="form-group col-lg-12">
                                    <div
                                      className="input-group"
                                      ref={(input) => {
                                        if (input) {
                                          this.addressInput = input.querySelector('input');
                                        }
                                      }}
                                    >
                                      <input
                                        className={"form-control"}
                                        name={"address"}
                                        disabled={paymentStatus === STATUS_LOADING}
                                        value={this.state.address}
                                        onChange={e => this.setFormValues({address: e.target.value})}
                                        type="text"
                                        placeholder="Dirección *"
                                      />
                                    </div>
                                    {this.renderError(error_antifraud, 'address')}
                                  </div>

                                </div>
                                <p>
                                  <span>(*) Campos obligatorios</span>
                                </p>
                              </div>

                              <div className={"card payment-methods"}>
                                <div className="card-header" id="payment-methods-header">
                                  <h2 className="mb-0">
                                    <button className="btn text-left"
                                            type="button"
                                            data-toggle="collapse"
                                            data-target="#paymentMethods"
                                            aria-expanded="true"
                                            aria-controls="paymentMethods"
                                    >
                                      Métodos de pago
                                      <Icon svg="down" classes="svg-icon down " title="Down"/>
                                    </button>
                                  </h2>
                                </div>
                                <div id="paymentMethods" className="collapse" aria-labelledby="paymentMethods">
                                  <div className="card-body">
                                    <div className="form-check">
                                      <input className="form-check-input" type="radio" name="paymentCard"
                                             id="paymentCard"
                                             value="payment_card" defaultChecked={true}/>
                                      <label className="form-check-label" htmlFor="paymentCard">
                                        Tarjeta de crédito / Tarjeta de débito
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className={"card items"}>
                                <div className="card-header" id="items-header">
                                  <h2 className="mb-0">
                                    <button className="btn  text-left"
                                            type="button"
                                            data-toggle="collapse"
                                            data-target="#collapseItems"
                                            aria-expanded="true"
                                            aria-controls="collapseItems"
                                    >
                                      Items
                                      <Icon svg="down" classes="svg-icon down" title="Down"/>
                                    </button>
                                  </h2>
                                </div>
                                <div id="collapseItems" className="collapse" aria-labelledby="collapseItems">
                                  <div className="card-body">
                                    {
                                      promotionCurrent.allow_payment ?
                                        <div className={"item"}>
                                          <p> {promotionCurrent.title}</p>
                                        </div>
                                        : <p>Esta promoción no está disponible para ser comprada</p>

                                    }
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className={" col-12 col-lg-4"}>
                              {
                                promotionCurrent.allow_payment ?
                                  <Fragment>
                                    <div className={"card detail"}>
                                      <h6>Resumen</h6>
                                      <div className={"row"}>
                                        <div className={"col"}>
                                          Subtotal
                                        </div>
                                        <div className={"mr-auto price"}>
                                          {currency ? currency.symbol : "S/."}   {promotionCurrent.original_price}
                                        </div>
                                      </div>
                                      <div className={"total row"}>
                                        <div className={"col"}>
                                          Total
                                        </div>
                                        <div className={"mr-auto price"}>
                                          {currency ? currency.symbol : "S/."}   {promotionCurrent.original_price}
                                        </div>
                                      </div>
                                      <div className={"row"}>
                                        <div className={"col-12"}>
                                          {publicKey ? <CulqiProvider
                                            publicKey={publicKey}
                                            amount={parseFloat(promotionCurrent.original_price) * 100}
                                            title={promotionCurrent.title}
                                            description="Necesitamos los datos de su tarjeta para hacer efectiva la compra"
                                            onToken={token => {
                                              that.payPromotion(token)
                                            }}
                                            onError={error => {
                                              that.errorPayment()
                                            }}
                                            options={{
                                              style: {
                                                maincolor: `#29b1cb`,
                                                buttontext: "#fff",
                                                maintext: "#000000",
                                                desctext: "#000000",
                                                logo: `${logo_app}`
                                              }
                                            }}
                                          >

                                            <Culqi>
                                              {({openCulqi, amount}) => {
                                                return (

                                                  <button disabled={paymentStatus === STATUS_LOADING}
                                                          className={"btn btn-primary"} onClick={e => {
                                                    if (that.validate() && paymentStatus !== STATUS_LOADING) {
                                                      openCulqi()
                                                    }
                                                  }}>{paymentStatus !== STATUS_LOADING ? BTN_PAYMENT : BTN_PAYMENT_LOADING}</button>

                                                );
                                              }}
                                            </Culqi>

                                          </CulqiProvider>
                                            :
                                            <p>El sitio aún no ha configurado pagos con tarjeta de crédito / tarjeta de débito</p>
                                          }

                                        </div>
                                      </div>

                                    </div>

                                  </Fragment>
                                  :
                                  <Fragment>
                                    <p>No disponible para compra</p>
                                  </Fragment>
                              }
                            </div>
                          </div>
                          :
                          <p>Información no encontrada</p>
                      }
                    </div>
                }
              </Fragment>
            }
          </Fragment>}
      </Fragment>
    )
  }
}

PaymentContainer.defaultProps = {
  app: null,
};

PaymentContainer.propTypes = {
  app: PropTypes.object,
  from: PropTypes.string,
  getPromotion: PropTypes.func
};


