import React from 'react'
import "./style.scss"
import moment from "moment";

export default class ItemRedemptions extends React.Component {

  getDate = (date) => {
    let YY = moment(date).format("YYYY")
    let MM = moment(date).format("MMMM")
    let DD = moment(date).format("DD")
    return (DD+" de "+ MM.charAt(0).toUpperCase() + MM.slice(1) + " " + YY)
  }

  render() {
    let {redemption, loading, currency } = this.props
    if(!loading){

      return (
        <div className={"item-redemption col-md-12"}>
          <div className="redemption-info">
              <div className="header-redemption">
                {
                  redemption.program_promotion &&
                  <h6>{redemption.program_promotion.program_name}</h6>
                }
                <span>
                  {this.getDate(redemption.created_at)}
                </span>
              </div>
              <h5>{redemption.program_promotion && redemption.program_promotion.promotion_title}</h5>
              <p>Local: { redemption.place && <span>{redemption.place.address}</span>}</p>
              {redemption.amount ? <p className={"amount"}>{currency ? currency.symbol : "S/."} {redemption.amount}</p> : <p></p>}
          </div>

        </div>
      );
    }else{
      return (
        <div className={"item-redemption col-md-12 load"}>
          <div className="redemption-info">
            <div className="header-redemption">
              <h6> </h6>
              <span> </span>
            </div>
            <h2> </h2>
            <p> </p>
            <p className={"amount"}> </p>
          </div>
        </div>
      )
    }

  }
}
