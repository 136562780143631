import React, {Fragment} from 'react';
import {Redirect, Switch} from "react-router-dom";
import {Route} from 'react-router';
import NotFound from "./NotFound";
import {ToastContainer} from 'react-toastify';
import MainMenu from "../menu/MainMenu";
import {getPlaces, getSchedule, selectPlace} from "../../actions/places";
import {
  changeStateSecondaryMenu,
  openSidebarMenu,
  openCartMenu,
  SECONDARY_MENU_BACK, SECONDARY_MENU_BUSINESS
} from "../../actions/menu";
import SidebarMenu from "../menu/SidebarMenu";
import {
  closeSession,
  getProfile,
  skipUpdate,
  updateUser,
  sendDeleteEmail,
  cleanDeleteStatus,
  getQRMember
} from "../../actions/user";
import {STATUS_SUCCESS} from "../../common/utils/generic";
import SecondaryMenu from "../menu/SecondaryMenu";
import {getPrograms} from "../../actions/programs";
import ProgramRoot from "./ProgramRoot";
import UpdateProfile from "../updateProfile";
import QRProfile from "../QRProfile";
import ChangePassword from "../changePassword";
import ProductRoot from "./ProductRoot";
import ProductsCart from "../productsCart";
import {openPopUp} from "../../actions/popup";
import {
  addProductCart,
  addProductCartSubscription,
  addProductsBack,
  CART_INVISIBLE, CART_SUBSCRIPTION,
  CART_VISIBLE,
  changeStateCart,
  cleanCart,
  cleanCode,
  cleanPaymentCart, cleanPaymentCartSubscription,
  getProductCart,
  getProductCartSubscription,
  getProductsBack,
  orderCart, orderSubscription, setTypeCart,
  updateProductCart,
  updateProductCartSubscription,
  validateCode
} from "../../actions/cart";
import {getProductCurrent, getProductsByPlace} from "../../actions/productsByPlace";
import {getAreas, selectArea} from "../../actions/areas";
import Extras from "../../common/utils/extras";
import PrePaymentCartContainer from "../prePaymentCart/PrePaymentCartContainer";
import CompaniesContainer from "../companies";
import DetailCompany from "../companies/companyDetail/DetailCompany";
import {changeCompany, mainCompany} from "../../actions/companies";
import SaveCoupon from "../saveCoupon";
import {setCoupon} from "../../actions/coupon";
import SubscriptionRoot from "./SubscriptionRoot";
import TermsAndConditions from "../others/termsAndConditions";
import Politics from "../others/politics";
import {
  cleanAdsCompany,
  getAdsCompany,
  getApp,
  getAppSections,
  getAppSectionsSearch,
  getCompaniesSections,
  getCompaniesSectionsSearch
} from "../../actions/app";
import Footer from "../footer/Footer";
import RafflesRoot from "../raffles/RafflesRoot";
import InfluencerRoot from "./InfluencerRoot";
import CompaniesCategoriesContainer from "../companies/CompaniesCategoriesContainer";
import {getPromotions} from "../../actions/promotions";
import {getFare} from "../../actions/delivery";
import OrdersRoot from "../orders/OrdersRoot";
import {getRaffles} from "../../actions/raffles";
import DeleteAccount from "../deleteAccount";
import SidebarCart from '../menu/SidebarCart';
import PollsRoot from "../polls/PollsRoot";
import EventsRoot from "../events/EventsRoot";
import * as PropTypes from "prop-types";
import FAQS from "../others/faqs";

EventsRoot.propTypes = {openPopUp: PropTypes.func};
export default class MainRoot extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedPlaceUid: "",
      isCleanCart: false,
      infoSubscriptionOptions: {},
      isEcommerceEnabled: null,
      uidInfluencer: "",
      delivery_service: null,

      show_cart: true,
      show_store: true,
      show_program: true,
      loadShows: false,
    }
  }

  getCart() {
    const {auth: {authStatus}} = this.props;

    if (localStorage.getItem("selectedPlaceUid") !== "null" && localStorage.getItem("selectedPlaceUid") !== null) {
      this.props.dispatch(getProductCart(localStorage.getItem("selectedPlaceUid")))
      if (authStatus === STATUS_SUCCESS) {
        this.props.dispatch(getProductsBack(localStorage.getItem("selectedPlaceUid")))
      }
    }
  }

  componentDidMount() {
    const {auth: {authStatus}} = this.props;
    if(!localStorage.getItem("version") && (!localStorage.getItem("token") || localStorage.getItem("token").length===0 || localStorage.getItem("token")==="null")){
      this.props.dispatch(closeSession());
      this.props.history.push('/');
    }
    if (authStatus === STATUS_SUCCESS) {
      this.props.dispatch(getProfile())
      this.setState({isEcommerceEnabled: null})
      this.updateComponent()
    }
    if (localStorage.getItem("selectedPlaceUid")) {
      let placeUid = localStorage.getItem("selectedPlaceUid")
      if (localStorage.getItem(`infoSubscriptionOptions${placeUid}`) &&
        JSON.parse(localStorage.getItem(`infoSubscriptionOptions${placeUid}`)) !== this.state.infoSubscriptionOptions) {
        this.setState({infoSubscriptionOptions: JSON.parse(localStorage.getItem(`infoSubscriptionOptions${placeUid}`))})
      }
    }

    if(localStorage.getItem("toFooterRedirect") && localStorage.getItem("toFooterRedirect").length!==0){
      let aux = ""
      aux = `/${localStorage.getItem("toFooterRedirect")}`
      this.props.history.push(aux)
      localStorage.removeItem("toFooterRedirect")
    }
  }

  //TODO simplify
  isSecondaryMenuProgram = (pathname) => {
    if (pathname.split('/')[0] === "" && pathname.split('/')[1] === "")
      return true;
    return pathname.split('/').length < 5 && pathname.split('/')[0] === "" && (pathname.split('/')[1] === "program" || pathname.split('/')[1] === "products") && pathname.split('/')[2] !== "";
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {history, user: {user}, app: {app, company}} = this.props;
    if (user) {
      if (!Extras.isDataComplete(user) && localStorage.getItem("skipUpdate") !== 'skip') {
        history.push('/complete-info')
      } else {
        if (company) {
          if (localStorage.getItem("redirectLoginToCartSubscription")) {
            this.props.dispatch(changeCompany(localStorage.getItem("redirectLoginToCartSubscription")))
            localStorage.removeItem("redirectLoginToCartSubscription")
            this.props.dispatch(setTypeCart(CART_SUBSCRIPTION))
            if (app.allowed_companies && app.allowed_companies.length > 0) {
              this.props.history.push(`/c/${company.uid}/cart`)
            } else {
              this.props.history.push("/cart")
            }
          }
        }
      }
    }
    if(!this.state.loadShows){
      company.hidden_for_members.map(s=>{
        if(s.name==="cart"){
          this.setState({show_cart: false})
        }
        if(s.name==="store"){
          this.setState({show_store: false})
        }
        if(s.name==="program"){
          this.setState({show_program: false})
        }
        return true
      })
      this.setState({loadShows: true})
    }
    this.updateComponent()
  }

  updateComponent() {
    const {app: {app, company}, places: {selectedPlaceUid, listStatus, selectedPlace}, cart: {entitiesCart}} = this.props;
    if(listStatus === "success"){
      if (this.state.selectedPlaceUid !== localStorage.getItem("selectedPlaceUid")) {
        this.setState({
          selectedPlaceUid: localStorage.getItem("selectedPlaceUid")
        })

        this.props.dispatch(getProductCartSubscription(localStorage.getItem("selectedPlaceUid")))
        if (app.allowed_companies && app.allowed_companies.length > 0) {
          if (selectedPlaceUid === localStorage.getItem("selectedPlaceUid")) {
            this.getCart()
            this.setState({isCleanCart: false})
          }
        } else {
          this.getCart()
          this.setState({isCleanCart: false})
        }
      } else {
        if (selectedPlaceUid === this.state.selectedPlaceUid && entitiesCart === null) {
          this.getCart()
        }
        if (!this.state.isCleanCart) {
          if (selectedPlaceUid === null) {
            this.props.dispatch(cleanCart())
            this.setState({isCleanCart: true})
          }
        }
      }
    }

    if (company.ecommerce_enabled !== this.state.isEcommerceEnabled) {
      this.setState({isEcommerceEnabled: company.ecommerce_enabled})
      if (company.ecommerce_enabled) {
        this.props.dispatch(changeStateCart(CART_VISIBLE))
      } else {
        this.props.dispatch(changeStateCart(CART_INVISIBLE))
      }
    }


    if(company && selectedPlace){
      if(app && app.company.delivery_service && selectedPlace.uses_country_delivery_service === true && this.state.delivery_service!==app.company.delivery_service){
        this.setState({delivery_service: app.company.delivery_service})
      }
    }
    if(!this.state.listRaffles && app && app.allowed_companies && app.allowed_companies.length > 0){
      this.props.dispatch(getRaffles(app.allowed_companies[0].uid))
      this.setState({listRaffles: true})
    }
  }

  setInfoSubscriptionOptions(infoSubscriptionOptions) {
    let placeUid = localStorage.getItem("selectedPlaceUid")
    this.setState({infoSubscriptionOptions: infoSubscriptionOptions})
    localStorage.setItem(`infoSubscriptionOptions${placeUid}`, JSON.stringify(infoSubscriptionOptions))
  }

  render() {
    const {
      path,
      dispatch,
      history,
      auth: {authStatus},
      user: {
        user,
        profileStatus,
        updateStatus,
        errorUpdate,
        sendDeleteStatus,
        qrStatus,
        errorQR,
        qrMember
      },
      app: {
        app, company, appFields,
        company:{logo_rectangle, logo},
        resultsSections: sectionsResults,
        entitiesSections: sectionsEntities,
        resultsCompaniesSections: companiesSectionsResults,
        entitiesCompaniesSections: companiesSectionsEntities,
        statusCompaniesSections,
        statusAppSections,
        resultsAdsCompany: adsCompanyResults,
        entitiesAdsCompany: adsCompanyEntities,
        statusAdsCompany,
        statusAppFields
      },
      places: {
        results: placesResults,
        listStatus: listPlacesStatus,
        entities: placesEntities,
        selectedPlace
      },
      programs: {
        results: programsResults,
        listStatus: listProgramStatus,
        entities: programEntities,
        entity: programEntity
      },
      productsPlace: {
        productsByPlaceCurrent,
        productsByPlaceCurrentStatus
      },
      cart: {
        entitiesCart,
        entitiesCartSubscription,
        listCartStatus,
        listCartSubscriptionStatus,
        statusCartBack,
        orderSuccessBody,
        orderStatus,
        orderErrors,
        orderSubscriptionSuccessBody,
        orderSubscriptionStatus,
        orderSubscriptionErrors,
        codeStatus,
        codeSuccessBody,
        codeErrors,
        stateCart,
        typeCart
      },
      menus: {
        stateSecondaryMenu,
        activeSidebarMenu,
        activeCartMenu
      },
      areas: {
        results: areasResults,
        listStatus: listAreasStatus,
        entities: areasEntities,
        selectedArea,
        selectedAreaUid
      },
      popup: {
        activePopUp,
        componentPopUp
      },
      subscriptions,
      programs,
      promotions,
      sections,
      placesPromotion,
      relatedPromotion,
      productsPlace,
      pickupSchedule,
      sectionsProducts,
      redemptions,
      coupon,
      ads,
      raffles,
      delivery,
      orders,
      events,
      polls
    } = this.props;

    let {
      show_cart,
      show_store,
      show_program
    } = this.state
    let logo_app = logo ? logo  : logo_rectangle
    //console.log(this.state.loadShows, show_cart, show_store, show_program, "HOLAAA")
    
    return <Fragment>
      {
        this.state.loadShows && !show_cart && !show_store && !show_program ?
          <div className={"d-flex justify-content-center align-items-center col-12"} style={{minHeight: "100vh"}}>
            <img alt=""
                 src={logo_app}
                 width={"250"}
                 height={"250"}
                 id={"logo-banner"}
                 className="d-sm-block d-md-block d-lg-block mr-3"/>
          </div>
          :
          <Fragment>

            <Fragment>
              {
                this.state.loadShows &&
                <Route key={"menu"} path={`/`} render={(props) =>
                  <MainMenu
                    {...props}
                    history={history}
                    app={app}
                    company={company}
                    loginStatus={authStatus}
                    user={user}
                    multisite={app.allowed_companies && app.allowed_companies.length > 0}
                    profileStatus={profileStatus}
                    selectedPlace={selectedPlace}
                    listPlacesStatus={listPlacesStatus}
                    places={placesResults.map(pUid => placesEntities[pUid])}
                    selectedArea={selectedArea}
                    getPlaces={() => {
                      if (company)
                        dispatch(getPlaces({company: company.uid, area: selectedAreaUid || null}))
                    }}
                    selectPlace={(place) => dispatch(selectPlace(place))}
                    selectArea={(area) => dispatch(selectArea(area))}
                    openSidebarMenu={(open) => dispatch(openSidebarMenu(open))}
                    openCartMenu={(open) => dispatch(openCartMenu(open))}
                    getProfile={() => dispatch(getProfile())}
                    entitiesCart={entitiesCart}
                    entitiesCartSubscription={entitiesCartSubscription}
                    listCartStatus={listCartStatus}
                    listCartSubscriptionStatus={listCartSubscriptionStatus}
                    getProductCart={(uid) => dispatch(getProductCart(uid))}
                    getProductCartSubscription={(uid) => dispatch(getProductCartSubscription(uid))}
                    getProductsBack={(uid) => dispatch(getProductsBack(uid))}
                    authStatus={authStatus}
                    cleanCart={() => dispatch(cleanCart())}
                    typeCart={typeCart}
                    getAppSectionsSearch={(uid, search) => dispatch(getAppSectionsSearch(uid, search))}
                    getCompaniesSectionsSearch={(uid, category, search) => dispatch(getCompaniesSectionsSearch(uid, category, search))}

                    getPrograms={() => dispatch(getPrograms({}))}
                    listProgramStatus={listProgramStatus}
                    programs={programsResults.map(pUid => programEntities[pUid])}

                    stateSecondaryMenu={stateSecondaryMenu}

                    getProductsByPlace={(uidPlace, params = {})=> dispatch(getProductsByPlace(uidPlace,{...params}))}
                    getPromotions={(uid, params = {}) => dispatch(getPromotions(uid, {area: selectedAreaUid, ...params}))}

                    programCoin={programEntity}
                  />}
                />
              }
            </Fragment>,
            <Route key={"company"} path={`/c/:cUid/`} render={(props) =>
              <DetailCompany
                {...props}
                changeCompany={(cUid) => dispatch(changeCompany(cUid))}
              />
            }/>,
            <SidebarMenu
              key={"sidebar"}
              history={history}
              app={app}
              company={company}
              loginStatus={authStatus}
              selectedPlace={selectedPlace}
              listPlacesStatus={listPlacesStatus}
              multisite={app.allowed_companies && app.allowed_companies.length > 0}
              selectPlace={(place) => dispatch(selectPlace(place))}
              places={placesResults.map(pUid => placesEntities[pUid])}
              activeSidebarMenu={activeSidebarMenu}
              openSidebarMenu={(open) => dispatch(openSidebarMenu(open))}
              closeSession={() => dispatch(closeSession())}
              selectedArea={selectedArea}
              listAreaStatus={listAreasStatus}
              areas={areasResults.map(aUid => areasEntities[aUid])}
              getAreas={() => dispatch(getAreas([{in_company: true}]))}
              selectArea={(area) => dispatch(selectArea(area))}
              getPlaces={(areaSelect) => {
                if (company)
                  dispatch(getPlaces({company: company.uid, area: areaSelect.uid}))
              }}
              raffles={raffles}
              getPrograms={() => dispatch(getPrograms({}))}
              listProgramStatus={listProgramStatus}
              programs={programsResults.map(pUid => programEntities[pUid])}
              updateUser={(data) => dispatch(updateUser(data))}
            />,
            <SidebarCart
              {...this.props}
              key={"sidebar-cart"}
              history={history}
              app={app}
              company={company}
              loginStatus={authStatus}
              selectedPlace={selectedPlace}
              listPlacesStatus={listPlacesStatus}
              multisite={app.allowed_companies && app.allowed_companies.length > 0}
              selectPlace={(place) => dispatch(selectPlace(place))}
              places={placesResults.map(pUid => placesEntities[pUid])}
              activeCartMenu={activeCartMenu}
              openCartMenu={(open) => dispatch(openCartMenu(open))}
              closeSession={() => dispatch(closeSession())}
              //productcart
              authStatus={authStatus}
              changeSecondaryMenu={() => dispatch(changeStateSecondaryMenu(SECONDARY_MENU_BACK))}
              changeStateCart={() => dispatch(changeStateCart(CART_INVISIBLE))}
              user={user}
              sendOrder={(data) => {
                if (selectedPlace) dispatch(orderCart(selectedPlace.uid, data))
              }}
              sendOrderSubscription={(data) => {
                if (selectedPlace) dispatch(orderSubscription(selectedPlace.uid, data))
              }}
              validateCode={(companyUid, code) => {
                if (selectedPlace) dispatch(validateCode(companyUid, code))
              }}
              cleanCode={() => {
                dispatch(cleanCode())
              }}
              coupon={coupon}
              cleanPaymentCart={() => {
                dispatch(cleanPaymentCart())
              }}
              cleanPaymentCartSubscription={() => {
                dispatch(cleanPaymentCartSubscription())
              }}
              getPickupSchedule={(params) => {
                if (selectedPlace) dispatch(getSchedule(selectedPlace.uid, params))
              }}
              getProductCartSubscription={(uid) => dispatch(getProductCartSubscription(uid))}
              pickupSchedule={pickupSchedule}
              updateProductCart={(quantity, productUid, place) => dispatch(updateProductCart(quantity, productUid, place))}
              addProductsBack={(data) => dispatch(addProductsBack(data))}
              updateProductCartSubscription={(quantity, productUid, place) => dispatch(updateProductCartSubscription(quantity, productUid, place))}
              listCartStatus={listCartStatus}
              listCartSubscriptionStatus={listCartSubscriptionStatus}
              entitiesCart={entitiesCart}
              entitiesCartSubscription={entitiesCartSubscription}
              orderStatus={orderStatus}
              orderSuccessBody={orderSuccessBody}
              orderErrors={orderErrors}
              orderSubscriptionStatus={orderSubscriptionStatus}
              orderSubscriptionSuccessBody={orderSubscriptionSuccessBody}
              orderSubscriptionErrors={orderSubscriptionErrors}
              codeStatus={codeStatus}
              codeSuccessBody={codeSuccessBody}
              codeErrors={codeErrors}
              typeCart={typeCart}
              setInfoSubscriptionOptions={(data) => this.setInfoSubscriptionOptions(data)}
              infoSubscriptionOptions={this.state.infoSubscriptionOptions}
              programs={programs}
              getProductsByPlace={(uidPlace, params = []) => dispatch(getProductsByPlace(uidPlace, params))}
              getProductCurrent={(uidPlace, productUid) => dispatch(getProductCurrent(uidPlace, productUid))}
              productsByPlaceCurrent={productsByPlaceCurrent}
              productsByPlaceCurrentStatus={productsByPlaceCurrentStatus}
              addProductCart={(product, place) => dispatch(addProductCart(product, place))}
              openPopUp={(open, component) => dispatch(openPopUp(open, component))}
              activePopUp={activePopUp}
              componentPopUp={componentPopUp}
              uidInfluencer={this.state.uidInfluencer}
              stateCart={stateCart}
              delivery_service={this.state.delivery_service}
              delivery={delivery}
              getFare={(location, placeUid) => dispatch(getFare(location, placeUid))}
              cleanCart={() => dispatch(cleanCart())}
            />,
            <Fragment key={"body"}>
              <div className={"d-flex"}>
                <div
                  className={stateCart === CART_VISIBLE ?
                    (app && app.allowed_companies && app.allowed_companies.length > 0) ? "main-section col-sm-12 col-lg-9 p-0 multisite-main"
                      : "main-section col-sm-12 col-lg-9 p-0" :
                    (this.props.history.location.pathname === "/cart") ? (app && app.allowed_companies && app.allowed_companies.length > 0) ? "main-section col-lg-12 pr-0 pl-0 multisite-main" : "main-section col-lg-12 pr-0 pl-0 no-multisite"
                      : (app && app.allowed_companies && app.allowed_companies.length > 0) ? "main-section col-lg-12 pr-0 pl-0 pt-3 p-sm-0 multisite-main" : "main-section col-lg-12 pr-0 pl-0 p-sm-0  no-multisite"}
                >

                  <div key={"secondary_menu"}>
                    {
                      false &&
                      <SecondaryMenu
                        selectArea={(area) => dispatch(selectArea(area))}
                        history={history}
                        app={app}
                        isSecondaryMenuProgram={this.isSecondaryMenuProgram(this.props.history.location.pathname)}
                        stateSecondaryMenu={stateSecondaryMenu}
                        loginStatus={authStatus}
                        company={company}
                        multisite={app.allowed_companies && app.allowed_companies.length > 0}
                        getPrograms={() => dispatch(getPrograms({}))}
                        listProgramStatus={listProgramStatus}
                        programs={programsResults.map(pUid => programEntities[pUid])}
                        selectedPlace={selectedPlace}
                        listPlacesStatus={listPlacesStatus}

                        places={placesResults.map(pUid => placesEntities[pUid])}
                        selectPlace={(place) => dispatch(selectPlace(place))}
                      />
                    }
                  </div>

                  <Switch>
                    <Route exact path={`/faqs`} render={(props) =>
                      <FAQS
                        app={app}
                        stateCart={stateCart}
                        authStatus={authStatus}
                        statusAppFields={statusAppFields}
                        changeSecondaryMenu={() => dispatch(changeStateSecondaryMenu(SECONDARY_MENU_BACK))}
                        changeStateCart={() => dispatch(changeStateCart(CART_INVISIBLE))}
                        getApp={(uid) => dispatch(getApp(uid))}
                        appFields={appFields}
                      />
                    }
                    />
                    <Route exact path={`/terms-and-conditions`} render={(props) =>
                      <TermsAndConditions
                        app={app}
                        stateCart={stateCart}
                        authStatus={authStatus}
                        statusAppFields={statusAppFields}
                        changeSecondaryMenu={() => dispatch(changeStateSecondaryMenu(SECONDARY_MENU_BACK))}
                        changeStateCart={() => dispatch(changeStateCart(CART_INVISIBLE))}
                        getApp={(uid) => dispatch(getApp(uid))}
                        appFields={appFields}
                      />
                    }
                    />
                    <Route exact path='/politics' render={(props) =>
                      <Politics
                        app={app}
                        stateCart={stateCart}
                        authStatus={authStatus}
                        statusAppFields={statusAppFields}
                        changeSecondaryMenu={() => dispatch(changeStateSecondaryMenu(SECONDARY_MENU_BACK))}
                        changeStateCart={() => dispatch(changeStateCart(CART_INVISIBLE))}
                        getApp={(uid) => dispatch(getApp(uid))}
                        appFields={appFields}
                      />
                    }
                    />
                    <Route path={`${path}program`} render={(props) =>
                      <ProgramRoot
                        {...props}
                        dispatch={dispatch}
                        programs={programs}
                        sections={sections}
                        promotions={promotions}
                        app={app}
                        multisite={app.allowed_companies && app.allowed_companies.length > 0}
                        stateCart={stateCart}
                        changeStateCart={() => dispatch(changeStateCart(CART_INVISIBLE))}
                        company={company}
                        selectedAreaUid={selectedAreaUid}
                        placesPromotion={placesPromotion}
                        relatedPromotion={relatedPromotion}
                        redemptions={redemptions}
                        coupon={coupon}
                        user={user}
                        ads={ads}
                        raffles={raffles}
                        statusAdsCompany={statusAdsCompany}
                        cleanAdsCompany={() => dispatch(cleanAdsCompany())}
                        getAdsCompany={(uid) => dispatch(getAdsCompany(uid))}
                        adsCompany={adsCompanyResults && adsCompanyEntities && adsCompanyResults.map(pUid => adsCompanyEntities[pUid])}

                        productsPlace={productsPlace}
                        selectedPlace={selectedPlace}

                        //product
                        path={path}
                        sectionsProducts={sectionsProducts}
                        listPlacesStatus={listPlacesStatus}
                        selectArea={(a)=>dispatch(selectArea(a))}
                        selectPlace={(place) => dispatch(selectPlace(place))}
                        places={placesResults.map(pUid => placesEntities[pUid])}
                        openPopUp={(open, component) => dispatch(openPopUp(open, component))}
                        activePopUp={activePopUp}
                        componentPopUp={componentPopUp}
                        addProductCart={(product, place) => dispatch(addProductCart(product, place))}
                        entitiesCart={entitiesCart}
                        authStatus={authStatus}
                        addProductsBack={(data) => dispatch(addProductsBack(data))}
                        updateProductCart={(quantity, productUid, place) => dispatch(updateProductCart(quantity, productUid, place))}
                        typeCart={typeCart}
                        cleanCart={() => dispatch(cleanCart())}
                      />
                    }
                    />
                    <Route path={`${path}products`} render={(props) =>
                      <ProductRoot
                        {...props}
                        multisite={app.allowed_companies && app.allowed_companies.length > 0}
                        path={path}
                        dispatch={dispatch}
                        company={company}
                        productsPlace={productsPlace}
                        selectedPlace={selectedPlace}
                        sectionsProducts={sectionsProducts}
                        listPlacesStatus={listPlacesStatus}
                        selectArea={(a)=>dispatch(selectArea(a))}
                        selectPlace={(place) => dispatch(selectPlace(place))}
                        places={placesResults.map(pUid => placesEntities[pUid])}
                        openPopUp={(open, component) => dispatch(openPopUp(open, component))}
                        activePopUp={activePopUp}
                        componentPopUp={componentPopUp}
                        addProductCart={(product, place) => dispatch(addProductCart(product, place))}
                        entitiesCart={entitiesCart}
                        authStatus={authStatus}
                        addProductsBack={(data) => dispatch(addProductsBack(data))}
                        updateProductCart={(quantity, productUid, place) => dispatch(updateProductCart(quantity, productUid, place))}
                        typeCart={typeCart}
                        raffles={raffles}
                        programs={programs}
                        app={app}
                        cleanCart={() => dispatch(cleanCart())}
                        statusAdsCompany={statusAdsCompany}
                        cleanAdsCompany={() => dispatch(cleanAdsCompany())}
                        getAdsCompany={(uid) => dispatch(getAdsCompany(uid))}
                        adsCompany={adsCompanyResults && adsCompanyEntities && adsCompanyResults.map(pUid => adsCompanyEntities[pUid])}

                        //programRoot
                        sections={sections}
                        promotions={promotions}
                        stateCart={stateCart}
                        changeStateCart={() => dispatch(changeStateCart(CART_INVISIBLE))}
                        selectedAreaUid={selectedAreaUid}
                        placesPromotion={placesPromotion}
                        relatedPromotion={relatedPromotion}
                        redemptions={redemptions}
                        coupon={coupon}
                        user={user}
                        ads={ads}

                      />
                    }
                    />
                    <Route path={`${path}subscription`} render={(props) => {
                      return <SubscriptionRoot
                        {...props}
                        dispatch={dispatch}
                        app={app}
                        selectedPlace={selectedPlace}
                        company={company}
                        authStatus={authStatus}
                        path={path}
                        productsPlace={productsPlace}
                        subscriptions={subscriptions}
                        openPopUp={(open, component) => dispatch(openPopUp(open, component))}
                        activePopUp={activePopUp}
                        componentPopUp={componentPopUp}
                        entitiesCartSubscription={entitiesCartSubscription}
                        addProductCartSubscription={(product, place) => dispatch(addProductCartSubscription(product, place))}
                        updateProductCartSubscription={(quantity, productUid, place) => dispatch(updateProductCartSubscription(quantity, productUid, place))}
                        typeCart={typeCart}
                        setInfoSubscriptionOptions={(data) => this.setInfoSubscriptionOptions(data)}
                        infoSubscriptionOptions={this.state.infoSubscriptionOptions}

                        cleanCart={() => dispatch(cleanCart())}
                      />

                    }}/>
                    <Route path={`${path}events`} render={
                      (props) => {
                        return (app && app.name.toLowerCase().includes("rock") && app.name.toLowerCase().includes("pez")) ?
                          <EventsRoot
                            {...props}
                            selectedPlace={selectedPlace}
                            programs={programs}
                            user={user}
                            dispatch={dispatch}
                            app={app}
                            events={events}
                            stateCart={stateCart}
                            company={company}
                            path={path}
                          /> :
                          <Redirect
                            to={"/"}/>
                      }
                    }/>
                    <Route path={`${path}orders`} render={(props) => {
                      return <OrdersRoot
                        {...props}
                        dispatch={dispatch}
                        app={app}
                        orders={orders}
                        stateCart={stateCart}
                        selectedPlace={selectedPlace}
                        company={company}
                        authStatus={authStatus}
                        path={path}
                        openPopUp={(open, component) => dispatch(openPopUp(open, component))}
                        activePopUp={activePopUp}
                        componentPopUp={componentPopUp}
                        typeCart={typeCart}
                      />
                    }}/>
                    <Route exact path={`${path}cart`} render={(props) =>
                      <PrePaymentCartContainer
                        {...props}

                        statusCartBack={statusCartBack}
                        authStatus={authStatus}
                        app={app}
                        multisite={app.allowed_companies && app.allowed_companies.length > 0}
                        changeSecondaryMenu={() => dispatch(changeStateSecondaryMenu(SECONDARY_MENU_BACK))}
                        changeStateCart={() => dispatch(changeStateCart(CART_INVISIBLE))}
                        company={company}
                        user={user}
                        sendOrder={(data) => {
                          if (selectedPlace) dispatch(orderCart(selectedPlace.uid, data))
                        }}
                        sendOrderSubscription={(data) => {
                          if (selectedPlace) dispatch(orderSubscription(selectedPlace.uid, data))
                        }}
                        validateCode={(companyUid, code) => {
                          if (selectedPlace) dispatch(validateCode(companyUid, code))
                        }}
                        cleanCode={() => {
                          dispatch(cleanCode())
                        }}
                        coupon={coupon}
                        cleanPaymentCart={() => {
                          dispatch(cleanPaymentCart())
                        }}
                        cleanPaymentCartSubscription={() => {
                          dispatch(cleanPaymentCartSubscription())
                        }}
                        getPickupSchedule={(params) => {
                          if (selectedPlace) dispatch(getSchedule(selectedPlace.uid, params))
                        }}
                        getProductCartSubscription={(uid) => dispatch(getProductCartSubscription(uid))}
                        pickupSchedule={pickupSchedule}
                        selectedPlace={selectedPlace}
                        updateProductCart={(quantity, productUid, place) => dispatch(updateProductCart(quantity, productUid, place))}
                        addProductsBack={(data) => dispatch(addProductsBack(data))}
                        updateProductCartSubscription={(quantity, productUid, place) => dispatch(updateProductCartSubscription(quantity, productUid, place))}
                        listCartStatus={listCartStatus}
                        listCartSubscriptionStatus={listCartSubscriptionStatus}
                        entitiesCart={entitiesCart}
                        entitiesCartSubscription={entitiesCartSubscription}
                        orderStatus={orderStatus}
                        orderSuccessBody={orderSuccessBody}
                        orderErrors={orderErrors}
                        orderSubscriptionStatus={orderSubscriptionStatus}
                        orderSubscriptionSuccessBody={orderSubscriptionSuccessBody}
                        orderSubscriptionErrors={orderSubscriptionErrors}
                        codeStatus={codeStatus}
                        codeSuccessBody={codeSuccessBody}
                        codeErrors={codeErrors}
                        typeCart={typeCart}

                        setInfoSubscriptionOptions={(data) => this.setInfoSubscriptionOptions(data)}
                        infoSubscriptionOptions={this.state.infoSubscriptionOptions}
                        programs={programs}


                        getProductsByPlace={(uidPlace, params = []) => dispatch(getProductsByPlace(uidPlace, params))}
                        getProductCurrent={(uidPlace, productUid) => dispatch(getProductCurrent(uidPlace, productUid))}
                        productsByPlaceCurrent={productsByPlaceCurrent}
                        productsByPlaceCurrentStatus={productsByPlaceCurrentStatus}
                        addProductCart={(product, place) => dispatch(addProductCart(product, place))}
                        openPopUp={(open, component) => dispatch(openPopUp(open, component))}
                        activePopUp={activePopUp}
                        componentPopUp={componentPopUp}
                        uidInfluencer={this.state.uidInfluencer}

                        stateCart={stateCart}
                        delivery_service={this.state.delivery_service}
                        delivery={delivery}
                        getFare={(location, placeUid) => dispatch(getFare(location, placeUid))}

                        cleanCart={() => dispatch(cleanCart())}
                      />
                    }/>


                    <Route path={`/company/:companyUid/code/:code/`} render={(props) =>
                      <SaveCoupon
                        {...props}
                        app={app}
                        multisite={app.allowed_companies && app.allowed_companies.length > 0}
                        setCoupon={(companyUid, code) => this.props.dispatch(setCoupon(companyUid, code))}
                      />
                    }/>
                    <Route path={`${path}raffles`} render={(props) =>
                      <RafflesRoot
                        {...props}
                        dispatch={dispatch}
                        stateCart={stateCart}
                        raffles={raffles}
                        app={app}
                        programs={programs}
                        user={user}
                        company={company}
                        openPopUp={(open, component) => dispatch(openPopUp(open, component))}
                        activePopUp={activePopUp}
                        componentPopUp={componentPopUp}
                      />
                    }
                    />
                    <Route path={`${path}polls`} render={(props) => {
                      return <PollsRoot
                        {...props}
                        dispatch={dispatch}
                        app={app}
                        polls={polls}
                        stateCart={stateCart}
                        selectedPlace={selectedPlace}
                        company={company}
                        authStatus={authStatus}
                        path={path}
                        openPopUp={(open, component) => dispatch(openPopUp(open, component))}
                        activePopUp={activePopUp}
                        componentPopUp={componentPopUp}
                        typeCart={typeCart}
                      />
                    }}/>
                    <Route path={`/profile`} render={(props) =>
                      <UpdateProfile
                        {...props}
                        changeSecondaryMenu={() => dispatch(changeStateSecondaryMenu(SECONDARY_MENU_BACK))}
                        changeStateCart={() => dispatch(changeStateCart(CART_INVISIBLE))}
                        stateCart={stateCart}
                        app={app}
                        user={user}
                        updateStatus={updateStatus}
                        errors={errorUpdate}
                        getProfile={() => dispatch(getProfile())}
                        skipUpdate={() => dispatch(skipUpdate())}
                        updateUser={(data) => dispatch(updateUser(data))}
                      />
                    }
                    />
                    <Route path={`/qr`} render={(props) =>
                      <QRProfile
                        {...props}
                        changeSecondaryMenu={() => dispatch(changeStateSecondaryMenu(SECONDARY_MENU_BACK))}
                        changeStateCart={() => dispatch(changeStateCart(CART_INVISIBLE))}
                        stateCart={stateCart}
                        app={app}
                        user={user}
                        errors={errorQR}
                        getQR={() => dispatch(getQRMember(app && app.allowed_programs[0].uid))}
                        qrStatus={qrStatus}
                        qrMember={qrMember}
                      />
                    }
                    />
                    <Route path={`/delete`} render={(props) =>
                      <DeleteAccount
                        {...props}
                        changeSecondaryMenu={() => dispatch(changeStateSecondaryMenu(SECONDARY_MENU_BACK))}
                        changeStateCart={() => dispatch(changeStateCart(CART_INVISIBLE))}
                        stateCart={stateCart}
                        app={app}
                        user={user}
                        sendDeleteStatus={sendDeleteStatus}
                        updateStatus={updateStatus}
                        errors={errorUpdate}
                        sendDeleteEmail={() => dispatch(sendDeleteEmail())}
                        cleanDeleteStatus={() => dispatch(cleanDeleteStatus())}
                        getProfile={() => dispatch(getProfile())}
                        skipUpdate={() => dispatch(skipUpdate())}
                        closeSession={() => dispatch(closeSession())}
                        updateUser={(data) => dispatch(updateUser(data))}
                      />
                    }
                    />
                    <Route exact path={`/change-password`} render={(props) =>
                      <ChangePassword
                        {...props}
                        changeSecondaryMenu={() => dispatch(changeStateSecondaryMenu(SECONDARY_MENU_BACK))}
                        changeStateCart={() => dispatch(changeStateCart(CART_INVISIBLE))}
                        stateCart={stateCart}
                        updateStatus={updateStatus}
                        errors={errorUpdate}
                        updateUser={(data) => dispatch(updateUser(data))}
                      />
                    }/>
                    {

                      this.state.loadShows &&
                      <Route exact path={`/`} render={
                        (props) => {
                          return (app.allowed_companies && app.allowed_companies.length > 0) ?
                            <CompaniesContainer
                              company={company}
                              changeSecondaryMenu={() => dispatch(changeStateSecondaryMenu(SECONDARY_MENU_BUSINESS))}
                              app={app}
                              setMainCompany={() => dispatch(mainCompany())}
                              cleanCart={() => dispatch(cleanCart())}
                              cartInvisible={() => dispatch(changeStateCart(CART_INVISIBLE))}
                              getAppSections={(uid) => dispatch(getAppSections(uid))}
                              sections={sectionsResults && sectionsEntities && sectionsResults.map(pUid => sectionsEntities[pUid])}
                              getAdsCompany={(uid) => dispatch(getAdsCompany(uid))}
                              cleanAdsCompany={() => cleanAdsCompany()}
                              statusCompaniesSections={statusCompaniesSections}
                              statusAppSections={statusAppSections}
                              adsCompany={adsCompanyResults && adsCompanyEntities && adsCompanyResults.map(pUid => adsCompanyEntities[pUid])}
                            /> :
                            <Redirect
                              to={
                                (show_store && company.ecommerce_enabled) ?
                                  `${path}products/more`
                                  : (show_program && app.allowed_programs.length!==0) ?
                                    `${path}program/${app.allowed_programs[0].uid}` : show_cart ? "/carta" : ""
                              }/>
                        }
                      }/>
                    }

                    <Route exact path={`/categories/:uid`} render={
                      (props) => {
                        return (app.allowed_companies && app.allowed_companies.length > 0) ?
                          <CompaniesCategoriesContainer
                            {...props}
                            company={company}
                            changeSecondaryMenu={() => dispatch(changeStateSecondaryMenu(SECONDARY_MENU_BUSINESS))}
                            app={app}
                            setMainCompany={() => dispatch(mainCompany())}
                            cleanCart={() => dispatch(cleanCart())}
                            cartInvisible={() => dispatch(changeStateCart(CART_INVISIBLE))}
                            getCompaniesSections={(uid, category) => dispatch(getCompaniesSections(uid, category))}
                            companiesSections={companiesSectionsResults && companiesSectionsEntities && companiesSectionsResults.map(pUid => companiesSectionsEntities[pUid])}
                            statusCompaniesSections={statusCompaniesSections}
                            getAppSections={(uid) => dispatch(getAppSections(uid))}
                            statusAppSections={statusAppSections}
                            sections={sectionsResults && sectionsEntities && sectionsResults.map(pUid => sectionsEntities[pUid])}
                          /> :
                          <Redirect
                            to={
                              (show_store && company.ecommerce_enabled) ?
                                `${path}products/more`
                                : (show_program && app.allowed_programs.length!==0) ?
                                  `${path}program/${app.allowed_programs[0].uid}` : show_cart ? "/carta" : ""
                            }
                          />
                      }
                    }/>
                    <Route exact path={`/in/:uid/`} render={(props) =>
                      <InfluencerRoot
                        {...props}
                        setInfluencer={uid => this.setState({uidInfluencer: uid})}
                      />
                    }/>
                    {
                      this.state.loadShows &&
                      <Route path='*' render={NotFound}/>
                    }
                  </Switch>
                </div>
                <div
                  className={stateCart === CART_VISIBLE ? "" : "hidden"}>
                  <ProductsCart
                    {...this.props}
                    history={history}
                    productsPlace={productsPlace}
                    company={company}
                    getProductsCart={(product) => dispatch(getProductCart(product))}
                    updateProductCartSubscription={(quantity, productUid, place) => dispatch(updateProductCartSubscription(quantity, productUid, place))}
                    multisite={app.allowed_companies && app.allowed_companies.length > 0}
                    listCartStatus={listCartStatus}
                    listCartSubscriptionStatus={listCartSubscriptionStatus}
                    entitiesCartSubscription={entitiesCartSubscription}
                    statusCartBack={statusCartBack}
                    stateCart={stateCart}

                    authStatus={authStatus}
                    getProductsByPlace={(uidPlace, params = []) => dispatch(getProductsByPlace(uidPlace, params))}
                    getProductCurrent={(uidPlace, productUid) => dispatch(getProductCurrent(uidPlace, productUid))}
                    productsByPlaceCurrent={productsByPlaceCurrent}
                    productsByPlaceCurrentStatus={productsByPlaceCurrentStatus}
                    selectedPlace={selectedPlace}
                    addProductCart={(product, place) => dispatch(addProductCart(product, place))}
                    updateProductCart={(quantity, productUid, place) => dispatch(updateProductCart(quantity, productUid, place))}
                    entitiesCart={entitiesCart}
                    addProductsBack={(data) => dispatch(addProductsBack(data))}
                    openPopUp={(open, component) => dispatch(openPopUp(open, component))}
                    activePopUp={activePopUp}
                    componentPopUp={componentPopUp}
                    typeCart={typeCart}
                    cleanCart={cleanCart}
                  />
                </div>
              </div>
              <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
            </Fragment>,
            <Footer key={"footer"}
                    user={user}
                    stateCart={stateCart}
                    history={history}
                    app={app}
                    company={company}
                    loginStatus={authStatus}
                    selectedPlace={selectedPlace}
                    listPlacfesStatus={listPlacesStatus}
                    multisite={app.allowed_companies && app.allowed_companies.length > 0}
                    selectPlace={(place) => dispatch(selectPlace(place))}
                    places={placesResults.map(pUid => placesEntities[pUid])}
                    activeSidebarMenu={activeSidebarMenu}
                    openSidebarMenu={(open) => dispatch(openSidebarMenu(open))}
                    closeSession={() => dispatch(closeSession())}
                    selectedArea={selectedArea}
                    updateUser={(data) => dispatch(updateUser(data))}
                    listAreaStatus={listAreasStatus}
                    areas={areasResults.map(aUid => areasEntities[aUid])}
                    getAreas={() => dispatch(getAreas([{in_company: true}]))}
                    selectArea={(area) => dispatch(selectArea(area))}
                    getPlaces={(areaSelect) => {
                      if (company)
                        dispatch(getPlaces({company: company.uid, area: areaSelect.uid}))
                    }}

                    getPrograms={() => dispatch(getPrograms({}))}
                    listProgramStatus={listProgramStatus}
                    programs={programsResults.map(pUid => programEntities[pUid])}
                    raffles={raffles}
            ></Footer>

          </Fragment>
      }
    </Fragment>

  }
}
