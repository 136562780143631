
import {STATUS_ERROR, STATUS_LOADING, STATUS_SUCCESS} from "../common/utils/generic";
import {
  GET_FARE,
} from "../actions/delivery";



const initialState = {
  fareStatus: null,
  fare: null
};

export default function reducer(state = initialState, action) {
  const {type, payload} = action;
  let data;
  switch (type) {
    case `${GET_FARE}_PENDING`:
      return {
        ...state,
        fareStatus: STATUS_LOADING,
        fare: null
      };
    case `${GET_FARE}_REJECTED`:
      return {
        ...state,
        fareStatus: STATUS_ERROR,
        fare: null
      };
    case `${GET_FARE}_FULFILLED`:
      data = payload.status === 200 ? payload.data : null;

      return {
        ...state,
        fare: data,
        fareStatus: payload.status === 200 ? STATUS_SUCCESS : STATUS_ERROR,
      };

    default:
      return state;
  }
}
