import HttpClient from "../common/utils/httpClient";


export const GET_SECTIONS = 'GET_SECTIONS';




/**Urls**/
export const LIST_SECTIONS_URL = 'v5/me/programs/{program_uid}/sections/';




export function getSections(programUid ) {

  let url = LIST_SECTIONS_URL.replace("{program_uid}",programUid)
  return (dispatch) => HttpClient.get(dispatch, GET_SECTIONS, url);
}


