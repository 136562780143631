import {
  ERROR_DEFAULT,
  LOGIN_TYPE_DOCUMENT,
  LOGIN_TYPE_EMAIL,
  LOGIN_TYPE_FACEBOOK,
  LOGIN_TYPE_IOS,
  LOGIN_TYPE_ONLY_DOCUMENT,
  STATUS_ERROR,
  STATUS_LOADING,
  STATUS_SUCCESS
} from "../common/utils/generic";
import {GET_APP} from "../actions/domain";
import {
  GET_APP_FIELDS,
  GET_APP_SECTIONS,
  GET_COMPANIES_SECTIONS,
  GET_ADS_FOR_COMPANY,
  CLEAN_ADS_COMPANY,
  GET_APP_LANDINIG_MODEL_TWO
} from "../actions/app";
import {CHANGE_COMPANY, MAIN_COMPANY} from "../actions/companies";
import {normalize, schema} from "normalizr";

const sectionSchema = new schema.Entity('sections', {}, {idAttribute: 'uid'});
const sectionListSchema = new schema.Array(sectionSchema);

const adsCompanySchema = new schema.Entity('adsCompany', {}, {idAttribute: 'uid'});
const adsCompanyListSchema = new schema.Array(adsCompanySchema);

const companiesSectionSchema = new schema.Entity('companies', {}, {idAttribute: 'uid'});
const companiesSectionListSchema = new schema.Array(companiesSectionSchema);

const initialState = {
  status: '',
  error: null,
  apiKey: null,
  app:null,
  company: null,
  logo: null,
  appFields: null,
  statusAppFields: null,
  appSections: null,
  statusAppSections: null,
  statusAdsCompany: null,
  statusCompaniesSections: null,
  entitiesAdsCompany: [],
  resultsAdsCompany: [],
  landingModelTwoResults: [],
  statusAppLandingModelTwo: null,
};

export default function app(state = initialState, action) {
  const {type, payload = {data: null}} = action;
  const {data} = payload;
  let dataSections;
  let dataAds;
  let dataCompaniesSections;

  if (type.endsWith('_FULFILLED') && payload.status === 403) {
    localStorage.removeItem('apiKey');
    return {
      ...state,
      //apiKey: null,
      //app:null
    };
  }
  switch (type) {
    case `${GET_APP}_PENDING`:
      return {
        ...state,
        domainStatus: STATUS_LOADING,
        status: STATUS_LOADING,
      };
    case `${GET_APP}_FULFILLED`:
      if(payload.status === 200 && data.length>0){
        let site = data[0]
        let company = null
        localStorage.setItem('apiKey',site.api_key);
        if(site.company){
          company = site.company
        }
        if(site.signin_methods){
          site.login_email =false
          site.login_facebook =false
          site.login_only_document =false
          site.login_document =false
          site.login_ios =false
          for (let i=0;i<site.signin_methods.length;i++){
            switch (site.signin_methods[i].type) {
              case  LOGIN_TYPE_ONLY_DOCUMENT:
                site.login_email =false
                site.login_facebook =false
                site.login_only_document =true
                site.login_document =false
                site.login_ios =false
                break;
              case  LOGIN_TYPE_EMAIL:
                site.login_email =true
                break;
              case  LOGIN_TYPE_DOCUMENT:
                site.login_document =true
                break;
              case  LOGIN_TYPE_FACEBOOK:
                site.login_facebook =true
                site.facebook_config =site.signin_methods[i].settings
                break;
              case  LOGIN_TYPE_IOS:
                site.login_ios =true
                site.ios_config =site.signin_methods[i].settings
                break;
              default:
                break;
            }
            if(site.login_only_document){
              break;
            }
          }
        }

        return {
          ...state,
          status: STATUS_SUCCESS,
          error: null,
          company:company,
          app: site,
          apiKey: site.api_key,
          domainStatus: STATUS_SUCCESS
        };
      }
      // else if (payload.status === 200 && data!==[]) {
      //   return{
      //     ...state,
      //     status: STATUS_SUCCESS,
      //   }
      // }
      else{
        localStorage.removeItem('apiKey');
        return {
          ...state,
          status: STATUS_ERROR,
          error: ERROR_DEFAULT,
          app: null,
          apiKey: null,
        };
      }

    case `${CHANGE_COMPANY}`:
      let company  = state.company

      if(state.app && action.companyUid ) {
        if(company && company.uid!==action.companyUid && state.app.allowed_companies){
          for(let i=0; i< state.app.allowed_companies.length; i++){
            if(state.app.allowed_companies[i].uid === action.companyUid ) {
              company = state.app.allowed_companies[i]
              break;
            }
          }
        }
      }
      return {
        ...state,
        company: company
      };
    case `${MAIN_COMPANY}`:
      let band = null
      if(state.app && state.app.company){
        band = state.app.company
      }
      return {
        ...state,
        company: band
      };
    case `${GET_APP_FIELDS}_PENDING`:
      return {
        ...state,
        statusAppFields: STATUS_LOADING
      };
    case `${GET_APP_FIELDS}_FULFILLED`:
      return {
        ...state,
        appFields: payload.data ? payload.data : null,
        statusAppFields: payload.status === 200 ? STATUS_SUCCESS : STATUS_ERROR,
      };
    case `${GET_APP_SECTIONS}_PENDING`:
      return {
        ...state,
        statusAppSections: STATUS_LOADING
      };
    case `${GET_APP_LANDINIG_MODEL_TWO}_PENDING`:
      return {
        ...state,
        statusAppLandingModelTwo: STATUS_LOADING
        };
    case `${GET_APP_LANDINIG_MODEL_TWO}_FULFILLED`:
      return {
         ...state,
         landingModelTwoResults: payload.status === 200 ? payload.data : {},
         statusAppLandingModelTwo: payload.status===200? STATUS_SUCCESS : STATUS_ERROR,
       };
    case `${GET_APP_SECTIONS}_FULFILLED`:

      dataSections = payload && payload.status === 200 ? normalize(payload.data.results, sectionListSchema) : {};
      return {
        ...state,
        entitiesSections:  dataSections && dataSections.entities ? dataSections.entities.sections : [],
        resultsSections:  dataSections && dataSections.result ? dataSections.result : [],
        nextPageSections:  payload && payload.data ? payload.data.next : null,
        statusAppSections:  payload && payload.status === 200 ? STATUS_SUCCESS : STATUS_ERROR,
        countSections:  payload && payload.data ? payload.data.count : 0,
      };
    case `${GET_ADS_FOR_COMPANY}_PENDING`:
      return {
        ...state,
        statusAdsCompany: STATUS_LOADING
      };
    case `${GET_ADS_FOR_COMPANY}_FULFILLED`:

      dataAds = payload && payload.status === 200 ? normalize(payload.data.length===0 ? payload.data : payload.data, adsCompanyListSchema) : {};
      return {
        ...state,
        entitiesAdsCompany:  dataAds && dataAds.entities ? dataAds.entities.adsCompany : [],
        resultsAdsCompany:  dataAds && dataAds.result ? dataAds.result : [],
        nextPageAdsCompany:  payload && payload.data ? payload.data.next : null,
        statusAdsCompany:  payload && payload.status === 200 ? STATUS_SUCCESS : STATUS_ERROR,
        countAdsCompany:  payload && payload.data ? payload.data.count : 0,
      };
    case `${GET_COMPANIES_SECTIONS}_PENDING`:
      return {
        ...state,
        statusCompaniesSections: STATUS_LOADING
      };
    case `${GET_COMPANIES_SECTIONS}_FULFILLED`:
      dataCompaniesSections = payload && payload.status === 200 ? normalize(payload.data.results, companiesSectionListSchema) : {};
      return {
        ...state,
        entitiesCompaniesSections:  dataCompaniesSections && dataCompaniesSections.entities ? dataCompaniesSections.entities.companies : [],
        resultsCompaniesSections:  dataCompaniesSections && dataCompaniesSections.result ? dataCompaniesSections.result : [],
        nextPageCompaniesSections:  payload && payload.data ? payload.data.next : null,
        statusCompaniesSections:  payload && payload.status === 200 ? STATUS_SUCCESS : STATUS_ERROR,
        countCompaniesSections:  payload && payload.data ? payload.data.count : 0,
      };
    case `${CLEAN_ADS_COMPANY}`:
      return {
        ...state,
        entitiesAdsCompany:  [],
        resultsAdsCompany:  [],
        nextPageAdsCompany:  null,
        statusAdsCompany:  null,
        countAdsCompany:  0,
      };

    default:
      return state
  }
}