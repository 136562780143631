import HttpClient from "../common/utils/httpClient";


export const GET_REDEMPTIONS= 'GET_REDEMPTIONS';
export const APPEND_REDEMPTIONS= 'APPEND_REDEMPTIONS';
export const REDEEM_BY_USER= 'REDEEM_BY_USER';
export const CLEAR_STATUS_REDEEM_BY_USER= 'CLEAR_STATUS_REDEEM_BY_USER';
//export const GET_STATISTICS_REDEMPTIONS= 'GET_STATISTICS_REDEMPTIONS';





/**Urls**/
export const GET_REDEMPTIONS_URL = 'v5/me/programs/{program_uid}/redemptions/';
//export const GET_STATISTICS_REDEMPTIONS_URL = 'v5/me/programs/{program_uid}/redemptions/statistics/';



export function getRedemptions(program_uid, params) {

  let filters = "";
  for (let key of Object.keys(params)) {
    if(params[key]!=null && params[key]!==""){
      if (filters.length > 1)
        filters += `&`;

      filters += `${key}=${params[key]}`
    }
  }
  let url = GET_REDEMPTIONS_URL.replace("{program_uid}",program_uid);
  if(filters.length>0)
    url = `${url}?${filters}`;

  return (dispatch) => HttpClient.get(dispatch, GET_REDEMPTIONS, url);
}

export function redeemByUser(programPromotionUid) {
  return (dispatch) => HttpClient.post(
    {
      "program_promotion": programPromotionUid
    },
    dispatch,
    REDEEM_BY_USER,
    `v5/redemption-by-user/`
  );
}
export function getNextRedemptions(nextPageUri) {
  return (dispatch) => HttpClient.get(dispatch, APPEND_REDEMPTIONS, nextPageUri, false);
}

export function clearStatusRedeem() {
  return {
    type: CLEAR_STATUS_REDEEM_BY_USER
  };
}



