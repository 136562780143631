import React from "react";
import Icon from "../../../common/icons/icon";
import PropTypes from "prop-types";
import "./style.scss"


export default class NavLocalities extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedLocality: {uid: "", price: ""}
    }
  }

  componentDidMount() {
    this.updateComponent()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.updateComponent()
  }

  updateComponent(){
    let {
      selectedLocality,
      selectLocality,
      localities
    } = this.props;
    if(selectedLocality) {
      let found = null;
      if(localities){
        localities.map(l => {
          if(l.uid === selectedLocality.uid){
            found = l
            if(this.state.selectedLocality.uid !== found.uid || this.state.selectedLocality.price !== found.price){
              this.setState({
                selectedLocality: found
              })
              selectLocality(found)
            }
          }
          return true
        })
        if(!found){
          selectLocality(null)
        }
      }
    }
  }

  selectLocality = (d) =>{
    let {
      selectedLocality,
      selectLocality
    } = this.props;
    if(selectedLocality == null || (selectedLocality && selectedLocality.uid!==d.uid)){
      selectLocality(d)
    }
  }

  render() {
    let {
      selectedLocality,
      localities
    } = this.props;
    return (
      <div className="nav-item dropdown nav-dates pb-1 pt-1">
        <button className="nav-link nav-select"
                id="menu-dates"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false">
          <span>Localidad</span><br/>

          { selectedLocality ?
            selectedLocality.name : "Seleccione una Localidad"
          }

          <Icon svg="down" classes="svg-icon down" title="Down"/>
        </button>
        <div className="dropdown-menu dropdown-select" aria-labelledby="menu-dates">
          {
            localities.map((locality, index) =>
              <div key={`locality${index}`} className="dropdown-item"
                   onClick={event => this.selectLocality(locality)}
              >
                <span>{locality.name}</span>
              </div>
            )
          }
        </div>
      </div>

    );
  }
}

NavLocalities.propTypes = {
  selectedLocality: PropTypes.object,
  selectLocality: PropTypes.func,
  localities: PropTypes.array,
};
