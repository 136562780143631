import HttpClient from "../common/utils/httpClient";


export const GET_APP = 'GET_APP';
export const GET_DOMAIN_COMPANY_URL = 'v7/apps/?domain={domain}';


export function retrieveDomain(domain) {
  let aux = domain
  if(domain.includes("www.")){
    aux = domain.substr(4, domain.length)
  }
  console.log(aux)
  return (dispatch) => HttpClient.get(
    dispatch,
    GET_APP,
    `${GET_DOMAIN_COMPANY_URL.replace("{domain}", aux)}`
  );
}
