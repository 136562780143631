import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {GoogleMap, Marker, withGoogleMap, withScriptjs} from "react-google-maps"
import Geocode from "react-geocode";
import Autocomplete from 'react-google-autocomplete';
import './style.scss';

const $ = require('jquery');

class SimpleMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      address: '',
      changeDrag: true,
      mapPosition: {
        lat: this.props.center.lat,
        lng: this.props.center.lng
      },
      markerPosition: {
        lat: this.props.center.lat,
        lng: this.props.center.lng
      },
      firstLoad: false
    }
  }

  componentDidMount() {
    Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_API_WITHOUT_RESTRICTION);
    Geocode.setLanguage("es");
    Geocode.enableDebug();
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    let {address} = this.props;
    if(this.state.address !== address){
      this.setState({address: address})
    }
    if(localStorage.getItem("reference") && !this.state.firstLoad &&  $("#reference")!==null){
      setTimeout(
        function() {
          document.getElementById("reference").value = localStorage.getItem("reference");
        },
        200
      );
      this.setState({firstLoad: true})
    }
  }

  onMarkerDragEnd = (event) => {
    let {setAddress} = this.props;
    let newLat = event.latLng.lat(),
      newLng = event.latLng.lng();
    Geocode.fromLatLng( newLat , newLng ).then(
      response => {
        const address = response.results[0].formatted_address;
        setAddress( response.results[0].formatted_address)
        this.setState( {
          address: ( address ) ? address : '',
          markerPosition: {
            lat: newLat,
            lng: newLng
          },
          mapPosition: {
            lat: newLat,
            lng: newLng
          },
        } )
      },
      error => {
        console.error(error);
      }
    );

    this.props.onDragEvent(newLat, newLng)
  };


  shouldComponentUpdate(nextProps, nextState, nextContext) {

    if (this.props.address !== nextProps.address && nextProps.address && nextProps.address.length > 6) {
      Geocode.fromAddress(nextProps.address).then(
        response => {
          const {lat, lng} = response.results[0].geometry.location;
          if (lat && lng) {
            this.setState({
              markerPosition: {
                lat: lat,
                lng: lng
              },
              mapPosition: {
                lat: lat,
                lng: lng
              }
            });
            this.props.onDragEvent(lat, lng)
          }
        },
        error => {
          console.error(error);
        }
      );
    }

    if (nextState.markerPosition.lat !== this.state.markerPosition.lat) {
      return true
    }
    return false
  }

  onPlaceSelected = ( place ) => {
    const {setAddress} = this.props
    setAddress(place.formatted_address)
  };

  verify = (e) => {
    e.preventDefault()
    if($(".pac-target-input").val()==="") {
      $(".pac-target-input").addClass("alert")
      $("#error").addClass("d-block")
    }else{
      $(".pac-target-input").removeClass("alert")
      $("#error").removeClass("d-block")
    }
  };

  render() {
    let { searchPoint} = this.props;

    const AsyncMap = withScriptjs(
      withGoogleMap(
        props => (
          <Fragment>
            <GoogleMap google={process.env.REACT_APP_GOOGLE_MAPS_API_WITHOUT_RESTRICTION}
                       options={{streetViewControl: false}}
                       defaultZoom={this.props.zoom}
                       defaultCenter={{lat: this.state.mapPosition.lat, lng: this.state.mapPosition.lng}}
            >
              <Marker
                google={process.env.REACT_APP_GOOGLE_MAPS_API_WITHOUT_RESTRICTION}
                onDragEnd={this.onMarkerDragEnd}
                draggable={true}
                position={{lat: this.state.markerPosition.lat, lng: this.state.markerPosition.lng}}/>

                <div className="search">
                  <div className="input-group">



                    <div className={"delivery-address"}>
                      {/*<img src={flag} alt="flag"/>*/}
                      <div className="form">
                        <Autocomplete
                          defaultValue={this.state.address}
                          onPlaceSelected={ this.onPlaceSelected }
                          onChange={ e => this.verify(e) }
                          placeholder={""}
                          types={[]}
                          required
                        />
                        <label className="lbl-nombre">
                          <span className="text-nomb">Dirección de entrega</span>
                        </label>
                      </div>
                    </div>
                    <div className={"delivery-department mb-3"}>
                      {/*<img src={house} alt="house"/>*/}
                      <div className="form">
                        {this.props.reference}
                        <input id="reference" type="text" required/>

                        <label className="lbl-nombre">
                          <span className="text-nomb">Piso, puerta o referencia</span>
                        </label>
                      </div>
                    </div>

                   {
                      false &&
                      <div className="input-group-append">
                        <button className="btn btn-outline-secondary" id="btn-search" type="button"
                                onClick={e => searchPoint(e)}>Buscar
                        </button>
                      </div>
                    }
                  </div>
                  <div id="error" className="invalid-feedback">
                    El campo es requerido
                  </div>
                </div>
            </GoogleMap>
          </Fragment>
          )
      )
    );
    if (this.props.center.lat !== undefined) {
      return (
        <Fragment>
          <AsyncMap
            googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_WITHOUT_RESTRICTION}&libraries=places&callback=initMap`}
            loadingElement={
              <div style={{height: `100%`}}/>
            }
            containerElement={
              <div style={{height: this.props.height, marginBottom:"0"}} className={"delivery-body"} id={"delivery-body"}/>
            }
            mapElement={
              <div style={{height: `100%`, minHeight: `90%`,order: "1"}} className={"delivery-body-map"}/>
            }
          />
        </Fragment>)
    }
  }
}

SimpleMap.defaultProps = {
  isMarkerShown: true,
  zoom: 8,
  latitude: 0,
  longitude: 0,
  address: '',
  height: '300px',
  center: {lat: -12.1226727, lng: -77.033459}
};

SimpleMap.propTypes = {
  isMarkerShown: PropTypes.bool,
  address: PropTypes.string,
  onDragEvent: PropTypes.func,
};

export default SimpleMap;