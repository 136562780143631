import React, {Fragment} from 'react'
import PropTypes from 'prop-types';
import {STATUS_SUCCESS} from "../../common/utils/generic";
import {Redirect} from "react-router-dom";

export default class ProgramsContainer extends React.Component {



  redirectProgram() {
    let {
      cUid,
      multisite,
      programs
    } = this.props;

    if(programs){
      programs = programs.filter((p) => {
        return cUid &&  cUid === p.company.uid
      })
      if(programs.length>0){

        return <Redirect to={`${(multisite && cUid)?`/c/${cUid}`:''}/program/${programs[0].uid}`} />
      }else{
        return <p>Lo sentimos, en este momento no tenemos programas activos en nuestra App</p>
      }
    }
  }

  render() {
    let {
      listProgramStatus
    } = this.props;
    return(

      <Fragment>
        {
          listProgramStatus === STATUS_SUCCESS ?
            <Fragment>
            {
              this.redirectProgram()
            }
            </Fragment> : <p>Cargando</p>

        }
      </Fragment>
    );
  }


}

ProgramsContainer.defaultProps = {
  programs: null,
  app: null,
  company: null
};

ProgramsContainer.propTypes = {
  app: PropTypes.object,
  programs: PropTypes.object,
  company :  PropTypes.object,
};


