import {CLOSE_SESSION, LOGIN, LOGIN_GOOGLE, REGISTER, RESET, USER_BAND, CLEAN_VALIDATE_USER, VALIDATE} from "../actions/user";
import {ERROR_DEFAULT, STATUS_ERROR, STATUS_LOADING, STATUS_SUCCESS} from "../common/utils/generic";


const initialState = {
  errorAuth: null,
  errorRegister: null,
  userBand:null,
  authStatus: null,
  registerStatus: null,
  token: null
};

export default function reducer(state = initialState, action) {
  const {type, payload = {data: null}} = action;
  const {data} = payload;
  switch (type) {

    case `${LOGIN}_PENDING`:
      return {
        ...state,
        authStatus: STATUS_LOADING,
        errorAuth: null,
      };
    case `${LOGIN}_REJECTED`:
      return {
        ...state,
        authStatus: STATUS_ERROR,
        errorAuth: ERROR_DEFAULT,
      };
    case `${LOGIN}_FULFILLED`: {
      if( payload.status === 200 ){
        localStorage.setItem('token', data.token);
        localStorage.setItem('merge', true);
      }
      return {
        ...state,
        authStatus: payload.status === 200 ? STATUS_SUCCESS : STATUS_ERROR,
        errorAuth: payload.status !== 200 ? (payload.status === 400 ? data : ERROR_DEFAULT) : null,
        token: data ? data.token : null
      };
    }
    case `${LOGIN_GOOGLE}_PENDING`:
      return {
        ...state,
        authStatus: STATUS_LOADING,
        errorAuth: null,
      };
    case `${LOGIN_GOOGLE}_REJECTED`:
      return {
        ...state,
        authStatus: STATUS_ERROR,
        errorAuth: ERROR_DEFAULT,
      };
    case `${LOGIN_GOOGLE}_FULFILLED`: {
      if( payload.status === 200 ){
        localStorage.setItem('token', data.token);
        localStorage.setItem('merge', true);
      }
      return {
        ...state,
        authStatus: payload.status === 200 ? STATUS_SUCCESS : STATUS_ERROR,
        errorAuth: payload.status !== 200 ? (payload.status === 400 ? data : ERROR_DEFAULT) : null,
        token: data ? data.token : null
      };
    }

    case `${VALIDATE}_PENDING`:
      return {
        ...state,
        validateStatus: STATUS_LOADING,
        errorValidate: null,
      };
    case `${VALIDATE}_REJECTED`:
      return {
        ...state,
        validateStatus: STATUS_ERROR,
        errorValidate: ERROR_DEFAULT,
      };
    case `${VALIDATE}_FULFILLED`: {
      return {
        ...state,
        validateStatus: payload.status === 200 ? STATUS_SUCCESS : STATUS_ERROR,
        errorValidate: payload.status !== 200 ? (payload.status === 400 ? data : ERROR_DEFAULT) : null,
        validate: (payload.status === 200 || payload.status === 201) ? data : null
      };
    }
    case `${CLEAN_VALIDATE_USER}`: {
      return {
        ...state,
        validateStatus: null,
        errorValidate: null,
        validate: null
      };
    }

    case `${REGISTER}_PENDING`:
      return {
        ...state,
        registerStatus: STATUS_LOADING,
        errorRegister: null,
      };
    case `${REGISTER}_REJECTED`:
      return {
        ...state,
        registerStatus: STATUS_ERROR,
        errorRegister: ERROR_DEFAULT,
      };
    case `${REGISTER}_FULFILLED`: {
      if( payload.status === 200 || payload.status === 201 ){
        localStorage.setItem('token', data.token);
        localStorage.setItem('merge', true);
      }
      return {
        ...state,
        registerStatus: (payload.status === 200 || payload.status === 201) ? STATUS_SUCCESS : STATUS_ERROR,
        authStatus: (payload.status === 200 || payload.status === 201) ? STATUS_SUCCESS : null,
        errorRegister: payload.status !== 200 && payload.status !== 201 ? (payload.status === 400 ? data : ERROR_DEFAULT) : null,
        token: data ? data.token : null
      };
    }

    case `${USER_BAND}`: {
      return {
        ...state,
        userBand: action.user,
      };
    }
    case `${RESET}`: {
      return {
        ...state,
        errorAuth: null,
        errorRegister: null,
        authStatus: null,
        registerStatus: null,
      };
    }
    case `${CLOSE_SESSION}`: {
      localStorage.removeItem('token')
      localStorage.removeItem('merge');
      return {
        ...state,
        authStatus: null,
        userBand: null,
        registerStatus: null,
        token: null,
        errorAuth: null,
        errorRegister: null,
      };
    }
    default:
      return state;
  }
}
