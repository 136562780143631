import HttpClient from "../common/utils/httpClient";

export const GET_ADS = 'GET_ADS';

/**Urls**/
export const LIST_ADS_URL = 'v5/me/programs/{program_id}/ads/';

export function getAds(programUid, params) {
  let url = LIST_ADS_URL.replace("{program_id}",programUid)
  let filters = "";
  if(params){
    for (let key of Object.keys(params)) {
      if(params[key]!=null && params[key]!==""){
        if (filters.length > 1) {
          filters += `&`;
        }
        filters += `${key}=${params[key]}`
      }
    }
    if(filters.length>0) {
      url = `${url}?${filters}`;
    }
  }
  return (dispatch) => HttpClient.get(dispatch, GET_ADS, url);
}

