import React, {Fragment} from "react";
import PropTypes from "prop-types";
import "./style.scss"
import {STATUS_LOADING} from "../../../common/utils/generic";
import moment from "moment"
import NoSearchResult from "../../../common/images/NoSearchResult.svg";

export default class PickupSchedule extends React.Component {


  componentDidMount() {
  }

  selectInterval = (interval) => {
    let {
      selectSchedule
    } = this.props;
    selectSchedule(interval)
  }

  render() {
    let {
      schedule,
      scheduleStatus,
      selectedInterval
    } = this.props;
    return (
      <Fragment>
        {scheduleStatus === STATUS_LOADING  ?
          <Fragment>
            <div className={"interval loading"} ><p/></div>
            <div className={"interval loading"} ><p/></div>
            <div className={"interval loading"} ><p/></div>
            <div className={"interval loading"} ><p/></div>
            <div className={"interval loading"} ><p/></div>
            <div className={"interval loading"} ><p/></div>
          </Fragment> :
          <Fragment>
            {
              (schedule &&
                schedule.dates &&
                schedule.dates.length > 0) ?
                <Fragment>
                  {
                    schedule.dates[0].intervals.length > 0 ?
                      schedule.dates[0].intervals.map((interval, index) =>
                        <div key={`date${index}`}
                             className={`interval ${ (selectedInterval &&
                               selectedInterval.starts === interval.starts &&
                               selectedInterval.ends === interval.ends
                             )?'active':''}`}
                             onClick={event => this.selectInterval(interval)}
                        >
                          <span>{moment(interval.starts, 'hh:mm:ss').format("hh:mm a")} - {moment(interval.ends, 'hh:mm:ss').format("hh:mm a")} </span>
                        </div>
                      ) :
                      <div className={"col-lg-12 no-results"}>
                        <img src={NoSearchResult} alt="Sin items"/>
                        <p >No se encontraron horarios para este día, por favor seleccione otra fecha</p>
                      </div>
                  }
                </Fragment> :
                <Fragment>
                  <div className={"col-lg-12 no-results"}>
                    <img src={NoSearchResult} alt="Sin items"/>
                    <p >No se encontraron horarios para este día, por favor seleccione otra fecha</p>
                  </div>
                </Fragment>
            }
          </Fragment>
        }
      </Fragment>

    );
  }
}

PickupSchedule.propTypes = {
  selectedHour: PropTypes.object,
  selectSchedule: PropTypes.func,
  hours: PropTypes.array,
  state: PropTypes.string,
};
