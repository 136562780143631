import React, {Fragment} from 'react'
import './style.scss';
import PropTypes from "prop-types";
import PollItem from "./PollItem";
import {STATUS_LOADING} from "../../../../common/utils/generic";
import moment from "moment";
import assignmentIcon from "../../../../common/images/assignment.svg";
import notItems from "../../../../common/images/no_items.png";
import taskIcon from "../../../../common/images/task.svg";

export default class PollTable extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedOrder: null,
      selectedPoll: null,
    };
  }

  previous() {
    const {getNumberPage, pageCurrent, setPageCurrent} = this.props
    setPageCurrent(pageCurrent - 1)
    getNumberPage(pageCurrent - 1)
  }

  next() {
    const {getNumberPage, pageCurrent, setPageCurrent} = this.props
    setPageCurrent(pageCurrent + 1)
    getNumberPage(pageCurrent + 1)
  }

  componentDidMount() {
    let {placeCurrent} = this.props
    if (placeCurrent) {
      this.props.getOrders();
    }
  }

  setSelectedPoll = (selectedPoll) => {
    this.setState({selectedPoll: selectedPoll})
    this.props.openPopUp(selectedPoll.uid)
  }

  renderPollsRead() {
    const {polls, listPollsStatus, count, openPopUp, clearStatusSendPoll, changeLoadList} = this.props;
    if (listPollsStatus === STATUS_LOADING) {
      return <Fragment>
        <PollItem loading={true}/>
        <PollItem loading={true}/>
      </Fragment>;
    }
    if (count === 0) {
      return (
        <div className={"d-flex justify-content-center mt-4 flex-column"}>
          <img src={notItems} alt="notItems" className={"not-items"}/>
          <span className={"text-center mt-3"} style={{color: "#939393", fontWeight: 700}}>Por el momento no hay <br className={"d-none d-sn-block d-md-block"}/>ninguna encuesta</span>
        </div>
      );
    }
    let auxDates = [];
    let uniques = [];
    polls.sort((a, b) => new Date(a.start_datetime).getTime() - new Date(b.start_datetime).getTime());
    polls.map(poll => {
      auxDates.push(moment(poll.start_datetime).format("l"))
      return true
    })
    for (let i = 0; i < auxDates.length; i++) {
      let e = auxDates[i];
      if (!uniques.includes(auxDates[i])) {
        uniques.push(e);
      }
    }
    auxDates = uniques
    console.log(polls, "polls")
    return auxDates.map((date, index) => (
      <div className={index > 0 ? "section mt-2" : "section"} key={`sectionPoll${index}`}>
        <div className="container p-0">
          <div className={"mx-auto col-12 survey-list p-0"}>
            {polls.map((poll, index) => {
                return date === moment(poll.start_datetime).format("l") &&
                  <PollItem key={`poll${index}`} count={count} poll={poll}
                            openPopUp={openPopUp}
                            loading={false}
                            clearStatusSendPoll={clearStatusSendPoll}
                            changeLoadList={changeLoadList}
                            setSelectedPoll={(poll) => this.setSelectedPoll(poll)}
                  />
              }
            )}
          </div>
        </div>
      </div>
    ))
  }

  renderPollsNotRead() {
    const {pollsResolved, listPollsResolvedStatus, countResolved, openPopUp, clearStatusSendPoll, changeLoadListResolved} = this.props;
    console.log(pollsResolved, "pollsResolved")
    if (listPollsResolvedStatus === STATUS_LOADING) {
      return <Fragment>
        <PollItem loading={true}/>
        <PollItem loading={true}/>
      </Fragment>;
    }
    if (countResolved === 0) {
      return (
        <div className={"d-flex justify-content-center mt-4 flex-column"}>
          <img src={notItems} alt="notItems" className={"not-items"}/>
          <span className={"text-center mt-3"} style={{color: "#939393", fontWeight: 700}}>Por el momento no hay <br className={"d-none d-sn-block d-md-block"}/>ninguna encuesta</span>
        </div>
      );
    }
    let auxDates = [];
    let uniques = [];
    pollsResolved.sort((a, b) => new Date(a.start_datetime).getTime() - new Date(b.start_datetime).getTime());
    pollsResolved.map(poll => {
      auxDates.push(moment(poll.start_datetime).format("l"))
      return true
    })
    for (let i = 0; i < auxDates.length; i++) {
      let e = auxDates[i];
      if (!uniques.includes(auxDates[i])) {
        uniques.push(e);
      }
    }
    auxDates = uniques
    return auxDates.map((date, index) => (
      <div className={index > 0 ? "section mt-2" : "section"} key={`sectionPoll${index}`}>
        <div className="container p-0">
          <div className={"mx-auto col-12 survey-list p-0"}>
            {pollsResolved.map((poll, index) => {
                return date === moment(poll.start_datetime).format("l") &&
                  <PollItem key={`poll${index}`} count={countResolved} poll={poll}
                            openPopUp={openPopUp}
                            loading={false}
                            read={true}
                            clearStatusSendPoll={clearStatusSendPoll}
                            changeLoadList={changeLoadListResolved}
                            setSelectedPoll={(poll) => this.setSelectedPoll(poll)}
                  />
              }
            )}
          </div>
        </div>
      </div>
    ))
  }

  render() {
    return (
      <Fragment>
        <div className={"card detail polls  col-10 col-lg-7 mx-auto mt-4"}>
          <div className={"title-bar-section"}>
            <img
              src={assignmentIcon}
              alt={"cart"}
              style={{ height: 30, width: 30, marginBottom: 4, marginRight: 8, marginLeft: 0 }}
            />
            <h6 className={""}>
              Encuestas sin resolver
            </h6>
          </div>

          {
            this.renderPollsRead()
          }
        </div>
        <div className={"card detail polls  col-10 col-lg-7 mx-auto mt-4"}>
          <div className={"title-bar-section"}>
            <img
              src={taskIcon}
              alt={"cart"}
              style={{ height: 30, width: 30, marginBottom: 4, marginRight: 8, marginLeft: 0 }}
            />
            <h6 className={""}>
              Encuestas resueltas
            </h6>
          </div>

          {
            this.renderPollsNotRead()
          }
        </div>
        <br/>
      </Fragment>
    );
  }
}

PollTable.defaultProps = {
  subscriptions: [],
  listSubscriptionsStatus: '',
  count: 0,
  nextPageSubscriptions: null
};

PollTable.propTypes = {
  subscriptions: PropTypes.array,
  nextPageSubscriptions: PropTypes.string,
  getMoreSubscriptions: PropTypes.func,
  listNextSubscriptionsStatus: PropTypes.string,
  count: PropTypes.number,
  listSubscriptionsStatus: PropTypes.string
};