import "./Sidebar.scss";
import React from "react";

export const Sidebar = ({ width, height, active, children, openSidebarMenu, right="310", cart=false,clickCartPopup=false }) => {

  let style = {
    width: width,
    minHeight: height,
    transform: `translatex(${width}px)`
  }
  if(window.innerWidth >= 1000){
    if(active){
      style.top = `100px`
      style.right = `${right}px`
    }else{
      style.right = `0px`
    }
  }
  else{
    if(active){
      style.right = `${width}px`
    }else{
      style.right = `0px`
    }
  }
  
  return (
    <React.Fragment>
      {
        active &&
        <div className={cart ? "shadow-back cart-popup" : "shadow-back"}
          onClick={event => openSidebarMenu(false)}
        />
      }
      <div
        className="side-bar"
        style={style}
      >
        {clickCartPopup ? <div className="content">{children}</div> : <div className="content" onClick={event => console.log(false)}>{children}</div> }
      </div>
    </React.Fragment>
  );
};