import React, {Fragment} from 'react'
import SubscriptionList from "./subscriptionList/SubscriptionList";
import {STATUS_ERROR, STATUS_SUCCESS} from "../../../common/utils/generic";
import {toast} from "react-toastify";

export default class SubscriptionDetailContainer extends React.Component {

  constructor() {
    super();
    this.state = {
      companyUid : ""
    }
  }

  componentDidMount() {
    const {
      changeStateCart,changeSecondaryMenu,changeTypeCart,
      selectedPlace
    } = this.props
    changeStateCart()
    changeSecondaryMenu()
    changeTypeCart()
    if(selectedPlace)
    this.updateComponent()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.updateComponent()
  }

  updateComponent() {
    const {company, getSubscriptions, subscriptions: {updateStatus},cleanUpdate } = this.props
    if(company){
      if(company.uid !==  this.state.companyUid){
        this.setState({companyUid:company.uid })
        if(company.subscription_enabled) {
          getSubscriptions({companie: company.uid})
        }
      }
      if(updateStatus===STATUS_SUCCESS){
        getSubscriptions({companie: company.uid})
        toast.success("La suscripción cancelada correctamente")
        cleanUpdate()
      }
      if(updateStatus===STATUS_ERROR){
        toast.error("Ha ocurrido un error al cancelar su suscripción")
        cleanUpdate()
      }
    }
  }

  render() {
    let {
      subscriptions:{
        entities: subscriptionsEntities,
        results: subscriptionsResults,
        listStatus,
        listNextStatus,
        nextPage,
        count,
        updateStatus
      },
      selectedPlace,
      getMoreSubscriptions,
      canceledSubscription,
      getSubscriptions
    } = this.props
    return (
      <Fragment>
        <SubscriptionList
          subscriptions={subscriptionsResults.map(cUid => subscriptionsEntities[cUid])}
          subscriptionsEntities={subscriptionsEntities}
          count = {count}
          nextPageSubscriptions={nextPage}
          listSubscriptionsStatus={listStatus}
          listNextSubscriptionsStatus={listNextStatus}
          getMoreSubscriptions = {getMoreSubscriptions}
          selectedPlace={selectedPlace}
          canceledSubscription={(uid) => {canceledSubscription(uid) }}
          getSubscriptions={() => {
            if (selectedPlace) getSubscriptions({place: selectedPlace.uid})
          }}
          updateStatus={updateStatus}
        />
      </Fragment>
    );
  }

}

