import React, {Component} from 'react';
import {connect} from 'react-redux';

class PoliticsCookies extends Component {

   componentDidMount() {
      this.props.setOverlay(false)
   }

   render() {
       return(
          <div className="container tyc">
             <div className="row">
               <div className="col-lg-12 mt-5 mb-5">
                 <h3 className={"font-weight-bold mb-4"}>Política de Cookies</h3>
                 <p>Utilizamos una tecnología denominada “cookies”, con la finalidad de brindarles información relativa
                   a su uso, de conformidad con la Ley 29733, Ley de Protección de Datos
                   Personales y su Reglamento, aprobado por Decreto Supremo 003-2013-JUS.
                   Esta Política describe la información que se recaba, a partir del uso de cookies, de los visitantes y
                   el tratamiento
                   que realiza con dicha información. El visitante declara haber leído y aceptado la Política de Cookies
                   sujetándose a sus disposiciones.</p>


                 <strong>¿Qué son las cookies?</strong>
                 <p>
                   Las cookies son pequeños fragmentos de texto de sitios web que navegas y que permiten “recordar”
                   información sobre tu visita. Se utiliza cookies y/o tecnologías similares que permiten
                   almacenar y recupera información cuando los visitantes navegan en nuestro sitio web. En general,
                   estas
                   tecnologías pueden servir para finalidades muy diversas, como, por ejemplo, reconocer a los
                   visitantes,
                   obtener información sobre sus hábitos de navegación o personalizar la forma en que se muestra el
                   contenido.</p>

                 <strong>¿Qué tipos de cookies se utilizan en esta página web y cuál es su finalidad?</strong>
                 <ul className={"mt-2"}>
                   <li>
                     <strong>Cookies necesarias</strong> <br/>
                     Permiten a los visitantes la navegación a través de la página web y la utilización de las
                     diferentes
                     opciones o servicios que en ella existan, incluyendo aquellas que se utilizan para permitir la
                     gestión y
                     operativa del sitio web y habilitar sus funciones y servicios como, por ejemplo, controlar el
                     tráfico
                     y
                     la comunicación de datos, identificar la sesión, realizar la solicitud de inscripción o
                     participación
                     en
                     un evento, utilizar elementos de seguridad durante la navegación o habilitar contenidos dinámicos
                     (por ejemplo, animación de carga de un texto o imagen).
                   </li>
                   <li className={"mt-2"}>
                     <strong>Cookies de preferencias</strong> <br/>
                     Permiten recordar información para que el visitante acceda al servicio con determinadas
                     características que pueden diferenciar su experiencia con otros visitantes como, por ejemplo, el
                     idioma, el número de resultados a mostrar cuando el visitante realiza una búsqueda, el aspecto o
                     contenido del servicio en función del tipo de navegador a través del cual el visitante accede al
                     servicio
                     o de la región desde la que accede al servicio.
                   </li>
                   <li className={"mt-2"}>
                     <strong>Cookies de estadísticas</strong> <br/>
                     Permiten el seguimiento y análisis del comportamiento de los visitantes al sitio web, incluida la
                     cuantificación de los impactos de los anuncios. La información recogida en este tipo de cookies se
                     utiliza en la medición de la actividad del sitio web, con el fin de introducir mejoras en función
                     del
                     análisis de los datos del uso que hacen los visitantes.
                   </li>
                   <li className={"mt-2"}>
                     <strong>Cookies de marketing</strong> <br/>
                     Almacenan información del comportamiento de los visitantes obtenida a través de la observación
                     continuada de sus hábitos de navegación, lo que permite desarrollar un perfil específico para
                     mostrar
                     publicidad en función del mismo. Este tipo de cookies es utilizado para rastrear a los visitantes
                     del
                     sitio web. Tienen como finalidad mostrar anuncios relevantes y atractivos para el visitante.
                   </li>
                 </ul>

                 <p>A continuación, detallamos el nombre el proveedor (si se trata de cookies propias o de terceros), la
                   finalidad y
                   la caducidad de los cookies</p>

                 <div className="table-responsive">
                   <table className="table">
                     <thead>
                     <tr>
                       <th scope="col">NOMBRE</th>
                       <th scope="col">PROPÓSITO</th>
                       <th scope="col">CADUCIDAD</th>
                       <th scope="col">TIPO</th>
                     </tr>
                     </thead>
                     <tbody>
                     <tr>
                       <td>_ga</td>
                       <td>Cookie analítica de Google Analytics. Sirve para distinguir a los usuarios.</td>
                       <td>2 años</td>
                       <td>HTTP</td>
                     </tr>
                     <tr>
                       <td>_guid</td>
                       <td>Cookie analítica de Google Analytics. Sirve para distinguir a los usuarios.</td>
                       <td>24 horas</td>
                       <td>HTTP</td>
                     </tr>
                     </tbody>
                   </table>
                 </div>

                 <strong>¿Qué control tiene el visitante al sitio web sobre las cookies?</strong>
                 <p>
                   Las cookies pueden borrarse del disco duro si el visitante así lo desea. La mayoría de los
                   navegadores
                   aceptan
                   las cookies de forma automática, pero permiten al visitante cambiar la configuración de su navegador
                   para que
                   rechace la instalación de cookies ―con excepción de los de tipo “necesarias” ― sin que ello
                   perjudique
                   su
                   acceso y navegación por el sitio web.</p>

                 <strong>¿Esta Política de Cookies puede ser modificada o actualizada?</strong>
                 <p>
                   Con motivo de la mejora continua de nuestros procesos, podrá ser modificada y/o actualizada esta
                   Política de
                   Cookies, ya sea para adaptarla a futuros cambios normativos, implementar mejores prácticas, brindar
                   una
                   mejor calidad de servicio o comunicar nuevas alternativas relacionadas al presente documento. En ese
                   sentido, verifique en la página web estos términos regularmente para consultar los cambios que puedan
                   haber existido y de qué manera le pueden afectar.</p>

               </div>
             </div>
          </div>
       )
   }

}

export default connect()(PoliticsCookies);
