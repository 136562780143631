import React, {Fragment} from "react";
import {STATUS_ERROR, STATUS_SUCCESS} from "../../../../../common/utils/generic";
import Icon from "../../../../../common/icons/icon";
import {toast} from "react-toastify";
import {Link} from "react-router-dom";

export default class DiscountCode extends React.Component {

  generateCode = (e) => {
    e.preventDefault()
    let {getCoupon, programUid, promotionUid} = this.props;
    getCoupon(programUid, promotionUid);
  }

  copyToClipboard = (e, text) => {
    e.preventDefault()
    if (text) {
      let textField = document.createElement('textarea')
      textField.innerText = text
      document.body.appendChild(textField)
      textField.select()
      document.execCommand('copy')
      textField.remove()
      toast.info("Código copiado")
    }

  }

  isExistInMultisite = () => {
    let {promotionCurrent, app} = this.props;
    let isExist = false
    if(promotionCurrent){
      if(app.allowed_companies){
        app.allowed_companies.map((company) =>{
          if(company.uid === promotionCurrent.company_retail.uid){
            isExist = true
          }
          return true
        })
      }
    }
    return isExist;
  }

  render() {
    let {promotionCurrent, statusDetailCoupon, errorDetailCoupon, couponDetail} = this.props;

    return (
      <Fragment>
        {(promotionCurrent.used_coupon === true &&
          couponDetail === null &&
          promotionCurrent.coupon === null
        ) ?

          <button className="btn btn-secondary" onClick={(e) => this.generateCode(e)}>
            Generar código de descuento
          </button>
          : <Fragment>{
            promotionCurrent.coupon &&
            (promotionCurrent.coupon.is_redeemed ?
                <p>El código de descuento <span>{promotionCurrent.coupon.code}</span> ya fue redimido</p>
                :
                <div className={"coupon-container"}>
                  <div className={"coupon"}>
                    <p>{promotionCurrent.coupon.code}</p>
                  </div>
                  <button className={"btn btn-secondary btn-copy"}
                          onClick={e => this.copyToClipboard(e, promotionCurrent.coupon.code)}>
                    <Icon svg="copy" classes="svg-icon" title="copy"/>
                  </button>
                  {promotionCurrent.coupon.redeem_link &&
                    (this.isExistInMultisite() ?
                      <Link className={"btn btn-secondary "}
                            to={`/company/${promotionCurrent.company_retail.uid}/code/${promotionCurrent.coupon.code}/`}>
                        <Icon svg="place" classes="svg-icon" title="place"/>
                      </Link>
                      :
                      <a className={"btn btn-secondary "}
                         target="_blank"
                         rel="noopener noreferrer"
                         href={`${promotionCurrent.coupon.redeem_link}company/${promotionCurrent.company_retail.uid}/code/${promotionCurrent.coupon.code}/`}>
                        <Icon svg="place" classes="svg-icon" title="place"/>
                      </a>
                    )
                  }
                </div>
            )
          } </Fragment>
        }

        {(statusDetailCoupon === STATUS_SUCCESS && couponDetail && promotionCurrent.coupon == null) ?
          <div className={"coupon-container"}>
            <div className={"coupon"}>
              <p>{couponDetail.coupon_code}</p>
            </div>
            <button className={"btn btn-secondary btn-copy"}
                    onClick={e => this.copyToClipboard(e, couponDetail.coupon_code)}>
              <Icon svg="copy" classes="svg-icon" title="copy"/>
            </button>
            {couponDetail.redeem_link && promotionCurrent &&
              (this.isExistInMultisite() ?
                  <Link className={"btn btn-secondary "}
                        to={`/company/${promotionCurrent.company_retail.uid}/code/${couponDetail.coupon_code}/`}>
                    <Icon svg="place" classes="svg-icon" title="place"/>
                  </Link>
                  :
                  <a className={"btn btn-secondary "}
                     target="_blank"
                     rel="noopener noreferrer"
                     href={`${couponDetail.redeem_link}company/${promotionCurrent.company_retail.uid}/code/${couponDetail.coupon_code}/`}>
                    <Icon svg="place" classes="svg-icon" title="place"/>
                  </a>
              )
            }
          </div>
          :
          (statusDetailCoupon === STATUS_ERROR &&
            <div className={"coupon-container "}>
              <p>
                {errorDetailCoupon &&
                (errorDetailCoupon.detail ? <span> {errorDetailCoupon.detail}</span> :
                  <span>{errorDetailCoupon.non_field_errors && errorDetailCoupon.non_field_errors[0]}</span>)
                }
              </p>
            </div>
          )
        }
      </Fragment>);
  }

}

