import React, { Component, Fragment } from 'react'
import ProgramBanner from "../programDetail/ProgramBanner"
import ProgramPromotionsList from './ProgramPromotionsList';
import PropTypes from "prop-types";
import ProgramContainer from "../ProgramContainer";
import {STATUS_INCLUDE, STATUS_SUCCESS} from "../../../common/utils/generic";

export default class ProgramPromotionsListDiscount extends Component {
    constructor(props) {
        super(props);
        this.state = {
          terms_conditions: false,
          stampsExist: false,
          uidProgram: '',
          selectedAreaUid: '',
          program:null
        };
        // console.log(this.props,"more new");
      }
  componentDidMount() {
    const {changeSecondaryMenu, changeStateCart} = this.props
    changeSecondaryMenu()
    changeStateCart()
  }
  existProgram = (uid) =>{
    const {
      app
    } = this.props
    if(app && app.allowed_programs) {
      for(let i =0;i<app.allowed_programs.length;i++){
        if(app.allowed_programs[i].uid===uid)
          return true
      }
    }
    return false
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    this.updateComponent()
  }

  updateComponent() {
    const {
      match: {params: {uid}},
      getSections,
      getProgram,
      selectedAreaUid,
      getPromotions,
      getAds,
      getRaffles,
      programs: {
        entity: program,
        retrieveStatus: retrieveStatusProgram,
        addStatus
      },
      type
    } = this.props

    if (uid && uid !== this.state.uidProgram ) {
      if(this.existProgram(uid)){
        this.setState({uidProgram:uid})
        getProgram(uid);
        getAds(uid, {type: "POP_UP"})
        getRaffles(uid)
      }
    }
    if(retrieveStatusProgram === STATUS_SUCCESS && program!=null){
      if(this.state.program == null ||
        ( this.state.program && program.uid !== this.state.program.uid )
        || this.state.selectedAreaUid !== selectedAreaUid
      ){
        this.setState({selectedAreaUid})
        this.setState({program: program})
        getPromotions(uid, {area:selectedAreaUid, type: type});
        getSections(uid);
      }
    }
    if(addStatus === STATUS_SUCCESS && retrieveStatusProgram === STATUS_INCLUDE && program!=null  ){
      getProgram(uid);
    }
  }
  render() {
    let {
        match: {params: {uid}},
        app,
        company,
        multisite,
        programs: {
          entity: program,
          addStatus,
          retrieveStatus: retrieveStatusProgram
        },
        promotions:{
          entities: programPromotionsEntities,
          results: programPromotionsResults,
          listStatus,
          listNextStatus,
          nextPage,
          count
        },
        // sections:{
        //   entities: sectionsEntities,
        //   results: sectionsResults,
        //   listStatus: sectionsStatus,
        // },
        // getPromotions,
        addProgram,
        getMoreProgramPromotions,
        
      } = this.props;
      console.log(this.props,"list");
    return (
      <Fragment>
         {program && <>
          <ProgramBanner
          app = {app}
          addProgramUser = {()=> addProgram(uid)}
          company = {company}
          addStatus = {addStatus}
          retrieveStatus={retrieveStatusProgram}
          program={program}
        />
        <div >
        <ProgramPromotionsList
            programPromotions={programPromotionsResults.map(cUid => programPromotionsEntities[cUid])}
            count = {count}
            multisite = {multisite}
            company={company}
            nextPagePromotions={nextPage}
            listProgramPromotionsStatus={listStatus}
            listNextProgramPromotionsStatus={listNextStatus}
            getMoreProgramPromotions = {getMoreProgramPromotions}
            programUid = {uid}
            countDiscount={999}
            moreDiscount={false}
            stampShow={true}
            app = {app}
            addProgramUser = {()=> addProgram(uid)}
            addStatus = {addStatus}
            retrieveStatus={retrieveStatusProgram}
            program={program}
            exchangeSection={false}
          />
        </div>
         </> }
      </Fragment>
    )
  }
}
ProgramContainer.defaultProps = {
  programs: null,
  app: null,
  promotions: null,
};

ProgramContainer.propTypes = {
  app: PropTypes.object,
  programs: PropTypes.object,
  promotions: PropTypes.object,
  getProgram: PropTypes.func,
  getSections: PropTypes.func,
  getPromotions: PropTypes.func,
  getMoreProgramPromotions: PropTypes.func,
};
