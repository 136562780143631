/*
 * HomeConstants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'yourproject/YourComponent' so we avoid
 * reducersapp accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'yourproject/YourContainer/YOUR_ACTION_CONSTANT';
 */


export const BTN_REGISTER = 'Registrar';
export const BTN_REGISTER_LOADING = 'Registrando...';
export const RECAPTCHA_SITE_KEY = "6LePoAAVAAAAAITzYqKrI1NnRxnCesl-Bu69Xd1U";
