import HttpClient from "../common/utils/httpClient";

export const GET_PROMOTIONS= 'GET_PROMOTIONS';
export const GET_SLUG= 'GET_SLUG';
export const GET_QR_PROMOTION= 'GET_QR_PROMOTION';
export const GET_DETAIL_PROMOTION= 'GET_DETAIL_PROMOTION';
export const APPEND_PROMOTIONS= 'APPEND_PROMOTIONS';
export const PAY_PROGRAM_PROMOTION = "PAY_PROGRAM_PROMOTION";
export const PAY_PROGRAM_PROMOTION_CLEAN = "PAY_PROGRAM_PROMOTION_CLEAN";
export const GET_PROMOTION= 'GET_PROMOTION';
export const GET_PAGE_PROMOTIONS = 'GET_PAGE_PROMOTIONS';
export const CLEAN_PROMOTION = 'CLEAN_PROMOTION';


/**Urls**/
export const LIST_PROMOTIONS_URL = 'v5/me/programs/{program_id}/promotions/';
export const GET_BY_SLUG_URL = 'v5/me/program-promotions/{slug}/';
export const GENERATE_QR_URL = 'v5/me/programs/{program_id}/promotions/{promotion_id}/qrcode/';
export const DETAIL_PROGRAM_PROMOTIONS_URL = 'v7/programs/{program_id}/promotions/{promotion_id}/';

export const LIST_PAGE_PROMOTIONS_URL = 'v5/me/programs/{program_id}/promotions/?page={page_uid}';


export function getPromotions(programUid, params) {
  let filters = "";
  let aux = ""
  for (let key of Object.keys(params)) {
    if(params[key]!=null && params[key]!==""){
      if (filters.length > 1){
        filters += `&`;
        aux += `&`;
      }
      if(params[key]!=="?tagged=true"){
        aux += `${key}=${params[key]}`
      }
      if(key==="ordering" && params[key]==="rate_discount"){
        filters += `${key}=-${params[key]}`
      }else{
        filters += `${key}=${params[key]}`
      }
    }
  }
  let url = LIST_PROMOTIONS_URL.replace("{program_id}",programUid)

  if(filters.includes("?tagged=true")){
    if(filters.length>0){
      url = `${url}?tagged=true&${aux}`;
    }else{
      url = `${url}?tagged=true`;
    }
  }else{
    if(filters.length>0)
      url = `${url}?${filters}`;
  }
  url = `${url}&page_size=24`;
  return (dispatch) => HttpClient.get(dispatch, GET_PROMOTIONS, url);
}

export function getNextPromotions(nextPageUri) {
  return (dispatch) => HttpClient.get(dispatch, APPEND_PROMOTIONS, nextPageUri, false);
}
export function getSlugPromotion(slug) {
  let url = GET_BY_SLUG_URL.replace("{slug}",slug)
  return (dispatch) => HttpClient.get(dispatch, GET_SLUG, url);
}

export function getNumberPagePromotions(uid, areaUid, pageUid, search = "", ordering = "", section = "") {
  let url = LIST_PAGE_PROMOTIONS_URL.replace('{program_id}', uid).replace('{page_uid}', pageUid);
  let type = GET_PROMOTIONS;
  if (areaUid.length!==0 || search.length!==0 || ordering.length!==0 || section.length!==0) {
    url = LIST_PROMOTIONS_URL.replace("{program_id}",uid);
    if(areaUid.length!==0)
      url = `${url}?area=${areaUid}`;
    if(search.length!==0)
      url = `${url}${areaUid.length===0 ?  "&" : "?"}search=${search}`;
    if(ordering.length!==0){

      if(ordering==="rate_discount"){
        url = `${url}${(areaUid.length===0 || search.length===0) ?  "&" : "?"}ordering=-${ordering}`;
      }else{
        url = `${url}${(areaUid.length===0 || search.length===0) ?  "&" : "?"}ordering=${ordering}`;
      }
    }
    if(section && section.length!==0)
      url = `${url}${(areaUid.length===0 || search.length=== 0 || ordering.length===0) ? "&" : "?"}sections=${section}`;
    //console.log(areaUid, search, ordering, (areaUid.length===0 || search.length=== 0 || ordering.length===0))
    url = `${url}&page=${pageUid}`;
    type = GET_PROMOTIONS;
  }
  url = `${url}&page_size=24`;
  return (dispatch) => HttpClient.get(dispatch, type, url, true);
}


export function getPagePromotions(pageUri) {
  return (dispatch) => HttpClient.get(dispatch, GET_PROMOTIONS, pageUri, false);
}

export function getPromotion(programUid, promotionUid) {
  let url = LIST_PROMOTIONS_URL.replace("{program_id}",programUid)
  url = `${url}${promotionUid}/`
  return (dispatch) => HttpClient.get(dispatch, GET_PROMOTION, url);
}
export function getDetailPromotion(programUid, promotionUid) {
  let url = DETAIL_PROGRAM_PROMOTIONS_URL.replace("{program_id}",programUid).replace("{promotion_id}",promotionUid)
  return (dispatch) => HttpClient.get(dispatch, GET_DETAIL_PROMOTION, url);
}

export function generateQR(programUid, promotionUid) {
  let url = GENERATE_QR_URL.replace("{program_id}",programUid).replace("{promotion_id}",promotionUid)
  return (dispatch) => HttpClient.post({}, dispatch, GET_QR_PROMOTION, url);
}

export function payPromotion(data, programUid, promotionUid) {
  return dispatch => HttpClient.post( data, dispatch, PAY_PROGRAM_PROMOTION, `v5/me/programs/${programUid}/promotions/${promotionUid}/purchase/`);
}

export function cleanPayment() {
  return {
    type: PAY_PROGRAM_PROMOTION_CLEAN
  }
}

export function cleanPromotion() {
  return {
    type: CLEAN_PROMOTION,
  };
}