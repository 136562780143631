import React, {Fragment} from 'react'
import PropTypes from 'prop-types';
import InfiniteScroll from "react-infinite-scroller";
import {STATUS_INCLUDE, STATUS_LOADING, STATUS_SUCCESS} from "../../../common/utils/generic";
import ProductColumn from "./components/productCard/Product";
import './style.scss';
import NoSearchResult from "../../../common/images/NoSearchResult.svg";
import ProductPopUp from "./components/productPopUp";
import $ from "jquery";
import ProductsPlaceFilters from "../ProductsPlaceFilters/ProductsPlaceFilters";
import Banner from "../../bederr/Banner";

export default class ProductsPlaceList extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      uidProduct: ""
    }
  }


  componentDidMount() {
    this.updateComponent()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if ( $(".modal-backdrop").length > 1 ) {
      $('.modal-backdrop:first').remove();
    }
    this.updateComponent()
  }
  updateComponent() {
    const {
      getSectionsToProducts,
      getProductsByPlace,
      selectedPlace,
      programs,
      getRaffles,
      company,

      //programRoot
      programUid: uid,
      getSections,
      getProgram,
      selectedAreaUid,
      getPromotions,
      getAds,
      programs: {
        entity: program,
        retrieveStatus: retrieveStatusProgram,
        addStatus,
      },
    } = this.props;
    if (selectedPlace) {
      if (this.state.placeUid !== selectedPlace.uid) {
        this.setState({placeUid: selectedPlace.uid})
        if (selectedPlace.uid !== null) {
          getProductsByPlace(selectedPlace.uid);
          getSectionsToProducts(selectedPlace.uid);
        }
      }
    }
    if(programs && programs.results.length!==0){
      if(this.state.programUid!==programs.results[0]){
        this.setState({programUid: programs.results[0]})
        getRaffles(programs.results[0])
      }
    }
    if(company.ecommerce_enabled!==this.state.isEcommerceEnabled){
      this.setState({isEcommerceEnabled: company.ecommerce_enabled})
    }


    //programRoot
    if (uid && uid !== this.state.uidProgram) {
      if (this.existProgram(uid)) {
        this.setState({ uidProgram: uid });
        getProgram(uid);
        getAds(uid, { type: "POP_UP" });
        getRaffles(uid);
      }
    }
    if (retrieveStatusProgram === STATUS_SUCCESS && program != null) {
      if (
        this.state.program == null ||
        (this.state.program && program.uid !== this.state.program.uid) ||
        this.state.selectedAreaUid !== selectedAreaUid
      ) {
        this.setState({ selectedAreaUid });
        this.setState({ program: program });
        getPromotions(uid, { area: selectedAreaUid });
        getSections(uid);
      }
    }
    if (
      addStatus === STATUS_SUCCESS &&
      retrieveStatusProgram === STATUS_INCLUDE &&
      program != null
    ) {
      getProgram(uid);
    }
    if (selectedPlace !== null && selectedPlace) {
      if (this.state.placeUid !== selectedPlace.uid) {
        this.setState({ placeUid: selectedPlace.uid });
        if (selectedPlace.uid !== null) {
          getProductsByPlace(selectedPlace.uid);
          // getSectionsToProducts(selectedPlace.uid);
        }
      }
    }
  }

  loadMore = () => {

    const {nextPageProducts, listNextProductsByPlaceStatus, listProductsByPlaceStatus, getMoreProductsByPlace} = this.props;
    if (listNextProductsByPlaceStatus !== STATUS_LOADING && listProductsByPlaceStatus !== STATUS_LOADING && nextPageProducts != null) {
      getMoreProductsByPlace(nextPageProducts);
    }
  };
  renderPopUp = () => {
    let {multisite, cleanCart,history, authStatus, productsByPlaceCurrent, productsByPlaceCurrentStatus, openPopUp, activePopUp, componentPopUp, getProductCurrent, placeUid,
      addProductCart, selectedPlace, company, entitiesCart, addProductsBack,updateProductCart, currency, typeCart} = this.props;

    if (this.state.uidProduct !== "") {

      let listOptionsCartProduct = []
      if(entitiesCart && entitiesCart.products !== undefined){
        Object.keys(entitiesCart.products).map(p => {
          if(this.state.uidProduct===entitiesCart.products[p].id){
            if(entitiesCart.products[p].length!==0){
              if(entitiesCart.products[p].option_groups){
                listOptionsCartProduct = entitiesCart.products[p].option_groups
              }
            }
          }
          return true;
        })
      }
      return (
        <ProductPopUp
          productsByPlaceCurrent={productsByPlaceCurrent}
          productsByPlaceCurrentStatus={productsByPlaceCurrentStatus}
          openPopUp={openPopUp}
          activePopUp={activePopUp}
          getProductCurrent={getProductCurrent}
          placeUid={placeUid}
          setProductUid={this.setProductUid}
          uid={this.state.uidProduct}
          componentPopUp={componentPopUp}
          addProductCart={addProductCart}
          addProductsBack ={addProductsBack }
          selectedPlace={selectedPlace}
          entitiesCart={entitiesCart}
          authStatus={authStatus}
          updateProductCart={updateProductCart}
          currency={currency}
          typeCart={typeCart}
          listOptionsCartProduct={listOptionsCartProduct}
          company={company}
          history={history}
          cleanCart={cleanCart}
          multisite={multisite}
        />
      );
    }
  }

  setProductUid = (uid) => {
    this.setState({
      uidProduct: uid
    })
  };

  renderProductsPlace() {
    const {productsPlace, listProductsByPlaceStatus, count, placeUid, openPopUp, currency,newStyle} = this.props;

    if (listProductsByPlaceStatus === STATUS_LOADING) {
      return <Fragment>
        <ProductColumn loading={true}/>
        <ProductColumn loading={true}/>
        <ProductColumn loading={true}/>
        <ProductColumn loading={true}/>
        <ProductColumn loading={true}/>
        <ProductColumn loading={true}/>
      </Fragment>;
    }

    if (count === 0 && listProductsByPlaceStatus === STATUS_SUCCESS) {
      return <div className={"mx-auto"}>
        <div className={"d-flex flex-column justify-content-center align-items-center"}>
          <img src={NoSearchResult} alt="noItems"/>
          <p className={"mt-3"}>No se encuentran resultados para esta búsqueda</p>
        </div>
      </div>
    }
    // eslint-disable-next-line
    return productsPlace.map((productPlace, index) => 
      {
          return <ProductColumn
          key={`promo${index}`}
          placeUid={placeUid}
          product={productPlace}
          loading={false}
          openPopUp={openPopUp}
          setProductUid={this.setProductUid}
          currency={currency}
          newStyle={newStyle}
        />
      }
      
    );

  }

  renderProductsPlaceList() {
    const {productsPlace, listProductsByPlaceStatus, count, placeUid, openPopUp, currency,newStyle} = this.props;

    if (listProductsByPlaceStatus === STATUS_LOADING) {
      return <Fragment>
        <ProductColumn loading={true}/>
        <ProductColumn loading={true}/>
        <ProductColumn loading={true}/>
        <ProductColumn loading={true}/>
      </Fragment>;
    }

    if (count === 0 && listProductsByPlaceStatus === STATUS_SUCCESS) {
      return <div className={"mx-auto"}>
        <div className={"d-flex flex-column justify-content-center align-items-center"}>
          <img src={NoSearchResult} alt="noItems"/>
          <p className={"mt-3"}>No se encuentran resultados para esta búsqueda</p>
        </div>
      </div>
    }
    // eslint-disable-next-line
    return productsPlace.map((productPlace, index) =>
      {
          return <ProductColumn
          key={`promo${index}`}
          placeUid={placeUid}
          product={productPlace}
          loading={false}
          openPopUp={openPopUp}
          setProductUid={this.setProductUid}
          currency={currency}
          newStyle={newStyle}
        />
      }

    );

  }

  render() {
    let {nextPageProducts, componentPopUp, sections, newStyle,
      sectionsStatus, getProductsByPlace,

      app,
      company,
      programs: {
        entity: program,
        addStatus,
        retrieveStatus: retrieveStatusProgram,
      },
      selectedPlace,
      history
    } = this.props;
    let more = nextPageProducts != null;
    let loaderMore = (
      <Fragment key={"load"}>
        <ProductColumn loading={true}/>
        <ProductColumn loading={true}/>
        <ProductColumn loading={true}/>
      </Fragment>
    );
    let noTodo = false

    return (
      <div>
        <Banner
          history={history}
          app={app}
          company={company}
          addStatus={addStatus}
          retrieveStatus={retrieveStatusProgram}
          program={program}
          selectedPlace={selectedPlace}
        />

        <ProductsPlaceFilters
          sections={sections}
          listSectionsStatus={sectionsStatus}
          selectedPlace={selectedPlace}
          getProductsByPlace={(params) => {
            if (selectedPlace.uid) getProductsByPlace(selectedPlace.uid, params)
          }}
        />

        <div className={"container"} >

          {
            sections && (
              sections.map((section, index) => {
                if(localStorage.getItem("sectionSearchProduct")===section.uid) {
                  noTodo = true
                  return <h3
                    key={`category${index}`} className={"row category-title mt-2"}>{section.name}</h3>
                }
                return true
              })
            )
          }
          {
            !noTodo && <h3 className={"row category-title mt-2"}>Todo</h3>
          }

          <InfiniteScroll
            className={newStyle ? "exchange-list" : "row product-list mb-4"}
            pageStart={0}
            loadMore={this.loadMore.bind(this)}
            hasMore={more}
            loader={loaderMore}
          >
            {this.renderProductsPlace()}
          </InfiniteScroll>

          {
            (componentPopUp=== "productPopUp") &&
            this.renderPopUp()
          }
        </div>

      </div>
    );
  }
}

ProductsPlaceList.defaultProps = {
  productsPlace: [],
  listProductsByPlaceStatus: '',
  count: 0,
  nextPageProducts: null
};

ProductsPlaceList.propTypes = {
  productsPlace: PropTypes.array,
  nextPageProducts: PropTypes.string,
  getMoreProductsByPlace: PropTypes.func,
  listNextProductsByPlaceStatus: PropTypes.string,
  placeUid: PropTypes.string,
  count: PropTypes.number,
  listProductsByPlaceStatus: PropTypes.string
};

