import React, {Fragment} from 'react'
import PropTypes from 'prop-types';
import './style.scss';
import logo from "../../../common/images/logo.png";
import {STATUS_LOADING, STATUS_SUCCESS} from "../../../common/utils/generic";
import Footer from "../../footer/Footer";
import {CART_INVISIBLE} from "../../../actions/cart";
const $ = require('jquery');

export default class Politics extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      appUid: "",
      privacy_policies: ""
    }
  }
  componentDidMount() {
    const {changeSecondaryMenu, changeStateCart} = this.props
    if(changeSecondaryMenu && changeStateCart){
      changeSecondaryMenu()
      changeStateCart()
    }
    this.updateComponent()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.updateComponent()
  }

  updateComponent(){
    let {app, getApp, appFields, changeSecondaryMenu, changeStateCart, stateCart} = this.props;
    if(app.uid !== this.state.appUid){
      this.setState({appUid: app.uid})
      getApp(app.uid)
    }
    if(appFields && appFields.site && appFields.site.privacy_policies){
      if(appFields.site.privacy_policies !== this.state.privacy_policies){
        this.setState({privacy_policies: appFields.site.privacy_policies})
        $("#politics-box").html(`${appFields.site.privacy_policies}`)
      }
    }

    if(stateCart!=="CART_INVISIBLE" && changeSecondaryMenu && changeStateCart){
      changeSecondaryMenu()
      changeStateCart()
    }
  }

  renderPolitics() {
    let {appFields, statusAppFields} = this.props;
    console.log(appFields && appFields.site, "appFields")
    return (
      <div className="row mx-auto col-12 col-md-10 col-lg-10 politics-box pt-4" id={"politics-box"}>
      {
        statusAppFields===STATUS_SUCCESS ?
        !(appFields && appFields.site && appFields.site.privacy_policies) &&
          <div>
            <p>AVISO DE PRIVACIDAD</p>
            <p>
              <small>Fecha de Publicación: 1 de abril de 2020</small><br/>
              <small>Fecha de Última Actualización: 25 de julio de 2020</small>
            </p>
            <p>POR FAVOR LEA CUIDADOSAMENTE LOS TÉRMINOS Y CONDICIONES ESTABLECIDOS EN EL PRESENTE AVISO DE
              PRIVACIDAD.</p>
            <p>
              Bederr INC, (en lo sucesivo “Bederr”) valora su privacidad y está comprometida en proteger la información
              personal (“Datos Personales”) que se recaben en el transcurso de nuestra relación comercial o de
              servicios con nuestros usuarios, clientes, proveedores, prestadores de servicios y público en general
              (“Titulares” o “Titular”). En tal sentido, Bederr en cumplimiento a lo dispuesto por la Ley Federal de
              Protección de Datos Personales en Posesión de los Particulares (en adelante “Ley de Datos Personales”),
              así como su Reglamento y Lineamientos del Aviso de Privacidad, actuando como Responsable de la
              recopilación y tratamiento de sus Datos Personales, hace de su conocimiento el presente Aviso de
              Privacidad (“Aviso”), el cual puede ser consultado de manera física en nuestras oficinas y sucursales,
              así como en el sitio web www.bederr.com, así como la aplicación móvil “Bederr”.
            </p>
            <p>En consecuencia, este Aviso describe sus derechos respecto de la recopilación, uso, almacenamiento,
              tratamiento, transferencia y protección de sus Datos Personales por parte de Bederr. Este Aviso aplica al
              recabamiento de Datos Personales de manera directa, incluyendo el acceso a nuestras oficinas, así como a
              cualquier sitio web propiedad de Bederr, incluyendo sitios relacionados, aplicaciones móviles,
              plataformas digitales y herramientas propiedad de Bederr, independientemente de la forma y lugar de
              acceso. </p>
            <p>Responsable de los Datos Personales Recabados </p>
            <p>El “Responsable” de los Datos Personales, en términos de lo establecido en la Ley de Datos Personales, es
              Bederr INC, con domicilio en Av. Alfredo Benavides 1180, Piso 6, Miraflores, Lima, Perú, y página web
              www.bederr.com. </p>
            <p>Bederr mantiene medidas de seguridad administrativas, técnicas y físicas necesarias, entre ellas el
              acceso limitado a las bases de datos, uso de contraseñas y hardware o archivos restringidos, que permiten
              garantizar la privacidad y protección de los datos personales contra daño, pérdida, alteración,
              destrucción o el uso, acceso o tratamiento no autorizado de los mismos.</p>


            <p>IDatos Personales Recabados </p>
            <p>
              Bederr sólo tendrá acceso a la información que el Titular proporcione voluntariamente de manera personal
              y directa; a través de correo electrónico, aplicación móvil u otro contacto directo de manera digital
              remota con el Titular, incluyendo el uso del sitio web de Bederr; a través de llamadas telefónicas, así
              como por medio de referencias de terceros, contactos familiares u otros medios electrónicos. Por tanto,
              Bederr únicamente podrá recabar y almacenar los siguientes datos personales del Titular, con el fin de
              llevar a cabo las finalidades descritas en el presente aviso de privacidad:
            </p>

            <ul style={{listStyle: "none"}}>
              <li><strong>Datos de Identificación.</strong> Bederr podrá recabar los siguientes datos de
                identificación de los Titulares:
              </li>
              <li>
                <ul>
                  <li>Nombre Completo; o Fecha y Lugar de Nacimiento; o Nacionalidad; o Edad</li>
                </ul>
              </li>
            </ul>
            <ul style={{listStyle: "none"}}>
              <li><strong>Datos de contacto.</strong> Bederr podrá recabar los siguientes datos de contac</li>
              <li>
                <ul>
                  <li>Domicilio; o Correo electrónico; o Teléfono fijo o celular.</li>
                </ul>
              </li>
            </ul>
            <ul style={{listStyle: "none"}}>
              <li><strong>Datos bancarios y/o financieros. </strong> Bederr podrá recabar los siguientes datos
                bancarios y/o financieros:
              </li>
              <li>
                <ul>
                  <li>Datos de identificación de instrumento financiero (nombre del titular, número de serie,
                    fecha de caducidad, código CVV), sea tarjeta de débito o crédito.
                  </li>
                </ul>
              </li>
            </ul>

            <p>
              Asimismo, el Titular podrá optar por proporcionar a Bederr acceso a cierta información personal
              almacenada por terceros, tales como sitios de redes sociales, incluyendo de manera enunciativa más no
              limitativa a Facebook®, Twitter®, Google®, LinkedIn®, así como credenciales de acceso y registro o inicio
              de sesión a través de sus perfiles personales. La información personal a la que se podrá tener acceso
              varía según el sitio web, la misma está controlada mediante la configuración de privacidad de cada sitio
              web y mediante el consentimiento de cada Titular. En caso de asociar la información de cualquiera de las
              cuentas administradas por terceros, el Titular autoriza a Bederr a tener acceso a su información y poder
              recopilar, utilizar, almacenar dicha información de dichos sitios web, de conformidad con este Aviso.
            </p>
            <p>El tratamiento de los Datos Personales Recabados será el que resulte necesario, adecuado y relevante para
              el cumplimiento de las finalidades mencionadas en el presente Aviso, así como para las finalidades
              adicionales descritas en este documento.
            </p>
            <p>
              Todos los Datos Personales Recabados de los Titulares, con excepción de los bancarios y/o financieros,
              serán almacenados en la base de datos o base de almacenamiento y recopilación de información de Bederr
              (en lo sucesivo la “Base de Datos”), misma que cuenta con altas medidas de seguridad para garantizar la
              privacidad y protección de los Datos Personales Recabados almacenados en ella contra daño, pérdida,
              alteración, destrucción o el uso, acceso o tratamiento no autorizado de los mismos. Bederr almacenará los
              Datos Personales Recabados durante el tiempo que sea necesario para cumplir con la finalidad para la cual
              fueron recabados así como durante el tiempo permitido en la legislación aplicable.
            </p>
            <p>
              En consecuencia, los Datos Personales se encuentran protegidos tanto en línea, mediante códigos de
              cifrado; como fuera de línea, ya que sólo el personal designado para realizar alguna tarea específica con
              los Datos Personales tiene acceso a la Base de Datos.
            </p>
            <p>
              Bederr podrá comunicarse con el Titular por medio de correo electrónico, vía telefónica, WhatsApp® o
              algún otro medio autorizado a fin de informarle sobre nuevos productos o servicios, así como sobre algún
              cambio sobre las reglas operativas de los productos y/o al presente Aviso.
            </p>

            <p>Finalidad de los Datos Personales Recabados almacenados en la Base de Datos </p>
            <p>Los Datos Personales Recabados serán utilizados por parte de Bederr para las siguientes finalidades:
            </p>

            <p>
              Finalidades Principales.
            </p>
            <ul>
              <li>Registro de cuenta en línea en la plataforma de Bederr.</li>
              <li>Proporcionar los servicios y/o productos de Bederr y los comercios asociados al Titular.</li>
              <li>Uso de la plataforma digital y aplicación móvil de Bederr.</li>
              <li>Realizar pagos o compras de servicios de Bederr y/o de los comercios asociados a Bederr.</li>
              <li>Recepción de noticias, ofertas, promociones e información de Bederr y los comercios asociados.</li>
              <li>Procesamiento, actualización, modificación, cancelación y/o confirmación de los servicios, productos
                e información enviada por Bederr a los Titulares a través del sitio web, aplicaciones móviles,
                llamadas telefónicas y/o correo electrónico.
              </li>
              <li>Dar cumplimiento a las obligaciones contraídas por el Titular con Bederr.</li>
            </ul>
            <p>Finalidades Secundarias. </p>
            <p>Asimismo, los Datos Personales Recabados podrán ser utilizados para las siguientes finalidades
              adicionales, que no se relacionan directamente con los servicios prestados o productos de Bederr, pero
              que ayudan a Bederr a brindar una mejor atención a los Titulares: </p>
            <ul>
              <li>Gestión, administración, ampliación, mejora y seguimiento de los servicios que Bederr preste a los
                Titulares;
              </li>
              <li>Dar respuesta a las consultas planteadas por los Titulares acerca de los productos y los servicios de
                Bederr.
              </li>
              <li>Evaluación y encuestas de calidad de los productor y/o servicios;</li>
              <li>Recepción de boletines informativos de Bederr, así como recepción de información con fines de
                mercadotecnia, publicidad y prospección comercial.
              </li>
            </ul>
            <p>
              Los Titulares de los Datos Personales de manera inmediata podrán solicitar en cualquier momento la
              cancelación del envío de correos electrónicos publicitarios por parte de Bederr, mediante el link
              “Cancelar suscripción” que se encuentra inserto en los correos electrónicos que Bederr envía a los
              Titulares.
            </p>
            <p>
              Para el caso en que no desee que sus datos personales sean tratados de conformidad con estas finalidades,
              desde este momento usted puede comunicarse a la dirección de correo electrónico team@Bederr.com siguiendo
              el procedimiento descrito en este Aviso.
            </p>
            <p>Finalidad de los Datos bancarios y/o financieros</p>
            <p>Los Datos bancarios y/op financieros serán utilizados por parte de Bederr con la única finalidad de
              realizar pagos o compras de servicios de Bederr y/o de los comercios asociados a Bederr.</p>
            <p>Revelación de los Resultados Obtenidos y Transferencia de los Datos Personales Recabados almacenados en
              la Base de Datos</p>
            <p>Bederr podrá revelar los Datos Personales Recabados de los Usuarios y que estén almacenados en la Base de
              Datos respecto a los servicios que preste Bederr con otros proveedores de servicios dentro de los
              servicios y programas adquiridos por el Titular, previo procedimiento de disociación, a personas
              distintas de Bederr, que se encuentren dentro o fuera de México (los clientes de Bederr). </p>
            <p>Asimismo, en atención a lo dispuesto por la Ley de Datos Personales, Bederr podrá transferir y/o
              compartir los Datos Personales Recabados de los Titulares, dentro y fuera del país con las siguientes
              personas, empresas, organizaciones y autoridades distintas a Bederr, para los siguientes fines: </p>

            <table className="table table-bordered">
              <thead>
              <tr>
                <th scope="col">Destinatario de los datos personales</th>
                <th scope="col">Finalidad</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>
                  Sociedades controladoras, subsidiarias o afiliadas bajo el control de Bederr, o a una sociedad
                  matriz o a cualquier sociedad del mismo grupo de Bederr que opere bajo los mismos procesos y
                  políticas internas.
                </td>
                <td>Para llevar a cabo la promoción y ofrecimiento de sus productos y servicios.
                </td>
              </tr>
              <tr>
                <td>
                  Terceros que tengan celebrados convenios de colaboración con Bederr.
                </td>
                <td>Para llevar a cabo la promoción y ofrecimiento de sus productos y servicios. *</td>
              </tr>
              <tr>
                <td>
                  Autoridades a las que Bederr deba realizarlo por virtud de ley.
                </td>
                <td>Cumplir con la normatividad correspondiente</td>
              </tr>
              </tbody>
            </table>
            <p>
              Le informamos que para las transferencias indicadas con un asterisco (*) requerimos obtener su
              consentimiento. Si usted no manifiesta su negativa para dichas transferencias, entenderemos que nos lo ha
              otorgado.
            </p>
            <p>En caso que Bederr sea adquirido por un tercero, los Datos Personales Recabados almacenados en la Base de
              Datos serán transferidos al adquirente de Bederr. En ese caso, el adquirente, como nuevo Responsable del
              tratamiento de sus Datos Personales, seguirá manteniendo las medidas de seguridad administrativas,
              técnicas y físicas necesarias que permitan garantizar la privacidad y protección de los Datos Personales
              Recabados almacenados en la Base de Datos del Titular contra daño, pérdida, alteración, destrucción o el
              uso, acceso o tratamiento no autorizado de los mismos de conformidad con lo establecido en el presente
              Aviso de Privacidad. </p>

            <p>Acceso y Control sobre los Datos Personales Recabados almacenados en la Base de Datos</p>
            <p>En cualquier momento, los Titulares o sus representantes legales podrán ejercer libremente los siguientes
              derechos ARCO: revocar su consentimiento para el tratamiento o uso de sus Datos Personales por parte de
              Bederr, limitar su uso o divulgación, así como acceder a ellos, rectificarlos en caso de que sean
              inexactos o incompletos, cancelarlos y oponerse a su tratamiento en su totalidad o para ciertos fines del
              presente Aviso de Privacidad, así como para su uso con fines de mercadotecnia, publicidad y prospección
              comercial.
            </p>
            <p>
              En tal caso, el Titular deberá contactar al área de protección de datos de Bederr al correo electrónico
              team@Bederr.com y siguiendo el procedimiento señalado en los párrafos siguientes.
            </p>
            <p>Los Titulares deberán señalar expresa y detalladamente: (i) que desean revocar su consentimiento para el
              tratamiento o uso de sus Datos Personales; (ii) señalar la manera mediante la cual desea limitar el uso o
              divulgación de los Datos Personales; (iii) señalar la manera en que desean acceder o rectificar sus Datos
              Personales; (iv) señalar que desean cancelar sus Datos Personales; y/o (v) señalar que desean oponerse al
              tratamiento de sus Datos Personales.
            </p>
            <p>Para ejercer los derechos antes descritos, los Titulares deberán acompañar a su solicitud lo
              siguiente: </p>
            <ul style={{listStyle: "none"}}>
              <li>a) El nombre del Titular, correo electrónico, domicilio u otro medio para comunicarle la respuesta a
                su solicitud.
              </li>
              <li>b) Una copia de los documentos que acrediten la identidad o, en su caso, la representación legal del
                Titular.
              </li>
              <li>c) La descripción clara y precisa de los Datos Personales respecto de los que se busca ejercer alguno
                de los derechos antes mencionados.
              </li>
              <li>d) Cualquier otro elemento o documento que facilite la localización de los Datos Personales.</li>
            </ul>
            <p>Para el caso del presente Aviso, la respuesta a la solicitud del Titular se realizará primeramente en el
              correo electrónico proporcionado y, salvo cuando así lo especifique el Titular, se realizará en el
              domicilio señalado.
            </p>
            <p>En el caso de solicitudes de rectificación de Datos Personales, el Titular deberá indicar en la
              solicitud, además de lo arriba señalado, las modificaciones a realizarse y aportar la documentación que
              sustente su petición. </p>
            <p>Bederr responderá toda solicitud en un plazo máximo de 20 (veinte) días hábiles contados desde la fecha
              de presentación de la solicitud correspondiente, a efecto de que, si resulta procedente, se haga efectiva
              la misma dentro de los 15 (quince) días hábiles siguientes a la fecha en que se comunique la
              respuesta. </p>
            <p>Tratándose de solicitudes de acceso a Datos Personales procederá la entrega, previa acreditación de la
              identidad del solicitante o representante legal, según corresponda.
              Los plazos antes referidos podrán ser ampliados una sola vez por un periodo igual, siempre y cuando así
              lo justifiquen las circunstancias del caso.
            </p>
            <p>Uso de Cookies y Web Beacons </p>
            <p>Las cookies son archivos de texto que son descargados automáticamente y almacenados en el disco duro del
              equipo de cómputo de un usuario al navegar en una página de Internet específica, que permiten recordar al
              servidor de Internet algunos datos sobre un usuario, entre ellos, sus preferencias para la visualización
              de las páginas en ese servidor, nombre y contraseña. </p>
            <p>Por su parte, las web beacons son imágenes insertadas en una página de Internet o correo electrónico, que
              puede ser utilizado para monitorear el comportamiento de un usuario, como almacenar información sobre la
              dirección IP de un usuario, duración del tiempo de interacción en dicha página y el tipo de navegador,
              entre otros. </p>
            <p>Cuando navegue por el Sitio Web de Bederr, es posible que Bederr, o sus proveedores de servicios de
              Internet, utilice cookies, web beacons o tecnologías similares. Bederr utiliza las cookies como ayuda
              para identificarlo como usuario, para mejorar su experiencia en el sitio web, para evaluar la efectividad
              promocional y para garantizar la confianza y la seguridad en el sitio web. </p>
            <p>Enlaces </p>
            <p>Este sitio web contiene enlaces a otros sitios que no son propiedad de Bederr. Por tanto, Bederr no es
              responsable de las políticas de privacidad o el contenido de esos sitios web. </p>
            <p>Cambios y Actualizaciones al Aviso de Privacidad </p>
            <p>Todo cambio efectuado al presente Aviso será reflejado en el sitio web de Bederr www.bederr.com, así como
              en sus oficinas y aplicaciones móviles, o bien, por medio de una notificación, incluyendo el envío de
              correos electrónicos; por tanto, el presente Aviso estará vigente de manera indefinida, hasta en tanto
              sea realizada alguna modificación, cambio o actualización al mismo, y ésta se encuentre de conformidad
              con las disposiciones de la Ley de Datos. El Titular acepta cuando se notifique alguna modificación en el
              Aviso, por cualquiera de los medios antes señalados, y el Titular continue en el uso del sitio web,
              aplicaciones móviles o servicios de Bederr, éste acepta expresamente y se somente a las modificaciones
              realizadas, las cuales surtirán plenos efectos en la fecha de su publicación en el sitio web,
              aplicaciones móviles u oficinas de Bederr. Lo anterior, sin perjuicio de los cambios relativos a Datos
              Personales Sensibles o Patrimoniales los cuales deberán ser aceptados expresamente por el Titular. Para
              el caso de alguna queja o sugerencia en cuanto al presente Aviso de Privacidad, le pedimos ponerse en
              contacto con nosotros inmediatamente a la siguiente dirección de correo electrónico team@bederr.com.
            </p>
          </div> :
          !appFields && statusAppFields === STATUS_LOADING ?
            <Fragment>
              <p className={"load"}></p>
              <p className={"load"}></p>
              <p className={"load"}></p>
            </Fragment>
            : <p></p>
      }
      </div>
    );
  }

  render() {
    let {app, authStatus, appFields} = this.props;
    let logo_app = app.company.logo_rectangle ? app.company.logo_rectangle : app.logo
    return (
      <div className={"section"}>

        <div className={"title-bar-section"}>
          <div className="container">
            <h6 className={"mx-auto col-lg-10 col-md-12"}>Politicas de privacidad</h6>
          </div>
        </div>

        {
          (authStatus === STATUS_SUCCESS || app.allow_anonymous_navigation === true) ?
            <div className="container">
              {this.renderPolitics()}
            </div> :
            <Fragment>
              <div className=" rendered-content ">
                <div className="elevation-politics mx-auto col-12 col-md-10 col-lg-8 ">
                  {
                    !(appFields && appFields.site && appFields.site.privacy_policies) &&
                    <img src={logo_app ? logo_app : logo} className="logo" alt="logo"/>
                  }
                  {this.renderPolitics()}
                </div>
              </div>
              <Footer
                stateCart={CART_INVISIBLE}></Footer>
            </Fragment>
        }
      </div>
    );
  }
}

Politics.defaultProps = {
  app: null,
  authStatus: ''
};

Politics.propTypes = {
  app: PropTypes.object,
  authStatus: PropTypes.string
};

