import {normalize, schema} from 'normalizr';
import {STATUS_ERROR, STATUS_LOADING, STATUS_SUCCESS} from "../common/utils/generic";

import {
  APPEND_PRODUCTS, APPEND_PRODUCTS_MENU,
  GET_PRODUCT_PLACES, GET_PRODUCTS_MENU,
  GET_PRODUCTS_PLACES,
  GET_PRODUCTS_REDEEMABLES_PLACES
} from "../actions/productsByPlace";


const productSchema = new schema.Entity('products', {}, {idAttribute: 'uid'});
const productListSchema = new schema.Array(productSchema);



const initialState = {

  entities: null,
  results: [],
  entitiesMenu: null,
  resultsMenu: [],
  listProductsByPlaceStatus: "",
  errorListProductsByPlace: "",

  listNextStatus: null,
  nextPage: null,
  count: 0,


  entitiesRedeemables: null,
  resultsRedeemables: [],
  listProductsRedeemablesByPlaceStatus: "",
  nextPageRedeemables: null,
  countRedeemables: 0,


  productsByPlaceCurrent:null,
  errorProductsByPlaceCurrent: "",
  productsByPlaceCurrentStatus: "",
};

export default function reducer(state = initialState, action) {
  const {type, payload} = action;
  let data;
  switch (type) {

    case `${GET_PRODUCTS_PLACES}_PENDING`:
      return {
        ...state,
        listProductsByPlaceStatus: STATUS_LOADING,
        entities: null,
        results: []
      };
    case `${GET_PRODUCTS_PLACES}_REJECTED`:
      return {
        ...state,
        listProductsByPlaceStatus: STATUS_ERROR,
      };
    case `${GET_PRODUCTS_PLACES}_FULFILLED`: {
      data = payload && payload.status === 200 ? normalize(payload.data.results, productListSchema) : {};

      return {
        ...state,
        entities:  data && data.entities ? data.entities.products : [],
        results:  data && data.result ? data.result : [],
        nextPage:  payload && payload.data ? payload.data.next : null,
        listProductsByPlaceStatus:  payload && payload.status === 200 ? STATUS_SUCCESS : STATUS_ERROR,
        errorListProductsByPlace:  payload && payload.status !== 200 ? (payload.status === 400 ? data : STATUS_ERROR) : null,
        count:  payload && payload.data ? payload.data.count : 0,
      };

    }



    case `${GET_PRODUCTS_MENU}_PENDING`:
      return {
        ...state,
        listProductsMenuStatus: STATUS_LOADING,
        entitiesMenu: null,
        resultsMenu: []
      };
    case `${GET_PRODUCTS_MENU}_REJECTED`:
      return {
        ...state,
        listProductsMenuStatus: STATUS_ERROR,
      };
    case `${GET_PRODUCTS_MENU}_FULFILLED`: {
      data = payload && payload.status === 200 ? normalize(payload.data.results, productListSchema) : {};
      return {
        ...state,
        entitiesMenu:  data && data.entities ? data.entities.products : [],
        resultsMenu:  data && data.result ? data.result : [],
        nextPageMenu:  payload && payload.data ? payload.data.next : null,
        listProductsMenuStatus:  payload && payload.status === 200 ? STATUS_SUCCESS : STATUS_ERROR,
        errorListProductsMenu:  payload && payload.status !== 200 ? (payload.status === 400 ? data : STATUS_ERROR) : null,
        countMenu:  payload && payload.data ? payload.data.count : 0,
      };

    }


    case `${GET_PRODUCTS_REDEEMABLES_PLACES}_PENDING`:
      return {
        ...state,
        listProductsRedeemablesByPlaceStatus: STATUS_LOADING,
        entitiesRedeemables: null,
        resultsRedeemables: []
      };
    case `${GET_PRODUCTS_REDEEMABLES_PLACES}_REJECTED`:
      return {
        ...state,
        listProductsRedeemablesByPlaceStatus: STATUS_ERROR,
      };
    case `${GET_PRODUCTS_REDEEMABLES_PLACES}_FULFILLED`: {
      data = payload && payload.status === 200 ? normalize(payload.data.results, productListSchema) : {};
      return {
        ...state,
        entitiesRedeemables:  data && data.entities ? data.entities.products : [],
        resultsRedeemables:  data && data.result ? data.result : [],
        nextPageRedeemables:  payload && payload.data ? payload.data.next : null,
        listProductsRedeemablesByPlaceStatus:  payload && payload.status === 200 ? STATUS_SUCCESS : STATUS_ERROR,
        errorListProductsRedeemablesByPlace:  payload && payload.status !== 200 ? (payload.status === 400 ? data : STATUS_ERROR) : null,
        countRedeemables:  payload && payload.data ? payload.data.count : 0,
      };

    }

    case `${GET_PRODUCT_PLACES}_PENDING`:
      return {
        ...state,
        productsByPlaceCurrentStatus: STATUS_LOADING,
      };
    case `${GET_PRODUCT_PLACES}_REJECTED`:
      return {
        ...state,
        productsByPlaceCurrentStatus: STATUS_ERROR,
      };
    case `${GET_PRODUCT_PLACES}_FULFILLED`: {
      return {
        ...state,
        productsByPlaceCurrentStatus: ((payload && payload.status === 200) || payload.status === 201) ? STATUS_SUCCESS : STATUS_ERROR,
        errorProductsByPlaceCurrent: payload && payload.status !== 200 ? (payload.status === 400 ? data : STATUS_ERROR) : null,
        productsByPlaceCurrent: payload && payload.data ? payload.data : null,
      };
    }


    case `${APPEND_PRODUCTS}_PENDING`:
      return {
        ...state,
        listNextStatus: STATUS_LOADING
      };
    case `${APPEND_PRODUCTS}_FULFILLED`:
      data = payload && payload.status === 200 ? normalize(payload.data.results, productListSchema) : {};
      return {
        ...state,
        entities: data && data.entities ? {...state.entities, ...data.entities.products} :{...state.entities} ,
        results: data && data.result ? [...state.results, ...data.result] : [...state.results] ,
        listNextStatus: payload && payload.status === 200 ? STATUS_SUCCESS : STATUS_ERROR,
        nextPage: payload && payload.data ? payload.data.next : null,
        count: payload && payload.data ? payload.data.count : 0,
      };


    case `${APPEND_PRODUCTS_MENU}_PENDING`:
      return {
        ...state,
        listNextStatusMenu: STATUS_LOADING
      };
    case `${APPEND_PRODUCTS_MENU}_FULFILLED`:
      data = payload && payload.status === 200 ? normalize(payload.data.results, productListSchema) : {};
      return {
        ...state,
        entitiesMenu: data && data.entities && {...state.entitiesMenu, ...data.entities.products},
        resultsMenu: data && data.result && [...state.resultsMenu, ...data.result] ,
        listNextStatusMenu: payload && payload.status === 200 ? STATUS_SUCCESS : STATUS_ERROR,
        nextPageMenu: payload && payload.data ? payload.data.next : null,
        countMenu: payload && payload.data ? payload.data.count : 0,
      };


    default:
      return state;
  }
}
