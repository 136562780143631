import React, {Fragment} from "react";
import './style.scss'
import {CART_VISIBLE} from "../../actions/cart";
import {Link} from "react-router-dom";
import {STATUS_LOADING, STATUS_SUCCESS} from "../../common/utils/generic";
import bederrLogo from "../../common/images/bederr.png"

export default class Footer extends React.Component {


  goToOtherPage = (e, path) => {
    e.preventDefault()
    const {history, openSidebarMenu} = this.props
    history.push(path);
    openSidebarMenu(false)
  }

  closeSidebar = (e) => {
    e.preventDefault()
    const {openSidebarMenu} = this.props
    openSidebarMenu(false)
  }

  renderPrograms() {
    let {programs, multisite, company} = this.props
    if (programs) {
      programs = programs.filter((p) => {
        return company && p.company && company.uid === p.company.uid
      })
      return programs.map((p, index) =>
        <Link key={`program${index}`}  to={multisite  ? `/c/${company.uid}/program/${p.uid}` : `/program/${p.uid}`}>
          {p.name}
        </Link>
      )
    }
  }

  renderProgramsMultisite() {
    let {programs, multisite, company} = this.props
    if (programs) {
      programs = programs.filter((p) => {
        return company && p.company && company.uid === p.company.uid
      })
      return programs.map((p, index) =>
        <Link  key={`program${index}`} to={multisite && `/c/${company.uid}/program/${p.uid}`}>
          {p.name}
        </Link>
      )
    }
  }


  isMainCompanyMultisite = () => {
    let { company, app, multisite } = this.props
    if(multisite){
      let mainCompanyUid = app.company.uid
      if(company.uid === mainCompanyUid){
        return true;
      }
    }
    return false;
  }

  isChildCompanyMultisite = () => {
    let { company, app, multisite } = this.props
    if(multisite){
      let mainCompanyUid = app.company.uid
      if(company.uid !== mainCompanyUid){
        return true;
      }else{
        return false;
      }
    }
    return false;
  }


  render() {
    let {stateCart} = this.props

    let {
      company,
      loginStatus,
      multisite,
      app,
      listProgramStatus,
      selectedPlace,
      listPlacesStatus,
      user,
      raffles
    } = this.props
    let that = this
    let areasAvailable = []
    if(app && app.countries.length!==0){
      app.countries.map(p=>{
        p.areas.map(area=>{
          areasAvailable.push(area)
          return true
        })
        return true;
      })
    }
    return (
      <footer id="footer">
        <div className={ this.isMainCompanyMultisite() ? " text-center main-section col-lg-12 pr-0 pl-0 multisite-main pt-4" : stateCart===CART_VISIBLE ? "text-center p-3 with-cart col-sm-12 col-lg-9 p-0" : "text-center col-10 col-lg-8 mx-auto pt-3 pl-0"}>
          <div className={stateCart===CART_VISIBLE || this.isMainCompanyMultisite()  ? "footer-div-options container" :  "footer-div-options"}>
            <div className={"options-left"}>
              <h2>Tienda</h2>
              <div className={"options-list"}>
                <div className={"options-list-left"}>
                  <a href={"/"} onClick={e => this.goToOtherPage(e, "/")}
                  >Inicio</a>
                  <a href={user ? "/profile" : "/login"} onClick={e => {
                    localStorage.setItem("toFooterRedirect", "profile")
                    this.goToOtherPage(e, user ? "/profile" : "/login")
                  }}
                  >Datos personales</a>
                  <a href={user ? "/change-password" : "/login" } onClick={e => {
                    localStorage.setItem("toFooterRedirect", "change-password")
                    this.goToOtherPage(e, user ? "/change-password" : "/login")
                  }}
                  >Cambiar contraseña</a>

                  {
                    company &&
                    company.ecommerce_enabled &&
                    <Link  to={multisite ? `/c/${company.uid}/products` : `/products`}>
                      Productos
                    </Link>
                  }
                  {
                    selectedPlace &&
                    selectedPlace.subscription_enabled &&
                    listPlacesStatus === STATUS_SUCCESS && false &&
                    <Link  to={multisite ? `/c/${company.uid}/subscription` : `/subscription`}>
                      Suscripcion
                    </Link>
                  }
                  {
                    this.isMainCompanyMultisite()
                    && false
                    &&
                    <Link  to={`/`}>
                      Marketplace
                    </Link>
                  }

                  {
                    company &&
                    company.benefit_programs_enabled &&
                    loginStatus === STATUS_SUCCESS &&
                    <Fragment>
                      {
                        listProgramStatus === STATUS_LOADING ?
                          <li className="nav-item loading">
                            <span/>
                          </li>
                          : <Fragment>{that.renderPrograms()}</Fragment>
                      }
                    </Fragment>
                  }
                  {
                    user && app && app.company && app.company.ecommerce_enabled &&
                      <Link to={multisite ? `/c/${company.uid}/orders` : `/orders`}>
                        Órdenes
                      </Link>
                  }

                  {
                    !this.isMainCompanyMultisite() && raffles && raffles.count>0 &&
                    <Link  to={multisite ? `/c/${company.uid}/raffles` : `/raffles`}>
                      Sorteos
                    </Link>
                  }
                </div>
                <div className={"options-list-right"}>
                  {
                    !this.isMainCompanyMultisite()
                    && app && app.company && app.company.ecommerce_enabled &&
                    <Link  to={multisite ? `/c/${company.uid}/cart` : `/cart`}>
                      Carrito de compras
                    </Link>
                  }
                  {this.isMainCompanyMultisite() &&
                    <a href={`https://administrador.${process.env.REACT_APP_URL_REST.includes("bemaven") ? "bemaven.xyz" : "bederr.com"}/register/`}  target="_blank" rel="noopener noreferrer"
                    >Crear tienda</a>
                  }
                </div>
              </div>
            </div>
            <div className={"options-right"}>
              <h2>Otros</h2>
              <div className={"options-list"}>

                <a href={"/terms-and-conditions"} onClick={e => this.goToOtherPage(e, "/terms-and-conditions")}
                >Términos y Condiciones</a>

                <a href={"/politics"} onClick={e => this.goToOtherPage(e, "/politics")}
                >Política de privacidad</a>

                <a href={"/faqs"} onClick={e => this.goToOtherPage(e, "/faqs")}
                >FAQs</a>

              </div>
            </div>
          </div>
          <div className={"bederr-footer d-flex justify-content-center align-items-center"}>
            <p style={{marginTop: 8, marginBottom: 0, marginRight: 5}}> © { new Date().getFullYear()} Powered by</p> <a href={"https://www.bederr.com/"} target={"_blank"} rel="noopener noreferrer">

            <img src={bederrLogo} alt="bederrLogo" width={80} height={20}/>
          </a>
          </div>
        </div>
      </footer>
    );
  }

}