import {normalize, schema} from 'normalizr';

import {STATUS_ERROR, STATUS_INCLUDE, STATUS_LOADING, STATUS_SUCCESS} from "../common/utils/generic";
import {
  GET_RAFFLES,
  GET_RAFFLE, REGISTER_RAFFLE, DISMISS_RAFFLE
} from "../actions/raffles";

const raffleSchema = new schema.Entity('raffles', {}, {idAttribute: 'uid'});
const raffleListSchema = new schema.Array(raffleSchema);


const initialState = {
  rafflesStatus: null,
  entities: null,
  results: [],
  listStatus: null,
  nextPage: null,
  count: 0,
  entity: null,
  retrieveStatus: null,
  register: null,
  registerStatus: null,
  dismiss: null,
  dismissStatus: null,
};

export default function reducer(state = initialState, action) {
  const {type, payload} = action;
  let data;
  switch (type) {
    case `${GET_RAFFLES}_PENDING`:
      return {
        ...state,
        listStatus: STATUS_LOADING,
        count: 0,
        entities: null,
        nextPage: null,
        results: []
      };
    case `${GET_RAFFLES}_FULFILLED`:
      data = payload.status === 200 ? normalize(payload.data.results, raffleListSchema) : {};

      return {
        ...state,
        entities: data.entities ? data.entities.raffles : [],
        results: data.result ? data.result : [],
        nextPage: payload.data ? payload.data.next : null,
        listStatus: payload.status === 200 ? STATUS_SUCCESS : STATUS_ERROR,
        count: payload.data ? payload.data.count : 0,
      };

    case `${GET_RAFFLE}_PENDING`:
      return {
        ...state,
        retrieveStatus: STATUS_LOADING
      };
    case `${GET_RAFFLE}_FULFILLED`:
      return {
        ...state,
        entity: payload.data ? payload.data : null,
        retrieveStatus: payload.status === 200 ? STATUS_SUCCESS : ( payload.status === 404 ? STATUS_INCLUDE :STATUS_ERROR),
      };

    case `${REGISTER_RAFFLE}_PENDING`:
      return {
        ...state,
        registerStatus: STATUS_LOADING
      };
    case `${REGISTER_RAFFLE}_FULFILLED`:
      return {
        ...state,
        register: payload.data ? payload.data : null,
        registerStatus: payload.status === 200 ? STATUS_SUCCESS : ( payload.status === 404 ? STATUS_INCLUDE :STATUS_ERROR),
      };
    case `${DISMISS_RAFFLE}_PENDING`:
      return {
        ...state,
        dismissStatus: STATUS_LOADING
      };
    case `${DISMISS_RAFFLE}_FULFILLED`:
      return {
        ...state,
        dismiss: payload.data ? payload.data : null,
        dismissStatus: payload.status === 200 ? STATUS_SUCCESS : ( payload.status === 404 ? STATUS_INCLUDE :STATUS_ERROR),
        listStatus: null,
        retrieveStatus: null,
      };
    default:
      return state;
  }
}
