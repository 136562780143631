import React, {Fragment} from "react";
import {STATUS_LOADING, STATUS_SUCCESS} from "../../common/utils/generic";
import {Link} from "react-router-dom";
import Icon from "../../common/icons/icon";
import {SECONDARY_MENU_BACK, SECONDARY_MENU_BUSINESS, SECONDARY_MENU_INVISIBLE} from "../../actions/menu";
import NavPlaces from "./components/NavPlaces";

export default class SecondaryMenu extends React.Component {

  componentDidMount() {
    let {getPrograms, loginStatus} = this.props
    if (loginStatus === STATUS_SUCCESS) {
      getPrograms()
    }
  }

  renderPrograms() {
    let {programs, multisite, company} = this.props
    if (programs) {
      programs = programs.filter((p) => {
        return company && p.company && company.uid === p.company.uid
      })
      return programs.map((p, index) =>
        <li key={`program${index}`} className="nav-item"
        >
            <Link className="nav-link" to={multisite  ? `/c/${company.uid}/program/${p.uid}` : `/program/${p.uid}`}>
              {p.name}
            </Link>
        </li>
      )
    }
  }

  renderProgramsMultisite() {
    let {programs, multisite, company} = this.props
    if (programs) {
      programs = programs.filter((p) => {
        return company && p.company && company.uid === p.company.uid
      })
      return programs.map((p, index) =>
        <li key={`program${index}`} className="nav-item"
        >
          <Link className="nav-link" to={multisite && `/c/${company.uid}/program/${p.uid}`}>
            {p.name}
          </Link>
        </li>
      )
    }
  }

  isMainCompanyMultisite = () => {
    let { company, app, multisite } = this.props
    if(multisite){
      let mainCompanyUid = app.company.uid
      if(company.uid === mainCompanyUid){
        return true;
      }
    }
    return false;
  }

  isChildCompanyMultisite = () => {
    let { company, app, multisite } = this.props
    if(multisite){
      let mainCompanyUid = app.company.uid
      if(company.uid !== mainCompanyUid){
        return true;
      }else{
        return false;
      }
    }
    return false;
  }

  onBack = (e) => {
    const {history} = this.props;
    e.preventDefault()
    if (history)
      history.goBack();
  }


  render() {
    let {stateSecondaryMenu, loginStatus, listProgramStatus, selectArea, listPlacesStatus, multisite, company, selectedPlace,
      places, selectPlace} = this.props
    let that = this;
    if (stateSecondaryMenu === SECONDARY_MENU_INVISIBLE)
      return <div className={"submenu"}/>
    if (stateSecondaryMenu === SECONDARY_MENU_BACK)
      return <div className={"submenu"}>
        <div className={"container"}>
          <ul className="navbar-nav nav-submenu">
            <li className="nav-item">
              <a className="nav-link" href={"/"} onClick={e => this.onBack(e)}>
                <Icon svg="back" classes="svg-icon back" title="Back"/>
                <span>Regresar</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    if (stateSecondaryMenu === SECONDARY_MENU_BUSINESS)
      return <div className={"submenu"} >
        {
          !this.isMainCompanyMultisite() &&
          <div className={"container"}>
            <ul className="navbar-nav nav-submenu"
                style={{paddingTop: this.isMainCompanyMultisite() ? "15px" : "8px", paddingBottom: "7px",
                  fontSize: this.isMainCompanyMultisite() ? "19px" : "16px"}}>
              {
                this.isChildCompanyMultisite() &&
                places.length>1 &&
                <NavPlaces
                  selectedPlace={selectedPlace}
                  selectPlace={selectPlace}
                  selectArea={selectArea}
                  places={places}
                  listPlacesStatus={listPlacesStatus}
                />
              }
              {
                this.isChildCompanyMultisite() &&
                places.length>1 &&
                <div className={"places-secondary"}>
                  <NavPlaces
                    selectedPlace={selectedPlace}
                    selectPlace={selectPlace}
                    places={places}
                    listPlacesStatus={listPlacesStatus}
                  />
                </div>
              }
              <div className={"d-flex align-items-center"}>
                {
                  company &&
                  company.ecommerce_enabled &&
                  <li className="nav-item">
                    <Link className="nav-link" to={multisite ? `/c/${company.uid}/products/more` : `/products/more`}>
                      Productos
                    </Link>
                  </li>
                }
                {
                  this.isMainCompanyMultisite()
                  && false
                  &&
                  <li className="nav-item">
                    <Link className="nav-link" to={`/`}>
                      Marketplace
                    </Link>
                  </li>
                }
                {
                  company &&
                  company.benefit_programs_enabled &&
                  loginStatus === STATUS_SUCCESS &&
                  <Fragment>
                    {
                      listProgramStatus === STATUS_LOADING ?
                        <li className="nav-item loading">
                          <span/>
                        </li>
                        : <Fragment>{that.renderPrograms()}</Fragment>
                    }
                  </Fragment>
                }
                {
                  selectedPlace &&
                  selectedPlace.subscription_enabled &&
                  listPlacesStatus === STATUS_SUCCESS &&
                  <li className="nav-item">
                    <Link className="nav-link" to={multisite ? `/c/${company.uid}/subscription` : `/subscription`}>
                      Suscripcion
                    </Link>
                  </li>
                }
              </div>
            </ul>
          </div>
        }
      </div>
    return (<Fragment/>);
  }


}