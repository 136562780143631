import HttpClient from "../common/utils/httpClient";


export const GET_APP_FIELDS = 'GET_APP_FIELDS';
export const GET_APP_URL = 'v7/apps/{uid_app}';

export const GET_APP_SECTIONS = 'GET_APP_SECTIONS';
export const GET_APP_SECTIONS_URL = 'v7/categories/?app={uid_app}&with_companies=true';

export const GET_ADS_FOR_COMPANY = 'GET_ADS_FOR_COMPANY';
export const GET_ADS_FOR_COMPANY_URL = 'v5/companies/{uid_company}/ads';

export const GET_APP_SECTIONS_SEARCH_URL = 'v7/categories/?app={uid_app}&with_companies=true&search={search}';

export const GET_COMPANIES_SECTIONS = 'GET_COMPANIES_SECTIONS';
export const GET_COMPANIES_SECTIONS_URL = 'v7/apps/{uid_app}/allowed-companies/?category={uid_category}';
export const GET_COMPANIES_SECTIONS_SEARCH_URL = 'v7/apps/{uid_app}/allowed-companies/?category={uid_category}&search={search}';

export const GET_APP_LANDINIG_MODEL_TWO = 'GET_APP_LANDINIG_MODEL_TWO';
export const GET_APP_LANDINIG_MODEL_TWO_URL = 'v7/apps/{uid_app}/landing/';


export const CLEAN_ADS_COMPANY = 'CLEAN_ADS_COMPANY';

export function getApp(uid) {

  return (dispatch) => HttpClient.get(
    dispatch,
    GET_APP_FIELDS,
    `${GET_APP_URL.replace("{uid_app}", uid)}`
  );
}

export function getAppSections(uid) {

  return (dispatch) => HttpClient.get(
    dispatch,
    GET_APP_SECTIONS,
    `${GET_APP_SECTIONS_URL.replace("{uid_app}", uid)}`
  );
}
export function getAdsCompany(uid) {
  return (dispatch) => HttpClient.get(
    dispatch,
    GET_ADS_FOR_COMPANY,
    `${GET_ADS_FOR_COMPANY_URL.replace("{uid_company}", uid)}`
  );
}
export function getAppSectionsSearch(uid, search) {

  return (dispatch) => HttpClient.get(
    dispatch,
    GET_APP_SECTIONS,
    `${GET_APP_SECTIONS_SEARCH_URL.replace("{uid_app}", uid).replace("{search}",search)}`
  );
}
export function getCompaniesSections(uid, category) {

  return (dispatch) => HttpClient.get(
    dispatch,
    GET_COMPANIES_SECTIONS,
    `${GET_COMPANIES_SECTIONS_URL.replace("{uid_app}", uid).replace("{uid_category}",category)}`
  );
}

export function getCompaniesSectionsSearch(uid, category, search) {

  return (dispatch) => HttpClient.get(
    dispatch,
    GET_COMPANIES_SECTIONS,
    `${GET_COMPANIES_SECTIONS_SEARCH_URL.replace("{uid_app}", uid).replace("{uid_category}",category).replace("{search}",search)}`
  );
}

export function getAppLandingModelTwo(uid) {

  return (dispatch) => HttpClient.get(
    dispatch,
    GET_APP_LANDINIG_MODEL_TWO,
    `${GET_APP_LANDINIG_MODEL_TWO_URL.replace("{uid_app}", uid)}`
  );
}

export function cleanAdsCompany() {
  return {
    type: CLEAN_ADS_COMPANY,
  };
}