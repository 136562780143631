import React from "react";
import Icon from "../../../common/icons/icon";
import {STATUS_ERROR, STATUS_LOADING, STATUS_SUCCESS} from "../../../common/utils/generic";
import PropTypes from "prop-types";

export default class NavAreas extends React.Component {

  componentDidMount() {
    /*
    let {selectArea, areas} =this.props
    if(areas.length===1){
      //selectArea(areas[0])
      console.log(areas[0],"areas[0]---")
    }*/
  }

  changeArea = (e, p) => {
    e.preventDefault()
    let {selectedArea, getPlaces, selectArea, updateUser ,loginStatus} = this.props
    if(selectArea  || (selectedArea && selectedArea.uid !== p.uid)){
      selectArea(p)
      getPlaces(p)
      console.log(p,"p---")
      if(loginStatus === STATUS_SUCCESS){
        updateUser({area: p.uid})
      }
    }
  }

  render() {
    let {areas, selectedArea, listAreasStatus} = this.props
    let auxAreas = [...areas].sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
    return (
      <div className="nav-item dropdown m-1 mr-2">
        <button className="nav-link nav-areas"
                id="menu-areas"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false">
          <span>Ciudad</span><br/>
          {selectedArea ?
            selectedArea.name : "Escoger una ciudad"
          }
          <Icon svg="down" classes="svg-icon down" title="Down"/>
        </button>
        <div className="dropdown-menu dropdown-areas list-countries" aria-labelledby="menu-areas">
          {
            auxAreas.map((p, index) =>
              <div key={`area${index}`} className="dropdown-item"
                   onClick={event => this.changeArea(event, p)}
              >
                {p.name}
              </div>
            )
          }
          {
            listAreasStatus === STATUS_LOADING ? <div className="dropdown-item">Cargando...</div> :
              (listAreasStatus === STATUS_SUCCESS && areas.length === 0 ?
                  <div className="dropdown-item"> No se ha encontrado ciudades</div> :
                  (listAreasStatus === STATUS_ERROR ?
                      <div className="dropdown-item"> No existen ciudades en este momento</div> :
                      <span/>
                  )
              )
          }
        </div>
      </div>

    );
  }
}

NavAreas.propTypes = {
  areas: PropTypes.array,
  selectedArea: PropTypes.object,
  listAreasStatus: PropTypes.string,
  selectArea: PropTypes.func
};
