import {normalize, schema} from 'normalizr';
import {STATUS_ERROR, STATUS_LOADING, STATUS_SUCCESS} from "../common/utils/generic";

import {GET_EVENTS, EVENT_DETAIL} from "../actions/events";

const eventSchema = new schema.Entity('events', {}, {idAttribute: 'uid'});
const eventListSchema = new schema.Array(eventSchema);



const initialState = {
  eventStatus: null,
  eventCurrent: null,
  entities: null,
  results: [],
  listStatus: null,
  listNextStatus: null,
  nextPage: null,
  count: 0,
  formInitial: null,
  retrieveStatus: null,
};

export default function reducer(state = initialState, action) {
  const {type, payload} = action;
  let data;
  switch (type) {
    case `${GET_EVENTS}_PENDING`:
      return {
        ...state,
        listStatus: STATUS_LOADING,
        count: 0,
        entities: null,
        nextPage: null,
        results: []
      };
    case `${GET_EVENTS}_FULFILLED`:
      data = payload.status === 200 ? normalize(payload.data.results, eventListSchema) : {};
      return {
        ...state,
        entities: data.entities ? data.entities.events : [],
        results: data.result ? data.result : [],
        nextPage: payload.data ? payload.data.next : null,
        listStatus: payload.status === 200 ? STATUS_SUCCESS : STATUS_ERROR,
        count: payload.data ? payload.data.count : 0,
      };
    case `${EVENT_DETAIL}_PENDING`:
      return {
        ...state,
        formInitial: null,
        retrieveStatus: STATUS_LOADING
      };
    case `${EVENT_DETAIL}_FULFILLED`:
      data = action.payload.status === 200 ? action.payload.data : null;
      return {
        ...state,
        retrieveStatus: action.payload.status === 200 ? STATUS_SUCCESS : STATUS_ERROR,
        formInitial: data
      };
    default:
      return state;
  }
}
