import React, {Fragment} from 'react'
import PollTable from "./PollTable/PollTable";
import ModalPoll from "../ModalPoll";

const $ = require('jquery');

export default class ListPolls extends React.Component {

  constructor() {
    super();
    this.state = {
      companyUid: "",
      pageCurrent: 1,
      openNotificationDetail: false,
      pollUid: "",
      loadList: false,
      loadListResolved: false,
    }
  }

  componentDidMount() {
    const {
      changeStateCart, changeSecondaryMenu,
    } = this.props
    changeStateCart()
    changeSecondaryMenu()
    this.updateComponent()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let {changeSecondaryMenu, changeStateCart, stateCart} = this.props;
    this.updateComponent()
    if (stateCart !== "CART_INVISIBLE") {
      changeSecondaryMenu()
      changeStateCart()
    }
    if ($(".modal-backdrop").length > 1) {
      $('.modal-backdrop:first').remove();
    }
  }

  updateComponent() {
    const {company, getPolls, getPollsResolved} = this.props
    if (company) {
      if (company.uid !== this.state.companyUid) {
        this.setState({companyUid: company.uid})
        getPolls({companyUid: company.uid})
        getPollsResolved({companyUid: company.uid})
      }
    }
  }

  openPopUp(pollUid) {
    this.setState({openNotificationDetail: true, pollUid: pollUid})
  }
  renderStatus = (status) => {
    switch (status) {
      case "received":
        return "Orden recibida";
      case "delivered":
        return "Orden entregada";
      case "canceled_by_place":
        return "Orden Cancelada";
      case "canceled_by_user":
        return "Orden Cancelada por el usuario";
      case "accepted":
        return "Orden aceptada";
      case "Aceptada":
        return "Orden aceptada";
      case "shipped":
        return "Orden enviada";
      default:
        return '--'
    }
  }

  render() {
    let {
      polls: {
        entities: pollsEntities,
        results: pollsResults,
        listStatus,
        count,

        entitiesResolved: pollsEntitiesResolved,
        resultsResolved: pollsResultsResolved,
        listStatusResolved,
        countResolved,

        listNextStatus,
        nextPage,
        previousPage,
        retrieveStatus,
        formInitial,
        statusSendPoll
      },
      getPolls,
      getPollsResolved,
      getNumberPagePolls,
      retrievePoll,
      sendPoll,
      clearStatusPoll,
      clearStatusSendPoll,
      app,
      company
    } = this.props
    return (
      <Fragment>
        <PollTable
          polls={pollsResults.map(cUid => pollsEntities[cUid])}
          pollsResolved={pollsResultsResolved.map(cUid => pollsEntitiesResolved[cUid])}
          count={count}
          countResolved={countResolved}
          nextPage={nextPage}
          previousPage={previousPage}
          listPollsStatus={listStatus}
          listPollsResolvedStatus={listStatusResolved}
          listNextPollsStatus={listNextStatus}
          getPolls={() => {
            getPolls()
          }}
          getPollsResolved={() => {
            getPollsResolved()
          }}
          getNumberPage={(numberPage) => getNumberPagePolls(numberPage)}
          clearStatusSendPoll={clearStatusSendPoll}
          pageCurrent={this.state.pageCurrent}
          setPageCurrent={pageCurrent => this.setState({pageCurrent: pageCurrent})}
          openPopUp={(uid) => this.openPopUp(uid)}
          changeLoadList={()=>this.setState({loadList: false})}
          changeLoadListResolved={()=>this.setState({loadListResolved: false})}
          app={app}
          company={company}
        />

        <ModalPoll
          showModal={active => this.setState({openNotificationDetail: active})}
          retrieveStatus={retrieveStatus}
          poll={formInitial}
          clearCompanyUid={()=>this.setState({companyUid: null})}
          pollUid={this.state.pollUid}
          setPollUid={pollUid => (this.setState({pollUid: pollUid}))}
          getPoll={(pollUid) => (pollUid && retrievePoll(pollUid))}
          renderStatus={(status) => this.renderStatus(status)}
          openNotificationDetail={this.state.openNotificationDetail}
          sendPoll={sendPoll}
          statusSendPoll={statusSendPoll}
          clearStatusPoll={clearStatusPoll}
          loadList={this.state.loadList}
          loadListResolved={this.state.loadListResolved}
          changeLoadList={(loadList)=>this.setState({loadList: loadList})}
        />
      </Fragment>
    );
  }

}

