import React, {Fragment} from 'react'
import PropTypes from 'prop-types';
import {STATUS_LOADING, STATUS_SUCCESS} from "../../common/utils/generic";
import './style.scss';
import PaginationTable from "../../common/Pagination/PaginationTable";
import Benefit from "./Benefit";

export default class PromotionsDeleteList extends React.Component {


  constructor(props) {
    super(props);
    this.state = {
      pageCurrent: 1,
      top: false,
    };
  }

  renderProgramPromotions() {
    const {programPromotions, listProgramPromotionsStatus, count} = this.props;
    if (count === 0 && listProgramPromotionsStatus === STATUS_LOADING) {
      return <Fragment>
        <Benefit loading={true}/>
        <Benefit loading={true}/>
        <Benefit loading={true}/>
      </Fragment>;
    }
    if (count === 0 && listProgramPromotionsStatus === STATUS_SUCCESS) {
      return (
        <p>No hay nada que mostrar</p>
      );
    }
    return programPromotions.map((promotion, index) => (
      <Benefit key={`promo${index}`} promotion={promotion}/>
    ));

  }



  render() {
    let {nextPagePromotions, getPagePromotions, previousPagePromotions, count, getNumberPage,
      setPageCurrent, pageCurrent} = this.props;
    //console.log(nextPagePromotions)
    return (
      <div className={"container program-promotions"}>
            <div
              className="entities-section row"
            >
              {
                this.props.collapse &&
                <div className={"w-100"}>
                  {this.renderProgramPromotions()}
                </div>
              }

              {
                nextPagePromotions &&
                <PaginationTable
                  getPage={getPagePromotions} previousPage={previousPagePromotions} nextPage={nextPagePromotions}
                  pageCurrent={pageCurrent} setPageCurrent={setPageCurrent} count={count}
                  getNumberPage={getNumberPage}
                />
              }
            </div>
      </div>
    );
  }
}

PromotionsDeleteList.defaultProps = {
  programPromotions: [],
  listProgramPromotionsStatus: '',
  count: 0,
  nextPagePromotions: null
};

PromotionsDeleteList.propTypes = {
  programPromotions: PropTypes.array,
  nextPagePromotions: PropTypes.string,
  getMoreProgramPromotions: PropTypes.func,
  listNextProgramPromotionsStatus: PropTypes.string,
  programUid: PropTypes.string,
  count: PropTypes.number,
  listProgramPromotionsStatus: PropTypes.string
};

