import React, {Fragment} from "react";
import './style.scss'
import Icon from "../../common/icons/icon";
import {Sidebar} from "../../common/components/Sidebar";
import NavPlaces from "./components/NavPlaces";
import {STATUS_LOADING, STATUS_SUCCESS} from "../../common/utils/generic";
import NavAreas from "./components/NavAreas";
import {Link} from "react-router-dom";


export default class SidebarMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show_cart: true,
      show_store: true,
      show_program: true,
    };
  }
  componentDidMount() {
    let {getAreas, app:{company}} = this.props;
    getAreas()
    console.log("company", company.hidden_for_members)
    company.hidden_for_members.map(s=>{
      if(s.name==="cart"){
        this.setState({show_cart: false})
      }
      if(s.name==="store"){
        this.setState({show_store: false})
      }
      if(s.name==="program"){
        this.setState({show_program: false})
      }
      return true
    })
  }

  goToOtherPage = (e, path) => {
    e.preventDefault()
    const {history, openSidebarMenu} = this.props
    history.push(path);
    openSidebarMenu(false)
  }

  closeSidebar = (e) => {
    e.preventDefault()
    const {openSidebarMenu} = this.props
    openSidebarMenu(false)
  }
  showArea = () => {
    const {history} = this.props;
    if (history.location.pathname.includes("/program")) {
      return true
    }
    return false
}

  renderPrograms() {
    let {programs, multisite, company} = this.props
    if (programs) {
      programs = programs.filter((p) => {
        return company && p.company && company.uid === p.company.uid
      })
      return programs.map((p, index) =>
          <Link key={`program${index}`} className="list-group-item list-group-item-action" to={multisite  ? `/c/${company.uid}/program/${p.uid}` : `/program/${p.uid}`}>
            {p.name}
          </Link>
      )
    }
  }

  renderProgramsMultisite() {
    let {programs, multisite, company} = this.props
    if (programs) {
      programs = programs.filter((p) => {
        return company && p.company && company.uid === p.company.uid
      })
      return programs.map((p, index) =>
          <Link className="list-group-item list-group-item-action" key={`program${index}`} to={multisite && `/c/${company.uid}/program/${p.uid}`}>
            {p.name}
          </Link>
      )
    }
  }


  isMainCompanyMultisite = () => {
    let { company, app, multisite } = this.props
    if(multisite){
      let mainCompanyUid = app.company.uid
      if(company.uid === mainCompanyUid){
        return true;
      }
    }
    return false;
  }

  isChildCompanyMultisite = () => {
    let { company, app, multisite } = this.props
    if(multisite){
      let mainCompanyUid = app.company.uid
      if(company.uid !== mainCompanyUid){
        return true;
      }else{
        return false;
      }
    }
    return false;
  }


  render() {
    let {
      company,
      activeSidebarMenu,
      openSidebarMenu,
      loginStatus,
      closeSession,
      updateUser,

      selectedPlace,
      selectPlace,
      places,
      listPlacesStatus,

      selectedArea,
      selectArea,
      //areas,
      listAreasStatus,
      multisite,
      getPlaces,
      app,
      listProgramStatus,
      //raffles
    } = this.props

    let {
      show_store,
      show_program
    } = this.state

    let that = this
    let areasAvailable = []
    if(app && app.countries.length!==0){
      app.countries.map(p=>{
        p.areas.map(area=>{
          areasAvailable.push(area)
          return true
        })
        return true;
      })
    }
    return (
      <Sidebar
        key={"sidebar"}
        width={300}
        height={"auto"}
        active={activeSidebarMenu}
        openSidebarMenu={openSidebarMenu}
      >
        <div className={"container sidebar-menu"}>
          <div className={"row"}>
            <div className={"col align-self-end closePrograms"}>
              <button id="btn-close-sidebar" className={"btn btn-icon"} onClick={e => this.closeSidebar(e)}>
                <Icon svg="close" classes="svg-icon close" title="Close"/>
              </button>
            </div>
              <div className={ areasAvailable.length>1 ? "col-12 areas-sidebar" : "d-none col-12 areas-sidebar"}>
                <NavAreas
                  selectedArea={selectedArea}
                  selectArea={selectArea}
                  areas={areasAvailable}
                  updateUser={updateUser}
                  loginStatus={loginStatus}
                  listAreasStatus={listAreasStatus}
                  getPlaces={getPlaces}
                  app={app}
                />
              </div>

              
              <div className={"d-block d-md-none col-12 places-sidebar select-place"}>
                <NavPlaces
                  selectedPlace={selectedPlace}
                  selectPlace={selectPlace}
                  selectArea={selectArea}
                  places={places}
                  listPlacesStatus={listPlacesStatus}
                />
              </div>



            <div className={"col-12"}>
              <div className={"list-group list-group-flush"}>
                {
                  loginStatus === STATUS_SUCCESS ? <Fragment>
                    <a href={"/profile"} onClick={e => this.goToOtherPage(e, "/profile")}
                       className="list-group-item list-group-item-action">Mi perfil</a>

                    <a href={"/qr"} onClick={e => this.goToOtherPage(e, "/qr")}
                       className="d-block d-lg-none list-group-item list-group-item-action">Mi código QR</a>

                    <a href={"/change-password"} onClick={e => this.goToOtherPage(e, "/change-password")}
                       className="list-group-item list-group-item-action">Cambiar contraseña</a>
                        {
                          company &&
                          company.ecommerce_enabled && show_store && false &&
                            <Link className="list-group-item list-group-item-action" to={multisite ? `/c/${company.uid}/products/more` : `/products/more`}>
                              Productos
                            </Link>
                        }
                        {
                          this.isMainCompanyMultisite()
                          && false
                          &&
                            <Link className="list-group-item list-group-item-action" to={`/`}>
                              Marketplace
                            </Link>
                        }
                        {
                          company &&
                          company.benefit_programs_enabled &&
                          loginStatus === STATUS_SUCCESS && show_program && false &&
                          <Fragment>
                            {
                              listProgramStatus === STATUS_LOADING ?
                                <li className="nav-item loading">
                                  <span/>
                                </li>
                                : <Fragment>{that.renderPrograms()}</Fragment>
                            }
                          </Fragment>
                        }
                    {
                      show_store &&
                      <Link className="list-group-item list-group-item-action" to={multisite ? `/c/${company.uid}/orders` : `/orders`}>
                      Órdenes
                      </Link>
                    }
                    {
                      app && app.company && app.company.ecommerce_enabled && company.gamification_system &&
                      <Link className="list-group-item list-group-item-action" to={multisite ? `/c/${company.uid}/orders/coins` : `/orders/coins`}>
                      Canjes
                      </Link>
                    }
                    {
                      app && app.company && app.name.toLowerCase().includes("rock") && app.name.toLowerCase().includes("pez") &&
                      <Link className="list-group-item list-group-item-action" to={multisite ? `/c/${company.uid}/events` : `/events`}>
                      Eventos
                      </Link>
                    }

                      <a href={"/polls"} onClick={e => this.goToOtherPage(e, "/polls")}
                         className="list-group-item list-group-item-action">Encuestas</a>
                        {/* {
                          selectedPlace &&
                          selectedPlace.subscription_enabled &&
                          listPlacesStatus === STATUS_SUCCESS &&
                            <Link className="list-group-item list-group-item-action" to={multisite ? `/c/${company.uid}/subscription` : `/subscription`}>
                              Suscripcion
                            </Link>
                        } */}

                      {/* {
                        (company && company.subscription_enabled) &&
                        (
                          (multisite===true) ?
                            <a href={`/subscription/me`} onClick={e => this.goToOtherPage(e, `/c/${company.uid}/subscription/me`)}
                               className="list-group-item list-group-item-action">Mis suscripciones </a>
                            :
                            <a href={"/subscriptions"} onClick={e => this.goToOtherPage(e, "/subscription/me")}
                               className="list-group-item list-group-item-action">Mis suscripciones </a>
                        )
                      } */}
                  </Fragment> :
                  <Link to={"/login"} className="list-group-item list-group-item-action">
                  Ingresar
                  </Link>
                }

                {/* <a href={"/terms-and-conditions"} onClick={e => this.goToOtherPage(e, "/terms-and-conditions")}
                   className="list-group-item list-group-item-action">Términos y Condiciones</a>

                <a href={"/politics"} onClick={e => this.goToOtherPage(e, "/politics")}
                   className="list-group-item list-group-item-action">Política de privacidad</a> */}
                {
                  loginStatus === STATUS_SUCCESS &&
                  <button className="list-group-item list-group-item-action close-session-menu"
                          onClick={e => {
                            closeSession();
                            document.location.href = '/';
                          }}
                  >Cerrar sesión</button>
                }
              </div>

            </div>
          </div>
        </div>


      </Sidebar>
    );
  }


}