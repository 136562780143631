import React, {Fragment} from 'react'
import PropTypes from 'prop-types';
import {GoogleMap, LoadScriptNext, Marker} from '@react-google-maps/api'
import moment from "moment";
import './style.scss';


export default class MapPlacePromotion extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      listPlaces: [],
      currentlyPlace: null,
      textBtnMore: false,
      showBtnMore: false
    }
  }

  componentDidMount() {
    const {
      listPlacePromotion
    } = this.props;
    if (listPlacePromotion.length>0) {
      this.setState(
        {
          listPlaces: listPlacePromotion,
          currentlyPlace: listPlacePromotion[0]
        }
      )
    }
    if(listPlacePromotion.length>4){
      this.setState({textBtnMore: true, showBtnMore: true})
    }
  }



  setCurrentlyPlace = (e, place) => {
    this.setState({
      currentlyPlace: place
    })
  }


  renderMap = () => {

    let {listPlaces,
      currentlyPlace} = this.state
    //let API_key = "AIzaSyCDVeXSSU_rDyyTPSc0mrJJoaSQRR-w4sI";
    let API_key = "AIzaSyAWTCEZ32pjF851IgouM7sbFKbaOWjWvPM";

    const containerStyle = {
      width: '100%',
      height: '300px'
    };

    const center = {
      lat: currentlyPlace.location.latitude,
      lng: currentlyPlace.location.longitude
    };




    return <LoadScriptNext
      googleMapsApiKey={API_key}

    >
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        options={{
          streetViewControl: false,
          scaleControl: false,
          fullscreenControl: true,
          gestureHandling: "greedy",
          minZoom: 12,
          maxZoom: 18,
          mapTypeControl: false,
          zoomControl: true,
          clickableIcons: true
        }}
        zoom={15}
      >
        {listPlaces.map(place => (
          <Marker
            key={place.uid}
            position={{
              lat: parseFloat(place.location.latitude),
              lng: parseFloat(place.location.longitude)
            }}
            onClick={(e) => this.setCurrentlyPlace(e, place)}
          />
        ))}
      </GoogleMap>
    </LoadScriptNext>
  }

  render() {

    const {currentlyPlace} = this.state;
    const {listPlacePromotion} = this.props;
    return (
      <div>
        {
          currentlyPlace &&
          this.renderMap()
        }
        <div className={"places-list-detail"}>
          {
            listPlacePromotion.map((currentlyPlace, index) => (
              <button className={index>3 && this.state.textBtnMore ? "place-detail p-3 d-none" : "place-detail p-3 "} key={`mapPlace${index}`}
                      onClick={(e) => this.setCurrentlyPlace(e, currentlyPlace)}>
                <h5 className={"mt-0 mb-3"} style={{textAlign: "center"}}>
                  {currentlyPlace.name &&
                    <span>{currentlyPlace.name }</span>} -
                  {currentlyPlace.locality && <span>{" "}{currentlyPlace.locality.name}</span>} </h5>
                <div className="address-schedule d-flex">
                  <div className="address">
                    <div>
                      <p>{currentlyPlace.address}</p>
                    </div>

                    <div className={"mt-2"}>
                      {((currentlyPlace.telephone1 &&
                            currentlyPlace.telephone1 !== "") ||
                          (currentlyPlace.telephone2 &&
                            currentlyPlace.telephone2 !== "")) &&
                        <div className={"d-flex"}>
                          <h6 className={"mr-2"}>Teléfono(s)</h6>
                          <Fragment>
                            <p className={"d-flex flex-column"}>
                              {currentlyPlace.telephone1 &&
                                currentlyPlace.telephone1 !== "" && (
                                  <span>{currentlyPlace.telephone1}</span>
                                )}
                              {currentlyPlace.telephone2 &&
                                currentlyPlace.telephone2 !== "" && (
                                  <span>, {currentlyPlace.telephone2}</span>
                                )}
                            </p>
                          </Fragment>
                        </div>
                      }
                    </div>
                  </div>
                  <div className="schedule">
                    {currentlyPlace.timetable &&
                      <Fragment>
                        <h6>Horarios</h6>
                        {currentlyPlace.timetable.monday_hours_from && (
                          <p >
                            <span>Lunes - Viernes:</span>{" "}
                            {moment(
                              currentlyPlace.timetable.monday_hours_from,
                              "hh:mm:ss"
                            ).format("hh:mm")}am{" "}
                            a{" "}
                            {moment(
                              currentlyPlace.timetable.monday_hours_to,
                              "hh:mm:ss"
                            ).format("hh:mm")}pm
                          </p>
                        )}
                        {currentlyPlace.timetable.saturday_hours_from && (
                          <p >
                            <span>Sábado:</span>{" "}
                            {moment(
                              currentlyPlace.timetable.saturday_hours_from,
                              "hh:mm:ss"
                            ).format("hh:mm")}am{" "}
                            a{" "}
                            {moment(
                              currentlyPlace.timetable.saturday_hours_to,
                              "hh:mm:ss"
                            ).format("hh:mm")}pm
                          </p>
                        )}
                        {currentlyPlace.timetable.sunday_hours_from && (
                          <p >
                            <span>Domingo:</span>{" "}
                            {moment(
                              currentlyPlace.timetable.sunday_hours_from,
                              "hh:mm:ss"
                            ).format("hh:mm")}am{" "}
                            a{" "}
                            {moment(
                              currentlyPlace.timetable.sunday_hours_to,
                              "hh:mm:ss"
                            ).format("hh:mm")}pm
                          </p>
                        )}
                        {currentlyPlace.timetable.extra &&
                          currentlyPlace.timetable.extra !== "" && (
                            <p className="list-group-item">
                              <i>{currentlyPlace.timetable.extra}</i>
                            </p>
                          )}
                      </Fragment>
                    }
                  </div>
                </div>
              </button>
            ))
          }
        </div>

        {
          this.state.showBtnMore &&
          <button className={"btn-more"} onClick={()=>this.setState({textBtnMore: !this.state.textBtnMore})}>{!this.state.textBtnMore ? "Ver menos" : "Ver más"}</button>
        }
      </div>
    )
  }


}


MapPlacePromotion.defaultProps = {
  listPlacePromotion: []
};

MapPlacePromotion.propTypes = {
  listPlacePromotion: PropTypes.array
};

