import React from "react";
import "./style.scss";
import not_found from "../../common/images/not_found.svg";

export default class Error404 extends React.Component {
  render() {
    return (
      <div className="error404-container">
        <div className="error404-box">
          <h3>Lo sentimos, página no encontrada!</h3>
          <div>
          </div>
          <img alt="404" src={not_found} />
        </div>
      </div>
    );
  }
}
