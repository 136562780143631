import {normalize, schema} from 'normalizr';

import {ERROR_DEFAULT, STATUS_ERROR, STATUS_LOADING, STATUS_SUCCESS} from "../common/utils/generic";
import {
  APPEND_PROMOTIONS,
  GET_PROMOTION,
  GET_PROMOTIONS,
  CLEAN_PROMOTION,
  PAY_PROGRAM_PROMOTION,
  PAY_PROGRAM_PROMOTION_CLEAN,
  GET_DETAIL_PROMOTION, GET_QR_PROMOTION, GET_SLUG
} from "../actions/promotions";


const promotionSchema = new schema.Entity('promotions', {}, {idAttribute: 'uid'});
const promotionListSchema = new schema.Array(promotionSchema);


const initialState = {
  promotionStatus: null,
  promotionCurrent: null,
  entities: null,
  results: [],
  listStatus: null,
  listNextStatus: null,
  nextPage: null,
  previousPage: null,
  count: 0,
  paymentStatus: null,
  paymentSuccessBody: null,
  paymentErrors: null,
  slugStatus: null,
  slugDetail: null,
};

export default function reducer(state = initialState, action) {
  const {type, payload} = action;
  let data;
  switch (type) {
    case `${GET_PROMOTIONS}_PENDING`:
      return {
        ...state,
        listStatus: STATUS_LOADING,
        count: 0,
        entities: null,
        nextPage: null,
        results: [],

        promotionStatus: null,
        promotionCurrent: null,
        slugStatus: null,
        slugDetail: null,
      };
    case `${GET_PROMOTIONS}_FULFILLED`:
      data = payload && payload.status === 200 ? normalize(payload.data.results, promotionListSchema) : {};

      return {
        ...state,
        entities: data.entities ? data.entities.promotions : [],
        results: data.result ? data.result : [],
        nextPage: payload && payload.data ? payload.data.next : null,
        previousPage: payload && payload && payload.data ? payload.data.previous : null,
        listStatus: payload && payload.status === 200 ? STATUS_SUCCESS : STATUS_ERROR,
        count: payload && payload.data ? payload.data.count : 0,
      };

    case `${GET_SLUG}_PENDING`:
      return {
        ...state,
        slugStatus: STATUS_LOADING,
        slugDetail: null,
      };
    case `${GET_SLUG}_FULFILLED`:
      return {
        ...state,
        slugStatus: payload && payload.status === 200 ? STATUS_SUCCESS : STATUS_ERROR,
        slugDetail: payload.data,
      };


    case `${APPEND_PROMOTIONS}_PENDING`:
      return {
        ...state,
        listNextStatus: STATUS_LOADING
      };
    case `${APPEND_PROMOTIONS}_FULFILLED`:
      data = payload.status === 200 ? normalize(payload.data.results, promotionListSchema) : {};
      return {
        ...state,
        entities: data.entities ? {...state.entities, ...data.entities.promotions} :{...state.entities} ,
        results: data.result ? [...state.results, ...data.result] : [...state.results] ,
        listNextStatus: payload.status === 200 ? STATUS_SUCCESS : STATUS_ERROR,
        nextPage: payload.data ? payload.data.next : null,
        previousPage: payload && payload.data ? payload.data.previous : null,
        count: payload.data ? payload.data.count : 0,
      };


    case `${GET_PROMOTION}_PENDING`:
      return {
        ...state,
        promotionStatus: STATUS_LOADING,
      };
    case `${GET_PROMOTION}_REJECTED`:
      return {
        ...state,
        promotionStatus: STATUS_ERROR,
      };
    case `${GET_PROMOTION}_FULFILLED`: {
      return {
        ...state,
        promotionStatus: (payload.status === 200 || payload.status === 201) ? STATUS_SUCCESS : STATUS_ERROR,
        errorValidate: payload.status !== 200 ? (payload.status === 400 ? data : ERROR_DEFAULT) : null,
        promotionCurrent: payload.data ? payload.data : null
      };
    }
    case `${GET_DETAIL_PROMOTION}_PENDING`:
      return {
        ...state,
        promotionProgramStatus: STATUS_LOADING,
      };
    case `${GET_DETAIL_PROMOTION}_REJECTED`:
      return {
        ...state,
        promotionProgramStatus: STATUS_ERROR,
      };
    case `${GET_DETAIL_PROMOTION}_FULFILLED`: {
      return {
        ...state,
        promotionProgramStatus: (payload.status === 200 || payload.status === 201) ? STATUS_SUCCESS : STATUS_ERROR,
        errorPromotionProgram: payload.status !== 200 ? (payload.status === 400 ? data : ERROR_DEFAULT) : null,
        promotionProgramDetail: payload.data ? payload.data : null
      };
    }
    case `${GET_QR_PROMOTION}_PENDING`:
      return {
        ...state,
        qrPromotionProgramStatus: STATUS_LOADING,
      };
    case `${GET_QR_PROMOTION}_REJECTED`:
      return {
        ...state,
        qrPromotionProgramStatus: STATUS_ERROR,
      };
    case `${GET_QR_PROMOTION}_FULFILLED`: {
      return {
        ...state,
        qrPromotionProgramStatus: (payload.status === 200 || payload.status === 201) ? STATUS_SUCCESS : STATUS_ERROR,
        errorQrPromotionProgram: payload.status !== 200 ? (payload.status === 400 ? data : ERROR_DEFAULT) : null,
        qrPromotionProgramDetail: payload.data ? payload.data : null
      };
    }
    case `${PAY_PROGRAM_PROMOTION}_PENDING`:
      return {
        ...state,
        paymentErrors: null,
        paymentStatus: STATUS_LOADING,
      };
    case `${PAY_PROGRAM_PROMOTION}_REJECTED`:
      return {
        ...state,
        paymentErrors: ERROR_DEFAULT,
        paymentStatus: STATUS_ERROR,
      };
    case `${PAY_PROGRAM_PROMOTION}_FULFILLED`: {
      return {
        ...state,
        paymentStatus: (payload.status === 200 || payload.status === 201) ? STATUS_SUCCESS : STATUS_ERROR,
        paymentSuccessBody: (payload.status === 200 || payload.status === 201) ? payload.data : null,
        paymentErrors: payload.status !== 200 ? ( payload.status === 400 ? payload.data : ERROR_DEFAULT) : null,
      };
    }
    case PAY_PROGRAM_PROMOTION_CLEAN: {
      return {
        ...state,
        paymentErrors: null,
        paymentStatus: null,
        paymentSuccessBody: null,
      };
    }
    case `${CLEAN_PROMOTION}`:
      return {
        ...state,
        slugStatus: null,
        slugDetail: null,
        promotionStatus: null,
        promotionCurrent: null,
        entities: null,
        results: [],
        listStatus: null,
        listNextStatus: null,
        nextPage: null,
        previousPage: null,
        count: 0,
        paymentStatus: null,
        paymentSuccessBody: null,
        paymentErrors: null
      };
    default:
      return state;
  }
}
