import React, {Fragment} from 'react'
import './style.scss';
import ReactImageAppear from "react-image-appear";
import placeholder from "../../../common/images/placeholder.jpg";
import {STATUS_LOADING, STATUS_SUCCESS} from "../../../common/utils/generic";
import {toast} from "react-toastify";
import Validator from "../../../common/utils/validator";
import complete from "../../../common/images/complete.png";

const $ = require('jquery');

export default class RafflePopUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      load: false,
      programUid: null,
      showRegister: false,
      showToastSuccess: false,
      showDismiss: false,
      terms_conditions: true,
      policies: true,
      email: "",
      document_number: "",
      first_name: "",
      mobile_number: "",
    }
  }

  componentDidMount() {
    this.validator = new Validator([
      {
      id: "email",
      ref: this.emailInput,
      rules: ["email"]
      },
      {
      id: "mobile_number",
      ref: this.mobileNumberInput,
      rules: ["numeric"]
      }
    ])
    let {user} = this.props
    this.setState({
      document_number: user.document_number,
      first_name: `${user.first_name} ${user.last_name}`,
      email: user.email,
      mobile_number: user.mobile_number,
    })
    this.updateComponent();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.updateComponent();
  }

  updateComponent = () => {
    let {
      getRaffle,
      programs,
      raffleUid,
      activePopUp,
      openPopUp,
      componentPopUp,
      registerStatus,
      dismissStatus,
      getRaffles
    } = this.props
    if(programs && programs.length!==0 && !this.state.load){

      getRaffle(programs[0].uid, raffleUid)
      this.setState({load: true, programUid: programs[0].uid})
    }
    if(this.state.showRegister){
      if(registerStatus===STATUS_SUCCESS){
        console.log(this.state.showToastSuccess)
        if(this.state.showToastSuccess){
          toast.success("Se ha inscrito correctamente en el sorteo")
          this.setState({showToastSuccess: false})
        }
      }
    }

    if(this.state.showDismiss){
      if(dismissStatus===STATUS_SUCCESS){
        toast.success("Se eliminó correctamente el sorteo")

        this.setState(  {
          programUid: null,
          showRegister: false,
          showToastSuccess: false,
          showDismiss: false,
        });
        $(this.modal).modal('hide');
        getRaffles(localStorage.getItem("programUid"))
      }
    }

    if (activePopUp) {
      $('#raffleModal').modal('show');
      openPopUp(false, componentPopUp);
    }
    $('body').css("padding", "0");
  }

  register = e => {
    let {user, history, registerInRaffle,raffleUid} = this.props
    e.preventDefault()
    if(user.email.length===0 || user.mobile_number.length===0){
      toast.error("Debe completas todos sus datos personales para inscribirse en el sorteo")
      history.push("/profile")
    }else{
      registerInRaffle({
        "area":user.area.uid,
        "mobile_number":user.mobile_number,
        "email": user.email
      },this.state.programUid, raffleUid)
      this.setState({showRegister: true, showToastSuccess: true})
    }
  }

  setFormValues = (propertiesToUpdate) => {
    this.setState(propertiesToUpdate);
    this.validator.validate(propertiesToUpdate);
  }

  closeModal = (e) => {
    let {getRaffles} = this.props
    e.preventDefault()
    this.setState(  {
      programUid: null,
      showRegister: false,
      showToastSuccess: false,
      showDismiss: false,
    });
    $(this.modal).modal('hide');
    getRaffles(localStorage.getItem("programUid"))
  }

  onSubmitForm = async (event) => {
    event.preventDefault();

    let {user, registerInRaffle,raffleUid} = this.props
    let payload = {...this.state}

    if (!this.state.terms_conditions) {
      toast.info("Aceptar los términos y condiciones es obligatorio");
      return;
    }
    if (!this.state.policies) {
      toast.info("Aceptar las políticas es obligatorio");
      return;
    }

    this.setState({showErrorFirstTime: false})

    delete payload.showErrorFirstTime
    delete payload.terms_conditions
    delete payload.policies
    delete payload.load
    delete payload.programUid
    if (this.validator.validate(payload)) {
      if(this.state.email.length===0 || this.state.mobile_number.length===0){
        toast.error("Debe completas todos sus datos personales para inscribirse en el sorteo");
        return;
      }else{
        registerInRaffle({
          "area":user.area.uid,
          "mobile_number":this.state.mobile_number,
          "email": this.state.email
        },this.state.programUid, raffleUid)
        this.setState({showRegister: true, showToastSuccess: true})
      }
    }


  }

  render() {
    let {raffles, dismissToRaffle, rafflesStatus} = this.props
    let raffle = null
    if(raffles){
      raffle = raffles[0]
    }

    return (
          <div>
            <div>
              <div
                className={"modal fade"}
                id="raffleModal"
                ref={(modal) => this.modal = modal}
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
                data-backdrop="static" data-keyboard="false"
              >
                <div
                  className={"modal-dialog modal-dialog-centered  modal-lg"}
                  role="document">
                  <div className={rafflesStatus === STATUS_LOADING ? "modal-content load" : "modal-content "}>

                    {
                      !this.state.showRegister &&
                      <div
                        className={rafflesStatus === STATUS_LOADING ? "modal-header load" : "modal-header "}>
                        { rafflesStatus === STATUS_LOADING &&
                        <div className={"cover load"}>
                        </div>
                        }
                        { (rafflesStatus === STATUS_SUCCESS && raffle) &&
                        <ReactImageAppear
                          className="cover"
                          loader={placeholder}
                          loaderClass="cover"
                          placeholderClass={"placeholder"}
                          src={raffle.image ? raffle.image : ""}
                          animationDuration="1s"
                        />
                        }

                        <button type="button" className="close btn-close-ad btn-close-product" data-dismiss="modal" aria-label="Close"
                                style={{zIndex: 99, paddingRight: "25px"}} onClick={e=>this.closeModal(e)}>
                          <span aria-hidden="true" style={{fontSize:"30px"}}>&times;</span>
                        </button>

                      </div>

                    }
                    <div className={!this.state.showRegister ? "modal-body" : "modal-body small d-flex align-items-center"}>
                      {
                        !this.state.showRegister ?
                        <div className={"detail"}>
                          {rafflesStatus === STATUS_SUCCESS && raffle &&
                          <Fragment>
                            <div className="header-detail d-flex">
                              <Fragment>
                                <span>{raffle.title}</span>
                              </Fragment>
                            </div>
                            <div className={"col-12 col-md-12 mx-auto"}>
                              <h5 className={"font-weight-bold"} style={{fontSize: "1.4rem"}}>{raffle.name}</h5>

                              <h5 style={{color: "#6b6b6b"}}>{raffle.terms}</h5>

                              <form className="form m-0" onSubmit={this.onSubmitForm}>
                                {
                                  this.state.document_number.length !== 0 &&
                                  <div className="form-group p-0">
                                    <div className="input-group">
                                      <input
                                        value={this.state.document_number}
                                        className="form-control"
                                        onChange={e => this.setFormValues({document_number: e.target.value})}
                                        type="text"
                                        placeholder="Documento de identidad *"
                                        disabled={true}
                                      />
                                    </div>
                                  </div>
                                }
                                {
                                  this.state.first_name.length !== 0 &&
                                  <div className="form-group p-0">
                                    <div className="input-group">
                                      <input
                                        className={"form-control"}
                                        value={this.state.first_name}
                                        onChange={e => this.setFormValues({first_name: e.target.value})}
                                        type="text"
                                        placeholder="Nombres y Apellidos*"
                                        disabled={true}
                                      />
                                    </div>
                                  </div>
                                }
                                <div className="form-group p-0">
                                  <div>
                                    <div
                                      className="input-group"
                                      ref={(input) => {
                                        if (input) {
                                          this.emailInput = input.querySelector('input');
                                        }
                                      }}
                                    >
                                      <input
                                        className={"form-control"}
                                        value={this.state.email}
                                        onChange={e => this.setFormValues({email: e.target.value})}
                                        type="email"
                                        placeholder="Correo Electrónico *"
                                        disabled={this.state.email.length !== 0 ? true : false}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="form-group p-0">
                                  <div>
                                    <div
                                      className="input-group"
                                      ref={(input) => {
                                        if (input) {
                                          this.mobileNumberInput = input.querySelector('input');
                                        }
                                      }}
                                    >
                                      <input
                                        className={"form-control"}
                                        value={this.state.mobile_number}
                                        onChange={e => this.setFormValues({mobile_number: e.target.value})}
                                        type="text"
                                        placeholder="Celular *"
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div className="terms mt-3">
                                  <div className="form-check">
                                    <input type="checkbox"
                                           onChange={e => {
                                             this.setState({terms_conditions: e.target.checked})
                                           }
                                           }
                                           checked={this.state.terms_conditions}
                                           className="form-check-input" id="checkbox-terms"/>
                                    <label className="ml-2 form-check-label" htmlFor="exampleCheck1"> <span
                                      onClick={e => {
                                        this.setState({terms_conditions: !this.state.terms_conditions})
                                      }}
                                    >Acepto los</span> Términos y Condiciones</label>
                                  </div>

                                </div>

                                <div className="terms">
                                  <div className="form-check">
                                    <input type="checkbox"
                                           onChange={e => {
                                             this.setState({policies: e.target.checked})
                                           }
                                           }
                                           checked={this.state.policies}
                                           className="form-check-input" id="checkbox-terms"/>
                                    <label className="ml-2 form-check-label" htmlFor="exampleCheck1"> <span
                                      onClick={e => {
                                        this.setState({policies: !this.state.policies})
                                      }}
                                    >Acepto las</span> Políticas de privacidad</label>
                                  </div>

                                </div>

                                <button
                                  type="submit"
                                  style={{
                                    textOverflow: "ellipsis",
                                    overflow: "hidden",
                                    width: "80%",
                                    textTransform: "none",
                                    margin: "auto"
                                  }}
                                  className="btn  btn-primary mt-4 d-flex justify-content-center ">
                                  Registrarme
                                </button>

                                <button
                                  style={{
                                    textOverflow: "ellipsis",
                                    overflow: "hidden",
                                    width: "80%",
                                    textTransform: "none",
                                    margin: "auto"
                                  }}
                                  className="btn  btn-secondary btn-cancel mb-2 mt-3 d-flex justify-content-center "
                                  onClick={e => {
                                    e.preventDefault()
                                    dismissToRaffle(this.state.programUid, raffle.uid)
                                    this.setState({showDismiss: true})
                                  }}>
                                  No me interesa
                                </button>
                              </form>
                            </div>
                          </Fragment>
                          }
                        </div>
                        :
                          <Fragment>

                            <div className={" flex-column col-12 d-flex align-items-center"}>
                              <img src={complete} alt="complete" style={{width: 100, height: 100}}/>
                              <h3>Hemos recibido tus datos</h3>
                              <p>Ya estas participando</p>
                            </div>
                            <button type="button" className="close btn-close-ad btn-close-product" data-dismiss="modal" aria-label="Close"
                                    style={{zIndex: 99, paddingRight: "25px"}} onClick={e=>this.closeModal(e)}>
                              <span aria-hidden="true" style={{fontSize:"30px"}}>&times;</span>
                            </button>
                          </Fragment>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
    );
  }
}
