import {normalize, schema} from 'normalizr';

import {STATUS_ERROR, STATUS_LOADING, STATUS_SUCCESS} from "../common/utils/generic";
import {GET_SECTIONS} from "../actions/sections";


const sectionSchema = new schema.Entity('sections', {}, {idAttribute: 'uid'});
const sectionListSchema = new schema.Array(sectionSchema);


const initialState = {
  entities: null,
  results: [],
  listStatus: null,
  count: 0
};

export default function reducer(state = initialState, action) {
  const {type, payload} = action;
  let data;
  switch (type) {

    case `${GET_SECTIONS}_PENDING`:
      return {
        ...state,
        listStatus: STATUS_LOADING
      };

    case `${GET_SECTIONS}_FULFILLED`:
      data = payload.status === 200 ? normalize(payload.data, sectionListSchema) : {};

      return {
        ...state,
        entities: data.entities ? data.entities.sections : [],
        results: data.result ? data.result : [],
        listStatus: payload.status === 200 ? STATUS_SUCCESS : STATUS_ERROR,
        count: payload.data ? payload.data.length : 0,
      };

    default:
      return state;
  }
}
