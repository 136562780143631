import React, { Component, Fragment } from "react";
import CoinSvg from '../../../../common/images//Coin.svg'
import moment from "moment";

export default class OrderItem extends Component {
  renderStatus = (status) => {
    switch (status) {
      case "received":
        return "Orden recibida";
      case "delivered":
        return "Orden entregada";
      case "canceled_by_place":
        return "Orden Cancelada";
      case "canceled_by_user":
        return "Orden Cancelada por el usuario";
      case "accepted":
        return "Orden aceptada";
      case "Aceptada":
        return "Orden aceptada";
      case "shipped":
        return "Orden enviada";
      default:
        return "--";
    }
  };

  render() {
    let {
      order,
      loading,
      listCoins,
      //setSelectedOrder
    } = this.props;
    //console.log(order, "order");
    return (
      <Fragment>
        {loading ? (
          <div>
            <div className={"order-item load"}>
              <div className={"order-left col-12 col-sm-10 pl-0"}>
                <p className={"order-date"}></p>
                <p className={"order-products"}></p>
              </div>
              <div className={"order-right col-12 col-sm-2 pl-0"}>
                <p className={"order-price"}></p>
              </div>
            </div>
            <div className={"order-item load"}>
              <div className={"order-left col-12 col-sm-10 pl-0"}>
                <p className={"order-date"}></p>
                <p className={"order-products"}></p>
              </div>
              <div className={"order-right col-12 col-sm-2 pl-0"}>
                <p className={"order-price"}></p>
              </div>
            </div>
            <div className={"order-item load"}>
              <div className={"order-left col-12 col-sm-10 pl-0"}>
                <p className={"order-date"}></p>
                <p className={"order-products"}></p>
              </div>
              <div className={"order-right col-12 col-sm-2 pl-0"}>
                <p className={"order-price"}></p>
              </div>
            </div>
          </div>
        ) : (
          <div className={"order-item flex-column flex-sm-row"}>
            {listCoins ? (
              <div className="coins-list-box">
                <div className="coins-info">
                  <p className={"order-date"}>
                    {order.created_at
                      ? moment(order.created_at).format("LLL")
                      : "-"}
                  </p>
                  <p className={"order-products"}>
                    {order.order_product_to_text}
                  </p>
                </div>
                <div className="coins-status">
                  <div className={"order-price"}>
                    <img src={CoinSvg} alt="coin" />
                    <span>- {order.total ? order.total : "0"}</span>
                  </div>
                  <p className={"order-status"}>
                    Status: <span className={order.status}>{this.renderStatus(order.status)}</span>
                  </p>
                  <div
                    className="order-modal"
                    data-toggle="modal"
                    data-target="#orderModal"
                    onClick={(e) => {
                      e.preventDefault();
                      this.props.setSelectedOrder(order);
                    }}
                  >
                   <span> Ver Código</span>
                  </div>
                </div>
              </div>
            ) : (
              <Fragment>
                <div
                  className={"order-left col-12 col-sm-10 pl-0"}
                  data-toggle="modal"
                  data-target="#orderModal"
                  onClick={(e) => {
                    e.preventDefault();
                    this.props.setSelectedOrder(order);
                  }}
                >
                  <p className={"order-date"}>
                    {order.created_at
                      ? moment(order.created_at).format("LLL")
                      : "-"}
                  </p>
                  <p className={"order-products"}>
                    {order.order_product_to_text}
                  </p>
                </div>
                <div className={"order-right col-12 col-sm-2 pl-0"}>
                  <p className={"order-price"}>
                    {order.currency ? order.currency.symbol : "S/. "}
                    {order.total ? order.total : "0.0"}
                  </p>
                </div>
              </Fragment>
            )}
          </div>
        )}
      </Fragment>
    );
  }
}
