import React, {Fragment} from "react";
import {Link} from "react-router-dom";
import placeholder from "../../../../common/images/placeholder.jpg";
import ReactImageAppear from 'react-image-appear';
import PropTypes from "prop-types";
import Stamp from "./Stamp";


const Promotion = props => {
  let {app, promotion, color, is_detail, programUid, loading, multisite, company, openPopUp, see_information_btn} = props
  let mainColor = "#20a6ff"
  let style_class = is_detail ? "program-promotion-card no-hover col-xs-12 col-sm-9 mx-auto detail" : "program-promotion-card col-11 col-xs-12 col-md-4 col-sm-6 col-xl-4";
  if (promotion && promotion.company_retail && !loading) {
    let show_btn_exchange = (promotion.validation_by==="QR" && (promotion.allow_steps || promotion.allow_qr)) ? true : false

    let pinImage = promotion.pin && promotion.pin.image;
    let pin = (promotion.pin && promotion.pin.name) ? promotion.pin.name.split(" ")[0] : "";

    let stamps = [];
    let required_stamps = promotion.required_stamps ? promotion.required_stamps : 0
    if (required_stamps > 0) {
      for (let i = 0; i < required_stamps; i++) {
        stamps.push(promotion.available_stamps > i);
      }
    }
    //let show_btn_validation_by_user = true
    let show_btn_validation_by_user = (promotion.available_uses > 0 && promotion.available_times_used > 0 && promotion.usage_limit_per_user  >= 0)
    /*
    let backgroundImage = {
      backgroundImage: "url(" + promotion.image + ")"
    };

     */

    let body = (
      <Fragment>
        {
          props.promotion.type !== "GIFT_CARD" ?
            <div className={is_detail ? "credit-cards-item detail" : "credit-cards-item no-detail"}>
              <div className={"credit-cards-item-content"}>
                <div className={"promotion-item"}>
                  <div className={"promotion-item-image"}
                       style={{"backgroundImage": `url(${promotion.image})`}}>
                    {
                      promotion.pin && promotion.pin.image &&
                      <img alt={"pin"} className="pin-image banbif" src={promotion.pin.image}/>
                    }
                    <img alt={"company"} className="company-image banbif" src={promotion.company_retail && promotion.company_retail.logo}/>

                  </div>
                  <div className={"promotion-item-detail"}>
                    <h1>{promotion.company_retail && promotion.company_retail.name}</h1>
                    <h2>{promotion.title}</h2>
                    <p>{promotion.subtitle}</p>
                  </div>

                  {promotion.is_available === false &&
                    <div className="used-promotion">
                      {props.promotion.is_used > 0 ? <p>La promoción ya fue usada</p> :
                        <p>La promoción ya no puede ser usada</p>}
                    </div>
                  }
                </div>

              </div>
            </div>
            : <figure>
              <ReactImageAppear
                className="cover"
                loader={placeholder}
                loaderClass="cover"
                placeholderClass={"placeholder"}
                src={promotion.image}
                animationDuration="1s"
              />
              {
                props.promotion.type === "GIFT_CARD" ?
                  <div>
                    <div className="company-logo"
                         style={{backgroundImage: promotion.company_retail ? `ùrl(${promotion.company_retail.logo})` : `none`}}
                    />
                  </div> :
                  <div className="info ml-2">

                    <div className="col">
                      {required_stamps <= 0 ? (pinImage ?
                            <img alt={"pin"} className="pin-image" src={promotion.pin.image}/> :
                            <p className="pin">{pin}</p>
                        ) :
                        (
                          <div className="row stamps p-0">
                            {stamps.map(function (item, i) {
                              return <div className="stamp" style={item ? {background: color} : {}}
                                          key={`stamp${promotion.uid}${i}`}/>
                            })
                            }
                          </div>
                        )
                      }
                    </div>
                    <div className="row detail">
                      <div className="col">
                        <p className="title">{promotion.title} </p>
                        <p className="subtitle">{promotion.company_retail.name}</p>
                      </div>
                    </div>
                  </div>
              }

              {promotion.is_available === false &&
                <div className="used-promotion">
                  {props.promotion.is_used > 0 ? <p>La promoción ya fue usada</p> :
                    <p>La promoción ya no puede ser usada</p>}
                </div>
              }
            </figure>
        }
      </Fragment>
    );
    let url = ''
    let colorDetails = promotion && promotion.color ? promotion.color.replace("#", "") :"36AA8C"

    if (multisite && company)
      url = `/c/${company.uid}`
    return (
      <div className={""}>
        {props.is_detail ?
          <Fragment>
            <div className={"box-detail-promotion"}>
              {promotion.type !== "STAMPS"&& (
                <div
                  className="BoxDetailPromo"
                  style={promotion.color.length>2 ? { background: `#${colorDetails}`} : { background: "#36AA8C"}}
                >
                  {promotion.type === "GIFT" && (
                    <>
                      <h1>GRA</h1>
                      <h1>TIS</h1>
                    </>
                  )}
                  {promotion.type === "SPECIAL_PRICE" && (
                    <>
                      <h1>ESPE</h1>
                      <h1>CIAL</h1>
                    </>
                  )}
                  {promotion.type === "RATE" && (
                    <>
                      {promotion.rate_discount === 100 ? (
                        <>
                          <h1>GRA</h1>
                          <h1>TIS</h1>
                        </>
                      ) : (
                        <>
                          <h1>{promotion.rate_discount}%</h1>
                          <h2>de dscto.</h2>
                        </>
                      )}
                    </>
                  )}
                  {promotion.type === "FIXED" && (
                    <>
                      {promotion.fixed_discount && (
                        <>
                          {app.company.currency && <h1>{app.company.currency.symbol}</h1> }
                          <h1>{promotion.fixed_discount.replace(".", ",")}</h1>
                        </>
                      )
                      }
                    </>
                  )}
                </div>
              )
              }
              {promotion.type === "STAMPS" && (
                <Stamp is_detail={false} programUid={programUid} promotion={promotion} />
              )}
              {/* <Promotion is_detail={true}
                            programUid={programUid}
                            promotion={promotion}/> */}
              {(promotion) &&  promotion.link && promotion.link.length > 0 && (
                <a
                  href={promotion.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn btn_detailStamp"
                >
                  Más Información
                </a>
              )}




              {see_information_btn() && (
                <a
                  href={promotion.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn btn-secondary link-more-banbif"
                >
                  Más Información
                </a>
              )}
              {
                show_btn_exchange &&
                <button
                  className="btn btn-secondary btn-exchange"
                  onClick={()=>{openPopUp(promotion.uid, "qr")}}
                  style={{backgroundColor: `${mainColor}`, border: `2px solid ${mainColor}`}}
                >
                  Canjear
                </button>
              }
              {
                promotion.available_uses > 0 && promotion.validation_by==="BY USER" &&
                <button
                  className={show_btn_validation_by_user ? "btn btn-secondary btn-exchange" : "btn btn-secondary btn-exchange btn-disabled"}
                  onClick={()=>{openPopUp(promotion.uid, "by_user")}}
                  style={{backgroundColor: `${mainColor}`, border: `2px solid ${mainColor}`}}
                >
                  Activar Promoción
                </button>
              }
              {
                promotion.available_uses <= 0 &&
                <p className={"text-center font-weight-bold"}>Usted ya sobrepasó su numero de canjes para esta promoción.</p>
              }
              {
                promotion.available_times_used <= 0 &&
                <p className={"text-center font-weight-bold"}>La cantidad de stock de esta promoción está agotado.</p>
              }
            </div>

          </Fragment>
          :
          <Link to={`${url}/program/${programUid}/promotions/${props.promotion.slug}`}>{body}</Link>}
      </div>
    );
  } else {
    return (
      <div className={`${style_class} load`}>
        <div>
          <figure>
            <div className="info">
              <div className={"pin loading"}>
                <div/>
              </div>
              <div className="row detail">
                {
                  false &&
                  <div className="col">
                    <p className="title loading"/>
                    <p className="subtitle loading"/>
                  </div>
                }
              </div>
            </div>
          </figure>

        </div>
      </div>
    )
  }

};

Promotion.defaultProps = {
  is_detail: false,
  loading: false,
  promotion: null,
  multisite: false,
  company: null,
  color: "#ccc"
};

Promotion.propTypes = {
  promotion: PropTypes.object,
  is_detail: PropTypes.bool,
  loading: PropTypes.bool,
  multisite: PropTypes.bool,
  company: PropTypes.object,
  color: PropTypes.string,
};
export default Promotion;