import React from "react";
import Icon from "../../../common/icons/icon";
import PropTypes from "prop-types";
import "./style.scss"

import moment from "moment"


export default class NavDates extends React.Component {


  componentDidMount() {
  }

  selectDate = (d, index) =>{
    let {
      selectedDate,
      selectDate
    } = this.props;
    if(selectedDate && selectedDate.date!==d.date){
      selectDate(d, index)
    }

  }

  render() {
    let {
      selectedDate,
      dates
    } = this.props;
    return (
      <div className="nav-item dropdown nav-dates">
        <button className="nav-link nav-select"
                id="menu-dates"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false">
          <span>fecha</span><br/>

          { selectedDate ?
            `${moment(selectedDate.date).format('dddd, Do [de] MMMM YYYY')}` : "Seleccione una fecha"
          }

          <Icon svg="down" classes="svg-icon down" title="Down"/>
        </button>
        <div className="dropdown-menu dropdown-select" aria-labelledby="menu-dates">
          {
            dates.map((d, index) =>
              <div key={`date${index}`} className="dropdown-item"
                   onClick={event => this.selectDate(d, index)}
              >
                <span>{moment(d.date).format('dddd, Do [de]] MMMM YYYY')}</span>
              </div>
            )
          }

        </div>
      </div>

    );
  }
}

NavDates.propTypes = {
  selectedDate: PropTypes.object,
  selectedHour: PropTypes.object,
  dates: PropTypes.array,
};
