import {combineReducers} from 'redux';
import app from './app';
import auth from './auth';
import places from './places';
import menus from './menus';
import user from './user';
import programs from './programs';
import sections from './sections';
import promotions from './promotions';
import placesPromotion from './placesByPromotion';
import relatedPromotion from './relatedToPromotion';
import productsPlace from './productsByPlace';
import sectionsProducts from './sectionsToProducts';
import popup from './popup';
import areas from './areas';
import pickupSchedule from './pickupSchedule';
import cart from './cart';
import redemptions from './redemptions';
import coupon from './coupon';
import subscriptions from './subscriptions';
import ads from './ads';
import raffles from './raffles';
import delivery from './delivery';
import orders from './orders';
import polls from './polls';
import events from './events';


export default combineReducers({
  app,
  auth,
  places,
  menus,
  sections,
  user,
  promotions,
  programs,
  placesPromotion,
  relatedPromotion,
  productsPlace,
  sectionsProducts,
  popup,
  areas,
  pickupSchedule,
  cart,
  redemptions,
  coupon,
  subscriptions,
  ads,
  raffles,
  delivery,
  orders,
  polls,
  events
});
