import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

const Stamp = (props) => {
  let { promotion, color, programUid, loading, multisite, company, is_detail } =
    props;
  //console.log(props, "stamps");

  if (!loading) {
    let stamps = [];
    let required_stamps = promotion.required_stamps
      ? promotion.required_stamps
      : 0;
    if (required_stamps > 0) {
      for (let i = 0; i < required_stamps; i++) {
        stamps.push(promotion.available_stamps > i);
      }
    }

    let body = (
      <figure>
        {
          <div className="StampsContainer">
            {required_stamps > 0 && required_stamps < 7 && (
              <>
                <div
                  className="StampsBox"
                  style={{
                    background: promotion.color ? promotion.color : color,
                  }}
                >
                  <div className="row StampsBox_1 p-0">
                    {stamps.map(function (item, i) {
                      return (
                        <div
                          className={item ? "stamps-use" : {}}
                          style={
                            item
                              ? {
                                  background: `${
                                    promotion.color.includes("#")
                                      ? promotion.color
                                      : `#${promotion.color}`
                                  }`,
                                }
                              : {}
                          }
                          key={`stamp${promotion.uid}${i}`}
                        >
                          {i + 1}
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="row StampsDataBox">
                  <div className="col StampsData">
                    <h4 className="title">{promotion.title} </h4>
                    <p className="subtitle">
                      {promotion.content
                        ? promotion.content.substring(0, 50)
                        : ""}
                    </p>
                  </div>
                  <div
                    className="StampsDataColor"
                    style={{
                      background: promotion.color ? promotion.color : color,
                    }}
                  ></div>
                </div>
              </>
            )}
            {required_stamps > 6 && required_stamps < 11 && (
              <>
                <div
                  className="StampsBox"
                  style={{
                    background: promotion.color ? promotion.color : color,
                  }}
                >
                  <div className="row StampsBox_2 p-0">
                    {stamps.map(function (item, i) {
                      return (
                        <div
                          className={item ? "stamps-use" : {}}
                          style={
                            item
                              ? {
                                  background: `${
                                    promotion.color.includes("#")
                                      ? promotion.color
                                      : `#${promotion.color}`
                                  }`,
                                }
                              : {}
                          }
                          key={`stamp${promotion.uid}${i}`}
                        >
                          {i + 1}
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="row StampsDataBox">
                  <div className="col StampsData">
                    <h4 className="title">{promotion.title} </h4>
                    <p className="subtitle">
                      {promotion.content
                        ? promotion.content.substring(0, 50)
                        : ""}
                    </p>
                  </div>
                  <div
                    className="StampsDataColor"
                    style={{
                      background: promotion.color ? promotion.color : color,
                    }}
                  ></div>
                </div>
              </>
            )}
          </div>
        }
      </figure>
    );
    let url = "";
    if (multisite && company) url = `/c/${company.uid}`;
    return (
      <div>
        {!props.promotion.is_available ? (
          <>
            <div style={{ opacity: "0.5" }}>{body}</div>
            {promotion.is_available === false && (
              <div className="used-promotion-container">
                {promotion.is_used > 0 ? (
                  <h6>La promoción ya fue usada</h6>
                ) : (
                  <h6>La promoción ya no puede ser usada</h6>
                )}
              </div>
            )}
          </>
        ) : (
          <>
            {is_detail ? (
              <Link
                to={`${url}/program/${programUid}/promotions/${props.promotion.uid}`}
              >
                {body}
              </Link>
            ) : (
              <div>{body}</div>
            )}
          </>
        )}
      </div>
    );
  } else {
    const stamps = [0, 1, 2, 3, 4];
    return (
      <div className="StampsContainer ml-5" style={{width : "250px"}}>
        <div
          className="StampsBox"
          style={{
            background: "#b6b6b6",
          }}
        >
          <div className="row StampsBox_1 p-0">
            {stamps.map(function (item, i) {
              return (
                <div
                  className={item ? "stamps-use" : {}}
                  style={
                    item
                      ? {
                          background: "#b6b6b6",
                        }
                      : {}
                  }
                  key={`stamp-${i}`}
                >
                  &nbsp;
                </div>
              );
            })}
          </div>
        </div>
        <div className="row StampsDataBox">
          <div className="col StampsData">
            <h4 className="title"> </h4>
            <p className="subtitle"></p>
          </div>
          <div
            className="StampsDataColor"
            style={{
              background: "#b6b6b6",
            }}
          ></div>
        </div>
      </div>
    );
  }
};

Stamp.defaultProps = {
  is_detail: false,
  loading: false,
  promotion: null,
  multisite: false,
  company: null,
  color: "#FCC02D",
};

Stamp.propTypes = {
  promotion: PropTypes.object,
  is_detail: PropTypes.bool,
  loading: PropTypes.bool,
  multisite: PropTypes.bool,
  company: PropTypes.object,
  color: PropTypes.string,
};
export default Stamp;
