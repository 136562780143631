import {normalize, schema} from 'normalizr';

import {STATUS_ERROR, STATUS_LOADING, STATUS_SUCCESS} from "../common/utils/generic";
import {GET_SECTIONS_BY_COMPANY, GET_SECTIONS_PRODUCTS} from "../actions/sectionsToProducts";


const sectionProductSchema = new schema.Entity('sectionProduct', {}, {idAttribute: 'uid'});
const sectionProductListSchema = new schema.Array(sectionProductSchema);


const initialState = {
  entities: null,
  results: [],
  listStatus: null,
  count: 0,

  entitiesCategoriesByCompany: null,
  resultsCategoriesByCompany: [],
  listStatusCategoriesByCompany: null,
  countCategoriesByCompany: 0,
};

export default function reducer(state = initialState, action) {
  const {type, payload} = action;
  let data;
  switch (type) {

    case `${GET_SECTIONS_PRODUCTS}_PENDING`:
      return {
        ...state,
        listStatus: STATUS_LOADING
      };

    case `${GET_SECTIONS_PRODUCTS}_FULFILLED`:
      data = payload.status === 200 ? normalize(payload.data, sectionProductListSchema) : {};

      return {
        ...state,
        entities: data.entities ? data.entities.sectionProduct : [],
        results: data.result ? data.result : [],
        listStatus: payload.status === 200 ? STATUS_SUCCESS : STATUS_ERROR,
        count: payload.data ? payload.data.length : 0,
      };

    case `${GET_SECTIONS_BY_COMPANY}_PENDING`:
      return {
        ...state,
        listStatusCategoriesByCompany: STATUS_LOADING
      };

    case `${GET_SECTIONS_BY_COMPANY}_FULFILLED`:
      data = payload.status === 200 ? normalize(payload.data.results, sectionProductListSchema) : {};
      return {
        ...state,
        entitiesCategoriesByCompany: data.entities ? data.entities.sectionProduct : [],
        resultsCategoriesByCompany: data.result ? data.result : [],
        listStatusCategoriesByCompany: payload.status === 200 ? STATUS_SUCCESS : STATUS_ERROR,
        countCategoriesByCompany: payload.data ? payload.data.length : 0,
      };

    default:
      return state;
  }
}
