import {normalize, schema} from 'normalizr';
import {ERROR_DEFAULT, STATUS_ERROR, STATUS_LOADING, STATUS_SUCCESS} from "../common/utils/generic";

import {APPEND_SUBSCRIPTIONS,GET_SUBSCRIPTION,GET_SUBSCRIPTIONS, UPDATE_SUBSCRIPTION, CLEAN_UPDATE} from "../actions/subscriptions";

const subscriptionSchema = new schema.Entity('subscriptions', {}, {idAttribute: 'uid'});
const subscriptionListSchema = new schema.Array(subscriptionSchema);



const initialState = {

  subscriptionStatus: null,
  subscriptionCurrent: null,
  entities: null,
  results: [],
  listStatus: null,
  listNextStatus: null,
  nextPage: null,
  count: 0,
};

export default function reducer(state = initialState, action) {
  const {type, payload} = action;
  let data;
  switch (type) {
    case `${GET_SUBSCRIPTIONS}_PENDING`:
      return {
        ...state,
        listStatus: STATUS_LOADING,
        count: 0,
        entities: null,
        nextPage: null,
        results: []
      };
    case `${GET_SUBSCRIPTIONS}_FULFILLED`:
      data = payload.status === 200 ? normalize(payload.data.results, subscriptionListSchema) : {};
      return {
        ...state,
        entities: data.entities ? data.entities.subscriptions : [],
        results: data.result ? data.result : [],
        nextPage: payload.data ? payload.data.next : null,
        listStatus: payload.status === 200 ? STATUS_SUCCESS : STATUS_ERROR,
        count: payload.data ? payload.data.count : 0,
      };


    case `${APPEND_SUBSCRIPTIONS}_PENDING`:
      return {
        ...state,
        listNextStatus: STATUS_LOADING
      };
    case `${APPEND_SUBSCRIPTIONS}_FULFILLED`:
      data = payload.status === 200 ? normalize(payload.data.results, subscriptionListSchema) : {};
      return {
        ...state,
        entities: data.entities ? {...state.entities, ...data.entities.subscriptions} :{...state.entities} ,
        results: data.result ? [...state.results, ...data.result] : [...state.results] ,
        listNextStatus: payload.status === 200 ? STATUS_SUCCESS : STATUS_ERROR,
        nextPage: payload.data ? payload.data.next : null,
        count: payload.data ? payload.data.count : 0,
      };


    case `${GET_SUBSCRIPTION}_PENDING`:
      return {
        ...state,
        subscriptionStatus: STATUS_LOADING,
      };
    case `${GET_SUBSCRIPTION}_REJECTED`:
      return {
        ...state,
        subscriptionStatus: STATUS_ERROR,
      };
    case `${GET_SUBSCRIPTION}_FULFILLED`: {
      return {
        ...state,
        subscriptionStatus: (payload.status === 200 || payload.status === 201) ? STATUS_SUCCESS : STATUS_ERROR,
        errorValidate: payload.status !== 200 ? (payload.status === 400 ? data : ERROR_DEFAULT) : null,
        subscriptionCurrent: payload.data ? payload.data : null
      };
    }

    case `${UPDATE_SUBSCRIPTION}_PENDING`:
      return {
        ...state,
        updateStatus: STATUS_LOADING,
      };
    case `${UPDATE_SUBSCRIPTION}_REJECTED`:
      return {
        ...state,
        updateStatus: STATUS_ERROR,
      };
    case `${UPDATE_SUBSCRIPTION}_FULFILLED`: {
      return {
        ...state,
        updateStatus: (payload.status === 200 || payload.status === 201) ? STATUS_SUCCESS : STATUS_ERROR,
        errorUpdate: payload.status !== 200 ? (payload.status === 400 ? data : ERROR_DEFAULT) : null
      };
    }
    case `${CLEAN_UPDATE}`:
      return {
        ...state,
        updateStatus: null,
      };

    default:
      return state;
  }
}
