import React from 'react'
import PropTypes from 'prop-types';
import './style.scss';
import Icon from "../../../common/icons/icon";
import SelectDays from "./component/SelectDays";
import moment from "moment";
import {PICKUP_METHOD, DELIVERY_METHOD} from "../../redemptions/constants";

export default class SubscriptionOptions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subscriptionsDaysEnabled: null,
      subscriptionsDaysSelected: [],

      deliveryMethodsText:"PICKUP O DELIVERY",
      deliveryMethods: null,
      deliveryMethodCurrent: null,

      subscriptionScheduleText:"RANGO DE HORARIO",
      subscriptionPickupIntervals: null,
      subscriptionDeliveryIntervals: null,
      subscriptionScheduleSelected: null,

      initialLoaded: false
    }
  }

  static getDerivedStateFromProps(props, state) {
    const {
       selectedPlace
    } = props;

    if(!state.initialLoaded){
      return {
        ...state,
        subscriptionsDaysEnabled: [ ...selectedPlace.subscriptions_days_enabled.map(s => ({
          uid: s,
          value: s,
          label: (s===1) ? "Lunes" : ((s===2) ? "Martes" : ((s===3) ? "Miércoles" : ((s===4) ? "Jueves" : ((s===5) ? "Viernes" : ((s===6) ? "Sábado" : "Domingo"))))),
          name: (s===1) ? "Lunes" : ((s===2) ? "Martes" : ((s===3) ? "Miércoles" : ((s===4) ? "Jueves" : ((s===5) ? "Viernes" : ((s===6) ? "Sábado" : "Domingo")))))
        }))],
        deliveryMethods: [ ...selectedPlace.subscription_delivery_methods.map(s => ({
          uid: s.uid,
          name: s.name,
          selected: false
        }))],
        subscriptionPickupIntervals: [ ...selectedPlace.subscription_pickup_intervals.map((s,index) => ({
          uid: s.uid,
          name: `${moment(s.starts, 'hh:mm:ss').format("hh:mm a")} - ${moment(s.ends, 'hh:mm:ss').format("hh:mm a")}`,
          selected: false,
          starts: s.starts,
          ends: s.ends
        }))],
        subscriptionDeliveryIntervals: [ ...selectedPlace.subscription_delivery_intervals.map((s,index) => ({
          uid: s.uid,
          name: `${moment(s.starts, 'hh:mm:ss').format("hh:mm a")} - ${moment(s.ends, 'hh:mm:ss').format("hh:mm a")}`,
          selected: false,
          starts: s.starts,
          ends: s.ends
        }))],
        initialLoaded: true
      }
    }
    return state;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let {infoSubscriptionOptions, selectedPlace, setInfoSubscriptionOptions} = this.props


    if(infoSubscriptionOptions && infoSubscriptionOptions.subscriptionsDaysSelected && selectedPlace){
      let aux = infoSubscriptionOptions.subscriptionsDaysSelected.filter(s=>selectedPlace.subscriptions_days_enabled.includes(s.value))
      if(!this.compare(aux, this.state.subscriptionsDaysSelected)){
        this.setState({
          subscriptionsDaysSelected: aux
        })
        let auxOptions = JSON.parse(JSON.stringify(infoSubscriptionOptions))
        auxOptions.subscriptionsDaysSelected = aux
        setInfoSubscriptionOptions(auxOptions)
      }
    }
    if(infoSubscriptionOptions && infoSubscriptionOptions.deliveryMethodCurrent){
      if(!this.compare(infoSubscriptionOptions.deliveryMethodCurrent, this.state.deliveryMethodCurrent)){
        this.setState({
          deliveryMethodCurrent: infoSubscriptionOptions.deliveryMethodCurrent,
          deliveryMethodsText: (infoSubscriptionOptions.deliveryMethodCurrent.uid==="0WpJ9Y") ? "Delivery" : "Recojo en Tienda"
        })
      }
    }
    if(infoSubscriptionOptions && infoSubscriptionOptions.subscriptionScheduleSelected){
      if(!this.compare(infoSubscriptionOptions.subscriptionScheduleSelected, this.state.subscriptionScheduleSelected)){
        this.setState({
          subscriptionScheduleSelected: infoSubscriptionOptions.subscriptionScheduleSelected,
          subscriptionScheduleText:
            (infoSubscriptionOptions.subscriptionScheduleSelected.starts && infoSubscriptionOptions.subscriptionScheduleSelected.ends) ?
            `${moment(infoSubscriptionOptions.subscriptionScheduleSelected.starts, 'hh:mm:ss').format("hh:mm a")} - ${moment(infoSubscriptionOptions.subscriptionScheduleSelected.ends, 'hh:mm:ss').format("hh:mm a")}`
          : "RANGO DE HORARIO",
        })
      }
    }
  }

  compare = (obj1, obj2) => {
    return JSON.stringify(obj1) === JSON.stringify(obj2)
  }

  renderDelivery = () => {
    return this.state.deliveryMethods.map((f, index) => (<div
      key={`category${index}`}
      className={`dropdown-item dropdown-type select-type ${f.selected ? 'selected' : ''}`}
      onClick={_ =>
        this.selectIntervals(
          f, index, "", false
        )
      }
    >
      {f.name}
    </div>));
  }


  renderSchedule = (uid) => {
    if (uid==="0WpJ9Y") {
      return this.state.subscriptionDeliveryIntervals.map((f, index) => (<div
        key={`category${index}`}
        className={`dropdown-item dropdown-type select-type ${f.selected ? 'selected' : ''}`}
        onClick={_ =>
          this.selectIntervals(
            f, index, DELIVERY_METHOD, true
          )
        }
      >
        {f.name}
      </div>));
    }

    return this.state.subscriptionPickupIntervals.map((f, index) => (<div
      key={`category${index}`}
      className={`dropdown-item dropdown-type select-type ${f.selected ? 'selected' : ''}`}
      onClick={_ =>
        this.selectIntervals(
          f, index, PICKUP_METHOD, true
        )
      }
    >
      {f.name}
    </div>));
  }

  selectIntervals = (selected, i, type, isInterval) => {

    let payload = JSON.parse(JSON.stringify(this.state));
    delete payload.deliveryMethods
    delete payload.deliveryMethodsText
    delete payload.initialLoaded
    delete payload.subscriptionsDaysEnabled
    delete payload.subscriptionDeliveryIntervals
    delete payload.subscriptionPickupIntervals
    delete payload.subscriptionScheduleText

    let options
    if(isInterval){
      if(type=== PICKUP_METHOD){
        let {subscriptionPickupIntervals} = this.state
        options = subscriptionPickupIntervals
      }else{
        let {subscriptionDeliveryIntervals} = this.state
        options = subscriptionDeliveryIntervals
      }
    }else{
      let {deliveryMethods} = this.state
      options = deliveryMethods
    }

    let text = ''
    if (options[i]) {
      selected.selected = true
      if (selected.selected) {
        for (let i = 0; i < options.length; i++) {
          let c = options[i]
          if (c.uid !== options.uid && c.selected) {
            c.selected = false
            options[i] = c
          }
        }
        text = `${selected.name}`
      }
      options[i] = selected
    }
    if(isInterval) {
      if (type === PICKUP_METHOD) {
        this.setState({subscriptionScheduleText: text, subscriptionPickupIntervals: options, subscriptionScheduleSelected: selected})
        payload["subscriptionScheduleSelected"] = selected
        delete payload.subscriptionScheduleSelected.selected
      } else {
        this.setState({subscriptionScheduleText: text, subscriptionDeliveryIntervals: options, subscriptionScheduleSelected: selected})
        payload["subscriptionScheduleSelected"] = selected
        delete payload.subscriptionScheduleSelected.selected
      }
    }else{
      if(this.state.deliveryMethodCurrent){
        if(selected.uid !== this.state.deliveryMethodCurrent.uid){
          this.setState({subscriptionScheduleText:"RANGO DE HORARIO"})
          payload["subscriptionScheduleSelected"] = null
        }
      }
      this.setState({deliveryMethodsText: text, deliveryMethods: options, deliveryMethodCurrent:selected})
      payload["deliveryMethodCurrent"] = selected
    }
    this.setInfoSubscription(payload)
  }

  setInfoSubscription = (payload) => {
    let {infoSubscriptionOptions, setInfoSubscriptionOptions} = this.props
    let aux = Object.assign(infoSubscriptionOptions, payload)
    setInfoSubscriptionOptions(aux)
  }

  render() {
    let { deliveryMethodsText } = this.state

    return (
      <div className={"container"}>
        <div className={`row product-place-search-section`}>
          <div className={"col-lg-4 d-flex align-items-center"}>
            <SelectDays
              changeValue={(value) => {
                this.setState(value)
                this.setInfoSubscription(value)
              }}
              subscriptionsDaysSelected={this.state.subscriptionsDaysSelected}
              subscriptionsDaysEnabled={this.state.subscriptionsDaysEnabled}
            />
          </div>
          <div className={"col-lg-4"}>
            <div
              className={`
              form-group 
              dropdown 
              categories`}>
              <div
                className="form-control"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span>{deliveryMethodsText}</span>
                <Icon svg="down" classes="svg-icon down" title="Down"/>
              </div>
              <div
                className="dropdown-menu"
                style={{
                  width: "100%",
                  overflowY: "auto",
                  maxHeight: 200
                }}
              >
                {this.renderDelivery()}
              </div>
            </div>
          </div>
          <div className={"col-lg-4"}>
            <div
              className={`pr-0 mr-0
              form-group 
              dropdown 
              categories`}>
              <div
                className="form-control"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span>{this.state.subscriptionScheduleText}</span>
                <Icon svg="down" classes="svg-icon down" title="Down"/>
              </div>
              <div
                className="dropdown-menu"
                style={{
                  width: "100%",
                  overflowY: "auto",
                  maxHeight: 200
                }}
              >
                {this.state.deliveryMethodCurrent && this.renderSchedule(this.state.deliveryMethodCurrent.uid)}
              </div>
            </div>
          </div>
        </div>
      </div>

    );
  }
}

SubscriptionOptions.defaultProps = {
  categories: []
};

SubscriptionOptions.propTypes = {
  getProductsByPlace: PropTypes.func,
  categories: PropTypes.array
};

