/* eslint-disable array-callback-return */
import React, { Fragment } from "react";
import PropTypes from "prop-types";
import InfiniteScroll from "react-infinite-scroller";
import {
  STATUS_INCLUDE,
  STATUS_LOADING,
  STATUS_SUCCESS,
} from "../../../common/utils/generic";
import Promotion from "./components/Promotion";
import "./style.scss";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import notItems from "../../../common/images/no_items.png";
import Stamp from "./components/Stamp";
import LevelInfo from "../programDetail/LevelInfo";

export default class ProgramPromotionsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      terms_conditions: false,
      stampsExist: false,
      load: false,
      info_modal: false,
    };
  }

  loadMore = () => {
    const {
      nextPagePromotions,
      listNextProgramPromotionsStatus,
      listProgramPromotionsStatus,
      getMoreProgramPromotions,
      countDiscount
    } = this.props;
    if (
      listNextProgramPromotionsStatus !== STATUS_LOADING &&
      listProgramPromotionsStatus !== STATUS_LOADING &&
      nextPagePromotions != null &&
      countDiscount !== 4
    ) {
      getMoreProgramPromotions(nextPagePromotions);
    }
  };

  renderProgramPromotions() {
    const {
      programPromotions,
      listProgramPromotionsStatus,
      count,
      programUid,
      multisite,
      company,
      countDiscount,
      moreDiscount,
      stampShow,
      app
    } = this.props;

    let discountArray = programPromotions.filter(
      (discount) => discount.type !== "STAMPS"
    );
    let stampsArray = programPromotions.filter(
      (stamps) => stamps.type === "STAMPS"
    );
    stampsArray = stampsArray.slice(0, 4)
    let cSmall = stampsArray.filter(s=> s.required_stamps===5 )
    let twoLarge = true
    stampsArray.map((s, index)=>{
      if(s.required_stamps!==10 && index<2){
        twoLarge = false
      }
    })
    if(twoLarge){
      stampsArray = stampsArray.slice(0, 2)
    }else{
      if(cSmall.length!==4){
        stampsArray = stampsArray.slice(0, 3)
      }
    }
    let moreStamps = programPromotions.filter(
      (stamps) => stamps.type === "STAMPS"
    ).length > stampsArray.length

    if (count === 0 && listProgramPromotionsStatus === STATUS_LOADING) {
      return (
        <Fragment>
          <div className="loadingContainer">
            <h1>&nbsp;</h1>
            <div className="loadingContainer_grid">
              <Promotion loading={true} />
              <Promotion loading={true} />
            </div>
          </div>
        </Fragment>
      );
    }
    if (count === 0 && listProgramPromotionsStatus === STATUS_SUCCESS) {
      return (
        <div className="content-no-items mt-4 mb-4">
          No se encontraron promociones disponibles
        </div>
      );
    }
    let url = "";
    if (multisite && company) url = `/c/${company.uid}`;
    let pathname = window.location.pathname

    return (
      <Fragment>


        <div className="DiscountBoxList">
          {
            !pathname.split("/").includes("more-stamps") && pathname.split("/").includes("more") &&
            <div >
              <h3 className={programPromotions.length!==0 ? "category-title mt-4 ml-3" : "d-none"}>DESCUENTOS</h3>
              <span className={programPromotions.length!==0 ? "category-description ml-3" : "d-none"}>Sube tus puntos para llegar un mejor nivel y tener mejores recompensas</span>
            </div>
          }
          {moreDiscount ? (
            <>
              <h3 className={discountArray.length!==0 ? "category-title mt-4 ml-3" : "d-none"}>DESCUENTOS</h3>
              {discountArray.length > 6 ? (
                <h4 className={"category-title mt-4"}>
                  {/* <a href="/">Ver más</a> */}
                  <Link to={`${url}/program/${programUid}/more`}>Ver más</Link>
                </h4>
              ) : (
                <h1 className={discountArray.length!==0 ? "" : "d-none"}>&nbsp;</h1>
              )}
            </>
          ) : (
            <h1>&nbsp;</h1>
          )}
          {/* {discountArray.length > 6 ? (
              <h4 className={"category-title mt-4"}>
                <Link
            to={`${url}/program/${programUid}/more`}
          >Ver más</Link>
              </h4>
            ) : (
              <h1>&nbsp;</h1>
            )} */}
        </div>
        <span className={discountArray.length!==0 && moreDiscount ? "category-description ml-3" : "d-none"}>Sube tus puntos para llegar un mejor nivel y tener mejores recompensas</span>

        <div className="PromotionDiscount">
          {discountArray.map((programPromotion, index) => {
            if (index < countDiscount) {
              return (
                <div className="PromotionDiscountBox" key={`promo${index}`}>
                  <Promotion
                    key={`promo${index}`}
                    programUid={programUid}
                    multisite={multisite}
                    company={company}
                    promotion={programPromotion}
                    color={programPromotion.color}
                    loading={false}
                    app={app}
                  />
                </div>
              );
            }
          })}
        </div>
        {stampShow && (
          <>
            {stampsArray.length > 0 && (
              <div className="StampsDiscountContainer mt-0">
                <div className="DiscountBoxList">
                  <h3 className={"category-title mt-4"}>STAMPS</h3>
                  {moreStamps ? (
                    <>
                        <h4 className={"category-title mt-4"}>
                          <Link to={`${url}/program/${programUid}/more-stamps`}>
                            Ver más
                          </Link>
                        </h4>
                    </>
                  ) : (
                    <h1>&nbsp;</h1>
                  )}
                </div>
                <span className={stampsArray.length !==0 ? "category-description stamps ml-4" : "d-none"}>Completa tu cartilla y gana una recompensa especial</span>
                <div className="StampsDiscount">
                  {stampsArray.map((programPromotion, index) => {
                    if (index < countDiscount) {
                      return (
                        <div
                          className="StampsDiscountBox"
                          key={`promo${index}`}
                        >
                          <Stamp
                            key={`promo${index}`}
                            programUid={programUid}
                            multisite={multisite}
                            company={company}
                            promotion={programPromotion}
                            loading={false}
                            is_detail={true}
                          />
                        </div>
                      );
                    }
                  })}
                </div>
              </div>
            )}
          </>
        )}
        <br />
      </Fragment>
    );
  }

  render() {
    let {
      retrieveStatus,
      nextPagePromotions,
      addProgramUser,
       programPromotions,
      authStatus,

      programUid,
      multisite,
      company,
      program
    } = this.props;

    let more = nextPagePromotions != null;
    let loaderMore = (
      <Fragment key={"load"}>
        {/* <Stamp loading={true} /> */}
        {/* <Promotion loading={true} /> */}
      </Fragment>
    );

    let stampsArrayBackDrop = []
    if(authStatus===null){
      stampsArrayBackDrop = [
        {
          "uid": "96gEv8",
          "promotion_uid": "96gEv8",
          "title": "Prueba QR",
          "image": "https://bederr-staging.s3.amazonaws.com/media/promotions/20221027113631_db6cb291166348c6bd009859d941931f.png",
          "is_used": 0,
          "is_available": true,
          "company_retail": {
            "uid": "Wv6DeW",
            "name": "Fastfox",
            "web_page": "",
            "facebook_page": "https://www.google.com/",
            "logo": "https://bederr-staging.s3.amazonaws.com/media/companies/logo_photos/20221004095209_d1ce24ae54274deb838c3d30fb1d6982.png",
            "cover": "https://bederr-staging.s3.amazonaws.com/media/companies/cover_photos/e11c05bcbe3547fcb1a0fe4fea439de3_20190621173151.jpeg",
            "logo_rectangle": "https://bederr-staging.s3.amazonaws.com/media/companies/logo_photos/20200806133025_ad835442de554e19a790b9b186fea2a5.jpeg",
            "description": "Fastfox una empresa diferente"
          },
          "pin": null,
          "required_stamps": 10,
          "available_stamps": 0,
          "type": "STAMPS",
          "original_price": "0.00",
          "allow_payment": false,
          "content": "Prueba de codigo QR",
          "fixed_discount": "0.00",
          "rate_discount": 100,
          "color": "#36aaBc",
          "subtitle": null,
          "all_levels": true,
          "all_segments": false,
          "promotion_unexpired": false
        },
        {
          "uid": "9doD1k",
          "promotion_uid": "9doD1k",
          "title": "Descuentos de fin de semana",
          "image": null,
          "is_used": 0,
          "is_available": true,
          "company_retail": {
            "uid": "Wv6DeW",
            "name": "Fastfox",
            "web_page": "",
            "facebook_page": "https://www.google.com/",
            "logo": "https://bederr-staging.s3.amazonaws.com/media/companies/logo_photos/20221004095209_d1ce24ae54274deb838c3d30fb1d6982.png",
            "cover": "https://bederr-staging.s3.amazonaws.com/media/companies/cover_photos/e11c05bcbe3547fcb1a0fe4fea439de3_20190621173151.jpeg",
            "logo_rectangle": "https://bederr-staging.s3.amazonaws.com/media/companies/logo_photos/20200806133025_ad835442de554e19a790b9b186fea2a5.jpeg",
            "description": "Fastfox una empresa diferente"
          },
          "pin": null,
          "required_stamps": 10,
          "available_stamps": 0,
          "type": "STAMPS",
          "original_price": "0.00",
          "allow_payment": false,
          "content": "",
          "fixed_discount": "0.00",
          "rate_discount": 100,
          "color": "#d6e600",
          "subtitle": "prueba sssss",
          "all_levels": true,
          "all_segments": true,
          "promotion_unexpired": true
        }
      ]
    }

    const HandleClose = ()=>{
      this.setState({info_modal: false})
    }
    let level = null;
    let percent = 0;
    let pointsExtra = 0;
    let next_level = null;
    let color = null;
    let descriptionNextLevel = null;
    let colorNextLevel = null;
    if (program) {
      if (program.color) color = program.color.replace("#", "");
      if (program.levels_system) {
        for (let i = 0; i < program.levels.length; i++) {
          if (level) {
            next_level = program.levels[i];
            break;
          }
          if (program.levels[i].is_active) {
            level = program.levels[i];
            if (level.max_points && level.max_points > 0) {
              percent = (program.points * 100) / level.max_points;
            }
            if (level.color) color = level.color.replace("#", "");
          }
        }
        if (next_level) {
          //console.log(next_level,"sassss");
          pointsExtra = next_level.points - program.points
          descriptionNextLevel = next_level.description
          colorNextLevel=next_level.color
        }
      }
    }

    return (
      <Fragment>
        <div
          className={
            "container program-promotions program-promotions__discount"
          }
        >
          <div className="DiscountBoxList d-flex flex-column">
            {
              program &&
              <div className={"program-banner"}>
                <div className={"containerPrograms"}>
                  <div className={"banner bannerPrograms row"}>
                    <div className={"cardPrograms col-lg-6 col-md-8"}>
                      <div className={"card-advanced"}>
                        <div className={""}>
                          {this.state.info_modal && (
                            <LevelInfo
                              nivelTitle={level.title}
                              color={level.color}
                              percent={percent}
                              point={program.points}
                              description={level.description}
                              pointsExtra={pointsExtra}
                              nextLevel = {next_level!==null ? next_level.title : null}
                              descriptionNextLevel={descriptionNextLevel}
                              colorNextLevel={colorNextLevel}
                              HandleClose={HandleClose}
                            />
                          )}
                          <div className={"level-info"}>
                            {level ? (
                              <div>
                                <div className={"m-0"}>
                                  <span>{level.title.toUpperCase()}</span>
                                </div>
                                <div className={"m-0"}>
                                <span
                                  onClick={(e) =>
                                    this.setState({
                                      info_modal: !this.state.info_modal,
                                    })
                                  }
                                  className={"level-info-more"}
                                >
                                  Ver más
                                </span>
                                </div>
                              </div>
                            ) : (
                              <p className={"m-0"}>Sin Nivel</p>
                            )}
                          </div>
                        </div>
                        <div className={"advanced-percent program"}>
                          <div className={"bar mt-1"} style={{overflow: "hidden"}}>
                            <div
                              style={
                                color
                                  ? {
                                    width: `${percent}%`,
                                    background: `#${color}`,
                                  }
                                  : { width: `${percent}%` }
                              }
                            />
                          </div>
                          <div className="poinsProgram">
                            <p>
                              <span>{program.points!==null && program.points}pts </span> -{" "}
                              {percent.toFixed(0)}%
                            </p>
                          </div>
                        </div>
                        <div className={" points-info"}>
                          {/* <div className={"mr-auto"}>
                            {
                              pointsExtra > 0 ? <p className={"mb-0"}>Te faltan <span>{pointsExtra}pts </span>
                                  para subir a nivel {next_level && <span>{next_level.title}</span>}</p> :
                                <p className={"mb-0"}>Sigue acumulando puntos para obtener mejores beneficios</p>
                            }

                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }

          </div>


          {retrieveStatus === STATUS_SUCCESS && authStatus!==null ? (
            <Fragment>
              <InfiniteScroll
                pageStart={0}
                loadMore={this.loadMore.bind(this)}
                hasMore={more}
                loader={loaderMore}
              >
                {this.renderProgramPromotions()}
              </InfiniteScroll>
            </Fragment>
          ) : retrieveStatus === STATUS_INCLUDE ? (
            <div
              className={
                "d-flex justify-content-center mt-4 flex-column col-12 col-md-5 mx-auto program-terms"
              }
            >
              <div className="terms ">
                <img src={notItems} alt="notItems" className={"not-items"} />
                <p
                  className={"text-center mt-4 mb-4"}
                  style={{ color: "#939393", fontWeight: 700 }}
                >
                  Primero debe unirse al <br className={"d-none d-sn-block"} />
                  programa de loyalty
                </p>
                <div className="form-check">
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      this.setState({ terms_conditions: e.target.checked });
                    }}
                    checked={this.state.terms_conditions}
                    className="form-check-input"
                    id="checkbox-terms"
                  />
                  <label className="form-check-label" htmlFor="exampleCheck1">
                    {" "}
                    <span
                      onClick={(e) => {
                        this.setState({
                          terms_conditions: !this.state.terms_conditions,
                        });
                      }}
                    >
                      Acepto los
                    </span>
                    <Link to={"/terms-and-conditions"} target={"_blank"}>
                      {" "}
                      Términos y Condiciones
                    </Link>{" "}
                    y
                    <br />
                    Acepto el uso de mis datos personales.
                  </label>
                </div>
              </div>
              <button
                onClick={(e) => {
                  if (!this.state.terms_conditions) {
                    toast.info(
                      "Aceptar los términos y condiciones es obligatorio"
                    );
                    return;
                  } else {
                    addProgramUser();
                  }
                }}
                className={"btn btn-primary mt-4"}
              >
                Unirme
              </button>
            </div>
          ) : (
            <>
              <div style={{position: "relative"}}>
                <div className="DiscountBoxList flex-column" style={{opacity: "0.3"}}>
                  <h3 className={programPromotions.length!==0 ? "category-title mt-4 ml-3" : "d-none"}>DESCUENTOS</h3>
                  <div className="PromotionDiscount">
                    <div className="PromotionDiscountBox">
                      <Promotion backdrop ={true}/>
                    </div>
                    <div className="PromotionDiscountBox">
                      <Promotion backdrop ={true}/>
                    </div>
                    <div className="PromotionDiscountBox">
                      <Promotion backdrop ={true}/>
                    </div>
                    <div className="PromotionDiscountBox">
                      <Promotion backdrop ={true}/>
                    </div>
                  </div>
                </div>
                <div className={"promotions-backdrop"}>
                  <p>PARA VER UN MUNDO DE BENEFICIOS</p>
                  <Link className={"btn btn-login btn-primary "} to={`/register/`}>
                    REGÍSTRATE
                  </Link>
                </div>
              </div>
              <div style={{position: "relative"}}>
                <>
                  {stampsArrayBackDrop.length > 0 && (
                    <div className="StampsDiscountContainer mt-0" style={{opacity: "0.3"}}>
                      <div className="DiscountBoxList">
                        <h3 className={"category-title mt-4"}>STAMPS</h3>
                      </div>
                      <div className="StampsDiscount" style={authStatus===null ? {pointerEvents: "none"} : {}}>
                        {stampsArrayBackDrop.map((programPromotion, index) => {
                          if (index < 2) {
                            return (
                              <div
                                className="StampsDiscountBox"
                                key={`promo${index}`}
                              >
                                <Stamp
                                  key={`promo${index}`}
                                  programUid={programUid}
                                  multisite={multisite}
                                  company={company}
                                  promotion={programPromotion}
                                  loading={false}
                                  is_detail={true}
                                />
                              </div>
                            );
                          }
                        })}
                      </div>
                    </div>
                  )}
                </>


              </div>
            </>



          )}
        </div>
      </Fragment>
    );
  }
}

ProgramPromotionsList.defaultProps = {
  programPromotions: [],
  listProgramPromotionsStatus: "",
  count: 0,
  nextPagePromotions: null,
  stampShow: true,
};

ProgramPromotionsList.propTypes = {
  programPromotions: PropTypes.array,
  nextPagePromotions: PropTypes.string,
  getMoreProgramPromotions: PropTypes.func,
  listNextProgramPromotionsStatus: PropTypes.string,
  programUid: PropTypes.string,
  count: PropTypes.number,
  listProgramPromotionsStatus: PropTypes.string,
};
