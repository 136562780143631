import {OPEN_POPUP} from "../actions/popup";


const initialState = {
  activePopUp: false,
  componentPopUp:""
};

export default function app(state = initialState, action) {
  const {type} = action;
  switch (type) {
    case `${OPEN_POPUP}`:
      return {
        ...state,
        activePopUp: action.open,
        componentPopUp: action.component
      };
    default:
      return state
  }
}
