import {
  ADD_PRODUCT,
  ADD_PRODUCT_BACK,
  CLEAN_CODE,
  CLEAN_ORDER,
  GET_CART,
  GET_CART_BACK,
  ORDER,
  UPDATE_QUANTITY_PRODUCT,
  VALIDATE_CODE,
  STATE_CART,
  TYPE_CART,
  CLEAN_CART,
  GET_CART_SUBSCRIPTION,
  ADD_PRODUCT_SUBSCRIPTION,
  UPDATE_QUANTITY_PRODUCT_SUBSCRIPTION,
  SUBSCRIPTION,
  CART_PRODUCTS, CART_SUBSCRIPTION, CLEAN_ORDER_SUBSCRIPTION
} from "../actions/cart";
import {normalize, schema} from "normalizr";

import {ERROR_DEFAULT, ERROR_NO_CODE, STATUS_ERROR, STATUS_LOADING, STATUS_SUCCESS} from "../common/utils/generic";


const initialState = {
  entitiesCart: null,
  entitiesCartSubscription: null,
  orderErrors: null,
  orderStatus: null,
  orderSuccessBody: null,
  statusCartBack: null,
  typeCart:'',
  stateCart: ''
};

const placeCartSchema = new schema.Entity('place', {}, {idAttribute: 'uid'});

const productCartSchema = new schema.Entity('product', {}, {idAttribute: 'id'});
const productCartListSchema = new schema.Array(productCartSchema);
const productCartBackListSchema = new schema.Array(productCartSchema);

const getCart = (state, placeUid, type = "products") => {
  if (type==="products") {
    let cart = JSON.parse(localStorage.getItem(`cart${placeUid}`)) ? JSON.parse(localStorage.getItem(`cart${placeUid}`)) : []
    if(cart!==[])
      state.entitiesCart = cart;
  }
  if (type==="subscription") {
    let cart = JSON.parse(localStorage.getItem(`cartSubscription${placeUid}`)) ? JSON.parse(localStorage.getItem(`cartSubscription${placeUid}`)) : []
    if(cart!==[])
      state.entitiesCartSubscription = cart;
  }
}

const deleteCart = (state, type=CART_PRODUCTS) => {
  if(type===CART_PRODUCTS){
    let placeUid = Object.keys(state.entitiesCart.place)[0]
    localStorage.removeItem(`cart${placeUid}`)
    let cart = JSON.parse(localStorage.getItem(`cart${placeUid}`)) ? JSON.parse(localStorage.getItem(`cart${placeUid}`)) : []
    if (cart!==[])
      state.entitiesCart = cart;
    state.statusCartBack = null;
  }
  if(type===CART_SUBSCRIPTION){
    Object.keys(localStorage).map( item => {
      if(item.includes('cartSubscription'))
        localStorage.removeItem(item);
      return true;
    })
  }
}

const getCartMerge = (state, data) => {
  if (data.products !== undefined) {
    let dataPlace = normalize(data.place, placeCartSchema)
    data.products = data.products.map((product, idx) => ({
      ...product,
      id: `${product.uid}_${idx}`
    }))
    let aux = data.products
    let auxDataProducts = []
    auxDataProducts = normalize(aux, productCartBackListSchema)

    let productsBack = auxDataProducts.entities.product

    if (state.entitiesCart!== null && state.entitiesCart.products !== undefined)
    {
      let productsLocal = state.entitiesCart.products
      let pMerge = []
      Object.keys(productsLocal).map(p => {
          pMerge.push(productsLocal[p])
          return true
        }
      )
      if(productsBack && productsBack.length!==0){
        Object.keys(productsBack).map(p => {
          if(productsBack[p]){
            pMerge.push(productsBack[p])
          }
            return true
          }
        )
      }
      pMerge.map((product, idx) => {
        delete product.id
        return true
      })
      pMerge = pMerge.map((product, idx) => ({
        id: `${product.uid}_${idx}`,
        ...product
      }))
      if(localStorage.getItem("merge")){
        state.entitiesCart = {
          place: dataPlace.entities.place ? dataPlace.entities.place : [],
          products: pMerge ? pMerge : []
        }
        localStorage.removeItem("merge")
      }

    } else {

      state.entitiesCart = {
        place: dataPlace.entities.place ? dataPlace.entities.place : [],
        products: productsBack ? productsBack : []
      }
    }
    updateLocalStorage(state)
  }
}

const updateLocalStorage = (state, type = "products") => {
  if (type==="products") {
    let placeUid = Object.keys(state.entitiesCart.place)[0]
    localStorage.removeItem(`cart${placeUid}`);

    if (Object.keys(state.entitiesCart.products).length !== 0)
      localStorage.setItem(`cart${placeUid}`, JSON.stringify(state.entitiesCart));
  }
  if (type==="subscription") {
    let placeUid = Object.keys(state.entitiesCartSubscription.place)[0]
    localStorage.removeItem(`cartSubscription${placeUid}`);
    if (Object.keys(state.entitiesCartSubscription.products).length !== 0)
      localStorage.setItem(`cartSubscription${placeUid}`, JSON.stringify(state.entitiesCartSubscription));
  }
}


const addProduct = (state, product, place, type = "products") => {
  let dataPlace = normalize(place, placeCartSchema)
  let productsList = []
  if (type==="products") {
    if (state.entitiesCart !== null) {
      if (state.entitiesCart.products !== [] && state.entitiesCart.products !== undefined) {
        Object.keys(state.entitiesCart.products).map(p => {
          productsList.push(state.entitiesCart.products[p])
          return true;
        })
      }
    }
    let first = false
    let i = -1
    productsList.map((p, index)=>{
      if(p.id===product.id){
        if(!first){
          i = index
          first = true
        }
      }
      return true
    })
    if(i!==-1){
      productsList.splice(i, 1, product)

    }else{
      productsList.push(product)
    }
    productsList = productsList.map((product, idx) => ({
      ...product,
      id: `${product.uid}_${idx}`
    }))
    let dataProducts = normalize(productsList, productCartListSchema)

    state.entitiesCart = {
      place: dataPlace.entities.place ? dataPlace.entities.place : [],
      products: dataProducts.entities.product ? dataProducts.entities.product : []
    }
    console.log("state", state)
    updateLocalStorage(state);
  }

  if (type==="subscription") {
    if (state.entitiesCartSubscription !== null) {
      if (state.entitiesCartSubscription.products !== [] && state.entitiesCartSubscription.products !== undefined) {
        Object.keys(state.entitiesCartSubscription.products).map(p => {
          productsList.push(state.entitiesCartSubscription.products[p])
          return true;
        })
      }
    }
    let first = false
    let i = -1
    productsList.map((p, index)=>{
      if(p.id===product.id){
        if(!first){
          i = index
          first = true
        }
      }
      return true
    })
    if(i!==-1){
      productsList.splice(i, 1, product)

    }else{
      productsList.push(product)
    }
    productsList = productsList.map((product, idx) => ({
      ...product,
      id: `${product.uid}_${idx}`
    }))
    let dataProductsSubscription = normalize(productsList, productCartListSchema)

    state.entitiesCartSubscription = {
      place: dataPlace.entities.place ? dataPlace.entities.place : [],
      products: dataProductsSubscription.entities.product ? dataProductsSubscription.entities.product : []
    }
    updateLocalStorage(state, "subscription");

  }
};

const updateQuantity = (state, quantity, productUid, place, type="products") => {
  if (type==="products") {
    console.log(productUid)
    if (quantity > 0) {
      state.entitiesCart.products[productUid].quantity = quantity
    } else {
      if (productUid !== "")
        delete state.entitiesCart.products[productUid]
    }
    updateLocalStorage(state);
  }
  if (type==="subscription") {
    if (quantity > 0) {
      state.entitiesCartSubscription.products[productUid].quantity = quantity
    } else {
      if (productUid !== "")
        delete state.entitiesCartSubscription.products[productUid]
    }
    updateLocalStorage(state, "subscription");
  }
}

export default function reducer(state = initialState, action) {
  const {type, payload = {data: null}} = action;
  const {data} = payload;
  switch (type) {
    case `${STATE_CART}`:
      return {
        ...state,
        stateCart: action.state
      };
    case `${TYPE_CART}`:
      return {
        ...state,
        typeCart: action.state
      };
    case `${ADD_PRODUCT}`:
      addProduct(state, action.product, action.place)
      return {
        ...state,
      };
    case `${ADD_PRODUCT_SUBSCRIPTION}`:
      addProduct(state, action.product, action.place, "subscription")
      return {
        ...state,
      };
    case `${ADD_PRODUCT_BACK}_PENDING`:
      return {
        ...state
      };
    case `${ADD_PRODUCT_BACK}_REJECTED`:
      return {
        ...state
      };
    case `${ADD_PRODUCT_BACK}_FULFILLED`: {

      let data = (payload.status === 200 || payload.status === 201) ? payload.data : {};
      let dataPlace = (payload.status === 200 || payload.status === 201) ? normalize(data.place, placeCartSchema) : {}
      let dataProducts = (payload.status === 200 || payload.status === 201) ? data.products : []
      dataProducts = dataProducts.map((product, idx) => ({
        ...product,
        id: `${product.uid}_${idx}`
      }))
      dataProducts = normalize(dataProducts, productCartListSchema)

      return {
        ...state,

        entitiesCart: {
          place: (payload.status === 200 || payload.status === 201) && dataPlace.entities.place ? dataPlace.entities.place : [],
          products: (payload.status === 200 || payload.status === 201) && dataProducts.entities.product ? dataProducts.entities.product : []
        }
      }
    }
    case `${GET_CART}`: {
      getCart(state, action.place)
      return {
        ...state,
        listCartStatus: STATUS_SUCCESS
      };
    }
    case `${GET_CART_SUBSCRIPTION}`: {
      getCart(state, action.place, "subscription")
      return {
        ...state,
        listCartSubscriptionStatus: STATUS_SUCCESS
      };
    }
    case `${GET_CART_BACK}_PENDING`:
      return {
        ...state,
        statusCartBack: STATUS_LOADING
      };
    case `${GET_CART_BACK}_REJECTED`:
      return {
        ...state,
        statusCartBack: STATUS_ERROR
      };
    case `${GET_CART_BACK}_FULFILLED`: {
      getCartMerge(state, data)
      return {
        ...state,
        statusCartBack: (payload.status === 200 || payload.status === 201) ? STATUS_SUCCESS : STATUS_ERROR,
      };
    }
    case `${UPDATE_QUANTITY_PRODUCT}`: {
      updateQuantity(state, action.quantity, action.productUid, action.place)
      return {
        ...state,
        listCartStatus: STATUS_SUCCESS
      };
    }
    case `${UPDATE_QUANTITY_PRODUCT_SUBSCRIPTION}`: {
      updateQuantity(state, action.quantity, action.productUid, action.place, "subscription")
      return {
        ...state,
        listCartSubscriptionStatus: STATUS_SUCCESS
      };
    }
    case `${ORDER}_PENDING`:
      return {
        ...state,
        orderErrors: null,
        orderStatus: STATUS_LOADING,
      };
    case `${ORDER}_REJECTED`:
      return {
        ...state,
        orderErrors: ERROR_DEFAULT,
        orderStatus: STATUS_ERROR,
      };
    case `${ORDER}_FULFILLED`: {
      if (payload.status === 200 || payload.status === 201) deleteCart(state)
      return {
        ...state,
        orderStatus: (payload.status === 200 || payload.status === 201) ? STATUS_SUCCESS : STATUS_ERROR,
        orderSuccessBody: (payload.status === 200 || payload.status === 201) ? payload.data : null,
        orderErrors: payload.status !== 200 ? (payload.status === 400 ? payload.data : ERROR_DEFAULT) : null,
      };
    }

    case `${SUBSCRIPTION}_PENDING`:
      return {
        ...state,
        orderSubscriptionErrors: null,
        orderSubscriptionStatus: STATUS_LOADING,
      };
    case `${SUBSCRIPTION}_REJECTED`:
      return {
        ...state,
        orderSubscriptionErrors: (payload.data ? payload.data : ERROR_DEFAULT),
        orderSubscriptionStatus: STATUS_ERROR,
      };
    case `${SUBSCRIPTION}_FULFILLED`: {
      if (payload.status === 200 || payload.status === 201) deleteCart(state, CART_SUBSCRIPTION)
      return {
        ...state,
        orderSubscriptionStatus: (payload.status === 200 || payload.status === 201) ? STATUS_SUCCESS : STATUS_ERROR,
        orderSubscriptionSuccessBody: (payload.status === 200 || payload.status === 201) ? payload.data : null,
        orderSubscriptionErrors: payload.status !== 200 ? (payload.status === 400 ? payload.data : ERROR_DEFAULT) : null,
        entitiesCartSubscription: null
      };
    }

    case `${VALIDATE_CODE}_PENDING`:
      return {
        ...state,
        codeErrors: null,
        codeStatus: STATUS_LOADING,
      };
    case `${VALIDATE_CODE}_FULFILLED`: {
      return {
        ...state,
        codeStatus: (payload.status === 200 || payload.status === 201) ? STATUS_SUCCESS : STATUS_ERROR,
        codeSuccessBody: (payload.status === 200 || payload.status === 201) ? payload.data : null,
        codeErrors: payload.status !== 200 ? (payload.status === 400 ? payload.data : (payload.status === 404 ? ERROR_NO_CODE : ERROR_DEFAULT)) : null,
      };
    }
    case CLEAN_ORDER: {
      return {
        ...state,
        orderErrors: null,
        orderStatus: null,
        orderSuccessBody: null,
      };
    }
    case CLEAN_CODE: {
      return {
        ...state,
        codeStatus: null,
        codeSuccessBody: null,
        codeErrors: null,
      };
    }
    case CLEAN_CART: {
      return {
        ...state,
        entitiesCart: null
      };
    }
    case CLEAN_ORDER_SUBSCRIPTION: {
      return {
        ...state,
        orderSubscriptionErrors: null,
        orderSubscriptionStatus: null,
        orderSubscriptionSuccessBody: null,
      };
    }
    default:
      return state
  }
}