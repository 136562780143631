import React, {Fragment} from "react";
import "./style.scss"
import {GoogleMap, LoadScriptNext, Marker} from "@react-google-maps/api";
import PropTypes from "prop-types";

export default class PlaceMap extends React.Component {

  renderMap = () => {

    let {
      currentlyPlace
    } = this.props
    //let API_key = "AIzaSyCDVeXSSU_rDyyTPSc0mrJJoaSQRR-w4sI";
    let API_key = "AIzaSyATc0OBFpvajYwxTRldugE-UyBYfiBwEZg";
    const containerStyle = {
      width: '100%',
      height: '300px'
    };

    const center = {
      lat: currentlyPlace.location.latitude,
      lng: currentlyPlace.location.longitude
    };

    return <LoadScriptNext
      googleMapsApiKey={API_key}

    >
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        options={{
          streetViewControl: false,
          scaleControl: false,
          fullscreenControl: true,
          gestureHandling: "greedy",
          minZoom: 11,
          maxZoom: 18,
          mapTypeControl: false,
          zoomControl: true,
          clickableIcons: true
        }}
        zoom={16}
      >
        <Marker
          position={center}
          title={currentlyPlace.name}
        />
      </GoogleMap>
    </LoadScriptNext>
  }

  render() {
    let {
      currentlyPlace,
      className
    } = this.props
    return (
      <div className={` ${className} card detail  prepayment map-container col-10 col-lg-10 mx-auto mt-4`}>
        <div className={"map-title-container"}>
          <div className={"title-bar-section"}>
            <h6 className={""}>Lugar de recojo</h6>

          </div>
          {
            currentlyPlace &&
            <p>{currentlyPlace.address}</p>
          }
        </div>
        <div className={""}>
          {
            currentlyPlace && <Fragment>
              <div className={"col-lg-12 p-0"}>
                {this.renderMap()}
              </div>
            </Fragment>
          }
        </div>
      </div>)
  }
}

PlaceMap.propTypes = {
  className: PropTypes.string,
  currentlyPlace: PropTypes.object
};

