import {normalize, schema} from 'normalizr';
import {STATUS_ERROR, STATUS_LOADING, STATUS_SUCCESS} from "../common/utils/generic";

import {
  CLEAR_STATUS_POLL,
  GET_POLLS,
  POLL_DETAIL,
  SEND_POLL,
  CLEAR_STATUS_SEND_POLL,
  GET_POLLS_RESOLVED
} from "../actions/polls";

const pollSchema = new schema.Entity('polls', {}, {idAttribute: 'uid'});
const pollListSchema = new schema.Array(pollSchema);



const initialState = {
  pollStatus: null,
  pollCurrent: null,
  entities: null,
  results: [],
  listStatus: null,
  listNextStatus: null,
  nextPage: null,
  count: 0,

  entitiesResolved: null,
  resultsResolved: [],
  listStatusResolved: null,
  listNextStatusResolved: null,
  nextPageResolved: null,
  countResolved: 0,
  formInitial: null,
};

export default function reducer(state = initialState, action) {
  const {type, payload} = action;
  let data;
  switch (type) {
    case `${GET_POLLS}_PENDING`:
      return {
        ...state,
        listStatus: STATUS_LOADING,
        count: 0,
        entities: null,
        nextPage: null,
        results: []
      };
    case `${GET_POLLS}_FULFILLED`:
      data = payload.status === 200 ? normalize(payload.data.results, pollListSchema) : {};
      return {
        ...state,
        entities: data.entities ? data.entities.polls : [],
        results: data.result ? data.result : [],
        nextPage: payload.data ? payload.data.next : null,
        listStatus: payload.status === 200 ? STATUS_SUCCESS : STATUS_ERROR,
        count: payload.data ? payload.data.count : 0,
      };
    case `${GET_POLLS_RESOLVED}_PENDING`:
      return {
        ...state,
        listStatusResolved: STATUS_LOADING,
        countResolved: 0,
        entitiesResolved: null,
        nextPageResolved: null,
        resultsResolved: []
      };
    case `${GET_POLLS_RESOLVED}_FULFILLED`:
      data = payload.status === 200 ? normalize(payload.data.results, pollListSchema) : {};
      return {
        ...state,
        entitiesResolved: data.entities ? data.entities.polls : [],
        resultsResolved: data.result ? data.result : [],
        nextPageResolved: payload.data ? payload.data.next : null,
        listStatusResolved: payload.status === 200 ? STATUS_SUCCESS : STATUS_ERROR,
        countResolved: payload.data ? payload.data.count : 0,
      };
    case `${POLL_DETAIL}_PENDING`:
      return {
        ...state,
        formInitial: null,
        retrieveStatus: STATUS_LOADING
      };
    case `${POLL_DETAIL}_FULFILLED`:
      data = action.payload.status === 200 ? action.payload.data : null;
      return {
        ...state,
        retrieveStatus: action.payload.status === 200 ? STATUS_SUCCESS : STATUS_ERROR,
        formInitial: data
      };
    case `${SEND_POLL}_PENDING`:
      return {
        ...state,
        isLoadingSend: null,
        errorSendPoll: false,
        statusSendPoll: STATUS_LOADING
      };
    case `${SEND_POLL}_REJECTED`:
      return { ...state, isLoadingSend: false, errorSendPoll: true, error: action.payload,
        statusSendPoll: STATUS_ERROR };
    case `${SEND_POLL}_FULFILLED`:
      return {
        ...state,
        isLoadingSend: false,
        errorSendPoll: false,
        successSendPoll: 200,
        statusSendPoll: STATUS_SUCCESS
      };
    case `${CLEAR_STATUS_POLL}`:
      return {
        ...state,
        isLoadingSend: false,
        errorSendPoll: false,
        successSendPoll: null,
        retrieveStatus: null,
      };
    case `${CLEAR_STATUS_SEND_POLL}`:
      return {
        ...state,
        statusSendPoll: null,
      };
    default:
      return state;
  }
}
