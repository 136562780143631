import React from "react";
import bench from "../../common/images/bench.svg";
import {Link} from "react-router-dom";
import "./styles.scss"

export default function NotFound(props) {
  return (
    <div className={"page-404 col-10 col-md-10 col-lg-8"}>
      <img src={bench} alt="404"/>

      <p>
        Lo sentimos esta página no está disponible
      </p>
      <p>
        <Link to={"/"} className={"btn btn-secondary"}>Regresar al Inicio</Link>
      </p>
    </div>
  );
}