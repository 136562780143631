/* eslint-disable prefer-destructuring */

import moment from 'moment';

export default class  Validator {
  constructor(fields) {
    this.defaultDepth = 2;
    this.validFields = {};
    this.fields = fields;
  }

  isValid() {
    const fields = Object.keys(this.validFields);

    for (let i = 0; i < fields.length; i += 1) {
      if (!this.validFields[fields[i]]) return false;
    }

    return true;
  }

  getParentNode = (ref, depth) => {
    let parentNode = ref;
    for (let i = 0; i < depth; i += 1) {
      parentNode = parentNode.parentNode;
    }
    return parentNode;
  };

  getErrorContainerNode = (parentNode, id) => parentNode.querySelector(`#${id}-error`);


  isValidRule = (rule, value) => {
    let toEval = value;

    if (toEval !== null && toEval !== undefined) {
      toEval = toEval.toString()
        .trim();
    }

    if (rule !== 'required' && rule !== 'business' && toEval === '') return true;
    switch (rule) {
      case 'checked':
        return toEval !== 'false';
      case 'email':
        return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(toEval);
      case 'numeric':
        return /^[+-]?\d+(\.\d+)?$/.test(toEval);
      case 'positiveNumber':
        return /^[+]?\d+(\.\d+)?$/.test(toEval);
      case 'dni':
        return /^\d{8,9}$/.test(toEval);
      case 'telephone': {
        let international = false
        if (rule === 'telephone') {
          if (toEval[0] === '+') {
            international = true;
          }
        }
        if (international && toEval !== null && toEval.length > 3) {
          toEval = toEval.substring(1, toEval.length);
          //return /^\d{11}$/.test(toEval);
          return `+${/^[+-]?\\d+(\\.\\d+)?$/.test(toEval)}`;
        }
        return /^\d{9}$/.test(toEval);
      }
      case 'dateSlash':
        return (moment(toEval, 'DD/MM/YYYY').isValid());
      case 'required':
        return toEval !== null && toEval !== undefined && toEval !== '';
      case 'business':
        return toEval !== null && toEval !== undefined && toEval !== '';
      case 'url': {
        const urlRegex = '^(?!mailto:)(?:(?:http|https|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$';
        const url = new RegExp(urlRegex, 'i');
        return url.test(toEval);
      }
      default:
        break;
    }

    return true;
  };

  getMessage = (ruleType) => ({
    checked: 'Este campo es requerido',
    date: 'Ingresar una fecha correcta',
    dateSlash: 'Ingresar una fecha correcta',
    email: 'Ingresar un email correcto',
    dni: 'Ingresar un dni correcto.',
    numeric: 'Ingresar un número',
    telephone: 'Agregar el código numérico correspondiente a tu país antes de ingresar tu número telefónico (Todo junto). PE: +51, MX: +52, COL: +57, ECUA: +593.',
    positiveNumber: 'Ingresar un número válido',
    required: 'Este campo es requerido',
    business: 'Este campo es requerido',
    time: 'Ingresar una hora correcta',
    url: 'Ingresar una URL correcta',
  }[ruleType] || '');

  renderError = (ref, id, ruleType, depth = this.defaultDepth,
                 errorClass = 'field-error-message', parentContainerError = false, compound = false
  ) => {

    let parentNode = this.getParentNode(ref, depth);
    if (parentContainerError)
      parentNode = ref.parentNode
    let errorContainer = this.getErrorContainerNode(parentNode, id);

    if (!errorContainer) {
      errorContainer = document.createElement('span');
      errorContainer.setAttribute('id', `${id}-error`);
      errorContainer.setAttribute('class', errorClass);

      if (ruleType === 'checked') {
        parentNode.appendChild(errorContainer);
      } else {
        if (ruleType === 'business' || compound) {
          parentNode.appendChild(errorContainer);
        } else

          parentNode.insertBefore(errorContainer, ref.nextSibling);
      }
    }

    ref.classList.add('field-error');
    ref.parentNode.parentNode.classList.remove('field-ok');
    errorContainer.style.display = 'block';
    errorContainer.innerHTML = this.getMessage(ruleType);
    this.validFields[id] = false;
  };

  clearError = (ref, id, depth = this.defaultDepth) => {
    const parentNode = this.getParentNode(ref, depth);
    const errorContainer = this.getErrorContainerNode(parentNode, id);

    if (errorContainer) {
      ref.classList.remove('field-error');
      errorContainer.style.display = 'none';
    }

    ref.parentNode.parentNode.classList.add('field-ok');

    this.validFields[id] = true;
  };

  validate = (values) => {
    return this.validateVisibleError (values, true)
  };

  validateVisibleError = (values, visibleError) => {
    let isValid = true;

    const fields = Object.keys(values);

    for (let i = 0; i < fields.length; i += 1) {
      const field = fields[i];
      const value = values[field];

      const validation = this.fields.find((config) => config.id === field);

      if (validation) {
        for (let j = 0; j < validation.rules.length; j += 1) {
          const rule = validation.rules[j];

          if (!this.isValidRule(rule, value)) {
            if(visibleError){
              this.renderError(
                validation.ref,
                validation.id,
                rule,
                validation.depth,
                validation.errorClass,
                validation.parentContainerError,
                validation.compound,
              );
            }
            isValid = false;
            break;
          } else {
            if(visibleError) {
              this.clearError(validation.ref, validation.id, validation.depth);
            }
          }
        }
      }
    }

    return isValid;
  };
}
