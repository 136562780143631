import React, {Fragment} from 'react'
import SubscriptionBanner from "./subscriptionBanner";
import NoGPS from "../../common/images/NoGPS.svg";
import ProductsSubscriptionPlaceList from "./productsSubscriptionPlace/ProductsSubscriptionPlaceList";
import SubscriptionOptions from "./productsSubscriptionOptions/SubscriptionOptions";
import {CART_INVISIBLE, CART_VISIBLE} from "../../actions/cart";

export default class SubscriptionContainer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      placeUid: "",
      isEcommerceEnabled: null
    }
  }

  componentDidMount() {
    const {
      changeTypeCart,changeSecondaryMenu
    } = this.props
    //changeStateCart()
    changeSecondaryMenu()
    changeTypeCart()
    this.updateComponent()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.updateComponent()
  }

  updateComponent() {
    const {
      getProductsByPlace,
      selectedPlace,
      company,
      app,
      changeStateCart
    } = this.props;
    if(selectedPlace){
      if (this.state.placeUid !== selectedPlace.uid ) {
        this.setState({placeUid: selectedPlace.uid})
        if(app.allowed_companies && app.allowed_companies.length > 0){
          if(!selectedPlace.subscription_enabled){
            if(company.ecommerce_enabled){
              this.props.history.push(`products`)
            }else{
              this.props.history.push(`program`)
            }
          }
        }else{
          if(!selectedPlace.subscription_enabled){
            if(company.ecommerce_enabled){
              this.props.history.push("/products")
            }else{
              this.props.history.push("/program")
            }
          }
        }

        if(selectedPlace.uid!==null){
          getProductsByPlace(selectedPlace.uid, {for_subscription: true});
        }
      }
    }

    if(company.ecommerce_enabled!==this.state.isEcommerceEnabled){
      this.setState({isEcommerceEnabled: company.ecommerce_enabled})
      if(company.ecommerce_enabled){
        changeStateCart(CART_VISIBLE)
      }else{
        changeStateCart(CART_INVISIBLE)
      }
    }
  }

  render() {
    let {
      company,
      selectedPlace,
      authStatus,
      productsPlace:{
        entities: productsByPlaceEntities,
        results: productsByPlaceResults,
        listProductsByPlaceStatus,
        listNextStatus,
        nextPage,
        count,
        productsByPlaceCurrent,
        productsByPlaceCurrentStatus,
      },
      getMoreProductsByPlace,
      getProductCurrent,
      addProductCartSubscription,
      updateProductCartSubscription,
      entitiesCartSubscription,
      openPopUp,
      activePopUp,
      componentPopUp,
      typeCart,
      infoSubscriptionOptions,
      setInfoSubscriptionOptions,
      history,
      cleanCart
    } = this.props;
    return(
      <Fragment>
        {
          (selectedPlace) ?
            <div>
              <SubscriptionBanner
                company = {company}
                selectedPlace={selectedPlace}
              />
              <SubscriptionOptions
                selectedPlace={selectedPlace}
                infoSubscriptionOptions={infoSubscriptionOptions}
                setInfoSubscriptionOptions={setInfoSubscriptionOptions}
              />
              <ProductsSubscriptionPlaceList
                productsPlace={productsByPlaceResults.map(cUid => productsByPlaceEntities[cUid])}
                count={count}
                nextPageProducts={nextPage}
                listProductsByPlaceStatus={listProductsByPlaceStatus}
                listNextProductsByPlaceStatus={listNextStatus}
                getMoreProductsByPlace={getMoreProductsByPlace}
                placeUid={selectedPlace.uid}
                selectedPlace={selectedPlace}
                productsByPlaceCurrent={productsByPlaceCurrent}
                productsByPlaceCurrentStatus={productsByPlaceCurrentStatus}
                getProductCurrent={getProductCurrent}
                addProductCartSubscription={addProductCartSubscription}
                updateProductCartSubscription={updateProductCartSubscription}
                entitiesCartSubscription={entitiesCartSubscription}
                authStatus={authStatus}
                currency={company.currency}
                openPopUp={openPopUp}
                activePopUp={activePopUp}
                componentPopUp={componentPopUp}
                typeCart={typeCart}
                company={company}
                history={history}
                cleanCart={cleanCart}
              />

            </div> :
            <div className={"no-place col-10 col-md-10 col-lg-8"}>
              <img src={NoGPS} alt="NoPlace"/>
              Por favor seleccione un lugar
            </div>
        }
      </Fragment>
    );
  }
}

