import React, {Fragment} from 'react'
import PropTypes from 'prop-types';
import {STATUS_LOADING, STATUS_SUCCESS} from '../../common/utils/generic';
import Validator from '../../common/utils/validator';
import {BTN_RECOVERY, BTN_RECOVERY_LOADING} from "./constants";
import './style.scss';
import {toast} from "react-toastify";
import {Link} from "react-router-dom";
import Footer from "../footer/Footer";
import {CART_INVISIBLE} from "../../actions/cart";


export default class RecoveryPassword extends React.Component {


  constructor(props) {
    super(props);

    this.state = {
      email: "",
      error: '',
      showResponse: false
    };
  }


  componentDidUpdate(prevProps, prevState, snapshot) {

    let {recoveryStatus} = this.props;

    if (recoveryStatus === STATUS_SUCCESS && this.state.showResponse) {
      toast.success("Correo enviado. Por favor revise su bandeja de entrada.");
      this.setState({showResponse: false})
    }

  }


  componentDidMount() {
    this.validator = new Validator([
      {
        id: "email",
        ref: this.emailInput,
        rules: ["required", "email"]
      }
    ]);
  }

  setFormValues = (propertiesToUpdate) => {
    this.setState(propertiesToUpdate);
    this.validator.validate(propertiesToUpdate);
  }

  onSubmitForm = async (event) => {
    event.preventDefault();
    let {recoveryStatus, recoveryPassword} = this.props;
    this.setState({showResponse: true})

    let payload = {...this.state}

    if (recoveryStatus !== STATUS_LOADING && this.validator.validate({payload})) {


      delete payload.error
      delete payload.showResponse
      recoveryPassword(payload);

    }
  }

  renderError(errors, fieldName) {
    return errors && errors[fieldName] &&
      <span className='field-error-message'>
        {errors[fieldName][0]}
      </span>;
  }

  render() {
    let {recoveryStatus, errors, app: {company: {logo_rectangle, logo}}} = this.props;
    let logo_app = logo_rectangle ? logo_rectangle : logo


    return (
      <Fragment>

        <div className={"rendered-content"}>

          <div className="elevation-container col-12 col-xl-6 col-lg-6 col-md-6 col-sm-8">
            <div className="recovery">
              <div className="box-form">
                <form className="form form-recovery" onSubmit={this.onSubmitForm}>
                  <img src={logo_app ? logo_app : logo} className="logo" alt="logo"/>
                  <h2 className={"text-center"}>Recuperar contraseña</h2> <br></br>
                  <p className={"text-center"}>Ingresar su correo electrónico, donde le enviaremos las indicaciones para recuperar su contraseña.</p>

                  <div className="form-group p-0">
                    <div>
                      <div
                        className="input-group"
                        ref={(input) => {
                          if (input) {
                            this.emailInput = input.querySelector('input');
                          }
                        }}
                      >
                        <input
                          className={"form-control"}
                          value={this.state.email}
                          onChange={e => this.setFormValues({email: e.target.value})}
                          type="email"
                          placeholder="Correo Electrónico"
                          disabled={recoveryStatus === STATUS_LOADING}
                        />
                      </div>
                      {this.renderError(errors, 'email')}
                    </div>
                  </div>

                  {
                    (errors && errors.non_field_errors) &&
                    <div
                      style={{marginTop: 15}}
                    >
                      {this.renderError(errors, 'non_field_errors')}
                    </div>
                  }

                  <div className="submits" style={{marginTop: 30}}>
                    <Link
                      type="button"
                      className="btn btn-secondary btn-cancel"
                      to={'/login'}>
                      Cancelar
                    </Link>


                    <button
                      type="submit"
                      disabled={recoveryStatus === STATUS_LOADING}
                      className="btn btn-login btn-primary">
                      {recoveryStatus === STATUS_LOADING ? BTN_RECOVERY_LOADING : BTN_RECOVERY}
                    </button>

                  </div>

                </form>
              </div>
            </div>

          </div>
        </div>
        <Footer
          stateCart={CART_INVISIBLE}></Footer>
      </Fragment>
    );
  }
}

RecoveryPassword.defaultProps = {
  error: null,
  recoveryStatus: '',
};

RecoveryPassword.propTypes = {
  dispatch: PropTypes.func,
  error: PropTypes.object,
  recoveryStatus: PropTypes.string
};

