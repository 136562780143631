import React, {Fragment} from 'react'
import {STATUS_ERROR, STATUS_LOADING} from "../../common/utils/generic";
import moment from "moment";


export default class ModalOrder extends React.Component {

  constructor() {
    super();
    this.state = {
      orderUid: ""
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let {orderUid, getOrder} = this.props
    if (orderUid !== this.state.orderUid) {
      this.setState({orderUid: orderUid})
      getOrder(orderUid)
    }
  }


  renderDocumentType(documentType) {
    switch (documentType) {
      case 'NATIONAL_ID':
        return (<span>Documento de Identidad</span>);
      case 'PASSPORT':
        return (<span>Pasaporte</span>);
      case 'FOREIGNER_ID':
        return (<span>Carnet de Extranjería</span>);
      case 'TAX_ID':
        return (<span>Ruc</span>);
      default:
        return (<span>Otro</span>);
    }
  }

  renderProducts(products) {
    let {formInitial} = this.props
    let order = formInitial
    return products.map((product, index) => (
      <Fragment key={index}>
        <tr key={`product${index}`}>
          <td><strong>{product.product.title}</strong></td>
          <td> {product.quantity} </td>
          <td> {order.currency ? order.currency.symbol : "S/."}{product.subtotal} </td>
        </tr>
        {product.option_groups.length !== 0 &&
          product.option_groups.map((option_group, index) => (
            <Fragment key={option_group.uid}>
              {
                option_group.options.map((o, index) => (
                  <tr key={o.name}>
                    <td>Extra: {o.name}</td>
                    <td>{product.quantity}</td>
                    <td>{order.currency ? order.currency.symbol : "S/."}{o.price * product.quantity}</td>
                  </tr>
                ))
              }
            </Fragment>
          ))
        }
      </Fragment>

    ));
  }

  render() {
    let {editStatus, retrieveStatus, renderStatus, formInitial} = this.props
    let order = formInitial
    return (
      <div
        className="modal fade"
        ref={(modal) => this.modal = modal}
        id="orderModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-order-detail  modal-lg" role="document">

          <div className="modal-content">
            <div className="modal-header">
              <div className={"col"}>
                <h5 className="modal-title">Detalle de la orden {order && <span>#{order.number}</span>}</h5>
              </div>

              <button onClick={this.handleCloseClick} type="button" className="close close-modal">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body ">
              <div className="col-12">
                {(retrieveStatus === STATUS_LOADING || editStatus === STATUS_LOADING) &&
                  <p>
                    <span>Cargando</span>
                  </p>
                }
              </div>
              <div className="col-12">
                {retrieveStatus === STATUS_ERROR &&
                  <p>
                    <span>Lo sentimos la información no se ha podido traer adecuadamente</span>
                  </p>
                }
              </div>
              <div className="col-12">
                {editStatus === STATUS_ERROR &&
                  <p>
                    <span>Lo sentimos la información no se ha podido actualizar</span>
                  </p>
                }
              </div>
              {(order && (retrieveStatus !== STATUS_LOADING || editStatus !== STATUS_LOADING)) &&
                <div className="">
                  <div className={"col-12 justify-content-center"}>
                    <p className={"status-order"}>
                    <span className={`${order.status} badge `}
                          style={{color: "#000"}}>{renderStatus(order.status)}</span>
                    </p>

                  </div>
                  <div className={"col-12 title-section"}><h6>Datos del usuario</h6></div>
                  {
                    order.user &&
                    <div className={"col-12 body-section "}>
                      <div className={"row"}>
                        <div className={"col-6"}>
                          <strong>Nombre y Apellidos</strong>
                        </div>
                        <div className={"col-6"}>
                          <p>{order.user.first_name} {order.user.last_name}</p>
                        </div>
                        <div className={"col-6"}>
                          <strong>{this.renderDocumentType(order.user.document_type)}</strong>
                        </div>
                        <div className={"col-6"}>
                          <p>{order.user.document_number}</p>
                        </div>
                        <div className={"col-6"}>
                          <strong>email</strong>
                        </div>
                        <div className={"col-6"}>
                          <p>{order.user.email}</p>
                        </div>
                      </div>
                    </div>
                  }
                  {
                    order.contact_phone &&
                    <div className={"col-12 body-section"}>
                      {
                        order.contact_phone && order.contact_phone.length !== 0 &&
                        <div className={"row"}>
                          <div className={"col-6"}>
                            <strong>Número de contacto</strong>
                          </div>
                          <div className={"col-6"}>
                            <p>{order.contact_phone}</p>
                          </div>
                        </div>
                      }
                    </div>
                  }
                  <div className={"col-12 title-section"}><h6>Pedido</h6></div>
                  <div className={"col-12 body-section"}>
                    <div className={"row"}>
                      <div className={"col-6"}>
                        <strong>Tipo de Pedido</strong>
                      </div>
                      {
                        order && order.delivery_method &&
                        <div className={"col-6"}>
                                       <span
                                         className={order.delivery_method ? `badge badge-light ${order.delivery_method ? order.delivery_method.type : ""}` : 'badge badge-light '}>
                                          {order.delivery_method ? `${order.delivery_method.name}` : ''}
                                       </span>
                        </div>
                      }
                      {
                        order && order.delivery_method && order.delivery_method.type === 'delivery' &&
                        <div className={"col-12 section-detail"}>
                          <div className={"row"}>
                            <div className={"col-6 "}>
                              <strong>Distrito</strong>
                            </div>
                            <div className={"col-6"}>
                              {order.delivery_locality && <span>
                                             {order.delivery_locality.name}
                                          </span>}
                            </div>

                            {
                              order.shipping_address && order.shipping_address.length !== 0 &&
                                <Fragment>
                                  <div className={"col-6"}>
                                    <strong>Dirección</strong>
                                  </div>
                                  <div className={"col-6"}>
                                    {order.shipping_address}
                                  </div>
                                </Fragment>
                            }
                            {
                              order.reference_address && order.reference_address.length !== 0 &&
                              <Fragment>
                                <div className={"col-6"}>
                                  <strong>Referencia</strong>
                                </div>
                                <div className={"col-6"}>
                                  {order.reference_address && <span>{order.reference_address}</span>}
                                </div>
                              </Fragment>
                            }
                            {
                              order.comments && order.comments.length !== 0 &&
                              <Fragment>
                                <div className={"col-6"}>
                                  <strong>Comentarios</strong>
                                </div>
                                <div className={"col-6"}>
                                  {order.comments && <span>{order.comments}</span>}
                                </div>
                              </Fragment>
                            }
                            {order.delivery_price !== 0 &&
                              <Fragment>
                                <div className={"col-6"}>
                                  <strong>Precio</strong>
                                </div>
                                <div className={"col-6"}>
                                  <span>{order.delivery_price}</span>
                                </div>
                              </Fragment>
                            }
                          </div>

                        </div>
                      }
                      {
                        order && order.delivery_method && order.delivery_method.type === 'pickup' &&
                        <div className={"col-12 section-detail"}>
                          <div className={"row"}>
                            <div className={"col-6"}>
                              <strong>Fecha de Recojo</strong>
                            </div>
                            <div className={"col-6"}>
                              {`${moment(order.pickup_scheduled_date).format('DD/MM/YYYY')}`}
                            </div>
                            <div className={"col-6"}>
                              <strong>Hora del recojo</strong>
                            </div>
                            <div className={"col-6"}>
                              {order.pickup_scheduled_starts} - {order.pickup_scheduled_ends}
                            </div>

                          </div>

                        </div>
                      }
                      <div className={"col-6"}>
                        <strong>Tipo de Pago</strong>
                      </div>
                      <div className={"col-6"}>
                                       <span
                                         className={order.payment_method ? `badge badge-light ${order.payment_method.type}` : 'badge badge-light '}>
                                          {order.payment_method ? `${order.payment_method.name}` : ''}
                                       </span>
                      </div>
                    </div>
                  </div>
                  <div className={"col-12 title-section"}><h6>Productos</h6></div>
                  {
                    order.order_products &&
                    <div className={"col-12"}>
                      <div className={"row"}>
                        <table className="table table-striped">
                          <thead>
                          <tr>
                            <th scope="col">Producto</th>
                            <th scope="col">Cantidad</th>
                            <th scope="col">Precio</th>
                          </tr>
                          </thead>
                          <tbody>
                          {this.renderProducts(order.order_products)}
                          <tr>
                            <td colSpan={2}>
                              <strong>{order.delivery_price !== null && order.delivery_price !== undefined && order.delivery_price !== 0 ? "TOTAL CON DELIVERY" : "TOTAL"}</strong>
                            </td>
                            <td colSpan={1} className={"price"}>
                              {order.currency ? order.currency.symbol : "S/."}{order.delivery_price !== null && order.delivery_price !== undefined && order.delivery_price !== 0 ? order.total + order.delivery_price : order.total}
                            </td>
                          </tr>
                          </tbody>
                        </table>

                      </div>
                    </div>
                  }
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    )
  }

}

