import {normalize, schema} from 'normalizr';

import {STATUS_ERROR, STATUS_INCLUDE, STATUS_LOADING, STATUS_SUCCESS} from "../common/utils/generic";
import {ADD_PROGRAM, GET_PROGRAM, GET_PROGRAMS} from "../actions/programs";

const programSchema = new schema.Entity('programs', {}, {idAttribute: 'uid'});
const programListSchema = new schema.Array(programSchema);


const initialState = {
  entities: null,
  results: [],
  listStatus: null,
  entity: null,
  retrieveStatus: null,
  addStatus: null,
  count: 0
};

export default function reducer(state = initialState, action) {
  const {type, payload} = action;
  let data;
  switch (type) {

    case `${GET_PROGRAMS}_PENDING`:
      return {
        ...state,
        listStatus: STATUS_LOADING
      };

    case `${GET_PROGRAMS}_FULFILLED`:
      data = payload.status === 200 ? normalize(payload.data.results, programListSchema) : {};

      return {
        ...state,
        entities: data.entities ? data.entities.programs : [],
        results: data.result ? data.result : [],
        listStatus: payload.status === 200 ? STATUS_SUCCESS : STATUS_ERROR,
        count: payload.data ? payload.data.count : 0,
      };
    case `${GET_PROGRAM}_PENDING`:
      return {
        ...state,
        addStatus: null,
        retrieveStatus: STATUS_LOADING
      };

    case `${GET_PROGRAM}_FULFILLED`:


      return {
        ...state,
        entity: payload.data ? payload.data : null,
        addStatus: null,
        retrieveStatus: payload.status === 200 ? STATUS_SUCCESS : ( payload.status === 404 ? STATUS_INCLUDE :STATUS_ERROR),
      };

    case `${ADD_PROGRAM}_PENDING`:
      return {
        ...state,
        addStatus: STATUS_LOADING
      };

    case `${ADD_PROGRAM}_FULFILLED`:
      return {
        ...state,
        addStatus: (payload.status === 200 ||  payload.status === 201) ? STATUS_SUCCESS : STATUS_ERROR
      };

    default:
      return state;
  }
}
