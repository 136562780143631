import HttpClient from "../common/utils/httpClient";

export const GET_RAFFLES = 'GET_RAFFLES';
export const GET_RAFFLE = 'GET_RAFFLE';
export const REGISTER_RAFFLE = 'REGISTER_RAFFLE';
export const DISMISS_RAFFLE = 'DISMISS_RAFFLE';

/**Urls**/
export const LIST_RAFFLES_URL = 'v5/me/programs/{program_id}/raffles/';
export const LIST_REGISTER_RAFFLE_URL = 'v5/me/programs/{program_id}/raffles/{raffle_uid}/register/';
export const LIST_DISMISS_RAFFLE_URL = 'v5/me/programs/{program_id}/raffles/{raffle_uid}/dismiss/';

export function getRaffles(programUid) {
  let url = LIST_RAFFLES_URL.replace("{program_id}",programUid)
  return (dispatch) => HttpClient.get(dispatch, GET_RAFFLES, url);
}

export function getRaffle(programUid, uidRaffle) {
  let url = LIST_RAFFLES_URL.replace("{program_id}",programUid)
  return (dispatch) => HttpClient.get(dispatch, GET_RAFFLE, `${url}${uidRaffle}/`);
}

export function registerInRaffle(data, programUid, uidRaffle) {
  let url = LIST_REGISTER_RAFFLE_URL.replace("{program_id}",programUid).replace("{raffle_uid}",uidRaffle)
  return (dispatch) => HttpClient.post(data, dispatch, REGISTER_RAFFLE, `${url}`);
}

export function dismissToRaffle(programUid, uidRaffle) {
  let url = LIST_DISMISS_RAFFLE_URL.replace("{program_id}",programUid).replace("{raffle_uid}",uidRaffle)
  return (dispatch) => HttpClient.post({}, dispatch, DISMISS_RAFFLE, `${url}`);
}