import React, {Fragment} from 'react'
import PropTypes from 'prop-types';
import InfiniteScroll from "react-infinite-scroller";
import {STATUS_LOADING, STATUS_SUCCESS} from "../../../common/utils/generic";
import Product from "../../products/productsPlace/components/productCard/Product";
import './style.scss';
import NoSearchResult from "../../../common/images/NoSearchResult.svg";
import ProductPopUp from "../../products/productsPlace/components/productPopUp";

export default class ProductsPlaceList extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      uidProduct: ""
    }
  }

  loadMore = () => {

    const {nextPageProducts, listNextProductsByPlaceStatus, listProductsByPlaceStatus, getMoreProductsByPlace} = this.props;
    if (listNextProductsByPlaceStatus !== STATUS_LOADING && listProductsByPlaceStatus !== STATUS_LOADING && nextPageProducts != null) {
      getMoreProductsByPlace(nextPageProducts);
    }
  };

  setProductUid = (uid) => {
    this.setState({
      uidProduct: uid
    })
  };

  renderPopUp = () => {
    let {history,cleanCart, authStatus, productsByPlaceCurrent, productsByPlaceCurrentStatus, openPopUp, activePopUp, componentPopUp, getProductCurrent, placeUid,
      selectedPlace, currency, company, typeCart, addProductCartSubscription, entitiesCartSubscription, updateProductCartSubscription} = this.props;

    if (this.state.uidProduct !== "") {
      return (
        <ProductPopUp
          cleanCart={cleanCart}
          productsByPlaceCurrent={productsByPlaceCurrent}
          productsByPlaceCurrentStatus={productsByPlaceCurrentStatus}
          openPopUp={openPopUp}
          activePopUp={activePopUp}
          getProductCurrent={getProductCurrent}
          placeUid={placeUid}
          uid={this.state.uidProduct}
          componentPopUp={componentPopUp}
          entitiesCartSubscription={entitiesCartSubscription}
          addProductCartSubscription={addProductCartSubscription}
          updateProductCartSubscription={updateProductCartSubscription}
          selectedPlace={selectedPlace}
          authStatus={authStatus}
          currency={currency}
          typeCart={typeCart}
          company={company}
          setProductUid={this.setProductUid}
          history={history}
        />
      );
    }
  }

  setProductUid = (uid) => {
    this.setState({
      uidProduct: uid
    })
  };

  renderProductsSubscriptionPlace() {
    const {productsPlace, listProductsByPlaceStatus, count, placeUid, currency, openPopUp} = this.props;

    if (listProductsByPlaceStatus === STATUS_LOADING) {
      return <Fragment>
        <Product loading={true}/>
        <Product loading={true}/>
        <Product loading={true}/>
        <Product loading={true}/>
        <Product loading={true}/>
        <Product loading={true}/>
      </Fragment>;
    }

    if (count === 0 && listProductsByPlaceStatus === STATUS_SUCCESS) {
      return <div className={"mx-auto"}>
        <div className={"d-flex flex-column justify-content-center align-items-center"}>
          <img src={NoSearchResult} alt="noItems"/>
          <p className={"mt-3"}>No se encuentran resultados para esta búsqueda</p>
        </div>
      </div>
    }

    return productsPlace.map((productPlace, index) => (
      <Product
        key={`promo${index}`}
        placeUid={placeUid}
        product={productPlace}
        loading={false}
        setProductUid={this.setProductUid}
        currency={currency}
        openPopUp={openPopUp}
      />
    ));

  }

  render() {

    let {nextPageProducts, componentPopUp} = this.props;
    let more = nextPageProducts != null;
    let loaderMore = (
      <Fragment key={"load"}>
        <Product loading={true}/>
        <Product loading={true}/>
        <Product loading={true}/>
      </Fragment>
    );
    return (
      <div className={"container"}>
        <InfiniteScroll
          className="row product-list"
          pageStart={0}
          loadMore={this.loadMore.bind(this)}
          hasMore={more}
          loader={loaderMore}
        >
          {this.renderProductsSubscriptionPlace()}
        </InfiniteScroll>
        {
          (componentPopUp=== "productPopUp") &&
          this.renderPopUp()
        }
      </div>
    );
  }
}

ProductsPlaceList.defaultProps = {
  productsPlace: [],
  listProductsByPlaceStatus: '',
  count: 0,
  nextPageProducts: null
};

ProductsPlaceList.propTypes = {
  productsPlace: PropTypes.array,
  nextPageProducts: PropTypes.string,
  getMoreProductsByPlace: PropTypes.func,
  listNextProductsByPlaceStatus: PropTypes.string,
  placeUid: PropTypes.string,
  count: PropTypes.number,
  listProductsByPlaceStatus: PropTypes.string
};

