import {normalize, schema} from 'normalizr';

import {STATUS_ERROR, STATUS_LOADING, STATUS_SUCCESS} from "../common/utils/generic";
import {
  GET_ADS,
} from "../actions/ads";


const adSchema = new schema.Entity('ads', {}, {idAttribute: 'uid'});
const adListSchema = new schema.Array(adSchema);


const initialState = {
  adsStatus: null,
  entities: null,
  results: [],
  listStatus: null,
  nextPage: null,
  count: 0,
};

export default function reducer(state = initialState, action) {
  const {type, payload} = action;
  let data;
  switch (type) {
    case `${GET_ADS}_PENDING`:
      return {
        ...state,
        listStatus: STATUS_LOADING,
        count: 0,
        entities: null,
        nextPage: null,
        results: []
      };
    case `${GET_ADS}_FULFILLED`:
      data = payload.status === 200 ? normalize(payload.data, adListSchema) : {};

      return {
        ...state,
        entities: data.entities ? data.entities.ads : [],
        results: data.result ? data.result : [],
        nextPage: payload.data ? payload.data.next : null,
        listStatus: payload.status === 200 ? STATUS_SUCCESS : STATUS_ERROR,
        count: payload.data ? payload.data.count : 0,
      };

    default:
      return state;
  }
}
