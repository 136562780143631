import React from 'react'
import {toast} from "react-toastify";
import PropTypes from 'prop-types';
import './style.scss';
import "react-datepicker/dist/react-datepicker.css"
import {STATUS_SUCCESS} from "../../common/utils/generic";

export default class DetailRaffle extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      load: false,
      programUid: null,
      showRegister: false,
      showDismiss: false,
    }
  }

  componentDidMount() {
    let {changeSecondaryMenu, changeStateCart} = this.props
    changeSecondaryMenu()
    changeStateCart()
    this.updateComponent();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.updateComponent();
  }

  updateComponent = () => {
    let {
      getRaffle,
      programs,
      match: {params: {uid}},
      raffles, changeSecondaryMenu, changeStateCart, stateCart
    } = this.props
    if(programs && programs.length!==0 && !this.state.load){
      getRaffle(programs[0].uid, uid)
      this.setState({load: true, programUid: programs[0].uid})
    }
    if(this.state.showRegister){
      if(raffles.registerStatus===STATUS_SUCCESS){
        toast.success("Se ha inscrito correctamente en el sorteo")
        this.setState({showRegister: false})
        setTimeout(function () {
          document.location.href = '/products'
        }, 1000)
      }
    }

    if(this.state.showDismiss){
      if(raffles.dismissStatus===STATUS_SUCCESS){
        toast.success("Se eliminó correctamente el sorteo")
        this.setState({showDismiss: false})
        setTimeout(function () {
          document.location.href = '/products'
        }, 1000)
      }
    }

    if(stateCart!=="CART_INVISIBLE"){
      changeSecondaryMenu()
      changeStateCart()
    }
  }
  register = e => {
    let {user, history, registerInRaffle,
      match: {params: {uid}},} = this.props
    e.preventDefault()
    if(user.email.length===0 || user.mobile_number.length===0){
      toast.error("Debe completas todos sus datos personales para inscribirse en el sorteo")
      history.push("/profile")
    }else{
        registerInRaffle({
          "area":user.area.uid,
          "mobile_number":user.mobile_number,
          "email": user.email
        },this.state.programUid, uid)
      this.setState({showRegister: true})
    }
  }

  render() {
    let {raffles, dismissToRaffle} = this.props
    let raffle = null
    if(raffles.entity){
      raffle = raffles.entity
    }
    return (
      <div className="container">
        {
          raffles.entity &&
            <div className={"d-flex detail-raffle flex-column flex-md-row"}>
              <div className={"col-12 col-md-4"}>
                <img
                  src={raffle.image}
                  alt={"img"}
                  className={"d-block w-100"}
                />
                <button
                  style={{textOverflow:"ellipsis", overflow: "hidden", width:"100%"}}
                  className="btn btn-raffle btn-primary btn-refuse-raffle mb-4 mt-4" onClick={e=>{
                  e.preventDefault()
                  dismissToRaffle(this.state.programUid, raffle.uid)
                  this.setState({showDismiss: true})
                }}>
                  No me interesa
                </button>
              </div>
              <div className={"col-12 col-md-8"}>
                <h5 className={"font-weight-bold"} style={{fontSize: "1.4rem"}}>{raffle.name}</h5>
                <h5 className={"font-weight-bold mt-4"}>Términos y condiciones</h5>
                <h5 style={{color: "#6b6b6b"}}>{raffle.terms}</h5>

                <button
                  style={{textOverflow:"ellipsis", overflow: "hidden", width:"100%"}}
                  className="btn btn-raffle btn-primary mt-2 btn-accept-raffle" onClick={this.register}>
                  Participar
                </button>
              </div>
            </div>
        }

      </div>

    );
  }
}

DetailRaffle.defaultProps = {
  error: null,
  updateStatus: '',
};

DetailRaffle.propTypes = {
  dispatch: PropTypes.func,
  error: PropTypes.object,
  updateStatus: PropTypes.string
};

