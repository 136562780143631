import React from 'react'
import Select from "react-select";

export default class SelectDays extends React.Component {

  render() {

    let { subscriptionsDaysEnabled, changeValue, subscriptionsDaysSelected } = this.props

    return (
      <Select
        options={subscriptionsDaysEnabled.map((a) => {
          return {label: `${a.name}`, value: a.uid};
        })}
        isMulti={true}
        value={subscriptionsDaysSelected}
        onChange={select_days => changeValue({subscriptionsDaysSelected: select_days || []})}
        placeholder={"FRECUENCIA DEL PEDIDO"}
        className={`pl-0 ml-0 days-selector`}
      />
    );
  }
}