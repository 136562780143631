import React from 'react'
import $ from "jquery";
import "./style.scss"

export default class ModalExchange extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      promotionUid: "",
      qrCode: "",
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let {promotionUid,
      showModal,
      openPromotionDetail,
      getQRPromotion,
      qrCode,
      setQrCode,
      promotion,
      typePopUp
    } = this.props
    if (promotionUid !== this.state.promotionUid) {
      this.setState({promotionUid: promotionUid})
      getQRPromotion()
    }
    if(promotion && promotion.allow_qr && qrCode !== this.state.qrCode){
      this.setState({qrCode: qrCode})
      setQrCode(qrCode)
    }

    if (openPromotionDetail && typePopUp==="qr") {
      $('#promotionModal').modal('show');
      showModal(false);
    }
    $('body').css("padding", "0");

  }

  handleCloseClick = (e) => {
    e.preventDefault()
    let {setPromotionUid} = this.props
    setPromotionUid("")
    $(this.modal).modal('hide');
  }


  render() {
    let {app:{subdomain, company: {logo_rectangle, logo}}, promotion} = this.props
    let mainColor =  "#20a6ff"

    let logo_app = logo ? logo : logo_rectangle
    return (
      <div
        className={"modal fade"}
        ref={(modal) => this.modal = modal}
        id="promotionModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-order-detail  modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-body ">
              <img src={logo_app} className="logo mx-auto"
                   alt="logo" style={subdomain!=="banbif" ? {width: 120} : {}}/>
              <h3>Para realizar el canje sigue las siguientes instrucciones:</h3>
              <div className={"content-detail"}>
                {
                  promotion && promotion.info_to_redeem &&
                  <div dangerouslySetInnerHTML={{ __html: `${promotion && promotion.info_to_redeem}` }} />
                }
                {
                  this.state.qrCode &&
                  <img src={this.state.qrCode} alt="qr" className={"qr-code"}/>
                }
              </div>
              <button className={"btn-back"} onClick={e=>this.handleCloseClick(e)}
                      style={{backgroundColor: `${mainColor}`, border: `2px solid ${mainColor}`}}>
                Regresar
              </button>
              <button onClick={this.handleCloseClick} type="button" className="close close-modal">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }

}

