import React from "react";
import './style.scss'
import bg from "../../common/images/background.jpg";
import ReactImageAppear from "react-image-appear";
import {Link} from "react-router-dom";

export default class Landing extends React.Component {


  render() {
    let {
      app: {company: {logo_rectangle, logo, cover},
      }} = this.props;
    let logo_app = logo_rectangle ? logo_rectangle : logo
    let cover_app = cover ? cover : bg
    return (
      <div className={"rendered-content"}>
        <div className="elevation-container">
          <div className="login col-auto">
            <div className="box-form  d-flex flex-column">
              <div className="box-landing">
                <ReactImageAppear
                  showLoader={false}
                  placeholder={false}
                  animationDuration="1s"
                  placeholderClass={"placeholder"}
                  src={logo_app}
                  className="logo"
                  alt="logo"
                />
              </div>
              <Link to="/recovery-password" className="forget" style={{position: "absolute", bottom: 50,right: 50}}>Olvidé mi contraseña</Link>
            </div>
          </div>
          <div className="publicity col-auto">
            <ReactImageAppear
              className="cover"
              placeholder={bg}
              placeholderClass={"placeholder"}
              src={cover_app}
              showLoader={false}
              animationDuration="1s"
            />
          </div>
        </div>
      </div>
    );
  }

}