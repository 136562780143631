import React, {Fragment} from 'react'
import PropTypes from 'prop-types';
import bg from "../../../common/images/background.jpg";
import moment from "moment";
import "../style.scss";

export default class PlaceBanner extends React.Component {

  render() {
    let {
      company:{banner, cover, logo_rectangle, logo, is_digital_entity},
      selectedPlace,
      showLogoCompany=false
    } = this.props;

    let cover_app = banner ? banner : cover || bg

    let logo_app = logo ? logo  : logo_rectangle

    return (

      <div className={"container-banner"}>

        {
          (selectedPlace!==undefined && selectedPlace !== null && selectedPlace.locality && selectedPlace.locality !== undefined) ?
            <div className={"container"}>
              <div className={"banner row"} style={{backgroundImage: `url(${cover_app})`}}>
                <div className={"card col-lg-6 col-md-8"}>
                  <div className={"row align-items-center"}>

                    <div className={"col place-info"}>
                      <div className={"title-banner d-flex align-items-center"}>
                        {
                          showLogoCompany &&
                          <img alt=""
                               src={logo_app}
                               width={"60"}
                               height={"60"}
                               id={"logo-banner"}
                               className="d-sm-block d-md-block d-lg-block mr-3"/>
                        }
                        {selectedPlace.name &&
                        selectedPlace.name !== "" &&
                        <p className={"mr-1"}>{selectedPlace.name}</p>
                        }
                        {selectedPlace.locality && selectedPlace.locality.name &&
                        selectedPlace.locality.name !== "" &&
                        is_digital_entity!==true &&
                        <span>{selectedPlace.locality.name}</span>
                        }
                      </div>
                      {selectedPlace.address &&
                      selectedPlace.address !== "" &&
                      is_digital_entity!==true  &&
                      <p className={"mb-1"} >{selectedPlace.address}</p>
                      }
                      {((selectedPlace.telephone1 &&
                        selectedPlace.telephone1 !== "") ||
                        (selectedPlace.telephone2 &&
                          selectedPlace.telephone2 !== "")) &&
                      <Fragment>
                        <p className={"mb-0 d-flex justify-content-between"} style={{marginTop: "1rem"}}>
                          <span className={"font-weight-bold mr-3"}>Teléfono:</span> {" "}
                          {selectedPlace.telephone1 &&
                          selectedPlace.telephone1 !== "" && (
                            selectedPlace.telephone1
                          )}
                          {selectedPlace.telephone2 &&
                          selectedPlace.telephone2 !== "" && (
                            (" - ").concat(selectedPlace.telephone2)
                          )}</p>
                      </Fragment>
                      }
                      {selectedPlace.timetable &&

                      <div className="schedule mt-4">
                        <Fragment>
                          <p className={"font-weight-bold"}>Horario:</p>
                          <div className="days d-flex flex-column">
                            {selectedPlace.timetable.monday_hours_from && (
                              <div className={"d-flex justify-content-between"}>
                                <p>Lunes - Viernes : {" "}</p>
                                <p>{moment(
                                  selectedPlace.timetable.monday_hours_from,
                                  "hh:mm:ss"
                                ).format("hh:mm A")}{" "}
                                  -{" "}
                                  {moment(
                                    selectedPlace.timetable.monday_hours_to,
                                    "hh:mm:ss"
                                  ).format("hh:mm A")}</p>
                                 </div>
                            )}
                            {selectedPlace.timetable.saturday_hours_from && (
                              <div className={"d-flex justify-content-between"}>
                                <p>Sábado: {" "}</p>
                                <p>
                                  {moment(
                                    selectedPlace.timetable.saturday_hours_from,
                                    "hh:mm:ss"
                                  ).format("hh:mm A")}{" "}
                                  -{" "}
                                  {moment(
                                    selectedPlace.timetable.saturday_hours_to,
                                    "hh:mm:ss"
                                  ).format("hh:mm A")}</p>
                              </div>

                            )}
                            {selectedPlace.timetable.sunday_hours_from && (
                              <div className={"d-flex justify-content-between"}>
                                <p>Domingo: {" "}</p>
                                <p>{moment(
                                  selectedPlace.timetable.sunday_hours_from,
                                  "hh:mm:ss"
                                ).format("hh:mm A")}{" "}
                                  -{" "}
                                  {moment(
                                    selectedPlace.timetable.sunday_hours_to,
                                    "hh:mm:ss"
                                  ).format("hh:mm A")}</p>
                              </div>
                            )}
                          </div>
                        </Fragment>
                      </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
            :
            <div className={"container loading"}>
              <div className={"banner row"} >
                <div className={"card col-lg-6 col-md-6"}>
                  <div className={"row"}>
                    <div className={"col level-info"}>
                      <h4> </h4>
                      <p className="mt-2 mb-2"> </p>
                      <p className="mt-3 mb-2"> </p>
                      <div className="horary loading mt-4">
                          <p></p>
                          <div className="days mt-3 d-flex">
                              <p></p>
                              <p></p>
                              <p></p>
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

        }

      </div>
    );
  }
}

PlaceBanner.defaultProps = {
  app: null
};

PlaceBanner.propTypes = {
  app: PropTypes.object
};

