import React, { Component } from "react";
import { Link } from "react-router-dom";
import wsp from "../Molitienda/images/wsp.png";

export default class SectionModel2 extends Component {
  render() {
    const {
      section,
      app,
      linkWsp,
      numberWhatsapp,
      app: { company },
      path,
    } = this.props;
    //console.log(this.props);
    let domainSite =
      app?.domain !== null
        ? app?.domain.split(`/landing`).shift()
        : app?.domain;
    return (
      <>
        {section !== null &&
          section !== [] &&
          section?.type === "WELCOME" &&
          section?.is_active && (
            <div className="welcome">
              <div className="container container-molitienda">
                <div className="wrapper">
                  <div className="welcome-left col-7">
                    <h1>{section.tittle}</h1>
                    <p className="lead">{section.content}</p>
                    <div className={"btns-welcome col-12 p-0"}>
                      {section?.buttons.map((btn, index) => {
                        if (btn?.link.includes(domainSite)) {
                          /*let domainHttp = btn?.link.includes("https")
                          ? "https://"
                          : "http://";*/
                          /*let toLink = btn?.link
                          .split(`${domainHttp}${domainSite}`)
                          .shift();*/
                          //   console.log(domainSite);
                          //  console.log(btn?.link);
                          //  console.log(btn?.link.includes(domainSite),"ssssssss");
                          //  console.log(toLink);
                          return (
                            <Link
                              key={btn.uid}
                              to={
                                company.ecommerce_enabled
                                  ? company.ecommerce_enabled ||
                                    !company.subscription_enabled
                                    ? `${path}products`
                                    : `${path}program/${app.allowed_programs[0].uid}`
                                  : `${path}program/${app.allowed_programs[0].uid}`
                              }
                              className={
                                index === 0 ? "btn-order" : "btn-go-market"
                              }
                            >
                              {btn.tittle}
                            </Link>
                          );
                        } else {
                          return (
                            <a
                              key={btn.uid}
                              href={btn.link}
                              target="_blank"
                              rel="noopener noreferrer"
                              className={
                                index === 0 ? "btn-order" : "btn-go-market"
                              }
                            >
                              {btn.tittle}
                            </a>
                          );
                        }
                      })}
                    </div>
                  </div>
                  <div className="welcome-right col-4">
                    <img
                      src={app.company?.logo}
                      alt="logo"
                      className="logo logo-molitienda"
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        {section !== null &&
          section !== [] &&
          section?.type === "INFORMATIVE" &&
          section?.is_active && (
            <div className="container container-molitienda">
              {section?.cards.map((card, index) => {
                if (index < 3 && card?.is_active) {
                  return (
                    <div
                      className={
                        card.images[0]?.spatial_position === "LEFT"
                          ? "wrapper-register"
                          : "wrapper-register reverse_apicarousel"
                      }
                      key={card.uid}
                    >
                      <div className={"wrapper-register-left col-6"}>
                        <img
                          src={card.images[0]?.image}
                          alt="imageCard"
                          className="logo logo-molitienda"
                        />
                      </div>
                      <div className={"wrapper-register-right col-5"}>
                        {card.tittle !== null && <h1>{card.tittle}</h1>}
                        {card.content !== null && (
                          <p className="lead">{card.content}</p>
                        )}
                        {card.buttons.length > 0 && (
                          <a
                            href={card.buttons[0].link}
                            className={"btn-data"}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {card.buttons[0].tittle}
                          </a>
                        )}
                      </div>
                    </div>
                  );
                }
              })}
            </div>
          )}
        {section !== null &&
          section !== [] &&
          section?.type === "CARD" &&
          section?.is_active && (
            <div className="container container-molitienda">
              <div className="wrapper-area">
                {section?.cards.map((card, index) => {
                  if (index < 3 && card?.is_active) {
                    return (
                      <div
                        className={"agent"}
                        key={card.uid}
                        style={
                          card.color !== null && card.color.includes("#")
                            ? { backgroundColor: `${card.color}` }
                            : { backgroundColor: `#${card.color}` }
                        }
                      >
                        <img src={card.images[0].image} alt="agent" />
                        <p className="name">{card.tittle}</p>
                        {card.subtittle !== null && (
                          <p className="rol">{card.subtittle}</p>
                        )}
                        <p className="description">{card.content}</p>
                        <br />
                        <br />
                      </div>
                    );
                  }
                })}
              </div>
            </div>
          )}

        {section !== null &&
          section !== [] &&
          section.type === "SMALL_IMAGE" &&
          section.is_active &&
          section.cards.length===0 && (
            <div className="wrapper-stores-landing">
              <h1>{section?.tittle}</h1>
              <div
                className={
                  section?.images.length < 3 ? "list-stores-one" : "list-stores"
                }
              >
                {section?.images.map((img, index) => {
                  if (index < 5 && img?.is_active) {
                    return (
                      <a
                        key={img.uid}
                        href={img.link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={img.image} alt="tienda1" />
                      </a>
                    );
                  }
                })}
              </div>
            </div>
          )}

        {section !== null &&
          section !== [] &&
          section.type === "SMALL_IMAGE" &&
          section.is_active &&
          section.cards.length > 0 && (
            <div className="small-image-card">
              {section.cards.map((card,index) => {
                  if (index < 3 && card.is_active) {
                    return (
                      <div
                        className={"agent"}
                        key={card.uid}
                      >
                        <p className="name">{card.tittle}</p>
                        <img src={card.images[0].image} alt="agent" />
                      </div>
                    );
                  }
                  return true;
                }
                )}
            </div>
          )}

        {section !== null &&
          section !== [] &&
          section?.type === "CAROUSEL" &&
          section?.is_active && (
            <>
              <div
                id="carouselExampleIndicators"
                className="carousel slide wrapper-fundo"
                data-ride="carousel"
              >
                <div className="wrapper-fundo-container">
                  <h2 className={"text-carousel-api"}>{section?.tittle}</h2>
                  <br />
                  <ol className="carousel-indicators">
                    {section?.cards.map((card, index) => {
                      return (
                        <li
                          key={card.uid}
                          data-target="#carouselExampleIndicators"
                          data-slide-to={`${index}`}
                          className={index === 0 ? "active" : ""}
                        ></li>
                      );
                    })}
                  </ol>
                  <div className="carousel-inner">
                    {section?.cards.map((card, index) => {
                      return (
                        <div
                          key={card.uid}
                          className={
                            index === 0
                              ? "carousel-item active"
                              : "carousel-item"
                          }
                        >
                          <div>
                            <div
                              className={
                                card.images[0].spatial_position === "LEFT"
                                  ? "wrapper-fundo-content carousel-content"
                                  : "wrapper-fundo-content carousel-content reverse_apicarousel"
                              }
                            >
                              <div className="wrapper-fundo-right">
                                <img
                                  src={card.images[0].image}
                                  alt="cardImage"
                                />
                              </div>
                              <div className="wrapper-fundo-left">
                                <img
                                  src={card.images[1]?.image}
                                  alt="cardImage"
                                />
                                <h1>{card.tittle}</h1>
                                <p>{card.content}</p>
                                {card?.buttons.length > 0 && (
                                  <a
                                    href={card?.buttons[0].link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className={"btn-order"}
                                  >
                                    {card?.buttons[0].tittle}
                                  </a>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </>
          )}
        {section !== null &&
          section !== [] &&
          section?.type === "FORM" &&
          section?.is_active && (
            <div className="wrapper-form" id={"form"}>
              <iframe
                className="iframe formLandingTwo_iframe"
                id="frame1"
                title={"iframe"}
                src={section?.link}
                marginHeight={0}
                marginWidth={0}
                frameBorder={0}
              >
                Cargando…
              </iframe>
            </div>
          )}
        {section !== null &&
          section !== [] &&
          section?.type === "CONTACT" &&
          section?.is_active && (
            <div className={"wrapper-wsp"}>
              <br />
              <h1>{section?.tittle}</h1>
              <p>{section?.content}</p>
              <div className={"wsp"}>
                {app.company?.whatsapp_number !== null && (
                  <a
                    href={`http://${linkWsp}.whatsapp.com/send?phone=${numberWhatsapp}&text=Hola!%20Estoy%20interesado%20en%20tu%20servicio`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={wsp} alt="wsp" />
                  </a>
                )}
              </div>
            </div>
          )}
      </>
    );
  }
}
