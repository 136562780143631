import React, {Fragment} from 'react'
import PropTypes from 'prop-types';
import InfiniteScroll from "react-infinite-scroller";
import {STATUS_LOADING, STATUS_SUCCESS} from "../../../common/utils/generic";
import ProductColumn from "./components/productCard/Product";
import './style.scss';
import NoSearchResult from "../../../common/images/NoSearchResult.svg";
import ProductPopUp from "./components/productPopUp";
import {Link} from "react-router-dom";

export default class ProductsPlaceList extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      uidProduct: ""
    }
  }

  loadMore = () => {

    const {nextPageProducts, listNextProductsByPlaceStatus, listProductsByPlaceStatus, getMoreProductsByPlace} = this.props;
    if (listNextProductsByPlaceStatus !== STATUS_LOADING && listProductsByPlaceStatus !== STATUS_LOADING && nextPageProducts != null) {
      getMoreProductsByPlace(nextPageProducts);
    }
  };
  renderPopUp = () => {
    let {multisite, cleanCart,history, authStatus, productsByPlaceCurrent, productsByPlaceCurrentStatus, openPopUp, activePopUp, componentPopUp, getProductCurrent, placeUid,
      addProductCart, selectedPlace, company, entitiesCart, addProductsBack,updateProductCart, currency, typeCart} = this.props;

    if (this.state.uidProduct !== "") {

      let listOptionsCartProduct = []
      if(entitiesCart && entitiesCart.products !== undefined){
        Object.keys(entitiesCart.products).map(p => {
          if(this.state.uidProduct===entitiesCart.products[p].id){
            if(entitiesCart.products[p].length!==0){
              if(entitiesCart.products[p].option_groups){
                listOptionsCartProduct = entitiesCart.products[p].option_groups
              }
            }
          }
          return true;
        })
      }
      return (
        <ProductPopUp
          productsByPlaceCurrent={productsByPlaceCurrent}
          productsByPlaceCurrentStatus={productsByPlaceCurrentStatus}
          openPopUp={openPopUp}
          activePopUp={activePopUp}
          getProductCurrent={getProductCurrent}
          placeUid={placeUid}
          setProductUid={this.setProductUid}
          uid={this.state.uidProduct}
          componentPopUp={componentPopUp}
          addProductCart={addProductCart}
          addProductsBack ={addProductsBack }
          selectedPlace={selectedPlace}
          entitiesCart={entitiesCart}
          authStatus={authStatus}
          updateProductCart={updateProductCart}
          currency={currency}
          typeCart={typeCart}
          listOptionsCartProduct={listOptionsCartProduct}
          company={company}
          history={history}
          cleanCart={cleanCart}
          multisite={multisite}
        />
      );
    }
  }

  setProductUid = (uid) => {
    this.setState({
      uidProduct: uid
    })
  };

  renderProductsPlace() {
    const {productsPlace, listProductsByPlaceStatus, count, placeUid, openPopUp, currency,newStyle,countProduct} = this.props;

    if (listProductsByPlaceStatus === STATUS_LOADING) {
      return <Fragment>
        <ProductColumn loading={true}/>
        <ProductColumn loading={true}/>
        <ProductColumn loading={true}/>
        <ProductColumn loading={true}/>
        <ProductColumn loading={true}/>
        <ProductColumn loading={true}/>
      </Fragment>;
    }

    if (count === 0 && listProductsByPlaceStatus === STATUS_SUCCESS) {
      return <div className={"mx-auto"}>
        <div className={"d-flex flex-column justify-content-center align-items-center"}>
          <img src={NoSearchResult} alt="noItems"/>
          <p className={"mt-3"}>No se encuentran resultados para esta búsqueda</p>
        </div>
      </div>
    }
    // eslint-disable-next-line
    return productsPlace.map((productPlace, index) => 
      {
        if (newStyle ? index < countProduct : true ) {
          return <ProductColumn
          key={`promo${index}`}
          count={count}
          placeUid={placeUid}
          product={productPlace}
          loading={false}
          openPopUp={openPopUp}
          setProductUid={this.setProductUid}
          currency={currency}
          newStyle={newStyle}
        />
        }
      }
      
    );

  }

  renderProductsPlaceList() {
    const {productsPlace, listProductsByPlaceStatus, count, placeUid, openPopUp, currency,newStyle} = this.props;

    if (listProductsByPlaceStatus === STATUS_LOADING) {
      return <Fragment>
        <ProductColumn loading={true}/>
        <ProductColumn loading={true}/>
        <ProductColumn loading={true}/>
        <ProductColumn loading={true}/>
      </Fragment>;
    }

    if (count === 0 && listProductsByPlaceStatus === STATUS_SUCCESS) {
      return <div className={"mx-auto"}>
        <div className={"d-flex flex-column justify-content-center align-items-center"}>
          <img src={NoSearchResult} alt="noItems"/>
          <p className={"mt-3"}>No se encuentran resultados para esta búsqueda</p>
        </div>
      </div>
    }
    // eslint-disable-next-line
    return productsPlace.map((productPlace, index) =>
      {
        if (index < 4) {
          return <ProductColumn
          key={`promo${index}`}
          placeUid={placeUid}
          product={productPlace}
          loading={false}
          openPopUp={openPopUp}
          setProductUid={this.setProductUid}
          currency={currency}
          newStyle={newStyle}
          count={count}
        />
        }
      }

    );

  }

  render() {
    let {count, nextPageProducts, componentPopUp, sections, newStyle, productsPlace, multisite, company} = this.props;
    let more = nextPageProducts != null;
    let loaderMore = (
      <Fragment key={"load"}>
        <ProductColumn loading={true}/>
        <ProductColumn loading={true}/>
        <ProductColumn loading={true}/>
      </Fragment>
    );

    let url = "";
    if (multisite && company) url = `/c/${company.uid}`;

    return (
      <div className={"container"}>

        {
          !newStyle && false && (
              sections.map(section => {
                if(localStorage.getItem("sectionSearchProduct")===section.uid) {
                  return <h3 className={"category-title ml-3"}>{section.name}</h3>
                }
                return true
              })
          )
        }

        {
          !newStyle &&
          <div className="DiscountBoxList">
            { productsPlace.length>3 ? (
              <>
                <h3 className={"category-title mt-4 ml-3"}>PRODUCTOS</h3>
                <h4 className={"category-title mt-4"}>
                  {/* <a href="/">Ver más</a> */}
                  <Link to={`${url}/products/more`}>Ver más</Link>
                </h4>
              </>
            ) : (
              <h1>&nbsp;</h1>
            )}
          </div>
        }

        {
          newStyle ?
          <InfiniteScroll
            className={newStyle ? "exchange-list" : "row product-list"}
            pageStart={0}
            loadMore={this.loadMore.bind(this)}
            hasMore={more}
            loader={loaderMore}
          >
            {this.renderProductsPlace()}
          </InfiniteScroll> :

            <div className={count>3 ? "d-flex flex-wrap justify-content-between" : "d-flex flex-wrap"}>
              {this.renderProductsPlaceList()}
            </div>
        }
        {
         (componentPopUp=== "productPopUp") &&
          this.renderPopUp()
        }
      </div>
    );
  }
}

ProductsPlaceList.defaultProps = {
  productsPlace: [],
  listProductsByPlaceStatus: '',
  count: 0,
  nextPageProducts: null
};

ProductsPlaceList.propTypes = {
  productsPlace: PropTypes.array,
  nextPageProducts: PropTypes.string,
  getMoreProductsByPlace: PropTypes.func,
  listNextProductsByPlaceStatus: PropTypes.string,
  placeUid: PropTypes.string,
  count: PropTypes.number,
  listProductsByPlaceStatus: PropTypes.string
};

