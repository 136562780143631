import React from "react";
import {
  STATUS_ERROR,
  STATUS_LOADING,
  STATUS_SUCCESS,
} from "../../../common/utils/generic";
import PropTypes from "prop-types";
import Icon from "../../../common/icons/icon";

export default class NavPlaces extends React.Component {
  render() {
    let { places, selectedPlace, listPlacesStatus, selectPlace, selectArea } =
      this.props;
    let auxPlaces = [...places].sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
    return (
      <div className="nav-item dropdown m-1 mr-2 mb-2">
        <button
          className="nav-link nav-places places-select"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="true"
        >
          <div>
            <span>Local</span>
            <br />
            {selectedPlace ? selectedPlace.name : "Escoger un local"}
          </div>

          {/* <Icon svg="down" classes="svg-icon down" title="Down"/> */}

          <Icon svg="down" classes="svg-icon down" title="Down"/>
        </button>
        <div
          className="dropdown-menu dropdown-places"
          aria-labelledby="menu-places"
        >
          {auxPlaces.map((p, index) => (
            <div
              key={`place${index}`}
              className="dropdown-item"
              onClick={(event) => {
                selectPlace(p);
                selectArea(
                  p.locality ? (p.locality.area ? p.locality.area : "") : ""
                );
              }}
            >
              <span>{p.name}</span>
              <br />
              {p.address}
            </div>
          ))}
          {listPlacesStatus === STATUS_LOADING ? (
            <div className="dropdown-item">Cargando...</div>
          ) : listPlacesStatus === STATUS_SUCCESS && places.length === 0 ? (
            <div className="dropdown-item"> No se ha encontrado locales</div>
          ) : listPlacesStatus === STATUS_ERROR ? (
            <div className="dropdown-item">
              {" "}
              No existen locales para esta empresa
            </div>
          ) : (
            <span />
          )}
        </div>
      </div>
    );
  }
}

NavPlaces.propTypes = {
  places: PropTypes.array,
  selectedPlace: PropTypes.object,
  listPlacesStatus: PropTypes.string,
  selectPlace: PropTypes.func,
};
