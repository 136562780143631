import React from "react";
import "./style.scss"
import moment from "moment";

export default class IntervalsRange extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedInterval:null,
      deliveryMethod:null
    }
  }

  componentDidMount() {
    let {
      setInfoIntervalsRangeSubscription,
      deliveryMethod,
      info
    } = this.props;
    if (info && info.deliveryMethod && info.selectedInterval) {
      this.setState({...info})
    } else {
      setInfoIntervalsRangeSubscription({deliveryMethod: deliveryMethod, selectedInterval: null})
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let {
      info
    } = this.props;
    if(!this.compare(this.state.selectedInterval,info.selectedInterval)){
      this.setState({selectedInterval: info.selectedInterval})
    }
  }

  compare = (obj1, obj2) => {
    return JSON.stringify(obj1) === JSON.stringify(obj2)
  }

  selectInterval(interval){
    let {deliveryMethod, setInfoIntervalsRangeSubscription} = this.props;
    let payload = {deliveryMethod:deliveryMethod, selectedInterval: interval}
    this.setState(payload)
    setInfoIntervalsRangeSubscription({...payload, isValid: true})
  }

  render() {
    let {subscription_intervals, info} = this.props;
    let {selectedInterval} = this.state;

    return (
      <div className={`card detail`}>
        <div className={"title-bar-section"}>
          <h6 className={"col-10 col-lg-10 mx-auto"}>Rango de Horarios</h6>
        </div>
        <div className="col-10 col-lg-10 mx-auto p-0 d-flex flex-wrap justify-content-between ">
          {
            subscription_intervals.length > 0 ?
              subscription_intervals.map((interval, index) =>
                <div key={`date${index}`}
                     className={`interval range ${ (selectedInterval &&
                       selectedInterval.starts === interval.starts &&
                       selectedInterval.ends === interval.ends
                     )?'active':''}`}
                     onClick={event => {
                       this.selectInterval(interval)
                     }}
                >
                  <span>{moment(interval.starts, 'hh:mm:ss').format("hh:mm a")} - {moment(interval.ends, 'hh:mm:ss').format("hh:mm a")} </span>
                </div>
              ) :
              <div className={"col-10 col-lg-10 mx-auto p-0 no-results text-left"}>
                <p style={{fontWeight:"500", fontSize:"1rem"}}>No se encontraron horarios para este día, por favor seleccione otra fecha</p>
              </div>
          }
        </div>
        {
          !(info.selectedInterval && info.selectedInterval.uid) &&
          <span className="col-10 col-lg-10 mx-auto p-0 field-error-message mb-2" style={{display:"block"}}>
            Debe seleccionar un horario
          </span>
        }
      </div>
    )
  }
}

IntervalsRange.propTypes = {
};

