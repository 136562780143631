import React, {Fragment} from 'react'
import PropTypes from 'prop-types';
import './style.scss';
import ListRedemptions from "./components/ListRedemptions";


export default class RedemptionsContainer extends React.Component {

  componentDidMount() {
    const {
      match: {params: {uid, promotionUid}},
      getRedemptions,
      changeSecondaryMenu,
      changeStateCart
    } = this.props
    changeSecondaryMenu()
    changeStateCart()
    if (uid && uid !== '' && promotionUid && promotionUid !== ''){
      //getRedemptions(uid, {pp_uid: promotionUid})
      getRedemptions(uid)
    }
  }

  renderError(errors, fieldName) {
    return errors && errors[fieldName] &&
      <span className='field-error-message'>
        {errors[fieldName][0]}
      </span>;
  }

  render() {
    let {
      match: {params: {uid, promotionUid}},
      redemptions:{
        entities: programRedemptionsEntities,
        results: programRedemptionsResults,
        listStatus,
        listNextStatus,
        nextPage,
        count
      },
      currency,
      getMoreProgramRedemptions
    } = this.props;
    return (
      <Fragment>

        <div className={"container redemption"}>
          <div className={"row"}>
            <div className={" mx-auto col-sm-11 col-lg-11"}>
              <div className="mt-4 mb-2">
                <h4>Historial de redenciones</h4>
                <p>Estas son las redenciones asociadas a su GIFT CARD</p>
              </div>
              <ListRedemptions
                programRedemptions={programRedemptionsResults.map(cUid => programRedemptionsEntities[cUid])}
                count = {count}
                nextPageRedemptions={nextPage}
                listProgramRedemptionsStatus={listStatus}
                listNextProgramRedemptionsStatus={listNextStatus}
                getMoreProgramRedemptions = {getMoreProgramRedemptions}
                programUid = {uid}
                promotionUid={promotionUid}
                currency={currency}
              />
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
}

RedemptionsContainer.defaultProps = {
  selectedPlace: null
};

RedemptionsContainer.propTypes = {
  selectedPlace: PropTypes.object
};


