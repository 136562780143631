import React from 'react';
import {Switch} from "react-router-dom";
import {Route} from 'react-router';
import {
  changeStateSecondaryMenu, SECONDARY_MENU_BACK,
  SECONDARY_MENU_BUSINESS
} from "../../actions/menu";
import {CART_SUBSCRIPTION, changeStateCart, setTypeCart, CART_INVISIBLE} from "../../actions/cart";
import SubscriptionContainer from "../subscription";
import {getMoreProductsByPlace, getProductCurrent, getProductsByPlace} from "../../actions/productsByPlace";
import SubscriptionDetailContainer from "../subscription/subscriptionDetailContainer/SubscriptionDetailContainer";
import {getNextSubscriptions, getSubscriptions, canceledSubscription, cleanUpdate} from "../../actions/subscriptions";

const $ = require('jquery');

export default class SubscriptionRoot extends React.Component {

  componentDidUpdate(prevProps, prevState, snapshot) {
    if ( $(".modal-backdrop").length > 1 ) {
      $('.modal-backdrop:first').remove();
    }

    localStorage.removeItem("skipBuyService")
  }

  render() {
    const {
      match,
      app,
      dispatch,
      company,
      selectedPlace,
      productsPlace,
      authStatus,
      entitiesCartSubscription,
      addProductCartSubscription,
      updateProductCartSubscription,
      openPopUp,
      activePopUp,
      componentPopUp,
      typeCart,
      infoSubscriptionOptions,
      setInfoSubscriptionOptions,
      subscriptions,
      cleanCart
    } = this.props;

    return (
      <Switch>
        <Route exact path={`${match.url}`} render={(props) =>
          <SubscriptionContainer
            {...props}
            authStatus={authStatus}
            company={company}
            app={app}
            selectedPlace={selectedPlace}
            changeSecondaryMenu={() => dispatch(changeStateSecondaryMenu(SECONDARY_MENU_BUSINESS))}
            changeStateCart={(state) => dispatch(changeStateCart(state))}
            getProductsByPlace={(uidPlace, params = {})=> dispatch(getProductsByPlace(uidPlace,{...params}))}
            getMoreProductsByPlace={(url) => dispatch(getMoreProductsByPlace(url))}
            productsPlace={productsPlace}
            getProductCurrent={(uidPlace, productUid) => dispatch(getProductCurrent(uidPlace, productUid))}
            openPopUp={openPopUp}
            activePopUp={activePopUp}
            componentPopUp={componentPopUp}
            entitiesCartSubscription={entitiesCartSubscription}
            addProductCartSubscription={addProductCartSubscription}
            updateProductCartSubscription={updateProductCartSubscription}
            changeTypeCart={() => dispatch(setTypeCart(CART_SUBSCRIPTION))}
            typeCart={typeCart}
            infoSubscriptionOptions={infoSubscriptionOptions}
            setInfoSubscriptionOptions={setInfoSubscriptionOptions}
            cleanCart={cleanCart}
          />
        }
        />
        <Route exact path={`${match.url}/me`} render={(props) =>
          <SubscriptionDetailContainer
            {...props}
            authStatus={authStatus}
            app={app}
            company={company}
            selectedPlace={selectedPlace}
            changeSecondaryMenu={() => dispatch(changeStateSecondaryMenu(SECONDARY_MENU_BACK))}
            changeStateCart={() => dispatch(changeStateCart(CART_INVISIBLE))}
            changeTypeCart={() => dispatch(setTypeCart(CART_SUBSCRIPTION))}
            getSubscriptions={( params = {}) => dispatch(getSubscriptions({...params}))}
            canceledSubscription={(place_uid, subscription_uid) => dispatch(canceledSubscription(place_uid, subscription_uid))}
            getMoreSubscriptions={(url) => dispatch(getNextSubscriptions(url))}
            subscriptions={subscriptions}
            cleanUpdate={() => dispatch(cleanUpdate())}
          />
        }/>
      </Switch>
    )
  }
}

