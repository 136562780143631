import React, {Fragment} from 'react'
import PropTypes from 'prop-types';
import {STATUS_ERROR, STATUS_LOADING, STATUS_SUCCESS} from '../../common/utils/generic';
import Validator from '../../common/utils/validator';
import {BTN_RESET, BTN_RESET_LOADING} from "./constants";
import './style.scss';
import {toast} from "react-toastify";
import {Link} from "react-router-dom";
import {CART_INVISIBLE} from "../../actions/cart";
import Footer from "../footer/Footer";

export default class ResetPassword extends React.Component {


  constructor(props) {
    super(props);

    this.state = {
      sending: false,
      password: "",
      password2: "",
      uid: "",
      token: "",
      showResponse: ''

    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {

    let {resetStatus} = this.props;
    if (resetStatus === STATUS_ERROR && this.state.showResponse === true) {
      toast.error("Error al enviar información, es posible que el link haya caducado");
      this.setState({showResponse: false})
    } else {
      if (resetStatus === STATUS_SUCCESS && this.state.showResponse === true) {
        toast.success("Contraseña actualizada");
        this.setState({showResponse: false,password:'',password2:''})
        this.props.history.push(`/`);

      }
    }
  }

  componentDidMount() {

    const {match} = this.props;
    const uidUser = match.params.uidUser;
    const token = match.params.token;


    if (uidUser !== undefined && token !== undefined) {
      this.setState({uid: uidUser});
      this.setState({token: token});
    } else {
      this.props.history.push(`/`);
    }
    this.validator = new Validator([
      {
        id: "password",
        ref: this.passwordInput,
        rules: ["required", "password"]
      },
      {
        id: "password2",
        ref: this.password2Input,
        rules: ["required", "password"]
      }
    ]);

  }


  setFormValues = (propertiesToUpdate) => {
    this.setState(propertiesToUpdate);
    this.validator.validate(propertiesToUpdate);
  }

  onSubmitForm = async (event) => {
    event.preventDefault();

    const {password, password2} = this.state;
    let {resetStatus, resetPassword} = this.props;
    this.setState({showResponse: true})

    if (this.validator.validate({password, password2})) {
      const password_confirmation = password2;
      if (password !== password2) {
        toast.error("Las contraseñas deben ser iguales");
        return;
      }
      if (resetStatus !== STATUS_LOADING) {
        resetPassword(
          {password, password_confirmation},
          this.state.uid,
          this.state.token
        );

      }
    }

  }

  renderError(errors, fieldName) {
    return errors && errors[fieldName] &&
      <span className='field-error-message'>
        {errors[fieldName][0]}
      </span>;
  }

  render() {
    let {resetStatus, errors, app: {company: {logo_rectangle, logo}}} = this.props;
    let logo_app = logo_rectangle ? logo_rectangle : logo

    return (
      <Fragment>
        <div className={"rendered-content"}>

          <div className="elevation-container col-xl-4 col-lg-5 col-md-7 col-sm-">
            <div className="reset">
              <div className="box-form">
                <form className="form" onSubmit={this.onSubmitForm}>
                  <img src={logo_app ? logo_app : logo} className="logo" alt="logo"/>
                  <h2>Ingresa una nueva contraseña</h2> <br/>

                  <div className="form-group">
                    <div>
                      <div
                        className="input-group"
                        ref={(input) => {
                          if (input) {
                            this.passwordInput = input.querySelector('input');
                          }
                        }}
                      >
                        <input
                          className={"form-control"}
                          value={this.state.password}
                          onChange={e => this.setFormValues({password: e.target.value})}
                          type="password"
                          placeholder="Nueva contraseña *"
                          disabled={resetStatus === STATUS_LOADING}
                        />
                      </div>
                      {this.renderError(errors, 'password')}
                    </div>
                  </div>

                  <div className="form-group">
                    <div>
                      <div
                        className="input-group"
                        ref={(input) => {
                          if (input) {
                            this.password2Input = input.querySelector('input');
                          }
                        }}
                      >
                        <input
                          className={"form-control"}
                          value={this.state.password2}
                          onChange={e => this.setFormValues({password2: e.target.value})}
                          type="password"
                          placeholder="Repita la contraseña *"
                          disabled={resetStatus === STATUS_LOADING}
                        />
                      </div>
                      {this.renderError(errors, 'password2')}
                    </div>
                  </div>
                  {
                    (errors && errors.non_field_errors) &&
                    <div
                      style={{marginTop: 15}}
                    >
                      <span className='field-error-message'>Las contraseñas deben ser iguales</span>;
                    </div>
                  }


                  <div className="submits" style={{marginTop: 30}}>
                    <Link
                      type="button"
                      className="btn btn-login btn-secondary"
                      to={'/login'}>
                      ir a Login
                    </Link>
                    <button
                      type="submit"
                      disabled={resetStatus === STATUS_LOADING}
                      style={{textOverflow:"ellipsis", overflow: "hidden"}}
                      className="btn btn-reset btn-primary">
                      {resetStatus === STATUS_LOADING ? BTN_RESET_LOADING : BTN_RESET}
                    </button>

                  </div>

                </form>
              </div>
            </div>


          </div>
        </div>
        <Footer
          stateCart={CART_INVISIBLE}></Footer>
      </Fragment>
    );
  }
}

ResetPassword.defaultProps = {
  error: null,
  resetStatus: '',
};

ResetPassword.propTypes = {
  dispatch: PropTypes.func,
  error: PropTypes.object,
  resetStatus: PropTypes.string
};

