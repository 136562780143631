import React from 'react'
import bg from "../../../common/images/background.jpg";

export default class SubscriptionBanner extends React.Component {

  render() {
    let {
      company:{banner, cover, logo_rectangle, logo},
      selectedPlace
    } = this.props;
    let cover_app = banner ? banner : cover || bg
    let logo_app = logo ? logo  : logo_rectangle
    return (
      <div className={"container-banner"}>
        {
          (selectedPlace) ?
            <div className={"container"}>
              <div className={"banner row"} style={{backgroundImage: `url(${cover_app})`}}>
                <div className={"card col-lg-6 col-md-8"}>
                  <div className={"row align-items-center"}>
                    <img alt=""
                         src={logo_app}
                         width={"160"}
                         height={"160"}
                         id={"logo-banner"}
                         className="d-none d-sm-block d-md-block d-lg-block mr-2"/>
                    <div className={"col place-info"}>
                      <div className={"title-banner d-flex"}>
                        <span>Subscríbete</span>
                      </div>
                      <p>En la parte de abajo, elige los productos, la frecuencia del pedido, si lo quieres para delivery o recojo en tienda y el rango de horario en que te gustaría recibirlo.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            :
            <div className={"container loading"}>
              <div className={"banner row"} >
                <div className={"card col-lg-6 col-md-6"}>
                  <div className={"row"}>
                    <div className={"col level-info"}>
                      <h4> </h4>
                      <p className="mt-2 mb-2 " style={{width:"100%"}}> </p>
                      <p className="mt-2 mb-2 " style={{width:"100%"}}> </p>
                      <p className="mt-2 mb-2 " style={{width:"100%"}}> </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        }
      </div>
    );
  }
}