import React from 'react'
import './style.scss';
import {CART_PRODUCTS} from "../../../actions/cart";
import CoinSvg from "../../../common/images/Coin.svg";
import TrashSvg from "../../../common/images/trash.svg";

const $ = require('jquery');

export default class ProductCart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      option_groups: [],
      selectedPlace: null
    }
  }

  componentDidMount() {
    let {authStatus, product, selectedPlace,updateProductCart, validate = true} = this.props

    if(authStatus==="success" && validate) {
      if (product.direct_purchase && this.props.match && this.props.match.path !== "/cart") {
          updateProductCart(0, product.id, selectedPlace)

        localStorage.removeItem(`cart${selectedPlace.uid}`);
        localStorage.removeItem(`cartSubscription${selectedPlace.uid}`);
        if (localStorage.getItem(`cart${selectedPlace.uid}`) || localStorage.getItem(`cartSubscription${selectedPlace.uid}`)) {

          localStorage.removeItem(`cartSubscription${selectedPlace.uid}`);
          localStorage.removeItem(`cart${selectedPlace.uid}`);
        }
      }

    }

  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    let {product, selectedPlace,updateProductCart, cleanCart, authStatus, validate = true} = this.props
    if(validate){
      if(authStatus==="success"){
        if(product.direct_purchase && this.props.match && this.props.match.path!=="/cart"){
          if(localStorage.getItem(`cart${selectedPlace.uid}`) || localStorage.getItem(`cartSubscription${selectedPlace.uid}`)){
            localStorage.removeItem(`cart${selectedPlace.uid}`);
            localStorage.removeItem(`cartSubscription${selectedPlace.uid}`);
          }
          if(selectedPlace!==this.state.selectedPlace){
            updateProductCart(0,product.id,selectedPlace)
            this.setState({selectedPlace: selectedPlace})
          }
          cleanCart()

        }
      }else{
        if(product.direct_purchase && this.props.match && this.props.match.path!=="/cart" && localStorage.getItem("skipBuyService")!=="true"){

          if(localStorage.getItem(`cart${selectedPlace.uid}`) || localStorage.getItem(`cartSubscription${selectedPlace.uid}`)){
            updateProductCart(0,product.id,selectedPlace)
            localStorage.removeItem(`cart${selectedPlace.uid}`);
            localStorage.removeItem(`cartSubscription${selectedPlace.uid}`);
            cleanCart()
            localStorage.removeItem("skipBuyService")
          }
        }
      }
    }


  }

  openModal = (e, uid) => {
    e.preventDefault()
    const {openPopUp, setProductUid} = this.props
    openPopUp(true, "productPopUp")
    setProductUid(uid)
  }

  updateProductCart = (quantity) => {
    let {product, selectedPlace,updateProductCart } = this.props
    updateProductCart(quantity,product.id,selectedPlace)
  }

  updateProductCartBack = () => {
    let {product, entitiesCart,addProductsBack,authStatus,selectedPlace } = this.props
    if( authStatus==="success" ){
      let listProducts = []
      if(entitiesCart!==null && entitiesCart!==undefined && entitiesCart.products !== undefined){
        Object.keys(entitiesCart.products).map((p,index) => {
            if(entitiesCart.products[p].option_groups.length!==0){
              entitiesCart.products[p].option_groups.map(group => {

                delete group.name
                delete group.description
                delete group.minimum_to_select
                delete group.maximum_to_select
                delete group.is_active
                if(group.options.length!==0){
                  group.options.map(o => {
                    delete o.description
                    delete o.is_active
                    return true
                  })
                }
                return true
              })

            }

          let aux = {
            id: entitiesCart.products[p].id,
            uid: entitiesCart.products[p].uid,
            quantity:entitiesCart.products[p].quantity, option_groups: entitiesCart.products[p].option_groups }
          if(product.id === entitiesCart.products[p].id){
            aux = {
              id: entitiesCart.products[p].id,
              uid: entitiesCart.products[p].uid,
              quantity:product.quantity, option_groups: entitiesCart.products[p].option_groups}
          }
          listProducts.push(aux)
          return true;
        })
      }
      addProductsBack({place: selectedPlace.uid, products: listProducts})
    }
  }

  increase = (e) => {
    e.preventDefault()
    let {product, typeCart} = this.props
    if(product.quantity<product.stock){
      this.updateProductCart(product.quantity+1)
      if(typeCart===CART_PRODUCTS){
        this.updateProductCartBack()
      }
    }
  };


  decrease = (e) => {
    e.preventDefault()
    let {product, typeCart } = this.props
    if(product.quantity-1 === 0){
      $('#items').addClass('load');
      setTimeout(() => {
        $('#items').removeClass('load');
      }, 2000);
    }
    this.updateProductCart(product.quantity-1)
    if(typeCart===CART_PRODUCTS){
      this.updateProductCartBack()
    }
  };

  getPrice = (product) => {
    let aux = 0

    if(product.option_groups){
      product.option_groups.map(group => {
        group.options.map(o=>{
          aux += o.price
          return true
        })
        return true
      })
    }
    if(aux){
      return (parseFloat(product.unit_price)+parseFloat(aux))*parseFloat(product.quantity)
    }
    return (parseFloat(product.unit_price))*parseFloat(product.quantity)


  }

  getToppingsNumber = (option_groups) => {
    let aux = 0
    if(option_groups){
      option_groups.map(group => {
        group.options.map(o=>{
          if(o.uid){
            aux += 1
          }
          return true
        })
        return true
      })
    }
    return aux
  }

  getToppings = (option_groups) => {
    let toppings = []
    if(option_groups){
      option_groups.map(group=> {
        group.options.map((o)=>{
          toppings.push(o)
          return true
        })
        return true
      })

    }
    return toppings.map((o, index) =>
      ((o.name!==undefined && index < 8) && <p key={index} className={"topping-item"}><span>{o.name}</span></p>)
    )

  }

  render() {
    let {product, currency,cartPopup=false} = this.props
    let ItemExchange = localStorage.getItem("ItemExchange")
    return (
      <div className={"col-lg-12 product-cart"}>
        {
          product &&
          <div>
            <div className={"media"}>
              <div className={"media-body pt-2"}>
                <div className="detail">
                  <h5 className={"mb-0 pb-1 d-flex mt-1"} style={{fontWeight: "500", color: "black"}}>
                    <p className={"product-name"}>{product.name}</p>
                    {
                      this.getToppings(product.option_groups)
                    }
                    {
                      (this.getToppingsNumber(product.option_groups)>8) && <button onClick={e => this.openModal(e, product.id)} className={"topping-item"}><span>...</span></button>
                    }
                  </h5>
                </div>

                <div className={!product.direct_purchase ? "price-options" : "price-options direct-purchase" }>

                  { !product.direct_purchase &&
                    <div className="controls d-flex mb-1">
                      <button type="button" className="btn btn-default bg-white"
                              onClick={e => this.decrease(e)}>-</button>
                      <span>{product.quantity}</span>
                      <button type="button"
                              disabled={product.quantity>=product.stock}
                              className="btn btn-default"
                              onClick={e => this.increase(e)}>+</button>
                    </div>
                  }

                  {ItemExchange!=="true" && cartPopup!==true && <button className={"btn-edit-topping"} onClick={e => this.openModal(e, product.id)}>Editar</button>}
                  {
                    ItemExchange==="true" ? (
                      <p className={"coin-img"}>
                        <img src={CoinSvg} alt="coin" />
                   <span> {(Math.round(this.getPrice(product) * 100) / 100)}</span> </p>
                    ) :
                    <p className={"text-center"}>{currency ? currency.symbol : "S/."}
                    {(Math.round(this.getPrice(product) * 100) / 100).toFixed(1)}</p>
                  }
                  { product.direct_purchase &&
                    <div className="controls d-flex mb-1" style={{boxShadow: "none"}}>
                      <button type="button" className="btn-default bg-white" style={{marginTop: "-5px", border: "none"}}
                              onClick={e => this.decrease(e)}>
                        <img className={"trash"} src={TrashSvg} alt="TrashSvg" width={25} height={25}/>
                      </button>
                    </div>
                  }
                </div>


              </div>
            </div>
          </div>


        }
      </div>
    );
  }
}
