import React, {Fragment} from 'react'
import PropTypes from 'prop-types';
import {STATUS_LOADING, STATUS_SUCCESS} from '../../common/utils/generic';
import './style.scss';
import "react-datepicker/dist/react-datepicker.css"
import {toast} from "react-toastify";
import RaffleBanner from "./raffleBanner/RaffleBanner";
import notItems from "../../common/images/no_items.png";
import ReactImageAppear from "react-image-appear";
import placeholder from "../../common/images/placeholder.jpg";
import RafflePopUp from "./rafflePopUp/RafflePopUp";

export default class ListRaffles extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      load: false,
      programUid: null,
      showDismiss: false,
      raffleUid: null
    }
  }

  componentDidMount() {
    let {changeSecondaryMenu, changeStateCart} = this.props
    changeSecondaryMenu()
    changeStateCart()
    this.updateComponent();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.updateComponent();
  }

  updateComponent = () => {
    let {getRaffles, programs, raffles, changeSecondaryMenu, changeStateCart, stateCart} = this.props
    if(programs && programs.length!==0 && !this.state.load){
      localStorage.setItem("programUid",programs[0].uid)
      getRaffles(localStorage.getItem("programUid"))
      this.setState({load: true, programUid: programs[0].uid})
    }

    if(this.state.showDismiss){
      if(raffles.dismissStatus===STATUS_SUCCESS){
        toast.success("Se eliminó correctamente el sorteo")
        getRaffles(localStorage.getItem("programUid"))
        this.setState({showDismiss: false})
      }
    }

    if(stateCart!=="CART_INVISIBLE"){
      changeSecondaryMenu()
      changeStateCart()
    }
  }


  openModal = (e, uid) => {
    e.preventDefault()
    const {openPopUp} = this.props
    openPopUp(true, "pollPopUp")
    this.setState({raffleUid: uid})
  }

  renderRaffles = () => {
    let {raffles} = this.props
    return raffles.map((raffle, index) => (
      <div key={`raffle${index}`} style={{cursor: "pointer"}} className={"program-promotion-card col-xs-12 col-md-5 col-sm-6 col-xl-5"}
           onClick={e => this.openModal(e, raffle.uid)} >
        <figure>
          <ReactImageAppear
            className="cover"
            loader={placeholder}
            loaderClass="cover"
            placeholderClass={"placeholder"}
            src={raffle.image}
            animationDuration="1s"
          />
              <div className="info ml-2">
                <div className="row detail">
                  <div className="col">
                    <p className="title" style={{textShadow: "0px 1px 5px rgba(0, 0, 0, 0.901251)"}}>{raffle.name} </p>
                  </div>
                </div>
              </div>
        </figure>
      </div>
      ));
  }

  renderPopUp = () => {
    let {openPopUp, activePopUp, rafflesStatus,registerStatus, dismissStatus, componentPopUp, getRaffles, getRaffle, registerInRaffle, user, dismissToRaffle, app, programs, stateCart, raffles} = this.props
    return (
        <RafflePopUp
          openPopUp={openPopUp}
          activePopUp={activePopUp}
          componentPopUp={componentPopUp}
          raffles={raffles}
          stateCart={stateCart}
          app={app}
          programs={programs}
          dismissToRaffle={dismissToRaffle}
          getRaffle={getRaffle}
          user={user}
          registerInRaffle={registerInRaffle}
          dismissStatus={dismissStatus}
          raffleUid={ this.state.raffleUid}
          rafflesStatus={rafflesStatus}
          registerStatus={registerStatus}
          getRaffles={getRaffles}
        />
      );
  }

  render() {
    let {app, company, raffles, componentPopUp, rafflesStatus} = this.props
    console.log(raffles)
    return (
      <Fragment>
        <RaffleBanner
          app = {app}
          company = {company}
        />
        <div className="container">
          <div className={"col-sm-12 d-flex flex-column head-raffles"}>
            <h5>Sorteos</h5>
            {
              rafflesStatus===STATUS_SUCCESS ?
                (raffles && raffles.length !==0) ?
                  <div className={"d-flex flex-wrap"}>
                    {this.renderRaffles()}
                  </div>
                  :
                  <div className={"d-flex justify-content-center mt-4 flex-column"}>
                    <img src={notItems} alt="notItems" className={"not-items"}/>
                    <span className={"text-center mt-3"} style={{color: "#939393", fontWeight: 700}}>Por el momento no hay <br className={"d-none d-sn-block d-md-block"}/>ningún sorteo</span>
                  </div>
                :
                rafflesStatus===STATUS_LOADING ?
                <div className={"d-flex flex-wrap "}>
                  <div className={"program-promotion-card load col-xs-12 col-md-5 col-sm-6 col-xl-5"}>
                    <figure>
                      <div className="info ml-2">
                        <div className="row detail">
                          <div className="col">
                            <p className="title" style={{textShadow: "0px 1px 5px rgba(0, 0, 0, 0.901251)"}}></p>
                          </div>
                        </div>
                      </div>
                    </figure>
                  </div>
                </div>
                  :
                  <div className={"d-flex justify-content-center mt-4 flex-column"}>
                    <img src={notItems} alt="notItems" className={"not-items"}/>
                    <span className={"text-center mt-3"} style={{color: "#939393", fontWeight: 700}}>Por el momento no hay <br className={"d-none d-sn-block d-md-block"}/>ningún sorteo</span>
                  </div>
            }
          </div>

        </div>
        {
          (componentPopUp=== "pollPopUp") &&
          this.renderPopUp()
        }
      </Fragment>
    );
  }
}

ListRaffles.defaultProps = {
  error: null,
  updateStatus: '',
};

ListRaffles.propTypes = {
  dispatch: PropTypes.func,
  error: PropTypes.object,
  updateStatus: PropTypes.string
};

