import React, {Fragment} from 'react'
import PropTypes from 'prop-types';
import PlaceBanner from "./placeBanner";
import ProductsPlaceFilters from "./ProductsPlaceFilters/ProductsPlaceFilters";
import ProductsPlaceList from "./productsPlace/ProductsPlaceList";
import NoGPS from "../../common/images/NoGPS.svg";
import "./style.scss";
import {STATUS_INCLUDE, STATUS_LOADING, STATUS_SUCCESS} from "../../common/utils/generic";
import NavPlaces from "../menu/components/NavPlaces";
import Loader from "../loading/Loader"
import ProgramRafflesPopUp from "../program/programRafflesPopUp";
import {CART_INVISIBLE, CART_VISIBLE} from "../../actions/cart";
import CompanyAdsPopUp from "./companyAdsPopUp";
import ProgramBanner from "../program/programDetail";
import ProgramAdsPopUp from "../program/programAdsPopUp";
import ProgramPromotionsList from "../program/programPromotions/ProgramPromotionsList";
import {Link} from "react-router-dom";
import CoinSvg from "../../common/images/Coin.svg";
const $ = require('jquery');

export default class ProductContainer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      placeUid: "",
      programUid: "",
      isEcommerceEnabled: null,
      //programRoot

      uidProgram: "",
      selectedAreaUid: "",
      program: null,
    }
  }

  componentDidMount() {
    let {changeSecondaryMenu, changeTypeCart} = this.props
    changeSecondaryMenu()
    //changeStateCart()
    changeTypeCart()

    this.updateComponent()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if ( $(".modal-backdrop").length > 1 ) {
      $('.modal-backdrop:first').remove();
    }
    this.updateComponent()
  }

  existProgram = (uid) => {
    const { app } = this.props;
    if (app && app.allowed_programs) {
      for (let i = 0; i < app.allowed_programs.length; i++) {
        if (app.allowed_programs[i].uid === uid) return true;
      }
    }
    return false;
  };

  updateComponent() {
    const {
      getSectionsToProducts,
      getProductsByPlace,
      selectedPlace,
      programs,
      getRaffles,
      company,
      changeStateCart,

      //programRoot
      programUid: uid,
      getSections,
      getProgram,
      selectedAreaUid,
      getPromotions,
      getAds,
      programs: {
        entity: program,
        retrieveStatus: retrieveStatusProgram,
        addStatus,
      },
      getProductsRedeemablesByPlace,
    } = this.props;
    if (selectedPlace !== null) {
      if (this.state.placeUid !== selectedPlace.uid) {
        this.setState({placeUid: selectedPlace.uid})
        if (selectedPlace.uid !== null) {
          getProductsByPlace(selectedPlace.uid);
          getProductsRedeemablesByPlace(selectedPlace.uid);
          getSectionsToProducts(selectedPlace.uid);
        }
      }
    }
    if(programs && programs.results.length!==0){
      if(this.state.programUid!==programs.results[0]){
        this.setState({programUid: programs.results[0]})
        getRaffles(programs.results[0])
      }
    }
    if(company.ecommerce_enabled!==this.state.isEcommerceEnabled){
      this.setState({isEcommerceEnabled: company.ecommerce_enabled})
      if(company.ecommerce_enabled){
        changeStateCart(CART_VISIBLE)
      }else{
        changeStateCart(CART_INVISIBLE)
      }
    }


    //programRoot
    if (uid && uid !== this.state.uidProgram) {
      if (this.existProgram(uid)) {
        this.setState({ uidProgram: uid });
        getProgram(uid);
        getAds(uid, { type: "POP_UP" });
        getRaffles(uid);
      }
    }
    if (retrieveStatusProgram === STATUS_SUCCESS && program != null) {
      if (
        this.state.program == null ||
        (this.state.program && program.uid !== this.state.program.uid) ||
        this.state.selectedAreaUid !== selectedAreaUid
      ) {
        this.setState({ selectedAreaUid });
        this.setState({ program: program });
        getPromotions(uid, { area: selectedAreaUid });
        getSections(uid);
      }
    }
    if (
      addStatus === STATUS_SUCCESS &&
      retrieveStatusProgram === STATUS_INCLUDE &&
      program != null
    ) {
      getProgram(uid);
    }
    if (selectedPlace !== null && selectedPlace) {
      if (this.state.placeUid !== selectedPlace.uid) {
        this.setState({ placeUid: selectedPlace.uid });
        if (selectedPlace.uid !== null) {
          getProductsByPlace(selectedPlace.uid);
          getProductsRedeemablesByPlace(selectedPlace.uid);
          // getSectionsToProducts(selectedPlace.uid);
        }
      }
    }
  }

  renderStatus = (rule) => {
    switch (rule) {
      case "FOR_COIN_WHEN_BUYING":
        return "Por cada moneda que gaste en una compra";
      case "FOR_SOLVING_SURVEY":
        return "Por resolver una encuesta";
      case "FOR_SEEING_NOTIFICATION":
        return "Por ver una notificación";
      case "FOR_DIRECT_CONSUMPTION":
        return "Por un consumo directo";
      default:
        return '--'
    }
  }

  renderMission = (rules,type="coin") =>{
    return (
      <div
        key={`rule-${rules.uid}`}
        className="rule-gamification ml-4"
      >
        <h5>{this.renderStatus(rules.rule)}</h5>
        {/* <h5>{rules.rule_name}</h5> */}
        <div className="coin-container-menu d-flex mission-coin-pint">
          <h4>
            <span>
              {type==="coin" && <img src={CoinSvg} alt="coin" /> }
            </span>
            {rules.reward}
            {type==="point" && " pts" }
          </h4>
        </div>
      </div>
    );
  }

  render() {
    let {
      company,
      authStatus,
      selectedPlace,
      listPlacesStatus,
      productsPlace: {
        entities: productsByPlaceEntities,
        results: productsByPlaceResults,
        listProductsByPlaceStatus,
        listNextStatus,
        nextPage,
        count,
        productsByPlaceCurrent,
        productsByPlaceCurrentStatus,

        entitiesRedeemables,
        resultsRedeemables,
        nextPageRedeemables,
        listProductsRedeemablesByPlaceStatus,
        countRedeemables
      },
      sectionsProducts: {
        entities: sectionsEntities,
        results: sectionsResults,
        listStatus: sectionsStatus,
      },
      getProductsByPlace,
      getMoreProductsByPlace,
      getProductCurrent,
      openPopUp,
      activePopUp,
      componentPopUp,
      addProductCart,
      addProductsBack,
      entitiesCart,
      updateProductCart,
      selectPlace,
      selectArea,
      typeCart,
      places,
      raffles:{
        entities: rafflesEntities,
        results: rafflesResults,
        listStatus: rafflesStatus,
        register: rafflesRegister,
        registerStatus: rafflesRegisterStatus,
        dismiss: rafflesDismiss,
        dismissStatus: rafflesDismissStatus,
      },
      dismissToRaffle,
      app,
      history,
      cleanCart,
      adsCompany,
      multisite,

      //programRoot
      programUid: uid,
      programs: {
        entity: program,
        addStatus,
        retrieveStatus: retrieveStatusProgram,
      },
      promotions: {
        entities: programPromotionsEntities,
        results: programPromotionsResults,
        listStatus,
        listNextStatus: listNextStatusPromotions
      },
      addProgram,
      getMoreProgramPromotions,
      ads: {
        entities: adsEntities,
        results: adsResults,
        listStatus: adsStatus,
      },
      registerInRaffle,
      productsPlace,
    } = this.props;

    let url = "";
    if (multisite && company) url = `/c/${company.uid}`;

    return (
      <Fragment>
        {
          (selectedPlace !== null) ?
            <div>
              {
                authStatus!==STATUS_SUCCESS ?
                <PlaceBanner
                  company={company}
                  selectedPlace={selectedPlace}
                  retrieveStatus={listPlacesStatus}
                /> :
                  <ProgramBanner
                    app={app}
                    addProgramUser={() => addProgram(uid)}
                    company={company}
                    addStatus={addStatus}
                    retrieveStatus={retrieveStatusProgram}
                    program={program}
                  />
              }


              {adsStatus === STATUS_SUCCESS && adsResults.length !== 0 && (
                <ProgramAdsPopUp
                  app={app}
                  ads={adsResults.map((cUid) => adsEntities[cUid])}
                  adsStatus={adsStatus}
                />
              )}

              {
                adsCompany && adsCompany.length!==0 &&
                <CompanyAdsPopUp
                  adsCompany = {adsCompany}
                />
              }

              {
                rafflesStatus===STATUS_SUCCESS && rafflesResults.length!==0 &&
                <ProgramRafflesPopUp
                  app = {app}
                  raffles = {rafflesResults.map(cUid => rafflesEntities[cUid])}
                  rafflesStatus={rafflesStatus}
                  history={history}
                  registerInRaffle={(raffleUid) =>
                    registerInRaffle(this.state.uidProgram, raffleUid)
                  }
                  dismissToRaffle={(raffleUid) => dismissToRaffle(this.state.programUid, raffleUid)}
                  rafflesRegister={rafflesRegister}
                  rafflesRegisterStatus={rafflesRegisterStatus}
                  rafflesDismiss={rafflesDismiss}
                  rafflesDismissStatus={rafflesDismissStatus}
                />
              }
              {
                false &&
                <ProductsPlaceFilters
                  sections={sectionsResults.map(cUid => sectionsEntities[cUid])}
                  listSectionsStatus={sectionsStatus}
                  selectedPlace={selectedPlace}
                  getProductsByPlace={(params) => {
                    if (selectedPlace.uid) getProductsByPlace(selectedPlace.uid, params)
                  }}
                />
              }
              <br/>
              <br/>

              <ProductsPlaceList
                multisite={multisite}
                sections={sectionsResults.map(
                  (cUid) => sectionsEntities[cUid]
                )}
                productsPlace={productsByPlaceResults.map(
                  (cUid) => productsByPlaceEntities[cUid]
                )}
                count={count}
                company={company}
                nextPageProducts={nextPage}
                listProductsByPlaceStatus={listProductsByPlaceStatus}
                listNextProductsByPlaceStatus={listNextStatus}
                getMoreProductsByPlace={getMoreProductsByPlace}
                placeUid={selectedPlace.uid}
                selectedPlace={selectedPlace}
                productsByPlaceCurrent={productsByPlaceCurrent}
                productsByPlaceCurrentStatus={productsByPlaceCurrentStatus}
                openPopUp={openPopUp}
                activePopUp={activePopUp}
                componentPopUp={componentPopUp}
                getProductCurrent={getProductCurrent}
                addProductCart={addProductCart}
                addProductsBack={addProductsBack}
                entitiesCart={entitiesCart}
                authStatus={authStatus}
                updateProductCart={updateProductCart}
                currency={company.currency}
                typeCart={typeCart}
                history={history}
                cleanCart={cleanCart}
                countProduct={8}

                newStyle={false}
              />


              <ProgramPromotionsList
                programPromotions={programPromotionsResults.map(
                  (cUid) => programPromotionsEntities[cUid]
                )}
                count={count}
                multisite={multisite}
                company={company}
                authStatus={authStatus}
                nextPagePromotions={nextPage}
                listProgramPromotionsStatus={listStatus}
                listNextProgramPromotionsStatus={listNextStatusPromotions}
                getMoreProgramPromotions={getMoreProgramPromotions}
                programUid={uid}
                countDiscount={4}
                moreDiscount={true}
                app={app}
                addProgramUser={() => addProgram(uid)}
                addStatus={addStatus}
                retrieveStatus={retrieveStatusProgram}
                program={program}
                productsPlace={productsPlace}
              />
              {company &&
                selectedPlace &&
                company.gamification_system &&
                productsPlace.entities !== null && (
                  <div className={
                    "container program-promotions program-promotions__discount"
                  }>
                    <div style={{position: "relative"}}>
                      <div className={resultsRedeemables.length!==0 ? "exchange-container" : "d-none"} style={authStatus===null ? {opacity: "0.3"} : {}}>
                        <div className={"DiscountBoxList"} style={resultsRedeemables.length===0 ? {marginTop: 150} : {}}>
                          <h3 className={"category-title mt-0 ml-3"}>CANJES</h3>
                          <h4 className={authStatus===null ? "d-none": "category-title"}>
                            {productsPlace.results.length > 8 && (
                              <Link to={`${url}/program/${uid}/more-exchange`}>
                                Ver más
                              </Link>
                            )}
                          </h4>
                        </div>
                        <span className={resultsRedeemables.length !==0 ? "category-description ml-3" : "d-none"}>Gana más monedas con tus consumos para canjear productos</span>
                        <ProductsPlaceList
                          multisite={multisite}
                          sections={sectionsResults.map(
                            (cUid) => sectionsEntities[cUid]
                          )}

                          productsPlace={resultsRedeemables.map(
                            (cUid) => entitiesRedeemables[cUid]
                          )}
                          count={countRedeemables}
                          company={company}
                          nextPageProducts={nextPageRedeemables}
                          listProductsByPlaceStatus={listProductsRedeemablesByPlaceStatus}
                          listNextProductsByPlaceStatus={listNextStatus}
                          getMoreProductsByPlace={getMoreProductsByPlace}
                          placeUid={selectedPlace.uid}
                          selectedPlace={selectedPlace}
                          productsByPlaceCurrent={productsByPlaceCurrent}
                          productsByPlaceCurrentStatus={productsByPlaceCurrentStatus}
                          openPopUp={openPopUp}
                          activePopUp={activePopUp}
                          componentPopUp={componentPopUp}
                          getProductCurrent={getProductCurrent}
                          addProductCart={addProductCart}
                          addProductsBack={addProductsBack}
                          entitiesCart={entitiesCart}
                          authStatus={authStatus}
                          updateProductCart={updateProductCart}
                          currency={company.currency}
                          typeCart={typeCart}
                          history={history}
                          cleanCart={cleanCart}
                          newStyle={true}
                          countProduct={8}
                        />
                      </div>
                      {
                        authStatus===null &&
                        <div className={"promotions-backdrop pt-4"}>
                          <p>PARA VER UN MUNDO DE BENEFICIOS</p>
                          <button className={"btn btn-login btn-primary "}>REGÍSTRATE</button>
                        </div>
                      }
                    </div>
                    <div className="exchange-container">
                      <div style={{position: "relative"}}>

                        <div className="DiscountBoxList" style={authStatus===null ? {opacity: "0.3", marginTop: 180} : {}}>
                          {
                            company.gamification_rules.length > 0 &&
                            <h3 className={"category-title mt-0 ml-3"}>MISIONES DE MONEDAS</h3>
                          }
                          {/* <h4 className={"category-title mt-4"}>
                      { productsPlace.results.length > 3 && <Link to={`${url}/program/${programUid}/more-exchange`}>Ver más</Link>}
                    </h4> */}
                        </div>
                        <div style={authStatus===null ? {opacity: "0.3"} : {}}>
                          {company.gamification_rules.map((rules) => {
                            if (rules.type_reward ==="COINS") {
                              return (
                                this.renderMission(rules)
                              );
                            }
                            return true
                          })}
                        </div>

                        {
                          authStatus===null &&
                          <div className={"promotions-backdrop"}>
                            <p>PARA VER UN MUNDO DE BENEFICIOS</p>
                            <button className={"btn btn-login btn-primary "}>REGÍSTRATE</button>
                          </div>
                        }
                      </div>
                      <br />
                      <br />
                      <div style={{position: "relative"}}>
                        <div className="DiscountBoxList" style={authStatus===null ? {opacity: "0.3", margin: "130px 0"} : {}}>
                          {
                            company.gamification_rules.length > 0 &&
                            <h3 className={"category-title mt-4 ml-3"}>MISIONES DE PUNTOS</h3>
                          }
                        </div>
                        <div style={authStatus===null ? {opacity: "0.3"} : {}}>
                          {company.gamification_rules.map((rules) => {
                            if (rules.type_reward ==="POINTS") {
                              return (
                                this.renderMission(rules,"point")
                              );
                            }
                            return true
                          })}
                        </div>

                        {
                          authStatus===null &&
                          <div className={"promotions-backdrop"}>
                            <p>PARA VER UN MUNDO DE BENEFICIOS</p>
                            <button className={"btn btn-login btn-primary "}>REGÍSTRATE</button>
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                )}
            </div> : (listPlacesStatus === STATUS_LOADING) ?
            <div className={"col-10 col-md-10 col-lg-8 loading-center"}>
              <Loader type={"list"}/>
            </div> :
            <div className={"no-place col-10 col-md-10 col-lg-8"}>
              <img src={NoGPS} alt="NoPlace"/>
              <p>Para continuar , seleccione el local en donde quisiera realizar su compra.</p>
              <NavPlaces
                selectedPlace={selectedPlace}
                selectPlace={selectPlace}
                selectArea={selectArea}
                places={places}
                listPlacesStatus={listPlacesStatus}
              />
            </div>
        }
      </Fragment>
    );
  }
}

ProductContainer.defaultProps = {
  productsPlace: null,
  company: null,
  selectedPlace: null,
  sectionsProducts: null,
  listPlacesStatus: "",
};

ProductContainer.propTypes = {
  company: PropTypes.object,
  selectedPlace: PropTypes.object,
  productsPlace: PropTypes.object,
  sectionsProducts: PropTypes.object,
  listPlacesStatus: PropTypes.string,
  getProductsByPlace: PropTypes.func,
  getMoreProductsByPlace: PropTypes.func,
};


