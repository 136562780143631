import React, {Fragment} from "react";
import PropTypes from "prop-types";
import moment from "moment";
import 'moment/locale/es';

export default class Subscription extends React.Component {

  getDays = (frecuency) => {
    let frequencyDays = frecuency
    let days = ""
    if(frecuency){
      frequencyDays.map(d=>{
        if(d===1){if(days.length!==0){days = days.concat("-")} days = days.concat("L")}
        if(d===2){if(days.length!==0){days = days.concat("-")} days = days.concat("Mar")}
        if(d===3){if(days.length!==0){days = days.concat("-")} days = days.concat("Mier")}
        if(d===4){if(days.length!==0){days = days.concat("-")} days = days.concat("J")}
        if(d===5){if(days.length!==0){days = days.concat("-")} days = days.concat("V")}
        if(d===6){if(days.length!==0){days = days.concat("-")} days = days.concat("S")}
        if(d===7){if(days.length!==0){days = days.concat("-")} days = days.concat("D")}
        return true;
      })
    }
    return days
  }

  render() {
    moment.locale('es');
    let {subscription, loading, canceledSubscription} = this.props;
    let style_class =  "subscription-card col-12 col-sm-12 col-md-12 mt-4 mb-4" ;
    if(!loading){
      let body = (
        <div>
          {
            subscription &&
            <Fragment>
              <div className="d-flex justify-content-between flex-wrap mt-2 mb-2">
                <h6>Suscripción {subscription.name}</h6>
                <h6>{moment(subscription.created_at).format('DD MMMM, YYYY HH:mm')}</h6>
              </div>
              <div className="d-flex justify-content-between flex-wrap text-xs-left text-sm-right">
                <ul>
                  {
                    subscription.subscription_products.map((p, index) => {
                      return <li key={`promo${index}`} className={"text-left"}>({p.quantity}) {p.product.title}</li>
                    })
                  }
                </ul>
                <div className="detail">
                  <p>Frecuencia {this.getDays(subscription.frequency)}</p>
                  <p>Rango horario: {moment(
                    subscription.delivery_scheduled_starts,
                    "hh:mm:ss"
                  ).format("hh:mm a")} a {moment(
                    subscription.delivery_scheduled_ends,
                    "hh:mm:ss"
                  ).format("hh:mm a")}</p>
                </div>
              </div>
              <div className="price">

                <span>Costo x Semana:</span> S/. {parseFloat(subscription.total) + parseFloat(subscription.delivery_price)}
              </div>
              <div className={subscription.status==="canceled_by_user" ? "option cancel" : "option" }>
                {
                  subscription.status==="canceled_by_user" ?
                    <Fragment>
                      <button className={"btn btn-primary col-md-4 canceled"}
                              disabled={true}>
                        Suscripción cancelada
                      </button>
                      <p>{moment(subscription.canceled_at).format('DD MMMM, YYYY HH:mm')}</p>
                    </Fragment>
                    :
                    <button className={"btn btn-primary col-md-4"}
                            disabled={subscription.status==="canceled_by_user"}
                            onClick={()=>canceledSubscription(subscription.uid)}>
                      Cancelar suscripción
                    </button>
                }
              </div>
            </Fragment>
          }
        </div>
      );

      return (
        <div className={style_class}>
          {body}
        </div>
      );
    }else{
      return (
        <div className={`${style_class} load`}>
          <div>
            <div className="d-flex justify-content-between flex-wrap mt-2 mb-2">
              <h6> </h6>
              <h6> </h6>
            </div>
            <div className="d-flex justify-content-between flex-wrap text-xs-left text-sm-right">
              <ul>
                <li> </li>
              </ul>
              <div className="detail">
                <p> </p>
                <p> </p>
              </div>
            </div>
            <div className="price">
              <span> </span>
            </div>
            <div className={"option"}>
              <button className={"btn btn-primary col-md-4"}>Cancelar suscripción</button>
            </div>
          </div>
        </div>
      )
    }
  }


};

Subscription.defaultProps = {
  loading: false,
  subscription: null,
};

Subscription.propTypes = {
  subscription: PropTypes.object,
  loading: PropTypes.bool,
};
