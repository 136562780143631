import React, { Fragment } from "react";
import PropTypes from "prop-types";
import bg from "../../../common/images/background.jpg";
import "./style.scss";
import {Link} from "react-router-dom";

export default class Banner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      info_modal: false,
    };
  }

  render() {
    let {
      history,
      app,
      company,
      selectedPlace,
      company: { banner, cover },
    } = this.props;
    let cover_app = banner ? banner : cover || bg;

    let show_cart = false
    let show_store = true
    let show_program = true
    let show_events = app && app.company && app.name.toLowerCase().includes("rock") && app.name.toLowerCase().includes("pez")
    company.hidden_for_members.map(s=>{
      if(s.name==="cart"){
        show_cart = false
      }
      if(s.name==="store"){
        show_store = false
      }
      if(s.name==="program"){
        show_program = false
      }
      return true
    })

    return (
      <Fragment>
        <div className={"header-banner"}>
          <div className={"container containerPrograms"}>

            <div
              className={"banner bannerPrograms row"}
              style={{ backgroundImage: `url(${cover_app})` }}
            >
              <div
                className={" col-lg-6 col-md-8"}
                style={{ height: "200px" }}
              ></div>
            </div>

          </div>


        </div>
        <div
          className={
            "container program-promotions program-promotions__discount"
          }
        >
          <div className="DiscountBoxList d-flex flex-column">
          <div className={"d-flex flex-column header-sections-menu"}>
            <h1 className={"mt-2"}>{company && company.name}</h1>
            <p>{selectedPlace && selectedPlace.address}</p>
            <div className={"list-cards-sections d-none"}>
              <Link className={show_events ? history.location.pathname.toLowerCase().includes("events") ? "card-section selected" : "card-section" : "d-none"} to={"/events"}>
                Eventos
              </Link>
              <Link className={show_store ? history.location.pathname.toLowerCase().includes("product") ? "card-section selected" : "card-section" : "d-none"} to={"/products/more"}>
                Tienda
              </Link>
              <Link className={show_cart ? history.location.pathname.toLowerCase().includes("carta") ? "card-section selected" : "card-section" : "d-none"} to={"/carta"}>
                Carta
              </Link>
              {app && app.allowed_programs.length !== 0 && show_program && (
                <Link className={history.location.pathname.toLowerCase().includes("program") ? "card-section selected" : "card-section"} to={`/program/${app.allowed_programs[0]?.uid}`}>
                  Loyalty
                </Link>
              )}
            </div>
          </div>
        </div>
        </div>
      </Fragment>
    );
  }
}

Banner.defaultProps = {
  program: null,
  app: null,
  retrieveStatus: "",
};

Banner.propTypes = {
  program: PropTypes.object,
  app: PropTypes.object,
  getProgram: PropTypes.func,
  retrieveStatus: PropTypes.string,
};
