import {normalize, schema} from 'normalizr';

import { STATUS_ERROR, STATUS_LOADING, STATUS_SUCCESS} from "../common/utils/generic";
import {
  APPEND_REDEMPTIONS,
  GET_REDEMPTIONS,
  REDEEM_BY_USER,
  CLEAR_STATUS_REDEEM_BY_USER
} from "../actions/redemptions";


const redemptionSchema = new schema.Entity('redemptions', {}, {idAttribute: 'uid'});
const redemptionListSchema = new schema.Array(redemptionSchema);


const initialState = {
  entities: null,
  results: [],
  listStatus: null,
  listNextStatus: null,
  nextPage: null,
  count: 0,

  isRedeem: false,
  isLoadingRedeem: null,
  errorRedeem: false,
  statusRedeem: null
};

export default function reducer(state = initialState, action) {
  const {type, payload} = action;
  let data;
  switch (type) {

    case `${REDEEM_BY_USER}_PENDING`:
      return {
        ...state,
        isLoadingRedeem: null,
        errorRedeem: false,
        statusRedeem: STATUS_LOADING
      };
    case `${REDEEM_BY_USER}_REJECTED`:
      return { ...state, isLoadingRedeem: false, errorRedeem: true, error: action.payload,
        statusRedeem: STATUS_ERROR };
    case `${REDEEM_BY_USER}_FULFILLED`:
      if(payload.status===400 || payload.status===401 || payload.status===500){
        return {
          ...state,
          isRedeem: false,
          errorRedeem: false,
          successRedeem: 400,
          statusRedeem: STATUS_ERROR
        };
      }else{
        return {
          ...state,
          isRedeem: false,
          errorRedeem: false,
          successRedeem: 200,
          statusRedeem: STATUS_SUCCESS
        };
      }



    case `${GET_REDEMPTIONS}_PENDING`:
      return {
        ...state,
        listStatus: STATUS_LOADING,
        count: 0,
        entities: null,
        nextPage: null,
        results: []
      };
    case `${GET_REDEMPTIONS}_FULFILLED`:
      data = payload.status === 200 ? normalize(payload.data.results, redemptionListSchema) : {};
      return {
        ...state,
        entities: data.entities ? data.entities.redemptions : [],
        results: data.result ? data.result : [],
        nextPage: payload.data ? payload.data.next : null,
        listStatus: payload.status === 200 ? STATUS_SUCCESS : STATUS_ERROR,
        count: payload.data ? payload.data.count : 0,
      };

    case `${APPEND_REDEMPTIONS}_PENDING`:
      return {
        ...state,
        listNextStatus: STATUS_LOADING
      };
    case `${APPEND_REDEMPTIONS}_FULFILLED`:
      data = payload.status === 200 ? normalize(payload.data.results, redemptionListSchema) : {};
      return {
        ...state,
        entities: data.entities ? {...state.entities, ...data.entities.redemptions} :{...state.entities} ,
        results: data.result ? [...state.results, ...data.result] : [...state.results] ,
        listNextStatus: payload.status === 200 ? STATUS_SUCCESS : STATUS_ERROR,
        nextPage: payload.data ? payload.data.next : null,
        count: payload.data ? payload.data.count : 0,
      };
    case `${CLEAR_STATUS_REDEEM_BY_USER}`:
      return {
        ...state,
        isRedeem: false,
        isLoadingRedeem: null,
        errorRedeem: false,
        statusRedeem: null
      };
    default:
      return state;
  }
}
