import React, {Fragment} from 'react'
import PropTypes from 'prop-types';
import {STATUS_LOADING, STATUS_SUCCESS} from '../../common/utils/generic';
import Validator from '../../common/utils/validator';
import './style.scss';
import ProductColumn from "../products/productsPlace/components/productCard/Product";
import ProductsPlaceFilters from "../products/ProductsPlaceFilters/ProductsPlaceFilters";
import InfiniteScroll from "react-infinite-scroller";
import NoSearchResult from "../../common/images/NoSearchResult.svg";
import bg from "../../common/images/background.jpg";
import { Link } from 'react-router-dom';
import Error404 from "../notFound/Error404";


export default class CartMenuPage extends React.Component {


  constructor(props) {
    super(props);

    this.state = {
      email: "",
      error: '',
      showResponse: false,
      companyUid: "",
      show_cart: true,
      show_store: true,
      show_program: true,
      modal: false
    };
  }


  componentDidMount() {
    this.validator = new Validator([
      {
        id: "email",
        ref: this.emailInput,
        rules: ["required", "email"]
      }
    ]);
    this.updateComponent()
    this.props.company.hidden_for_members.map(s=>{
      if(s.name==="cart"){
        this.setState({show_cart: false})
      }
      if(s.name==="store"){
        this.setState({show_store: false})
      }
      if(s.name==="program"){
        this.setState({show_program: false})
      }
      return true
    })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {


    this.updateComponent()
  }
  updateComponent() {
    let {company, getProductsByPlace, getSectionsToProducts} = this.props;
    if (company && company.uid !== this.state.companyUid) {
      this.setState({ companyUid: company.uid });
      getProductsByPlace();
      getSectionsToProducts();
    }
  }



  setFormValues = (propertiesToUpdate) => {
    this.setState(propertiesToUpdate);
    this.validator.validate(propertiesToUpdate);
  }

  onSubmitForm = async (event) => {
    event.preventDefault();
    let {recoveryStatus, recoveryPassword} = this.props;
    this.setState({showResponse: true})

    let payload = {...this.state}

    if (recoveryStatus !== STATUS_LOADING && this.validator.validate({payload})) {


      delete payload.error
      delete payload.showResponse
      recoveryPassword(payload);

    }
  }

  renderError(errors, fieldName) {
    return errors && errors[fieldName] &&
      <span className='field-error-message'>
        {errors[fieldName][0]}
      </span>;
  }

  setProductUid = (uid) => {
    this.setState({
      uidProduct: uid
    })
  };

  loadMore = () => {

    const {nextPageProducts, listNextProductsByPlaceStatus, listProductsByPlaceStatus, getMoreProductsByPlace} = this.props;
    if (listNextProductsByPlaceStatus !== STATUS_LOADING && listProductsByPlaceStatus !== STATUS_LOADING && nextPageProducts != null) {
      getMoreProductsByPlace(nextPageProducts);
    }
  };

  renderProductsPlace() {
    const {productsPlace, listProductsByPlaceStatus, count, placeUid, currency,newStyle} = this.props;

    if (listProductsByPlaceStatus === STATUS_LOADING) {
      return <Fragment>
        <ProductColumn loading={true}/>
        <ProductColumn loading={true}/>
        <ProductColumn loading={true}/>
        <ProductColumn loading={true}/>
        <ProductColumn loading={true}/>
        <ProductColumn loading={true}/>
      </Fragment>;
    }

    if (count === 0 && listProductsByPlaceStatus === STATUS_SUCCESS) {
      return <div className={"mx-auto"}>
        <div className={"d-flex flex-column justify-content-center align-items-center"}>
          <img src={NoSearchResult} alt="noItems"/>
          <p className={"mt-3"}>No se encuentran resultados para esta búsqueda</p>
        </div>
      </div>
    }
    // eslint-disable-next-line
    return productsPlace.map((productPlace, index) =>
      {
        return <ProductColumn
          key={`promo${index}`}
          placeUid={placeUid}
          product={productPlace}
          loading={false}
          openPopUp={null}
          setProductUid={this.setProductUid}
          currency={currency}
          newStyle={newStyle}
          notOpenModal={true}
          carta={true}
        />
      }

    );

  }

  render() {
    let {authStatus, nextPageProducts, sections, newStyle,
      company:{banner, name, cover, logo_rectangle, logo}, selectedPlace, status, sectionsStatus, getProductsByPlace} = this.props;
    let more = nextPageProducts != null;
    let loaderMore = (
      <Fragment key={"load"}>
        <ProductColumn loading={true}/>
        <ProductColumn loading={true}/>
        <ProductColumn loading={true}/>
      </Fragment>
    );
    let noTodo = false

    let cover_app = banner ? banner : cover || bg

    let logo_app = logo ? logo  : logo_rectangle

    return (
      <Fragment>
        {
          this.state.show_cart ?
            <div>
              <br/>

              <div className={"container-banner"}>

                {
                  (status===STATUS_SUCCESS) ?
                    <div className={"container"}>
                      <div className={"banner row d-flex justify-content-between"} style={{backgroundImage: `url(${cover_app})`}}>
                        <div className={"card col-lg-6 col-md-8"}>
                          <div className={"row align-items-center"}>

                            <div className={"col place-info"}>
                              <div className={"title-banner d-flex align-items-center"}>
                                <img alt=""
                                     src={logo_app}
                                     width={"60"}
                                     height={"60"}
                                     id={"logo-banner"}
                                     className="d-sm-block d-md-block d-lg-block mr-3"/>
                                <p className={"mr-1 font-weight-bold"}>{name}</p>
                              </div>
                            </div>

                          </div>
                        </div>
                        {
                          this.state.show_store &&
                          <Link className={"goWeb"} to={"/"}>
                            Ir a la web
                          </Link>
                        }
                      </div>

                    </div>
                    :
                    <div className={"container loading"}>
                      <div className={"banner row"} >
                        <div className={"card col-lg-6 col-md-6"}>
                          <div className={"row"}>
                            <div className={"col level-info"}>
                              <h4> </h4>
                              <p className="mt-2 mb-2"> </p>
                              <p className="mt-3 mb-2"> </p>
                              <div className="horary loading mt-4">
                                <p></p>
                                <div className="days mt-3 d-flex">
                                  <p></p>
                                  <p></p>
                                  <p></p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                }

              </div>

              <ProductsPlaceFilters
                sections={sections}
                listSectionsStatus={sectionsStatus}
                selectedPlace={selectedPlace}
                getProductsByPlace={getProductsByPlace}
              />

              <div className={"container"} style={authStatus===null ? {pointerEvents: "none"} : {}}>

                {
                  sections && (
                    sections.map((section, index) => {
                      if(localStorage.getItem("sectionSearchProduct")===section.uid) {
                        noTodo = true
                        return <h3
                          key={`category${index}`} className={"row category-title mt-2 d-none"}>{section.name}</h3>
                      }
                      return true
                    })
                  )
                }
                {
                  !noTodo && <h3 className={"row category-title mt-2 d-none"}>Todo</h3>
                }

                <InfiniteScroll
                  className={newStyle ? "exchange-list" : "row product-list mb-4"}
                  pageStart={0}
                  loadMore={this.loadMore.bind(this)}
                  hasMore={more}
                  loader={loaderMore}
                >
                  {this.renderProductsPlace()}
                </InfiniteScroll>

              </div>
            </div>
              :
            <Error404 />
        }
      </Fragment>
    );
  }
}

CartMenuPage.defaultProps = {
  error: null,
  recoveryStatus: '',
};

CartMenuPage.propTypes = {
  dispatch: PropTypes.func,
  error: PropTypes.object,
  recoveryStatus: PropTypes.string
};

