import React, {Fragment} from 'react'
import {STATUS_ERROR, STATUS_LOADING, STATUS_SUCCESS} from "../../common/utils/generic";
import $ from "jquery";
import CheckIcon from "./CheckIcon";
import {toast} from "react-toastify";

export default class ModalPoll extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      pollUid: "",
      answer: [],
      load: false
    }
  }
  componentDidMount() {

    this.setState({load: false})
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let {pollUid,
      showModal,
      openNotificationDetail,
      getPoll,
      poll,
      statusSendPoll,
      setPollUid,
      clearStatusPoll,
      clearCompanyUid,
      changeLoadList,
      loadList
    } = this.props
    if (pollUid !== this.state.pollUid) {
      this.setState({pollUid: pollUid})
      getPoll(pollUid)
    }

    if (openNotificationDetail) {
      $('#pollModal').modal('show');
      showModal(false);
    }
    $('body').css("padding", "0");

    if (poll != null && !poll.is_answered && this.state.answer.length===0) {
      let answer = [];
      poll.questions.forEach((question, index) => {
        answer[index] = {question: question.uid, marked: []};
      });
      this.setState({answer});
    }

    if(statusSendPoll===STATUS_SUCCESS && !loadList){
      changeLoadList(true)
      setPollUid("")
      //$(this.modal).modal('hide');
      clearStatusPoll();
      clearCompanyUid();
    }


  }

  handleCloseClick = (e) => {
    e.preventDefault()
    let {setPollUid} = this.props
    setPollUid("")
    $(this.modal).modal('hide');
  }


  handleCheckbox = (e, index) => {
    let answer = [...this.state.answer];
    let question = answer[index];
    if (e.target.checked) {
      question.marked.push(e.target.value);
    } else {
      question.marked.forEach((value, i) => {
        if (value === e.target.value) {
          question.marked.splice(i, 1);
        }
      });
    }
    answer[index] = question;
    this.setState({answer});
  };


  handleOption = (e, index) => {
    let answer = [...this.state.answer];
    let question = answer[index];
    if (question.marked.length > 0) {
      question.marked[0] = e.target.value;
    } else {
      question.marked.push(e.target.value);
    }
    answer[index] = question;
    this.setState({answer});
  };


  renderCheck = (index, question) => {
    return question.options.map(option => (
      <div key={option.uid} className="form-check poll">
        <p className="form-check-label" htmlFor={option.uid}>
          {option.text}
        </p>
        <label className={"check-box"} style={{transform: "scale(1.5)",  marginRight: 15}}>
          <input
            className="form-check-input"
            type="checkbox"
            value={option.uid}
            id={option.uid}
            onChange={e => this.handleCheckbox(e, index)}
          />
          <span style={{marginLeft: 7}}>
                        <CheckIcon/>
               </span>
        </label>
      </div>
    ));
  };

  renderOptions = (index, question) => {
    return question.options.map(option => (
      <div key={option.uid} className="form-check poll">
        <label className="form-check-label" htmlFor={option.uid}>
          {option.text}
        </label>
        <input
          className="form-check-input"
          style={{transform: "scale(1.2)",  marginRight:25}}
          type="radio"
          name={question.uid}
          id={option.uid}
          value={option.uid}
          onChange={e => this.handleOption(e, index)}
        />
      </div>
    ));
  };

  renderUI = () => {
    const {poll} = this.props;
    return poll.questions.map((question, index) => (
      <div key={index} className="form-group">
        <p htmlFor="question" className="option" style={{fontWeight: 600, whiteSpace: "pre-wrap"}}>
          {question.text}
        </p>
        {question.is_multiple_options === true
          ? this.renderCheck(index, question)
          : this.renderOptions(index, question)}
      </div>
    ));
  };

  renderProducts(products) {
    let {poll} = this.props
    return products.map((product, index) => (
      <Fragment key={index}>
        <tr key={`product${index}`}>
          <td><strong>{product.product.title}</strong></td>
          <td> {product.quantity} </td>
          <td> {poll.currency ? poll.currency.symbol : "S/."}{product.subtotal} </td>
        </tr>
        {product.option_groups.length !== 0 &&
          product.option_groups.map((option_group, index) => (
            <Fragment key={option_group.uid}>
              {
                option_group.options.map((o, index) => (
                  <tr key={o.name}>
                    <td>Extra: {o.name}</td>
                    <td>{product.quantity}</td>
                    <td>{poll.currency ? poll.currency.symbol : "S/."}{o.price * product.quantity}</td>
                  </tr>
                ))
              }
            </Fragment>
          ))
        }
      </Fragment>

    ));
  }


  handleSubmit = async event => {
    let {
      sendPoll
    } = this.props
    event.preventDefault();
    let validate = true;
    let answer = [...this.state.answer];
    answer.forEach(question => {
      if (question.marked.length === 0) {
        validate = false;
      }
    });
    if (validate) {
      let uid = this.props.poll.uid;

      sendPoll(uid, {answer: answer})
      console.log("send", uid, {answer: answer})
    } else {
      toast.error("Debe contestar todas las preguntas")
    }
  };

  render() {
    let {editStatus, retrieveStatus, poll, statusSendPoll} = this.props
    return (
      <div
        className={statusSendPoll===STATUS_SUCCESS ? "modal fade success" : "modal fade"}
        ref={(modal) => this.modal = modal}
        id="pollModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-order-detail  modal-lg" role="document">
          {
            statusSendPoll===STATUS_SUCCESS ?
              <div className="modal-content">
                {
                  poll && poll.image &&
                  <div className="modal-header">
                    <img src={ poll.image} alt="notificationImage"/>
                    <button onClick={this.handleCloseClick} type="button" className="close close-modal">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                }
                <div className="modal-body success">
                  {
                    (!poll || !poll.image) &&
                    <button onClick={this.handleCloseClick} type="button" className="close close-modal">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  }

                    <div className={"poll-content"}>
                      <div className={"poll-detail-content success"}>
                        <h5 className="success-poll">¡Su encuesta fue enviada con éxito!</h5>
                      </div>

                      <div className="content-btn">
                        <button className="btn-link success" onClick={(e)=>{this.handleCloseClick(e)}}>
                          Ir al Home
                        </button>
                      </div>

                    </div>
                </div>
              </div>
              :
              <div className="modal-content">
                {
                  poll && poll.image &&
                  <div className="modal-header">
                    <img src={ poll.image} alt="notificationImage"/>
                    <button onClick={this.handleCloseClick} type="button" className="close close-modal">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                }
                <div className="modal-body ">
                  {
                    (!poll || !poll.image) &&
                    <button onClick={this.handleCloseClick} type="button" className="close close-modal">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  }
                  <div className="col-12">
                    {(retrieveStatus === STATUS_LOADING || editStatus === STATUS_LOADING) &&
                      <p>
                        <span>Cargando</span>
                      </p>
                    }
                  </div>
                  <div className="col-12">
                    {retrieveStatus === STATUS_ERROR &&
                      <p>
                        <span>Lo sentimos la información no se ha podido traer adecuadamente</span>
                      </p>
                    }
                  </div>
                  <div className="col-12">
                    {editStatus === STATUS_ERROR &&
                      <p>
                        <span>Lo sentimos la información no se ha podido actualizar</span>
                      </p>
                    }
                  </div>

                  {(poll && !poll.is_answered && (retrieveStatus !== STATUS_LOADING || editStatus !== STATUS_LOADING)) &&
                    <div className={"poll-content"}>
                      <div className={"poll-detail-content"}>
                        <h5 className="modal-title">{poll.title}</h5>
                        <div className="modal-poll-detail">
                          <p>{poll.message}</p>
                          {this.renderUI()}
                        </div>
                      </div>

                      <div className="content-btn">
                        <button className="btn-link" onClick={(e)=>{this.handleSubmit(e)}}>
                          Enviar
                        </button>
                      </div>

                    </div>
                  }
                </div>
              </div>
          }

        </div>
      </div>
    )
  }

}

