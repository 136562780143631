import HttpClient from "../common/utils/httpClient";


export const GET_FARE = 'GET_FARE';


/**Urls**/
export const FARE_URL = 'v7/deliveries/places/{place_uid}/fare-estimate/';


export function getFare(data, place_uid) {
  let url = FARE_URL.replace("{place_uid}",place_uid)
  return (dispatch) => HttpClient.post(data, dispatch, GET_FARE, url);
}

