import React, {Fragment} from "react";
import PropTypes from "prop-types";
import Validator from "../../../common/utils/validator";
import NavLocalities from "./NavLocalities";
import {toast} from "react-toastify";

export default class PickUpMethod extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedLocality: null,
      address: '',
      reference: '',
      copy_address: false,
    }
    this.selectLocality = this.selectLocality.bind(this)
  }

  componentDidMount() {
    let {info} = this.props

    this.setState({...info})
    this.validator = new Validator([
      {
        id: "address",
        ref: this.addressInput,
        rules: ["required"]
      }])
  }

  selectLocality = (selectedLocality) => {
    this.setState({selectedLocality})
    let {
      setInfoDelivery
    } = this.props
    let payload = {...this.state, selectedLocality}
    setInfoDelivery({
      ...payload,
      isValid: selectedLocality !== null && this.validator.validateVisibleError(payload, false)
    })
  }

  renderError(errors, fieldName) {
    return errors && errors[fieldName] &&
      <span className='field-error-message'>
        {errors[fieldName][0]}
      </span>;
  }

  setFormValues = (propertiesToUpdate) => {
    let {
      selectedLocality
    } = this.state;
    this.setState(propertiesToUpdate);
    this.validator.validate(propertiesToUpdate);
    let {
      setInfoDelivery
    } = this.props
    let payload = {...this.state, ...propertiesToUpdate}
    setInfoDelivery({
      ...payload,
      isValid: selectedLocality !== null && this.validator.validateVisibleError(payload, false)
    })
  }


  order = () => {
    if (this.state.selectedLocality == null) {
      toast.info("Escoger un distrito")
      return;
    }
    if (this.validator.validate(this.state)) {

    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let {
      address
    } = this.props;
    // console.log(address);
    let{
      copy_address
    } = this.state;

    if(copy_address){
      if(address!==this.state.address){
        this.setFormValues({address: address})
      }
     }
    if(localStorage.getItem("address") && this.state.address!==localStorage.getItem("address")){
      this.setState({address: localStorage.getItem("address"), address_map: localStorage.getItem("address")})
    }

    if(this.state.reference!==localStorage.getItem("reference")){
      this.setState({reference: localStorage.getItem("reference")})
    }
  }

  render() {
    let {
      localities,
      errors,
      disabled,
      delivery_service,
      setOpenDeliveryPopUp,
      isDirectSale
    } = this.props;

    // console.log("props delivery", this.props);

    let {
      selectedLocality,
      address,
      reference,
      copy_address
    } = this.state;

    // console.log(this.state);

    return (
      <div className={"col-12 pl-0"}>
        {
          (localities.length > 0 || isDirectSale) &&
          <Fragment>
            {
              delivery_service === null &&
              <NavLocalities
                disabled={disabled}
                localities={localities}
                selectedLocality={selectedLocality}
                selectLocality={this.selectLocality}
              />
            }
            <div className="form form-group p-0">
              {
                delivery_service!==null
                  ?
                  <div>
                    <div
                      className="input-group"
                      ref={(input) => {
                        if (input) {
                          this.addressInput = input.querySelector('textarea');
                        }
                      }}
                    >
                    <textarea
                      disabled={true}
                      className={"form-control"}
                      value={address}
                      onChange={e => this.setFormValues({address: e.target.value})}
                      placeholder="Dirección *"
                    />
                    </div>
                    {this.renderError(errors, 'address')}
                  </div>
                  :
                  <div>
                    <div
                      className="input-group"
                      ref={(input) => {
                        if (input) {
                          this.addressInput = input.querySelector('textarea');
                        }
                      }}
                    >
                    <textarea
                      disabled={copy_address===true ? true : false}
                      className={"form-control"}
                      value={address}
                      onChange={e => this.setFormValues({address: e.target.value})}
                      placeholder="Dirección *"
                    />
                    </div>
                    {this.renderError(errors, 'address')}
                  </div>
              }
              {
                delivery_service !== null ?

                  <div className="form-check pl-0 form-check-edit-address">
                    <button id="edit-address" className="edit-address" onClick={e=>{
                      e.preventDefault()
                      setOpenDeliveryPopUp(true)
                    }
                    }>
                      Editar dirección
                    </button>
                  </div>
                  :
                  <div className="form-check">
                    <input id="copy_address" checked={copy_address} className="form-check-input" type="checkbox"
                           onChange={e => this.setFormValues({copy_address: !copy_address})}/>
                    <label className="form-check-label" htmlFor="copy_address">
                      <span style={{fontSize: "14px"}}>Copiar dirección</span>
                    </label>
                  </div>
              }
            </div>
            <div className="form-group form p-0">
              <div>
                <div
                  className="input-group"
                  ref={(input) => {
                    if (input) {
                      this.referenceInput = input.querySelector('textarea');
                    }
                  }}
                >
              <textarea
                disabled={delivery_service !== null ? true : false}
                className={"form-control"}
                value={reference ? reference : ""}
                onChange={e => this.setFormValues({reference: e.target.value})}
                placeholder="Referencia"
              />
                </div>
                {this.renderError(errors, 'reference')}
              </div>
            </div>
          </Fragment>
        }

      </div>
    )
  }
}

PickUpMethod.defaultProps = {
  localities: []
};

PickUpMethod.propTypes = {
  localities: PropTypes.array,
  info: PropTypes.object,
  setInfoDelivery: PropTypes.func
};
