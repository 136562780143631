import React from 'react'
import PropTypes from 'prop-types';
import bg from '../../../common/images/background.jpg';
import './style.scss';

export default class RaffleBanner extends React.Component {


  constructor(props) {
    super(props);
    this.state = {};
  }


  render() {
    let {company:{banner, cover}} = this.props;
    let cover_app = banner ? banner : cover || bg

    return (

      <div className={"program-banner"}>
            <div className={"container"}>{
              <div className={"banner row"} style={{backgroundImage: `url(${cover_app})`}}>

                    <div className={" col-lg-6 col-md-8"} style={{height:"200px"}}>
                    </div>
              </div>
            }</div>


      </div>
    );
  }
}

RaffleBanner.defaultProps = {
  program: null,
  app: null,
  retrieveStatus: ''
};

RaffleBanner.propTypes = {
  program: PropTypes.object,
  app: PropTypes.object,
  getProgram: PropTypes.func,
  retrieveStatus: PropTypes.string
};

