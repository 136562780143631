import {STATUS_ERROR, STATUS_LOADING, STATUS_SUCCESS} from "../common/utils/generic";

import {GET_RELATED_PROMOTIONS} from "../actions/relatedToPromotion";

const initialState = {
  listRelatedPromotion: [],
  errorRelatedPromotion: null,
  listRelatedPromotionStatus: null,
};

export default function reducer(state = initialState, action) {
  const {type, payload} = action;
  let data;
  switch (type) {

    case `${GET_RELATED_PROMOTIONS}_PENDING`:
      return {
        ...state,
        listRelatedPromotionStatus: STATUS_LOADING,
      };
    case `${GET_RELATED_PROMOTIONS}_REJECTED`:
      return {
        ...state,
        listRelatedPromotionStatus: STATUS_ERROR,
      };
    case `${GET_RELATED_PROMOTIONS}_FULFILLED`: {
      return {
        ...state,
        listRelatedPromotionStatus: (payload.status === 200 || payload.status === 201) ? STATUS_SUCCESS : STATUS_ERROR,
        errorRelatedPromotion: payload.status !== 200 ? (payload.status === 400 ? data : null) : null,
        listRelatedPromotion: payload.data ? payload.data.results : [],
      };
    }
    default:
      return state;
  }
}
