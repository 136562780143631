import React from 'react'
import PropTypes from 'prop-types';
import "./styles.scss"
import {Link} from "react-router-dom";
import {STATUS_LOADING, STATUS_SUCCESS} from "../../common/utils/generic";
import allcateg from "../../common/images/allcateg.jpg";

const $ = require('jquery');

export default class CompaniesCategoriesContainer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showAllCategories: false,
      firstLoad: true,
      appUid: null,
      visibilityViewMore: false,
      categoryUid: ""
    }
  }

  componentDidMount() {
    const {setMainCompany, cartInvisible, cleanCart, changeSecondaryMenu} = this.props
    cartInvisible()
    changeSecondaryMenu()
    setMainCompany()
    cleanCart()
    this.updateComponent()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.updateComponent()
  }

  updateComponent() {
    let { match: {params: {uid}}, app,getAppSections, getCompaniesSections, sections} = this.props;
    if (app.uid && uid !== this.state.categoryUid) {
      this.setState({categoryUid: uid})
      getCompaniesSections(app.uid, uid)
      getAppSections(app.uid)
    }
    if (sections) {
      if (sections.length >= 6 && !this.state.visibilityViewMore) {
        this.setState({visibilityViewMore: true})
      }
    }
  }

  renderCompanies(nRow) {
    let {companiesSections} = this.props
    let companies = []
    if (companiesSections) {
      companies = companiesSections
    }
    if (companies) {
      return companies.map((c, index) =>{
          if(index>=((nRow-1)*5) && index<nRow*5){
            return (<div key={`company${index}`} className="company company-multisite" >
              <Link to={c.ecommerce_enabled ? `/c/${c.uid}/products` : `/c/${c.uid}/program/`}>
                <figure className="figure p-0 mb-1">
                  <img src={`${c.profile_image ? c.profile_image : c.logo}`} className="figure-img img-fluid rounded mb-0" alt={`${c.name}`}/>
                </figure>
                <p className={"mt-2 pt-0 text-company"}><img src={`${c.logo}`} className="logo-p mr-1" alt={`${c.name}`}/> {c.name}</p>
              </Link>
            </div>)
          }
          return true
        }
      )
    }

  }

  render() {
    let {
      companiesSections,
      statusAppSections,
      sections,
      statusCompaniesSections,
      match: {params: {uid}}
    } = this.props

    let nRowCompanies = 0
    let arrayRowCompanies = []
    if (companiesSections) {
      nRowCompanies= Math.ceil(companiesSections.length/5)
    }
    for(let i=1; i<=nRowCompanies; i++){
      arrayRowCompanies.push(i)
    }
    return (
      <div>
        <div className={"container companies"}>
          <div className={"categories-multisite-section mb-4"}>

            {
              statusAppSections === STATUS_LOADING ?
                <div className={"categories-list list-flex load"}>
                  <div className={"categorie-item"}>
                    <div className={"img loading"}></div>
                    <div className={"detaill mt-4 loading"}></div>
                  </div>
                  <div className={"categorie-item"}>
                    <div className={"img loading"}></div>
                    <div className={"detaill mt-4 loading"}></div>
                  </div>
                </div>
                : statusAppSections === STATUS_SUCCESS &&
                <div className={sections && sections.length>4 ? "categories-list pt-2" : "categories-list list-flex pt-2"}>
                  <Link key={`allcateg`} to={`/`} className={"categorie-item"}>
                    <img src={allcateg} alt="image2"/>
                    <p>Todos</p>
                  </Link>
                  {
                    sections &&
                    sections.map((section, i) => {
                        if (i < 6) {
                          return (
                            <Link key={`${section.name}`} to={`/categories/${section.uid}`} className={uid===section.uid ? "categorie-item active" : "categorie-item"}>
                              <img src={section.image} alt="image2"/>
                              <p>{section.name}</p>
                            </Link>
                          )
                        }
                        return true
                      }
                    )
                  }
                </div>
            }
            <div
              className={!this.state.firstLoad ? this.state.showAllCategories ? "categories-list show-all-categories" : "categories-list hidden-all-categories" : "categories-list d-none"}>
              {
                statusAppSections === STATUS_SUCCESS &&
                sections &&
                sections.map((section, i) => {
                    if (i >= 6) {
                      return (
                        <Link key={`${section.name}`} to={`/categories/${section.uid}`} className={uid===section.uid ? "categorie-item active" : "categorie-item"}>
                          <img src={section.image} alt="image2"/>
                          <p>{section.name}</p>
                        </Link>
                      )
                    }
                    return true
                  }
                )
              }
            </div>

            {
              this.state.visibilityViewMore &&
              <button className={"view-more-categories font-weight-bold"} onClick={e => {
                e.preventDefault()
                this.setState({showAllCategories: !this.state.showAllCategories, firstLoad: false})
                setTimeout(function () {
                  $(".hidden-all-categories").css("display", "none");
                }, 1000);
                setTimeout(function () {
                  $(".show-all-categories").css("display", "flex");
                }, 100);
              }}>{!this.state.showAllCategories ? "Ver todas las categorías" : "Ocultar las categorías"}
              </button>
            }

          </div>
          {
            statusCompaniesSections===STATUS_LOADING ?
              <div className={"category-section load"}>
                <div className={"title-category"}>
                  <div className={"title loading"}></div>
                  <div className={"reset-category"}></div>
                </div>
                <div className={"section-list-companies"}>
                  <div className="row justify-content-md-left flex-nowrap ">
                    <div className="company company-multisite">
                      <div className={"d-flex flex-column align-items-center mt-4"}>
                        <figure className="figure p-0 mb-1 loading">
                        </figure>
                        <div className={"mt-2 pt-0 d-flex align-items-center"}><div className="logo-p mr-2"></div> <div className={"name loading"}></div> </div>
                      </div>
                    </div>
                    <div className="company company-multisite">
                      <div className={"d-flex flex-column align-items-center mt-4"}>
                        <figure className="figure p-0 mb-1 loading">
                        </figure>
                        <div className={"mt-2 pt-0 d-flex align-items-center"}><div className="logo-p mr-2"></div> <div className={"name loading"}></div> </div>
                      </div>
                    </div>
                    <div className="company company-multisite">
                      <div className={"d-flex flex-column align-items-center mt-4"}>
                        <figure className="figure p-0 mb-1 loading">
                        </figure>
                        <div className={"mt-2 pt-0 d-flex align-items-center"}><div className="logo-p mr-2"></div> <div className={"name loading"}></div> </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              :
              statusCompaniesSections===STATUS_SUCCESS &&
              companiesSections &&
              companiesSections.length!==0 ?
                <div className={"category-section"}>
                  <div className={"title-category mb-4"}>
                    <h2 className="row title-list-companies mb-2" style={{marginLeft: -5}}>{sections && sections.filter(s=>s.uid===uid)[0].name}{" "}({companiesSections.length})</h2>
                    <Link to="/" className="reset-category" >Resetear</Link>
                  </div>
                  {
                    arrayRowCompanies.map(i=>(
                      <div key={`${i}`} className={companiesSections.length>=5 ? "row justify-content-md-left flex-nowrap companies-list-full" : "row justify-content-md-left flex-nowrap companies-list-full flex-start"}>
                        {this.renderCompanies(i)}
                      </div>
                    ))
                  }
                </div>
                :
                statusCompaniesSections===STATUS_SUCCESS &&
                <div className={"category-section"}>
                  <div className={"title-category"}>
                    <h2 className="row title-list-companies mb-2" style={{marginLeft: -5}}>
                      {sections && sections.filter(s=>s.uid===uid)[0].name}{" "}(0)</h2>
                    <Link to="/" className="reset-category">Resetear</Link>
                  </div>
                  <div className="row justify-content-md-left flex-nowrap companies-list mt-2">
                    <h6 style={{marginLeft: 10}}>No se encontraron resultados</h6>
                  </div>
                </div>
          }
        </div>

      </div>
    );
  }

}

CompaniesCategoriesContainer.defaultProps = {
  app: null,
  company: null,
};

CompaniesCategoriesContainer.propTypes =
  {
    app: PropTypes.object,
    company: PropTypes.object,
  };