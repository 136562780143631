import React, { Component } from "react";
import { Link } from "react-router-dom";
import { STATUS_ERROR } from "../../../../common/utils/generic";
import wspBtn from "../Molitienda/images/whasapp.png";
import "./style.scss";
import $ from "jquery";
import SectionModel2 from "./SectionModel2";

export default class LandingModel2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      headerApi: null,
      numberWhatsapp: null,
      linkWsp: "web",
      loadApi: false,
    };
  }

  componentDidMount() {
    this.props.getAppLandingModelTwo();
    this.setState({
      numberWhatsapp: this.props.app.company.whatsapp_number,
    });
    if (window.innerWidth < 500 && window.innerHeight < 1000) {
      this.setState({
        linkWsp: "api",
      });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let { landingModelTwoResults } = this.props;
    if (
      landingModelTwoResults !== undefined &&
      landingModelTwoResults !== "undefined"
    ) {
      if (!this.state.loadApi) {
        let apiHeader = landingModelTwoResults?.sections.filter((s) => {
          if (s.type === "HEADER") {
            return s;
          }
        });
        this.setState({
          headerApi: apiHeader[0],
          loadApi: true,
        });
      }
    }
    $(".carousel").carousel({
      interval: 4000,
    });
  }

  render() {
    let { statusAppLandingModelTwo, app,landingModelTwoResults, path } = this.props;

    //console.log(landingModelTwoResults,"api info");

    if (statusAppLandingModelTwo === STATUS_ERROR) {
      return (
        <div className="">
          <h1>Landing no valido</h1>
        </div>
      );
    }
    return (
      <>
        {this.state.loadApi && (
          <div className={"landing-page"} id={"molitienda"}>
            <header>
              <nav>
                <div className={"social-networks"}>
                  <Link to={"/"}>
                    <img
                      src={app.company?.logo}
                      alt="logo"
                      className="logo lg-0"
                    />
                  </Link>
                </div>
                <div className={"btns-right"}>
                  {app.company?.whatsapp_number !== null && (
                    <a
                      href={`http://${this.state.linkWsp}.whatsapp.com/send?phone=${this.state.numberWhatsapp}&text=Hola!%20Estoy%20interesado%20en%20tu%20servicio`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={wspBtn} alt="wspBtn" />
                    </a>
                  )}
                  {this.state.headerApi!==null &&(
                    this.state.headerApi?.buttons.length > 0 && (
                      <a
                      href={this.state.headerApi.buttons[0]?.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={"btn-order"}
                    >
                      {this.state.headerApi.buttons[0]?.tittle}
                    </a>
                    )
                  )}
                  {app.marketplace ? (
                    <a
                      href={`https://administrador.${
                        process.env.REACT_APP_URL_REST.includes("bemaven")
                          ? "bemaven.xyz"
                          : "bederr.com"
                      }/`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={"btn-order btn-login"}
                    >
                      Iniciar sesión
                    </a>
                  ) : (
                    <Link to={"/login"} className={"btn-order btn-login"}>
                      Iniciar sesión
                    </Link>
                  )}
                </div>
              </nav>
            </header>
            {landingModelTwoResults?.sections.map((section,index) => (
              <SectionModel2  key={`stamps${index}`} path={path} section={section} app={app} numberWhatsapp={this.state.numberWhatsapp} linkWsp={this.state.linkWsp} />
            ))}
          </div>
        )}
      </>
    );
  }
}
