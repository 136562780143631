import {STATUS_SUCCESS} from "./common/utils/generic";
import {CART_PRODUCTS} from "./actions/cart";

export function initialState() {
  const token = localStorage.getItem('token') || null;
  const apiKey = localStorage.getItem('apiKey') || null;
  const selectedPlaceUid = localStorage.getItem('selectedPlaceUid') || null;
  const selectedAreaUid = localStorage.getItem('selectedAreaUid') || null;
  return {
    app: {
      apiKey: apiKey,
      company: null
    },
    places: {
      selectedPlaceUid: selectedPlaceUid,
      entities: null,
      results: []
    },
    areas: {
      selectedAreaUid: selectedAreaUid,
      entities: null,
      results: []
    },
    auth: {
      authStatus: token !== null ? STATUS_SUCCESS : null,
    },
    user: {
      recoveryStatus: token !== null ? STATUS_SUCCESS : null,
      profileStatus: '',
      user: null
    },
    cart:{
      typeCart: localStorage.getItem('typeCart') ? localStorage.getItem('typeCart') : CART_PRODUCTS
    }
  }
}