import React from 'react';
import {Switch} from "react-router-dom";
import {Route} from 'react-router';
import {
  changeStateSecondaryMenu, SECONDARY_MENU_BACK,
} from "../../actions/menu";
import {changeStateCart, CART_INVISIBLE} from "../../actions/cart";
import ListPolls from "./ListPolls/ListPolls";
import {
  clearStatusPoll,
  clearStatusSendPoll,
  getNumberPagePolls,
  getPolls, getPollsResolved,
  retrievePoll,
  sendPoll
} from "../../actions/polls";

import './style.scss';

export default class PollsRoot extends React.Component {


  render() {
    const {
      match,
      app,
      dispatch,
      company,
      selectedPlace,
      authStatus,
      polls,
      polls: {
        results: pollsResults,
        listStatus: listPollsStatus,
        entities: pollsEntities,
        nextPage,
        previousPage,
        count,
        formInitial
      },
      stateCart
    } = this.props;
    let programUid = app && app.allowed_programs[0].uid
    return (
      <Switch>
        <Route exact path={`${match.url}`} render={(props) =>
          <ListPolls
            {...props}
            authStatus={authStatus}
            app={app}
            company={company}
            selectedPlace={selectedPlace}
            stateCart={stateCart}
            changeSecondaryMenu={() => dispatch(changeStateSecondaryMenu(SECONDARY_MENU_BACK))}
            changeStateCart={() => dispatch(changeStateCart(CART_INVISIBLE))}
            getPolls={() => dispatch(getPolls(programUid))}
            getPollsResolved={() => dispatch(getPollsResolved(programUid))}
            polls={polls}
            getNumberPagePolls={(numberPage) => dispatch(getNumberPagePolls(programUid, numberPage))}
            retrievePoll={(uid) => dispatch(retrievePoll(programUid, uid))}
            sendPoll={(uid, data) => dispatch(sendPoll(uid, data))}
            clearStatusPoll={() => dispatch(clearStatusPoll())}
            clearStatusSendPoll={() => dispatch(clearStatusSendPoll())}
            nextPage={nextPage}
            previousPage={previousPage}
            listPollsStatus={listPollsStatus}
            count={count}
            pollsResults={pollsResults}
            pollsEntities={pollsEntities}
            formInitial={formInitial}
          />
        }
        />
      </Switch>
    )
  }
}

