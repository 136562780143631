import React from 'react';
import {Switch} from "react-router-dom";
import {Route} from 'react-router';
import {getProgram, joinToProgram} from "../../actions/programs";
import {
  cleanPayment,
  cleanPromotion, generateQR,
  getNextPromotions,
  getPromotion,
  getPromotions,
  payPromotion
} from "../../actions/promotions";
import ProgramContainer from "../program";
import ProgramPromotionDetailContainer from "../program/programPromotionDetailContainer";
import {getSections} from "../../actions/sections";
import {getPromotionPlaces} from '../../actions/placesByPromotion';
import {getRelatedProgramPromotion} from '../../actions/relatedToPromotion';
import PaymentContainer from "../payments/PaymentContainer";
import {FROM_PROMOTION} from "../payments/constants";
import RedemptionsContainer from "../redemptions/RedemptionsContainer";
import {clearStatusRedeem, getNextRedemptions, getRedemptions, redeemByUser} from "../../actions/redemptions";
import {getCoupon, validateCoupon} from "../../actions/coupon";
import {
  changeStateSecondaryMenu,
  SECONDARY_MENU_BACK,
  SECONDARY_MENU_BUSINESS
} from "../../actions/menu";
import {CART_INVISIBLE, changeStateCart, setTypeCart,CART_PRODUCTS} from "../../actions/cart";
import ProgramsContainer from "../program/ProgramsContainer";
import {getAds} from "../../actions/ads";
import {dismissToRaffle, getRaffles, registerInRaffle} from "../../actions/raffles";
import ProgramPromotionsListDiscount from '../program/programPromotions/ProgramPromotionsListDiscount';
import ProgramExchange from '../program/programPromotions/ProgramExchange';
import {getMoreProductsByPlace, getProductCurrent, getProductsByPlace} from "../../actions/productsByPlace";

//Product
import {getSectionsToProducts} from "../../actions/sectionsToProducts";
//product
const $ = require('jquery');

export default class ProgramRoot extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      companyUid: null,
      adsCompany: [],
      placeUid: "",
    }
  }

  componentDidMount() {
    this.props.cleanAdsCompany()
    this.updateComponent()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if ( $(".modal-backdrop").length > 1 ) {
      $('.modal-backdrop:first').remove();
    }
    localStorage.removeItem("skipBuyService")
    this.updateComponent()
  }

  updateComponent() {
    let {company, getAdsCompany, app, cleanAdsCompany, adsCompany, multisite, stateCart, changeStateCart} = this.props;
    if(multisite){
      if(app.company.uid!==company.uid){
        if (company.uid && company.uid !== this.state.companyUid) {
          this.setState({companyUid: company.uid})
          getAdsCompany(company.uid)
        }

        if(adsCompany && adsCompany.length!==0 && JSON.stringify(adsCompany)!==JSON.stringify(this.state.adsCompany)){
          this.setState({adsCompany: adsCompany})
          cleanAdsCompany()
        }
      }
    }else{
      if (company.uid && company.uid !== this.state.companyUid) {
        this.setState({companyUid: company.uid})
        getAdsCompany(company.uid)
      }

      if(adsCompany && adsCompany.length!==0 && JSON.stringify(adsCompany)!==JSON.stringify(this.state.adsCompany)){
        this.setState({adsCompany: adsCompany})
        cleanAdsCompany()
      }
    }

    if(stateCart!=="CART_INVISIBLE"){
      changeStateCart()
    }
  }

  render() {
    const {
      match,
      match: {params: {cUid}},
      dispatch,
      app,
      user,
      company,
      programs,
      programs: {
        results: programsResults,
        listStatus: listProgramStatus,
        entities: programEntities
      },
      sections,
      promotions,
      //selectedAreaUid,
      placesPromotion,
      relatedPromotion,
      multisite,
      coupon,
      redemptions,
      ads,
      raffles,
      statusAdsCompany,
      cleanAdsCompany,
      productsPlace,
      selectedPlace,

      //Product
      authStatus,
      selectPlace,
      selectArea,
      places,
      sectionsProducts,
      listPlacesStatus,
      openPopUp,
      activePopUp,
      componentPopUp,
      addProductCart,
      entitiesCart,
      addProductsBack,
      updateProductCart,
      typeCart,
      cleanCart
    } = this.props;
    let {adsCompany} = this.state

    return (

      <Switch>

        <Route exact path={`${match.url}/:uid/`} render={(props) =>
          <ProgramContainer
            {...props}
            app={app}
            multisite={multisite}
            company={company}
            getProgram={(uid) => dispatch(getProgram(uid))}
            addProgram={(uid) => dispatch(joinToProgram({program: uid}))}
            getSections={(uid) => dispatch(getSections(uid))}
            getPromotions={(uid, params = {}) => dispatch(getPromotions(uid, {area: localStorage.getItem("selectedAreaUid"),...params}))}
            getMoreProgramPromotions={(url) => dispatch(getNextPromotions(url))}
            programs={programs}
            programsCurrent={programsResults.map(pUid => programEntities[pUid])}
            changeSecondaryMenu={() => dispatch(changeStateSecondaryMenu(SECONDARY_MENU_BUSINESS))}
            changeStateCart={() => dispatch(changeStateCart(CART_INVISIBLE))}
            selectedAreaUid={localStorage.getItem("selectedAreaUid")}
            sections={sections}
            promotions={promotions}

            getAds={(uid, params = {}) => dispatch(getAds(uid,{...params}))}
            ads={ads}
            getRaffles={(uid) => dispatch(getRaffles(uid))}
            registerInRaffle={(programUid, raffleUid) => dispatch(registerInRaffle(programUid, raffleUid))}
            dismissToRaffle={(programUid, raffleUid) => dispatch(dismissToRaffle(programUid, raffleUid))}
            raffles={raffles}
            adsCompany={adsCompany}
            statusAdsCompany={statusAdsCompany}
            cleanAdsCompany={cleanAdsCompany}

            getProductCurrent={(uidPlace, productUid) => dispatch(getProductCurrent(uidPlace, productUid))}
            getProductsByPlace={(uidPlace, params = {
              is_redeemable: true
            })=> dispatch(getProductsByPlace(uidPlace,{...params}))}
            getMoreProductsByPlace={(url) => dispatch(getMoreProductsByPlace(url))}
            productsPlace={productsPlace}
            selectedPlace={selectedPlace}


            //Product
            authStatus={authStatus}
            getSectionsToProducts={(uidPlace) => dispatch(getSectionsToProducts(uidPlace))}
            sectionsProducts={sectionsProducts}
            listPlacesStatus={listPlacesStatus}
            openPopUp={openPopUp}
            selectPlace={selectPlace}
            selectArea={selectArea}
            places={places}
            activePopUp={activePopUp}
            componentPopUp={componentPopUp}
            addProductCart={addProductCart}
            entitiesCart={entitiesCart}
            addProductsBack ={addProductsBack }
            updateProductCart={updateProductCart}
            changeTypeCart={() => dispatch(setTypeCart(CART_PRODUCTS))}
            typeCart={typeCart}
            cleanCart={cleanCart}
          />
        }
        />
        <Route exact path={`${match.url}/:uid/promotions/`} render={(props) =>
          <ProgramContainer
            {...props}
            app={app}
            multisite={multisite}
            company={company}
            getProgram={(uid) => dispatch(getProgram(uid))}
            addProgram={(uid) => dispatch(joinToProgram({program: uid}))}
            getSections={(uid) => dispatch(getSections(uid))}
            getPromotions={(uid, params = {}) => dispatch(getPromotions(uid, {area: localStorage.getItem("selectedAreaUid"), ...params}))}
            getMoreProgramPromotions={(url) => dispatch(getNextPromotions(url))}
            programs={programs}
            programsCurrent={programsResults.map(pUid => programEntities[pUid])}
            changeSecondaryMenu={() => dispatch(changeStateSecondaryMenu(SECONDARY_MENU_BACK))}
            changeStateCart={() => dispatch(changeStateCart(CART_INVISIBLE))}
            selectedAreaUid={localStorage.getItem("selectedAreaUid")}
            sections={sections}
            promotions={promotions}

            getAds={(uid, params = {}) => dispatch(getAds(uid,{...params}))}
            ads={ads}
            getRaffles={(uid) => dispatch(getRaffles(uid))}
            registerInRaffle={(programUid, raffleUid) => dispatch(registerInRaffle(programUid, raffleUid))}
            dismissToRaffle={(programUid, raffleUid) => dispatch(dismissToRaffle(programUid, raffleUid))}
            raffles={raffles}
            adsCompany={adsCompany}
            statusAdsCompany={statusAdsCompany}
            cleanAdsCompany={cleanAdsCompany}

            getProductCurrent={(uidPlace, productUid) => dispatch(getProductCurrent(uidPlace, productUid))}
            getProductsByPlace={(uidPlace, params = {
              is_redeemable:true
            })=> dispatch(getProductsByPlace(uidPlace,{...params}))}
            getMoreProductsByPlace={(url) => dispatch(getMoreProductsByPlace(url))}
            productsPlace={productsPlace}
            selectedPlace={selectedPlace}

            //Product
            authStatus={authStatus}
            getSectionsToProducts={(uidPlace) => dispatch(getSectionsToProducts(uidPlace))}
            sectionsProducts={sectionsProducts}
            listPlacesStatus={listPlacesStatus}
            openPopUp={openPopUp}
            selectPlace={selectPlace}
            selectArea={selectArea}
            places={places}
            activePopUp={activePopUp}
            componentPopUp={componentPopUp}
            addProductCart={addProductCart}
            entitiesCart={entitiesCart}
            addProductsBack ={addProductsBack }
            updateProductCart={updateProductCart}
            changeTypeCart={() => dispatch(setTypeCart(CART_PRODUCTS))}
            typeCart={typeCart}
            cleanCart={cleanCart}

          />
        }
        />
        <Route exact path={`${match.url}/:uid/promotions/:promotionUid`} render={(props) =>
          <ProgramPromotionDetailContainer
            {...props}
            promotions={promotions}
            redemptions={redemptions}
            app={app}
            placesPromotion={placesPromotion}
            relatedPromotion={relatedPromotion}
            getPromotionCurrent={(uid, promotionUid) => dispatch(getPromotion(uid, promotionUid))}
            getPromotionPlaces={(promotionUid) => dispatch(getPromotionPlaces(promotionUid))}
            getRelatedProgramPromotion={(programUid, promotionUid) => dispatch(getRelatedProgramPromotion(programUid, promotionUid))}
            changeSecondaryMenu={() => dispatch(changeStateSecondaryMenu(SECONDARY_MENU_BACK))}
            changeStateCart={() => dispatch(changeStateCart(CART_INVISIBLE))}
            multisite={multisite}
            company={company}
            getCoupon={(programUid, promotionUid) => dispatch(getCoupon(programUid, promotionUid))}
            validateCoupon={(placeUid, code) => dispatch(validateCoupon(placeUid, code))}
            coupon={coupon}

            cleanPromotion={() => dispatch(cleanPromotion())}

            getQRPromotion={(uid, promotionUid) => dispatch(generateQR(uid, promotionUid))}
            redeemByUser={(programPromotionUid) => dispatch(redeemByUser(programPromotionUid))}
            clearStatusRedeem={() => dispatch(clearStatusRedeem())}
          />
        }/>
        <Route exact path={`${match.url}/:uid/more`} render={(props) =>
          <ProgramPromotionsListDiscount
          {...props}
          app={app}
          multisite={multisite}
          company={company}
          getProgram={(uid) => dispatch(getProgram(uid))}
          addProgram={(uid) => dispatch(joinToProgram({program: uid}))}
          getSections={(uid) => dispatch(getSections(uid))}
          getPromotions={(uid, params) => dispatch(getPromotions(uid, {area: localStorage.getItem("selectedAreaUid"), ...params}))}
          getMoreProgramPromotions={(url) => dispatch(getNextPromotions(url))}
          programs={programs}
          programsCurrent={programsResults.map(pUid => programEntities[pUid])}
          changeSecondaryMenu={() => dispatch(changeStateSecondaryMenu(SECONDARY_MENU_BACK))}
          changeStateCart={() => dispatch(changeStateCart(CART_INVISIBLE))}
          selectedAreaUid={localStorage.getItem("selectedAreaUid")}
          sections={sections}
          promotions={promotions}

          getAds={(uid, params = {}) => dispatch(getAds(uid,{...params}))}
          ads={ads}
          getRaffles={(uid) => dispatch(getRaffles(uid))}
          registerInRaffle={(programUid, raffleUid) => dispatch(registerInRaffle(programUid, raffleUid))}
          dismissToRaffle={(programUid, raffleUid) => dispatch(dismissToRaffle(programUid, raffleUid))}
          raffles={raffles}
          adsCompany={adsCompany}
          statusAdsCompany={statusAdsCompany}
          cleanAdsCompany={cleanAdsCompany}
          type={"WITHOUT_STAMPS"}
          />
        }/>
        <Route exact path={`${match.url}/:uid/more-stamps`} render={(props) =>
          <ProgramPromotionsListDiscount
          {...props}
          app={app}
          multisite={multisite}
          company={company}
          getProgram={(uid) => dispatch(getProgram(uid))}
          addProgram={(uid) => dispatch(joinToProgram({program: uid}))}
          getSections={(uid) => dispatch(getSections(uid))}
          getPromotions={(uid, params) => dispatch(getPromotions(uid, {area: localStorage.getItem("selectedAreaUid"), ...params}))}
          getMoreProgramPromotions={(url) => dispatch(getNextPromotions(url))}
          programs={programs}
          programsCurrent={programsResults.map(pUid => programEntities[pUid])}
          changeSecondaryMenu={() => dispatch(changeStateSecondaryMenu(SECONDARY_MENU_BACK))}
          changeStateCart={() => dispatch(changeStateCart(CART_INVISIBLE))}
          selectedAreaUid={localStorage.getItem("selectedAreaUid")}
          sections={sections}
          promotions={promotions}

          getAds={(uid, params = {}) => dispatch(getAds(uid,{...params}))}
          ads={ads}
          getRaffles={(uid) => dispatch(getRaffles(uid))}
          registerInRaffle={(programUid, raffleUid) => dispatch(registerInRaffle(programUid, raffleUid))}
          dismissToRaffle={(programUid, raffleUid) => dispatch(dismissToRaffle(programUid, raffleUid))}
          raffles={raffles}
          adsCompany={adsCompany}
          statusAdsCompany={statusAdsCompany}
          cleanAdsCompany={cleanAdsCompany}
          type={"STAMPS"}
          />
        }/>
        <Route exact path={`${match.url}/:uid/more-exchange`} render={(props) =>
          <ProgramExchange
          {...props}
          app={app}
          multisite={multisite}
          company={company}
          getProgram={(uid) => dispatch(getProgram(uid))}
          addProgram={(uid) => dispatch(joinToProgram({program: uid}))}
          getSections={(uid) => dispatch(getSections(uid))}
          getPromotions={(uid, params = {}) => dispatch(getPromotions(uid, {area: localStorage.getItem("selectedAreaUid"), ...params}))}
          getMoreProgramPromotions={(url) => dispatch(getNextPromotions(url))}
          programs={programs}
          programsCurrent={programsResults.map(pUid => programEntities[pUid])}
          changeSecondaryMenu={() => dispatch(changeStateSecondaryMenu(SECONDARY_MENU_BACK))}
          changeStateCart={() => dispatch(changeStateCart(CART_INVISIBLE))}
          selectedAreaUid={localStorage.getItem("selectedAreaUid")}
          sections={sections}
          promotions={promotions}

          getAds={(uid, params = {}) => dispatch(getAds(uid,{...params}))}
          ads={ads}
          getRaffles={(uid) => dispatch(getRaffles(uid))}
          registerInRaffle={(programUid, raffleUid) => dispatch(registerInRaffle(programUid, raffleUid))}
          dismissToRaffle={(programUid, raffleUid) => dispatch(dismissToRaffle(programUid, raffleUid))}
          raffles={raffles}
          adsCompany={adsCompany}
          statusAdsCompany={statusAdsCompany}
          cleanAdsCompany={cleanAdsCompany}

          getProductCurrent={(uidPlace, productUid) => dispatch(getProductCurrent(uidPlace, productUid))}
          getProductsByPlace={(uidPlace, params = {
            is_redeemable:true
          })=> dispatch(getProductsByPlace(uidPlace,{...params}))}
          getMoreProductsByPlace={(url) => dispatch(getMoreProductsByPlace(url))}
          productsPlace={productsPlace}
          selectedPlace={selectedPlace}

          //Product
          authStatus={authStatus}
          getSectionsToProducts={(uidPlace) => dispatch(getSectionsToProducts(uidPlace))}
          sectionsProducts={sectionsProducts}
          listPlacesStatus={listPlacesStatus}
          openPopUp={openPopUp}
          selectPlace={selectPlace}
          selectArea={selectArea}
          places={places}
          activePopUp={activePopUp}
          componentPopUp={componentPopUp}
          addProductCart={addProductCart}
          entitiesCart={entitiesCart}
          addProductsBack ={addProductsBack }
          updateProductCart={updateProductCart}
          changeTypeCart={() => dispatch(setTypeCart(CART_PRODUCTS))}
          typeCart={typeCart}
          cleanCart={cleanCart}
          />
        }/>
        <Route exact path={`${match.url}/:uid/promotions/:promotionUid/payment`} render={(props) =>
          <PaymentContainer
            {...props}
            app={app}
            user={user}
            company={company}
            from={FROM_PROMOTION}
            changeSecondaryMenu={() => dispatch(changeStateSecondaryMenu(SECONDARY_MENU_BACK))}
            changeStateCart={() => dispatch(changeStateCart(CART_INVISIBLE))}
            promotions={promotions}
            paymentPromotion={(dataPayment, uid, promotionUid) => dispatch(payPromotion(dataPayment, uid, promotionUid))}
            cleanPayment={() => dispatch(cleanPayment())}
            getPromotion={(uid, promotionUid) => dispatch(getPromotion(uid, promotionUid))}

          />
        }
        />

        <Route exact path={`${match.url}/:uid/promotions/:promotionUid/redemptions`} render={(props) =>
          <RedemptionsContainer
            {...props}
            changeSecondaryMenu={() => dispatch(changeStateSecondaryMenu(SECONDARY_MENU_BACK))}
            changeStateCart={() => dispatch(changeStateCart(CART_INVISIBLE))}
            redemptions={redemptions}
            getRedemptions={(uid, params = {}) => dispatch(getRedemptions(uid, {...params}))}
            getMoreProgramRedemptions={(url) => dispatch(getNextRedemptions(url))}
            currency={company.currency}
          />
        }
        />
        <Route exact path={`${match.url}`} render={(props) =>
          <ProgramsContainer
            {...props}
            multisite={multisite}
            cUid={cUid}
            company={company}
            listProgramStatus={listProgramStatus}
            programs={programsResults.map(pUid => programEntities[pUid])}
          />
        }
        />
      </Switch>
    )
  }
}
