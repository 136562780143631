import React from "react";
import PropTypes from "prop-types";
import NavDates from "./NavDates";
import PickupSchedule from "./PickupSchedule";
import moment from "moment"

export default class PickUpMethod extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedDate: null,
      selectedInterval: null,
      dates: []
    }
    this.selectDate = this.selectDate.bind(this)
    this.selectSchedule = this.selectSchedule.bind(this)
  }

  /**
   * Create admited dates (7 dates from currently day)
   */
  componentDidMount() {
    let {
      getPickupSchedule,
      setInfoPickUp,
      info
    } = this.props;
    if (info && info.dates && info.selectedDate) {
      this.setState({...info})
      getPickupSchedule({
        from_date: `${moment(info.selectedDate.date).format('YYYY-MM-DD')}`,
        to_date: `${moment(info.selectedDate.date).format('YYYY-MM-DD')}`
      })
    } else {
      let today = new Date();
      let days = []
      for (let i = 0; i < 7; i++) {
        let next = today.setDate(new Date().getDate() + i);
        days.push({date: next, selected: i === 0})
      }
      this.setState({dates: days, selectedDate: days[0]})
      getPickupSchedule({
        from_date: `${moment(days[0].date).format('YYYY-MM-DD')}`,
        to_date: `${moment(days[0].date).format('YYYY-MM-DD')}`
      })
      setInfoPickUp({dates: days, selectedDate: days[0]})
    }

  }

  /**
   * Select date and get schedule
   */
  selectDate = (d, index) => {
    let {
      getPickupSchedule, setInfoPickUp
    } = this.props;
    let {dates} = this.state
    for (let i = 0; i < dates.length; i++) {
      dates[i].selected = i === index
    }
    d.selected = true
    let payload = {dates, selectedDate: d, selectedInterval: null}
    this.setState(payload)
    setInfoPickUp({...payload, isValid: false})

    getPickupSchedule({
      from_date: `${moment(d.date).format('YYYY-MM-DD')}`,
      to_date: `${moment(d.date).format('YYYY-MM-DD')}`
    })


  }

  selectSchedule = (interval) => {
    let {
      setInfoPickUp
    } = this.props;
    let {
      selectedDate,
    } = this.state;
    if(selectedDate)
      interval.date = `${moment(selectedDate.date).format('YYYY-MM-DD')}`
    this.setState({selectedInterval: interval})
    let payload = {...this.state, selectedInterval: interval, isValid: interval && this.state.selectedDate}
    setInfoPickUp(payload)
  }

  renderError(errors, fieldName) {
    return errors && errors[fieldName] &&
      <span className='field-error-message'>
        {errors[fieldName][0]}
      </span>;
  }

  render() {
    let {
      disabled,
      pickupSchedule: {
        entity: schedule,
        status: scheduleStatus
      }
    } = this.props;
    let {
      dates,
      selectedDate,
      selectedInterval
    } = this.state;
    return (
      <div className={"col-12 pl-0"}>
        <NavDates
          dates={dates}
          disabled={disabled}
          selectedDate={selectedDate}
          selectDate={this.selectDate}
        />
        <PickupSchedule
          schedule={schedule}
          disabled={disabled}
          scheduleStatus={scheduleStatus}
          selectedInterval={selectedInterval}
          selectSchedule={this.selectSchedule}
        />


      </div>
    )
  }
}

PickUpMethod.defaultProps = {
  selectedPlace: null
};

PickUpMethod.propTypes = {
  selectedPlace: PropTypes.object,
  pickupSchedule: PropTypes.object,
  setInfoPickUp: PropTypes.func
};
