import React from 'react';
import {Switch} from "react-router-dom";
import {Route} from 'react-router';
import {
  changeStateSecondaryMenu, SECONDARY_MENU_BACK,
} from "../../actions/menu";
import {changeStateCart, CART_INVISIBLE} from "../../actions/cart";
import ListEvents from "./ListEvents/ListEvents";
import DetailEvent from "./DetailEvent";
import {getNumberPageEvents, getEvents, retrieveEvent} from "../../actions/events";

import './style.scss';

export default class SubscriptionRoot extends React.Component {


  render() {
    const {
      match,
      app,
      user,
      dispatch,
      company,
      events,
      events:{
        results: eventsResults,
        listStatus: listEventsStatus,
        entities: eventsEntities,
        nextPage,
        previousPage,
        count,
        formInitial
      },
      stateCart,
      programs,
      selectedPlace
    } = this.props;
    let companyUid = company ? company.uid : null

    return (
      <Switch>
        <Route exact path={`${match.url}`} render={(props) =>
          <ListEvents
            {...props}
            programs={programs}
            selectedPlace={selectedPlace}

            user={user}
            app={app}
            company={company}
            stateCart={stateCart}
            changeSecondaryMenu={() => dispatch(changeStateSecondaryMenu(SECONDARY_MENU_BACK))}
            changeStateCart={() => dispatch(changeStateCart(CART_INVISIBLE))}
            getEvents={(params) => companyUid && dispatch(getEvents(companyUid, params))}
            events={events}
            getNumberPageEvents={ (numberPage) => dispatch(getNumberPageEvents(numberPage))}
            retrieveEvent={ (uid) => companyUid && dispatch(retrieveEvent(companyUid, uid))}
            nextPage={nextPage}
            previousPage={previousPage}
            listEventsStatus={listEventsStatus}
            count={count}
            eventsResults={eventsResults}
            eventsEntities={eventsEntities}
            formInitial={formInitial}
            listCoins={false}
          />
        }
        />
        <Route exact path={`${match.url}/:uid/`} render={(props) =>
          <DetailEvent
            {...props}
            app={app}
            company={company}
            stateCart={stateCart}
            changeSecondaryMenu={() => dispatch(changeStateSecondaryMenu(SECONDARY_MENU_BACK))}
            changeStateCart={() => dispatch(changeStateCart(CART_INVISIBLE))}
            getEvents={(params) => companyUid && dispatch(getEvents(companyUid, params))}
            events={events}
            getNumberPageEvents={ (numberPage) => dispatch(getNumberPageEvents(numberPage))}
            retrieveEvent={ (uid) => companyUid && dispatch(retrieveEvent(companyUid, uid))}
            nextPage={nextPage}
            previousPage={previousPage}
            listEventsStatus={listEventsStatus}
            count={count}
            eventsResults={eventsResults}
            eventsEntities={eventsEntities}
            formInitial={formInitial}
            listCoins={false}
          />
        }
        />
      </Switch>
    )
  }
}

