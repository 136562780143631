



export const OPEN_SIDEBAR_MENU = 'OPEN_SIDEBAR_MENU';
export const OPEN_CART_MENU = 'OPEN_CART_MENU';
export const STATE_SECONDARY_MENU = 'STATE_SECONDARY_MENU';

export const SECONDARY_MENU_BUSINESS = 'SECONDARY_MENU_BUSINESS';
export const SECONDARY_MENU_BACK = 'SECONDARY_MENU_BACK';
export const SECONDARY_MENU_INVISIBLE = 'SECONDARY_MENU_INVISIBLE';


export function openSidebarMenu(open) {
  return {
    type: OPEN_SIDEBAR_MENU,
    open
  }
}
export function openCartMenu(open) {
  return {
    type: OPEN_CART_MENU,
    open
  }
}
export function changeStateSecondaryMenu(state) {
  return {
    type: STATE_SECONDARY_MENU,
    state
  }

}
