import {normalize, schema} from 'normalizr';
import {GET_AREAS, SELECT_AREA} from "../actions/areas";

import {STATUS_ERROR, STATUS_LOADING, STATUS_SUCCESS} from "../common/utils/generic";

const areaSchema = new schema.Entity('areas', {}, {idAttribute: 'uid'});
const areaListSchema = new schema.Array(areaSchema);


const initialState = {
  entities: null,
  results: [],
  selectedArea: null,
  selectedAreaUid: null,
  listStatus: null,
};

export default function reducer(state = initialState, action) {
  const {type, payload} = action;
  let data;
  switch (type) {

    case `${GET_AREAS}_PENDING`:
      return {
        ...state,
        listStatus: STATUS_LOADING
      };

    case `${GET_AREAS}_FULFILLED`:
      data = payload && payload.status === 200 ? normalize(payload.data, areaListSchema) : {};
      let selectedArea = null
      /*
      if (state.selectedAreaUid && data.entities && data.entities.areas) {
        selectedArea = data.entities.areas[state.selectedAreaUid] ? data.entities.areas[state.selectedAreaUid] : null
        if(selectedArea==null){
          selectedArea = data.entities.areas[data.result[0]]
          localStorage.setItem('selectedAreaUid', selectedArea.uid);
        }
      }else{
        if(data.entities && data.entities.areas){
          selectedArea = data.entities.areas[data.result[0]]
          localStorage.setItem('selectedAreaUid', selectedArea.uid);
        }else{
          localStorage.removeItem('selectedAreaUid');
        }

      }*/
      return {
        ...state,
        entities: data && data.entities && data.entities.areas ? data.entities.areas : [],
        results: data && data.result ? data.result : [],
        listStatus: payload && payload.status === 200 ? STATUS_SUCCESS : STATUS_ERROR,
        selectedArea: selectedArea,
        selectedAreaUid: selectedArea ? selectedArea.uid : null
      };

    case SELECT_AREA:
      let areaUid = action.area ? action.area.uid : null
      localStorage.setItem('selectedAreaUid', areaUid);
      return {
        ...state,
        selectedArea: action.area,
        selectedAreaUid: areaUid
      };
    default:
      return state;
  }
}
