import React from 'react'
import PropTypes from 'prop-types';
import "./styles.scss"
import {Link} from "react-router-dom";
import allcateg from "../../common/images/allcateg.jpg";
//import bg from "../../common/images/background.jpg";
import leftArrow from "../../common/images/leftArrow.png";
import {STATUS_LOADING, STATUS_SUCCESS} from "../../common/utils/generic";
import CompanyAdsPopUp from "../products/companyAdsPopUp";

const $ = require('jquery');

export default class CompaniesContainer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showAllCategories: false,
      firstLoad: true,
      appUid: null,
      visibilityViewMore: false,
      loadSections: false,
      sections: []
    }
  }

  componentDidMount() {
    const {setMainCompany, cartInvisible, cleanCart, changeSecondaryMenu, cleanAdsCompany} = this.props
    cartInvisible()
    changeSecondaryMenu()
    setMainCompany()
    cleanCart()
    cleanAdsCompany()
    this.updateComponent()

  }

  componentDidUpdate(prevProps, prevState, snapshot) {

    this.updateComponent()
    let {sections} = this.props
    if (sections) {
      sections.map((section, i) => {
        $(`#${section.uid}left`).click(function () {
          let leftPos = $(`#${section.uid}list`).scrollLeft();
          $(`#${section.uid}list`).animate({
            scrollLeft: leftPos + 200
          }, 300);
        });
        return true
      })
    }
  }

  updateComponent() {
    let {app, getAppSections, sections, getAdsCompany, cleanAdsCompany} = this.props;
    if (app.uid && app.uid !== this.state.appUid) {
      this.setState({appUid: app.uid})
      cleanAdsCompany()
      getAppSections(app.uid)
      getAdsCompany(app.company.uid)
    }
    if (sections) {
      if (sections.length >= 6 && !this.state.visibilityViewMore) {
        this.setState({visibilityViewMore: true})
      }
      if (!this.state.loadSections) {
        this.setState({sections: sections, loadSections: true})
      }
    }
  }

  renderCompanies(section) {
    let {app} = this.props
    let allowed_companies = app.allowed_companies
    let companies = []
    if (section) {
      companies = section.companies

    }
    let auxCompanies = []
    if (companies) {
      companies.map((c, index) => {
        let payload = c
        allowed_companies.map(s => {
            if (s.uid === c.uid) {
              payload.ecommerce_enabled = (s.ecommerce_enabled)
            }
            return true
          }
        )
        auxCompanies.push(payload)
        return true
      })

      return auxCompanies && auxCompanies.map((c, index) =>
        index<=6 &&
        <div key={`company${index}`} className="company company-multisite"
        >
          <Link to={(c.ecommerce_enabled) ? `/c/${c.uid}/products` : `/c/${c.uid}/program/`}>
            <figure className="figure p-0 mb-1">
              <img src={`${c.profile_image ? c.profile_image : c.logo}`} className="figure-img img-fluid rounded mb-0" alt={`${c.name}`}/>
            </figure>
            <p className={"mt-2 pt-0 text-company"}><img src={`${c.logo}`} className="logo-p mr-1" alt={`${c.name}`}/> {c.name}</p>
          </Link>
        </div>
      )
    }

  }

  /*
  renderCompanies() {
    let {app} = this.props
    if (app) {
      return app.allowed_companies.map((c, index) =>
        <div key={`company${index}`} className="company company-multisite"
        >
          <Link to={c.ecommerce_enabled ? `/c/${c.uid}/products`: `/c/${c.uid}/program/`}>
            <figure className="figure p-0 mb-1">
              <img src={`${c.logo}`} className="figure-img img-fluid rounded mb-0" alt={`${c.name}`}/>
            </figure>
            <p className={"mt-2 pt-0"}><img src={`${c.logo}`} className="logo-p" alt={`${c.name}`}/> {c.name}</p>
          </Link>
        </div>
      )
    }
  }
*/
  render() {
    let {
      //company,
      adsCompany,
      statusAppSections,
    } = this.props;
    let {
      sections
    } = this.state;
    //let banner_app = company ? company.banner ? company.banner : company.cover || bg : bg
    //let cover_app = company ? company.cover ? company.cover : "" : ""
    let banners = adsCompany && adsCompany.filter(p=>p.type==="IMAGE")
    return (
      <div>

        {
          adsCompany && adsCompany.length!==0 &&
          <CompanyAdsPopUp
            adsCompany = {adsCompany && adsCompany.filter(p=>p.type==="POP_UP")}
          />
        }
        <div className={"container companies"}>
          <div className={"categories-multisite-section mb-4"}>
            {
              statusAppSections === STATUS_LOADING ?
                <div className={"categories-list list-flex load"}>
                  <div className={"categorie-item"}>
                    <div className={"img loading"}></div>
                    <div className={"detaill mt-4 loading"}></div>
                  </div>
                  <div className={"categorie-item"}>
                    <div className={"img loading"}></div>
                    <div className={"detaill mt-4 loading"}></div>
                  </div>
                </div>
                : statusAppSections === STATUS_SUCCESS &&
                <div className={sections && sections.length > 4 ? "categories-list pt-2" : "categories-list list-flex pt-2"}>
                  <Link key={`allcateg`} to={`/`} className={"categorie-item active"}>
                    <img src={allcateg} alt="image2"/>
                    <p>Todos</p>
                  </Link>
                  {
                    sections &&
                    sections.map((section, i) => {
                        if (i < 6) {
                          return (
                            <Link key={`${section.name}`} to={`/categories/${section.uid}`} className={"categorie-item"}>
                              <img src={section.image} alt="image2"/>
                              <p>{section.name}</p>
                            </Link>
                          )
                        }
                        return true
                      }
                    )
                  }
                </div>
            }

            <div
              className={!this.state.firstLoad ? this.state.showAllCategories ? "categories-list show-all-categories" : "categories-list hidden-all-categories" : "categories-list d-none"}>
              {
                sections &&
                sections.map((section, i) => {
                    if (i >= 6) {
                      return (
                        <Link key={`${section.name}`} to={`/categories/${section.uid}`} className={"categorie-item"}>
                          <img src={section.image} alt="image2"/>
                          <p>{section.name}</p>
                        </Link>

                      )
                    }
                    return true
                  }
                )
              }
            </div>
            {
              this.state.visibilityViewMore &&
              <button className={"view-more-categories font-weight-bold"} onClick={e => {
                e.preventDefault()
                this.setState({showAllCategories: !this.state.showAllCategories, firstLoad: false})
                setTimeout(function () {
                  $(".hidden-all-categories").css("display", "none");
                }, 1000);
                setTimeout(function () {
                  $(".show-all-categories").css("display", "flex");
                }, 100);
              }}>{!this.state.showAllCategories ? "Ver todas las categorías" : "Ocultar las categorías"}
              </button>
            }

          </div>
          {
            banners && banners.length === 2 &&
            <div className={"banners-multisite pt-2 mb-4"}>
              <div className={"banner-item double"} style={{backgroundImage: `url(${banners[0].image})`}}>
              </div>
              <div className={"banner-item double second"} style={{backgroundImage: `url(${banners[1].image})`}}>
              </div>
            </div>
          }
          {
            banners && banners.length === 1 &&
            <div className={"banner row"} style={{backgroundImage: `url(${banners[0].image})`}}>
            </div>
          }
          {
            statusAppSections === STATUS_LOADING  ?
              <div className={"category-section load"}>
                <div className={"title-category"}>
                  <div className={"title loading"}></div>
                  <div className={"view-all loading"}></div>
                </div>
                <div className={"section-list-companies"}>
                  <div className="row justify-content-md-left flex-nowrap ">
                    <div className="company company-multisite">
                      <div className={"d-flex flex-column align-items-center mt-4"}>
                        <figure className="figure p-0 mb-1 loading">
                        </figure>
                        <div className={"mt-2 pt-0 d-flex align-items-center"}><div className="logo-p mr-2"></div> <div className={"name loading"}></div> </div>
                      </div>
                    </div>
                    <div className="company company-multisite">
                      <div className={"d-flex flex-column align-items-center mt-4"}>
                        <figure className="figure p-0 mb-1 loading">
                        </figure>
                        <div className={"mt-2 pt-0 d-flex align-items-center"}><div className="logo-p mr-2"></div> <div className={"name loading"}></div> </div>
                      </div>
                    </div>
                    <div className="company company-multisite">
                      <div className={"d-flex flex-column align-items-center mt-4"}>
                        <figure className="figure p-0 mb-1 loading">
                        </figure>
                        <div className={"mt-2 pt-0 d-flex align-items-center"}><div className="logo-p mr-2"></div> <div className={"name loading"}></div> </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              : statusAppSections === STATUS_SUCCESS &&
              sections &&
              sections.map((section, i) =>
                <div key={`${section.name}c`} className={"category-section"}>
                  <div className={"title-category"}>
                    <h2 className="row title-list-companies mb-2" style={{marginLeft: -5}}>{section.name}</h2>
                    <Link to={`/categories/${section.uid}`}>Ver todos</Link>
                  </div>
                  <div className={"section-list-companies"}>
                    <div id={`${section.uid}list`} className={(section.companies.length>=7 && section.companies.length<=7) ? "row justify-content-md-left flex-nowrap companies-list justify-content-between" : "row justify-content-md-left flex-nowrap companies-list"} >
                      {this.renderCompanies(section)}
                    </div>
                    <div className={"leftArrow"} id={`${section.uid}left`}><img src={leftArrow} alt="leftArrow"/></div>
                  </div>
                </div>
              )
          }
        </div>
        {
          false &&
          <div className={"container companies"}>

            <h2 className="row title-list-companies" style={{marginLeft: -5}}>Todas las empresas</h2>
            <div className="row justify-content-md-left">
              {this.renderCompanies()}
            </div>
          </div>
        }

      </div>
    );
  }

}

CompaniesContainer.defaultProps = {
  app: null,
  company: null,
};

CompaniesContainer.propTypes =
  {
    app: PropTypes.object,
    company: PropTypes.object,
  };