
export const CHANGE_COMPANY = 'CHANGE_COMPANY';
export const MAIN_COMPANY = 'MAIN_COMPANY';




/**Urls**/


export function mainCompany( ) {


  return {
    type: MAIN_COMPANY
  }

}


export function changeCompany(companyUid ) {


    return {
      type: CHANGE_COMPANY,
      companyUid
    }

}


