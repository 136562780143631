import React, {Fragment} from 'react'

export default class DetailCompany extends React.Component {


  constructor(props) {
    super(props);
    this.state = {
      companyUid: ""
    };
  }

  componentDidMount() {
    let {match, changeCompany} = this.props
    if(match.params && match.params.cUid){
      if(this.state.companyUid !== match.params.cUid){
        this.setState({
          companyUid: match.params.cUid
        })
        changeCompany(match.params.cUid)
      }
    }

    /*


     */
  }


  render() {
    return (
      <Fragment />
    );
  }
}





