import React, {Fragment} from 'react'
import PropTypes from 'prop-types';
import {STATUS_ERROR, STATUS_LOADING, STATUS_SUCCESS} from '../../common/utils/generic';
import './style.scss';
import {toast} from "react-toastify";
import {CART_INVISIBLE} from "../../actions/cart";
import Footer from "../footer/Footer";

export default class ValidateEmail extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      uidApp: "",
      uid: "",
      token: "",
      showResponse: ''
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {

    let {validateStatus} = this.props;
    if (validateStatus === STATUS_ERROR && this.state.showResponse === true) {
      toast.error("Ha ocurrido un error al confirmar su email");
      this.setState({ showResponse: false })
    } else {
      if (validateStatus === STATUS_SUCCESS && this.state.showResponse === true) {
        toast.success("Se ha valido correctamente su email");
        this.setState({ showResponse: false })
        setTimeout(() => {
          this.props.history.push(`/`);
          return true;
        }, 1500);

      }
    }
  }

  componentDidMount() {

    const {match, validateEmail} = this.props;
    const uidApp = match.params.uidApp;
    const uidUser = match.params.uidUser;
    const token = match.params.token;

    if (uidUser !== undefined && token !== undefined) {
      this.setState({uidApp: uidApp});
      this.setState({uid: uidUser});
      this.setState({token: token});
      validateEmail(uidApp, uidUser, token);

      this.setState({showResponse: true});
    } else {
      this.props.history.push(`/`);
    }
  }

  renderError(errors, fieldName) {
    return errors && errors[fieldName] &&
      <span className='field-error-message'>
        {errors[fieldName]}
      </span>;
  }

  render() {
    let { validateStatus, app: {company: {logo_rectangle, logo}}, errors } = this.props;
    let logo_app = logo_rectangle ? logo_rectangle : logo

    return (

      <Fragment>
        <div className={"rendered-content"}>
          <div className="elevation-container col-xl-4 col-lg-5 col-md-7 col-sm-6">
            <div className="validate-email">
              <div className="box-validate">
                <div className="message">
                  <img src={logo_app ? logo_app : logo} className="logo" alt="logo"/>
                  <div style={{marginTop: 80, marginBottom: 40}}>
                    <p>{ (validateStatus === STATUS_LOADING) && "validando...." }</p>
                    <p>{ (validateStatus === STATUS_ERROR) ? "No se ha podido validar correctamente el correo" : "Correo validado correctamente"}</p>
                  </div>
                  {
                    (errors && errors.detail) &&
                    <div
                      style={{marginTop: 15, marginBottom: 15}}
                    >
                      {this.renderError(errors, 'detail')}
                    </div>
                  }
                  <div className="submits">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => this.props.history.push('/')}>
                      VOLVER AL LOGIN
                    </button>
                  </div>
                </div>

              </div>
            </div>

          </div>
        </div>
        <Footer
          stateCart={CART_INVISIBLE}></Footer>
      </Fragment>
    );
  }
}

ValidateEmail.defaultProps = {
  errorValidate: null,
  validateStatus: '',
};

ValidateEmail.propTypes = {
  dispatch: PropTypes.func,
  errorValidate: PropTypes.object,
  validateStatus: PropTypes.string
};

