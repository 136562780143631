import React, { Fragment } from "react";
import PropTypes from "prop-types";
import bg from "../../../common/images/background.jpg";
import "./style.scss";
import {
  STATUS_INCLUDE,
  STATUS_LOADING,
  STATUS_SUCCESS,
} from "../../../common/utils/generic";
import LevelInfo from "./LevelInfo";

export default class ProgramBanner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      info_modal: false,
    };
  }

  render() {
    let {
      program,
      retrieveStatus,
      addStatus,
      company: { banner, cover },
    } = this.props;
    let cover_app = banner ? banner : cover || bg;
    let level = null;
    let percent = 0;
    let pointsExtra = 0;
    let next_level = null;
    let color = null;
    let descriptionNextLevel = null;
    let colorNextLevel = null;
    //console.log(program, "Program");gi
    if (program) {
      if (program.color) color = program.color.replace("#", "");
      if (program.levels_system) {
        for (let i = 0; i < program.levels.length; i++) {
          if (level) {
            next_level = program.levels[i];
            break;
          }
          if (program.levels[i].is_active) {
            level = program.levels[i];
            if (level.max_points && level.max_points > 0) {
              percent = (program.points * 100) / level.max_points;
            }
            if (level.color) color = level.color.replace("#", "");
          }
        }
        if (next_level) {
          //console.log(next_level,"sassss");
          pointsExtra = next_level.points - program.points
          descriptionNextLevel = next_level.description
          colorNextLevel=next_level.color
        }
      }
    }
    const HandleClose = ()=>{
      this.setState({info_modal: false})
    }

    return (
      <div className={"program-banner"}>
        {retrieveStatus === STATUS_SUCCESS ? (
          <div className={"container containerPrograms"}>
            {
              <div
                className={"banner bannerPrograms row"}
                style={{ backgroundImage: `url(${cover_app})` }}
              >
                {level ? (
                  <div className={"cardPrograms col-lg-6 col-md-8 d-none"}>
                    {/* <div className={"row"}>
                        <h1 className={"program-title"}>{program && program.name}</h1>
                      </div> */}
                    {false && (
                      <div className={"row"}>
                        <div className={"mr-auto"}>
                          <div
                            className={"circle"}
                            style={color ? { background: `#${color}` } : {}}
                          />
                        </div>

                        <div className={"col level-info"}>
                          <span>Nivel actual</span>
                          {level ? (
                            <p>
                              <span>{level.title}</span> {program.points} puntos
                            </p>
                          ) : (
                            <p>Sin Nivel</p>
                          )}
                        </div>
                      </div>
                    )}
                    <div className={"card-advanced p-3"}>
                      <div className={""}>
                        {this.state.info_modal && (
                          <LevelInfo
                            nivelTitle={level.title}
                            color={level.color}
                            percent={percent}
                            point={program.points}
                            description={level.description}
                            pointsExtra={pointsExtra}
                            nextLevel = {next_level!==null ? next_level.title : null}
                            descriptionNextLevel={descriptionNextLevel}
                            colorNextLevel={colorNextLevel}
                            HandleClose={HandleClose}
                          />
                        )}
                        <div className={"level-info"}>
                          {level ? (
                            <div>
                              <div className={"m-0"}>
                                <span>{level.title.toUpperCase()}</span>
                              </div>
                              <div className={"m-0"}>
                                <span
                                  onClick={(e) =>
                                    this.setState({
                                      info_modal: !this.state.info_modal,
                                    })
                                  }
                                  className={"level-info-more"}
                                >
                                  Ver más
                                </span>
                              </div>
                            </div>
                          ) : (
                            <p className={"m-0"}>Sin Nivel</p>
                          )}
                        </div>
                      </div>
                      <div className={"advanced-percent"}>
                        <div className={"bar mt-1"} style={{overflow: "hidden"}}>
                          <div
                            style={
                              color
                                ? {
                                    width: `${percent}%`,
                                    background: `#${color}`,
                                  }
                                : { width: `${percent}%` }
                            }
                          />
                        </div>
                        <div className="poinsProgram">
                          <p>
                            <span>{program.points!==null && program.points}pts </span> -{" "}
                            {percent.toFixed(0)}%
                          </p>
                        </div>
                      </div>
                      <div className={" points-info"}>
                        {/* <div className={"mr-auto"}>
                            {
                              pointsExtra > 0 ? <p className={"mb-0"}>Te faltan <span>{pointsExtra}pts </span>
                                  para subir a nivel {next_level && <span>{next_level.title}</span>}</p> :
                                <p className={"mb-0"}>Sigue acumulando puntos para obtener mejores beneficios</p>
                            }

                          </div> */}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    className={" col-lg-6 col-md-8"}
                    style={{ height: "200px" }}
                  ></div>
                )}
              </div>
            }
          </div>
        ) : retrieveStatus === STATUS_LOADING ||
          addStatus === STATUS_LOADING ? (
          <div className={"container loading"}>
            <div className={"banner row"}>
              <div className={"card col-lg-6 col-md-8  d-none "}>
                <div className={"row"}>
                  <div className={"mr-auto"}>
                    <div className={"circle"} />
                  </div>
                  <div className={"col level-info"}>
                    <span />
                    <p />
                  </div>
                </div>
                <div className={"row"}>
                  <div className={"bar col-12"} />
                </div>
                <div className={"row points-info"}>
                  <p />
                </div>
              </div>
            </div>
          </div>
        ) : retrieveStatus === STATUS_INCLUDE ? (
          <div className={"container "}>
            <div
              className={"banner bannerPrograms row"}
              style={{ backgroundImage: `url(${cover_app})`}}
            >
              {/*
                    <div className={"card col-lg-6 col-md-8"}>


                        <div className={"col level-info"}>
                          <span>Programa de Beneficios</span>
                          <p>Usted aún no se encuentra dentro del programa de fidelidad de {name}. Únete y disfruta de descuentos, regalos, sorteos y mucho más. </p>
                          <p>
                            <button
                              onClick={e => { addProgramUser()}}
                              className={"btn btn-primary"}>¡ Quiero unirme !</button>
                          </p>
                        </div>
                      </div>
                         */}
            </div>
          </div>
        ) : (
          <Fragment />
        )}
      </div>
    );
  }
}

ProgramBanner.defaultProps = {
  program: null,
  app: null,
  retrieveStatus: "",
};

ProgramBanner.propTypes = {
  program: PropTypes.object,
  app: PropTypes.object,
  getProgram: PropTypes.func,
  retrieveStatus: PropTypes.string,
};
