import React, { Fragment } from "react";
import PropTypes from "prop-types";
import bg from "../../common/images/background.jpg";
import ReactImageAppear from "react-image-appear";
import { STATUS_LOADING, STATUS_SUCCESS } from "../../common/utils/generic";
import { BTN_LOGIN, BTN_LOGIN_LOADING } from "./constants";
import Icon from "../../common/icons/icon";
import "./style.scss";
import { Link } from "react-router-dom";
import arrow_down from "../../common/images/downarrow.png";
import Footer from "../footer/Footer";
import { CART_INVISIBLE } from "../../actions/cart";
import { toast } from "react-toastify";
import firebase from "firebase";
import Select from "react-select";
import downarrow from "../../common/images/downarrow.png";
import GoogleLoginButton from "./GoogleLoginButton";


export default class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      mobile_number: '',

      code: '',
      country_code_number: '',

      document_type: "NATIONAL_ID",
      type_text: "Documento de Identidad *",
      document_number: "",
      login_only_document: false,
      login_email: false,
      login_mobile_number: false,
      selected_login_type: "",
      login_with_google: false,
      toFooterRedirect: "",
      captcha_token: null
    };
  }
  componentDidMount() {
    let {
      app: { signin_methods },
      reset,
    } = this.props;
    console.log(signin_methods, "signin_methods")

    if(this.state.selected_login_type==="" && signin_methods.length!==0){
      let login_email = false
      let login_mobile_number = false
      let login_only_document = false
      signin_methods.map(s=>{
        if(s.type==="EMAIL" && !this.state.login_email){
          this.setState({login_email: true})
          login_email = true
        }
        if(s.type==="MOBILE_NUMBER" && !this.state.login_mobile_number){
          this.setState({login_mobile_number: true})
          login_mobile_number = true
        }
        if(s.type==="ONLY_DOCUMENT" && !this.state.login_only_document){
          this.setState({login_only_document: true})
          login_only_document = true
        }
        return true
      })
      if(login_email){
        this.setState({selected_login_type: "EMAIL"})
      }else{
        if(login_mobile_number){
          this.setState({selected_login_type: "MOBILE_NUMBER"})
        }else{
          if(login_only_document){
            this.setState({selected_login_type: "ONLY_DOCUMENT"})
          }
        }
      }
    }

    if (
      localStorage.getItem("toFooterRedirect") &&
      localStorage.getItem("toFooterRedirect").length !== 0
    ) {
      this.setState({
        toFooterRedirect: localStorage.getItem("toFooterRedirect"),
      });
      localStorage.removeItem("toFooterRedirect");
    }
    reset();
  }

  recaptchaRules = () => {
    let {
      app: {recaptcha_settings}
    } = this.props;
    console.log(recaptcha_settings)

    if (recaptcha_settings && recaptcha_settings.site_key) {
      const verifyCallback = (response) => {
        this.setState({captcha_token: response});
      };

      const expiredCallback = () => {
        this.setState({captcha_token: null});
      };

      const onloadCallback = _ => {
        window.grecaptcha.ready(() => {
          window.grecaptcha.render('recaptcha', {
            'sitekey': recaptcha_settings.site_key,
            'callback': verifyCallback,
            'expired-callback': expiredCallback
          });
        })
      };

      const script = document.createElement("script");
      script.src = "https://www.google.com/recaptcha/api.js?render=explicit&hl=es";
      script.addEventListener("load", onloadCallback);
      document.body.appendChild(script);
    }
  }

  onSubmitForm = async (event) => {
    let {
      loginUser,
      validateUser,
      app: { recaptcha_settings },
    } = this.props;

    let {
      selected_login_type
    } = this.state;

    event.preventDefault();
    let payload = {};

    if (recaptcha_settings && recaptcha_settings.site_key && this.state.captcha_token == null) {
      toast.info("Haz click en No soy un robot");
      return;
    }
    if (recaptcha_settings === null) {
      delete payload.captcha_token
    } else {
      payload.captcha_token = this.state.captcha_token
    }
    console.log("payload.captcha_token", payload.captcha_token)

    if (selected_login_type==="EMAIL") {
      if (this.state.email.length === 0) {
        toast.error("Por favor digite un correo electrónico válido")
        return;
      }
      payload.email = this.state.email.toLowerCase()
      payload.password = this.state.password
      delete payload.document_number
      delete payload.document_type
      delete payload.mobile_number
    } else{
      if (selected_login_type==="ONLY_DOCUMENT") {
        if (this.state.document_type.length === 0) {
          toast.error("Por favor seleccion un tipo de documento")
          return;
        }
        if (this.state.document_number.length === 0) {
          toast.error("Por favor digite un númeor de documento")
          return;
        }
        payload.document_number = this.state.document_number
        payload.document_type = this.state.document_type
        delete payload.mobile_number
        delete payload.email
        delete payload.password
      }else{
        if (selected_login_type==="MOBILE_NUMBER") {
          if (this.state.mobile_number.length === 0) {
            toast.error("Por favor digite un teléfono válido")
            return;
          }
          payload.mobile_number = this.state.code + this.state.mobile_number
          payload.password = this.state.password
          delete payload.document_number
          delete payload.document_type
          delete payload.email
        }
      }
    }

    if(payload.document_number){
      payload.document_number = payload.document_number.toUpperCase()
    }
    if (this.state.toFooterRedirect.length !== 0) {
      localStorage.setItem("toFooterRedirect", this.state.toFooterRedirect)
    }
    localStorage.setItem("redirectLanding", "true")
    if (selected_login_type==="EMAIL" || selected_login_type==="MOBILE_NUMBER" ) {
      loginUser(payload);
    } else if (selected_login_type==="ONLY_DOCUMENT") {
      validateUser(payload)
    }
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    let {
      history,
      userBand,
      setUserBand,
      app: { login_only_document, recaptcha_settings },
      validateUserStatus,
      errorUserValidate,
      validate,
      cleanValidateUser,
      loginUser
    } = this.props;

    /*
    if(login_only_document && errors && errors.has_valid_member){
      if(userBand)
        history.push('/register')
      else
        setUserBand({document_type: this.state.document_type, document_number: this.state.document_number})
    }*/

    if (login_only_document && validateUserStatus === STATUS_SUCCESS) {
      if (validate.is_registered) {
        let payload = {};
        if (recaptcha_settings === null) {
          delete payload.captcha_token;
        } else {
          payload.captcha_token = this.state.captcha_token;
        }
        payload.document_number = this.state.document_number ? this.state.document_number.toUpperCase() : "";
        payload.document_type = this.state.document_type;
        loginUser(payload);
      } else if (validate.can_register) {
        if (userBand) history.push("/register");
        else
          setUserBand({
            document_type: this.state.document_type,
            document_number: this.state.document_number ? this.state.document_number.toUpperCase() : "",
          });

        history.push("/register");
      } else {
        toast.error(
          errorUserValidate.length !== 0
            ? errorUserValidate[0]
            : "Ocurrió un error al ingresar"
        );
      }
      cleanValidateUser();
    }
  }

  setFormValues = (propertiesToUpdate) => {
    this.setState(propertiesToUpdate);
    //this.validator.validate(propertiesToUpdate);
  };

  renderError(errors, fieldName) {
    return (
      errors &&
      errors[fieldName] && (
        <span className="field-error-message">{errors[fieldName][0]}</span>
      )
    );
  }

  selectDocumentType = (e, name, document_type) => {
    e.preventDefault();
    this.setState({ type_text: name, document_type: document_type });
  };



  render() {
    let {
      authStatus,
      errors,
      app: {
        company,
        company: { logo, cover },
        login_facebook,
        login_ios,
        allow_anonymous_navigation,
        recaptcha_settings,
        web_settings,
        site_firebase_settings,
        signin_methods,
        site_name,
        countries,
        allow_signup
      },
      loginUserGoogle,
    } = this.props;

    let {
      login_only_document,
      login_email,
      login_mobile_number,
      selected_login_type
    } = this.state

    let logo_app = logo ? logo : null;
    let cover_app = cover ? cover : bg;
    let mainColor = localStorage.getItem("colors_main_color") ? localStorage.getItem("colors_main_color") : "#1b4bc6"


    let allow_singin = 0

    if(login_email){
      allow_singin += 1
    }
    if(login_mobile_number){
      allow_singin += 1
    }
    if(login_only_document){
      allow_singin += 1
    }

    let listCountriesPhone = countries
    let options = []
    let defaultValue = null
    listCountriesPhone.sort(function (o1,o2) {
      if (o1.name > o2.name) { //comparación lexicogŕafica
        return 1;
      } else if (o1.name < o2.name) {
        return -1;
      }
      return 0;
    });


    listCountriesPhone.map((c, index) => {
      options.push({value: `+${c.code}`, label: `+${c.code} ${c.name}`})
      if(index===0){
        if(this.state.code===""){
          this.setState({code: `+${c.code}`, country: `${c.name}`})
        }
        defaultValue = {value: `+${c.code}}`, label: `+${c.code} ${c.name}`}
      }
      return true
    })

    const login = () => {

      let provider = new firebase.auth.GoogleAuthProvider();
      console.log(provider, "provider")
      firebase
        .auth()
        .signInWithPopup(provider)
        .then((result) => {
          loginUserGoogle({"access_token": result.credential.accessToken})
          console.log(result, "result");
        });
    }

    const handleLoginSuccess = (userData, accessToken) => {
      console.log('Login success:', userData);
      console.log('Access Token:', accessToken);
      loginUserGoogle({"access_token": accessToken})
    };

    const handleLoginFailure = () => {
      console.log('Login failed');
    };

    let coverImage = {
      backgroundImage: "url(" + cover_app + ")"
    };

    console.log(cover_app, "cover_app")


    return (
      <Fragment>
        <div className={"rendered-content"}>
          <div className="elevation-container login-section">
            {allow_anonymous_navigation && false && (
              <Link to={"/"} className={"btn btn-outline-danger btn-close"}>
                Cerrar
              </Link>
            )}
            <div className="login col-auto">
              <div className="box-form">
                <form className="form" onSubmit={this.onSubmitForm}>
                  {logo_app !== null && (
                    <ReactImageAppear
                      showLoader={false}
                      placeholder={false}
                      animationDuration="1s"
                      placeholderClass={"placeholder mb-2"}
                      src={logo_app}
                      className="logo"
                      alt="logo"
                    />
                  )}
                  <h2 className={"mb-2"}>Iniciar sesión</h2>
                  {login_facebook && (
                    <button className="btn btn-facebook">
                      <Icon
                        svg="facebook"
                        classes="svg-icon facebook"
                        title="Facebook"
                      />
                      <span>Ingresa con Facebook </span>
                    </button>
                  )}
                  {login_ios && (
                    <button className="btn btn-apple">
                      <Icon
                        svg="apple"
                        classes="svg-icon apple"
                        title="Facebook"
                      />
                      <span>Ingresa con Apple</span>
                    </button>
                  )}
                  {(login_facebook || login_ios) && <p>o</p>}
                  <div className={allow_singin>1 ? "d-flex justify-content-between mb-4 mt-4" : "d-none"}
                       style={signin_methods.length!==3 ? {width: "60%"} : {width: "90%"}}>
                    {
                      login_email &&
                      <span className={`badge badge-pill badge-primary ${selected_login_type==="EMAIL" ? "active" : ""}`}
                            onClick={()=>{this.setState({selected_login_type:"EMAIL"})}}>Email</span>
                    }
                    {
                      login_mobile_number &&
                      <span className={`badge badge-pill badge-primary ${selected_login_type==="MOBILE_NUMBER" ? "active" : ""}`}
                            onClick={()=>{this.setState({selected_login_type:"MOBILE_NUMBER"})}}>Teléfono</span>
                    }
                    {
                      login_only_document &&
                      <span className={`badge badge-pill badge-primary ${selected_login_type==="ONLY_DOCUMENT" ? "active" : ""}`}
                            onClick={()=>{this.setState({selected_login_type:"ONLY_DOCUMENT"})}}>Documento</span>
                    }
                  </div>


                  {
                    selected_login_type==="EMAIL" &&
                    <Fragment>
                      <div className="form-group p-0">
                        <div
                          className="input-group"

                        >
                          <input
                            className="form-control"
                            id="username"
                            type="email"
                            placeholder="Correo Electrónico*"
                            value={this.state.email}
                            onChange={e => this.setFormValues({email: e.target.value})}
                            disabled={authStatus === STATUS_LOADING}
                          />

                        </div>
                        {this.renderError(errors, 'email')}
                      </div>
                      <div className="form-group  p-0">
                        <div>
                          <div
                            className="input-group"

                          >
                            <input
                              className="form-control"
                              id="password"
                              type="password"
                              placeholder="Contraseña*"
                              value={this.state.password}
                              onChange={e => this.setFormValues({password: e.target.value})}
                              disabled={authStatus === STATUS_LOADING}
                            />
                          </div>
                          {this.renderError(errors, 'password')}
                        </div>
                      </div>
                    </Fragment>
                  }
                  {
                    selected_login_type==="MOBILE_NUMBER" &&
                    <Fragment>
                      <div className="form-group p-0">
                        <div className="input-group">
                          <div className={"form-group col-12 col-md-4 pl-0 pr-0 mb-0"}>
                            <Select options={options}
                                    onChange={e => {
                                      this.setState({
                                        code: e.value,
                                        country_code_number: e.value
                                      });
                                    }}
                                    defaultValue={defaultValue}
                            />
                            <img src={downarrow} alt="downarrow" className={"down-phone-number back"} style={{width: 20, height: 20}}/>
                            <img src={downarrow} alt="downarrow" className={"down-phone-number"} style={{width: 20, height: 20}}/>
                          </div>

                          <div
                            className="input-group col-12 col-md-8 pr-0"
                          >
                            <input
                              className="form-control"
                              id="mobile_number"
                              type="text"
                              placeholder="Teléfono*"
                              value={this.state.mobile_number}
                              onChange={e => this.setFormValues({mobile_number: e.target.value})}
                              disabled={authStatus === STATUS_LOADING}
                            />
                          </div>
                        </div>
                        {this.renderError(errors, 'mobile_number')}
                      </div>




                      <div className="form-group  p-0">
                        <div>
                          <div
                            className="input-group"

                          >
                            <input
                              className="form-control"
                              id="password"
                              type="password"
                              placeholder="Contraseña*"
                              value={this.state.password}
                              onChange={e => this.setFormValues({password: e.target.value})}
                              disabled={authStatus === STATUS_LOADING}
                            />
                          </div>
                          {this.renderError(errors, 'password')}
                        </div>
                      </div>
                    </Fragment>
                  }
                  {
                    selected_login_type==="ONLY_DOCUMENT" &&
                    <Fragment>
                      <div className="form-group p-0 dropdown mt-3">
                        <div
                          className="form-control"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <span style={{display: "block", marginTop: "10px"}}>{this.state.type_text}</span>
                          <img src={arrow_down} alt="" style={{display: "block", marginTop: "5px"}}
                               className={"arrow-down"}/>
                        </div>
                        <div
                          className="dropdown-menu"
                          style={{
                            width: "100%",
                            overflowY: "auto",
                            maxHeight: 200
                          }}
                        >
                          <a
                            href={"/"}
                            className="dropdown-item dropdown-type select-type"
                            onClick={e =>
                              this.selectDocumentType(
                                e,
                                "Documento de Identidad",
                                "NATIONAL_ID"
                              )
                            }
                          >
                            Documento de Identidad
                          </a>
                          <a
                            href={"/"}
                            className="dropdown-item dropdown-type select-type"
                            onClick={e =>
                              this.selectDocumentType(
                                e,
                                "Pasaporte",
                                "PASSPORT"
                              )
                            }
                          >
                            Pasaporte
                          </a>
                          <a
                            href={"/"}
                            className="dropdown-item dropdown-type select-type"
                            onClick={e =>
                              this.selectDocumentType(
                                e,
                                "Carnet de Extranjería",
                                "FOREIGNER_ID"
                              )
                            }
                          >
                            Carnet de Extranjería
                          </a>
                          <a
                            href={"/"}
                            className="dropdown-item dropdown-type select-type"
                            onClick={e =>
                              this.selectDocumentType(e, "Ruc", "TAX_ID")
                            }
                          >
                            Ruc
                          </a>
                          <a
                            href={"/"}
                            className="dropdown-item dropdown-type select-type"
                            onClick={e =>
                              this.selectDocumentType(e, "Otro", "OTHER")
                            }
                          >
                            Otro
                          </a>
                        </div>
                      </div>
                      <div className="form-group p-0">
                        <div className="input-group" >
                          <input
                            value={this.state.document_number}
                            className="form-control"
                            onChange={e => this.setFormValues({document_number: e.target.value})}
                            type="text"
                            placeholder={this.state.type_text === "Otro *" ? `${this.state.type_text}` : `Número de documento`}
                            disabled={authStatus === STATUS_LOADING}
                          />
                        </div>
                        {this.renderError(errors, 'document_number')}
                      </div>
                    </Fragment>
                  }
                  {errors && errors.non_field_errors && (
                    <div style={{ marginTop: 15, marginBottom: 15 }}>
                      {this.renderError(errors, "non_field_errors")}
                    </div>
                  )}
                  {recaptcha_settings && recaptcha_settings.site_key.length !== 0 ? <div id="recaptcha"/> : null}
                  {(login_email || login_only_document || login_mobile_number) && (
                    <Fragment>
                      <div
                        className={
                          (login_email && login_mobile_number) ? `submits email` : "submits document"
                        }
                      >
                        {/* {login_email && (
                          <button
                            type="button"
                            className="btn btn-register btn-secondary"
                            onClick={() =>
                              this.props.history.push("/register/")
                            }
                          >
                            Registrarme
                          </button>
                        )} */}

                        <button
                          type="submit"
                          disabled={authStatus === STATUS_LOADING}
                          className="btn btn-login btn-primary "
                          style={{backgroundColor: `${mainColor}`, border: `2px solid ${mainColor}`}}
                        >
                          {authStatus === STATUS_LOADING
                            ? BTN_LOGIN_LOADING
                            : BTN_LOGIN}
                        </button>
                      </div>
                      <hr className="line-login d-none" />

                      <br/>
                      <div className={(site_name !== "RewardsSite" || (company && !company.name.toLowerCase().includes("fastfox"))) ? "d-none" : "d-flex justify-content-center"}>
                        <GoogleLoginButton onSuccess={handleLoginSuccess} onFailure={handleLoginFailure}/>
                      </div>
                      <br/>

                      {site_firebase_settings && false && (
                        <div className="google-btn" onClick={login}>
                          <div className="google-icon-wrapper">
                            <img
                              className="google-icon"
                              src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg"
                              alt="logo-google"
                            />
                          </div>
                          <p className="btn-text">
                            <b>Continúa con Google</b>
                          </p>
                        </div>
                      )}

                      {(allow_signup) && (
                        <h4 className="register-login">
                          No tienes cuenta?{" "}
                          <span
                            onClick={() =>
                              this.props.history.push("/register/")
                            }
                          >
                            REGÍSTRATE
                          </span>{" "}
                        </h4>
                      )}
                      {(login_email || login_mobile_number) &&  (
                        <h4 className="register-login">
                          <Link
                            to="/recovery-password"
                            className="register-login"
                          >
                            Olvidé mi contraseña
                          </Link>
                        </h4>
                      )}
                    </Fragment>
                  )}
                </form>
              </div>
            </div>
            {
              web_settings && web_settings.external_link ?
                <a className="publicity col-auto" href={web_settings && web_settings.external_link} target="_blank"
                   rel="noopener noreferrer">
                  <ReactImageAppear
                    className="cover"
                    placeholderClass={"placeholder"}
                    src={cover_app}
                    showLoader={false}
                    animationDuration="1s"
                  />
                </a>
                :
                <div className={"cover"} style={coverImage}>

                </div>
            }
          </div>
        </div>
        <Footer stateCart={CART_INVISIBLE}></Footer>
      </Fragment>
    );
  }
}

Login.defaultProps = {
  authStatus: "",
};

Login.propTypes = {
  dispatch: PropTypes.func,
  authStatus: PropTypes.string,
};
