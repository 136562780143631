import React, {Fragment} from 'react'
import './style.scss';
import PropTypes from "prop-types";
import OrderItem from "./OrderItem";
import {STATUS_LOADING} from "../../../../common/utils/generic";

export default class OrderTable extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedOrder: null,
    };
  }

  previous() {
    const {getNumberPage, pageCurrent, setPageCurrent} = this.props
    setPageCurrent(pageCurrent - 1)
    getNumberPage(pageCurrent - 1)
  }

  next() {
    const {getNumberPage, pageCurrent, setPageCurrent} = this.props
    setPageCurrent(pageCurrent + 1)
    getNumberPage(pageCurrent + 1)
  }

  componentDidMount() {
    let {placeCurrent} = this.props
    if (placeCurrent) {
      this.props.getOrders();
    }
  }

  renderStatus = (status) => {
    switch (status) {
      case "received":
        return "Orden recibida";
      case "delivered":
        return "Orden entregada";
      case "canceled_by_place":
        return "Orden Cancelada";
      case "canceled_by_user":
        return "Orden Cancelada por el usuario";
      case "accepted":
        return "Orden aceptada";
      case "Aceptada":
        return "Orden aceptada";
      case "shipped":
        return "Orden enviada";
      default:
        return '--'
    }
  }
  setSelectedOrder = (selectedOrder) => {
    this.setState({selectedOrder: selectedOrder})
    this.props.openPopUp(selectedOrder.uid)
  }

  renderOrders() {
    const {orders, listOrdersStatus, count,listCoins} = this.props;
    if (listOrdersStatus === STATUS_LOADING) {
      return <Fragment>
        <OrderItem loading={true}/>
      </Fragment>;
    }
    if (count === 0) {
      return (
        <div className={"mt-4 mb-4"}>
          <p className={"font-weight-bold"}>No se encontraron pedidos</p>
        </div>
      );
    }
    const {
      getNextOrders, retrieveStatus, editStatus, retrieveOrder, updateOrder, cleanOrderStatus, formInitial
    } = this.props;

    return orders.map((order, index) => (
      <OrderItem key={`order${index}`} count={count} order={order}
                 retrieveOrder={retrieveOrder}
                 updateOrder={updateOrder}
                 cleanOrderStatus={cleanOrderStatus}
                 getNextOrders={getNextOrders}
                 retrieveStatus={retrieveStatus}
                 editStatus={editStatus}
                 loading={false}
                 setSelectedOrder={(order) => this.setSelectedOrder(order)}
                 formInitial={formInitial}
                 listCoins={listCoins}
      />
    ));
  }

  renderPagination() {
    const {count, getNumberPage, pageCurrent, setPageCurrent} = this.props
    let nPage = Math.ceil(count / 25)
    let pages = [];

    for (let i = 1; i < nPage + 1; i++) {
      if (nPage + 1 > 10) {
        if (pageCurrent === i) {
          if (pageCurrent === 1) {
            pages.push(1)
            pages.push(2)
            pages.push(3)
            pages.push("...")
            pages.push(nPage - 2)
            pages.push(nPage - 1)
            pages.push(nPage)
          }
          if (pageCurrent === 2) {
            pages.push(1)
            pages.push(2)
            pages.push(3)
            pages.push(4)
            pages.push("...")
            pages.push(nPage - 2)
            pages.push(nPage - 1)
            pages.push(nPage)
          }
          if (pageCurrent === 3) {
            pages.push(1)
            pages.push(2)
            pages.push(3)
            pages.push(4)
            pages.push(5)
            pages.push("...")
            pages.push(nPage - 2)
            pages.push(nPage - 1)
            pages.push(nPage)
          }
          if (pageCurrent === 4) {
            pages.push(1)
            pages.push(2)
            pages.push(3)
            pages.push(4)
            pages.push(5)
            pages.push(6)
            pages.push("...")
            pages.push(nPage - 2)
            pages.push(nPage - 1)
            pages.push(nPage)
          }
          if (pageCurrent === 5) {
            pages.push(1)
            pages.push(2)
            pages.push(3)
            pages.push(4)
            pages.push(5)
            pages.push(6)
            pages.push(7)
            pages.push("...")
            pages.push(nPage - 2)
            pages.push(nPage - 1)
            pages.push(nPage)
          }
          if (pageCurrent > 5 && pageCurrent < nPage - 2) {
            pages.push(1)
            pages.push(2)
            pages.push("...")
            pages.push(pageCurrent - 2)
            pages.push(pageCurrent - 1)
            pages.push(pageCurrent)
            pages.push(pageCurrent + 1)
            pages.push(pageCurrent + 2)
            pages.push("...")
            pages.push(nPage - 2)
            pages.push(nPage - 1)
            pages.push(nPage)
          }
          if (pageCurrent === nPage - 2) {
            pages.push(1)
            pages.push(2)
            pages.push(3)
            pages.push("...")
            pages.push(pageCurrent - 1)
            pages.push(pageCurrent)
            pages.push(pageCurrent + 1)
            pages.push(pageCurrent + 2)
          }
          if (pageCurrent === nPage - 1) {
            pages.push(1)
            pages.push(2)
            pages.push(3)
            pages.push("...")
            pages.push(pageCurrent - 1)
            pages.push(pageCurrent)
            pages.push(pageCurrent + 1)
          }
          if (pageCurrent === nPage) {
            pages.push(1)
            pages.push(2)
            pages.push(3)
            pages.push("...")
            pages.push(pageCurrent - 2)
            pages.push(pageCurrent - 1)
            pages.push(pageCurrent)
          }
        }
      } else {
        pages.push(i)
      }
    }

    for (let i = pages.length - 1; i >= 0; i--) {
      if (pages.indexOf(pages[i]) !== i) {
        if (pages[i] !== "...") {
          pages.splice(i, 1);
        }
      }
    }

    return pages.map((page, index) => (
      <li key={`page${index}`}><a href={"/#"} key={`page${index}`}
                                  className={page === pageCurrent ? "active" : ""}
                                  onClick={(e) => {
                                    e.preventDefault()
                                    if (page !== pageCurrent && page !== "...") {
                                      setPageCurrent(page)
                                      getNumberPage(page)
                                    }
                                  }}
      >{page}</a></li>
    ));

  }

  render() {
    let {previousPage, nextPage, pageCurrent} = this.props
    return (
      <Fragment>
        <div className={"section"}>
          <div className={"title-bar-section"}>
            <div className="container">
              <h6 className={"mx-auto col-lg-10 col-md-12"}>Mis pedidos</h6>
            </div>
          </div>
          <div className="container">
            <div className={"mx-auto col-lg-10 col-md-12"}>
              {this.renderOrders()}
            </div>
          </div>
          <div className={"d-flex justify-content-center mt-1 mb-1"}>
            <ul className="pagination">
              <li>
                <button className={(previousPage === null || pageCurrent === 1) ? "is-disabled" : ""}
                        disabled={previousPage === null} onClick={() => this.previous()}> {"<"} </button>
              </li>
              {this.renderPagination()}
              <li>
                <button className={nextPage === null ? "is-disabled" : ""} disabled={nextPage === null}
                        onClick={() => this.next()}> {">"} </button>
              </li>
            </ul>
          </div>
        </div>


      </Fragment>
    );
  }
}

OrderTable.defaultProps = {
  subscriptions: [],
  listSubscriptionsStatus: '',
  count: 0,
  nextPageSubscriptions: null
};

OrderTable.propTypes = {
  subscriptions: PropTypes.array,
  nextPageSubscriptions: PropTypes.string,
  getMoreSubscriptions: PropTypes.func,
  listNextSubscriptionsStatus: PropTypes.string,
  count: PropTypes.number,
  listSubscriptionsStatus: PropTypes.string
};