import React, {Fragment} from 'react'
import './style.scss';
import Subscription from "./component/Subscription";
import {STATUS_ERROR, STATUS_LOADING, STATUS_SUCCESS} from "../../../../common/utils/generic";
import InfiniteScroll from "react-infinite-scroller";
import PropTypes from "prop-types";
import NoSearchResult from "../../../../common/images/NoSearchResult.svg";
import SubscriptionPopUp from "./component/SubscriptionPopUp";

export default class SubscriptionList extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      subscriptionsCurrent: [],
      initialLoaded: false,
      submitRefresh: false,
      subscriptionUid: "",
    }
    this.cancelSubscription = this.cancelSubscription.bind(this);
  }

  loadMore = () => {
    const {nextPageSubscriptions, listNextSubscriptionsStatus, listSubscriptionsStatus, getMoreSubscriptions} = this.props;
    if (listNextSubscriptionsStatus !== STATUS_LOADING && listSubscriptionsStatus !== STATUS_LOADING  && nextPageSubscriptions != null) {
      getMoreSubscriptions(nextPageSubscriptions);
    }
  };


  componentDidUpdate(prevProps, prevState, snapshot) {
    let {subscriptions, listSubscriptionsStatus} = this.props
    if(listSubscriptionsStatus===STATUS_SUCCESS){
      if(!this.compare(this.state.subscriptionsCurrent, subscriptions)){
        this.setState({
          subscriptionsCurrent: subscriptions
        })
      }
    }
  }


  compare = (obj1,obj2) => {
    return JSON.stringify(obj2)===JSON.stringify(obj1)
  }

  cancelSubscription(subscriptionUid) {
    this.setState({subscriptionUid: subscriptionUid})
  }

  renderPopUp = () => {
    let {canceledSubscription} = this.props;
    if (this.state.subscriptionUid !== "") {
      return (
        <SubscriptionPopUp
          subscriptionUid={this.state.subscriptionUid}
          closePopup={()=>{this.setState({subscriptionUid:""})}}
          canceledSubscription={canceledSubscription}
        />
      );
    }
  }

  renderSubscriptionList() {
    const { listSubscriptionsStatus, count} = this.props;

    if (count === 0 && (listSubscriptionsStatus === STATUS_SUCCESS || listSubscriptionsStatus === STATUS_ERROR)) {
      return <div className="content-no-items">
        <img src={NoSearchResult} alt="noItems"/>
        <p className={"mt-3"}>Usted no tiene ninguna suscripción</p>
      </div>
     }
    let subscriptions = []

    if(this.state.subscriptionsCurrent.length!==0){
      subscriptions = this.state.subscriptionsCurrent
    }
    return subscriptions.map((subscription, index) => (
      <Subscription key={`promo${index}`} subscription={subscription}
                    canceledSubscription={(uid)=>this.cancelSubscription(uid)} loading={false}/>
    ));
  }


  render() {
    let {nextPageSubscriptions} = this.props;
    let more = nextPageSubscriptions != null;
    let loaderMore = (
      <Fragment key={"load"}>
        <Subscription loading={true}/>
        <Subscription loading={true}/>
        <Subscription loading={true} />
      </Fragment>

    );
    return (
      <Fragment>
        <div className={"container subscriptions"}>
        <h4>Historial de suscripciones</h4>
        <p>Estos son los pedidos que ha realizado desde que creo su cuenta</p>
        <InfiniteScroll
          className="entities-section "
          pageStart={0}
          loadMore={this.loadMore.bind(this)}
          hasMore={more}
          loader={loaderMore}
        >
          {this.renderSubscriptionList()}
        </InfiniteScroll>

      </div>
        {
          this.renderPopUp()
        }
      </Fragment>
    );
  }
}

SubscriptionList.defaultProps = {
  subscriptions: [],
  listSubscriptionsStatus: '',
  count: 0,
  nextPageSubscriptions: null
};

SubscriptionList.propTypes = {
  subscriptions: PropTypes.array,
  nextPageSubscriptions: PropTypes.string,
  getMoreSubscriptions: PropTypes.func,
  listNextSubscriptionsStatus: PropTypes.string,
  count: PropTypes.number,
  listSubscriptionsStatus: PropTypes.string
};