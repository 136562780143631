import React from 'react'
import PropTypes from 'prop-types';
import './style.scss';
import {STATUS_LOADING, STATUS_SUCCESS} from "../../../common/utils/generic";
import Arrow from "../../../common/images/IconArrowPlace.svg";
const $ = require('jquery');

export default class ProductsPlaceFilters extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      search: '',
      typing: false,
      typingTimeout: 0,
      sectionsText: 'Seleccionar tus categorias',
      sections: [{uid: "000", name: "Todo", selected: false}],
      showArrow: false
    }
    this.searchTerm = this.searchTerm.bind(this);

    localStorage.setItem('sectionSearchProduct', '');
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if(document.getElementById("categories-list").scrollLeftMax>0 && !this.state.showArrow){
      this.setState({showArrow: true})
    }
  }

  searchTerm = event => {
    const self = this;

    if (self.state.typingTimeout) {
      clearTimeout(self.state.typingTimeout);
    }
    let that = this;
    self.setState({
      search: event.target.value,
      typing: false,
      typingTimeout: setTimeout(function () {
        that.searchProductPlace()
      }, 800)
    });
  };
  searchProductPlace = () => {
    if(!this.state.typing){
      this.getProductPlace()
      this.setState({typing:true})
    }
  }
  renderCategories = () => {
    return this.state.sections.map((category, index) => (<div
      key={`category${index}`}
      className={`dropdown-item dropdown-type select-type ${category.selected ? 'selected' : ''}`}
      onClick={_ =>
        this.selectCategory(
          category, index
        )
      }
    >
      {category.name}
    </div>));
  }

  getProductPlace = () => {
    let {getProductsByPlace} = this.props
    let { search} = this.state

    getProductsByPlace({product_category: localStorage.getItem('sectionSearchProduct'), search: search, is_redeemable:false})
  }

  scrollLeft = (e) => {
    e.preventDefault()
    $('#categories-list').animate({
      scrollLeft: "+=200px"
    }, "slow");
  }

  selectCategory = (section, i) => {

    let {sections, sectionsText} = this.state
    let text = 'Seleccionar una categoría'

    if (sections[i]) {
      //TODO section.selected = !section.selected for multiple selection
      section.selected = true
      if (section.selected) {
        for (let i = 0; i < sections.length; i++) {
          let c = sections[i]
          if (c.uid !== section.uid && c.selected) {
            c.selected = false
            sections[i] = c
          }
        }
        text = `Categoría: ${section.name}`
      }
      sections[i] = section
    }

    this.setState({sectionsText: text, sections: sections})
    let sectionsParams = ""
    for (let i = 0; i < sections.length; i++) {
      if (sections[i].uid !== "000" && sections[i].selected) {
        if (sectionsParams.length > 0)
          sectionsParams += ","
        sectionsParams += `${sections[i].uid}`
      }
    }

    localStorage.setItem('sectionSearchProduct', sectionsParams);

    if(text!==sectionsText){
      this.getProductPlace();
    }
  }

  static getDerivedStateFromProps(props, state) {
    const {
      sections, listSectionsStatus
    } = props;
    if (listSectionsStatus === STATUS_LOADING && sections!==null && sections!==undefined ) {
      return {
        ...state,
        sections: [{uid: "000", name: "Todo", selected: false}]
      }
    }
    if (listSectionsStatus === STATUS_SUCCESS && sections!==null && sections!==undefined) {
      return {
        ...state,
        sections: [{uid: "000", name: "Todo", selected: false}, ...sections.map(s => ({
          uid: s.uid,
          name: s.name,
          image: s.image,
          color: s.color,
          selected: false
        }))]
      }
    }
    return state;
  }

  render() {
    let {search} = this.state
    return (
      <div className={"container"}>
        <div className={`row product-place-search-section`}>
          <div id={"products-categories"} className={"products-categories mb-0"}>
            <div id={"categories-list"} className={this.state.sections && this.state.sections.length>4 ? "categories-list" : "categories-list list-flex"}>
              {
                this.state.sections &&
                this.state.sections.map((section, index) => {
                    return (
                      <button key={`${section.name}${index}`}
                              style={{backgroundColor: section.color ? section.color : "#efefef"}}
                              className={`categorie-item ${(localStorage.getItem("sectionSearchProduct")===section.uid) ? 'selected' :
                                localStorage.getItem("sectionSearchProduct")==="" && section.uid === "000"  ? 'selected' :''}`}
                              onClick={_ =>
                                this.selectCategory(
                                  section, index
                                )
                              }>
                        <p>{section.name}</p>
                      </button>
                    )
                  }
                )
              }

            </div>
            <div className={this.state.showArrow ? "arrow-sections" : "d-none"} onClick={e=>this.scrollLeft(e)}>
              <img src={Arrow} alt="arrow"/>
            </div>
          </div>


          <div className={"col-lg-6 search-input d-none"}>
            <input type="text" className="form-control"
                   placeholder="Buscar un producto"
                   aria-label="Buscar"
                   value={search}
                   onKeyPress={event => {
                     if (event.key === 'Enter') {
                       this.searchProductPlace()
                     }
                   }}
                   onChange={e => {
                     this.setState({typing:true})
                     this.searchTerm(e)
                   }}
            />
          </div>

        </div>
      </div>

    );
  }
}

ProductsPlaceFilters.defaultProps = {
  categories: []
};

ProductsPlaceFilters.propTypes = {
  getProductsByPlace: PropTypes.func,
  categories: PropTypes.array
};
