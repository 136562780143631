import React from 'react';
import {Switch} from "react-router-dom";
import {Route} from 'react-router';
import {
  changeStateSecondaryMenu,
  SECONDARY_MENU_BACK,
} from "../../actions/menu";
import {CART_INVISIBLE, changeStateCart} from "../../actions/cart";
import {getRaffles, getRaffle, registerInRaffle, dismissToRaffle} from "../../actions/raffles";
import ListRaffles from "./ListRaffles";
import DetailRaffle from "./DetailRaffles";

export default class RafflesRoot extends React.Component {

  render() {
    const {
      match,
      dispatch,
      app,
      programs: {
        results: programsResults,
        entities: programEntities,
      },
      raffles: {
        results: rafflesResults,
        entities: rafflesEntities,
        listStatus: rafflesStatus,
        registerStatus,
        dismissStatus
      },
      raffles,
      user,
      stateCart,
      company,

      openPopUp,
      activePopUp,
      componentPopUp
    } = this.props;
    return (
      <Switch>
        <Route exact path={`${match.url}`} render={(props) =>
          <ListRaffles
            {...props}
            changeSecondaryMenu={() => dispatch(changeStateSecondaryMenu(SECONDARY_MENU_BACK))}
            changeStateCart={() => dispatch(changeStateCart(CART_INVISIBLE))}
            getRaffles={(uid) => dispatch(getRaffles(uid))}
            raffles={rafflesResults.map(pUid => rafflesEntities[pUid]).filter(r=>r.is_participating===false)}
            stateCart={stateCart}
            app={app}
            company={company}
            programs={programsResults.map(pUid => programEntities[pUid])}
            dismissToRaffle={(programUid, raffleUid) => dispatch(dismissToRaffle(programUid, raffleUid))}
            rafflesStatus={rafflesStatus}
            registerStatus={registerStatus}
            dismissStatus={dismissStatus}
            openPopUp={openPopUp}
            activePopUp={activePopUp}
            componentPopUp={componentPopUp}
            getRaffle={(uidProgram, uidRaffle) => dispatch(getRaffle(uidProgram, uidRaffle))}
            user={user}
            registerInRaffle={(data, programUid, raffleUid) => dispatch(registerInRaffle(data, programUid, raffleUid))}
          />
        }
        />

        <Route exact path={`${match.url}/:uid`} render={(props) =>
          <DetailRaffle
            {...props}
            changeSecondaryMenu={() => dispatch(changeStateSecondaryMenu(SECONDARY_MENU_BACK))}
            changeStateCart={() => dispatch(changeStateCart(CART_INVISIBLE))}
            getRaffle={(uidProgram, uidRaffle) => dispatch(getRaffle(uidProgram, uidRaffle))}
            raffles={raffles}
            stateCart={stateCart}
            app={app}
            user={user}
            programs={programsResults.map(pUid => programEntities[pUid])}
            registerInRaffle={(data, programUid, raffleUid) => dispatch(registerInRaffle(data, programUid, raffleUid))}
            dismissToRaffle={(programUid, raffleUid) => dispatch(dismissToRaffle(programUid, raffleUid))}
          />
        }/>
      </Switch>
    )
  }
}
