import React, {Fragment} from 'react'
import PropTypes from 'prop-types';
import {STATUS_LOADING} from '../../common/utils/generic';
import Validator from '../../common/utils/validator';
import {BTN_REGISTER, BTN_REGISTER_LOADING} from "./constants";
import './style.scss';
import arrow_down from "../../common/images/downarrow.png";
import {toast} from "react-toastify";
import {Link} from "react-router-dom";

import "react-datepicker/dist/react-datepicker.css"
import Footer from "../footer/Footer";
import {CART_INVISIBLE} from "../../actions/cart";
import Select from "react-select";
import downarrow from "../../common/images/downarrow.png";

export default class Register extends React.Component {


  constructor(props) {
    super(props);
    this.state = {
      subdomain: window.location.hostname.split('.')[0],
      document_type: "NATIONAL_ID",
      type_text: "Documento de Identidad",
      document_number: "",
      first_name: "",
      last_name: "",
      email: "",

      mobile_number: '',
      code: '',
      country_code_number: '',

      login_mobile_number: false,

      password: "",
      captcha_token: null,
      accepted_terms_cond: false,
      data_processing_accepted: false,
      data_processing_publicity_accepted: false,
      error: '',
      showErrorFirstTime: false,

      fieldsRequired: [],

      validate_document: true,
      validate_mobile_number: true,
    };
  }


  componentDidUpdate(prevProps, prevState, snapshot) {

    let {authStatus} = this.props;
    if (authStatus === 'error' && this.state.showErrorFirstTime === false) {
      toast.error("Por favor corregir los campos pertinentes");
      this.setState({showErrorFirstTime: true})
    }

  }

  componentDidMount() {
    let {
      userBand,
      reset,
      setUserBand,
      app,
      app: {login_email, login_only_document,
        signin_methods, allow_signup}
    } = this.props;

    console.log(app)
    if(app){
      this.setState({validate_document: app.validate_document, validate_mobile_number: app.validate_mobile_number})
    }

    let login_mobile_number = false
    signin_methods.map(s=>{
      if(s.type==="MOBILE_NUMBER"){
        this.setState({login_mobile_number: true})
        login_mobile_number = true
      }
      return true
    })
    if(!allow_signup){
      this.props.history.push("/login")
    }
    if (login_only_document) {

      let fieldsRequired = [
        {
          id: "email",
          ref: this.emailInput,
          rules: ["required", "email"]
        },
        {
          id: "document_number",
          ref: this.dniInput,
          rules: ["required", "numeric"]
        }
      ]

      if(this.state.validate_mobile_number){
        fieldsRequired.push({
          id: "mobile_number",
          ref: this.mobileNumberInput,
          rules: ["required", "positiveNumber"]
        })
      }

      this.setState({fieldsRequired: fieldsRequired})
      this.validator = new Validator(fieldsRequired)
      if (userBand) {
        //this.recaptchaRules()
        this.setState({
          document_number: userBand.document_number,
          document_type: userBand.document_type,
        })
        setUserBand(null)
      }
    } else{
      let fieldsRequired = [
        {
          id: "first_name",
          ref: this.firstNameInput,
          rules: ["required"]
        },
        {
          id: "last_name",
          ref: this.lastNameInput,
          rules: ["required"]
        },
        {
          id: "email",
          ref: this.emailInput,
          rules: ["required", "email"]
        },
        {
          id: "password",
          ref: this.passwordInput,
          rules: ["required"]
        }
      ]

      if(app.validate_document){
        fieldsRequired.push({
          id: "document_number",
          ref: this.dniInput,
          rules: ["required"]
        })
      }
      if(app.validate_mobile_number){
        fieldsRequired.push({
          id: "mobile_number",
          ref: this.mobileNumberInput,
          rules: ["required", "positiveNumber"]
        })
      }

      if (login_email) {
        if(login_mobile_number){
          if(!app.validate_mobile_number){
            fieldsRequired.push({
              id: "mobile_number",
              ref: this.mobileNumberInput,
              rules: ["required", "positiveNumber"]
            })
          }
        }
        console.log(fieldsRequired, "fieldsRequired2")
        this.validator = new Validator(fieldsRequired);
        this.setState({fieldsRequired: fieldsRequired})
        //this.recaptchaRules()
      }
    }
    reset()
  }

  recaptchaRules = () => {
    let {
      app: {recaptcha_settings}
    } = this.props;
    if (recaptcha_settings && recaptcha_settings.site_key) {
      const verifyCallback = (response) => {
        this.setState({captcha_token: response});
      };

      const expiredCallback = () => {
        this.setState({captcha_token: null});
      };

      const onloadCallback = _ => {
        window.grecaptcha.ready(() => {
          window.grecaptcha.render('recaptcha', {
            'sitekey': recaptcha_settings.site_key,
            'callback': verifyCallback,
            'expired-callback': expiredCallback
          });
        })
      };

      const script = document.createElement("script");
      script.src = "https://www.google.com/recaptcha/api.js?render=explicit&hl=es";
      script.addEventListener("load", onloadCallback);
      document.body.appendChild(script);
    }
  }

  selectDocumentType = (e, name, type_id) => {
    e.preventDefault();
    this.setState({type_text: name, type: type_id});
  };

  setFormValues = (propertiesToUpdate) => {
    this.setState(propertiesToUpdate);
    this.validator.validate(propertiesToUpdate);
  }

  onSubmitForm = async (event) => {
    event.preventDefault();

    let {
      isFetching, registerUser,
      app,
      app: {login_only_document, recaptcha_settings, login_email}
    } = this.props;

    let payload = {...this.state}

    if (recaptcha_settings && recaptcha_settings.site_key && this.state.captcha_token == null) {
      //toast.info("Haz click en No soy un robot");
      //return;
    }
    if (recaptcha_settings === null) {
      delete payload.captcha_token
    }
    if (!this.state.accepted_terms_cond) {
      toast.info("Aceptar los términos y condiciones es obligatorio");
      return;
    }
    if (!this.state.data_processing_accepted) {
      toast.info("Aceptar el tratamiento de datos personales es obligatorio");
      return;
    }
    if (!this.state.data_processing_publicity_accepted) {
      toast.info("Aceptar el tratamiento de datos personales para fines publicitarios es obligatorio");
      return;
    }

      delete payload.data_processing_accepted
      delete payload.data_processing_publicity_accepted

    this.setState({showErrorFirstTime: false})

    delete payload.showErrorFirstTime
    delete payload.subdomain
    delete payload.type_text
    delete payload.fieldsRequired
    delete payload.error
    payload.first_name = payload.first_name.split(" ")[0]
    payload.last_name = payload.last_name.split(" ")[0]

    if (login_only_document) {
      delete payload.first_name
      delete payload.last_name
      delete payload.password
      //payload.document_number = "74716969"
    }
    if(login_email){
      if(payload.email.length===0){
        toast.error("Complete los campos obligatorios")
        return
      }
    }
    if(this.state.login_mobile_number  || this.state.validate_mobile_number){
      if(payload.mobile_number.length===0 || payload.code.length===0){
        toast.error("Complete los campos obligatorios")
        return
      }
      payload.mobile_number = this.state.code + this.state.mobile_number

    }else{
      if(this.state.mobile_number.length===0){
        delete payload.mobile_number
        delete payload.code
      }
    }
    console.log(app)
    if(app){
      if(app.validate_document){
        if(this.state.mobile_number.length===0){
          delete payload.mobile_number
          delete payload.code
        }else{
          payload.mobile_number = this.state.code + this.state.mobile_number
        }
      }
      if(app.validate_mobile_number){
        if(payload.document_number.length===0){
          delete payload.document_type
          delete payload.document_number
        }
      }
    }
    delete payload.login_mobile_number
    delete payload.code
    delete payload.validate_document
    delete payload.validate_mobile_number
    delete payload.country


    if (!isFetching) {
      payload.email = this.state.email.toLowerCase()
      if(payload.document_number && payload.document_number.length!==0){
        payload.document_number = this.state.document_number.toUpperCase()
      }else{
        delete payload.document_number
      }
      registerUser(payload);
      console.log(payload, "pay")
    }
  }

  renderError(errors, fieldName) {
    return errors && errors[fieldName] &&
      <span className='field-error-message'>
        {errors[fieldName][0]}
      </span>;
  }

  render() {
    let {
      authStatus, errors, app: {
        subdomain, login_email, login_only_document,
        countries,
        company: {logo_rectangle, logo},
      }
    } = this.props;
    let mainColor = localStorage.getItem("colors_main_color") ? localStorage.getItem("colors_main_color") : "#20a6ff"

    let logo_app = logo ? logo : logo_rectangle



    let listCountriesPhone = countries
    let options = []
    let defaultValue = null
    listCountriesPhone.sort(function (o1,o2) {
      if (o1.name > o2.name) { //comparación lexicogŕafica
        return 1;
      } else if (o1.name < o2.name) {
        return -1;
      }
      return 0;
    });


    listCountriesPhone.map((c, index) => {
      options.push({value: `+${c.code}`, label: `+${c.code} ${c.name}`})
      if(index===0){
        if(this.state.code===""){
          this.setState({code: `+${c.code}`, country_code_number: `+${c.code}`,country: `${c.name}`})
        }
        defaultValue = {value: `+${c.code}}`, label: `+${c.code} ${c.name}`}
      }
      return true
    })

    console.log(this.state.fieldsRequired, "fieldsRequired")

    let required_mobile_number = this.state.fieldsRequired.filter(s=>s.id==="mobile_number").length!==0
    let required_document = this.state.fieldsRequired.filter(s=>s.id==="document_number").length!==0

    return (
      <div style={{position: "relative"}}>
        <div className={"rendered-content"}>
          <div className={`elevation-container register col-12 col-lg-8 col-md-8 col-sm-10`}
               style={login_only_document ? {margin: '10px auto'} : {margin: '10px auto'}}
          >
            <div className="box-form">
              <form className="form d-flex flex-column" onSubmit={this.onSubmitForm}>
                <img src={logo_app} className="logo" alt="logo"/>
                {
                  login_only_document &&
                  <p style={{
                    textAlign: 'center',
                    width: "83%",
                    margin: "40px auto",
                    fontWeight: 700,
                    fontSize: 15,
                    letterSpacing: 0
                  }}>
                    {
                      subdomain === "banbif" ? "HOLA, si ya cuentas con una Tarjeta de Crédito oe Cuenta Sueldo BanBif; espera cumplir 45 días con estos productos para obtener mejor descuentos"
                        : "HOLA, para proseguir con su registro necesitamos que ingrese su correo electrónico"
                    }
                  </p>
                }
                <h2 className={"title-register text-left mt-4 mb-4"} style={{fontSize: "1.2em"}}>Registro</h2>

                <div style={{order: required_document ? 0 : required_mobile_number ? 2 : 0}}>
                  <label htmlFor="document_type" className={"mt-1"}>Tipo de documento {required_document ? "*" : "" }</label>
                  <div className="form-group p-0 dropdown">
                    <div
                      className="form-control"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false">
                        <span className={"mt-2"} style={{
                          display: "block"
                        }}>{this.state.type_text}</span>
                      <img src={arrow_down} alt="" className={"arrow-down"}/>
                    </div>
                    <div
                      className="dropdown-menu"
                      style={{
                        width: "100%",
                        overflowY: "auto",
                        maxHeight: 200
                      }}
                    >
                      <a
                        href={"/"}
                        className="dropdown-item dropdown-type select-type"
                        onClick={e =>
                          this.selectDocumentType(
                            e,
                            "Documento de Identidad",
                            "NATIONAL_ID"
                          )
                        }
                      >
                        Documento de Identidad
                      </a>
                      <a
                        href={"/"}
                        className="dropdown-item dropdown-type select-type"
                        onClick={e =>
                          this.selectDocumentType(
                            e,
                            "Pasaporte",
                            "PASSPORT"
                          )
                        }
                      >
                        Pasaporte
                      </a>
                      <a
                        href={"/"}
                        className="dropdown-item dropdown-type select-type"
                        onClick={e =>
                          this.selectDocumentType(
                            e,
                            "Carnet de Extranjería",
                            "FOREIGNER_ID"
                          )
                        }
                      >
                        Carnet de Extranjería
                      </a>
                      <a
                        href={"/"}
                        className="dropdown-item dropdown-type select-type"
                        onClick={e =>
                          this.selectDocumentType(e, "Ruc", "TAX_ID")
                        }
                      >
                        Ruc
                      </a>
                      <a
                        href={"/"}
                        className="dropdown-item dropdown-type select-type"
                        onClick={e =>
                          this.selectDocumentType(e, "Otro", "OTHER")
                        }
                      >
                        Otro
                      </a>
                    </div>
                  </div>

                  <label htmlFor="document_number">Número de documento {required_document ? "*" : "" }</label>
                  <div className="form-group p-0 mb-0">
                    <div className="input-group" ref={input => {
                      if (input) {
                        this.dniInput = input.querySelector("input");
                      }
                    }}>
                      <input
                        value={this.state.document_number}
                        className="form-control"
                        onChange={e => this.setFormValues({document_number: e.target.value})}
                        type="text"
                        placeholder="Documento de identidad"
                        disabled={authStatus === STATUS_LOADING}
                      />

                    </div>
                    {this.renderError(errors, 'document_number')}
                  </div>
                </div>

                <div style={{order: required_mobile_number ? 2 : 1}}>
                  <label htmlFor="first_name" className={"mt-2"}>Nombres *</label>
                  <div className="form-group p-0">
                    <div
                      className="input-group"
                      ref={(input) => {
                        if (input) {
                          this.firstNameInput = input.querySelector('input');
                        }
                      }}
                    >
                      <input
                        className={"form-control"}
                        value={this.state.first_name}
                        onChange={e => this.setFormValues({first_name: e.target.value})}
                        type="text"
                        placeholder="Nombres *"
                        disabled={authStatus === STATUS_LOADING}
                      />

                    </div>
                    {this.renderError(errors, 'first_name')}
                  </div>

                  <label htmlFor="last_name">Apellidos *</label>
                  <div className="form-group p-0">
                    <div>
                      <div
                        className="input-group"
                        ref={(input) => {
                          if (input) {
                            this.lastNameInput = input.querySelector('input');
                          }
                        }}
                      >
                        <input
                          className={"form-control"}
                          value={this.state.last_name}
                          onChange={e => this.setFormValues({last_name: e.target.value})}
                          type="text"
                          placeholder="Apellidos *"
                          disabled={authStatus === STATUS_LOADING}
                        />
                      </div>
                      {this.renderError(errors, 'last_name')}
                    </div>
                  </div>
                </div>


                <div style={{order: required_mobile_number ? required_document ? 1 : 0 : 2}}>
                  <label htmlFor="last_name">Teléfono {required_mobile_number ? "*" : "" }</label>
                  <div>
                    <div className="form-group p-0 mb-0">
                      <div className="input-group">
                        <div className={"form-group col-12 col-md-4 pl-0 pr-1 mb-2 mt-0"}>
                          <Select options={options}
                                  onChange={e => {
                                    this.setState({
                                      code: e.value,
                                      country_code_number: e.value
                                    });
                                  }}
                                  defaultValue={defaultValue}
                          />
                          <img src={downarrow} alt="downarrow" className={"down-phone-number back"} style={{width: 20, height: 20}}/>
                          <img src={downarrow} alt="downarrow" className={"down-phone-number"} style={{width: 20, height: 20}}/>
                        </div>

                        <div
                          className="input-group col-12 col-md-8 pr-0 mb-2 pl-1"
                          ref={(input) => {
                            if (input) {
                              this.mobileNumberInput = input.querySelector('input');
                            }
                          }}
                        >
                          <input
                            className="form-control"
                            id="mobile_number"
                            type="text"
                            placeholder={`Teléfono ${required_mobile_number ? "*" : ""}`}
                            value={this.state.mobile_number}
                            onChange={e => this.setFormValues({mobile_number: e.target.value})}
                            disabled={authStatus === STATUS_LOADING}
                          />
                        </div>
                      </div>
                      {this.renderError(errors, 'mobile_number')}
                    </div>
                  </div>
                </div>

                <div style={{order: 4}}>

                  <label htmlFor="last_name" className={"mt-1"}>{!login_email ? "Correo Electrónico" : "Correo Electrónico *"}</label>
                  <div className="form-group p-0">
                    <div>
                      <div
                        className="input-group"
                        ref={(input) => {
                          if (input) {
                            this.emailInput = input.querySelector('input');
                          }
                        }}
                      >
                        <input
                          className={"form-control"}
                          value={this.state.email}
                          onChange={e => this.setFormValues({email: e.target.value})}
                          type="email"
                          placeholder={!login_email ? "Correo Electrónico" : "Correo Electrónico *"}
                          disabled={authStatus === STATUS_LOADING}
                        />
                      </div>
                      {this.renderError(errors, 'email')}
                    </div>
                  </div>

                  {
                    (login_email || this.state.login_mobile_number) &&
                    <Fragment>
                      <label htmlFor="password">Contraseña *</label>
                      <div className="form-group p-0">
                        <div>
                          <div
                            className="input-group"
                            ref={(input) => {
                              if (input) {
                                this.passwordInput = input.querySelector('input');
                              }
                            }}
                          >
                            <input
                              className={"form-control"}
                              value={this.state.password}
                              onChange={e => this.setFormValues({password: e.target.value})}
                              type="password"
                              placeholder="Contraseña *"
                              disabled={authStatus === STATUS_LOADING}
                            />
                          </div>
                          {this.renderError(errors, 'password')}
                        </div>
                      </div>
                    </Fragment>
                  }
                  {
                    (errors && errors.non_field_errors) &&
                    <div style={{marginTop: 15}}>
                      {this.renderError(errors, 'non_field_errors')}
                    </div>
                  }


                  <div className="terms">
                    <div className={"header-terms"}>Términos y condiciones</div>
                    <div className="form-check">
                      <input type="checkbox"
                             onChange={e => {
                               this.setState({accepted_terms_cond: e.target.checked})
                             }
                             }
                             checked={this.state.accepted_terms_cond}
                             className="form-check-input" id="checkbox-terms"/>
                      <label className="form-check-label" htmlFor="exampleCheck1"> <span
                        onClick={e => {
                          this.setState({accepted_terms_cond: !this.state.accepted_terms_cond})
                        }}
                      >Acepto los</span><Link to={"/terms-and-conditions"} target={"_blank"}> Términos y
                        Condiciones</Link> y
                        <br/>acepto las <Link to={"/politics"} target={"_blank"}> Políticas de privacidad</Link>.</label>
                    </div>

                    <div className={"form-check"}>
                      <input type="checkbox"
                             onChange={e => {
                               this.setState({data_processing_accepted: e.target.checked})
                             }
                             }
                             checked={this.state.data_processing_accepted}
                             className="form-check-input" id="checkbox-terms"/>
                      <label className="form-check-label" htmlFor="exampleCheck1"> <span
                        onClick={e => {
                          this.setState({data_processing_accepted: !this.state.data_processing_accepted})
                        }}
                      >Acepto el </span>tratamiento de mis datos personales.</label>
                    </div>

                    <div className={"form-check"}>
                      <input type="checkbox"
                             onChange={e => {
                               this.setState({data_processing_publicity_accepted: e.target.checked})
                             }
                             }
                             checked={this.state.data_processing_publicity_accepted}
                             className="form-check-input" id="checkbox-terms"/>
                      <label className="form-check-label" htmlFor="exampleCheck1"> <span
                        onClick={e => {
                          this.setState({data_processing_publicity_accepted: !this.state.data_processing_publicity_accepted})
                        }}
                      >Acepto el </span>tratamiento de mis datos personales para fines publicitarios.</label>
                    </div>
                  </div>


                  {this.state.subdomain === "fidelio" ? <div id="recaptcha"/> : null}

                  <div className="submits" style={{marginTop: 30}}>
                    <Link
                      type="button"
                      className="btn btn-secondary btn-cancel"
                      to={'/login'}>
                      Cancelar
                    </Link>

                    <button
                      type="submit"
                      disabled={authStatus === STATUS_LOADING}
                      className="btn btn-login btn-primary "
                      style={{backgroundColor: `${mainColor}`, border: `2px solid ${mainColor}`}}
                    >
                      {authStatus === STATUS_LOADING ? BTN_REGISTER_LOADING : BTN_REGISTER}
                    </button>

                  </div>

                </div>
              </form>
            </div>
          </div>
        </div>
        <Footer
          history={this.props.history}
          stateCart={CART_INVISIBLE}></Footer>
      </div>

    );
  }
}

Register.defaultProps = {
  error: null,
  authStatus: '',
};

Register.propTypes = {
  dispatch: PropTypes.func,
  error: PropTypes.object,
  authStatus: PropTypes.string
};

