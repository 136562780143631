import HttpClient from "../common/utils/httpClient";

export const GET_ORDERS = 'GET_ORDERS';
export const ORDER_DETAIL = 'ORDER_DETAIL';

/**Urls**/
export const LIST_ORDERS_URL = 'v5/me/orders/?payment_method={method_uid}';
export const UPDATE_RETRIEVE_ORDER_URL = 'v5/me/orders/{order_uid}/';


export function getOrders(method_uid="") {
  let url = LIST_ORDERS_URL.replace("{method_uid}",method_uid)
  return (dispatch) => HttpClient.get(dispatch, GET_ORDERS, url);
}

export function getNumberPageOrders(numberPage) {
  return (dispatch) => HttpClient.get(dispatch, GET_ORDERS, `${LIST_ORDERS_URL}?page=${numberPage}`);
}

export function retrieveOrder(orderUid) {
  return (dispatch) => HttpClient.get(
    dispatch,
    ORDER_DETAIL,
    UPDATE_RETRIEVE_ORDER_URL.replace("{order_uid}", orderUid)
  );
}