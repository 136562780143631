import React from 'react';
import { GoogleLogin } from 'react-google-login';
import "./style.scss"

const GoogleLoginButton = ({ onSuccess, onFailure }) => {
  const responseGoogle = (response) => {
    if (response && response.profileObj) {
      console.log(response, "response")
      //localStorage.setItem("accessToken", response.accessToken)
      onSuccess(response.profileObj, response.accessToken);
    } else {
      onFailure();
    }
  };

  return (
    <GoogleLogin
      clientId="76345638538-f1jod2lebdv8g19uk4p4ecpsh2vkdmn8.apps.googleusercontent.com"
      buttonText="Iniciar sesión con Google"
      onSuccess={responseGoogle}
      onFailure={responseGoogle}
      className={"btn-google"}
      cookiePolicy={'single_host_origin'}
    />
  );
};

export default GoogleLoginButton;