import HttpClient from "../common/utils/httpClient";


export const GET_PRODUCTS_PLACES= 'GET_PRODUCTS_PLACES';
export const GET_PRODUCTS_MENU= 'GET_PRODUCTS_MENU';
export const GET_PRODUCTS_REDEEMABLES_PLACES= 'GET_PRODUCTS_REDEEMABLES_PLACES';
export const GET_PRODUCT_PLACES= 'GET_PRODUCT_PLACES';
export const APPEND_PRODUCTS= 'APPEND_PRODUCTS';
export const APPEND_PRODUCTS_MENU= 'APPEND_PRODUCTS_MENU';




/**Urls**/
export const LIST_PRODUCTS_PLACES_URL = 'v7/places/{place_uid}/products/';
export const LIST_PRODUCTS_MENU_URL = 'v7/products/';
export const PRODUCT_PLACES_URL = 'v7/places/{place_uid}/products/{product_uid}/';



export function getProductsByPlace(place_uid, params) {
  let filters = "";
  for (let key of Object.keys(params)) {
    if(params[key]!=null && params[key]!==""){
      if (filters.length > 1) {
        filters += `&`;
      }
      filters += `${key}=${params[key]}`
    }
  }
  let url = LIST_PRODUCTS_PLACES_URL.replace("{place_uid}",place_uid);
  if(filters.length>0)
    url = `${url}?${filters}`;
  return (dispatch) => HttpClient.get(dispatch, GET_PRODUCTS_PLACES, url);
}

export function getProductsMenu(params) {
  let filters = "";
  for (let key of Object.keys(params)) {
    if(params[key]!=null && params[key]!=="" && key!=="is_redeemable"){
      if (filters.length > 1) {
        filters += `&`;
      }
      filters += `${key!=="product_category" ? key : "category"}=${params[key]}`
    }
  }
  let url = LIST_PRODUCTS_MENU_URL;
  if(filters.length>0)
    url = `${url}?${filters}`;
  return (dispatch) => HttpClient.get(dispatch, GET_PRODUCTS_MENU, url);
}

export function getProductsRedeemablesByPlace(place_uid, params) {
  let filters = "";
  for (let key of Object.keys(params)) {
    if(params[key]!=null && params[key]!==""){
      if (filters.length > 1) {
        filters += `&`;
      }
      filters += `${key}=${params[key]}`
    }
  }
  let url = LIST_PRODUCTS_PLACES_URL.replace("{place_uid}",place_uid);
  if(filters.length>0)
    url = `${url}?${filters}`;
  return (dispatch) => HttpClient.get(dispatch, GET_PRODUCTS_REDEEMABLES_PLACES, url);
}

export function getMoreProductsByPlace(nextPageUri) {
  return (dispatch) => HttpClient.get(dispatch, APPEND_PRODUCTS, nextPageUri, false);
}
export function getMoreProductsMenu(nextPageUri) {
  return (dispatch) => HttpClient.get(dispatch, APPEND_PRODUCTS_MENU, nextPageUri, false);
}


export function getProductCurrent( place_uid, product_uid) {
  let url = PRODUCT_PLACES_URL.replace("{place_uid}",place_uid).replace("{product_uid}",product_uid)
  return (dispatch) => HttpClient.get(dispatch, GET_PRODUCT_PLACES, url);
}

