import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {STATUS_LOADING} from "../../../common/utils/generic";
import {Culqi, CulqiProvider} from "../../../common/components/Culqi";

const $ = require('jquery');

export default class CulqiPayment extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      priceTotal: "0"
    }
  }

  componentDidMount() {
    this.updateComponent()
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    this.updateComponent()
  }

  updateComponent(){
    let {price} = this.props;
    if(this.state.priceTotal!==price) {
      this.setState({
        priceTotal: price
      })
      $('html, body').animate({scrollTop: ($(window).scrollTop()+0.1) }, 0);
    }
  }

  render() {
    let {
      paymentStatus,
      enabled,
      price,
      title,
      pay,
      btnTitle,
      payError,
      logo,
      publicKey
    } = this.props

    return (
      <Fragment>
        {
          (this.state.priceTotal === price) &&
          <CulqiProvider
            publicKey={publicKey}
            amount={ parseFloat(price) * 100}
            title={title}
            description="Necesitamos los datos de su tarjeta para hacer efectiva la compra"
            onToken={token => {
              pay(token)
            }}
            onError={error => {
              payError()
            }}
            options={{
              style: {
                maincolor: `#29b1cb`,
                buttontext: "#fff",
                maintext: "#000000",
                desctext: "#000000",
                logo: `${logo}`
              }
            }}
          >

            <Culqi>
              {({openCulqi, amount}) => {
                return (

                  <button
                    disabled={!enabled || paymentStatus === STATUS_LOADING}

                    className={"btn btn-primary"} onClick={e => {
                    if (enabled) {
                      openCulqi()
                    }
                  }}>{btnTitle}</button>

                );
              }}
            </Culqi>

          </CulqiProvider>
        }
      </Fragment>
      )
  }
}

CulqiPayment.propTypes = {
  paymentStatus: PropTypes.string,
  enabled: PropTypes.bool,
  price: PropTypes.string,
  title: PropTypes.string,
  pay: PropTypes.func,
  payError: PropTypes.func,
  logo_app: PropTypes.string,
};
