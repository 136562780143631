import HttpClient from "../common/utils/httpClient";

export const GET_SUBSCRIPTIONS= 'GET_SUBSCRIPTIONS';
export const GET_SUBSCRIPTION= 'GET_SUBSCRIPTION';
export const APPEND_SUBSCRIPTIONS= 'APPEND_SUBSCRIPTIONS';
export const UPDATE_SUBSCRIPTION= 'UPDATE_SUBSCRIPTION';

export const CLEAN_UPDATE= 'CLEAN_UPDATE';

/**Urls**/
export const LIST_SUBSCRIPTION_URL = 'v5/subscriptions/';
export const SUBSCRIPTION_URL = 'v5/subscriptions/{subscription_uid}/';


export function getSubscriptions(params) {
  let filters = "";
  for (let key of Object.keys(params)) {
    if(params[key]!=null && params[key]!==""){
      if (filters.length > 1) {
        filters += `&`;
      }
      filters += `${key}=${params[key]}`
    }
  }
  let url = LIST_SUBSCRIPTION_URL;
  if(filters.length>0)
    url = `${url}?${filters}`;
  return (dispatch) => HttpClient.get(dispatch, GET_SUBSCRIPTIONS, url);
}



export function getNextSubscriptions(nextPageUri) {
  return (dispatch) => HttpClient.get(dispatch, APPEND_SUBSCRIPTIONS, nextPageUri, false);
}


export function getSubscription(subscription_uid) {
  let url = SUBSCRIPTION_URL.replace("{subscription_uid}",subscription_uid)
  return (dispatch) => HttpClient.get(dispatch, GET_SUBSCRIPTION, url);
}

export function canceledSubscription(subscription_uid) {
  let url = SUBSCRIPTION_URL.replace("{subscription_uid}",subscription_uid)
  return (dispatch) => HttpClient.patch({
    "status": "canceled_by_user"
  }, dispatch, UPDATE_SUBSCRIPTION, url);
}


export function cleanUpdate() {
  return {
    type: CLEAN_UPDATE,
  }
}