import React, {Fragment} from 'react'
import PropTypes from 'prop-types';
import './style.scss';
const $ = require('jquery');

export default class CompanyAdsPopUp extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      openModal: false
    };
  }

  componentDidMount() {
    if(!this.state.openModal){
      $('#adsCompanyModal').modal('show');
      this.setState({openModal: true})
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if ( $(".modal-backdrop").length > 1 ) {
      $('.modal-backdrop:first').remove();
    }
  }

  closeModal = (e) => {
    e.preventDefault()
    $(this.modal).modal('hide');
    $('body').removeClass('modal-open');
    $('.modal-backdrop').remove();
    this.setState({openModal: false})
  }

  renderPopUps = () => {
    let {adsCompany} = this.props
    console.log(adsCompany)
    return adsCompany.map((ad, index) => (
      <div className={index ===0 ? "carousel-item carousel-ads active" : "carousel-item carousel-ads"} key={`ad${index}`}>
        <div className={"carousel-ads-header"}>
          <img
            src={ad.image}
            alt={"img"}
            className={"d-block w-100"}
          />
        </div>
        <div className={"carousel-ads-body"}>
          <p>{ad.title}</p>
          {
            ad.link &&
            <a href={ad.link} target={"_blank"} rel="noopener noreferrer" className="carousel-caption d-block">
              <a href={ad.link} target={"_blank"} rel="noopener noreferrer">Ver más</a>
            </a>
          }
        </div>
      </div>
    ));
  }

  renderControls = () => {
    let {adsCompany} = this.props
    return adsCompany.map((ad, index) => (
      <li key={`adIndicator${index}`} data-target="#carouselExampleControls" data-slide-to={index}
          className={index ===0 ? "active" : ""}></li>
    ));
  }

  render() {
    let {adsCompany} = this.props
    return (
      <div>
        {
          adsCompany.length !==0 &&
          <div>
            <div
              className={"modal fade"}
              id="adsCompanyModal"
              ref={(modal) => this.modal = modal}
              tabIndex="-1"
              role="dialog"
              aria-labelledby="exampleModalLabel2"
              aria-hidden="true"
            >
              <div
                className={"modal-dialog modal-dialog-popup modal-lg"}
                role="document">
                <div
                  className={"modal-content"}>

                  <button type="button" className="close btn-close-ad" data-dismiss="modal" aria-label="Close"
                          style={{zIndex: 99, paddingRight: "25px"}} >
                    <span aria-hidden="true" style={{fontSize:"30px"}}>&times;</span>
                  </button>
                  <div className="modal-header ">
                    { (adsCompany.length !== 0 ) &&
                    <Fragment>
                      <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
                        <ol className="carousel-indicators">
                          {
                            this.renderControls()
                          }
                        </ol>
                        <div className="carousel-inner">
                          {
                            this.renderPopUps()
                          }
                        </div>
                        <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                          <span className="sr-only">Previous</span>
                        </a>
                        <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                          <span className="carousel-control-next-icon" aria-hidden="true"></span>
                          <span className="sr-only">Next</span>
                        </a>
                      </div>
                    </Fragment>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    );
  }
}

CompanyAdsPopUp.defaultProps = {
  adsCompany: [],
};

CompanyAdsPopUp.propTypes = {
  adsCompany: PropTypes.array,
};


