import {STATUS_ERROR, STATUS_LOADING, STATUS_SUCCESS} from "../common/utils/generic";

import {GET_PROMOTION_PLACES} from "../actions/placesByPromotion";

const initialState = {
  listPlacePromotion:null,
  errorPlacePromotion: null,
  listPlacePromotionStatus: null,
};

export default function reducer(state = initialState, action) {
  const {type, payload} = action;
  let data;
  switch (type) {

    case `${GET_PROMOTION_PLACES}_PENDING`:
      return {
        ...state,
        listPlacePromotionStatus: STATUS_LOADING,
      };
    case `${GET_PROMOTION_PLACES}_REJECTED`:
      return {
        ...state,
        listPlacePromotionStatus: STATUS_ERROR,
      };
    case `${GET_PROMOTION_PLACES}_FULFILLED`: {
      return {
        ...state,
        listPlacePromotionStatus: (payload.status === 200 || payload.status === 201) ? STATUS_SUCCESS : STATUS_ERROR,
        errorPlacePromotion: payload.status !== 200 ? (payload.status === 400 ? data : STATUS_ERROR) : null,
        listPlacePromotion: payload.data ? payload.data.results : null,
      };
    }
    default:
      return state;
  }
}
