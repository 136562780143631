import React from "react";
import "./style.scss";
import Icon from "../../common/icons/icon";
import { Sidebar } from "../../common/components/Sidebar";
import { Link } from "react-router-dom";
import { STATUS_LOADING } from "../../common/utils/generic";
import { CART_PRODUCTS } from "../../actions/cart";
import ItemsPaymentCart from "../prePaymentCart/components/ItemsPaymentCart";

export default class SidebarCart extends React.Component {
  closeSidebar = (e) => {
    e.preventDefault();
    const { openCartMenu } = this.props;
    openCartMenu(false);
  };

  render() {
    const { activeCartMenu, openCartMenu } = this.props;
    const {
      company,
      updateProductCartSubscription,
      multisite,
      listCartStatus,
      entitiesCartSubscription,
      authStatus,
      getProductsByPlace,
      getProductCurrent,
      productsByPlaceCurrent,
      productsByPlaceCurrentStatus,
      selectedPlace,
      addProductCart,
      updateProductCart,
      entitiesCart,
      addProductsBack,
      openPopUp,
      activePopUp,
      componentPopUp,
      typeCart,
      cleanCart,
      currency,
      orderStatus,
      orderSubscriptionStatus,
    } = this.props;
    return (
      <Sidebar
        key={"sidebar"}
        width={300}
        height={"auto"}
        active={activeCartMenu}
        openSidebarMenu={openCartMenu}
        right={430}
        cart={true}
        clickCartPopup={true}
      >
        <div className={"container sidebar-menu"}>
          <div className={"row"}>
            <div className={"col align-self-end closePrograms"}>
              <button
                id="btn-close-sidebar"
                className={"btn btn-icon"}
                onClick={(e) => this.closeSidebar(e)}
              >
                <Icon svg="close" classes="svg-icon close" title="Close" />
              </button>
            </div>
          <div className="cart-content-popup">
          <ItemsPaymentCart
              {...this.props}
              authStatus={authStatus}
              currency={currency}
              entitiesCart={entitiesCart}
              entitiesCartSubscription={entitiesCartSubscription}
              addProductsBack={addProductsBack}
              updateProductCart={
                typeCart === CART_PRODUCTS
                  ? updateProductCart
                  : updateProductCartSubscription
              }
              selectedPlace={selectedPlace}
              disabled={
                orderStatus === STATUS_LOADING ||
                orderSubscriptionStatus === STATUS_LOADING
              }
              listCartStatus={listCartStatus}
              typeCart={typeCart}
              items={
                typeCart === CART_PRODUCTS
                  ? entitiesCart && entitiesCart.products
                    ? Object.keys(entitiesCart.products).map(
                        (entity) => entitiesCart.products[entity]
                      )
                    : []
                  : entitiesCartSubscription &&
                    entitiesCartSubscription.products
                  ? Object.keys(entitiesCartSubscription.products).map(
                      (entity) => entitiesCartSubscription.products[entity]
                    )
                  : []
              }
              componentPopUp={componentPopUp}
              getProductsByPlace={getProductsByPlace}
              getProductCurrent={getProductCurrent}
              productsByPlaceCurrent={productsByPlaceCurrent}
              productsByPlaceCurrentStatus={productsByPlaceCurrentStatus}
              addProductCart={addProductCart}
              openPopUp={openPopUp}
              activePopUp={activePopUp}
              company={company}
              cleanCart={cleanCart}
              cartPopup={true}
              validate={false}
            />
            <div
              className="content-cart-popup"
              onClick={(e) => this.closeSidebar(e)}
            >
              <Link
                className="nav-link svg-cart btn btn-related"
                to={multisite ? `/c/${company.uid}/cart` : `/cart`}
              >
                Confirmar pedido
              </Link>
            </div>
          </div>
            
          </div>
        </div>
      </Sidebar>
    );
  }
}
