import React from "react";
import './style.scss'

export default function Loader(props) {
  let {type} = props;
  if(type==="page") {
    return (
      <div className="container-loading">
        <div className="lds-facebook">
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    );
  }
  if(type==="list") {
    return (
      <div className="lds-facebook list">
        <div></div>
        <div></div>
        <div></div>
      </div>
    );
  }

}
