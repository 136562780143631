import React, {Component} from "react";
import "./style.scss"

export default class Overlay extends Component {
   render() {
      return (
         <div className={`overlay`}>
         </div>
      );
   }
}

